import BaseRenderer from 'diagram-js/lib/draw/BaseRenderer';

import {is} from "bpmn-js/lib/util/ModelUtil";
import {Renderer} from "./RendererUtil";

const HIGH_PRIORITY = 1500;

export default class CallbotMessageTaskRenderer extends BaseRenderer {
  constructor(eventBus, bpmnRenderer) {
    super(eventBus, HIGH_PRIORITY);

    this.bpmnRenderer = bpmnRenderer;
    this.elementWidth = 160;
    this.currentZoomLevel = 1;
    this.config = {
      height: 150,
      color: '#263C73',
      icon: {
        content: '<path ' +
          'd="M19 9.50003C19.0034 10.8199 18.6951 12.1219 18.1 13.3C17.3944 14.7118 16.3098 15.8992 14.9674 16.7293C13.6251 17.5594 12.0782 17.9994 10.5 18C9.18013 18.0035 7.87812 17.6951 6.7 17.1L1 19L2.9 13.3C2.30493 12.1219 1.99656 10.8199 2 9.50003C2.00061 7.92179 2.44061 6.37488 3.27072 5.03258C4.10083 3.69028 5.28825 2.6056 6.7 1.90003C7.87812 1.30496 9.18013 0.996587 10.5 1.00003H11C13.0843 1.11502 15.053 1.99479 16.5291 3.47089C18.0052 4.94699 18.885 6.91568 19 9.00003V9.50003Z" ' +
          'stroke="#000F3C" ' +
          'stroke-width="1.5" ' +
          'stroke-linecap="round" ' +
          'stroke-linejoin="round"' +
          '/>',
        width: 22,
        height: 22,
      }
    };
    this.fields = [
      {label: 'prompt name', valueKey: 'promptName', valueId: 'PromptName', defaultValue: 'No prompt name'},
      {label: 'template', valueKey: 'template', valueId: 'Template', defaultValue: ''},
      {label: 'clip duration', valueKey: 'clipDuration', valueId: 'ClipDuration', defaultValue: '0'},
      {label: 'values for jinja template', valueKey: 'values', valueId: 'Values', defaultValue: 'No values'}
    ];
  }

  canRender(element) {
    return is(element, 'bpmn:SendTask') && element.businessObject.subType === 'message-task';
  }

  drawShape(parentNode, element) {
    element.width = this.elementWidth;
    const render = Renderer(parentNode, element, this.bpmnRenderer);
    const fields = [...this.fields];
    if (element.businessObject.file) {
      fields[1] = ({label: 'file', valueKey: 'file', valueId: 'File', defaultValue: 'No file'});
    }
    const {shape, zoom} = render(this.config, fields);
    this.currentZoomLevel = zoom;
    return shape;
  }
}

CallbotMessageTaskRenderer.$inject = ['eventBus', 'bpmnRenderer'];
