import { all, call, put, takeLatest } from '@redux-saga/core/effects';
import DiagramsService from '../services/DiagramsService';
import diagramsActions from '../action-creators/diagramsActions';
import NotificationService from '../services/common/NotificationService';
import UsersService from '../../app/services/UsersService';
import usersActions from '../action-creators/usersActions';
import LoginService from '../../app/services/LoginService';

const getOwners = state => {
  return state.users.ownersList;
};

function* usersListFlow(action) {
  try {
    const response = yield call(UsersService.getUsersList, action.searchString);
    if (response.status === 200) {
      yield put(usersActions.updateOwnersList(response.data));
    } else {
      NotificationService.push({
        type: 'error',
        message: 'Smth wrong with user data',
      });
    }
  } catch (error) {
    NotificationService.push({
      type: 'error',
    });
  }
}

function* watchUsersListRequested() {
  yield takeLatest('FETCH_USERS_LIST', usersListFlow);
}

function* currentUserFlow(action) {
  try {
    const response = yield call(LoginService.me);
    if (response.status === 200) {
      yield put(usersActions.updateCurrentUser(response.data));
    } else {
      if (process.env.REQUIRE_AUTH !== 'false') {
        NotificationService.push({
          type: 'error',
          message: 'Smth wrong with user data',
        });
      } else {
        yield put(usersActions.updateCurrentUser({
          "id": "mart.vaha",
          "email": "mart.vaha@elisa.ee",
          "code": "1100",
          "firstName": "Märt",
          "lastName": "Vaha",
          "title": "tootejuht",
          "mobile": "+37256578767",
          "department": "Masinõppe tiim",
          "division": "Masinõppe tiim",
          "unit": "Telekomiteenuste valdkond",
          "isManager": true,
          "isOnMaternityLeave": false,
          "budgetCode": "RETA"
        }));
        console.log('Default user assigned internally');
      }
    }
  } catch (error) {
    if (process.env.REQUIRE_AUTH !== 'false') {
      NotificationService.push({
        type: 'error',
      });
    }
  }
}

function* watchCurrentUserRequested() {
  yield takeLatest('FETCH_CURRENT_USER', currentUserFlow);
}

export default function* usersSaga() {
  yield all([watchUsersListRequested(), watchCurrentUserRequested()]);
}
