import React from 'react';
import * as colors from '../../styles/colors';

import {
  ModalWrapper,
  ModalContent,
  ModalIconContent,
  ModalTitle,
  ModalParagraph,
  ModalInput,
  ModalButtons,
  CancelButton,
  ConfirmButton,
} from './modals-elements/ModalsElements';
import SaveIcon from '../shared/icons/tools/SaveIcon';

const DiagramCreationConflictModal = ({
  modalKey,
  proceedOnModalConfirm,
  actionOnConfirm,
  argsOnConfirm,
  extraModalArgs,
  proceedOnModalCancel,
  actionOnCancel,
  argsOnCancel,
  currentDiagramName,
  currentVersionLabel,
  updateCurrentDiagramName,
  updateCurrentVersionLabel,
}) => {
  const onConfirmClick = () => {
    const updatedArgs = Object.assign({}, argsOnConfirm, {
      diagramName: currentDiagramName,
      versionLabel: currentVersionLabel,
    });
    proceedOnModalConfirm(actionOnConfirm, updatedArgs, modalKey);
  };

  const onCancelClick = () => {
    proceedOnModalCancel(actionOnCancel, argsOnCancel, modalKey);
  };

  const { isConflictInName, isConflictInLabel } = extraModalArgs;

  return (
    <ModalWrapper>
      <ModalContent>
        <ModalIconContent>
          <SaveIcon color={colors.alternativeIconColor} />
        </ModalIconContent>
        <ModalTitle>
          {isConflictInName && 'Name '}
          {isConflictInName && isConflictInLabel && 'and '}
          {isConflictInLabel && 'label '}
          change required
        </ModalTitle>
        <ModalParagraph>Please, modify next properties and click continue.</ModalParagraph>
        {isConflictInName && (
          <ModalInput
            value={currentDiagramName}
            onChange={event => updateCurrentDiagramName(event.target.value)}
            placeholder="Diagram name goes here"
          />
        )}
        {isConflictInLabel && (
          <ModalInput
            value={currentVersionLabel}
            onChange={event => updateCurrentVersionLabel(event.target.value)}
            placeholder="Version label goes here"
          />
        )}
        <ModalButtons>
          <CancelButton onClick={onCancelClick}>Cancel</CancelButton>
          <ConfirmButton onClick={onConfirmClick}>Continue</ConfirmButton>
        </ModalButtons>
      </ModalContent>
    </ModalWrapper>
  );
};

export default DiagramCreationConflictModal;
