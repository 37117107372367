import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import './QualityAssessment.scss';
import QualityAssessmentHeader from './header/QualityAssessmentHeader';
import QualityAssessmentChat from './chat/QualityAssessmentChat';
import qualityAssessmentActions from '../../action-creators/qualityAssessmentActions';
import labelsActions from '../../action-creators/labelsActions';
import { Switch, Select } from 'antd';
import { computeBranchesAndUpdateLayout } from '../../../sfm-app/bpmn-engine/index';
import withChatDraft from '../utils/chat-draft/withChatDraft';
import { withRoles } from '../utils/roles/withRoles';

const { Option } = Select;
const SFM_BASE_URL = process.env.SFM_API_URL || 'http://127.0.0.1:8000';

const addBranchesToFile = async (file, versionId) => {
  try {
    let nodes = await computeBranchesAndUpdateLayout(file);
    let newFile = file;
    newFile = newFile.replaceAll(/\s*branches=\"\[(.*?)\]\"/g, '');
    newFile = newFile.replaceAll(/\s*leaf=\"true\"/g, '');
    newFile = newFile.replaceAll(/\s*\$type=\"(.*?)\"/g, '');
    nodes.map(node => {
      if (Array.isArray(node.branches))
        newFile = newFile.replace(
          `id=\"${node.id}\"`,
          `id=\"${node.id}\" branches=\"${JSON.stringify(node.branches)}\"`
        );
      if (node.isLeaf) newFile = newFile.replace(`id=\"${node.id}\"`, `id=\"${node.id}\" leaf=\"true\"`);
    });

    await fetch(`${SFM_BASE_URL}/dtm-api/diagram-versions/only-file/${versionId}`, {
      method: 'put',
      headers: {
        'content-type': 'application/json',
      },
      body: JSON.stringify({
        file: newFile,
      }),
    });
    return newFile;
  } catch (err) {}
};

const QualityAssessment = ({
  qualityAssessments,
  failureReasonOccurrences,
  totalCount,
  labels,
  labelVersions,
  failureReasons,
  fetchQualityAssessments,
  fetchChatsByFailureReason,
  fetchLabelsList,
  fetchLabelVersionsList,
  fetchFailureReasons,
  createFailureReason,
  deleteQualityAssessment,
  createQualityAssessment,
  updateQualityAssessment,
  currentUser,
  location,
  qualityAssessmentsLoading,
}) => {
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [selectedLabel, setSelectedLabel] = useState(location?.selectedLabel || localStorage.getItem('currentLabel'));
  const [selectedLabelVersion, setSelectedLabelVersion] = useState(localStorage.getItem('currentLabelVersion'));
  const [selectedBranch, setSelectedBranch] = useState(null);
  const [selectedFailureReason, setSelectedFailureReason] = useState(null);
  const [showMarkedChats, setShowMarkedChats] = useState(false);
  const [firstRenderLabelVersions, setfirstRenderLabelVersions] = useState(false);
  const [firstRenderLabel, setfirstRenderLabel] = useState(false);
  const [newFailureReasons, setNewFailureReasons] = useState(failureReasons);
  const [qualityAssessmentsWithReasons, setQualityAssessmentsWithReasons] = useState([]);
  const [filteredQualityAssessmentsWithReasons, setFilteredQualityAssessmentsWithReasons] = useState([]);
  const [pieChartReasons, setPieChartReasons] = useState([]);
  const urlSearchParams = new URLSearchParams(window.location.search);
  const { upgradeSFMDigrams } = Object.fromEntries(urlSearchParams.entries());

  useEffect(() => {
    if (selectedLabel) {
      localStorage.setItem('currentLabel', selectedLabel);
      selectedLabelVersion
        ? localStorage.setItem('currentLabelVersion', selectedLabelVersion)
        : localStorage.removeItem('currentLabelVersion');
    }
  }, [selectedLabel, selectedLabelVersion]);

  const getUserLabels = () => {
    return labels?.filter(label => currentUser?.role === 'admin' || label.owner_id == currentUser?.id);
  };
  useEffect(() => {
    fetchFailureReasons();
    fetchLabelsList();
    fetchLabelVersionsList({ files: upgradeSFMDigrams ? true : false });
  }, []);
  let updateVersionFiles = async () => {
    alert('Updating Files!');
    let promises = [];
    Object.keys(labelVersions).map(key => {
      labelVersions[key].map(version => {
        promises.push(addBranchesToFile(version.file, version.id));
      });
    });
    await Promise.all(promises);
    alert('Files Updated!');
  };
  useEffect(() => {
    if (labelVersions) {
      if (upgradeSFMDigrams && !firstRenderLabelVersions) {
        setfirstRenderLabelVersions(true);
        updateVersionFiles();
      }
    }
  }, [labelVersions]);
  useEffect(() => {
    setNewFailureReasons(failureReasons);
  }, [failureReasons]);

  useEffect(() => {
    let occurrences =
      selectedBranch && Array.isArray(failureReasonOccurrences)
        ? failureReasonOccurrences.filter(x => x.branches && JSON.parse(x.branches).includes(`${selectedBranch}`))
        : failureReasonOccurrences;
    if (Array.isArray(occurrences)) {
      occurrences = JSON.parse(JSON.stringify(occurrences));
      occurrences = occurrences.reduce((total, curr) => {
        let firstOccurrence = total.find(x => x.name === curr.name);
        let occurrenceContainsBranch =
          !selectedBranch || (curr.branches && JSON.parse(curr.branches).includes(`${selectedBranch}`));
        if (occurrenceContainsBranch) {
          if (!firstOccurrence) total.push(curr);
          else firstOccurrence.value += curr.value;
        }
        return total;
      }, []);
    }
    setPieChartReasons(occurrences || []);
  }, [failureReasonOccurrences, selectedBranch]);

  useEffect(() => {
    setSelectedFailureReason(null);
  }, [selectedBranch]);

  useEffect(() => {
    if (selectedLabel && selectedLabelVersion) {
      let selectedLabelRecord = getUserLabels()?.find(label => label.id == selectedLabel);
      setPieChartReasons([]);
      fetchQualityAssessments(
        limit * (page - 1),
        limit,
        showMarkedChats,
        selectedLabelRecord?.name || '',
        selectedLabelVersion
      );
    }
  }, [selectedLabel, selectedLabelVersion, page, limit, showMarkedChats, labels]);

  useEffect(() => {
    setPage(1);
  }, [limit, selectedLabel, selectedLabelVersion]);

  useEffect(() => {
    if (!labelVersions) return;
    let selectedLabelRecord = getUserLabels()?.find(label => label.id == selectedLabel);
    if (
      selectedLabelVersion &&
      localStorage.getItem('currentLabelVersion') &&
      labelVersions[selectedLabelRecord?.name]?.find(x => x.id.toString() === selectedLabelVersion)
    )
      return;
    if (selectedLabelRecord) setSelectedLabelVersion(selectedLabelRecord?.sfm?.live?.id?.toString());
  }, [selectedLabel, labels, labelVersions]);

  useEffect(() => {
    setSelectedBranch(null);
    if (!selectedLabel && selectedLabelVersion) setSelectedLabelVersion(null);
  }, [selectedLabel, selectedLabelVersion]);

  let chatContainsBranch = (chat, branch = selectedBranch) => {
    let messageDialogues = chat.messages.reduce((acc, curr) => {
      if (curr.dialog_start) acc.push([]);
      if (acc.length > 0) acc[acc.length - 1].push(curr);
      return acc;
    }, []);
    return chat.label_versions?.some((labelVersion, labelIndex) => {
      if (
        !(
          labels?.find(label => label.id == selectedLabel)?.name == labelVersion.label &&
          labelVersion.label_version == parseInt(selectedLabelVersion)
        )
      )
        return false;
      return (
        Array.isArray(chat.messages) &&
        chat.messages.length > 0 &&
        messageDialogues[labelIndex] &&
        JSON.parse(
          messageDialogues[labelIndex]
            ?.slice()
            ?.reverse()
            ?.find(message => message.branches)?.branches || '[]'
        )?.includes(branch)
      );
    });
  };

  let extractReasonsFromChat = (chat, newFailureReasons, setNewFailureReasons) => {
    chat?.messages?.forEach(message => {
      message?.quality_assessments?.forEach(quality_assessment => {
        if (
          quality_assessment?.failure_reason &&
          !newFailureReasons.find(r => r?.id == quality_assessment?.failure_reason?.id)
        ) {
          let temp = JSON.parse(JSON.stringify(newFailureReasons));
          temp.push(quality_assessment?.failure_reason);
          setNewFailureReasons(temp);
        }
      });
    });
  };

  let initiateReasons = () => {
    let temp = JSON.parse(JSON.stringify(qualityAssessments));
    temp.forEach(chat => {
      if (!selectedBranch || chatContainsBranch(chat)) {
        extractReasonsFromChat(chat, newFailureReasons, setNewFailureReasons);
      }
    });
    setQualityAssessmentsWithReasons(temp);
    setFilteredQualityAssessmentsWithReasons(temp);
  };

  useEffect(() => {
    if (qualityAssessments) initiateReasons();
  }, [qualityAssessments, selectedBranch]);

  const filterAndSetChats = () => {
    if (!selectedFailureReason || !showMarkedChats) {
      setFilteredQualityAssessmentsWithReasons(qualityAssessmentsWithReasons);
      return;
    }

    let temp = qualityAssessmentsWithReasons.filter(chat => {
      let flag = chat?.messages?.some(message =>
        message?.quality_assessments?.find(qa => qa?.failure_reason?.reason == selectedFailureReason)
      );
      return flag;
    });

    setFilteredQualityAssessmentsWithReasons(temp);
  };

  useEffect(() => {
    let selectedLabelRecord = getUserLabels()?.find(label => label.id == selectedLabel);
    if (selectedFailureReason)
      fetchChatsByFailureReason(selectedLabelRecord?.name || '', selectedLabelVersion, selectedFailureReason);
    else
      fetchQualityAssessments(
        limit * (page - 1),
        limit,
        showMarkedChats,
        selectedLabelRecord?.name || '',
        selectedLabelVersion
      );
  }, [selectedFailureReason]);

  // useEffect(() => {
  //   // filterAndSetChats();
  // }, [selectedFailureReason, showMarkedChats]);

  let getFilteredChats = () => {
    return filteredQualityAssessmentsWithReasons?.filter(
      chat => chat.messages.some(message => message.role === 'client') && (!selectedBranch || chatContainsBranch(chat))
    );
  };
  return (
    <div id="quality-assessment">
      <QualityAssessmentHeader
        qualityAssessmentsLoading={qualityAssessmentsLoading}
        selectedFailureReason={selectedFailureReason}
        setSelectedFailureReason={setSelectedFailureReason}
        pieChartReasons={pieChartReasons}
        labelVersions={labelVersions}
        labels={labels}
        currentUser={currentUser}
        totalCount={totalCount}
        selectedLabel={selectedLabel}
        setSelectedLabel={setSelectedLabel}
        selectedLabelVersion={selectedLabelVersion}
        setSelectedLabelVersion={setSelectedLabelVersion}
        selectedBranch={selectedBranch}
        setSelectedBranch={setSelectedBranch}
        limit={limit}
        setLimit={setLimit}
        page={page}
        setPage={setPage}
        showMarkedChats={showMarkedChats}
        setShowMarkedChats={setShowMarkedChats}
        chats={qualityAssessments}
        chatContainsBranch={chatContainsBranch}
      />
      <div style={{ marginBottom: 12 }}>
        <div>
          <Switch checked={showMarkedChats} onChange={setShowMarkedChats} />{' '}
          <span style={{ color: '#003c7a', marginLeft: 5 }}>Show reviewed contacts</span>
        </div>
        <div
          className="dropdown-filter"
          style={{ marginTop: 15, display: showMarkedChats ? 'block' : 'none', width: '30%' }}
        >
          <p className="dropdown-filter-title">Filter by failure reason</p>
          {
            <div dir="auto">
              <Select
                size={'large'}
                getPopupContainer={trigger => trigger.parentNode}
                allowClear
                showSearch
                className="dropdown dialog-problem-filter"
                placeholder="No Failure Reason Specified"
                loading={!labels}
                value={selectedFailureReason}
                onChange={setSelectedFailureReason}
              >
                {pieChartReasons
                  ?.sort((a, b) => b.name - a.name)
                  ?.map(option => (
                    <Option key={option.name} value={option.name}>
                      {option.name} - ({option.value})
                    </Option>
                  ))}
              </Select>
            </div>
          }
        </div>
      </div>
      {selectedLabel && (
        <div id="qa-chats">
          {filteredQualityAssessmentsWithReasons
            ? getFilteredChats()?.length > 0
              ? getFilteredChats().map(chat => (
                  <QualityAssessmentChat
                    chat={chat}
                    failureReasons={pieChartReasons}
                    setReasons={(reasonsToAdd, reasonsToRemove, messageId) => {
                      let selectedLabelRecord = getUserLabels()?.find(label => label.id == selectedLabel);
                      let message = chat.messages.find(m => m.id == messageId);
                      reasonsToAdd.forEach(reason => {
                        const reasonExists = newFailureReasons?.find(r => r.reason == reason);
                        createQualityAssessment(
                          chat.id,
                          false,
                          reasonExists === undefined,
                          reasonExists ? reasonExists?.id : reason,
                          messageId,
                          JSON.stringify(
                            labelVersions[selectedLabelRecord?.name]
                              ?.find(l => l.id == selectedLabelVersion)
                              ?.branches?.filter(x => chatContainsBranch(chat, parseInt(x.branch)))
                              ?.map(x => x.branch) || []
                          )
                        );
                        if (message) {
                          if (!message.quality_assessments) message.quality_assessments = [];
                          message.quality_assessments?.push({
                            failure_reason: reasonExists
                              ? newFailureReasons?.find(r => r.reason == reason)
                              : { reason },
                          });
                        }
                      });
                      reasonsToRemove.forEach(qa => {
                        if (message) {
                          message.quality_assessments = message?.quality_assessments?.filter(q => q.id != qa.id);
                        }
                        deleteQualityAssessment(qa.id);
                      });
                      setQualityAssessmentsWithReasons(qualityAssessmentsWithReasons);
                      setFilteredQualityAssessmentsWithReasons(filteredQualityAssessmentsWithReasons);
                    }}
                  />
                ))
              : selectedBranch
              ? 'No quality assessments found'
              : ''
            : 'Loading'}
        </div>
      )}
    </div>
  );
};

export const mapStateToProps = state => {
  return {
    failureReasonOccurrences: state.qualityAssessments.failureReasonOccurrences,
    qualityAssessments: state.qualityAssessments.qualityAssessments,
    qualityAssessmentsLoading: state.qualityAssessments.loading,
    totalCount: state.qualityAssessments.totalCount,
    failureReasons: state.qualityAssessments.failureReasons,
    labels: state.labels.labels,
    labelVersions: state.labels.labelVersions,
    showMarkedChats: state.qualityAssessments.showMarkedChats,
    currentUser: state.auth.user,
  };
};

const mapDispatchToProps = {
  createFailureReason: qualityAssessmentActions.createFailureReason,
  fetchQualityAssessments: qualityAssessmentActions.fetchQualityAssessments,
  fetchChatsByFailureReason: qualityAssessmentActions.fetchChatsByFailureReason,
  deleteQualityAssessment: qualityAssessmentActions.deleteQualityAssessment,
  fetchFailureReasons: qualityAssessmentActions.fetchFailureReasons,
  createQualityAssessment: qualityAssessmentActions.createQualityAssessment,
  updateQualityAssessment: qualityAssessmentActions.updateQualityAssessment,
  handleChatNotSolvedByBot: qualityAssessmentActions.handleChatNotSolvedByBot,
  fetchLabelsList: labelsActions.fetchLabelsList,
  fetchLabelVersionsList: labelsActions.fetchLabelVersionsList,
  toggleShowMarkedChats: qualityAssessmentActions.toggleShowMarkedChats,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRoles(['labeler', 'product_manager', 'admin'], withChatDraft(withRouter(QualityAssessment))));
