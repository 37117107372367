import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import uuid from 'react-uuid';
import './LabelsTable.scss';
import labelsActions from '../../../action-creators/labelsActions';
import stringToHexColor from '../../../helpers/stringToHexColor';

import icon1 from './img/icon1.png';
import icon2 from './img/icon2.png';
import iconMinus from './img/minus.png';
import iconPositive from './img/positive.png';
import iconNegative from './img/negative.png';
import { Avatar, Tooltip } from 'antd';
import { PlusOutlined, MinusOutlined } from '@ant-design/icons';
import { Icon } from '@iconify/react/dist/offline';
import externalLinkAlt from '@iconify-icons/uil/external-link-alt';

const SFM_CLIENT_URL = process.env.SFM_CLIENT_URL || 'http://127.0.0.1:8080';

const getJSON = obj => {
  try {
    return JSON.parse(obj);
  } catch (err) {
    return false;
  }
};

const LabelsTable = ({
  tableContent,
  labels,
  tasks,
  currentLabelId,
  updateCurrentLabelId,
  users,
  showAuxiliaryLabels,
  extraInfo,
}) => {
  const [tableLabels, setTableLabels] = useState([]);
  const [ownerFilterMap, setOwnerFilterMap] = useState([]);

  useEffect(() => {
    if (Array.isArray(labels)) {
      let temp = JSON.parse(JSON.stringify(labels));
      {
        temp.unshift({
          name: 'Uncertain Labels',
          id: 'uncertain_labels',
        });
        setTableLabels(temp);
      }
    }
  }, [labels]);

  const getLanguageAverageF1 = f1Json => {
    try {
      f1Json = JSON.parse(f1Json);
      const langs = Object.keys(f1Json);
      const avg = langs.reduce((total, lang) => total + f1Json[lang], 0) / langs.length;
      return avg;
    } catch (err) {
      return '-';
    }
  };
  const toggleLabelCollapse = (label, collapse) => {
    label.expanded = collapse;
    tableLabels.forEach(otherLabel => {
      if (otherLabel.parent_id == label.id) otherLabel.expanded = collapse;
    });
    setTableLabels([...tableLabels]);
  };

  const getLabelRow = (label, labelIndex) => {
    let rows = [];
    if (!showAuxiliaryLabels && label.is_auxiliary) return rows;

    if (!label.parent_id)
      rows.push(
        getTableRow(
          labelIndex,
          label,
          tableLabels.some(otherLabel => otherLabel.parent_id == label.id)
        )
      );
    tableLabels.forEach((otherLabel, otherIndex) => {
      if (otherLabel.parent_id == label.id && otherLabel.expanded) {
        rows.push(getTableRow(otherIndex, otherLabel, false, true));
      }
    });

    if (ownerFilterMap.length > 0) {
      rows = rows.filter(
        row => !row?.props['data-user-ref'] || ownerFilterMap.includes(row?.props['data-user-ref']?.toString())
      );
    }

    return rows;
  };

  const getTableRow = (labelIndex, label, isParent, isChild) => {
    let user = users?.find(user => user.id == label['owner_id']);
    return (
      <tr
        style={{
          borderBottomColor: '#263C73',
          borderBottomWidth: labelIndex == 0 ? 1 : 0,
          backgroundColor: isChild && currentLabelId !== label.id ? '#F9F9F9' : undefined,
        }}
        key={uuid()}
        className={`labels-table-item-container ${currentLabelId === label.id || label.expanded ? 'selected' : ''}`}
        onClick={() => (currentLabelId !== label.id ? updateCurrentLabelId(label.id) : '')}
        data-user-ref={label.owner_id}
      >
        {tableContent.map((tableContentItem, index) => (
          <td style={{ width: index < 4 ? '15%' : '8%' }} key={uuid()}>
            {(() => {
              let accuracy = getLanguageAverageF1(extraInfo?.[labelIndex]?.['f1']);
              if (accuracy === '-') accuracy = 0;
              let accuracyColor = accuracy >= 85 ? 'darkGreen' : accuracy >= 70 ? 'orange' : 'red';
              switch (tableContentItem.key) {
                case 'name':
                  return (
                    <p
                      className="labels-table-item"
                      style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                    >
                      {isParent ? (
                        label.expanded == true ? (
                          <MinusOutlined
                            onClick={() => toggleLabelCollapse(label, false)}
                            style={{ fontSize: 20, marginRight: 5 }}
                          />
                        ) : (
                          <PlusOutlined
                            onClick={() => toggleLabelCollapse(label, true)}
                            style={{ fontSize: 20, marginRight: 5 }}
                          />
                        )
                      ) : null}
                      {label[tableContentItem.key]}

                      {/*<span className="labels-table-item__tip">*/}
                      {/*    { label['description'] }*/}
                      {/*</span>*/}
                    </p>
                  );
                case 'description':
                  return <p className="labels-table-item">{label[tableContentItem.key]}</p>;
                case 'labels':
                  return (
                    <Link
                      to={{
                        pathname: 'labelling',
                        selectedLabel: label?.id?.toString(),
                      }}
                    >
                      {' '}
                      <div
                        className={labelIndex == 0 ? 'labels-statistics' : `labels-statistics ${accuracyColor}-border`}
                      >
                        <p className={`labels-statistics-number ${accuracyColor}`}>
                          {labelIndex == 0 ? (
                            <Icon
                              style={{ fontSize: 30, color: '#E61A3C' }}
                              icon={externalLinkAlt}
                              data-inline="false"
                            ></Icon>
                          ) : (
                            `${label?.x || 0}`
                          )}
                        </p>
                      </div>
                    </Link>
                  );
                case 'sfm':
                  return (
                    <div className="labels-table-item-wrapper">
                      {label[tableContentItem.key] && label[tableContentItem.key]['live'] ? (
                        <Link
                          to={`/sfm?label=${label[tableContentItem.key]['live']['label']}&id=${
                            label[tableContentItem.key]['live']['id']
                          }`}
                        >
                          <p>v{label[tableContentItem.key]['live']['id']} (live)</p>
                        </Link>
                      ) : (
                        ''
                      )}
                      {label[tableContentItem.key] && label[tableContentItem.key]['test'] ? (
                        <Link
                          to={`/sfm?label=${label[tableContentItem.key]['test']['label']}&id=${
                            label[tableContentItem.key]['test']['id']
                          }`}
                        >
                          <p>v{label[tableContentItem.key]['test']['id']} (test)</p>
                        </Link>
                      ) : (
                        ''
                      )}
                      {label[tableContentItem.key] &&
                      !label[tableContentItem.key]['live'] &&
                      !label[tableContentItem.key]['test'] &&
                      label[tableContentItem.key]['last'] ? (
                        <Link
                          to={`/sfm?label=${label[tableContentItem.key]['last']['label']}&id=${
                            label[tableContentItem.key]['last']['id']
                          }`}
                        >
                          <p>v{label[tableContentItem.key]['last']['id']} (latest)</p>
                        </Link>
                      ) : (
                        ''
                      )}
                      {!label[tableContentItem.key] ? (
                        labelIndex != 0 ? (
                          <Link to={`/sfm?create=true&label=${label?.name}`}>
                            <p>Create new</p>
                          </Link>
                        ) : (
                          '-'
                        )
                      ) : (
                        ''
                      )}
                    </div>
                  );
                case 'accuracy':
                  const f1JSON = getJSON(extraInfo?.[labelIndex]?.['f1']);
                  return (
                    <Tooltip
                      overlayStyle={{ whiteSpace: 'pre-line' }}
                      title={
                        f1JSON &&
                        Object.keys(f1JSON)
                          .map(lang => `${lang}: ${f1JSON[lang]?.toFixed(2)}%`)
                          .join('\n')
                      }
                    >
                      <p className={`labels-table-item ${extraInfo?.[labelIndex]?.['f1'] != '-' ? accuracyColor : ''}`}>
                        {getLanguageAverageF1(extraInfo?.[labelIndex]?.['f1']) === '-'
                          ? '-'
                          : getLanguageAverageF1(extraInfo?.[labelIndex]?.['f1']).toFixed('2') + '%'}
                      </p>
                    </Tooltip>
                  );
                case 'f1_keywords':
                  return (
                    <p
                      className={`labels-table-item ${
                        extraInfo?.[labelIndex]?.['f1_keywords'] !== '-' ? accuracyColor : ''
                      }`}
                    >
                      {extraInfo?.[labelIndex]?.['f1_keywords'] === '-' || !extraInfo?.[labelIndex]?.x1
                        ? '-'
                        : `${extraInfo?.[labelIndex]?.['f1_keywords'] * 100}%`}
                    </p>
                  );
                case 'fcr':
                  let fcr = parseFloat(extraInfo?.[labelIndex]?.['fcr']);
                  return (
                    <Link
                      to={{
                        pathname: 'qa',
                        selectedLabel: label?.id?.toString(),
                      }}
                    >
                      <p
                        className={`labels-table-item`}
                        style={!isNaN(fcr) ? { color: fcr >= 80 ? 'green' : fcr >= 50 ? 'orange' : 'red' } : {}}
                      >
                        {extraInfo?.[labelIndex]?.['fcr'] || '-'}
                      </p>
                    </Link>
                  );
                case 'volume':
                  let volume =
                    labelIndex == 0
                      ? `${Math.round(
                          100 -
                            tableLabels?.reduce((total, curr, index) => {
                              return total + (parseFloat(extraInfo[index]?.volume) || 0);
                            }, 0)
                        )}%`
                      : extraInfo?.[labelIndex]?.['volume'];
                  return (
                    <p className={`labels-table-item ${label?.x ? extraInfo?.[labelIndex]?.['volumeColor'] : ''}`}>
                      {extraInfo?.[labelIndex]?.['volume'] !== null
                        ? `${extraInfo?.[labelIndex]?.['volume'] || '0'}%`
                        : '-'}
                    </p>
                  );
                case 'owner':
                  return (
                    <div className={`labels-table-item`} style={{ display: 'flex', justifyContent: 'center' }}>
                      <div
                        className="circle-table-item"
                        style={{ backgroundColor: `${stringToHexColor(constructUserInitials(user))}` }}
                      >
                        {constructUserInitials(user)}
                      </div>
                    </div>
                  );
                case 'solutionReview':
                  // console.log('LABEL: ', label);
                  if (label['sfm'] && label['sfm']['review']) {
                    // console.log('STATUS: ', label['sfm']['review']['needsReview']);
                  }

                  return (
                    <div className={`labels-table-item`}>
                      {label['sfm'] && (label['sfm']['live'] || label['sfm']['last']) ? (
                        <Link
                          to={`/sfm?review=true&label=${(label['sfm']['live'] || label['sfm']['last'])['label']}&id=${
                            (label['sfm']['live'] || label['sfm']['last'])['id']
                          }`}
                        >
                          {!tasks.find(x => x.linked_item === `SolutionFlow:${label.name}`) &&
                          label['sfm'] &&
                          label['sfm']['review'] &&
                          label['sfm']['review']['approved'] ? (
                            <img src={iconPositive} alt="" />
                          ) : (
                            <img src={iconNegative} alt="" />
                          )}
                        </Link>
                      ) : (
                        '-'
                      )}
                    </div>
                  );
                default:
                  return (
                    <div className="empty">
                      <div className="empty-content"></div>
                    </div>
                  );
              }
            })()}
          </td>
        ))}
      </tr>
    );
  };

  const constructUserInitials = user => {
    let initials = `${user?.first_name?.[0]?.toUpperCase() || ''}${user?.last_name?.[0]?.toUpperCase() || ''}`;
    initials = initials || '-';
    return initials;
  };

  const handleFilterByOwner = e => {
    ownerFilterMap.includes(e.target.id)
      ? setOwnerFilterMap(ownerFilterMap.filter(user => user !== e.target.id))
      : setOwnerFilterMap([...ownerFilterMap, e.target.id]);
  };

  const clearFilters = () => {
    setOwnerFilterMap([]);
  };

  return (
    <>
      {labels ? (
        <>
          {/* {users ? (
            <div style={{ display: 'flex', 'align-items': 'center' }}>
              <Avatar.Group maxCount={1} size="large" maxStyle={{ color: '#f56a00', backgroundColor: '#fde3cf' }}>
                {users.map(user => (
                  <Tooltip title={`${user.first_name}${user.last_name}`} placement="top">
                    <div
                      className={'filter-status ' + `${ownerFilterMap.includes(user.id.toString()) ? 'selected' : ''}`}
                    >
                      <Avatar
                        id={user.id}
                        onClick={handleFilterByOwner}
                        style={{ backgroundColor: `${stringToHexColor(constructUserInitials(user))}` }}
                      >
                        {constructUserInitials(user)}
                      </Avatar>
                    </div>
                  </Tooltip>
                ))}
              </Avatar.Group>
              <div className="clear-button" onClick={clearFilters}>
                {' '}
                Clear filters{' '}
              </div>
            </div>
          ) : (
            ''
          )} */}

          <table id="labels-table">
            <thead id="labels-table-header">
              <tr>
                {tableContent.map(tableContentItem => (
                  <th key={uuid()} className={tableContentItem.label === 'Name' ? 'fixed-width' : ''}>
                    <p className="labels-table-header-item">{tableContentItem.label}</p>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>{tableLabels.sort((a, b) => b.is_auxiliary - a.is_auxiliary).map(getLabelRow)}</tbody>
          </table>
        </>
      ) : labels === undefined ? (
        'Loading...'
      ) : (
        'No labels found'
      )}
    </>
  );
};

export const mapStateToProps = state => {
  return {
    tableContent: state.labels.tableContent,
    labels: state.labels.labels,
    currentLabelId: state.labels.currentLabelId,
  };
};

const mapDispatchToProps = {
  updateCurrentLabelId: labelsActions.updateCurrentLabelId,
};

export default connect(mapStateToProps, mapDispatchToProps)(LabelsTable);
