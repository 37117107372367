const isInputActive = id => {
  return document.getElementById(id) === document.activeElement;
};

const isAnyInputActive = () => {
  const elemPropInputs = Array.from(document.getElementsByClassName('element-property-input')).filter(
    input => input === document.activeElement
  );
  const editorElem = document.getElementsByClassName('monaco-editor')[0];

  return (
    elemPropInputs.length > 0 || // Check for all task properties inputs
    // Check for monaco editor
    (editorElem && editorElem.classList.contains('focused')) ||
    // Checks for diagram name and label inputs
    isInputActive('diagram-name-input') ||
    isInputActive('diagram-label-input') ||
    // Checks for search inputs
    isInputActive('diagram-owners-search-input') ||
    isInputActive('node-owners-search-input') ||
    isInputActive('diagram-list-search') ||
    // Check for version comment input
    isInputActive('version-comment-input')
  );
};

export default isAnyInputActive;
