import ModelerService from './ModelerService';

const ViewerService = {
  importDiagramVersion: (viewer, file) => {
    if (!file) return;
    viewer
      .importXML(file)
      .then(() => {
        console.log('SUCCESS import XML');
        var canvas = viewer.get('canvas');
        canvas.zoom('fit-viewport');
        ModelerService._updateRenderedApperance(viewer)
        // ViewerService._subscribeForCustomAppearance(viewer);
        // // NOTE: this function require separate service for all scenarious
        // ModelerService._autoRefactorAppearance(modeler);
      })
      .catch(err => {
        console.log(err);
      });
  },

  exportDiagramVersion: (viewer, done) => {
    viewer
      .saveXML()
      .then(r => done(null, r))
      .catch(e => done(e, null));
  },

  subscribe: (viewer, updateCurrentTask, updateCurrentSubProcess, updateCurrentSubProcessDiagramVersions) => {
    ViewerService._updateElementsOnSelect(
      viewer,
      updateCurrentTask,
      updateCurrentSubProcess,
      updateCurrentSubProcessDiagramVersions
    );
    // ModelerService._disableDoubleClick(modeler);
  },

  _updateElementsOnSelect: (
    viewer,
    updateCurrentTask,
    updateCurrentSubProcess,
    updateCurrentSubProcessDiagramVersions
  ) => {
    // This function is required to remove editing-parent box
    // when it becomes visible, only if task node added
    const __removeCurrentEditingInputBox = (maxIter = 3) => {
      if (maxIter === 0) {
        return 0;
      }
      const editParents = document.getElementsByClassName('djs-direct-editing-parent');
      if (editParents.length > 0) {
        editParents[0].style.display = 'none';
        return 1;
      } else {
        setTimeout(() => {
          __removeCurrentEditingInputBox(maxIter - 1);
        }, 100);
      }
    };

    const __onSelectionChangedListener = ({ oldSelection, newSelection }) => {
      if (newSelection && newSelection.length === 1) {
        const oneSelectedElement = newSelection[0];
        if (oneSelectedElement.type.includes('Task')) {
          updateCurrentTask(oneSelectedElement, oneSelectedElement.businessObject);
          updateCurrentSubProcess(undefined, undefined);
          updateCurrentSubProcessDiagramVersions([]);
          __removeCurrentEditingInputBox();
        } else if (oneSelectedElement.type === 'bpmn:CallActivity') {
          updateCurrentSubProcess(oneSelectedElement, oneSelectedElement.businessObject);
          updateCurrentSubProcessDiagramVersions([]);
          updateCurrentTask(undefined, undefined);
        } else {
          updateCurrentSubProcess(undefined, undefined);
          updateCurrentTask(undefined, undefined);
          updateCurrentSubProcessDiagramVersions([]);
        }
      } else {
        updateCurrentSubProcess(undefined, undefined);
        updateCurrentTask(undefined, undefined);
        updateCurrentSubProcessDiagramVersions([]);
      }
    };

    let eventBus = viewer.get('eventBus');
    eventBus.on('selection.changed', __onSelectionChangedListener);

    return __onSelectionChangedListener;
  },
};

export default ViewerService;
