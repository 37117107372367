export const CODE_TO_LANGUAGE_MAP = {
  en: 'English',
  de: 'German',
  et: 'Estonian',
  ru: 'Russian',
  lv: 'Latvian',
  lt: 'Lithuanian',
  pl: 'Polish',
  arb: 'Arabic',
};
export const SUPPORTED_LANGUAGES = process.env.SUPPORTED_LANGUAGES ? process.env.SUPPORTED_LANGUAGES.split(',') : [];
