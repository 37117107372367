import initialQualityAssessmentsState from '../constants/initial-state-parts/initialQualityAssessmentsState';

const qualityAssessmentsReducer = (state = initialQualityAssessmentsState, action) => {
  let failureReasonOccurrences = [];
  switch (action.type) {
    case 'SET_QUALITY_ASSESSMENTS_LOADING':
      return Object.assign({}, state, {
        loading: action.loading,
      });
    case 'UPDATE_QUALITY_ASSESSMENTS_LIST':
      return Object.assign({}, state, {
        qualityAssessments: action.data.quality_assessments,
        totalCount: action.data.total_count,
        failureReasonOccurrences: action.data.qa_reasons,
      });
    case 'UPDATE_CHATS_BY_FAILURE_REASON_LIST':
      return Object.assign({}, state, {
        qualityAssessments: action.data.quality_assessments,
        totalCount: action.data.total_count,
      });
    case 'UPDATE_FAILURE_REASONS_LIST':
      return Object.assign({}, state, {
        failureReasons: action.data,
      });
    case 'HANDLE_CHAT_NOT_SOLVED_BY_BOT':
      console.log(action);
      return Object.assign({}, state, {
        qualityAssessments: Object.assign(
          [],
          state.qualityAssessments.map(qa =>
            qa.chat_intercom_id === action.chat.id
              ? Object.assign({}, qa, { is_chat_solved_by_bot: false })
              : Object.assign({}, qa, { ...qa })
          )
        ),
      });
    case 'ADD_CHAT_QUALITY_ASSESSMENT':
      if (Array.isArray(state.failureReasonOccurrences)) {
        failureReasonOccurrences = [...state.failureReasonOccurrences];
        let reasonExists = failureReasonOccurrences.find(
          f => f.name === action.qa.failure_reason.reason && f.branches === action.qa.branches
        );
        if (reasonExists) reasonExists.value++;
        else
          failureReasonOccurrences.push({
            name: action.qa.failure_reason.reason,
            value: 1,
            branches: action.qa.branches,
          });
      }
      return Object.assign({}, state, {
        failureReasonOccurrences,
        qualityAssessments: Object.assign(
          [],
          state.qualityAssessments.map(qa => {
            if (qa.id === action.qa.chat_intercom_id) {
              let message = qa?.messages?.find(message => message?.id == action.qa.message_id);
              if (message) {
                message.quality_assessments = Array.isArray(message.quality_assessments)
                  ? message.quality_assessments
                  : [];
                message.quality_assessments.push(action.qa);
              }
            }
            return { ...qa };
          })
        ),
      });
    case 'REMOVE_CHAT_QUALITY_ASSESSMENT':
      if (Array.isArray(state.failureReasonOccurrences)) {
        failureReasonOccurrences = [...state.failureReasonOccurrences];
        let reasonIndex = failureReasonOccurrences.findIndex(
          f => f.name === action.reason && f.branches === action.branches
        );
        if (reasonIndex > -1) {
          if (failureReasonOccurrences[reasonIndex].value <= 1) failureReasonOccurrences.splice(reasonIndex, 1);
          else failureReasonOccurrences[reasonIndex].value--;
        }
      }
      return Object.assign({}, state, {
        failureReasonOccurrences,
        qualityAssessments: Object.assign(
          [],
          state.qualityAssessments.map(qa => {
            qa.messages.forEach(
              message => (message.quality_assessments = message?.quality_assessments?.filter(x => x.id != action.id))
            );
            return { ...qa };
          })
        ),
      });
    case 'ADD_FAILURE_REASON':
      let failureReasons = [...state.failureReasons];
      failureReasons.push(action.reason);
      return Object.assign({}, state, {
        failureReasons,
      });

    default:
      return state;
  }
};

export default qualityAssessmentsReducer;
