import React, {useEffect, useRef} from "react";
import styled from "styled-components";

const messageMixin = (color, backgroundColor, align) => `
  color: ${color};
  background-color: ${backgroundColor};
  align-self: ${align};
`

const authorMixin = {
  bot: messageMixin("#273645", "#F0F1F5", "flex-start"),
  client: messageMixin("#fff", "#273645", "flex-end"),
  system: messageMixin("#fff", "#D33543", "center")
};


const Container = styled.div`
  align-self: flex-start;
  padding: 10px;
  border-radius: 10px;
  margin-bottom: 10px;
  max-width: 80%;
  word-break: break-word;
  ${props => authorMixin[props.author]} 
`;

const MessageText = styled.div`
  padding-left: 20px;
  padding-right: 20px;
`;

const CallbotMessage = ({message, author, audioSrc, finishedPlayingPrompt}) => {
  const ref = useRef(null);

  useEffect(() => {
    ref.current.scrollIntoView({behavior: "smooth"});
  }, []);

  const onAudioEnded = () => {
    if (author === "bot") {
      finishedPlayingPrompt();
    }
  }

  return (
    <Container author={author} ref={ref}>
      <MessageText>
        {message}
      </MessageText>
      {audioSrc && <audio onEnded={onAudioEnded} src={audioSrc} controls autoPlay/>}
    </Container>
  );
}

export default CallbotMessage;
