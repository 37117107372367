import BaseRenderer from "diagram-js/lib/draw/BaseRenderer";
import {DEFAULT_ZOOM_LEVEL, ELEMENT_WIDTH, HIGH_PRIORITY} from "../renderer-constants";
import {is} from "bpmn-js/lib/util/ModelUtil";
import {attr as svgAttr} from "tiny-svg";

export default class ParallelGatewayRenderer extends BaseRenderer {

  constructor(eventBus, bpmnRenderer) {
    super(eventBus, HIGH_PRIORITY);

    this.bpmnRenderer = bpmnRenderer;
    this.elementWidth = ELEMENT_WIDTH;
    this.currentZoomLevel = DEFAULT_ZOOM_LEVEL;
  }

  canRender(element) {
    return is(element, 'bpmn:ParallelGateway');
  }

  drawShape(parentNode, element) {
    const shape = this.bpmnRenderer.drawShape(parentNode, element);
    svgAttr(parentNode.childNodes[1], {
      class: 'parallel-gateway-display-initial',
    });
    return shape;
  }

}

ParallelGatewayRenderer.$inject = ['eventBus', 'bpmnRenderer'];
