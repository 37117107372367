/*This module handles communication between Helpdesk interface and ChatBot*/
import React from 'react';
import $ from 'jquery';
import { Client, Message } from 'paho-mqtt';
import { getStore } from '../components/Store';

const mqttUrlLive = process.env.REACT_APP_CWC_MQTT_URL_LIVE;
const mqttUrlDraft = process.env.REACT_APP_CWC_MQTT_URL_DRAFT;
const cwcConnectionUsername = process.env.REACT_APP_CWC_CONNECTED_USERNAME || 'admin';
const cwcConnectionPassword = process.env.REACT_APP_CWC_CONNECTED_PASSWORD || 'admin';

class MqttWrapper {
  constructor(mqttUrl) {
    this.onEventQueue = [];
    this.emitEventQueue = [];
    this.eventMap = {};
    this.ready = false;
    this.uid = Math.random().toString(16).substr(2, 8);
    this.connectCallback = () => {};
    this.connection = new Client(mqttUrl, `helpdesk/${this.uid}`);
    this.connection.onMessageArrived = message => this._processIncomeMessage(message);
    this.connection.onConnectionLost = error => this._handleConnectionLost(error);
    this.connection.connect({
      onSuccess: () => this._onConnected(),
      userName: cwcConnectionUsername,
      password: cwcConnectionPassword,
      keepAliveInterval: 3,
      reconnect: true,
    });
  }

  _processIncomeMessage(message) {
    const callback = this.eventMap[message.destinationName];
    console.log('_processIncomeMessage', message);
    if (callback) {
      callback(JSON.parse(message.payloadString));
    }
  }

  _renderUniqueTopic(event) {
    return `helpdesk-client/${this.uid}/${event}`;
  }

  _renderBroadcastTopic(event) {
    return `helpdesk-client/${event}`;
  }

  _renderServerTopic(event) {
    return `helpdesk/${this.uid}/${event}`;
  }

  _onMessage(event, callback) {
    this.connection.subscribe(this._renderUniqueTopic(event), {
      onSuccess: () => {
        this.eventMap[this._renderUniqueTopic(event)] = callback;
      },
    });
    this.connection.subscribe(this._renderBroadcastTopic(event), {
      onSuccess: () => {
        this.eventMap[this._renderBroadcastTopic(event)] = callback;
      },
    });
  }

  _onConnected() {
    this.onEventQueue.forEach(({ event, callback }) => this._onMessage(event, callback));
    this.connectCallback();
    // this.onEventQueue = [];
    this.emitEventQueue.forEach(({ event, message }) => this.emit(event, message));
    this.emitEventQueue = [];
  }

  _handleConnectionLost(error) {
    console.error('MQTT connection lost:', error);
    this.ready = false;
    // Handle connection lost
  }

  _onEvent(event, callback) {
    this.connection.on(event, callback);
  }

  on(event, callback) {
    if (event === 'connect') {
      this.connectCallback = () => {
        this.emit('connect', {});
        this.ready = true;
        callback();
      };
      if (this.ready) connectCallback();
    } else {
      if (this.ready) {
        this._onMessage(event, callback);
      } else {
        this.onEventQueue.push({ event, callback });
      }
    }
  }

  emit(event, message) {
    if (this.ready) {
      const mqttMessage = new Message(JSON.stringify(message));
      mqttMessage.destinationName = this._renderServerTopic(event);
      this.connection.send(mqttMessage);
    } else {
      this.emitEventQueue.push({ event, message });
    }
  }

  disconnect() {
    try {
      this.connection.disconnect();
    } catch (err) {
      console.error('MQTT disconnection failed:', err);
    }
  }
}

/**
 * get socket
 *
 * @public
 * @param mode {'draft' | 'live'}
 * @returns MqttWrapper
 */

let initSocket = (mode, loggedInUser) => {
  const store = getStore(mode);
  const mqtt_url = mode === 'draft' ? mqttUrlDraft : mqttUrlLive;

  console.log('check user: ', loggedInUser);
  const userSkills = [];
  const socket = new MqttWrapper(mqtt_url);
  $('#logout span').click(() => {
    store.dispatch({ type: 'RESET_STATE' });
  });

  socket.on('connect', function () {
    console.log('socket connected!');
    socket.emit('get chats', { username: `${loggedInUser['first_name']} ${loggedInUser['last_name']}` });
    socket.emit('set username', {
      username: `${loggedInUser['first_name']} ${loggedInUser['last_name']}`,
      groups: loggedInUser['groups'],
      spectator: store.getState().appState.spectator,
      skills: userSkills,
    });
  });

  socket.on('ping', function () {
    socket.emit('set username', {
      username: `${loggedInUser['first_name']} ${loggedInUser['last_name']}`,
      groups: loggedInUser['groups'],
      spectator: store.getState().appState.spectator,
      skills: userSkills,
    });
  });

  store.dispatch({ type: 'set socket', socket });
  store.dispatch({ type: 'set mode', mode });
  return socket;
};

export { initSocket };
