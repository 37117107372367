import crud from './common/CrudService';
import responseService from './common/ResponseService';

const API_BASE_URL = process.env.SFM_API_URL || 'http://127.0.0.1:8000';
const API_PREFIX = process.env.SFM_API_PREFIX || 'labels-api';
const sfmCRUD = crud(API_BASE_URL, API_PREFIX, ['all-content']);
const sfmLabelVersionCRUD = crud(API_BASE_URL, 'dtm-api', ['diagram-versions', `by-label/`]);

const SFMService = {
  getSFMList: params => {
    return responseService.handle(
      sfmCRUD.list({ description_translations_version: params?.descriptionTranslationsVersion })
    );
  },
  getSFMLabelVersionsList: params => {
    return responseService.handle(sfmLabelVersionCRUD.list(params));
  },
};

export default SFMService;
