const WebUrlService = {
  resetWebUrl: () => {
    window.history.pushState({}, null, `${process.env.WEB_URL_PREFIX || ''}`);
  },

  updateWebUrlWithVersion: (label, id = '') => {
    window.history.pushState(
        {},
        null,
        `${process.env.WEB_URL_PREFIX || ''}` + `${label ? `?label=${label}` : ''}` + `${id ? `&id=${id}` : ''}`);
  },
};

export default WebUrlService;
