import React from "react";
import {useEffect} from "react";
import $ from "jquery";

const withChatDraft = (Component) => {
  return (props) => {
    useEffect(() => {
      if (process.env.ENABLE_CHAT === 'false') {
        return;
      }
      if (!window.cws) {
        window.initCWC();
      } else if (window.cws.hidden) {
        window.cws.show();
        if (window.cws.resetButtonActive) {
          $('#chat-web-sdk-reset-btn').addClass('visible');
          window.cws.resetButtonActive = undefined;
        }

      }
    }, []);
    return <Component {...props} />
  };
}

export default withChatDraft;