import { useIndexedDB } from "react-indexed-db";

export const DBService = {
  name: 'sfmDB' + process.env.ENV,
  version: 2,
  objectStoresMeta: [
    {
      store: 'diagrams',
      storeConfig: { keyPath: 'id'},
      storeSchema: [
        { name: 'diagram', keypath: 'diagram', options: { unique: true } },
        { name: 'versionId', keypath: 'versionId', options: { unique: true } },
        { name: 'file', keypath: 'file', options: { unique: true } },
      ]
    }
  ]
};

const dbState = {
  inUse: false
}
const indexedDB = window.indexedDB || window.mozIndexedDB || window.webkitIndexedDB || window.msIndexedDB;


export function CreateObjectStore(dbName, version, storeSchemas) {
  const request = indexedDB.open(dbName, version);

  request.onupgradeneeded = function(event) {
    const database = event.target.result;
    storeSchemas.forEach(storeSchema => {
      if (!database.objectStoreNames.contains(storeSchema.store)) {
        const objectStore = database.createObjectStore(storeSchema.store, storeSchema.storeConfig);
        storeSchema.storeSchema.forEach((schema) => {
          objectStore.createIndex(schema.name, schema.keypath, schema.options);
        });
      }
    });
    database.close();
  };
  request.onsuccess = function(e) {
    e.target.result.close();
  };
}

function onErrorInitNewDb(func) {
  return async function (...args) {
    if (dbState.inUse) return;
    try {
      return await func(...args);
    } catch (e) {
      dbState.inUse = true;
      console.warn('Broken DB. try to init again');
      const req = indexedDB.deleteDatabase(DBService.name);
      req.onsuccess = () => {
        console.warn('DELETED broken DB');
        CreateObjectStore(DBService.name, DBService.version, DBService.objectStoresMeta);
        console.warn('reinited db');
        dbState.inUse = false;

      }
    }
  }
}

export const getRecord = onErrorInitNewDb(async function (diagramId) {
  const { getByID } = useIndexedDB('diagrams');
  return await getByID(diagramId);
});

export function addRecord(record) {
  const { add } = useIndexedDB('diagrams');
  onErrorInitNewDb(add)(record).then(
      () => {
        console.log('Created');
      },
      error => {
        console.log('on add record');
        console.log(error);
      }
    );
}

export function updateRecord(record) {
  const { update } = useIndexedDB('diagrams');
  onErrorInitNewDb(update)(record).then(
      () => { console.log("saved") },
      error => {
        console.log('on update record')
        console.log(error);
      }
    );
}

export function deleteRecord(diagramId) {
  const { deleteRecord } = useIndexedDB('diagrams');
  onErrorInitNewDb(deleteRecord)(diagramId).then(() => {
      console.log('Deleted!');
  }).catch(e => {
    console.log('on delete record')
    console.log(e);
  });
}
