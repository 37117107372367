import React from 'react';
import createReactClass from 'create-react-class';
import { connect } from 'react-redux';
import TextareaAutosize from 'react-autosize-textarea';
import { uploadFile } from '../utils/storage';

const AttachmentIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden="true"
    role="img"
    width="18"
    height="18"
    preserveAspectRatio="xMidYMid meet"
    viewBox="0 0 20 20"
  >
    <path
      fill="#000"
      d="M5.602 19.8c-1.293 0-2.504-.555-3.378-1.44c-1.695-1.716-2.167-4.711.209-7.116l9.748-9.87c.988-1 2.245-1.387 3.448-1.06c1.183.32 2.151 1.301 2.468 2.498c.322 1.22-.059 2.493-1.046 3.493l-9.323 9.44c-.532.539-1.134.858-1.738.922c-.599.064-1.17-.13-1.57-.535c-.724-.736-.828-2.117.378-3.337l6.548-6.63c.269-.272.705-.272.974 0s.269.714 0 .986l-6.549 6.631c-.566.572-.618 1.119-.377 1.364c.106.106.266.155.451.134c.283-.029.606-.216.909-.521l9.323-9.439c.64-.648.885-1.41.69-2.145a2.162 2.162 0 0 0-1.493-1.513c-.726-.197-1.48.052-2.12.7l-9.748 9.87c-1.816 1.839-1.381 3.956-.209 5.143c1.173 1.187 3.262 1.629 5.079-.212l9.748-9.87a.683.683 0 0 1 .974 0a.704.704 0 0 1 0 .987L9.25 18.15c-1.149 1.162-2.436 1.65-3.648 1.65z"
    />
  </svg>
);

var ReplyBox = createReactClass({
  getInitialState: function () {
    return { islocked: false, lockDuration: 0 };
  },

  componentDidMount: function () {
    this.timer = setInterval(this.checkLock, 1000);
  },

  componentWillUnmount() {
    clearInterval(this.timer);
  },

  checkLock: function () {
    // console.log('ReplyBox.checkLock');
    var now = Math.floor(Date.now() / 1000);
    var newState = { islocked: false, lockDuration: 0 };
    if (!isNaN(this.props.lockExpirationTime) && now < this.props.lockExpirationTime) {
      newState = { islocked: true, lockDuration: Math.round(this.props.lockExpirationTime - now) };
    }
    // console.log('ReplyBox.checkLock', this.state, newState);
    if (newState.islocked != this.state.islocked || newState.lockDuration != this.state.lockDuration) {
      // console.log('@ReplyBox.checkLock changing state');
      this.setState(newState);
    }
  },

  componentDidUpdate: function (prevProps) {
    this.checkLock();
  },

  sendMessage: function () {
    if (this.props.message.length === 0 || !this.props.message.trim()) {
      return;
    }
    if (this.state.islocked) {
      // console.log('ReplyBox.sendMessage exits due lock');
      return;
    }
    console.log('LOgged in user in reply box: ', this.props.loggedInUser);
    var message = {
      type: 'elisa',
      time: Math.floor(Date.now() / 1000),
      text: this.props.message,
      userId: this.props.userId,
      chatId: this.props.chatId,
      supportPerson: this.props.loggedInUser,
      messageType: this.props.messageType,
    };
    this.props.clearMessage(null, this.props.loggedInUser);
    this.props.addMessage(message);
    this.props.changeSupportPerson();
    let socket = this.props.socket;
    socket.emit('helpdesk message', message);
  },

  sendFile: function (e) {
    e.stopPropagation();
    const file = e.target.files[0];
    if (file) {
      if (!['image/png', 'application/pdf'].includes(file.type)) return;
      if (file.size > 9999999999) return;
      uploadFile(this.props.mode, file).then(filename => {
        this.props.socket.emit('helpdesk attachment', {
          text: file.name,
          url: filename,
          userId: this.props.userId,
          supportPerson: this.props.loggedInUser,
          messageType: this.props.messageType,
        });
      });
    }
  },

  handleKeyPress: function (event) {
    if (event.key === 'Enter') {
      /* If enter pressed send message*/
      event.preventDefault();
      if (this.props.message.length === 0 || !this.props.message.trim()) {
        return;
      }
      this.sendMessage();
    }
    if (event.keyCode == 32) {
      /* If spacebar pressed get likely answers */
      this.props.newAutocompletes(this.props.message);
    }
  },

  render: function () {
    // console.log('@ReplyBox.render:', this.props.chatId, this.state.islocked, this.props.lockExpirationTime);
    var className = 'row reply' + (this.state.islocked ? ' locked' : '');
    var buttonClassName = 'fa ' + (this.state.islocked ? ' fa-hourglass-half' : 'fa-send') + ' fa-2x';
    var timerText = this.state.islocked ? this.state.lockDuration.toString() : '';
    return (
      <div className={className}>
        <div className="col-sm-11 col-xs-11 reply-main">
          <TextareaAutosize
            dir="auto"
            id="replyBox"
            className="form-control"
            spellCheck="true"
            maxLength="1000"
            lang="et"
            value={this.props.message}
            onKeyDown={this.handleKeyPress}
            onChange={ev => this.props.changeMessage(ev, this.props.loggedInUser)}
          ></TextareaAutosize>
          {this.props.chatId && !this.props?.message?.length && (
            <>
              <input
                onChange={this.sendFile}
                style={{ display: 'none' }}
                id="inputFile"
                name="inputFile"
                type="file"
                accept=".jpg,.jpeg,.png,.pdf"
              />
              <label
                htmlFor="inputFile"
                style={{ position: 'absolute', right: 12, top: 10, cursor: 'pointer', 'z-index': 9999 }}
              >
                {AttachmentIcon}
              </label>
            </>
          )}
        </div>
        <div className="col-sm-1 col-xs-1 reply-send" onClick={this.sendMessage}>
          <i className={buttonClassName} aria-hidden="true">
            {timerText}
          </i>
        </div>
      </div>
    );
  },
});

/* What attributes from the global state to connect to replybox */
const mapStateToProps = function (store) {
  // console.log('@ReplyBox.mapStateToProps. chatId:', store.appState.chatId, ' lockExpirationTime', store.appState.lockExpirationTime);
  // this.state = {islocked: !isNaN(store.appState.lockExpirationTime) && ((new Date() / 1000) < store.appState.lockExpirationTime)};
  return {
    mode: store.appState.mode,
    socket: store.appState.socket,
    message: store.messageState.message,
    messageType: store.messageState.messageType,
    chatId: store.appState.chatId,
    userId: store.appState.userId,
    lockExpirationTime: store.appState.lockExpirationTime,
  };
};

/* What update actions to the global state replybox should have */
const mapDispatchToProps = dispatch => {
  return {
    changeMessage: (event, loggedInUser) => {
      dispatch({
        type: 'UPDATE_MESSAGE',
        message: event.target.value,
        loggedInUser,
      });
    },
    clearMessage: (event, loggedInUser) =>
      dispatch({
        type: 'UPDATE_MESSAGE',
        message: '',
        loggedInUser,
      }),
    newAutocompletes: message =>
      dispatch({
        type: 'LEMMATIZE_TEXT',
        message: message,
      }),
    addMessage: message =>
      dispatch({
        type: 'ADD_MESSAGE',
        message: message,
      }),
    changeSupportPerson: () =>
      dispatch({
        type: 'CHANGE_SUPPORT_PERSON',
        supportPerson: window.loggedInUser,
      }),
  };
};

/* Export the connected ReplyBox component */
export default connect(mapStateToProps, mapDispatchToProps)(ReplyBox);
