import React from 'react';

import './common.scss';

const Select = ({ label, placeholder, value, onChange, options }) => {
  return (
    <div className="input-container">
      <p className="input-label">{label}</p>
      <select className="select" placeholder={placeholder} value={value} onChange={onChange}>
        {options.map(option => (
          <option value={option.value}>{option.name}</option>
        ))}
      </select>
    </div>
  );
};

export default Select;
