import JSON5 from "json5";
import { useState, useEffect } from "react";

export function isJson(str) {
  try {
    JSON5.parse(str);
  } catch (e) {
    return false;
  }
  return true;
}

export function useLoading(readyText, isReady) {
  const getText = (currentText) => {
    if (isReady) {
      return readyText;
    }
    if (currentText === 'loading') {
      return 'loading.';
    }
    if (currentText === 'loading.') {
      return "loading..";
    }
    if (currentText === 'loading..') {
      return "loading..."
    }
    if (currentText === 'loading...') {
      return 'loading';
    }
  }
  const [text, setText] = useState(getText('loading...'))

  useEffect(() => {
    let timeout = null;
    if (isReady) {
      setText(getText(text));
    } else {
      timeout = setTimeout(() => {
        setText(getText(text));
        timeout = null;
      }, 400);
      return () => {
        if (timeout) {
          clearTimeout(timeout);
        }
      }
    }
  }, [text, isReady]);

  return text;
}