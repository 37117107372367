import React from 'react';
// import {connect} from "react-redux";

import './LabellingChat.scss';
import Message from './message/Message';

const LabellingChat = ({ chat, labels, addLabelsModal, updateMessageLangLabel, labelMode }) => {
  return (
    <div className="chat">
      {chat.messages && chat.messages.length > 0 ? (
        chat.messages
          .filter(message => message.role === 'client' || message.message)
          .map(message => (
            <Message
              key={message.id}
              updateMessageLangLabel={updateMessageLangLabel}
              addLabelsModal={addLabelsModal}
              labels={labels}
              chat={chat}
              message={message}
              labelMode={labelMode}
            />
          ))
      ) : (
        <p>No messages found</p>
      )}
    </div>
  );
};

// export const mapStateToProps = state => {
//     return {
//     };
// };
//
// const mapDispatchToProps = {
// };

// connect(mapStateToProps, mapDispatchToProps)
export default LabellingChat;
