import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import withChatDraft from '../utils/chat-draft/withChatDraft';
import { withRoles } from '../utils/roles/withRoles';
import { Input, Tooltip, DatePicker, Button } from 'antd';
import { InfoCircleOutlined, DownloadOutlined } from '@ant-design/icons';
import chatsActions from '../../action-creators/chatsActions';
import moment from 'moment';

const { RangePicker } = DatePicker;

const Export = ({ exportChatContexts }) => {
  const [contextKey, setContextKey] = useState('');
  const [dateRange, setDateRange] = useState([moment().subtract('1', 'months'), moment()]);

  return (
    <div style={{ height: '100%', padding: '80px 30px' }}>
      <div style={{ display: 'flex' }}>
        <Input
          style={{ width: 200 }}
          onChange={ev => setContextKey(ev.target.value)}
          value={contextKey}
          placeholder="Context key"
          suffix={
            <Tooltip title="Enter any context key here that you have used in Solution Flows">
              <InfoCircleOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
            </Tooltip>
          }
        />
        <RangePicker value={dateRange} onChange={setDateRange} />
        <Button
          disabled={!(contextKey && dateRange?.length === 2)}
          onClick={() =>
            exportChatContexts(contextKey, [
              moment(dateRange[0]).toDate().toISOString(),
              moment(dateRange[1]).toDate().toISOString(),
            ])
          }
          style={{ marginLeft: 20 }}
          type="primary"
          icon={<DownloadOutlined />}
        >
          Download as CSV
        </Button>
      </div>
    </div>
  );
};

export const mapStateToProps = state => {
  return {};
};

const mapDispatchToProps = {
  exportChatContexts: chatsActions.exportChatContexts,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRoles(['product_manager', 'admin'], withChatDraft(withRouter(Export))));
