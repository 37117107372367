/*
This module houses the global state of the application.
All communication and data required by multiple components should be handled here.
*/

import { createStore, combineReducers } from 'redux';
import Notifier from 'react-desktop-notification';
import _ from 'lodash';
import NOTIFICATION_AUDIO from './notification.mp3';
const audio = new Audio(NOTIFICATION_AUDIO);

if (Notification.permission !== 'granted') {
  Notification.requestPermission();
}

function jaccardIndex(text1, text2) {
  let words1 = text1;
  let words2 = text2;
  return _.intersection(words1, words2).length / _.union(words1, words2).length;
}

const initialMessageState = {
  /* Initial state is empty. The intended use is to rehydrate this from some storage when user refreshes. */
  message: [],
  likelyAnswers: [],
  allAnswers: [],
  messageType: '',
};

const SEGMENTS = JSON.parse(process.env.SEGMENTS || '["Anonymous", "Logged-in"]');
const initSegmentFilter = () => {
  let filter = {};
  SEGMENTS.forEach(seg => (filter[seg] = true));
  return filter;
};

const initialappState = {
  userName: '',
  userId: '',
  chatId: '',
  messages: [],
  elisaCustomerId: '',
  clientAvatar: '',
  entities: [],
  supportPerson: '',
  solutionl1: '',
  solutionl2: '',
  solutionl3: '',
  availProblems: [],
  l1Selectables: [],
  l2Selectables: [],
  l3Selectables: [],
  thisTyping: false,
  textFieldContents: '',
  availChats: [],
  recommendations: [],
  supportActive: true,
  someoneTyping: [],
  spectator: localStorage.getItem('liveChatSpectatorMode') === 'true',
  // show_private_customers: true,
  // show_organization_customers: true,
  // show_public_customers: true,
  supportList: [],
  userData: {}, // Store facts about the user here. Used to replace placeholders in answers.
  lockExpirationTime: NaN,
  ttl_code_required: true,
  links: [],
  socket: null,
  mode: '',
  segmentFilter: initSegmentFilter(),
};

const emptyChatState = {
  mode: '',
  userName: '',
  userId: '',
  chatId: '',
  messages: [],
  elisaCustomerId: '',
  clientAvatar: '',
  entities: [],
  supportPerson: '',
  solutionl1: '',
  solutionl2: '',
  solutionl3: '',
  thisTyping: false,
  textFieldContents: '',
  recommendations: [],
  someoneTyping: [],
  userData: {},
  lockExpirationTime: NaN,
  ttl_code_required: true,
  links: [],
};

const messageReducer = function (state = initialMessageState, action) {
  /*
  Handles updating the message from Elisa.
  Gets updated when Elisa types something or bot solution chosen.
  */
  switch (action.type) {
    case 'BOT_SOLUTION_CHOSEN':
      document.getElementById('replyBox').focus();
      return Object.assign({}, state, { message: action.message, messageType: ['bot', action.problemCode].join(':') });
    case 'AUTOCOMPLETE_CHOSEN':
      document.getElementById('replyBox').focus();
      return Object.assign({}, state, {
        message: action.message,
        messageType: ['autocomplete', action.problemCode].join(':'),
      });
    case 'UPDATE_MESSAGE':
      /* Store current message in state. */
      return Object.assign({}, state, { message: action.message, messageType: 'typed' });
    case 'AVAIL_RESPONSES':
      console.log(action);
      return Object.assign({}, state, { allAnswers: action.responses });
    case 'LEMMATIZE_TEXT':
      console.log(action);
      let socket = state.socket;
      socket.emit('lemmatize this', { text: action.message });
      return state;
    case 'LEMMATIZED_ANSWER':
      console.log(action);
      console.log(state.allAnswers);
      state.allAnswers.forEach(function (answer) {
        answer.score = jaccardIndex(action.answer, answer.answerLemmatized);
      });
      console.log(state.allAnswers);
      return Object.assign({}, state, {
        likelyAnswers: state.allAnswers
          .sort(function (a, b) {
            return b.score - a.score;
          })
          .slice(0, 3),
      });
    default:
      return state;
  }
};

function appendProblemTreeItems(tree, key, collection, key_prefix, label_prefix) {
  // console.log('appendProblemTreeItems', tree)
  if (tree === undefined || !tree[key] || tree[key].length === 0) return;
  for (var i = 0; i < tree[key].length; i++) {
    var child = tree[key][i];
    collection.push({ value: key_prefix + child['value'], label: label_prefix + child['label'] });
    appendProblemTreeItems(
      tree[child['value']],
      key,
      collection,
      key_prefix + child['value'] + '-',
      label_prefix + child['label'] + ' / '
    );
  }
}

function flatProblemTree(data) {
  // console.log('Flattening', data)
  var result = [];
  appendProblemTreeItems(data, 'selectables', result, '', '');
  // console.log('Flattened', result)
  return result;
}

const appReducer = function (state = initialappState, action) {
  /*
  Handles updating information about the current user.
  */
  switch (action.type) {
    case 'UPDATE_MESSAGE': {
      let socket = state.socket;
      /* Emit typing if writing a message, not typing if erased message. */
      if ((action.message != '') & !state.thisTyping) {
        socket.emit('typing', { chatId: state.chatId, supportPerson: action.loggedInUser });
        return Object.assign({}, state, { thisTyping: true });
      } else if (action.message == '') {
        socket.emit('not typing', { chatId: state.chatId, supportPerson: action.loggedInUser });
        return Object.assign({}, state, { thisTyping: false });
      }
      return state;
    }
    case 'L1_CHOSEN': {
      console.log(action);
      let socket = state.socket;
      socket.emit('custom solution chosen', {
        problemCode: action.problemCode,
        userId: state.userId,
        chatId: state.chatId,
      });
      return Object.assign({}, state, {
        solutionl1: action.problemCode,
        solutionl2: '',
        solutionl3: '',
        l2Selectables: state.availProblems[action.problemCode]?.selectables,
        solution: action.problemCode,
      });
    }
    case 'L2_CHOSEN': {
      console.log(action);
      var solution = [state.solutionl1, action.problemCode].join('-');
      let socket = state.socket;
      socket.emit('custom solution chosen', { problemCode: solution, userId: state.userId, chatId: state.chatId });
      return Object.assign({}, state, {
        solutionl2: action.problemCode,
        solutionl3: '',
        l3Selectables: state.availProblems[state.solutionl1][action.problemCode]?.selectables,
        solution: solution,
      });
    }
    case 'L3_CHOSEN': {
      console.log(action);
      var solution = [state.solutionl1, state.solutionl2, action.problemCode].join('-');
      let socket = state.socket;
      socket.emit('custom solution chosen', { problemCode: solution, userId: state.userId, chatId: state.chatId });
      return Object.assign({}, state, { solutionl3: action.problemCode, solution: solution });
    }
    case 'L1_TO_3_CHOSEN': {
      console.log(action);
      let socket = state.socket;
      socket.emit('custom solution chosen', {
        problemCode: action.problemCode,
        userId: state.userId,
        chatId: state.chatId,
      });
      var splitSolution = action.problemCode.split('-');
      var sp = splitSolution.length; // solution parts
      return Object.assign({}, state, {
        solution: action.problemCode,
        solutionl1: sp > 0 ? splitSolution[0] : undefined,
        solutionl2: sp > 1 ? splitSolution[1] : undefined,
        solutionl3: sp > 2 ? splitSolution[2] : undefined,
        l2Selectables: sp > 0 ? state.availProblems[splitSolution[0]]?.selectables : undefined,
        l3Selectables: sp > 1 ? state.availProblems[splitSolution[0]][splitSolution[1]]?.selectables : undefined,
      });
    }
    case 'BOT_SOLUTION_CHOSEN': {
      console.log(action);
      let socket = state.socket;
      socket.emit('bot solution chosen', {
        problemCode: action.problemCode,
        userId: state.userId,
        message: action.message,
        chatId: state.chatId,
      });
      return Object.assign({}, state, { solutionChosen: action.problemCode });
    }
    case 'NEW_CHAT': {
      let socket = state.socket;
      console.log(action);
      /* When user selects a chat from ConversationList, the TextArea gets populated.
         To populate ExtraInformation emits requesting additional information are dispatched.*/
      socket.emit('set_chats_helpdesk_state', {
        chatIds: [action.chatId],
        state: 'seen',
      });
      return Object.assign({}, state, {
        chatId: action.chatId,
        userId: action.userId,
        elisaCustomerId: action.elisaCustomerId,
        elisaCustomerCode: action.elisaCustomerCode,
        messages: action.messages,
        userName: action.userName,
        clientAvatar: action.clientAvatar,
        supportPerson: action.supportPerson,
        someoneTyping: action.someoneTyping,
        textFieldContents: action.textFieldContents ? action.textFieldContents : ' ',
        recommendations: action.recommendations ? action.recommendations : [],
        customer_segment: action.customer_segment,
        lockExpirationTime: action.lockExpirationTime,
        dialog_failed: action.dialog_failed,
        ttl_code_required: action.ttl_code_required,
        links: action.links,
      });
    }
    case 'ADD_INCOMING_MESSAGE':
      console.log(action);
      /* Add Client message to textArea if sent by the active client */
      if (action.message.type !== 'elisa')
        state.socket.emit('set_chats_helpdesk_state', {
          chatIds: [action.message.chatId],
          state: action.message.chatId === state.chatId ? 'seen' : 'delivered',
        });
      state = Object.assign({}, state, {
        availChats: state.availChats.map(function (chat) {
          if (chat.chatId === action.message.chatId && action.message.chatId !== state.chatId) {
            return Object.assign({}, chat, { seen: 'notSeen' });
          } else return chat;
        }),
      });
      if (action.message.chatId != state.chatId) return state;
      else return Object.assign({}, state, { messages: state.messages.concat([action.message]) });

    case 'NEW_ENTITIES':
      console.log(action);
      if (action.chatId != state.chatId) {
        return state;
      } else {
        return Object.assign({}, state, { entities: action.entities });
      }
    case 'CHAT_SELECTED': {
      console.log(action);
      let socket = state.socket;
      socket.emit('get messages', {
        chatId: action.chatId,
        userId: action.userId,
        supportPerson: action.supportPerson,
      });
      let splitSolution = [undefined, undefined, undefined];
      let l2Selectables = undefined;
      let l3Selectables = undefined;
      if (action.solutionChosen && state.availProblems) {
        splitSolution = action.solutionChosen.split('-');
        l2Selectables = state.availProblems[splitSolution[0]]?.selectables;
        // console.log(splitSolution[0], l2Selectables, splitSolution[1])
        if (splitSolution.length > 1 && state.availProblems[splitSolution[0]]?.[splitSolution[1]])
          l3Selectables = state.availProblems[splitSolution[0]]?.[splitSolution[1]]?.selectables;
      }
      return Object.assign({}, state, emptyChatState, {
        socket,
        mode: state.mode,
        solutionl1: splitSolution[0],
        solutionl2: splitSolution[1],
        solutionl3: splitSolution[2],
        solution: action.solutionChosen,
        l2Selectables: l2Selectables,
        l3Selectables: l3Selectables,
        availChats: state.availChats.map(function (chat) {
          if (chat.chatId !== action.chatId) return chat;
          else return Object.assign({}, chat, { seen: 'seen' });
        }),
      });
    }
    case 'POPULATE_PROBLEMS':
      console.log(action);
      return Object.assign({}, state, {
        availProblems: action.availProblems,
        l1Selectables: action.availProblems?.selectables,
        availProblemsFlat: flatProblemTree(action.availProblems),
      });
    case 'UPDATE_TEXT_FIELD_CONTENTS': {
      /* Necessary for the freetextfield in bot attributes. Currently not in use.*/
      console.log(action);
      let socket = state.socket;
      socket.emit('free text', { textFieldContents: action.message, chatId: action.chatId });
      return Object.assign({}, state, { textFieldContents: action.message });
    }
    case 'CHANGE_SUPPORT_PERSON':
      console.log(action);
      return Object.assign({}, state, { supportPerson: action.supportPerson });

    case 'POPULATE_CHATLIST':
      /* On application load, the ConversationList gets populated here.*/
      state.socket.emit('set_chats_helpdesk_state', {
        chatIds: action.availChats.map((c) => c.chatId),
        state: 'delivered',
      });
      return Object.assign({}, state, { availChats: action.availChats });

    case 'NEW_RECOMMENDATIONS':
      /* Based on user written text, bot recommends some answers.
         There are added to state here, if relevant to current user. */
      console.log(action);
      if (action.chatId != state.chatId) {
        return state;
      } else {
        return Object.assign({}, state, { recommendations: action.recommendations });
      }

    case 'RESOLVE_TOGGLED': {
      /*
      When Elisa clicks on the checkmark:
      - server is informed that the chat is resolved
      - chat status in the ConversationList is set to 'Resolved'
      */
      console.log(action);
      var actionChat = state.availChats.filter(function (chat) {
        return chat.chatId === action.chatId;
      })[0];
      // console.log('Chat being modified', actionChat)
      // if (actionChat.ttl_code_required){
      //   if (!actionChat.resolved && !actionChat.ttl_code_chosen) {
      //     sweetAlert({title:'Palun vali lahendusekood enne vestluse lahendatuks markeerimist.'})
      //     return state
      //   }
      //   var ttl_code_parts = actionChat.ttl_code_chosen.split('-')

      //   var l2Selectables = state.availProblems[ttl_code_parts[0]]?.selectables;
      //   // console.log('L2 selectables for', ttl_code_parts, l2Selectables)
      //   if (!actionChat.resolved && ttl_code_parts.length < 2 && l2Selectables.length > 0){
      //     sweetAlert({title:'Palun vali lahendusekoodi kõik tasemed enne vestluse lahendatuks markeerimist.'})
      //     return state
      //   }

      //   var l3Selectables = state.availProblems[ttl_code_parts[0]][ttl_code_parts[1]]?.selectables;
      //   // console.log('L3 selectables for', ttl_code_parts, l3Selectables)
      //   if (!actionChat.resolved && ttl_code_parts.length < 3 && l3Selectables.length > 0){
      //     sweetAlert({title:'Palun vali lahendusekoodi kõik tasemed enne vestluse lahendatuks markeerimist.'})
      //     return state
      //   }
      // }
      let socket = state.socket;
      socket.emit('chat resolved', { chatId: action.chatId });
      return Object.assign({}, state, {
        availChats: state.availChats.map(function (chat) {
          if (chat.chatId != action.chatId) return chat;
          else return Object.assign({}, chat, { resolved: !chat.resolved });
        }),
      });
    }
    case 'CHAT_CLOSED': {
      /* Chat is marked as irrelevant to the API.*/
      console.log(action);
      let socket = state.socket;
      socket.emit('chat closed', { chatId: action.chatId, userId: action.userId });
      return state;
    }
    case 'CHAT_CHANGED': {
      console.log(action);
      let socket = state.socket;
      if (action.changeType === 'user message') {
        if (decideNotify(action, state)) notify(action.chatElement);

        return Object.assign({}, state, {
          lockExpirationTime:
            action.chatElement.chatId === state.chatId
              ? action.chatElement.lockExpirationTime
              : state.lockExpirationTime,
          availChats: [action.chatElement].concat(
            state.availChats.filter(function (chat) {
              return chat.chatId !== action.chatElement.chatId;
            })
          ),
        });
      } else if (action.changeType === 'chat seen') {
        PageTitleNotification.Off();
        return Object.assign({}, state, {
          lockExpirationTime:
            action.chatElement.chatId === state.chatId
              ? action.chatElement.lockExpirationTime
              : state.lockExpirationTime,
          availChats: state.availChats.map(function (chat) {
            if (chat.chatId === action.chatElement.chatId) return action.chatElement;
            else return chat;
          }),
        });
      } else if (action.changeType === 'support typing') {
        if (action.chatElement.chatId === state.chatId) {
          return Object.assign({}, state, { someoneTyping: action.chatElement.somebodyTyping });
        }
      } else if (action.changeType === 'support not typing') {
        if (action.chatElement.chatId === state.chatId) {
          return Object.assign({}, state, { someoneTyping: action.chatElement.somebodyTyping });
        }
      } else {
        if (
          action.changeType === 'chat support person' &&
          action.chatElement.isSysHandover &&
          !action.chatElement.supportPerson &&
          action.isResponsibleForChat
        ) {
          notify(action.chatElement);
        } else if (decideNotify(action, state)) notify(action.chatElement);
        return Object.assign({}, state, {
          lockExpirationTime:
            action.chatElement.chatId === state.chatId
              ? action.chatElement.lockExpirationTime
              : state.lockExpirationTime,
          availChats: state.availChats.map(function (chat) {
            if (chat.chatId === action.chatElement.chatId) return action.chatElement;
            else return chat;
          }),
        });
      }
    }
    case 'IdleMonitor_idle':
      // console.log(action)
      return Object.assign({}, state, { supportActive: false });
    case 'IdleMonitor_active':
      // console.log(action)
      return Object.assign({}, state, { supportActive: true });
    case 'SPECTATOR_TOGGLED':
      let socket = state.socket;
      socket.emit('set spectator mode', { spectator: !state.spectator });
    // return Object.assign({}, state, { spectator: !state.spectator });
    case 'TOGGLE_SEGMENT_FILTER':
      let newState = Object.assign({}, state);
      newState.segmentFilter[action.segment] = !newState.segmentFilter[action.segment];
      console.log(newState.segmentFilter);
      return newState;
    case 'SUPPORT_LIST':
      console.log('SUPPORT LIST ACTION: ', action);
      const currentRecord = action.list?.find(x => x.username === action.loggedInUser);
      const currentSpectator = currentRecord ? currentRecord.spectator : state.spectator;
      localStorage.setItem('liveChatSpectatorMode', currentSpectator);
      return Object.assign({}, state, { spectator: currentSpectator, supportList: action.list });
    case 'RESET_STATE':
      return initialappState;
    case 'set socket':
      console.log(action);
      if (state.spectator) {
        action.socket.emit('set spectator mode', { spectator: state.spectator });
      }
      return Object.assign({}, state, { socket: action.socket });
    case 'set mode':
      console.log(action);
      return Object.assign({}, state, { mode: action.mode });
    default:
      return state;
  }
};

const reducers = combineReducers({
  messageState: messageReducer,
  appState: appReducer,
});

var PageTitleNotification = {
  Vars: {
    OriginalTitle: document.title,
    Interval: null,
  },
  On: function (notification, intervalSpeed) {
    var _this = this;
    if (_this.Vars.Interval) return;
    _this.Vars.Interval = setInterval(
      function () {
        document.title = _this.Vars.OriginalTitle == document.title ? notification : _this.Vars.OriginalTitle;
      },
      intervalSpeed ? intervalSpeed : 1000
    );
  },
  Off: function () {
    clearInterval(this.Vars.Interval);
    this.Vars.Interval = null;
    document.title = this.Vars.OriginalTitle;
  },
};

function decideNotify(action, state) {
  const message = action.chatElement;
  const loggedInUser = action.loggedInUser;
  const isResponsibleForChat = action.isResponsibleForChat;
  /* Decide if necessary to fire a notification*/
  if (
    message.seen === 'notSeen' &&
    (message.supportPerson === loggedInUser || message.needs_attention) &&
    isResponsibleForChat
  )
    return true;
  // if (!!state.supportActive) return false;

  // if (!(message.supportPerson == undefined || message.supportPerson == loggedInUser)) return false;

  // for (let key of Object.keys(state.segmentFilter)) {
  //   if (state.segmentFilter[key] && message.customer_segment == key) {
  //     return true;
  //   }
  // }
  return false;
}

function notify(message) {
  /* Creates necessary notifications upon incoming message */
  Notifier.start(
    'New message',
    'From: ' + message.userName + '\n"' + message.latestMessage + '"',
    '',
    'elisa_static/images/elisa-logo.png'
  );
  audio.play();
  PageTitleNotification.On('New messages!');
}

window.PageTitleNotification = PageTitleNotification;

document.addEventListener('mouseleave', function (e) {
  store.dispatch({ type: 'IdleMonitor_idle' });
});
document.addEventListener('mouseenter', function (e) {
  store.dispatch({ type: 'IdleMonitor_active' });
});

const store = createStore(reducers);
const draftStore = createStore(reducers);

const getStore = mode => (mode === 'draft' ? draftStore : store);
window.store = store;

export { getStore };
