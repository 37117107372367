import initialState from '../constants/initial-state-parts/initialState';

const appReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'NEVER':
      return state;
    default:
      return state;
  }
};

export default appReducer;
