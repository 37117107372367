const fetchUsersList = () => {
  return {
    type: 'FETCH_USERS_LIST',
  };
};

const updateUsersList = newUsersList => {
  return {
    type: 'UPDATE_USERS_LIST',
    newUsersList: newUsersList,
  };
};

const createUser = user => {
  return {
    type: 'CREATE_USER',
    user,
  };
};

const updateUser = (id, user, currentUser) => {
  return {
    type: 'UPDATE_USER',
    user,
    currentUser
  };
};

const deleteUser = id => {
  return {
    type: 'DELETE_USER',
    id: id,
  };
};

const usersActions = {
  fetchUsersList,
  updateUsersList,
  createUser,
  updateUser,
  deleteUser,
};

export default usersActions;
