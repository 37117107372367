import React from 'react';

const LogicPythonIcon = ({ color }) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12 7.75C12.4142 7.75 12.75 7.41421 12.75 7V6C12.75 5.58579 12.4142 5.25 12 5.25H7.75V5C7.75 3.20507 9.20507 1.75 11 1.75H12C13.7949 1.75 15.25 3.20507 15.25 5V7V7.5C15.25 9.29493 13.7949 10.75 12 10.75H11C8.63185 10.75 6.66846 12.483 6.30888 14.75H6C4.20507 14.75 2.75 13.2949 2.75 11.5V11C2.75 9.20507 4.20507 7.75 6 7.75H12Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 16.25C11.5858 16.25 11.25 16.5858 11.25 17V18C11.25 18.4142 11.5858 18.75 12 18.75H16.25V19C16.25 20.7949 14.7949 22.25 13 22.25H12C10.2051 22.25 8.75 20.7949 8.75 19V17V16.5C8.75 14.7051 10.2051 13.25 12 13.25H13C15.3682 13.25 17.3315 11.517 17.6911 9.25H18C19.7949 9.25 21.25 10.7051 21.25 12.5V13C21.25 14.7949 19.7949 16.25 18 16.25H12Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default LogicPythonIcon;
