import React, {useEffect} from "react";
import styled from "styled-components";
import * as mixins from "../../../../../app/components/callbot-metrics/styles/mixins";
import TTSService from "../../../../services/TTSService";
import AudioPlayer from "../../../audio/AudioPlayer";

const Wrapper = styled.div`
  ${mixins.flex()}
`

const Audio = ({value, currentElement, modeling}) => {
  useEffect(() => {
    if (currentElement.businessObject && currentElement.businessObject.promptId) {
      TTSService.getPrompt(currentElement.businessObject.promptId).then(({data}) => {
        modeling.updateProperties(currentElement, {
          promptUrl: data.url,
          clipDuration: data.duration,
        })
      })
    }
  }, [currentElement.businessObject.promptId])

  const handleSynthesize = () => {
    TTSService.synthesizeDynamic(
      currentElement.businessObject.template,
      currentElement.businessObject.values
    ).then(({data}) => {
      modeling.updateProperties(currentElement, {
        promptUrl: data.url,
        clipDuration: data.duration,
      })
    });
  }

  return (
    <Wrapper>
      <AudioPlayer promptUrl={value}
                   handleSynthesize={currentElement.businessObject.file ? undefined : handleSynthesize}/>
    </Wrapper>
  )
}

export default Audio;
