import React from 'react';
import styled from 'styled-components';
import * as mixins from '../../../styles/mixins';
import * as typography from '../../../styles/typography';
import * as colors from '../../../styles/colors';

import ElementPropertyGenericIcon from './ElementPropertyGenericIcon';
import ElementPropertyGenericInput from './ElementPropertyGenericInput';
import ElementPropertyGenericValue from "./ElementPropertyGenericValue";

const ElementProp = styled.div`
  margin-bottom: 12px;
  width: 100%;
  ${mixins.flex('flex-start', 'flex-start')};
`;

const ElementPropInfo = styled.div`
  margin-left: 10px;
  flex: 1;
`;

const ElementPropWrapper = styled.div`
  width: 100%;
  ${mixins.flex('space-between')};
`;

const ElementPropLabel = styled.p`
  position: relative;
  margin-bottom: 2px;
  color: ${colors.mainFontColor};
  ${mixins.fontStyle(typography.smallLabelFontSize, typography.semibold, typography.smallLineHeight)};
  ${props => (props.label === 'choices' ? `top: 20px;` : '')};
  ${props => (props.isBlocked ? `color: ${colors.extraFontColorWithOpacity};` : '')};
`;

const ElementPropExpandButton = styled.div`
     margin-bottom: 2px;
    color: ${colors.mainFontColor};
    ${mixins.fontStyle(typography.smallLabelFontSize, typography.semibold, typography.smallLineHeight)};
    cursor: pointer;
    
    &:hover {
        color ${colors.alternativeFontColor};
    }
`;

const ElementPropLanguages = styled.div`
  ${mixins.flex('center', 'center')};
`;

const ElementPropLanguageToggle = styled.div`
  margin: 0 5px;

  &.left-rotate {
    transform: rotate(90deg);
  }

  &.right-rotate {
    transform: rotate(-90deg);
  }
`;

const ElementProperty = ({
  canvas,
  modeling,
  moddle,
  currentElement,
  index,
  elementPropKey,
  options,
  type,
  label,
  value,
  editable,
  values = [],
  onChangeEffect = () => {},
  isBlocked = false,
  extensionElements,
  owners,
  ownersList,
  ownersSearchString,
  fetchUsersList,
  updateOwnersSearchString,
  updateOwnersList,
  currentValue,
  changeCurrentValue,
  isOpen,
  changeIsOpen,
  isEditorExpanded,
  changeEditorExpandState,
  templateHeight,
  optionsKey
}) => {
  return (
    <ElementProp key={index}>
      <ElementPropertyGenericIcon
        type={type}
        applyTopPositioning={label === 'choices'}
        biggerTopPadding={editable && !(type === 'owners')}
        isBlocked={isBlocked}
      />
      <ElementPropInfo>
        <ElementPropWrapper>
          <ElementPropLabel label={label} isBlocked={isBlocked}>
            {label}
          </ElementPropLabel>
          {type === 'code' || type === 'expression' || type === 'template' ? (
            <ElementPropExpandButton onClick={changeEditorExpandState}>
              {isEditorExpanded ? 'collapse' : 'expand'}
            </ElementPropExpandButton>
          ) : (
            ''
          )}
        </ElementPropWrapper>

        {editable ? (
          <ElementPropertyGenericInput
            canvas={canvas}
            modeling={modeling}
            moddle={moddle}
            currentElement={currentElement}
            elementPropKey={elementPropKey}
            type={type}
            options={options}
            value={value}
            values={values}
            onChangeEffect={onChangeEffect}
            isBlocked={isBlocked}
            extensionElements={extensionElements}
            owners={owners}
            ownersList={ownersList}
            ownersSearchString={ownersSearchString}
            fetchUsersList={fetchUsersList}
            updateOwnersSearchString={updateOwnersSearchString}
            updateOwnersList={updateOwnersList}
            currentValue={currentValue}
            changeCurrentValue={changeCurrentValue}
            isOpen={isOpen}
            changeIsOpen={changeIsOpen}
            isEditorExpanded={isEditorExpanded}
            templateHeight={templateHeight}
            optionsKey={optionsKey}
          />
        ) : (
          <ElementPropertyGenericValue type={type} value={value} currentElement={currentElement} modeling={modeling} />
        )}
      </ElementPropInfo>
    </ElementProp>
  );
};

export default ElementProperty;
