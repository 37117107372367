import React from 'react';
import styled from 'styled-components';
import * as mixins from '../../../../styles/mixins';
import * as colors from '../../../../styles/colors';
import * as typography from '../../../../styles/typography';

import DiagramPropertiesLastVersions from './diagram-properties-panel-last-versions/DiagramPropertiesLastVersions';
import DiagramPropertiesOwner from './diagram-properties-panel-owner/DiagramPropertiesOwner';

const DiagramPropertiesExpandedArea = ({
  user,
  users,
  currentDiagram,
  ownersSearchString,
  currentDiagramVersionId,
  currentDiagramLastVersions,
  currentDiagramLiveVersion,
  isCurrentDiagramHasMoreVersions,
  changeDiagramVersionStatus,
  fetchUsersList,
  updateOwnersSearchString,
  updateCurrentDiagramOwner,
  deleteDiagramVersion,
  updateOwnersList,
  isCommentVisible,
  changeCurrentDiagramAndVersion,
  loadMoreCurrentDiagramVersions,
  showLessCurrentDiagramVersions,
  isOwnerSearchOpen,
  changeOwnerSearchOpen,
}) => {
  return (
    <>
      <DiagramPropertiesLastVersions
        currentDiagram={currentDiagram}
        currentDiagramVersionId={currentDiagramVersionId}
        currentDiagramLastVersions={currentDiagramLastVersions}
        deleteDiagramVersion={deleteDiagramVersion}
        isCurrentDiagramHasMoreVersions={isCurrentDiagramHasMoreVersions}
        currentDiagramLiveVersion={currentDiagramLiveVersion}
        changeCurrentDiagramAndVersion={changeCurrentDiagramAndVersion}
        loadMoreCurrentDiagramVersions={loadMoreCurrentDiagramVersions}
        showLessCurrentDiagramVersions={showLessCurrentDiagramVersions}
        changeDiagramVersionStatus={changeDiagramVersionStatus}
        isCommentVisible={isCommentVisible}
      />
      {currentDiagram.owner ? (
        <DiagramPropertiesOwner
          user={user}
          users={users}
          owner={currentDiagram.owner}
          ownersSearchString={ownersSearchString}
          isOwnerSearchOpen={isOwnerSearchOpen}
          changeOwnerSearchOpen={changeOwnerSearchOpen}
          fetchUsersList={fetchUsersList}
          updateOwnersSearchString={updateOwnersSearchString}
          updateCurrentDiagramOwner={updateCurrentDiagramOwner}
          updateOwnersList={updateOwnersList}
        />
      ) : (
        ''
      )}
    </>
  );
};

export default DiagramPropertiesExpandedArea;
