import {assign} from "min-dash";

const actionClick = (name, modeling) => (event, element) => {
  element.businessObject.name = name;
  modeling.updateProperties(element, element.businessObject);
}

export const flowActions = {
  timeout: {
    actionName: 'replace.sf-timeout',
    removeActionName: 'replace.sf-regular',
    className: 'timeout',
    removeClassName: 'no-timeout',
    title: 'Change to timeout',
    removeTitle: 'Change to regular',
    name: '<timeout>',
  },
  event: {
    actionName: 'replace.sf-event',
    removeActionName: 'replace.sf-regular',
    className: 'event',
    removeClassName: 'no-event',
    title: 'Change to event',
    removeTitle: 'Change to regular',
    name: '<event>'
  },
  main: {
    actionName: 'replace.sf-main',
    removeActionName: 'replace.sf-regular',
    className: 'main-branch',
    removeClassName: 'no-main-branch',
    title: 'Change to main',
    removeTitle: 'Change to regular',
    name: '<main>'
  }
}
export function assignAction(element, modeling, translate, actions, flowAction) {
  if (element.businessObject.name === flowAction.name) {
    assign(actions, {
      [flowAction.removeActionName]: {
        group: 'edit',
        className: flowAction.removeClassName,
        title: translate(flowAction.removeTitle),
        action: {
          click: actionClick('', modeling)
        }
      }
    });
  } else {
    assign(actions, {
      [flowAction.actionName]: {
        group: 'edit',
        className: flowAction.className,
        title: translate(flowAction.title),
        action: {
          click: actionClick(flowAction.name, modeling)
        }
      }
    });
  }
}