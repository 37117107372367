import { put, call, all, take, takeLatest, spawn } from 'redux-saga/effects';
import NotificationService from '../services/common/NotificationService';
import QualityAssessmentsService from '../services/QualityAssessmentsService';
import FailureReasonsService from '../services/FailureReasonsService';
import qualityAssessmentActions from '../action-creators/qualityAssessmentActions';

function* qualityAssessmentsListFlow(action) {
  try {
    console.log('ACTION: ', action);
    yield put(qualityAssessmentActions.setQualityAssessmentsLoading(true));
    yield put(
      qualityAssessmentActions.updateQualityAssessmentsList({
        qa_reasons: [],
        quality_assessments: [],
      })
    );
    const response = yield call(
      QualityAssessmentsService.getAssessmentsList,
      action.skip,
      action.limit,
      action.showMarkedChats,
      action.label,
      action.labelVersion,
      action.branch
    );
    yield put(qualityAssessmentActions.setQualityAssessmentsLoading(false));
    if (response.status === 200) {
      yield put(qualityAssessmentActions.updateQualityAssessmentsList(response.data));
    } else {
      NotificationService.push({
        type: 'error',
        message: response.data,
      });
    }
  } catch (error) {
    yield put(qualityAssessmentActions.setQualityAssessmentsLoading(false));
    NotificationService.push({
      type: 'error',
      message: 'Error fetching quality assessments',
    });
  }
}

function* fetchChatsByFailureReasonFlow(action) {
  try {
    console.log('ACTION: ', action);
    yield put(qualityAssessmentActions.setQualityAssessmentsLoading(true));
    // yield put(
    //   qualityAssessmentActions.updateQualityAssessmentsList({
    //     qa_reasons: [],
    //     quality_assessments: [],
    //   })
    // );
    const response = yield call(
      QualityAssessmentsService.getChatsByFailureReason,
      action.label,
      action.labelVersion,
      action.failureReason
    );
    yield put(qualityAssessmentActions.setQualityAssessmentsLoading(false));
    if (response.status === 200) {
      yield put(qualityAssessmentActions.updateChatsByFailureReasonList(response.data));
    } else {
      NotificationService.push({
        type: 'error',
        message: response.data,
      });
    }
  } catch (error) {
    yield put(qualityAssessmentActions.setQualityAssessmentsLoading(false));
    NotificationService.push({
      type: 'error',
      message: 'Error fetching quality assessments',
    });
  }
}

function* failureReasonCreateFlow(action) {
  try {
    const response = yield call(FailureReasonsService.createFailureReason, action);
    if (response.status === 200) {
      yield put(qualityAssessmentActions.addFailureReason(response.data));
    } else {
      NotificationService.push({
        type: 'error',
        message: response.data,
      });
    }
  } catch (error) {
    NotificationService.push({
      type: 'error',
    });
  }
}

function* failureReasonsListFlow() {
  try {
    const response = yield call(FailureReasonsService.getFailureReasonsList);
    if (response.status === 200) {
      yield put(qualityAssessmentActions.updateFailureReasonsList(response.data));
    } else {
      NotificationService.push({
        type: 'error',
        message: response.data,
      });
    }
  } catch (error) {
    NotificationService.push({
      type: 'error',
      message: 'Error fetching failure reasons',
    });
  }
}

function* createChatQualityAssessmentFlow(action) {
  try {
    const response = yield call(QualityAssessmentsService.createQualityAssessment, action);
    if (response.status === 200) {
      yield put(qualityAssessmentActions.addChatQualityAssessment(response.data));
    } else {
      NotificationService.push({
        type: 'error',
        message: response.data,
      });
    }
  } catch (error) {
    NotificationService.push({
      type: 'error',
    });
  }
}

function* updateChatQualityAssessmentFlow(action) {
  try {
    const response = yield call(QualityAssessmentsService.updateQualityAssessment, action);
    if (response.status === 200) {
      yield put(qualityAssessmentActions.addChatQualityAssessment(response.data));
    } else {
      NotificationService.push({
        type: 'error',
        message: response.data,
      });
    }
  } catch (error) {
    NotificationService.push({
      type: 'error',
    });
  }
}

function* deleteChatQualityAssessmentFlow(action) {
  try {
    const response = yield call(QualityAssessmentsService.deleteQualityAssessment, action);
    if (response.status === 200) {
      yield put(
        qualityAssessmentActions.removeChatQualityAssessment(
          response.data.id,
          response.data.failure_reason.reason,
          response.data.branches
        )
      );
    } else {
      NotificationService.push({
        type: 'error',
        message: response.data,
      });
    }
  } catch (error) {
    NotificationService.push({
      type: 'error',
    });
  }
}

function* watchQualityAssessmentsRequested() {
  yield takeLatest('FETCH_QUALITY_ASSESSMENTS', qualityAssessmentsListFlow);
}

function* watchFetchChatsByFailureReasonRequested() {
  yield takeLatest('FETCH_CHATS_BY_FAILURE_REASON', fetchChatsByFailureReasonFlow);
}

function* watchFailureReasonsRequested() {
  yield takeLatest('FETCH_FAILURE_REASONS', failureReasonsListFlow);
}

function* watchCreateFailureReason() {
  yield takeLatest('CREATE_FAILURE_REASON', failureReasonCreateFlow);
}

function* watchCreateQualityAssessment() {
  yield takeLatest('CREATE_QUALITY_ASSESSMENT', createChatQualityAssessmentFlow);
}

function* watchUpdateQualityAssessment() {
  yield takeLatest('UPDATE_QUALITY_ASSESSMENT', updateChatQualityAssessmentFlow);
}

function* watchDeleteQualityAssessment() {
  yield takeLatest('DELETE_QUALITY_ASSESSMENT', deleteChatQualityAssessmentFlow);
}

export default function* qualityAssessmentsSaga() {
  yield all([
    watchQualityAssessmentsRequested(),
    watchFetchChatsByFailureReasonRequested(),
    watchFailureReasonsRequested(),
    watchCreateQualityAssessment(),
    watchUpdateQualityAssessment(),
    watchDeleteQualityAssessment(),
    watchCreateFailureReason(),
  ]);
}
