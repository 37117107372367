import BaseRenderer from 'diagram-js/lib/draw/BaseRenderer';

import {
  append as svgAppend,
  attr as svgAttr,
  classes as svgClasses,
  create as svgCreate,
  remove as svgRemove,
  innerSVG as svgInnerAppend,
} from 'tiny-svg';

import { getRoundRectPath } from 'bpmn-js/lib/draw/BpmnRenderUtil';

import { is } from 'bpmn-js/lib/util/ModelUtil';
import { isAny } from 'bpmn-js/lib/features/modeling/util/ModelingUtil';

import modeling from 'bpmn-js/lib/features/modeling';

const HIGH_PRIORITY = 1500;

export default class SendTaskRenderer extends BaseRenderer {
  constructor(eventBus, bpmnRenderer) {
    super(eventBus, HIGH_PRIORITY);

    this.bpmnRenderer = bpmnRenderer;
    // this.modeling = bpmnRenderer.get('modeling');

    // this.maxHeight = 0;
    this.elementWidth = 200;
    this.currentZoomLevel = 1;
  }

  canRender(element) {
    return is(element, 'bpmn:SendTask') && element.businessObject.subType === 'message-task';
  }

  svg_textMultiline(id, maxWidth, zoom) {
    var x = 10;
    var y = 0;
    var dy = 15;

    /* get the text */
    var element = document.getElementById(id);

    var text = element.innerHTML;

    var words = text.split(' ');
    var line = '';

    var constructedText = '';

    for (var n = 0; n < text.length; n++) {
      element.innerHTML = '<tspan id="PROCESSING">busy</tspan >';

      var newTextLine = line + text[n];

      const newTextElem = document.getElementById('PROCESSING');

      newTextElem.innerHTML = newTextLine;
      var metrics = newTextElem.getBoundingClientRect();
      var newTextWidth = metrics.width;

      if (newTextWidth > maxWidth - 2 * x * (zoom ? zoom : 1)) {
        if ((constructedText.match(/<tspan/g) || []).length > 0) {
          constructedText += '<tspan x="' + x + '" dy="' + dy + '">' + line + '</tspan>';
        } else {
          constructedText += '<tspan x="' + x + '" dy="' + y + '">' + line + '</tspan>';
        }
        line = text[n];
      } else {
        line = newTextLine;
      }

      document.getElementById('PROCESSING').remove();
    }
    if ((constructedText.match(/<tspan/g) || []).length > 0) {
      constructedText += '<tspan x="' + x + '" dy="' + dy + '">' + line + '</tspan>';
    } else {
      constructedText += '<tspan x="' + x + '" dy="' + y + '">' + line + '</tspan>';
    }

    element.innerHTML = constructedText;
  }

  // Main function to draw custom shape

  drawShape(parentNode, element) {
    const parentNodeBounds = parentNode.getBoundingClientRect();

    element.width = this.elementWidth;

    let shapeProcessing = this.bpmnRenderer.drawShape(parentNode, element);
    let element_id = `${element.id}${Math.floor(Math.random() * 10000)}`
    // element id can repeat, so add "salt" to avoid it

    this.lastActualWidth = shapeProcessing.getBoundingClientRect().width;

    const newText = svgCreate('text');
    svgAttr(newText, {
      id: `newTextFor${element_id}`,
      class: 'djs-label',
      x: 10,
      y: 35,
    });

    newText.appendChild(document.createTextNode(element.businessObject.template));

    parentNode.appendChild(newText);

    const newLabelText = svgCreate('text');
    svgAttr(newLabelText, {
      id: `newLabelText`,
      class: 'djs-label-small',
      x: 10,
      y: 20,
    });

    newLabelText.appendChild(document.createTextNode('message template'));

    parentNode.appendChild(newLabelText);

    let zoom;
    if (shapeProcessing.getBoundingClientRect().width) {
      zoom = (shapeProcessing.getBoundingClientRect().width / this.elementWidth).toFixed(4);
    } else {
      zoom = 1;
    }

    let newClientRect;
    try {
      this.svg_textMultiline(`newTextFor${element_id}`, this.lastActualWidth, zoom);

      newClientRect = document.getElementById(`newTextFor${element_id}`).getBoundingClientRect();
    } catch (e) {
      newClientRect = {height: 15}
    }
    let newHeight = Math.round(
        newClientRect.height + 40 * (zoom ? zoom : 1) > 80 * (zoom ? zoom : 1)
            ? newClientRect.height + 40 * (zoom ? zoom : 1)
            : 80 * (zoom ? zoom : 1)
    );
    let oldHeight = element.height;
    newHeight = zoom === 1 ? newHeight : newHeight * (1 / zoom);

    svgAttr(shapeProcessing, {
      height: newHeight,
    });

    element.width = this.elementWidth;
    element.height = newHeight;
    element.y = element.y - (newHeight / 2 - oldHeight / 2);

    let shape = this.bpmnRenderer.drawShape(parentNode, element);

    svgAttr(parentNode.childNodes[5], {
      fillOpacity: 0,
      strokeWidth: 3,
      stroke: '#263C73',
      class: 'djs-rect',
    });

    svgRemove(parentNode.childNodes[0]);
    svgRemove(parentNode.childNodes[0]);
    svgRemove(parentNode.childNodes[4])
    drawRect(parentNode);

    this.currentZoomLevel = zoom;

    return shape;
  }
}

SendTaskRenderer.$inject = ['eventBus', 'bpmnRenderer'];

// helpers //////////

// copied from https://github.com/bpmn-io/bpmn-js/blob/master/lib/draw/BpmnRenderer.js
function drawRect(parentNode, width, height, borderRadius, strokeColor) {
  const g = svgCreate('g');

  svgAttr(g, {
    transform: 'translate(-8, -8)',
    fill: 'white',
  });

  /*const rect = svgCreate('rect');

  svgAttr(rect, {
    width: width,
    height: height,
    fill: "#fff"
  });*/

  //svgAppend(g, rect);

  const rect = '<rect width="22" height="22" fill="white"></rect>';

  const iconContent =
    '<path ' +
    'd="M19 9.50003C19.0034 10.8199 18.6951 12.1219 18.1 13.3C17.3944 14.7118 16.3098 15.8992 14.9674 16.7293C13.6251 17.5594 12.0782 17.9994 10.5 18C9.18013 18.0035 7.87812 17.6951 6.7 17.1L1 19L2.9 13.3C2.30493 12.1219 1.99656 10.8199 2 9.50003C2.00061 7.92179 2.44061 6.37488 3.27072 5.03258C4.10083 3.69028 5.28825 2.6056 6.7 1.90003C7.87812 1.30496 9.18013 0.996587 10.5 1.00003H11C13.0843 1.11502 15.053 1.99479 16.5291 3.47089C18.0052 4.94699 18.885 6.91568 19 9.00003V9.50003Z" ' +
    'stroke="#000F3C" ' +
    'stroke-width="1.5" ' +
    'stroke-linecap="round" ' +
    'stroke-linejoin="round"' +
    '/>';

  svgInnerAppend(g, rect + iconContent);

  svgAppend(parentNode, g);

  return g;
}

// copied from https://github.com/bpmn-io/diagram-js/blob/master/lib/core/GraphicsFactory.js
function prependTo(newNode, parentNode, siblingNode) {
  parentNode.insertBefore(newNode, siblingNode || parentNode.firstChild);
}
