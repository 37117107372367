import {useEffect, useRef, useState} from "react";
import {createBpmnEditor, destroyBpmnEditor} from "../modeler/init/modeler-init";
import ModelerService from "../services/ModelerService";
import ViewerService from "../services/ViewerService";
import {computeBranchesAndUpdateLayout} from "../bpmn-engine";

const ENABLE_FEATURE_REVIEW_AND_COMMENTS = process.env.ENABLE_FEATURE_REVIEW_AND_COMMENTS === 'true';

export function useModeler({
  diagramType, userId,  diagramFile,
  updateCurrentTask,
  updateCurrentSubProcess,
  updateCurrentSubProcessDiagramVersions,
  updateCurrentEvent,
  updateCurrentFileState,
  updateCurrentDiagramTranslationsModificationCount,
  setReviewMode,
  updateIndexedDB,
}) {

  const [modeler, _setModeler] = useState();
  const modelerRef = useRef(modeler);
  const [viewer, setViewer] = useState();
  const [reviewViewer, setReviewViewer] = useState();

  function setModeler(m) {
    modelerRef.current = m;
    _setModeler(m);
  }

  useEffect(() => {
    const [m, v, rv] = createBpmnEditor(diagramType);
    setModeler(m);
    setViewer(v);
    setReviewViewer(rv);

    const eventBus = m.get('eventBus');
    // next line is ugly hack to fix cmd button for selection multiple nodes
    eventBus.off('element.mousedown', eventBus._getListeners('element.mousedown').next.callback);

    ModelerService.subscribe(
      m,
      userId,
      updateCurrentTask,
      updateCurrentSubProcess,
      updateCurrentSubProcessDiagramVersions,
      updateCurrentEvent,
      updateCurrentFileState,
      updateCurrentDiagramTranslationsModificationCount,
      diagramType
    );

    ViewerService.subscribe(
      rv,
      updateCurrentTask,
      updateCurrentSubProcess,
      updateCurrentSubProcessDiagramVersions
    );

    const interval = setInterval(() => {
      updateIndexedDB(modelerRef.current);
    }, 3000);

    return () => {
      clearInterval(interval);
      destroyBpmnEditor(m, v, rv);
      setModeler(undefined);
      setReviewViewer(undefined);
      setViewer(undefined);
    }
  }, [diagramType]);

  useEffect(() => {
    // load xml file
    if (diagramFile && modeler) {
      const search = window.location.search;
      const params = new URLSearchParams(search);
      const searchParams = params.get('searchString');
      const selectBranch = params.get('highlightBranch');
      if (selectBranch) {
        if (diagramFile)
          computeBranchesAndUpdateLayout(diagramFile).then(nodes => {
            let selectNode = nodes.find(
              node => node.branches?.length === 1 && node.isLeaf && node.branches[0] === parseInt(-1)
            );
            if (selectNode) {
              setTimeout(() => {
                const selection = modeler.get('selection');
                const elementRegistry = modeler.get('elementRegistry');
                const newElement = elementRegistry.get(selectNode.id);
                selection.select(newElement);
              });
            }
          });
      }

      if (params.get('review') === 'true' && ENABLE_FEATURE_REVIEW_AND_COMMENTS) {
        setReviewMode(true);
      }

      if (modeler.get('canvas')._planes) {
        ModelerService.importDiagramVersion(modeler, diagramFile, userId);
      }

      setTimeout(() => {
        // incrementing modification count here just to trigger re-render for updating unreviewedTranslationsCount
        updateCurrentDiagramTranslationsModificationCount();
        if (modeler.get('canvas')._container) {
          ModelerService.centerDiagram(modeler);
        }
        if (searchParams) {
          const searchPad = modeler.get('searchPad');
          searchPad.open();
          searchPad._searchInput.value = searchParams;
          searchPad._search(searchParams);
          searchPad._searchInput.focus();
          const el = searchPad._results[searchPad._getCurrentResult().dataset.resultId].element;
          setTimeout(() => {
            searchPad._resetOverlay(el);
          }, 100);
        }
      }, 500);
    }
  }, [diagramFile, modeler]);

  return [modeler, viewer, reviewViewer];
}
