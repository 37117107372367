const fetchTasksList = searchParams => {
  return {
    type: 'FETCH_TASKS_LIST',
    searchParams,
  };
};

const saveTasksList = tasks => {
  return {
    type: 'SAVE_TASKS_LIST',
    tasks,
  };
};
const taskActions = {
  fetchTasksList,
  saveTasksList,
};

export default taskActions;
