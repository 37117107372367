import React from "react";
import styled from "styled-components";
import {fontStyle} from "../../styles/mixins";
import {titleFontSize, semibold} from "../../styles/typography";
import {grey, mediumBlue} from "../../styles/colors";

const Container = styled.div`
  display: flex;
  align-items: center;
  ${fontStyle(titleFontSize, semibold)}
  color: ${grey};
  background-color: ${mediumBlue};
  border-radius: 10px 10px 0 0;
  padding: 5px;
`

const Title = styled.span`
  flex-grow: 1;
  text-align: center;
`

const CallbotClientHeader = () => {
  return (
    <Container>
      <Title>
        Callbot Test Client
      </Title>
    </Container>
  );
}

export default CallbotClientHeader;
