import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import EventPropertiesPanel from "../../components/event-properties-panel/EventPropertiesPanel";
import currentEventActions from "../../action-creators/currentEventActions";

const EventPropertiesPanelContainer = ({
       modeler, reviewMode, currentEvent, currentEventBusinessObject, updateCurrentEvent
}) => {

    const isExpanded = !!(currentEvent);

    // let currentEventData;
    // if (isExpanded) {
    //     currentEventData = taskData[currentTaskBusinessObject.subType];
    // }

    return (
        <EventPropertiesPanel
            modeler={modeler}
            isExpanded={isExpanded}
            currentEvent={currentEvent}
            // currentEventData={currentEventData}
            currentBusinessObject={currentEventBusinessObject}
            updateCurrentEvent={updateCurrentEvent}
            reviewMode={reviewMode}
        />
    );
};

export const mapStateToProps = state => {
    return {
        currentEvent: state.app.currentEvent,
        currentEventBusinessObject: state.app.currentEventBusinessObject
    };
};

const mapDispatchToProps = {
    updateCurrentEvent: currentEventActions.updateCurrentEvent
};

export default connect(mapStateToProps, mapDispatchToProps)(EventPropertiesPanelContainer);
