import { put, call, all, select, take, takeLatest, spawn } from 'redux-saga/effects';

import DiagramsService from '../services/DiagramsService';
import DiagramVersionsService from '../services/DiagramVersionsService';
import currentDiagramAndVersionActions from '../action-creators/currentDiagramAndVersionActions';
import visiblityActions from '../action-creators/visibilityActions';
import modalsActions from '../action-creators/modalsActions';
import NotificationService from '../services/common/NotificationService';
import { fullDemoDiagram } from '../constants/demo-diagrams/fullDemoDiagram';
import { smallDemoDiagram } from '../constants/demo-diagrams/smallDemoDiagram';
import { pukCodeInquiryDiagram } from '../constants/demo-diagrams/pukCodeInquiryDiagram';

import { featuresOverviewDiagram } from '../constants/demo-diagrams/new/featuresOverviewDiagram';
import { masterDiagram } from '../constants/demo-diagrams/new/masterDiagram';
import { roamingInquiryDiagram } from '../constants/demo-diagrams/new/roamingInquiryDiagram';

import WebUrlService from '../services/common/WebUrlService';

//// Sagas state extractors /////////////////////////////////////////////////////

const isCurrentDiagramAndVersionSaved = state => {
  return (
    state.app.isCurrentDiagramSaved &&
    state.app.isCurrentDiagramVersionSaved &&
    state.app.isCurrentDiagramVersionFileSaved
  );
};

const isVersionControlVisible = state => state.visibility.versionControlPanelVisible;

const getCurrentCount = state => state.app.currentDiagramLastVersionsCount;

const getCurrentDiagramId = state => state.app.currentDiagram.id;

/////////////////////////////////////////////////////////////////////////

function* createNewCurrentDiagramAndVersionFlow(action) {
  try {
    // VERIFY IF CURRENT DIAGRAM AND VERSION HAS NO UNSAVED CHANGES
    const isSaved = yield select(isCurrentDiagramAndVersionSaved);
    // IF HAS NO UNSAVED CHANGES CREATE NEW DIAGRAM
    if (isSaved || action.shouldSave) {
      // EXTRACT DEFAULT EMPTY DIAGRAM AND VERSION FROM FILE
      const newDiagram = {
        id: -1,
        name: action.label || 'Untitled solution flow',
      };
      const newDiagramVersion = {
        id: -1,
        label: action.label || 'new-label',
        file: smallDemoDiagram,
        diagramType: 'chatbot'
      };
      // UPDATE STATE WITH NEW CURRENT VERSION
      yield put(
        currentDiagramAndVersionActions.updateCurrentDiagramAndVersion(
          newDiagram,
          newDiagramVersion,
          [],
          false,
          false,
          false,
          false
        )
      );
      // TOGGLE VERSION CONTROL PANEL
      const isVisible = yield select(isVersionControlVisible);
      if (isVisible) {
        yield put(visiblityActions.toggleVersionControlVisibility());
      }
      WebUrlService.resetWebUrl();
      // NotificationService.push({
      //     type: 'success',
      //     message: 'New diagram draft with new version successfully created!'
      // });
      // IF HAS UNSAVED CHANGES OPEN CONFIRMATION MODAL FIRST
    } else {
      yield put(
        modalsActions.openModal('unsavedChanges', currentDiagramAndVersionActions.createNewCurrentDiagramAndVersion, {
          shouldSave: true,
        })
      );
    }
  } catch (error) {
    NotificationService.push({
      type: 'error',
    });
  }
}

function* watchCreateNewCurrentDiagramAndVersionRequested() {
  yield takeLatest('CREATE_NEW_CURRENT_DIAGRAM_AND_VERSION', createNewCurrentDiagramAndVersionFlow);
}

function* changeCurrentDiagramAndVersionFlow(action) {
  try {
    // VERIFY IF CURRENT DIAGRAM AND VERSION HAS NO UNSAVED CHANGES
    const isSaved = yield select(isCurrentDiagramAndVersionSaved);
    // EXTRACT CHOSEN DIAGRAM VERSION
    if (isSaved || action.shouldSave) {
      const newDiagramVersionResponse = yield call(DiagramVersionsService.getDiagramVersion, {
        versionId: action.versionId,
      });
      const currentCount = yield select(getCurrentCount);
      const newDiagramLastVersionsResponse = yield call(DiagramVersionsService.getDiagramVersions, {
        diagramId: action.diagram.id,
        count: currentCount,
        startFrom: 0,
      });
      if (newDiagramVersionResponse.status === 200 && newDiagramLastVersionsResponse.status === 200) {
        const newDiagramVersion = newDiagramVersionResponse.data;
        const newDiagramLastVersions = newDiagramLastVersionsResponse.data['versions'];
        const diagramHasMoreVersions = newDiagramLastVersionsResponse.data['has_next'];
        // UPDATE STATE WITH NEW CURRENT VERSION
        yield put(
          currentDiagramAndVersionActions.updateCurrentDiagramAndVersion(
            action.diagram,
              action.specificFile ?
                  Object.assign(
                {}, newDiagramVersion, {'file': action.specificFile}
                ) : newDiagramVersion,
            newDiagramLastVersions,
            diagramHasMoreVersions,
            true,
            true,
            true
          )
        );
        // TOGGLE VERSION CONTROL PANEL
        const isVisible = yield select(isVersionControlVisible);
        if (isVisible) {
          yield put(visiblityActions.toggleVersionControlVisibility());
        }
        if (action.specificFile) {
          yield put(currentDiagramAndVersionActions.updateCurrentDiagramToBeUnsaved())
        }
        WebUrlService.updateWebUrlWithVersion(newDiagramVersion.label, newDiagramVersion.id);
      } else {
        NotificationService.push({
          type: 'error',
        });
      }
      // IF HAS UNSAVED CHANGES OPEN CONFIRMATION MODAL FIRST
    } else {
      yield put(
        modalsActions.openModal('unsavedChanges', currentDiagramAndVersionActions.changeCurrentDiagramAndVersion, {
          diagram: action.diagram,
          versionId: action.versionId,
          shouldSave: true,
        })
      );
    }
  } catch (error) {
    NotificationService.push({
      type: 'error',
    });
  }
}

function* watchChangeCurrentDiagramAndVersionRequested() {
  yield takeLatest('CHANGE_CURRENT_DIAGRAM_AND_VERSION', changeCurrentDiagramAndVersionFlow);
}

function* changeCurrentDiagramVersionsFlow(action) {
  try {
    const currentCount = yield select(getCurrentCount);
    const newDiagramLastVersionsResponse = yield call(DiagramVersionsService.getDiagramVersions, {
      diagramId: action.diagram.id,
      count: currentCount,
      startFrom: 0,
    });
    if (newDiagramLastVersionsResponse.status === 200) {
      const newDiagramLastVersions = newDiagramLastVersionsResponse.data['versions'];
      const diagramHasMoreVersions = newDiagramLastVersionsResponse.data['has_next'];
      // UPDATE STATE WITH NEW CURRENT VERSION
      yield put(currentDiagramAndVersionActions.updateCurrentDiagram(action.diagram));
      yield put(
        currentDiagramAndVersionActions.updateCurrentDiagramVersions(
          newDiagramLastVersions,
          diagramHasMoreVersions,
          currentCount,
          true
        )
      );
      // NotificationService.push({
      //     type: 'success',
      //     message: `Diagram versions updated!`,
      //     timeOut: 3000
      // });
    } else {
      NotificationService.push({
        type: 'error',
      });
    }
  } catch (error) {
    NotificationService.push({
      type: 'error',
    });
  }
}

function* watchChangeCurrentDiagramVersionsRequested() {
  yield takeLatest('CHANGE_CURRENT_DIAGRAM_VERSIONS', changeCurrentDiagramVersionsFlow);
}

function* loadMoreCurrentDiagramVersionsFlow(action) {
  try {
    const response = yield call(DiagramVersionsService.getDiagramVersions, {
      diagramId: action.diagramId,
      count: 30,
      startFrom: action.startFrom,
    });
    if (response.status === 200) {
      const diagramVersions = response.data['versions'];
      const hasMore = response.data['has_next'];
      const currentCount = yield select(getCurrentCount);
      yield put(
        currentDiagramAndVersionActions.updateCurrentDiagramVersions(diagramVersions, hasMore, currentCount + 3)
      );
      // NotificationService.push({
      //     type: 'success',
      //     message: 'Uploaded extra diagram versions for chosen diagram successfully!'
      // });
    } else {
      NotificationService.push({
        type: 'error',
      });
    }
  } catch (error) {
    NotificationService.push({
      type: 'error',
    });
  }
}

function* watchLoadMoreCurrentDiagramVersionsRequested() {
  yield takeLatest('LOAD_MORE_CURRENT_DIAGRAM_VERSIONS', loadMoreCurrentDiagramVersionsFlow);
}

function* showLessCurrentDiagramVersionsFlow(action) {
  try {
    const currentDiagramId = yield select(getCurrentDiagramId);
    const response = yield call(DiagramVersionsService.getDiagramVersions, {
      diagramId: currentDiagramId,
      count: action.newCount,
      startFrom: 0,
    });
    if (response.status === 200) {
      const diagramVersions = response.data['versions'];
      const hasMore = response.data['has_next'];
      yield put(currentDiagramAndVersionActions.updateCurrentDiagramVersions(diagramVersions, hasMore, 3, true));
      // NotificationService.push({
      //     type: 'success',
      //     message: 'Uploaded extra diagram versions for chosen diagram successfully!'
      // });
    } else {
      NotificationService.push({
        type: 'error',
      });
    }
  } catch (error) {
    NotificationService.push({
      type: 'error',
    });
  }
}

function* watchShowLessCurrentDiagramVersionsRequested() {
  yield takeLatest('SHOW_LESS_CURRENT_DIAGRAM_VERSIONS', showLessCurrentDiagramVersionsFlow);
}

function* loadSpecialDiagramVersionFlow(action) {
  try {
    const response = yield call(DiagramsService.getDiagramAndVersionByLabel, {
      label: action.label,
      versionId: action.versionId || '',
    });
    if (response.status === 200) {
      const newDiagram = response.data.diagram;
      const newDiagramVersion = response.data.version;
      const currentCount = yield select(getCurrentCount);
      const newDiagramLastVersionsResponse = yield call(DiagramVersionsService.getDiagramVersions, {
        diagramId: newDiagram.id,
        count: currentCount,
        startFrom: 0,
      });
      if (newDiagramLastVersionsResponse.status === 200) {
        const newDiagramLastVersions = newDiagramLastVersionsResponse.data['versions'];
        const diagramHasMoreVersions = newDiagramLastVersionsResponse.data['has_next'];
        // UPDATE STATE WITH NEW CURRENT VERSION
        yield put(
          currentDiagramAndVersionActions.updateCurrentDiagramAndVersion(
            newDiagram,
            newDiagramVersion,
            newDiagramLastVersions,
            diagramHasMoreVersions,
            true,
            true,
            true
          )
        );
      } else {
        // TODO: WRONG
      }

      // NotificationService.push({
      //     type: 'success',
      //     message: 'Uploaded extra diagram versions for chosen diagram successfully!'
      // });
    } else if (response.status === 400) {
      NotificationService.push({
        type: 'warning',
        message: `Could not load specific version from url. Possible cause: ${response.data}`,
      });
    } else {
      NotificationService.push({
        type: 'error',
      });
    }
  } catch (error) {
    NotificationService.push({
      type: 'error',
    });
  }
}

function* watchLoadSpecialDiagramVersionRequested() {
  yield takeLatest('LOAD_SPECIAL_DIAGRAM_VERSION', loadSpecialDiagramVersionFlow);
}

export default function* currentDiagramAndVersionSaga() {
  yield all([
    watchCreateNewCurrentDiagramAndVersionRequested(),
    watchChangeCurrentDiagramAndVersionRequested(),
    watchChangeCurrentDiagramVersionsRequested(),
    watchLoadMoreCurrentDiagramVersionsRequested(),
    watchShowLessCurrentDiagramVersionsRequested(),
    watchLoadSpecialDiagramVersionRequested(),
  ]);
}
