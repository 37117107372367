import React, {useState} from "react";
import FlexibleWrapper from "../shared/FlexibleWrapper";
import * as colors from "../../styles/colors";
import MessageIcon from "../shared/icons/tasks/MessageIcon";
import HelpdeskMessageIcon from "../shared/icons/tasks/HelpdeskMessageIcon";
import ConfirmDiagramTypeChangeModal from "../modals/ConfirmDiagramTypeChangeModal";
import {SaveButtonWrapper, SaveTooltip, SaveTooltipContent} from "../shared/Buttons";

const BotToggleButtons = ({diagramType, setDiagramType}) => {
  const [open, setOpen] = useState(null);
  const openModal = (type) => {
    if (diagramType !== type) {
      setOpen(type);
    }
  };

  const confirm = () => {
    setDiagramType(open);
    setOpen(null);
  }


  return (
    <>{open &&
      <ConfirmDiagramTypeChangeModal onClose={() => setOpen(null)} onConfirm={confirm} diagramType={open}/>}
      <FlexibleWrapper>
        <SaveButtonWrapper onClick={() => openModal("chatbot")}>
          <MessageIcon color={diagramType === "chatbot" ? colors.red : colors.mainFontColor}/>
          <SaveTooltip>
            <SaveTooltipContent>
              Chatbot
            </SaveTooltipContent>
          </SaveTooltip>
        </SaveButtonWrapper>
        <SaveButtonWrapper onClick={() => openModal("callbot")}>
          <HelpdeskMessageIcon color={diagramType === "callbot" ? colors.red : colors.mainFontColor}/>
          <SaveTooltip>
            <SaveTooltipContent>
              Callbot
            </SaveTooltipContent>
          </SaveTooltip>
        </SaveButtonWrapper>
      </FlexibleWrapper>
    </>
  )
}

export default BotToggleButtons;
