import CallbotMessageTaskRenderer from "./CallbotMessageTaskRenderer";
import ServiceTaskRenderer from './ServiceTaskRenderer';
import MapTaskRenderer from './MapTaskRenderer';
import LogicTaskRenderer from './LogicTaskRenderer';
import LogicPythonTaskRenderer from './LogicPythonTaskRenderer';
import CallbotClassifierTaskRenderer from "./CallbotClassifierTaskRenderer";
import CallActivityRenderer from './CallActivityRenderer';
import EmptyTaskRenderer from './EmptyTaskRenderer';
import TableTaskRenderer from './TableTaskRenderer';
import CarouselTaskRenderer from './CarouselTaskRenderer';
import HelpdeskMessageTaskRenderer from './HelpdeskMessageTaskRenderer';
import VoiceTaskRenderer from './VoiceTaskRenderer';
import SmsTaskRenderer from './SmsTaskRenderer';
import RoutingTaskRenderer from "./RoutingTaskRenderer";
import CallbotInputTaskRenderer from "./CallbotInputTaskRenderer";
import ParallelGatewayRenderer from "./ParallelGatewayRenderer";
import SequenceFlowTaskRenderer from './SequenceFlowTaskRenderer';

export default {
  __init__: [
    'SendTaskRenderer',
    'InputTaskRenderer',
    'TableTaskRenderer',
    'ServiceTaskRenderer',
    'MapTaskRenderer',
    'LogicTaskRenderer',
    'LogicPythonTaskRenderer',
    'ClassifierTaskRenderer',
    'CallActivityRenderer',
    'CarouselTaskRenderer',
    'EmptyTaskRenderer',
    'HelpdeskMessageTaskRenderer',
    'VoiceTaskRenderer',
    'SmsTaskRenderer',
    'RoutingTaskRenderer',
    'ParallelGatewayRenderer',
    'SequenceFlowTaskRenderer'
  ],
  SendTaskRenderer: ['type', CallbotMessageTaskRenderer],
  InputTaskRenderer: ['type', CallbotInputTaskRenderer],
  TableTaskRenderer: ['type', TableTaskRenderer],
  ServiceTaskRenderer: ['type', ServiceTaskRenderer],
  MapTaskRenderer: ['type', MapTaskRenderer],
  LogicTaskRenderer: ['type', LogicTaskRenderer],
  LogicPythonTaskRenderer: ['type', LogicPythonTaskRenderer],
  ClassifierTaskRenderer: ['type', CallbotClassifierTaskRenderer],
  CallActivityRenderer: ['type', CallActivityRenderer],
  CarouselTaskRenderer: ['type', CarouselTaskRenderer],
  EmptyTaskRenderer: ['type', EmptyTaskRenderer],
  HelpdeskMessageTaskRenderer: ['type', HelpdeskMessageTaskRenderer],
  VoiceTaskRenderer: ['type', VoiceTaskRenderer],
  SmsTaskRenderer: ['type', SmsTaskRenderer],
  RoutingTaskRenderer: ['type', RoutingTaskRenderer],
  ParallelGatewayRenderer: ['type', ParallelGatewayRenderer],
  SequenceFlowTaskRenderer: ['type', SequenceFlowTaskRenderer],
};
