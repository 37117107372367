import initialLabelsState from '../constants/initial-state-parts/initialLabelsState';

const labelsReducer = (state = initialLabelsState, action) => {
  switch (action.type) {
    case 'UPDATE_LABELS_LIST':
      return Object.assign({}, state, {
        labels: action.newLabelsList,
      });
    case 'UPDATE_SFM_LIST':
      return Object.assign({}, state, {
        diagrams: action.newSFMList,
      });
    case 'UPDATE_LABEL_VERSIONS_LIST':
      return Object.assign({}, state, {
        labelVersions: action.newLabelVersionsList,
      });
    case 'UPDATE_CURRENT_LABEL_ID':
      return Object.assign({}, state, {
        currentLabelId: action.newLabelId,
      });
    default:
      return state;
  }
};

export default labelsReducer;
