import React, { useEffect, useRef } from 'react';
import moment from 'moment';
import AudioPlayer from '../audio/AudioPlayer';
import { Input } from 'antd';

const { TextArea } = Input;

const TranslationValue = ({ value }) => {
  return (
    <div dir="auto" className={`full-height word-break line-break ${value ? 'opacity-full' : 'opacity-half'}`}>
      {value || 'No translation'}
    </div>
  );
};

const TranslationInput = ({ autoFocus, onTranslationChanged, value }) => {
  const fixFocus = e => {
    const val = e.target.value;
    e.target.value = '';
    e.target.value = val;
  };
  return (
    <TextArea
      dir="auto"
      autoFocus={autoFocus}
      className="no-padding full-height cursor-text-class"
      onChange={ev => onTranslationChanged(ev.target.value)}
      placeholder="No translation"
      bordered={false}
      value={value}
      onFocus={fixFocus}
      autoSize
    />
  );
};

const LanguageCell = ({
  node,
  diagramType,
  onTranslationChanged,
  srcStringModifiedAt,
  onTranslationReview,
  handleSynthesize,
  noPlayer,
  onTranslationValuesChange,
}) => {
  return (
    <div className="language-cell">
      <div className={`full-height full-width ${node.text ? 'opacity-full' : 'opacity-half'}`}>
        {node.selected ? (
          <>
            <TranslationInput value={node.text} autoFocus onTranslationChanged={onTranslationChanged} />
            {!noPlayer && diagramType === 'callbot' && (
              <TranslationInput value={node.values} onTranslationChanged={onTranslationValuesChange} />
            )}
          </>
        ) : (
          <div className="language-translation-text">
            <TranslationValue value={node.text} />
            {diagramType === 'callbot' && node.values && <TranslationValue value={node.values} />}
            {node.reviewed === false && node.text && (
              <div>
                <span className="attention-cell">
                  Source string was updated on {moment(srcStringModifiedAt * 1000).format('HH:mm DD.MM.YY')}
                </span>
                <button onClick={onTranslationReview}>
                  <span className="attention-ok">OK</span>
                </button>
              </div>
            )}
          </div>
        )}
      </div>
      {!noPlayer && diagramType === 'callbot' && (
        <AudioPlayer promptUrl={node.promptUrl} handleSynthesize={handleSynthesize} />
      )}
    </div>
  );
};

export default LanguageCell;
