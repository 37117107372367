// Common colors
import { medium } from './typography';

export const white = '#FFFFFF';
export const grey = '#F2F2F2';
export const darkGrey = '#E5E5E5';
export const mediumBlue = '#263C73';
export const darkBlue = '#002348';
export const red = '#E61A3C';
export const green = '#1EAAAA';

export const mediumBlueWithOpacity = 'rgba(38, 60,115, 0.25)';
export const darkBlueWithOpacity = 'rgba(0, 15, 60, 0.3)';

// Backgrounds
export const backgroundColor = white;
export const backgroundColorWithOpacity = 'rgba(255, 255, 255, 0.3)';
export const componentBackgroundColor = grey;
export const extraBackgroundColor = darkGrey;

// Font colors
export const mainFontColor = mediumBlue;
export const extraFontColor = darkBlue;
export const extraFontColorWithOpacity = 'rgba(38, 60, 115, 0.35)';
export const alternativeFontColor = red;

export const placeholderColor = darkBlueWithOpacity;
export const unselectedLabelColor = mediumBlueWithOpacity;

// Icon colors
export const mainIconColor = mediumBlue;
export const extraIconColor = darkBlue;
export const alternativeIconColor = red;
export const successIconColor = green;
