import React from 'react';
import {
  Line,
  LineChart,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  Label as AxisLabel,
  ResponsiveContainer,
} from 'recharts';
import moment from 'moment';

const formatData = (selectedCustomMetricsContextKeys, metrics) => {
  let dict = {};
  metrics.forEach(metric => {
    if (selectedCustomMetricsContextKeys.includes(metric.context_key)) {
      if (!dict[metric.dt]) {
        dict[metric.dt] = { name: moment(metric.dt).format('MMMM YYYY') };
      }
      dict[metric.dt][metric.context_key] = metric.value;
    }
  });
  return Object.values(dict);
};

const CustomMetrics = ({ selectedCustomMetricsContextKeys, metrics, customMetricsColorPaletteMap }) => {
  return (
    <ResponsiveContainer width="100%" height={400}>
      <LineChart
        data={formatData(selectedCustomMetricsContextKeys, metrics)}
        margin={{
          top: 20,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        {/* <Legend /> */}
        <XAxis dataKey="name" />
        <YAxis>
          <AxisLabel position="insideLeft" angle={-90} style={{ textAnchor: 'middle' }} />
        </YAxis>
        <Tooltip />
        {selectedCustomMetricsContextKeys.map(contextKey => (
          <Line
            type="monotone"
            stroke={customMetricsColorPaletteMap[contextKey]}
            dataKey={contextKey}
            activeDot={{ r: 8 }}
          />
        ))}
      </LineChart>
    </ResponsiveContainer>
  );
};

export default CustomMetrics;
