import crud from "./common/CrudService";
import responseService from "./common/ResponseService";

const API_BASE_URL = process.env.SFM_API_URL || 'http://127.0.0.1:5000';
const API_PREFIX = process.env.SFM_CLIENT_PREFIX || 'dev-api';
const synthesizeCrud = crud(API_BASE_URL, API_PREFIX, ["tts", "synthesize"]);
const dynamicSynthesizeCrud = crud(API_BASE_URL, API_PREFIX, ["tts", "synthesize", "dynamic"]);
const promptsCrud = crud(API_BASE_URL, API_PREFIX, ["tts", "prompts"]);

function parseValues(valuesString) {
  if (!valuesString) {
    return;
  }
  return valuesString.split(",").map(keyValue => keyValue.trim().split("=")).reduce((acc, [key, value]) => {
    acc[key.trim()] = value.trim();
    return acc;
  }, {});
}

const TTSService = {
  synthesize(template, valuesString, name) {
    return responseService.handle(synthesizeCrud.create(({template, values: parseValues(valuesString), name})));
  },
  synthesizeDynamic(template, valuesString) {
    return responseService.handle(dynamicSynthesizeCrud.create(({ template, values: parseValues(valuesString) })));
  },
  getAllPrompts() {
    return promptsCrud.list();
  },
  getPrompts(ids) {
    return promptsCrud.list({ids: ids});
  },
  getPrompt(id) {
    return responseService.handle(promptsCrud.view(id));
  }
}

export default TTSService;
