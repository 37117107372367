import React from "react";
import {ElementPropOption, ElementPropSelect} from "./utils/CommonElements";

export const Classifier = ({
  isBlocked, modeling, currentElement, elementPropKey, value
}) => {
return (
  <ElementPropSelect
    value={value || ''}
    disabled={isBlocked}
    onChange={e => {
      modeling.updateProperties(currentElement, {
        [elementPropKey]: e.target.value,
      });
    }}
  >
    <ElementPropOption value="">Select classifier to use</ElementPropOption>
    <ElementPropOption value="main_classifier">main_classifier</ElementPropOption>
  </ElementPropSelect>
);
}