import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

import store from './store/index';
import App from './components/App';
import SettingsService from './services/SettingsService';
import 'antd/dist/antd.variable.css';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import '../node_modules/font-awesome/css/font-awesome.min.css';
import './styles/font-poppins.css';
import './js/socket.io';
import './js/socket.io-stream.js';
import './js/compromise.min.js';

const ENABLE_CHAT = process.env.ENABLE_CHAT || 'true';

const $app = document.querySelector('#app');

if (module.hot) {
  module.hot.accept();
}

if (process.env.REACT_APP_SENTRY_DSN && process.env.REACT_APP_SENTRY_ENV) {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    environment: 'admin_interface_client_' + process.env.REACT_APP_SENTRY_ENV,
    integrations: [new Integrations.BrowserTracing()],
  });
}

function hasClass(el, className) {
  return el.classList ? el.classList.contains(className) : new RegExp('\\b' + className + '\\b').test(el.className);
}

function addClass(el, className) {
  if (el.classList) el.classList.add(className);
  else if (!hasClass(el, className)) el.className += ' ' + className;
}

function removeClass(el, className) {
  if (el.classList) el.classList.remove(className);
  else el.className = el.className.replace(new RegExp('\\b' + className + '\\b', 'g'), '');
}

const getWidgetOptions = (settings = {}) => {
  Object.keys(settings).forEach(key => {
    if (!settings[key] || settings[key] === '') {
      delete settings[key];
    }
  });
  const defaultValues = {
    icon_url: 'https://cwc.demo.mindtitan.com/mt.png',
    title: '{"en": "My custom title"}',
    languages: 'en',
    timezone: 'Europe/Helsinki',
    color_bar: '#263c73',
    color_support: '#000719',
    color_user: '#f0f1f5',
  };
  delete settings.draft_parsers; // this take lots of URL space. Remove it when large URL problem fixed
  const actualData = {
    ...defaultValues,
    ...settings,
  };
  let languages;
  if (actualData.languages === '') {
    languages = [];
  } else {
    languages = actualData.languages.split(',');
  }

  let titles;
  try {
    titles = JSON.parse(actualData.title);
    if (typeof titles !== 'object') {
      titles = { [languages[0]]: JSON.stringify(titles) };
    }
  } catch (e) {
    titles = JSON.parse(defaultValues.title);
  }
  const widgetOptions = {
    languages,
    message_preview_enabled: process.env.ENABLE_CWC_MESSAGE_PREVIEW === 'true',
    title: titles[languages[0]],
    icon_url: actualData.icon_url,
    timezone: actualData.timezone,
    color_bar: actualData.color_bar,
    color_user: actualData.color_user,
    color_support: actualData.color_support,
    default_label_language: actualData.default_label_language,
    other_styles: {
      '.rcw-conversation-container': `{
            border-radius: 4px;
        }`,
      '.rcw-header': `{
            visibility: visible;
            border-top-left-radius: 4px !important;
            border-top-right-radius: 4px !important;
            height: 56px;
            min-height: unset !important;
            max-height: unset !important;
            display: flex !important;
            justify-content: center;
            padding: 0px 24px !important;
        }`,
      'rcw-messages-scrollable-container': `{
        height: 100% !important;
      }`,
      '.rcw-title': `{
            padding: 0px !important;
        }`,
      '.chat-web-sdk-btn': `{
            height: 56px;
            width: 56px;
            box-shadow: unset;
        }`,
      '.chat-web-close-sdk-btn': `{
            height: 56px;
            width: 56px;
        }`,
    },
    other_styles_chat_collapsed: {
      '.rcw-header': `{
        visibility: hidden;
      }`,
      '.rcw-message-container': `{
        visibility: hidden;
      }`,
      '.rcw-messages-container': `{
        visibility: hidden !important;
      }`,
      '.quick-buttons-container': `{
        visibility: hidden !important;
      }`,
    },
    custom_bubble_style: {
      'z-index': 10000,
      border: 'none',
      height: 'calc(100% - 80px - 15px - 80px - 100px)',
      position: 'fixed',
      right: '0px',
      width: '100%',
      bottom: '80px',
      'max-width': '382px',
      opacity: 1,
      transition: 'opacity 0.2s',
    },
    custom_bubble_style_mobile: {
      'z-index': 10000,
      border: 'none',
      height: '100%',
      position: 'fixed',
      width: '100%',
      right: '0px',
      opacity: 1,
      bottom: '0px',
      transition: 'opacity 0.2s',
      'max-width': '100%',
    },
    showChatLauncherStyle: {
      'z-index': 10000,
      height: '64px',
      width: '64px',
      position: 'fixed',
      right: '15px',
      bottom: '15px',
      cursor: 'pointer',
      // opacity: 1,
      border: 'none',
      // transition: 'visibility 0s 0.5s, opacity 0.5s linear',
      'user-select': 'none',
      display: 'flex',
      'align-items': 'center',
      'justify-content': 'center',
    },
  };
  return widgetOptions;
};

window.initCWC = async function initCWC() {
  if (window.cws) window.cws.destroy();
  let widgetOptions;
  try {
    const { data } = await SettingsService.fetch();
    widgetOptions = getWidgetOptions(data);
  } catch (err) {
    widgetOptions = getWidgetOptions();
  }

  const lastPath = window.location.href.substring(window.location.href.lastIndexOf('/') + 1);
  if (
    !lastPath.startsWith('callbot-metrics') &&
    (lastPath.startsWith('callbot') || lastPath.startsWith('live-client-testing'))
  )
    return;

  if (lastPath === 'login') {
    const $resetbtn = document.getElementById('chat-web-sdk-reset-btn');
    if ($resetbtn) removeClass($resetbtn, 'visible');
    window?.cws?.destroy();
    return;
  }

  if (ENABLE_CHAT === 'true') {
    window.cws = initChatSdk(`${process.env.CWC_DRAFT_URL}/custom_enabled/`, {
      userId: 'john.smith',
      demoForm: false,
      logLevel: 'warn',
      options: widgetOptions,
    });

    const $resetbtn = document.getElementById('chat-web-sdk-reset-btn');

    window.cws.onClose = async function () {
      if ($resetbtn) removeClass($resetbtn, 'visible');
    };
    window.cws.onOpen = async function () {
      if ($resetbtn) addClass($resetbtn, 'visible');
    };
  }
};

window.onload = function () {
  const resetbtn = document.createElement('div');
  resetbtn.setAttribute('id', 'chat-web-sdk-reset-btn');
  resetbtn.innerHTML =
    '<svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"\n' +
    '\t width="528.919px" height="528.918px" viewBox="0 0 528.919 528.918" style="enable-background:new 0 0 528.919 528.918;"\n' +
    '\t xml:space="preserve">\n' +
    '<g>\n' +
    '\t<g>\n' +
    '\t\t<path d="M70.846,324.059c3.21,3.926,8.409,3.926,11.619,0l69.162-84.621c3.21-3.926,1.698-7.108-3.372-7.108h-36.723\n' +
    '\t\t\tc-5.07,0-8.516-4.061-7.427-9.012c18.883-85.995,95.625-150.564,187.207-150.564c105.708,0,191.706,85.999,191.706,191.706\n' +
    '\t\t\tc0,105.709-85.998,191.707-191.706,191.707c-12.674,0-22.95,10.275-22.95,22.949s10.276,22.949,22.95,22.949\n' +
    '\t\t\tc131.018,0,237.606-106.588,237.606-237.605c0-131.017-106.589-237.605-237.606-237.605\n' +
    '\t\t\tc-116.961,0-214.395,84.967-233.961,196.409c-0.878,4.994-5.52,9.067-10.59,9.067H5.057c-5.071,0-6.579,3.182-3.373,7.108\n' +
    '\t\t\tL70.846,324.059z" fill="#0019AF"/>\n' +
    '\t</g>\n' +
    '</g>\n' +
    '</svg>';
  document.body.append(resetbtn);
  const $resetbtn = document.getElementById('chat-web-sdk-reset-btn');

  $resetbtn.addEventListener('click', function () {
    removeClass($resetbtn, 'visible');
    window.cws.destroy();
    initCWC();
  });
};

// Render react app
ReactDOM.render(
  <Provider store={store}>
    <Router>
      <App />
    </Router>
  </Provider>,
  $app
);
