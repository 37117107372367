import inherits from 'inherits';

import ContextPadProvider from 'bpmn-js/lib/features/context-pad/ContextPadProvider';

import { assign, bind } from 'min-dash';
import {assignAction, flowActions} from "./context-pad-util";

const ENABLE_VOICE_TASK = process.env.ENABLE_VOICE_TASK || 'false';

export default function CallbotContextPadProvider(injector, connect, translate, _elementRegistry) {
  injector.invoke(ContextPadProvider, this);

  let elementFactory = this._elementFactory;
  let elementRegistry = _elementRegistry;
  let autoPlace = this._autoPlace;
  let create = this._create;
  let modeling = this._modeling;

  function getMaxNum(arr) {
    if (!arr) {
      return null;
    }
    var maxV = arr[0];
    for (let a of arr) {
      if (a > maxV) maxV = a;
    }
    return maxV;
  }

  function appendAction(type, group, className, title, options) {
    if (typeof title !== 'string') {
      options = title;
      title = translate('Append {type}', { type: type.replace(/^bpmn:/, '') });
    }

    function appendStart(event, element) {
      let target;
      if (options && options.isError) {
        target = {
          type: type,
          eventDefinitionType: 'bpmn:ErrorEventDefinition',
        };
      } else {
        target = { type: type };
      }
      var shape = elementFactory.createShape(assign(target, options));

      if (options && !options.isError) {
        shape.businessObject.subType = options.subType;
      }

      if (type === 'bpmn:SendTask' && props.subType) {
        shape.businessObject.subType = props.subType;
        shape.businessObject.promptName = '';
        shape.businessObject.template = props.template || '';
        shape.businessObject.clipDuration = '';
        shape.businessObject.promptUrl = '';
        shape.businessObject.values = ''
        shape.businessObject.source = 'Source';
        shape.businessObject.templateAsAudioUrl = '';
      } else if (type === 'bpmn:Task') {
        shape.businessObject.subType = props.subType;
      } else if (type === 'bpmn:ReceiveTask' && props.subType === 'input-task') {
        shape.businessObject.subType = props.subType;
        shape.businessObject.template = props.template || '';
        shape.businessObject.identifierForInput = `choice_${getCurrentChoice()}`;
        shape.businessObject.choices = '[]';
        shape.businessObject.validator = '';
        shape.businessObject.promptUrl = '';
        shape.businessObject.clipDuration = '';
        shape.businessObject.retryCount = '';
        shape.businessObject.inputType = 'text';
      } else if (type === 'bpmn:ReceiveTask' && props.subType === 'carousel-task') {
        shape.businessObject.subType = props.subType;
        shape.businessObject.identifierForSelectedItem = props.identifierForSelectedItem;
        shape.businessObject.carouselItemsData = '';
        shape.businessObject.itemTitleTemplate = '';
        shape.businessObject.itemDescription = '';
        shape.businessObject.itemImage = '';
        shape.businessObject.itemActionPayload = '';
      } else if (type === 'bpmn:ServiceTask') {
        shape.businessObject.subType = props.subType;
        shape.businessObject.method = '';
        shape.businessObject.url = '';
        shape.businessObject.body = '';
        shape.businessObject.requestVariable = '';
        shape.businessObject.identifierForResponse = '';
      } else if (type === 'bpmn:ScriptTask' && props.subType === 'routing-task') {
        shape.businessObject.subType = props.subType;
        shape.businessObject.routingNumber = '';
      } else if (type === 'bpmn:ScriptTask' && props.subType === 'table-task') {
        shape.businessObject.subType = props.subType;
        shape.businessObject.firstRowIsHeader = '';
        shape.businessObject.firstColumnIsHeader = '';
        shape.businessObject.title = '';
        shape.businessObject.footer = '';
        shape.businessObject.data = '';
        shape.businessObject.definition = '';
      } else if (type === 'bpmn:ScriptTask' && props.subType) {
        shape.businessObject.subType = props.subType;
        shape.businessObject.identifierForResult = 'identifier_for_result';
        shape.businessObject.identifierForInput = '';
        shape.businessObject.template = props.subType === 'logic' ? 'Jinja expression goes here' : 'Python code here';
      } else if (type === 'bpmn:ParallelGateway') {
        shape.businessObject.subType = 'parallel-gateway';
        shape.businessObject.timeout = '';
      }

      create.start(event, shape);
    }

    var append = autoPlace
      ? function (event, element) {
        let target;
        if (options && options.isError) {
          target = {
            type: type,
            eventDefinitionType: 'bpmn:ErrorEventDefinition',
          };
        } else {
          target = { type: type };
        }
        var shape = elementFactory.createShape(assign(target, options));

        if (
          type === 'bpmn:SendTask' &&
          (['message-task', 'helpdesk-message-task', 'voice-task', 'sms-task'].includes(options.subType))
        ) {
          shape.businessObject.subType = options.subType;
          shape.businessObject.promptName = '';
          shape.businessObject.template = '';
          shape.businessObject.clipDuration = '';
          shape.businessObject.promptUrl = '';
          shape.businessObject.values = '';
          shape.businessObject.source = 'Source';
          shape.businessObject.templateAsAudioUrl = '';
        } else if (type === 'bpmn:SendTask' && options.subType === 'table-task') {
          shape.businessObject.subType = options.subType;
          shape.businessObject.firstRowIsHeader = '';
          shape.businessObject.firstColumnIsHeader = '';
          shape.businessObject.title = '';
          shape.businessObject.footer = '';
          shape.businessObject.data = '';
          shape.businessObject.definition = '';
        } else if (type === 'bpmn:Task') {
          shape.businessObject.subType = options.subType;
        } else if (type === 'bpmn:ReceiveTask' && options.subType === 'input-task') {
          shape.businessObject.subType = options.subType;
          shape.businessObject.template = options.template || '';
          shape.businessObject.identifierForInput = `choice_${getCurrentChoice()}`;
          shape.businessObject.choices = '[]';
          shape.businessObject.validator = '';
          shape.businessObject.promptUrl = '';
          shape.businessObject.clipDuration = '';
          shape.businessObject.retryCount = '';
          shape.businessObject.inputType = 'text';
        } else if (type === 'bpmn:ReceiveTask' && options.subType === 'carousel-task') {
          shape.businessObject.subType = options.subType;
          shape.businessObject.identifierForSelectedItem = '';
          shape.businessObject.identifierForChoice = '';
          shape.businessObject.carouselItemsData = '';
          shape.businessObject.itemTitleTemplate = '';
          shape.businessObject.itemDescription = '';
          shape.businessObject.itemImage = '';
          shape.businessObject.itemActionPayload = '';
        } else if (type === 'bpmn:ServiceTask') {
          shape.businessObject.subType = options.subType;
          shape.businessObject.method = '';
          shape.businessObject.url = '';
          shape.businessObject.body = '';
          shape.businessObject.requestVariable = '';
          shape.businessObject.identifierForResponse = '';
        } else if (type === 'bpmn:ScriptTask' && options.subType === 'routing-task') {
          shape.businessObject.subType = options.subType;
          shape.businessObject.routingNumber = '';
        } else if (type === 'bpmn:ScriptTask' && options.subType) {
          shape.businessObject.subType = options.subType;
          shape.businessObject.identifierForResult = '';
          shape.businessObject.identifierForInput = '';
          shape.businessObject.template =
            options.subType === 'logic' ? 'Jinja expression goes here' : 'Python code here';
          shape.businessObject.classifierType = '';
        } else if (type === 'bpmn:ParallelGateway') {
          shape.businessObject.subType = options.subType;
          shape.businessObject.timeout = '';
        }

        if (options && !options.isError) {
          shape.businessObject.subType = options.subType;
        }
        autoPlace.append(element, shape);
      }
      : appendStart;

    return {
      group: group,
      className: className,
      title: title,
      action: {
        dragstart: appendStart,
        click: append,
      },
    };
  }

  var cached = bind(this.getContextPadEntries, this);

  this.getContextPadEntries = function (element) {
    var actions = cached(element);

    delete actions['append.text-annotation'];
    delete actions['replace'];
    delete actions['append.append-task'];
    delete actions['append.intermediate-event'];
    delete actions['append.end-event'];

    if (element.type === 'bpmn:EndEvent') {
      delete actions['connect'];
    }
    if (
      element.type.includes('Task') ||
      element.type.includes('StartEvent') ||
      element.type.includes('Gateway') ||
      element.type.includes('CallActivity')
    ) {
      assign(actions, {
        'append.gateway': appendAction(
          'bpmn:ExclusiveGateway',
          'model',
          'exclusive-gateway',
          translate('Exclusive Gateway')
        ),
        'append.parallel-gateway': appendAction(
          'bpmn:ParallelGateway',
          'model',
          'parallel-gateway',
          translate('Parallel Gateway'),
          { subType: 'parallel-gateway' }
        ),
        'append.error-event': appendAction(
          'bpmn:EndEvent',
          'model',
          'error-event',
          translate('Error Event'),
          { isError: true }
        ),
        'append.end-event': appendAction(
          'bpmn:EndEvent',
          'model',
          'end-event',
          translate('End Event')
        ),
        'append.message-task': appendAction(
          'bpmn:SendTask',
          'connect',
          'message-task',
          translate('Message Task'),
          { template: '', subType: 'message-task' }
        ),
        'append.input-task': appendAction(
          'bpmn:ReceiveTask',
          'connect',
          'input-task',
          translate('Input Task'),
          { subType: 'input-task' }
        ),
        'append.table-task': appendAction(
          'bpmn:SendTask',
          'connect',
          'table-task',
          translate('Table Task'),
          { subType: 'table-task' }
        ),
        'append.carousel-task': appendAction(
          'bpmn:ReceiveTask',
          'connect',
          'carousel-task',
          translate('Carousel Task'),
          { subType: 'carousel-task' }
        ),
        'append.service-task': appendAction(
          'bpmn:ServiceTask',
          'connect',
          'service-task',
          translate('API Call Task'),
          { subType: 'service-task' }
        ),
        'append.script-logic-task': appendAction(
          'bpmn:ScriptTask',
          'connect',
          'logic-task',
          translate('Logic Task'),
          { subType: 'logic-task' }
        ),
        'append.script-logic-python-task': appendAction(
          'bpmn:ScriptTask',
          'connect',
          'logic-python-task',
          translate('Logic (python) Task'),
          { subType: 'logic-python-task' }
        ),
        'append.classifier-task': appendAction(
          'bpmn:ScriptTask',
          'connect',
          'classifier-task',
          translate('Classifier Task'),
          { subType: 'classifier-task' }
        ),
        'append.helpdesk-message-task': appendAction(
          'bpmn:SendTask',
          'connect',
          'helpdesk-message-task',
          translate('Helpdesk Message Task'),
          {
            subType: 'helpdesk-message-task',
          }
        ),
        'append.sms-task': appendAction(
          'bpmn:SendTask',
          'connect',
          'sms-task',
          translate('Sms Task'),
          {
            subType: 'sms-task',
          }
        ),
        'append.routing-task': appendAction(
          'bpmn:ScriptTask',
          'connect',
          'routing-task',
          translate('Routing Task'),
          {
            subType: 'routing-task'
          }
        ),
        'append.call-activity-task': appendAction(
          'bpmn:CallActivity',
          'connect',
          'call-activity',
          translate('Subflow'),
        ),
      });
      if (ENABLE_VOICE_TASK === 'true')
        assign(actions, {
          'append.voice-task':  appendAction(
            'bpmn:SendTask',
            'connect',
            'voice-task',
            translate('Voice Task'),
            {
              subType: 'voice-task',
            }
          )
        })
    }

    if (element.type === 'bpmn:SequenceFlow' && element.source?.type === 'bpmn:ExclusiveGateway') {
      assignAction(element, modeling, translate, actions, flowActions.timeout);
      assignAction(element, modeling, translate, actions, flowActions.event);
    }

    if (element.type === 'bpmn:SequenceFlow' && element.source?.type === 'bpmn:ParallelGateway') {
      assignAction(element, modeling, translate, actions, flowActions.main);
      assignAction(element, modeling, translate, actions, flowActions.timeout);
    }

    return actions;
  };

  function getCurrentChoice() {
    var elements = elementRegistry.filter(
      element => element.type === 'bpmn:ReceiveTask' && element.businessObject.identifierForInput?.startsWith('choice_')
    );
    let numChoice =
      parseInt(
        getMaxNum(
          elements
            .map(el => el.businessObject.identifierForInput.replace('choice_', ''))
            .filter(x => !isNaN(parseInt(x)))
        ) || 0
      ) + 1;
    return numChoice;
  }
}

inherits(CallbotContextPadProvider, ContextPadProvider);

CallbotContextPadProvider.$inject = ['injector', 'connect', 'translate', 'elementRegistry'];
