import React, { useRef } from 'react';
import styled from 'styled-components';
import * as mixins from '../../../styles/mixins';
import * as colors from '../../../styles/colors';
import * as typography from '../../../styles/typography';
import RadioIcon from '../icons/tools/RadioIcon';
import { useVersionHover } from "./useVersionHover";

const DiagramVersionLabel = styled.p`
  margin-left: 8px;
  ${mixins.fontStyle(typography.textFontSize, typography.normal)};
`;

const DiagramVersionMakeLiveLabel = styled(DiagramVersionLabel)`
  width: 70px;
  color: rgba(255, 255, 255, 0);
`;

const DiagramVersionUnmakeLiveLabel = styled(DiagramVersionLabel)`
  width: 70px;
  color: rgba(255, 255, 255, 0);
`;

const DiagramVersionLiveLabel = styled(DiagramVersionLabel)`
  width: 70px;
  color: ${colors.alternativeFontColor};

  &.test {
    color: ${colors.mainIconColor};
  }
`;

const DiagramVersionInfoContent = styled.div`
  position: relative;
  ${mixins.flex('flex-start')};
`;

const DiagramVersionWrapper = styled.div`
  min-height: 28px;
  box-sizing: border-box;
  padding: 0 24px;
  ${mixins.flex('space-between', 'flex-start', 'row', 'wrap')};

  &:hover {
    & svg path {
      stroke: ${colors.extraFontColorWithOpacity};
    }
  }

  &:hover {
    background-color: ${colors.backgroundColor};
  }

  &:hover ${DiagramVersionMakeLiveLabel} {
    color: ${colors.extraFontColorWithOpacity};
  }
`;

const DiagramVersionButton = styled.div`
  margin-left: 10px;
  cursor: pointer;
  ${mixins.flex()};

  & svg path {
    stroke: rgba(255, 255, 255, 0);
  }

  &:hover ${DiagramVersionLiveLabel} {
    display: none;
  }

  &:hover ${DiagramVersionMakeLiveLabel} {
    color: ${colors.alternativeFontColor};
  }

  &:hover ${DiagramVersionUnmakeLiveLabel} {
    color: ${colors.alternativeFontColor};
  }

  &.test:hover ${DiagramVersionMakeLiveLabel} {
    color: ${colors.mainIconColor};
  }

  &.test:hover ${DiagramVersionUnmakeLiveLabel} {
    color: ${colors.mainIconColor};
  }

  &:hover {
    & svg path {
      stroke: ${colors.alternativeIconColor};
    }
  }

  &.test:hover {
    & svg path {
      stroke: ${colors.mainIconColor};
    }
  }

  ${props =>
    props.active
      ? `
                & ${DiagramVersionUnmakeLiveLabel} {
                    display: none;
                }
                
                &:hover ${DiagramVersionUnmakeLiveLabel} {
                    display: initial;
                }
            
                & svg path {
                    stroke: ${colors.alternativeIconColor} !important; 
                }
                
                &.test svg path {
                    stroke: ${colors.mainIconColor} !important; 
                }
            `
      : `
            
            `}
`;

const DiagramVersionCurrentMark = styled.div`
  position: absolute;
  top: calc(50% - 3px);
  left: 0;
  transform: translateX(-15px);
  width: 6px;
  height: 6px;
  background-color: ${colors.extraFontColor};
  border-radius: 50%;
`;

const DiagramVersionInfo = styled.p`
  cursor: pointer;
  ${props =>
    props.isCurrent
      ? `
        color: ${colors.extraFontColor};
        ${mixins.fontStyle(typography.labelFontSize, typography.semibold)};
    `
      : `
        color: ${colors.mainFontColor};
        ${mixins.fontStyle(typography.labelFontSize, typography.normal)};
    `};

  &:hover {
    color: ${colors.alternativeFontColor};
  }

  &:hover ~ ${DiagramVersionCurrentMark} {
    background-color: ${colors.alternativeFontColor};
  }

  & span {
    margin-left: 10px;
  }
`;

const DiagramVersionCreatedByContent = styled.div`
  position: relative;
  width: 150px;
  ${mixins.flex('flex-end')};
`;

const DiagramVersionCreatedByLabelContainer = styled.div`
  overflow: hidden;
  pointer-events: none;
  opacity: 0;
  position: absolute;
  top: 0;
  right: 0;
  transform: translateX(-30px);
  height: 24px;
  box-sizing: border-box;
  padding: 6px 8px;
  background-color: ${colors.mainFontColor};
  ${mixins.flex()};
`;

const DiagramVersionCreatedByLabel = styled.p`
  float: left;
  color: ${colors.white};
  ${mixins.fontStyle(typography.textFontSize, typography.semibold)};
`;

const DiagramVersionCreatedBy = styled.div`
  width: 24px;
  height: 24px;
  background-color: ${colors.mainFontColor};
  border-radius: 50%;
  ${mixins.flex()};
  cursor: pointer;

  &:hover + ${DiagramVersionCreatedByLabelContainer} {
    opacity: 1;
  }
`;

const DiagramVersionCreatedByInitials = styled.p`
  color: ${colors.white};
  ${mixins.fontStyle(typography.smallLabelFontSize, typography.semibold)};
  text-transform: uppercase;
`;

const DiagramVersionDescription = styled.p`
  color: ${colors.darkBlueWithOpacity};
  ${mixins.fontStyle(typography.labelFontSize, typography.normal)};
  margin-right: 7px;
`;

const DiagramVersion = ({
  isFirst,
  version,
  isTest,
  isLive,
  isCurrent,
  onDiagramVersionClick,
  onChangeVersionStatus,
  showCreatedBy,
  isCommentVisible,
  canDelete,
  onDeleteVersionClick,
}) => {
  const elementRef = useRef()
  const {
    setVersionAndElement
  } = useVersionHover();



  return (
    <DiagramVersionWrapper
      active={isLive}
      ref={elementRef}
    >
      <DiagramVersionInfoContent>
        <DiagramVersionInfo
          isCurrent={isCurrent || false}
          onClick={onDiagramVersionClick}
          onMouseOver={() => {
            setVersionAndElement(version, elementRef.current);
          }}
          onMouseOut={() => {
            setVersionAndElement(null, null);
          }}
        >
          {version.id} - {version.createdDate}
          <span>
            (
            {version.comment && isCommentVisible
              ? version.comment.length > 24
                ? version.comment.substring(0, 24) + '...'
                : version.comment
              : version.label}
            )
          </span>
        </DiagramVersionInfo>
        {isCurrent ? <DiagramVersionCurrentMark /> : ''}
        <DiagramVersionButton
          className="test"
          active={isTest}
          onClick={onChangeVersionStatus(isTest ? 'remove-test' : 'test')}
        >
          {isTest ? (
            <>
              <RadioIcon color={colors.mainIconColor} />
              <DiagramVersionLiveLabel className="test">Test</DiagramVersionLiveLabel>
              <DiagramVersionUnmakeLiveLabel>Untest</DiagramVersionUnmakeLiveLabel>
            </>
          ) : (
            <>
              <RadioIcon color={colors.extraFontColorWithOpacity} />
              <DiagramVersionMakeLiveLabel>To test</DiagramVersionMakeLiveLabel>
            </>
          )}
        </DiagramVersionButton>
        <DiagramVersionButton active={isLive} onClick={onChangeVersionStatus(isLive ? 'remove-live' : 'live')}>
          {isLive ? (
            <>
              <RadioIcon color={colors.alternativeIconColor} />
              <DiagramVersionLiveLabel>Live</DiagramVersionLiveLabel>
              <DiagramVersionUnmakeLiveLabel>Unlive</DiagramVersionUnmakeLiveLabel>
            </>
          ) : (
            <>
              <RadioIcon color={colors.extraFontColorWithOpacity} />
              <DiagramVersionMakeLiveLabel>To live</DiagramVersionMakeLiveLabel>
            </>
          )}
        </DiagramVersionButton>
        {!(isLive || isTest) && (
          <DiagramVersionButton active={canDelete} onClick={onDeleteVersionClick}>
            <DiagramVersionMakeLiveLabel>Delete</DiagramVersionMakeLiveLabel>
          </DiagramVersionButton>
        )}
      </DiagramVersionInfoContent>
      {showCreatedBy ? (
        version.createdBy ? (
          <DiagramVersionCreatedByContent>
            {isFirst ? <DiagramVersionDescription>Created by:</DiagramVersionDescription> : ''}
            <DiagramVersionCreatedBy>
              <DiagramVersionCreatedByInitials>
                {version.createdBy.firstName.substr(0, 1) + version.createdBy.lastName.substr(0, 1)}
              </DiagramVersionCreatedByInitials>
            </DiagramVersionCreatedBy>
            <DiagramVersionCreatedByLabelContainer>
              <DiagramVersionCreatedByLabel>
                {version.createdBy.firstName + ' ' + version.createdBy.lastName}
              </DiagramVersionCreatedByLabel>
            </DiagramVersionCreatedByLabelContainer>
          </DiagramVersionCreatedByContent>
        ) : (
          ''
        )
      ) : (
        ''
      )}
    </DiagramVersionWrapper>
  );
};

export default DiagramVersion;
