import React, {useRef, useState, useEffect} from 'react';
import {ChoicesWithOptions} from "./ElementPropertyInputTypes/ChoicesWithOptions";
import {Choices} from "./ElementPropertyInputTypes/Choices";
import {ElementPropInput} from "./ElementPropertyInputTypes/utils/CommonElements";
import {CodeEditor} from "./ElementPropertyInputTypes/CodeEditor";
import {Template} from "./ElementPropertyInputTypes/Template";
import {Description} from "./ElementPropertyInputTypes/Description";
import {Checkbox} from "./ElementPropertyInputTypes/Checkbox";
import {CheckboxSwitch} from "./ElementPropertyInputTypes/CheckboxSwitch";
import {Classifier} from "./ElementPropertyInputTypes/Classifier";
import {Api} from "./ElementPropertyInputTypes/Api";
import {DiagramVersionId} from "./ElementPropertyInputTypes/DiagramVersionId";
import {DiagramId} from "./ElementPropertyInputTypes/DiagramId";
import {Owners} from "./ElementPropertyInputTypes/Owners";
import Options from "./ElementPropertyInputTypes/Options";
import {SelectWithStateOptions} from "./ElementPropertyInputTypes/SelectWithStateOptions";
import FileUpload from "./ElementPropertyInputTypes/FileUpload";
import styled from "styled-components";

export const CheckboxWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 32px;
`


const ElementPropertyGenericInput = ({
                                       canvas,
                                       modeling,
                                       moddle,
                                       currentElement,
                                       elementPropKey,
                                       type,
                                       options,
                                       value,
                                       values = [],
                                       onChangeEffect,
                                       isBlocked,
                                       extensionElements,
                                       owners,
                                       ownersList,
                                       ownersSearchString,
                                       fetchUsersList,
                                       updateOwnersSearchString,
                                       updateOwnersList,
                                       currentValue,
                                       changeCurrentValue,
                                       isOpen,
                                       changeIsOpen,
                                       isEditorExpanded,
                                       templateHeight,
                                       optionsKey
                                     }) => {
  const [isEditorReady, setIsEditorReady] = useState(false);
  const valueGetter = useRef();
  const [observer, setObserver] = useState(undefined);

  function handleEditorDidMount(_valueGetter) {
    setIsEditorReady(true);
    valueGetter.current = _valueGetter;
  }

  useEffect(() => {
    if (isEditorReady) {
      if (observer) {
        observer.disconnect();
      }

      const editorElem = document.getElementsByClassName('monaco-editor')[0];
      let currentObserver = new MutationObserver(function (event) {
        if (!editorElem.classList.contains('focused')) {
          modeling.updateProperties(currentElement, {
            [elementPropKey]: currentValue,
          });
        }
      });

      setObserver(currentObserver);

      currentObserver.observe(editorElem, {
        attributes: true,
        attributeFilter: ['class'],
        childList: false,
        characterData: false,
      });
    }
  }, [isEditorReady, currentValue]);

  switch (type) {
    case 'code':
    case 'expression':
      return <CodeEditor
        currentValue={currentValue}
        changeCurrentValue={changeCurrentValue}
        isBlocked={isBlocked}
        isEditorExpanded={isEditorExpanded}
        handleEditorDidMount={handleEditorDidMount}
      />
    case 'choices':
      return Array.isArray(options) ? (
        <ChoicesWithOptions
          currentValue={currentValue}
          changeCurrentValue={changeCurrentValue}
          elementPropKey={elementPropKey}
          options={options}
          canvas={canvas}
          modeling={modeling}
          currentElement={currentElement}
          isBlocked={isBlocked}
        />
      ) : (
        <Choices
          isBlocked={isBlocked}
          currentElement={currentElement}
          changeCurrentValue={changeCurrentValue}
          currentValue={currentValue}
          elementPropKey={elementPropKey}
          modeling={modeling}
        />
      );

    case 'template':
      return <Template
        handleEditorDidMount={handleEditorDidMount}
        isEditorExpanded={isEditorExpanded}
        isBlocked={isBlocked}
        changeCurrentValue={changeCurrentValue}
        currentValue={currentValue}
        height={templateHeight}
      />
    case 'description':
      return <Description
        currentValue={currentValue}
        changeCurrentValue={changeCurrentValue}
        isEditorExpanded={isEditorExpanded}
        modeling={modeling}
        currentElement={currentElement}
        elementPropKey={elementPropKey}
      />
    case 'owners':
      return <Owners
        currentElement={currentElement}
        isBlocked={isBlocked}
        modeling={modeling}
        owners={owners}
        changeIsOpen={changeIsOpen}
        isOpen={isOpen}
        extensionElements={extensionElements}
        updateOwnersSearchString={updateOwnersSearchString}
        ownersSearchString={ownersSearchString}
        updateOwnersList={updateOwnersList}
        fetchUsersList={fetchUsersList}
        moddle={moddle}
      />
    case 'diagramId':
      return <DiagramId
        values={values}
        value={value}
        onChangeEffect={onChangeEffect}
        elementPropKey={elementPropKey}
        modeling={modeling}
        currentElement={currentElement}
      />
    case 'diagramVersionId':
      return <DiagramVersionId
        modeling={modeling}
        currentElement={currentElement}
        elementPropKey={elementPropKey}
        isBlocked={isBlocked}
        value={value}
        onChangeEffect={onChangeEffect}
        values={values}
      />
    case 'api':
      return <Api
        value={value}
        isBlocked={isBlocked}
        currentElement={currentElement}
        elementPropKey={elementPropKey}
        modeling={modeling}
      />
    case 'classifier':
      return <Classifier
        value={value}
        isBlocked={isBlocked}
        currentElement={currentElement}
        elementPropKey={elementPropKey}
        modeling={modeling}
      />
    case 'select-state-options':
      return <SelectWithStateOptions
        value={value}
        isBlocked={isBlocked}
        currentElement={currentElement}
        elementPropKey={elementPropKey}
        modeling={modeling}
        stateKey={optionsKey}
      />;
    case 'options':
      return <Options value={value} options={options}
                      onChange={(e) => modeling.updateProperties(currentElement, {[elementPropKey]: e.target.value})}/>
    case 'checkbox':
      return <Checkbox
        elementPropKey={elementPropKey}
        modeling={modeling}
        currentElement={currentElement}
        changeCurrentValue={changeCurrentValue}
        currentValue={currentValue}
        isBlocked={isBlocked}
      />
    case 'checkbox-input':
      return <CheckboxWrapper>
        <CheckboxSwitch
          elementPropKey={elementPropKey}
          modeling={modeling}
          currentElement={currentElement}
          changeCurrentValue={(selected) => {
            changeCurrentValue(JSON.stringify({
              selected,
              value: JSON.parse(currentValue || '{}').value
            }))
          }}
          currentValue={currentValue}
          isBlocked={isBlocked}
        />
        {
          JSON.parse(currentValue || '{}').selected &&
          <ElementPropInput
            className="element-property-input"
            value={JSON.parse(currentValue || '{}').value || ''}
            disabled={isBlocked}
            onChange={e => {
              changeCurrentValue(JSON.stringify({
                selected: JSON.parse(currentValue || '{}').selected,
                value: e.target.value
              }));
            }}
            onBlur={e => {
              modeling.updateProperties(currentElement, {
                [elementPropKey]: currentValue,
              });
            }}
          />
        }
      </CheckboxWrapper>
    case 'file':
      return <FileUpload
        value={value}
        disabled={isBlocked}
        modeling={modeling}
        currentElement={currentElement}
        elementPropKey={elementPropKey}
      />
    default:
      return (
        <ElementPropInput
          className="element-property-input"
          value={currentValue || ''}
          disabled={isBlocked}
          onChange={e => {
            changeCurrentValue(e.target.value);
          }}
          onBlur={e => {
            modeling.updateProperties(currentElement, {
              [elementPropKey]: currentValue,
            });
          }}
        />
      );
  }
};

export default ElementPropertyGenericInput;
