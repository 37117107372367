import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './QualityAssessmentHeader.scss';
import ArrowIcon from '../../icons/Arrow';
import { Select, Button, Tooltip, Switch } from 'antd';
import LabelVersions from '../../metrics/Plots/LabelVersions';
import * as mixins from '../../callbot-metrics/styles/mixins';
import * as colors from '../../callbot-metrics/styles/colors';
import * as typography from '../../callbot-metrics/styles/typography';
import { PieChart, Pie, Legend, Tooltip as ReChartsTooltip, ResponsiveContainer } from 'recharts';
import MetricsService from '../../../services/MetricsService';
import moment from 'moment';

const data01 = [
  { name: 'Group A', value: 400 },
  { name: 'Group B', value: 300 },
  { name: 'Group C', value: 300 },
  { name: 'Group D', value: 200 },
  { name: 'Group E', value: 278 },
  { name: 'Group F', value: 189 },
];

const data02 = [
  { name: 'Group A', value: 2400 },
  { name: 'Group B', value: 4567 },
  { name: 'Group C', value: 1398 },
  { name: 'Group D', value: 9800 },
  { name: 'Group E', value: 3908 },
  { name: 'Group F', value: 4800 },
];
import styled from 'styled-components';
const Content = styled.div`
  width: 100%;
  box-sizing: border-box;
  margin-bottom: 30px;
  ${mixins.respondTo.desktop`
        // padding: 40px 30px;

        ${mixins.flex('space-between', 'flex-start', 'row', 'wrap')};
    `};

  ${mixins.respondTo.desktopLarge`
        // padding: 40px 7%;
    `};
`;
const Title = styled.p`
  color: ${colors.darkBlue};
  ${mixins.fontStyle(typography.biggestTitleFontSize, typography.semibold)};
  margin-bottom: 20px !important;

  ${mixins.respondTo.tablet`
        margin-bottom: 40px !important;
    `};
`;
const CardTitle = styled.p`
  background-color: #dbdee2;
  font-weight: bold;
  padding: 6px;
  padding-left: 12px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
`;
const Card = styled.div`
  width: 100%;
  background-color: ${colors.white};
  // box-shadow: 0 1px 2px rgba(0,0,0,0.07),
  //             0 2px 4px rgba(0,0,0,0.07),
  //             0 4px 8px rgba(0,0,0,0.07),
  //             0 8px 16px rgba(0,0,0,0.07),
  //             0 16px 32px rgba(0,0,0,0.07),
  //             0 32px 64px rgba(0,0,0,0.07);

  filter: drop-shadow(0px 8px 16px rgba(0, 51, 167, 0.08));

  box-sizing: border-box;
  padding: 20px;
  padding-bottom: 0px !important;
  ${mixins.respondTo.tablet`
        padding: 40px 20px;
    `};
`;
const SmallCard = styled(Card)`
  padding: 0px;
  height: 100%;
  ${mixins.respondTo.desktop`
        width: 100%;
    `};
`;

const QualityAssessmentHeader = ({
  selectedFailureReason,
  setSelectedFailureReason,
  labels,
  pieChartReasons,
  labelVersions,
  totalCount,
  limit,
  setLimit,
  page,
  setPage,
  selectedLabel,
  selectedLabelVersion,
  selectedBranch,
  showMarkedChats,
  setShowMarkedChats,
  setSelectedLabel,
  setSelectedLabelVersion,
  setSelectedBranch,
  currentUser,
  chats,
  chatContainsBranch,
  qualityAssessmentsLoading,
}) => {
  let renderCustomizedLabel = function (entry) {
    return entry.name.length > 10 ? `${entry.name.substring(0, 10)}...` : entry.name;
  };
  const [showFcrChart, setShowFcrChart] = useState(false);
  const [chartDict, setChartDict] = useState({});
  const [branches, setBranches] = useState([]);
  const [branchContactMetrics, setBranchContactMetrics] = useState([]);
  const [branchFcrMetrics, setBranchFcrMetrics] = useState([]);
  const [searchLabel, setSearchLabel] = useState('');
  useEffect(() => {
    window.addEventListener('resize', updateSize);
    let timer1 = setTimeout(() => setShowFcrChart(true), 1500);
    return () => {
      clearTimeout(timer1);
    };
  }, []);

  let selectedLabelRecord = labels?.find(label => label.id == selectedLabel);
  const canLoadNextPage = (page - 1) * limit + (chats?.length || 0) < totalCount;
  const canLoadPreviousPage = page > 1;
  const previousPage = () => (canLoadPreviousPage ? setPage(page - 1) : null);
  const nextPage = () => (canLoadNextPage ? setPage(page + 1) : null);
  const [labelsWithInfo, setLabelsWithInfo] = useState(labels);
  const updateSize = () => {
    if (document.getElementById('branch-list')) {
      document.getElementById('branch-list').style.height = `${document.getElementById('chart-box').clientHeight}px`;
    }
    if (document.getElementById('failure-reasons-chart')) {
      document.getElementById('failure-reasons-chart').style.height = `${
        document.getElementById('chart-box').clientHeight
      }px`;
    }
  };
  const showCharts = !qualityAssessmentsLoading && totalCount !== 0 && labelsWithInfo?.find(l => l.id == selectedLabel);
  useEffect(() => {
    updateSize();
  });
  useEffect(() => {
    let temp = labels
      ?.sort((a, b) => b.is_auxiliary - a.is_auxiliary)
      ?.map((label, labelIndex) => ({
        isAuxilary: label.is_auxiliary,
        key: `${labelIndex}`,
        name: label.name,
        id: label.id,
        ownerId: label.owner_id,
        description: label.description,
        sfm: label.sfm,
      }));
    setLabelsWithInfo(
      temp?.filter(
        label => !label.isAuxilary && label.sfm && (currentUser?.role === 'admin' || label.ownerId === currentUser?.id)
      )
    );
  }, [labels]);

  useEffect(() => {
    getLabelVersionsChartData();
  }, [selectedLabel, labelsWithInfo]);

  useEffect(() => {
    getMetricsForBranches();
  }, [selectedLabel, selectedLabelVersion]);

  const getMetricsForBranches = async () => {
    let labelRecord = labelsWithInfo?.find(l => l.id == selectedLabel);
    if (!labelRecord) return;
    let response = await MetricsService.getBranchMetrics({
      label: labelRecord.name,
      label_version: selectedLabelVersion,
    });
    if (response.status === 200) {
      setBranchContactMetrics(response.data.contacts);
      setBranchFcrMetrics(response.data.fcr);
    }
  };
  const sortDates = data => data.sort((a, b) => new Date(a.dt) - new Date(b.dt));
  const getAverage = data => data.reduce((acc, val) => acc + val.value, 0) / data.length;

  const computeMovingAverage = (data, period) => {
    const movingAverages = [];
    const sortedData = sortDates(data);

    // if the period is greater than the length of the dataset
    // then return the average of the whole dataset
    if (period > sortedData.length) {
      return [
        {
          ...sortedData[0],
          dt: sortedData[0].dt,
          value: getAverage(data),
        },
      ];
    }
    for (let x = 0; x + period - 1 < sortedData.length; x += 1) {
      console.log('sortedData.slice(x, x + period)', sortedData.slice(x, x + period));
      movingAverages.push({
        ...sortedData[x],
        dt: sortedData[x].dt,
        value: getAverage(sortedData.slice(x, x + period)),
      });
    }
    return movingAverages;
  };

  const getLabelVersionsChartData = async () => {
    let labelRecord = labelsWithInfo?.find(l => l.id == selectedLabel);
    if (!labelRecord) return;
    if (!chartDict[selectedLabel]) {
      let response = await MetricsService.getAdminMetrics({
        metrics_name: 'label_versions_fcr',
        // range_start: moment().subtract('30', 'days').format('YYYY-MM-DDT') + '00%3A00',
        time_granularity: 'day',
        label: labelRecord.name,
      });
      setChartDict({
        ...chartDict,
        [selectedLabel]: computeMovingAverage(response.data, 5).map(x => ({
          percent: x.value,
          dt: x.dt,
          changeover: x.changeover,
        })),
      });
    }
  };

  useEffect(() => {
    setBranches(getBranches());
  }, [chats, selectedLabelVersion, labelVersions]);

  const getBranches = () => {
    let versionRecord = filterUpToLiveVersions()?.find(l => l.id == selectedLabelVersion);
    return (versionRecord?.branches || [])
      .sort((a, b) => a.branch - b.branch)
      .map(x => {
        const { calls, fcr } = getBranchMetrics(parseInt(x.branch));
        return {
          branchId: parseInt(x.branch),
          branchName: `Branch ${`${x.branch}`.padStart(2, '0')}`,
          fcr,
          calls,
          tooltip: x.template,
        };
      });
  };

  let getBranchMetrics = branch => {
    const calls = branchContactMetrics?.find(x => x.branch === branch)?.value || 0;
    let fcr = branchFcrMetrics?.find(x => x.branch === branch)?.value || 0;
    fcr = `${Math.round(fcr)}%`;
    return { calls, fcr };
  };

  const formatLabelVersion = versionId => {
    let version = `v${versionId.toString()}`;
    if (versionId == selectedLabelRecord?.sfm?.live?.id?.toString()) version += ' (live)';
    return version;
  };
  const filterUpToLiveVersions = () => {
    return (
      (selectedLabelRecord?.sfm &&
        labelVersions &&
        Object.keys(selectedLabelRecord?.sfm)?.some(key => key == 'live') &&
        labelVersions[selectedLabelRecord?.name]?.filter(option => option.id <= selectedLabelRecord?.sfm?.live?.id)) ||
      undefined
    );
  };
  return (
    <Content>
      <Title>Quality Assessment</Title>
      <Card>
        <div id="qa-header">
          <div className="header-top-row">
            <div id="qa-selectors">
              <div>
                <div className="dropdown-filter">
                  <p className="dropdown-filter-title">Select Solution</p>
                  {
                    <div dir="auto">
                      <Select
                        size={'large'}
                        getPopupContainer={trigger => trigger.parentNode}
                        allowClear
                        showSearch
                        className="dropdown dialog-problem-filter"
                        placeholder="No Solution Specified"
                        loading={!labelsWithInfo}
                        disabled={!labelsWithInfo}
                        filterOption={(input, option) =>
                          option.props.key.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                          option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                        value={selectedLabel}
                        onSearch={value => setSearchLabel(value?.normalize('NFKD'))}
                        searchValue={searchLabel}
                        onChange={setSelectedLabel}
                      >
                        {Array.isArray(labelsWithInfo) &&
                          labelsWithInfo.map(option => (
                            <Option key={option?.id?.toString()} value={option?.id?.toString()}>
                              {option.name}
                            </Option>
                          ))}
                      </Select>
                    </div>
                  }
                </div>
              </div>
              <div>
                <div className="dropdown-filter">
                  <p className="dropdown-filter-title">Solution Version</p>
                  {
                    <Select
                      size={'large'}
                      getPopupContainer={trigger => trigger.parentNode}
                      allowClear
                      showSearch
                      className="dropdown dialog-problem-filter"
                      placeholder="No Solution Version Specified"
                      loading={!labelsWithInfo}
                      disabled={!selectedLabelRecord?.sfm?.live?.id}
                      value={selectedLabelVersion}
                      onChange={setSelectedLabelVersion}
                      filterOption={(input, option) =>
                        option.props.key.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                        option.props.children.indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      {selectedLabelRecord?.sfm &&
                        filterUpToLiveVersions()?.map(option => (
                          <Option key={option?.id?.toString()} value={option?.id?.toString()}>
                            {formatLabelVersion(option.id)}
                          </Option>
                        ))}
                    </Select>
                  }
                </div>
              </div>
              <div>
                <div id="labelling-total-info" style={{ display: 'flex', bottom: 'unset' }}>
                  <div id="labelling-total-row">
                    <p id="labelling-total-row-label">Rows on page</p>
                    <select id="labelling-total-row-select" value={limit} onChange={e => setLimit(e.target.value)}>
                      <option value={10}>10</option>
                      <option value={100}>100</option>
                      <option value={500}>500</option>
                    </select>
                  </div>

                  <p id="labelling-total-count">
                    {(page - 1) * limit + 1} - {(page - 1) * limit + (chats?.length || 0)} total {totalCount}
                  </p>

                  <div id="labelling-pagination">
                    {canLoadPreviousPage ? (
                      <div className="pagination-arrow reverse" onClick={() => previousPage()}>
                        <ArrowIcon />
                      </div>
                    ) : null}
                    {canLoadNextPage ? (
                      <div className="pagination-arrow" onClick={() => nextPage()}>
                        <ArrowIcon />
                      </div>
                    ) : (
                      <div className="no-more-conversation"> No more conversations! </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="header-last-row">
            <div id="chart-box" className="header-col">
              <div id="chart-container" className="chart-container">
                <SmallCard>
                  <CardTitle>First Contact Resolution Trendline</CardTitle>
                  {showCharts && (
                    <div style={{ height: 220, width: '90%' }}>
                      <LabelVersions metrics={showFcrChart ? chartDict[selectedLabel] || [] : []} />
                    </div>
                  )}
                </SmallCard>
              </div>
            </div>
            <div id="failure-reasons-chart" className="header-col">
              <div className="chart-container">
                <SmallCard>
                  <CardTitle>Failure reasons</CardTitle>
                  {showCharts && (
                    <ResponsiveContainer width="100%" height="90%">
                      <PieChart>
                        <Pie
                          dataKey="value"
                          isAnimationActive={false}
                          data={pieChartReasons || []}
                          cx="50%"
                          cy="50%"
                          outerRadius={80}
                          fill="#5C9EF5"
                          label={renderCustomizedLabel}
                        />
                        <ReChartsTooltip />
                      </PieChart>
                    </ResponsiveContainer>
                  )}
                </SmallCard>
              </div>
            </div>
            <div id="branch-list" className="header-col">
              <div className="chart-container">
                <SmallCard>
                  <CardTitle>Branches ({branches.length})</CardTitle>
                  {showCharts && (
                    <div id="branches">
                      {branches.map(branch => (
                        <div style={{ display: 'flex' }}>
                          <Tooltip title={branch.tooltip}>
                            <Button
                              onClick={() => {
                                setSelectedBranch(selectedBranch == branch.branchId ? null : branch.branchId);
                              }}
                              className="branch-btn"
                              type="dashed"
                              block
                              style={
                                selectedBranch == branch.branchId ? { borderColor: '#40a9ff', color: '#40a9ff' } : {}
                              }
                            >
                              <div style={{ fontWeight: 'bold', display: 'inline' }}>{branch.branchName} </div> -{' '}
                              {branch.fcr} FCR, {branch.calls} contacts
                            </Button>
                          </Tooltip>
                          <Link
                            to={`sfm?label=${selectedLabelRecord?.name}&id=${selectedLabelVersion}&highlightBranch=${branch.branchId}`}
                          >
                            <Button className="branch-link-btn">
                              <svg
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M14.25 19.5C15.4926 19.5 16.5 18.4926 16.5 17.25C16.5 16.0074 15.4926 15 14.25 15C13.0074 15 12 16.0074 12 17.25C12 18.4926 13.0074 19.5 14.25 19.5Z"
                                  stroke="#263C73"
                                  strokeWidth="2"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                                <path
                                  d="M14.25 8.5C15.4926 8.5 16.5 7.49264 16.5 6.25C16.5 5.00736 15.4926 4 14.25 4C13.0074 4 12 5.00736 12 6.25C12 7.49264 13.0074 8.5 14.25 8.5Z"
                                  stroke="#263C73"
                                  strokeWidth="2"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                                <path
                                  d="M7 12L12 16"
                                  stroke="#263C73"
                                  strokeWidth="2"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                                <path
                                  d="M7 11L12 7"
                                  stroke="#263C73"
                                  strokeWidth="2"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                                <path
                                  d="M17 6L20.5 6"
                                  stroke="#263C73"
                                  strokeWidth="2"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                                <path
                                  d="M17 17L20.5 17"
                                  stroke="#263C73"
                                  strokeWidth="2"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                                <rect
                                  x="2"
                                  y="9"
                                  width="5"
                                  height="5"
                                  stroke="#263C73"
                                  strokeWidth="2"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </svg>
                            </Button>
                          </Link>
                        </div>
                      ))}
                    </div>
                  )}
                </SmallCard>
              </div>
            </div>
          </div>

          {!labelsWithInfo?.find(l => l.id == selectedLabel) || qualityAssessmentsLoading || totalCount === 0 ? (
            <div id="choose-solution">
              {!labelsWithInfo?.find(l => l.id == selectedLabel)
                ? 'Choose a solution to start quality assessment'
                : qualityAssessmentsLoading
                ? 'Loading data...'
                : 'No conversations found for this solution and version.'}
            </div>
          ) : null}
        </div>
      </Card>
    </Content>
  );
};

export default QualityAssessmentHeader;
