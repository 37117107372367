import $ from 'jquery';

import { getComments, removeComment, addComment, removeComments } from './util';

export default function Comments(eventBus, overlays, bpmnjs) {
  function toggleCollapse(element) {
    var o = overlays.get({ element: element, type: 'comments' })[0];

    var $overlay = o && o.html;

    if ($overlay) {
      var expanded = $overlay.is('.expanded');

      eventBus.fire('comments.toggle', { element: element, active: !expanded });

      if (expanded) {
        $overlay.removeClass('expanded');
      } else {
        $overlay.addClass('expanded');
        $overlay.find('input').focus();
      }
    }
  }

  function createCommentBox(element) {
    var $overlay = $(Comments.OVERLAY_HTML);

    $overlay.find('.toggle').click(function (e) {
      toggleCollapse(element);
    });

    var $commentCount = $overlay.find('[data-comment-count]'),
      $input = $overlay.find('input'),
      $comments = $overlay.find('.comments');

    function timeConverter(UNIX_timestamp) {
      if (!UNIX_timestamp) {
        return '';
      }
      var a = new Date(UNIX_timestamp);
      var months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
      var year = a.getFullYear();
      var month = months[a.getMonth()];
      var date = a.getDate();
      var hour = a.getHours();
      var min = a.getMinutes();
      // var sec = a.getSeconds();
      var time = date + ' ' + month + ' ' + year + '  ' + hour + ':' + min;
      return time;
    }

    function renderComments() {
      // clear innerHTML
      $comments.html('');

      var comments = getComments(element);

      comments.forEach(function (val, index) {
        var $comment = $(Comments.COMMENT_HTML);

        $comment.find('[data-text]').text(val[1]);

        $comment.find('[data-timestamp]').text(timeConverter(parseInt(val[2])));
        if (index === 0) {
          $comment.find('[data-resolved]').addClass('enabled');
        }
        $comment.find('[data-delete]').click(function (e) {
          e.preventDefault();

          removeComment(element, val);
          renderComments();
          $input.val(val[1]);
        });

        $comments.append($comment);
      });

      $overlay[comments.length ? 'addClass' : 'removeClass']('with-comments');

      $commentCount.text(comments.length ? '(' + comments.length + ')' : '');

      eventBus.fire('comments.updated', { comments: comments });
    }

    $input.on('keydown', function (e) {
      if (e.which === 13 && !e.shiftKey) {
        e.preventDefault();

        var comment = $input.val();

        if (comment) {
          addComment(element, '', comment);

          $input.val('');
          renderComments();

          $overlay.find('.comment-resolved').click(function (e) {
            removeComments(element);
            $input.val('');
            toggleCollapse(element);
            renderComments();
          });
        }
      }
    });

    // attach an overlay to a node
    overlays.add(element, 'comments', {
      position: {
        bottom: 10,
        right: 10,
      },
      html: $overlay,
    });

    renderComments();
  }

  eventBus.on('shape.added', function (event) {
    var element = event.element;

    if (element.labelTarget || !element.businessObject.$instanceOf('bpmn:FlowNode')) {
      return;
    }

    defer(function () {
      createCommentBox(element);
    });
  });

  this.collapseAll = function () {
    overlays.get({ type: 'comments' }).forEach(function (c) {
      var html = c.html;
      if (html.is('.expanded')) {
        toggleCollapse(c.element);
      }
    });
  };
}

Comments.$inject = ['eventBus', 'overlays', 'bpmnjs'];

Comments.OVERLAY_HTML =
  '<div class="comments-container">' +
  '<div class="comments-toggle toggle">' +
  '<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">' +
  '<g clip-path="url(#clip0)">' +
  '<rect width="24" height="24" fill="white"/>' +
  '<path d="M12.6001 8.2002V14.2002" stroke="#003C7A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>' +
  '<path d="M9.6001 11.2002H15.6001" stroke="#003C7A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>' +
  '<path d="M6 5H10.9062H19C20.1046 5 21 5.89543 21 7V15.4138C21 16.5184 20.1046 17.4138 19 17.4138H7.1875L4 20V7C4 5.89543 4.89543 5 6 5Z" stroke="#003C7A" stroke-width="1.5" stroke-linejoin="round"/>' +
  '</g>' +
  '<defs>' +
  '<clipPath id="clip0">' +
  '<rect width="24" height="24" fill="white"/>' +
  '</clipPath>' +
  '</defs>' +
  '</svg>' +
  '</div>' +
  '<div class="comments-content">' +
  '<div class="comments"></div>' +
  '<div class="comments-edit">' +
  '<input class="comments-input" placeholder="Input comment"/>' +
  '</div>' +
  '</div>' +
  '</div>';

Comments.COMMENT_HTML =
  '<div class="comment">' +
  '<div class="comment-header">' +
  '<div class="comment-owner">' +
  '<div class="comment-owner-image"></div>' +
  '<div class="comment-info">' +
  '<p class="comment-owner-name">Robert Roosalu</p>' +
  '<p class="comment-date" data-timestamp></p>' +
  '</div>' +
  '</div>' +
  '<div data-resolved class="comment-resolved">' +
  '<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">' +
  '<path d="M20 6L10 16.01L7 13.01" stroke="#0D693D" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>' +
  '</svg>' +
  '</div>' +
  '<div class="comment-options">' +
  '<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">' +
  '<circle cx="12" cy="5" r="2" fill="#D6D6D6"/>' +
  '<circle cx="12" cy="12" r="2" fill="#D6D6D6"/>' +
  '<circle cx="12" cy="19" r="2" fill="#D6D6D6"/>' +
  '</svg>' +
  '</div>' +
  '</div>' +
  '<div data-text class="comment-text"></div><a href class="delete icon-delete" data-delete></a>' +
  '</div>';

// helpers ///////////////

function defer(fn) {
  setTimeout(fn, 0);
}
