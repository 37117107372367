import { useRef, useEffect } from 'react'

/**
 * Hook to memo a func in a ref to avoid updating the effect that uses it when the func changes.
 * @param func function to memoize, does not necessarily need to be a function
 * @returns {React.MutableRefObject<*>} ref to the memoized function
 */
export function useRefFunc(func) {
  const ref = useRef(func);
  useEffect(() => {
    ref.current = func;
  }, [func]);
  return ref;
}
