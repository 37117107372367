import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import {Tooltip} from "antd"
import SegmentsToggle from './SegmentsToggle';
import Chats from './Chats';

const ConversationListComponent = ({
  mode,
  populateChatList,
  populateProblems,
  loggedInUser,
  newSupportList,
  chatChanged,
  supportList,
  loggedInUserObj,
  segmentFilter,
  availChats,
  width,
  toggleSpectator,
  toggleSegmentFilter,
  spectator,
  segments,
  chatId,
  socket,
}) => {
  function chatFilter(chat) {
    const { forMe, activeChats, loggedInUserObj, segmentFilter } = filterStateRef.current;
    if (activeChats && (chat.resolved || !chat.is_open)) {
      return false;
    }

    if (forMe && loggedInUserObj.groups.length > 0) {
      const userGroups = loggedInUserObj.groups.map(group => group.name);
      if (chat.groups.filter(group => userGroups.includes(group)).length === 0) {
        return false;
      }
    }
    for (let key of Object.keys(segmentFilter)) {
      if (chat.customer_segment === key) {
        return segmentFilter[key];
      }
    }
    return false;
  }
  useEffect(() => {
    socket.on('chat list', populateChatList);
    socket.on('available problems', populateProblems);
    socket.on('chat changed', chatChanged(loggedInUser, chatFilter));
    socket.on('customer support list', newSupportList(loggedInUser));
  }, []);

  const [forMe, setForMe] = useState(true);
  const [activeChats, setActiveChats] = useState(true);
  const filterStateRef = useRef({
    forMe,
    activeChats,
    loggedInUserObj,
    segmentFilter,
  });

  segmentFilter = JSON.parse(segmentFilter);
  filterStateRef.current = {
    forMe,
    activeChats,
    loggedInUserObj,
    segmentFilter,
  };

  const toggleForMe = () => setForMe(!forMe);
  const toggleForActiveChats = () => setActiveChats(!activeChats);

  let visibilityElement;
  if (!spectator)
    visibilityElement = <i className="fa fa-eye-slash" title="You are in spectator mode" aria-hidden="true" />;
  else visibilityElement = <i className="fa fa-eye" title="You are online and visible" aria-hidden="true" />;
  return (
    <div className={'col-sm-' + width + ' side'}>
      <div className="side-one left-side">
        {/*Heading*/}

        <div className="row heading vcenter user-heading">
          <div className="col-sm-11 col-xs-11">
            <div className="headerItem currentUser">{loggedInUser}</div>
            <Tooltip placement="bottomRight" title={<div>
            {supportList.map((item, i) => (
              <div className="row userList" key={i}>
                <div className="col-sm-6 col-xs-6">{item.username}</div>
                <div className="col-sm-6 col-xs-6 userRole">{!item.spectator ? 'spectator' : 'online'}</div>
              </div>
            ))}
          </div>} >
            <div className="headerItem supportList" >
              {'Customer support: ' + supportList.length}
            </div>
            </Tooltip>
          </div>
          <div className="col-sm-1 col-xs-1 visibilityElement keep-left" onClick={toggleSpectator}>
            {visibilityElement}
          </div>
        </div>
        {/*End Heading*/}

        <SegmentsToggle
          loggedInUser={loggedInUserObj}
          segments={segments}
          segmentFilter={segmentFilter}
          toggleSegmentFilter={toggleSegmentFilter}
          forMe={forMe}
          toggleForMe={toggleForMe}
          activeChats={activeChats}
          toggleForActiveChats={toggleForActiveChats}
        />

        <Chats
          mode={mode}
          availChats={availChats.filter(chatFilter)}
          loggedInUser={loggedInUser}
          currentChatId={chatId}
        />
      </div>
    </div>
  );
};

/* What attributes from the global state to connect to replybox */
const mapStateToProps = store => {
  return {
    mode: store.appState.mode,
    socket: store.appState.socket,
    segmentFilter: JSON.stringify(store.appState.segmentFilter),
    availChats: store.appState.availChats,
    chatId: store.appState.chatId,
    spectator: store.appState.spectator,
    supportList: store.appState.supportList,
  };
};

/* What update actions to the global state reply box should have */
const mapDispatchToProps = dispatch => {
  return {
    populateChatList: users => {
      dispatch({
        type: 'POPULATE_CHATLIST',
        availChats: users,
      });
    },
    populateProblems: problems =>
      dispatch({
        type: 'POPULATE_PROBLEMS',
        availProblems: problems,
      }),
    chatChanged: (loggedInUser, isResponsibleForChatFunctor) => chat =>
      dispatch({
        type: 'CHAT_CHANGED',
        changeType: chat.field,
        chatElement: chat.chat,
        loggedInUser,
        isResponsibleForChat: isResponsibleForChatFunctor(chat.chat),
      }),
    toggleSpectator: () =>
      dispatch({
        type: 'SPECTATOR_TOGGLED',
      }),
    toggleSegmentFilter: segment =>
      dispatch({
        type: 'TOGGLE_SEGMENT_FILTER',
        segment,
      }),
    newSupportList: loggedInUser => list =>
      dispatch({
        type: 'SUPPORT_LIST',
        list,
        loggedInUser,
      }),
  };
};

/* Export the connected ReplyBox component */
const ConversationList = connect(mapStateToProps, mapDispatchToProps)(ConversationListComponent);

export { ConversationList };
