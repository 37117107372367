const fetchQualityAssessments = (skip, limit, showMarkedChats, label, labelVersion, branch) => {
  return {
    type: 'FETCH_QUALITY_ASSESSMENTS',
    label,
    skip,
    limit,
    showMarkedChats,
    labelVersion,
    branch,
  };
};

const fetchChatsByFailureReason = (label, labelVersion, failureReason) => {
  return {
    type: 'FETCH_CHATS_BY_FAILURE_REASON',
    label,
    labelVersion,
    failureReason,
  };
};

const updateQualityAssessmentsList = qualityAssessments => {
  console.log('QA: ', qualityAssessments);
  return {
    type: 'UPDATE_QUALITY_ASSESSMENTS_LIST',
    data: qualityAssessments,
  };
};
const updateChatsByFailureReasonList = qualityAssessments => {
  console.log('QA: ', qualityAssessments);
  return {
    type: 'UPDATE_CHATS_BY_FAILURE_REASON_LIST',
    data: qualityAssessments,
  };
};
const setQualityAssessmentsLoading = loading => {
  return {
    type: 'SET_QUALITY_ASSESSMENTS_LOADING',
    loading,
  };
};
const createQualityAssessment = (chatId, isChatSolvedByBot, isNewReason, failureReasonId, messageId, branches) => {
  return {
    type: 'CREATE_QUALITY_ASSESSMENT',
    chatId,
    isNewReason,
    isChatSolvedByBot,
    failureReasonId,
    messageId,
    branches,
  };
};

const updateQualityAssessment = (id, chatId, isChatSolvedByBot, failureReasonId) => {
  return {
    type: 'UPDATE_QUALITY_ASSESSMENT',
    id,
    chatId,
    isChatSolvedByBot,
    failureReasonId,
  };
};

const deleteQualityAssessment = id => {
  return {
    type: 'DELETE_QUALITY_ASSESSMENT',
    id,
  };
};

const handleChatNotSolvedByBot = chat => {
  return {
    type: 'HANDLE_CHAT_NOT_SOLVED_BY_BOT',
    chat,
  };
};

const addChatQualityAssessment = qa => {
  return {
    type: 'ADD_CHAT_QUALITY_ASSESSMENT',
    qa,
  };
};

const removeChatQualityAssessment = (id, reason, branches) => {
  return {
    type: 'REMOVE_CHAT_QUALITY_ASSESSMENT',
    id,
    reason,
    branches,
  };
};

const addFailureReason = reason => {
  return {
    type: 'ADD_FAILURE_REASON',
    reason,
  };
};

const fetchFailureReasons = () => {
  return {
    type: 'FETCH_FAILURE_REASONS',
  };
};

const createFailureReason = reason => {
  return {
    type: 'CREATE_FAILURE_REASON',
    reason,
  };
};

const updateFailureReasonsList = failureReasons => {
  return {
    type: 'UPDATE_FAILURE_REASONS_LIST',
    data: failureReasons,
  };
};

const qualityAssessmentActions = {
  fetchQualityAssessments,
  fetchChatsByFailureReason,
  updateChatsByFailureReasonList,
  updateQualityAssessmentsList,
  createQualityAssessment,
  updateQualityAssessment,
  fetchFailureReasons,
  updateFailureReasonsList,
  addChatQualityAssessment,
  handleChatNotSolvedByBot,
  addFailureReason,
  createFailureReason,
  removeChatQualityAssessment,
  deleteQualityAssessment,
  setQualityAssessmentsLoading,
};

export default qualityAssessmentActions;
