import {useCallback, useRef, useState} from 'react';
import {Recorder} from "./pcmRecorder";


export function useAudioRecorder({onStart, onStop, onData}) {
  const recorderRef = useRef(null);
  const data = useRef([]);
  const [state, setState] = useState("WAITING");

  const start = useCallback(async () => {
    const recorder = new Recorder();
    recorder.onaudioprocess = onData;
    recorderRef.current = recorder;
    await recorder.connect();
    onStart && onStart();
    setState("RECORDING");
  }, []);

  const stop = useCallback(async () => {
    recorderRef.current.disconnect();
    onStop && onStop();
    setState("ENDED");
  }, []);

  return {recorder: recorderRef, start, stop, recorderState: state, data};
}
