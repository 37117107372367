const updateSaveProgress = ({ saveInProgress = false, saveProgressStatus = 0 }) => {
  return {
    type: 'UPDATE_SAVE_PROGRESS',
    saveInProgress: saveInProgress,
    saveProgressStatus: saveProgressStatus,
  };
};

const saveProgressActions = {
  updateSaveProgress,
};

export default saveProgressActions;
