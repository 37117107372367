import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { TransitionGroup } from 'react-transition-group'; // ES6

const AutoCompleteTextBox = ({ answer, problemCode, updateAutocomplete }) => {
  const InsertText = () => {
    /* If Elisa chooses a response, update message state with the response text. */
    updateAutocomplete(answer, problemCode);
  };
  return (
    <div className="col-sm-4">
      <div className="autoCompleteTextBox" onClick={InsertText}>
        <p>{answer}</p>
      </div>
    </div>
  );
};

const AutoCompleteText = ({ socket, updateResponses, updateLikely, userData, likelyAnswers, updateAutocomplete }) => {
  useEffect(() => {
    socket.on('available responses', updateResponses);
    socket.on('lemmatized', updateLikely);
  }, []);

  /* We take user data and all recommended answers and fill the blanks with the information we have on the person*/
  likelyAnswers = likelyAnswers.map(obj => {
    obj.answer = obj.answer.replace(/<\w+?>/g, all =>
      Object.prototype.hasOwnProperty.call(userData, all) ? userData[all] : all
    );
    return obj;
  });
  return (
    <div className="col-sm-12 autoCompleteText">
      <TransitionGroup
        transitionname="example"
        transitionappear="true"
        transitionentertimeout={200}
        transitionleavetimeout={200}
        transitionappeartimeout={200}
      >
        {likelyAnswers.map((item, i) => (
          <AutoCompleteTextBox
            key={i}
            answer={item.answer}
            problemCode={item.problemCode}
            updateAutocomplete={updateAutocomplete}
          />
        ))}
      </TransitionGroup>
    </div>
  );
};

/* What attributes from the global state to connect to replybox */
const mapStateToProps = store => {
  return {
    socket: store.appState.socket,
    allAnswers: store.messageState.allAnswers,
    likelyAnswers: store.messageState.likelyAnswers,
    userData: store.appState.userData,
  };
};

/* What update actions to the global state replybox should have */
const mapDispatchToProps = dispatch => {
  return {
    updateAutocomplete: (answer, problemCode) =>
      dispatch({
        type: 'AUTOCOMPLETE_CHOSEN',
        message: answer,
        problemCode: problemCode,
      }),
    updateResponses: responses =>
      dispatch({
        type: 'AVAIL_RESPONSES',
        responses: responses,
      }),
    updateLikely: json =>
      dispatch({
        type: 'LEMMATIZED_ANSWER',
        answer: json.text,
      }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AutoCompleteText);
