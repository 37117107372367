import React from 'react';
import uuid from 'react-uuid';
import styled from 'styled-components';
import * as mixins from '../../../../styles/mixins';
import * as colors from '../../../../styles/colors';
import * as typography from '../../../../styles/typography';

import DiagramInfo from './VersionControlDiagramInfo';
import ArrowIcon from '../../../shared/icons/tools/ArrowIcon';
import VersionControlDiagramVersion from './VersionControlDiagramVersion';
import { DiagramVersionHoverProvider } from "../../../shared/diagram-version/DiagramVersionHoverProvider";
import { DiagramVersionHover } from "../../../shared/diagram-version/DiagramVersionHover";

const DiagramContent = styled.div`
  width: 100%;
  box-sizing: border-box;
  padding: 4px 0;
`;

const DiagramHeader = styled.div`
  width: 100%;
  ${mixins.flex('space-between')};
  box-sizing: border-box;
  padding: 0 24px;
  cursor: pointer;

  &:hover {
    background-color: ${colors.backgroundColor};
  }
`;

const SearchResults = styled.div`
  width: 100%;
  box-sizing: border-box;
  padding: 0 24px;
  color: rgba(0, 15, 60, 0.3);
  max-height: 35px;
  overflow: hidden;
  font-size: 12px;
  max-width: 85%;
  text-align: justify;
`;

const DiagramHeaderLeftSection = styled.div`
  position: relative;
  width: 150px;
  ${mixins.flex('flex-end')};
`;

const DiagramHeaderLeftSectionLabel = styled.p`
  color: ${colors.darkBlueWithOpacity};
  ${mixins.fontStyle(typography.labelFontSize, typography.normal)};
  margin-right: 7px;
`;

const DiagramOwnerLabelContainer = styled.div`
  overflow: hidden;
  pointer-events: none;
  opacity: 0;
  position: absolute;
  top: 0;
  right: 0;
  transform: translateX(-55px);
  height: 24px;
  box-sizing: border-box;
  padding: 6px 8px;
  background-color: ${colors.mainFontColor};
  ${mixins.flex()};
`;

const DiagramOwnerLabel = styled.p`
  float: left;
  color: ${colors.white};
  ${mixins.fontStyle(typography.textFontSize, typography.semibold)};
`;

const DiagramOwner = styled.div`
  width: 24px;
  height: 24px;
  background-color: ${colors.mainFontColor};
  border-radius: 50%;
  ${mixins.flex()};

  &:hover + ${DiagramOwnerLabelContainer} {
    opacity: 1;
  }
`;

const DiagramOwnerInitials = styled.p`
  color: ${colors.white};
  ${mixins.fontStyle(typography.smallLabelFontSize, typography.semibold)};
  text-transform: uppercase;
`;

const DiagramToggler = styled.div`
  padding: 5px;

  ${props =>
    props.isExpanded
      ? `
            & > * {
                -webkit-transform: rotate(180deg);
                transform: rotate(180deg);
                ${mixins.transition(['.1s ease-in'])};
            }
        `
      : `
            & > * {
                ${mixins.transition(['.1s ease-out'])};
            }
        `}
`;

const DiagramVersions = styled.div`
  width: 100%;
  background-color: ${colors.extraBackgroundColor};
  box-sizing: border-box;
  padding: 6px 0;
`;

const DiagramVersionsButton = styled.div`
  height: 36px;
  box-sizing: border-box;
  padding: 0 24px;
  ${mixins.flex('flex-start')};
`;

const DiagramVersionsLabel = styled.p`
  margin-right: 10px;
  color: ${colors.mainFontColor};
  ${mixins.fontStyle(typography.textFontSize, typography.semibold)};
  cursor: pointer;

  &:hover {
    color: ${colors.alternativeFontColor};
  }
`;

const getHighlightedText = (text, highlight) => {
  // Split on highlight term and include term into parts, ignore case
  const parts = text.split(new RegExp(`(${highlight})`, 'gi'));
  return (
    <span>
      {parts.map((part, i) => (
        <span
          key={i}
          style={
            part.toLowerCase() === highlight.toLowerCase() ? { fontWeight: 'bold', textDecoration: 'underline' } : {}
          }
        >
          {part}
        </span>
      ))}
    </span>
  );
};

const VersionControlDiagram = ({
  diagram,
  isExpanded,
  isCurrent,
  versions = [],
  isExpandedHasMoreVersion,
  isCommentVisible,
  changeDiagramVersionStatus,
  deleteDiagramVersion,
  changeExpandedDiagram,
  loadMoreExpandedDiagramVersions,
  showLessExpandedDiagramVersions,
  changeCurrentDiagramAndVersion,
  currentDiagramVersionId,
  error = undefined,
  searchString,
}) => {
  const onDiagramInfoClick = event => {
    event.stopPropagation();
    changeCurrentDiagramAndVersion({
      diagram: diagram,
      versionId: diagram.lastVersion.id,
      shouldSave: false,
    });
  };

  // TODO: As owner object can have diff. keys for diff. endpoints
  //  we need to construct mapper service which will allow to
  //  preconfigure it for such components like this one.
  let ownerInitials;
  let ownerFullName;
  if (diagram.owner) {
    if (diagram.owner.firstName) {
      ownerInitials = diagram.owner.firstName.substr(0, 1) + diagram.owner.lastName.substr(0, 1);
      ownerFullName = diagram.owner.firstName + ' ' + diagram.owner.lastName;
    } else if (diagram.owner.name) {
      ownerInitials = diagram.owner.name.substr(0, 1) + diagram.owner.surname.substr(0, 1);
      ownerFullName = diagram.owner.name + ' ' + diagram.owner.surname;
    }
  }
  return (
    <DiagramContent>
      <DiagramHeader onClick={changeExpandedDiagram}>
        <DiagramInfo
          name={diagram.name}
          version={diagram.lastVersion}
          isCurrent={isCurrent}
          onDiagramInfoClick={onDiagramInfoClick}
        />
        <DiagramHeaderLeftSection>
          <DiagramHeaderLeftSectionLabel>Owner:</DiagramHeaderLeftSectionLabel>
          {diagram.owner ? (
            <>
              <DiagramOwner>
                <DiagramOwnerInitials>{ownerInitials}</DiagramOwnerInitials>
              </DiagramOwner>
              <DiagramOwnerLabelContainer>
                <DiagramOwnerLabel>{ownerFullName}</DiagramOwnerLabel>
              </DiagramOwnerLabelContainer>
            </>
          ) : (
            ''
          )}
          <DiagramToggler isExpanded={isExpanded}>
            <ArrowIcon color={colors.mainIconColor} />
          </DiagramToggler>
        </DiagramHeaderLeftSection>
      </DiagramHeader>
      {isExpanded && versions ? (
        <DiagramVersionHoverProvider>
          <DiagramVersionHover />
          <DiagramVersions>
            {versions.map((version, index) => (
              <VersionControlDiagramVersion
                key={uuid()}
                isFirst={index === 0}
                diagram={diagram}
                version={version}
                canDelete={version.canDelete && versions.length > 1}
                isTest={diagram.versionInTest && diagram.versionInTest.id === version.id}
                isLive={diagram.versionInLive && diagram.versionInLive.id === version.id}
                isCurrent={currentDiagramVersionId === version.id}
                isCommentVisible={isCommentVisible}
                changeCurrentDiagramAndVersion={changeCurrentDiagramAndVersion}
                changeExpandedDiagramVersionStatus={statusAction =>
                  changeDiagramVersionStatus({
                    versionId: version.id,
                    forDiagram: 'expanded',
                    statusAction: statusAction,
                  })
                }
                deleteExpandedDiagramVersion={() => {
                  deleteDiagramVersion({
                    versionId: version.id,
                    forDiagram: 'expanded',
                  });
                }}
              />
            ))}
            <DiagramVersionsButton>
              {isExpandedHasMoreVersion ? (
                <DiagramVersionsLabel onClick={() => loadMoreExpandedDiagramVersions(diagram.id, versions.length)}>
                  Load more +
                </DiagramVersionsLabel>
              ) : (
                ''
              )}
              {versions.length > 3 ? (
                <DiagramVersionsLabel onClick={() => showLessExpandedDiagramVersions()}>Show less -</DiagramVersionsLabel>
              ) : (
                ''
              )}
            </DiagramVersionsButton>
          </DiagramVersions>
        </DiagramVersionHoverProvider>
      ) : (
        ''
      )}
      {diagram.searchResults && (
        <SearchResults>
          <span>
            <span style={{ fontWeight: 'bold' }}>{diagram.searchMatchesCount.toString()}</span> <span> matches: </span>
          </span>
          {getHighlightedText(diagram.searchResults, searchString)}
        </SearchResults>
      )}
    </DiagramContent>
  );
};

export default VersionControlDiagram;
