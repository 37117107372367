import React, { useEffect, useCallback } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

export const useDebouncedEffect = (effect, delay, deps) => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const callback = useCallback(effect, deps);

  useEffect(() => {
    const handler = setTimeout(() => {
      callback();
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [callback, delay]);
};

const SettingsCwcPreview = ({ options }) => {
  useDebouncedEffect(
    () => {
      const cwc = initChatSdk(`${process.env.CWC_DRAFT_URL}/custom_enabled`, {
        userId: 'mart.vaha',
        logLevel: 'warn',
        target: 'chat-sdk',
        options: {
          ...options,
          demo_messages: [
            { type: 'text', role: 'appMaker', text: 'Hello!' },
            {
              type: 'text',
              role: 'appUser',
              text: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
            },
            {
              type: 'text',
              role: 'appUser',
              text: 'Lorem Ipsum.',
            },
            { type: 'text', role: 'appMaker', text: 'Great choice!' },
          ],
        },

        customer: {
          segment: 'Anonymous',
          // customerId: 8296840,
          secure: false,
          // segment: "private",
          // customerFullName: "klient-" + getRandomStringe,
        },
      });

      return cwc.destroy;
    },
    100,
    [JSON.stringify(options)]
  );

  return (
    <div className={'cwc-preview'}>
      <div id="chat-sdk" style={{ width: '400px', height: '800px' }} />

      <div
        id="chat-sdk-btn"
        style={{
          background: `url(${options.icon_url}) no-repeat center center/50% 50%, white`,
        }}
      ></div>
    </div>
  );
};

export default connect()(withRouter(SettingsCwcPreview));
