import React, { useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import moment from 'moment';
import '../../app/components/labelling/chat/message/Message.scss';
import {
  getSenderCaptionFromChannelAndRole,
  getClassNameFromChannelAndRole,
} from '../../app/helpers/messageChannelAndRoleInfo';
import { Image } from 'antd';

const AttachmentIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden="true"
    role="img"
    width="18"
    height="18"
    preserveAspectRatio="xMidYMid meet"
    viewBox="0 0 20 20"
  >
    <path
      fill="#000"
      d="M5.602 19.8c-1.293 0-2.504-.555-3.378-1.44c-1.695-1.716-2.167-4.711.209-7.116l9.748-9.87c.988-1 2.245-1.387 3.448-1.06c1.183.32 2.151 1.301 2.468 2.498c.322 1.22-.059 2.493-1.046 3.493l-9.323 9.44c-.532.539-1.134.858-1.738.922c-.599.064-1.17-.13-1.57-.535c-.724-.736-.828-2.117.378-3.337l6.548-6.63c.269-.272.705-.272.974 0s.269.714 0 .986l-6.549 6.631c-.566.572-.618 1.119-.377 1.364c.106.106.266.155.451.134c.283-.029.606-.216.909-.521l9.323-9.439c.64-.648.885-1.41.69-2.145a2.162 2.162 0 0 0-1.493-1.513c-.726-.197-1.48.052-2.12.7l-9.748 9.87c-1.816 1.839-1.381 3.956-.209 5.143c1.173 1.187 3.262 1.629 5.079-.212l9.748-9.87a.683.683 0 0 1 .974 0a.704.704 0 0 1 0 .987L9.25 18.15c-1.149 1.162-2.436 1.65-3.648 1.65z"
    />
  </svg>
);

const removeSeparator = text => {
  text = text.replace(/"[^"]*mt_internal_separator-/g, `"`);
  text = text.split('-mt_internal_separator-')[text.split('-mt_internal_separator-').length - 1];
  return text;
};

const MessageBox = props => {
  const messagesEnd = useRef(null);
  const scrollToBottom = () => {
    const node = ReactDOM.findDOMNode(messagesEnd.current);
    node.scrollIntoView(false);
  };

  useEffect(() => {
    scrollToBottom();
  }, [props.messages.length, props.someoneTyping]);

  let typingNotif;
  if (!props.someoneTyping) {
    typingNotif = '';
  } else if (props.someoneTyping[0] !== props.loggedInUser && props.someoneTyping.length > 0) {
    typingNotif = 'Someone else is typing...';
  } else typingNotif = '';
  return (
    <div id="conversation">
      <div className="row message-previous" />

      {props.messages.map((item, i) => (
        <Message
          key={i}
          contentType={item.content_type}
          type={item.type}
          url={item.url}
          time={item.time}
          text={item.text}
        />
      ))}

      <div className="typingNotif">{typingNotif}</div>
      <div style={{ float: 'left', clear: 'both' }} ref={messagesEnd} />
    </div>
  );
};

/* What attributes from the global state to connect to replybox */
const mapStateToProps = function (store) {
  return {
    someoneTyping: store.appState.someoneTyping,
  };
};

const Message = props => {
  const [showImage, setShowImage] = useState(true);
  return (
    <div
      className={`message-content ${getClassNameFromChannelAndRole(props.type, props.channel)}`}
      style={{ width: '100%' }}
    >
      {props.contentType === 'file' && showImage ? (
        <Image
          onError={() => setShowImage(false)}
          width={360}
          wrapperStyle={{
            position: 'relative',
            left: props.type === 'client' ? 5 : 0,
            right: props.type !== 'client' ? 5 : 0,
            margin: 5,
          }}
          src={props.url}
        />
      ) : (
        <div className="message" style={{ margin: '4px 10px' }}>
          {props.contentType === 'file' ? (
            <a
              style={{ color: 'unset', textDecoration: 'underline' }}
              className="message-text"
              dir="auto"
              href={props.url}
              download={props.text}
            >
              <div style={{ display: 'inline', marginRight: 5, position: 'relative', top: 4 }}>{AttachmentIcon}</div>
              {props.text}
            </a>
          ) : (
            <p dir="auto" className="message-text">
              {removeSeparator(props.text)}
            </p>
          )}
          <div className="sender-info">
            <span className="message-sender">{`${getSenderCaptionFromChannelAndRole(
              props.type,
              props.channel
            )} `}</span>
            <span className="message-timestamp">
              &#183;{` ${moment(props.time * 1000).format('DD/MM/YYYY - HH:mm')}`}
            </span>
          </div>
        </div>
      )}
    </div>
  );
};

export default connect(mapStateToProps)(MessageBox);
