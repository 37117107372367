import React, {useEffect, useRef, useState} from 'react';
import {useAudioRecorder} from "../../hooks/useAudioRecorder";
import {useWebsocket} from "../../hooks/useWebsocket";
import {Button, Upload} from "antd";
import {AudioTwoTone, AudioMutedOutlined, UploadOutlined} from "@ant-design/icons";
import CircleLoaderIcon from "../shared/icons/tools/CircleLoaderIcon";
import styled, {keyframes} from "styled-components";
import * as colors from "../../styles/colors";
import {useIsMounted} from "../../hooks/useIsMounted";


const VOSK_WS_URL = process.env.VOSK_WS_URL || "wss://rancher-test.int.radiolinja.ee/vosk";
const EOF = '{"eof" : 1}';

const SEND_INTERVAL = 1000;
const BYTES_PER_SECOND = 8000;
const BYTES_PER_INTERVAL = BYTES_PER_SECOND * (SEND_INTERVAL / 1000);

const Transcript = styled.span`
  margin-right: 10px;
  max-width: 400px;
  word-break: break-word;
`

const rotate = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const Loading = styled(CircleLoaderIcon)`
  animation: 1.5s ${rotate} linear infinite;
  margin-right: 10px;
`;

const UploadButton = styled(Button)`
  margin-left: 10px;
`

const Container = styled.div`
  display: flex;
  align-items: center;
  padding: 10px 0 10px 10px;
`;

const WaitingContainer = styled.div`
  display: flex;
  align-items: baseline;
`;

const VoiceInput = ({sendTranscript}) => {
  const [transcript, setTranscript] = useState("");
  const [fileUploadingInterval, setFileUploadingInterval] = useState(null);
  const {executeIfMounted} = useIsMounted();
  const ref = useRef(null);

  useEffect(() => {
    ref.current.scrollIntoView({behavior: "smooth"});
  }, []);

  useEffect(() => {
    return () => {
      if (fileUploadingInterval !== null) {
        clearInterval(fileUploadingInterval);
      }
    }
  }, []);

  const {send} = useWebsocket({
    url: VOSK_WS_URL,
    onMessage: (e) => {
      const message = JSON.parse(e.data);
      if (message.text) {
        setTranscript(t => t + message.text + ". ");
      }
    },
    onClose: () => {
      if (fileUploadingInterval !== null) {
        clearInterval(fileUploadingInterval);
        executeIfMounted(() => setFileUploadingInterval(null));
      }
      executeIfMounted(() => sendTranscript(transcript));
    }
  });

  const {start, stop, recorderState} = useAudioRecorder({
    onData: (data) => {
      send(data);
    },
    onStop: () => {
      send(EOF);
    }
  });

  const beforeUpload = (file) => {
    let current = 0;
    const intervalId = setInterval(() => {
      if (current < file.size) {
        send(file.slice(current, current + BYTES_PER_INTERVAL));
        current += BYTES_PER_INTERVAL;
      } else {
        send(EOF);
        clearInterval(intervalId);
        executeIfMounted(() => setFileUploadingInterval(null));
      }
    }, SEND_INTERVAL);
    setFileUploadingInterval(intervalId);
    return false;
  }


  return (
    <Container ref={ref}>
      {recorderState === "WAITING" && fileUploadingInterval === null && (
        <WaitingContainer>
          <Button onClick={() => start()} icon={<AudioTwoTone/>} type="primary">Start</Button>
          <Upload beforeUpload={beforeUpload}>
            <UploadButton icon={<UploadOutlined/>}>Upload</UploadButton>
          </Upload>
        </WaitingContainer>
      )}
      {(recorderState === "RECORDING" || fileUploadingInterval !== null) && (
        <>
          <Transcript>{transcript}</Transcript>
          <Loading color={colors.mainIconColor}/>
          {fileUploadingInterval === null && <Button onClick={stop} icon={<AudioMutedOutlined/>}>Stop</Button>}
        </>
      )}
    </Container>
  );
}

export default VoiceInput;
