import { EditorWrapper } from "./utils/CommonElements";
import { Editor } from "@monaco-editor/react";
import React from "react";

export const Template = ({
  currentValue, changeCurrentValue, isEditorExpanded, isBlocked, handleEditorDidMount, height
}) => {
  if (!height) {
    height = '112px';
  } else {
    height = 'calc(' + height + ' - 12px)';
  }
  const onTemplateChange = (newValue) => {
    changeCurrentValue(newValue);
  };
  const templateOptions = {
	unicodeHighlight: {
      invisibleCharacters: true,
	},
    minimap: {
      enabled: false,
    },
    wordWrap: 'Off',
    wordWrapColumn: !isEditorExpanded ? 110 : 160,
    wordWrapMinified: true,
    wrappingIndent: 'indent',
    lineNumbers: 'off',
    glyphMargin: false,
    folding: false,
    // Undocumented see https://github.com/Microsoft/vscode/issues/30795#issuecomment-410998882
    lineDecorationsWidth: 0,
    lineNumbersMinChars: 0,
  };
  return (
    <EditorWrapper className={`${isEditorExpanded ? 'large' : ''} ${isBlocked ? 'disabled' : ''}`}>
      <Editor
        width={isEditorExpanded ? '1050px' : '750px'}
        height={isEditorExpanded ? 'calc(50vh - 12px)' : height}
        language="python"
        defaultValue={currentValue}
        options={templateOptions}
        onChange={onTemplateChange}
        onMount={handleEditorDidMount}
      />
    </EditorWrapper>
  );
}
