import React from 'react';
import styled from 'styled-components';
import * as mixins from '../../../styles/mixins';
import * as colors from '../../../styles/colors';
import * as typography from '../../../styles/typography';
import InfoIcon from '../../shared/icons/tools/InfoIcon';
import RadioIcon from '../../shared/icons/tools/RadioIcon';

const HeaderWrapper = styled.div`
  min-width: 650px;
  ${mixins.flex('space-between')};
`;

const HeaderContent = styled.div`
  flex: 1;
  margin-left: 10px;
  ${mixins.flex('space-between')};
`;

const DiagramNameInput = styled.input`
  box-sizing: border-box;
  padding: 0 8px;
  width: ${props => props.size || '195px'};
  color: ${colors.mainFontColor};
  ${mixins.fontStyle(typography.textFontSize, typography.medium)};
`;

const DiagramName = styled.p`
  min-width: 50px;
  min-height: 24px;
  color: ${colors.mainFontColor};
  ${mixins.fontStyle(typography.textFontSize, typography.medium)};
  cursor: initial;
`;

const DiagramVersionInfo = styled.div`
  margin-left: 24px;
  ${mixins.flex('space-between')};
`;

const DiagramLabelContent = styled.div`
  ${mixins.flex('flex-start')};
`;

const DiagramLabelSpan = styled.p`
  margin-right: 5px;
  color: ${colors.mainFontColor};
  ${mixins.fontStyle(typography.labelFontSize, typography.normal)};
`;

const DiagramLabel = styled.p`
  min-width: 50px;
  min-height: 12px;
  color: ${colors.mainFontColor};
  ${mixins.fontStyle(typography.labelFontSize, typography.normal)};
  cursor: initial;
`;

const DiagramLabelInput = styled.input`
  box-sizing: border-box;
  padding: 0 4px;
  width: ${props => props.size || '150px'};
  color: ${colors.mainFontColor};
  ${mixins.fontStyle(typography.labelFontSize, typography.normal)};
`;

const VersionInLiveIcon = styled.div`
  margin-left: 4px;
  height: 24px;
`;

const DiagramPropertiesHeader = ({
  currentDiagram,
  currentDiagramVersion,
  updateCurrentDiagramName,
  updateCurrentDiagramVersionLabel,
  isNameEditable,
  changeNameEditable,
  isLabelEditable,
  changeLabelEditable,
  nameSize,
  labelSize,
}) => {
  return (
    <HeaderWrapper>
      <InfoIcon color={colors.mainIconColor} />
      <HeaderContent>
        {isNameEditable ? (
          <DiagramNameInput
            dir="auto"
            id="diagram-name-input"
            value={currentDiagram.name}
            onChange={event => updateCurrentDiagramName(event.target.value)}
            onBlur={() => changeNameEditable(false)}
            autoFocus={!!isNameEditable}
            size={nameSize}
          />
        ) : (
          <DiagramName dir="auto" id="current-diagram-name" onDoubleClick={() => changeNameEditable(true)}>
            {currentDiagram.name}
          </DiagramName>
        )}
        <DiagramVersionInfo>
          <DiagramLabelContent>
            <DiagramLabelSpan>Label:</DiagramLabelSpan>
            {isLabelEditable ? (
              <DiagramLabelInput
                id="diagram-label-input"
                value={currentDiagramVersion.label}
                onChange={event => updateCurrentDiagramVersionLabel(event.target.value)}
                onBlur={() => changeLabelEditable(false)}
                autoFocus={!!isLabelEditable}
                size={labelSize}
              />
            ) : (
              <DiagramLabel dir="auto" id="current-version-label" onDoubleClick={() => changeLabelEditable(true)}>
                {currentDiagramVersion.label}
              </DiagramLabel>
            )}
          </DiagramLabelContent>
          {currentDiagram.versionInTest && currentDiagramVersion.id === currentDiagram.versionInTest.id ? (
            <VersionInLiveIcon>
              <RadioIcon color={colors.mainIconColor} />
            </VersionInLiveIcon>
          ) : (
            ''
          )}
          {currentDiagram.versionInLive && currentDiagramVersion.id === currentDiagram.versionInLive.id ? (
            <VersionInLiveIcon>
              <RadioIcon color={colors.alternativeIconColor} />
            </VersionInLiveIcon>
          ) : (
            ''
          )}
        </DiagramVersionInfo>
      </HeaderContent>
    </HeaderWrapper>
  );
};

export default DiagramPropertiesHeader;
