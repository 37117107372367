import { assign } from 'min-dash';

/**
 * A palette provider for BPMN 2.0 elements.
 */

const ENABLE_VOICE_TASK = process.env.ENABLE_VOICE_TASK || 'false';

export default function PaletteProvider(
  palette,
  create,
  elementFactory,
  elementRegistry,
  spaceTool,
  lassoTool,
  handTool,
  globalConnect,
  translate
) {
  this._palette = palette;
  this._create = create;
  this._elementFactory = elementFactory;
  this._elementRegistry = elementRegistry;
  this._spaceTool = spaceTool;
  this._lassoTool = lassoTool;
  this._handTool = handTool;
  this._globalConnect = globalConnect;
  this._translate = translate;

  palette.registerProvider(this);
}

PaletteProvider.$inject = [
  'palette',
  'create',
  'elementFactory',
  'elementRegistry',
  'spaceTool',
  'lassoTool',
  'handTool',
  'globalConnect',
  'translate',
];

PaletteProvider.prototype.getPaletteEntries = function (element) {
  var actions = {},
    create = this._create,
    elementFactory = this._elementFactory,
    elementRegistry = this._elementRegistry,
    spaceTool = this._spaceTool,
    lassoTool = this._lassoTool,
    handTool = this._handTool,
    globalConnect = this._globalConnect,
    translate = this._translate;

  function createAction(type, group, className, title, options) {
    function createListener(event) {
      let target;
      if (options && options.isError) {
        target = {
          type: type,
          eventDefinitionType: 'bpmn:ErrorEventDefinition',
        };
      } else {
        target = { type: type };
      }
      var shape = elementFactory.createShape(assign(target, options));

      if (options) {
        shape.businessObject.di.isExpanded = options.isExpanded;
      }

      if (options && options.isEndEvent) {
        shape.businessObject.disableEndQuestion = false;
      }

      create.start(event, shape);
    }

    var shortType = type.replace(/^bpmn:/, '');

    return {
      group: group,
      className: className,
      title: title || translate('Create {type}', { type: shortType }),
      action: {
        dragstart: createListener,
        click: createListener,
      },
    };
  }
  function getMaxNum(arr) {
    if (!arr) {
      return null;
    }
    var maxV = arr[0];
    for (let a of arr) {
      if (a > maxV) maxV = a;
    }
    return maxV;
  }

  function createTask(type, group, className, title, props) {
    function createTaskListener(event) {
      var shape = elementFactory.createShape(assign({ type: type }));
      shape.businessObject.owners = [];
      if (type === 'bpmn:SendTask' && ['message-task', 'helpdesk-message-task', 'voice-task'].includes(props.subType)) {
        shape.businessObject.subType = props.subType;
        shape.businessObject.template = props.template || '';
        shape.businessObject.source = 'Source';
        shape.businessObject.templateAsAudioUrl = '';
      } else if (type === 'bpmn:SendTask' && props.subType === 'table-task') {
        console.log('TABLE IN PALETTE: ', className);
        shape.businessObject.subType = props.subType;
        shape.businessObject.firstRowIsHeader = '';
        shape.businessObject.firstColumnIsHeader = '';
        shape.businessObject.title = '';
        shape.businessObject.footer = '';
        shape.businessObject.data = '';
        shape.businessObject.definition = '';
      } else if (type === 'bpmn:Task') {
        shape.businessObject.subType = props.subType;
      } else if (type === 'bpmn:ReceiveTask' && props.subType === 'input-task') {
        var elements = elementRegistry.filter(
          element =>
            element.type === 'bpmn:ReceiveTask' && element.businessObject.identifierForInput?.startsWith('choice_')
        );
        let numChoice =
          parseInt(
            getMaxNum(
              elements
                .map(el => el.businessObject.identifierForInput.replace('choice_', ''))
                .filter(x => !isNaN(parseInt(x)))
            ) || 0
          ) + 1;
        shape.businessObject.subType = props.subType;
        shape.businessObject.identifierForInput = `choice_${numChoice}`;
        shape.businessObject.identifierForChoice = '';
        shape.businessObject.choices = '[]';
        shape.businessObject.parser = '';
        shape.businessObject.eventFilter = '';
      } else if (type === 'bpmn:ReceiveTask' && props.subType === 'carousel-task') {
        shape.businessObject.subType = props.subType;
        shape.businessObject.carouselItemsData = '';
        shape.businessObject.itemTitleTemplate = '';
        shape.businessObject.itemDescription = '';
        shape.businessObject.itemImage = '';
        shape.businessObject.itemActionPayload = '';
        shape.businessObject.identifierForSelectedItem = '';
        shape.businessObject.identifierForChoice = '';
      } else if (type === 'bpmn:ServiceTask') {
        shape.businessObject.subType = props.subType;
        shape.businessObject.method = '';
        shape.businessObject.url = '';
        shape.businessObject.body = '';
        shape.businessObject.requestVariable = '';
        shape.businessObject.identifierForResponse = '';
      } else if (type === 'bpmn:ScriptTask' && props.subType) {
        shape.businessObject.subType = props.subType;
        shape.businessObject.identifierForResult = '';
        shape.businessObject.template = props.subType === 'logic' ? 'Jinja expression goes here' : 'Python code here';
        shape.businessObject.classifierType = '';
      }

      create.start(event, shape);
    }

    var shortType = type.replace(/^bpmn:/, '');

    return {
      group: group,
      className: className,
      title: title || translate('Create {type}', { type: shortType }),
      action: {
        dragstart: createTaskListener,
        click: createTaskListener,
      },
    };
  }

  function createSubprocess(event) {
    var subProcess = elementFactory.createShape({
      type: 'bpmn:SubProcess',
      x: 0,
      y: 0,
      isExpanded: true,
    });

    var startEvent = elementFactory.createShape({
      type: 'bpmn:StartEvent',
      x: 40,
      y: 82,
      parent: subProcess,
    });

    create.start(event, [subProcess, startEvent], {
      hints: {
        autoSelect: [startEvent],
      },
    });
  }

  function createCallActivity(event) {
    var callActivity = elementFactory.createShape({
      type: 'bpmn:CallActivity',
    });
    callActivity.businessObject.diagramId = null;
    callActivity.businessObject.diagramVersionId = null;
    create.start(event, callActivity);
  }

  /*function createSubprocess(event) {

      var subProcess = elementFactory.createShape({type: 'bpmn:SubProcess'});

      var startEvent = elementFactory.createShape(
          {
              type: 'bpmn:StartEvent',
              parent: subProcess
          }
      );

      create.start(event, subProcess);
  }*/
  /* TODO: return if needed
  function createParticipant(event) {
      create.start(event, elementFactory.createParticipantShape());
  }*/

  assign(
    actions,
    ENABLE_VOICE_TASK === 'true'
      ? {
          /*'hand-tool': {
        group: 'tools',
        className: 'bpmn-icon-hand-tool',
        title: translate('Activate the hand tool'),
        action: {
            click: function(event) {
                handTool.activateHand(event);
            }
        }
    },*/
          'lasso-tool': {
            group: 'tools',
            className: 'bpmn-icon-lasso-tool',
            title: translate('Activate the lasso tool'),
            action: {
              click: function (event) {
                lassoTool.activateSelection(event);
              },
            },
          },
          /* TODO: uncomment this if this tools needed
    'space-tool': {
        group: 'tools',
        className: 'bpmn-icon-space-tool',
        title: translate('Activate the create/remove space tool'),
        action: {
            click: function(event) {
                spaceTool.activateSelection(event);
            }
        }
    },
    'global-connect-tool': {
        group: 'tools',
        className: 'bpmn-icon-connection-multi',
        title: translate('Activate the global connect tool'),
        action: {
            click: function(event) {
                globalConnect.toggle(event);
            }
        }
    },*/
          'tool-separator': {
            group: 'tools',
            separator: true,
          },
          'create.start-event': createAction('bpmn:StartEvent', 'event', 'start-event', translate('Start Event')),
          // 'create.intermediate-event': createAction(
          //     'bpmn:IntermediateThrowEvent', 'event', 'bpmn-icon-intermediate-event-none',
          //     translate('Create Intermediate/Boundary Event')
          // ),
          'create.error-event': createAction('bpmn:EndEvent', 'event', 'error-event', translate('Error Event'), {
            isError: true,
          }),
          'create.end-event': createAction('bpmn:EndEvent', 'event', 'end-event', translate('End Event'), {
            isEndEvent: true,
          }),
          'tasks-separator': {
            group: 'tasks',
            separator: true,
          },
          'create.message-task': createTask('bpmn:SendTask', 'tasks', 'message-task', translate('Message Task'), {
            template: '',
            subType: 'message-task',
          }),
          'create.helpdesk-message-task': createTask(
            'bpmn:SendTask',
            'tasks',
            'helpdesk-message-task',
            translate('Helpdesk Message Task'),
            { template: '', subType: 'helpdesk-message-task' }
          ),
          'create.voice-task': createTask('bpmn:SendTask', 'tasks', 'voice-task', translate('Voice Task'), {
            template: '',
            subType: 'voice-task',
          }),
          // 'create.map-task': createTask(
          //     'bpmn:SendTask',
          //     'tasks',
          //     'map-task',
          //     translate('Map Task'),
          //     {template: '', subType: 'map-task'}
          // ),
          'create.input-task': createTask('bpmn:ReceiveTask', 'tasks', 'input-task', translate('Input Task'), {
            subType: 'input-task',
          }),
          'create.table-task': createTask('bpmn:SendTask', 'tasks', 'table-task', translate('Table Task'), {
            subType: 'table-task',
          }),
          'create.carousel-task': createTask('bpmn:ReceiveTask', 'tasks', 'carousel-task', translate('Carousel Task'), {
            subType: 'carousel-task',
          }),
          // 'create.empty-task': createTask(
          //     'bpmn:Task',
          //     'tasks',
          //     'empty-task',
          //     translate('Empty Task'),
          //     {subType: 'empty-task'}
          // ),
          'create.service-task': createTask('bpmn:ServiceTask', 'tasks', 'service-task', translate('API Call Task'), {
            subType: 'service-task',
          }),
          'create.script-logic-task': createTask('bpmn:ScriptTask', 'tasks', 'logic-task', translate('Logic Task'), {
            subType: 'logic-task',
          }),
          'create.script-logic-python-task': createTask(
            'bpmn:ScriptTask',
            'tasks',
            'logic-python-task',
            translate('Logic (python) Task'),
            { subType: 'logic-python-task' }
          ),
          'create.classifier-task': createTask(
            'bpmn:ScriptTask',
            'tasks',
            'classifier-task',
            translate('Classifier Task'),
            {
              subType: 'classifier-task',
            }
          ),
          'subprocess-separator': {
            group: 'subprocesses',
            separator: true,
          },
          // 'create.subprocess-expanded': {
          //     group: 'activity',
          //     className: 'bpmn-icon-subprocess-expanded',
          //     title: translate('Create expanded SubProcess'),
          //     action: {
          //         dragstart: createSubprocess,
          //         click: createSubprocess
          //     }
          // },
          'create.exclusive-gateway': createAction(
            'bpmn:ExclusiveGateway',
            'gateway',
            'exclusive-gateway',
            translate('Exclusive Gateway')
          ),
          'create.call-activity': {
            group: 'activity',
            className: 'call-activity',
            title: translate('Subflow'),
            action: {
              dragstart: createCallActivity,
              click: createCallActivity,
            },
          },
        }
      : {
          /*'hand-tool': {
        group: 'tools',
        className: 'bpmn-icon-hand-tool',
        title: translate('Activate the hand tool'),
        action: {
            click: function(event) {
                handTool.activateHand(event);
            }
        }
    },*/
          'lasso-tool': {
            group: 'tools',
            className: 'bpmn-icon-lasso-tool',
            title: translate('Activate the lasso tool'),
            action: {
              click: function (event) {
                lassoTool.activateSelection(event);
              },
            },
          },
          /* TODO: uncomment this if this tools needed
    'space-tool': {
        group: 'tools',
        className: 'bpmn-icon-space-tool',
        title: translate('Activate the create/remove space tool'),
        action: {
            click: function(event) {
                spaceTool.activateSelection(event);
            }
        }
    },
    'global-connect-tool': {
        group: 'tools',
        className: 'bpmn-icon-connection-multi',
        title: translate('Activate the global connect tool'),
        action: {
            click: function(event) {
                globalConnect.toggle(event);
            }
        }
    },*/
          'tool-separator': {
            group: 'tools',
            separator: true,
          },
          'create.start-event': createAction('bpmn:StartEvent', 'event', 'start-event', translate('Start Event')),
          // 'create.intermediate-event': createAction(
          //     'bpmn:IntermediateThrowEvent', 'event', 'bpmn-icon-intermediate-event-none',
          //     translate('Create Intermediate/Boundary Event')
          // ),
          'create.error-event': createAction('bpmn:EndEvent', 'event', 'error-event', translate('Error Event'), {
            isError: true,
          }),
          'create.end-event': createAction('bpmn:EndEvent', 'event', 'end-event', translate('End Event'), {
            isEndEvent: true,
          }),
          'tasks-separator': {
            group: 'tasks',
            separator: true,
          },
          'create.message-task': createTask('bpmn:SendTask', 'tasks', 'message-task', translate('Message Task'), {
            template: '',
            subType: 'message-task',
          }),
          'create.helpdesk-message-task': createTask(
            'bpmn:SendTask',
            'tasks',
            'helpdesk-message-task',
            translate('Helpdesk Message Task'),
            { template: '', subType: 'helpdesk-message-task' }
          ),
          // 'create.map-task': createTask(
          //     'bpmn:SendTask',
          //     'tasks',
          //     'map-task',
          //     translate('Map Task'),
          //     {template: '', subType: 'map-task'}
          // ),
          'create.input-task': createTask('bpmn:ReceiveTask', 'tasks', 'input-task', translate('Input Task'), {
            subType: 'input-task',
          }),
          'create.table-task': createTask('bpmn:SendTask', 'tasks', 'table-task', translate('Table Task'), {
            subType: 'table-task',
          }),
          'create.carousel-task': createTask('bpmn:ReceiveTask', 'tasks', 'carousel-task', translate('Carousel Task'), {
            subType: 'carousel-task',
          }),
          // 'create.empty-task': createTask(
          //     'bpmn:Task',
          //     'tasks',
          //     'empty-task',
          //     translate('Empty Task'),
          //     {subType: 'empty-task'}
          // ),
          'create.service-task': createTask('bpmn:ServiceTask', 'tasks', 'service-task', translate('API Call Task'), {
            subType: 'service-task',
          }),
          'create.script-logic-task': createTask('bpmn:ScriptTask', 'tasks', 'logic-task', translate('Logic Task'), {
            subType: 'logic-task',
          }),
          'create.script-logic-python-task': createTask(
            'bpmn:ScriptTask',
            'tasks',
            'logic-python-task',
            translate('Logic (python) Task'),
            { subType: 'logic-python-task' }
          ),
          'create.classifier-task': createTask(
            'bpmn:ScriptTask',
            'tasks',
            'classifier-task',
            translate('Classifier Task'),
            {
              subType: 'classifier-task',
            }
          ),
          'subprocess-separator': {
            group: 'subprocesses',
            separator: true,
          },
          // 'create.subprocess-expanded': {
          //     group: 'activity',
          //     className: 'bpmn-icon-subprocess-expanded',
          //     title: translate('Create expanded SubProcess'),
          //     action: {
          //         dragstart: createSubprocess,
          //         click: createSubprocess
          //     }
          // },
          'create.exclusive-gateway': createAction(
            'bpmn:ExclusiveGateway',
            'gateway',
            'exclusive-gateway',
            translate('Exclusive Gateway')
          ),
          'create.call-activity': {
            group: 'activity',
            className: 'call-activity',
            title: translate('Subflow'),
            action: {
              dragstart: createCallActivity,
              click: createCallActivity,
            },
          },
        }
  );

  return actions;
};
