export const SET_CALLBOT_CLASSIFIERS = 'SET_CALLBOT_CLASSIFIERS';
export const SET_CALLBOT_VALIDATORS = 'SET_CALLBOT_VALIDATORS';

const setCallbotClassifiers = (classifiers) => ({
  type: SET_CALLBOT_CLASSIFIERS,
  payload: classifiers
})

const setCallbotValidators = (validators) => ({
  type: SET_CALLBOT_VALIDATORS,
  payload: validators
})

const callbotActions = {
  setCallbotClassifiers,
  setCallbotValidators
}

export default callbotActions;
