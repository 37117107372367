import { put, call, all, take, takeLatest, spawn, fork } from 'redux-saga/effects';
import NotificationService from '../services/common/NotificationService';
import TasksService from '../services/TasksService';
import taskActions from '../action-creators/taskActions';

function* fetchTasksFlow({ searchParams }) {
  try {
    const response = yield call(TasksService.fetch, searchParams);

    if (response.status === 200) {
      yield put(taskActions.saveTasksList(response.data));
    } else {
      NotificationService.push({
        type: 'error',
      });
    }
  } catch (error) {
    NotificationService.push({
      type: 'error',
    });
  }
}

function* watchFetchTasksListRequested() {
  yield takeLatest('FETCH_TASKS_LIST', fetchTasksFlow);
}

export default function* tasksSaga() {
  yield all([watchFetchTasksListRequested()]);
}
