import { put, call, all, take, takeLatest, spawn, fork } from 'redux-saga/effects';
import NotificationService from '../services/common/NotificationService';
import LoginService from '../services/LoginService';
import StorageService from '../services/common/StorageService';
import authActions from '../action-creators/authActions';

const ENABLE_HEADER = process.env.ENABLE_HEADER || 'true';

function* loginFlow(action) {
  try {
    const response = yield call(LoginService.login, action);

    if (response.status === 200) {
      yield call(StorageService.storeForSession, 'token', response.data.access_token);

      yield call(fetchUserDataFlow);
    } else if (response.status === 422) {
      yield put(authActions.setLoginErrors(response.data.detail.map(d => d.msg)));
      NotificationService.push({
        type: 'error',
      });
    } else if (response.status === 400) {
      yield put(authActions.setLoginErrors([response.data.detail]));
      NotificationService.push({
        type: 'error',
      });
    } else {
      NotificationService.push({
        type: 'error',
      });
    }
  } catch (error) {
    console.log(error);
    NotificationService.push({
      type: 'error',
    });
  }
}

function* fetchUserDataFlow() {
  try {
    const token = yield call(StorageService.getFromSession, 'token');

    let user = null;

    if (!!token) {
      const response = yield call(LoginService.me);

      if (response.status === 200) {
        user = response.data;
      } else if (response.status === 403) {
        yield call(StorageService.removeFromSession, 'token');
      } else {
        NotificationService.push({
          type: 'error',
        });
      }
    }

    yield put(authActions.setActiveUser(user));
  } catch (error) {
    console.log(error);
    if (ENABLE_HEADER === 'true') {
      NotificationService.push({
        type: 'error',
      });
    }
    yield put(authActions.setActiveUser(null));
  }
}

function* logoutFlow(action) {
  try {
    yield call(StorageService.removeFromSession, 'token');
    yield put(authActions.setActiveUser(null));

    NotificationService.push({
      type: 'info',
      message: 'You are now logged out!',
    });
  } catch (error) {
    console.error(error);
    NotificationService.push({
      type: 'error',
    });
  }
}

function* watchLoginRequested() {
  yield takeLatest('LOGIN', loginFlow);
}

function* watchlogoutRequested() {
  yield takeLatest('LOGOUT', logoutFlow);
}

function* loadUserDataOnInitFlow() {
  yield call(fetchUserDataFlow);
}

export default function* authSaga() {
  yield fork(loadUserDataOnInitFlow);

  yield all([watchLoginRequested(), watchlogoutRequested()]);
}
