import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import LabellingHeader from './header/LabellingHeader';
import './Labelling.scss';
import LabellingChat from './chat/LabellingChat';
import chatsActions from '../../action-creators/chatsActions';
import labelsActions from '../../action-creators/labelsActions';
import labellingActions from '../../action-creators/labellingActions';
import FormModal from '../overview/modals/FormModal';
import modalsActions from '../../action-creators/modalsActions';
import usersActions from '../../action-creators/usersActions';
import settingsActions from '../../action-creators/settingsActions';
import withChatDraft from '../utils/chat-draft/withChatDraft';
import { withRoles } from '../utils/roles/withRoles';

// TODO: Move (limit, page) to redux otherwise LabellingHeader can't be used
// more than once

const ENABLE_ACTIVE_LEARNING = process.env.ENABLE_ACTIVE_LEARNING || 'true';

const getJSON = obj => {
  try {
    return JSON.parse(obj);
  } catch (err) {
    return {};
  }
};

const getLabelParser = (obj, label) => {
  obj = getJSON(obj);
  return obj?.main_parser?.[label] || [];
};

const Labelling = ({
  chats,
  totalCount,
  fetchChatsNotLabelled,
  updateChatsNotLabelled,
  fetchLabelsList,
  updateMessageLangLabel,
  location,
  fetchUsersList,
  labels,
  addLabelModalOpen,
  createLabel,
  toggleModal,
  users,
  currentUser,
  createMessageLabelExample,
  settings,
  fetchSettings,
  updateSettings,
}) => {
  const [seed, setSeed] = useState(0);
  const [limit, setLimit] = useState(50);
  const [page, setPage] = useState(1);
  const [labelMode, setLabelMode] = useState(
    ENABLE_ACTIVE_LEARNING === 'true'
      ? (!location?.selectedLabel || location?.selectedLabel === 'uncertain_labels') &&
        !localStorage.getItem('currentLabel')
        ? 'uncertain_labels'
        : 'my_labels'
      : null
  );
  const [selectedLabel, setSelectedLabel] = useState(
    location?.selectedLabel && location?.selectedLabel != 'uncertain_labels'
      ? location?.selectedLabel
      : localStorage.getItem('currentLabel')
  );
  const [addLabel, setAddLabel] = useState('');
  const [firstRender, setfirstRender] = useState(false);
  const [initialPromptLabelling, setInitialPromptLabelling] = useState(false);
  const [showLabelled, setShowLabelled] = useState(
    location?.selectedLabel || localStorage.getItem('currentLabel') ? true : false
  );
  const [selectedLanguage, setSelectedLanguage] = useState(null);
  const [showExampleModal, setShowExampleModal] = useState(false);
  const getUserLabels = () => {
    return labels?.filter(label => currentUser?.role === 'admin' || label.owner_id == currentUser?.id);
  };

  useEffect(() => {
    if (selectedLabel && selectedLabel != 'uncertain_labels') {
      if (localStorage.getItem('currentLabel') !== selectedLabel.toString())
        localStorage.removeItem('currentLabelVersion');
      localStorage.setItem('currentLabel', selectedLabel);
    }
  }, [selectedLabel]);

  useEffect(() => {
    const seed = Math.floor(Math.random() * 100000);
    setSeed(seed);
    fetchChatsNotLabelled(limit * (page - 1), limit, labelMode, selectedLabel, showLabelled, selectedLanguage, seed);
    fetchLabelsList();
    fetchUsersList();
    fetchSettings();
    return () => {
      updateChatsNotLabelled({
        chats: [],
        total_count: 0,
      });
    };
  }, []);

  useEffect(() => {
    if (!firstRender) {
      setfirstRender(true);
      return;
    }
    console.log('Page, limit: ', page, limit);
    if (!(labelMode === 'my_labels' && !selectedLabel)) {
      if (labelMode === 'uncertain_labels') setShowLabelled(false);
      fetchChatsNotLabelled(limit * (page - 1), limit, labelMode, selectedLabel, showLabelled, selectedLanguage, seed);
    }
  }, [labelMode, selectedLabel, showLabelled, page, limit, selectedLanguage]);

  useEffect(() => {
    setPage(1);
  }, [limit, showLabelled, labelMode, selectedLabel, selectedLanguage]);

  const filterChatsForClientPromptMessages = () => {
    let filteredChats = [];
    chats.forEach(chat => {
      let clientMessageFound = false;
      let clientPromptMessages = [];
      for (let message of chat.messages) {
        if (!initialPromptLabelling) {
          if (message.content_type != 'error') clientPromptMessages.push(message);
          continue;
        }
        if (message.role == 'client') {
          clientMessageFound = true;
          clientPromptMessages.push(message);
        } else if (clientMessageFound) break;
      }
      if (clientPromptMessages.length > 0) {
        let chatCopy = { ...chat };
        chatCopy.messages = clientPromptMessages;
        filteredChats.push(chatCopy);
      }
    });
    return filteredChats;
  };

  return (
    <div id="labelling">
      {addLabelModalOpen ? (
        <FormModal
          labels={labels.filter(label => !label.parent_id)}
          users={users}
          title="Add label"
          labelAction={createLabel}
          closeModal={() => toggleModal('addLabelModal')}
          formState={{
            name: addLabel,
            owner_id: currentUser?.id,
            description: '',
            interval: 'quarterly',
            is_auxiliary: false,
          }}
        />
      ) : (
        ''
      )}
      {showExampleModal ? (
        <FormModal
          labels={labels}
          users={users}
          title="Add example"
          exampleModal={true}
          labelAction={(labelId, examples, language, parsers) => {
            if (
              JSON.stringify(parsers?.liveParsers) !== settings?.live_parsers ||
              JSON.stringify(parsers?.draftParsers) !== settings?.draft_parsers
            ) {
              updateSettings({
                ...settings,
                live_parsers: JSON.stringify(parsers.liveParsers),
                draft_parsers: JSON.stringify(parsers.draftParsers),
              });
            }
            if (Array.isArray(examples)) {
              examples.map(example => createMessageLabelExample(labelId, example, currentUser?.id, language));
            }
          }}
          settings={settings}
          closeModal={() => setShowExampleModal(false)}
          formState={{
            settings: getLabelParser(
              settings?.live_parsers,
              labels.find(label => label.id === parseInt(selectedLabel))?.name
            ),
            label_id: !isNaN(parseInt(selectedLabel)) ? parseInt(selectedLabel) : null,
            description: '',
            examples: [''],
            language: selectedLanguage,
          }}
        />
      ) : (
        ''
      )}
      <LabellingHeader
        addLabelsModal={() => setShowExampleModal(true)}
        totalCount={totalCount}
        selectedLabel={selectedLabel}
        labelMode={labelMode}
        labels={getUserLabels()}
        setLabelMode={setLabelMode}
        setSelectedLabel={setSelectedLabel}
        limit={limit}
        setLimit={_limit => setLimit(_limit)}
        page={page}
        setPage={_page => setPage(_page)}
        initialPromptLabelling={initialPromptLabelling}
        setInitialPromptLabelling={setInitialPromptLabelling}
        showLabelled={showLabelled}
        setShowLabelled={setShowLabelled}
        selectedLanguage={selectedLanguage}
        setSelectedLanguage={setSelectedLanguage}
        currentCount={chats?.length || 0}
      />
      <div id="labelling-chats">
        {chats
          ? filterChatsForClientPromptMessages()?.length > 0
            ? filterChatsForClientPromptMessages().map(chat => (
                <LabellingChat
                  key={chat.id}
                  addLabelsModal={addLabel => {
                    setAddLabel(addLabel);
                    toggleModal('addLabelModal');
                  }}
                  labelMode={labelMode}
                  labels={labels}
                  chat={chat}
                  updateMessageLangLabel={updateMessageLangLabel}
                />
              ))
            : 'No conversations to show.'
          : 'Loading'}
      </div>
      {chats?.length ? (
        <LabellingHeader
          onlyPagination={true}
          addLabelsModal={() => setShowExampleModal(true)}
          totalCount={totalCount}
          selectedLabel={selectedLabel}
          labelMode={labelMode}
          labels={getUserLabels()}
          setLabelMode={setLabelMode}
          setSelectedLabel={setSelectedLabel}
          limit={limit}
          setLimit={_limit => setLimit(_limit)}
          page={page}
          setPage={_page => setPage(_page)}
          initialPromptLabelling={initialPromptLabelling}
          setInitialPromptLabelling={setInitialPromptLabelling}
          showLabelled={showLabelled}
          setShowLabelled={setShowLabelled}
          selectedLanguage={selectedLanguage}
          setSelectedLanguage={setSelectedLanguage}
          currentCount={chats?.length || 0}
        />
      ) : null}
    </div>
  );
};

export const mapStateToProps = state => {
  return {
    chats: state.labelling.chats,
    totalCount: state.labelling.totalCount,
    labels: state.labels.labels,
    users: state.users.users,
    currentUser: state.auth.user,
    addLabelModalOpen: state.modals.addLabelModal.isOpen,
    settings: state.settings,
  };
};

const mapDispatchToProps = {
  fetchChatsNotLabelled: chatsActions.fetchChatsNotLabelled,
  updateChatsNotLabelled: chatsActions.updateChatsNotLabelled,
  fetchLabelsList: labelsActions.fetchLabelsList,
  updateMessageLangLabel: chatsActions.updateMessageLangLabel,
  fetchUsersList: usersActions.fetchUsersList,
  toggleModal: modalsActions.toggleModal,
  createLabel: labelsActions.createLabel,
  createMessageLabelExample: labellingActions.createMessageLabelExample,
  fetchSettings: settingsActions.fetchSettings,
  updateSettings: settingsActions.updateSettings,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRoles(['labeler', 'product_manager', 'admin'], withChatDraft(withRouter(Labelling))));
