import {Provider} from 'react-redux';
import React from 'react';
import GlobalStyle from './styles/globalStyle';

import ModelerGlobalStyle from './modeler/styles/modelerGlobalStyle';

import store from './store';
import InitContainer from "./containers/init/InitContainer";

const BindComponent = ({labels, settings}) => {

  return (
    <Provider store={store}>
      <GlobalStyle/>
      <ModelerGlobalStyle/>
      <InitContainer labels={labels} settings={settings}/>
    </Provider>
  );
};


export default BindComponent;
