// Font sizes
export const biggestTitleFontSize = '32px';
export const biggerTitleFontSize = '20px';
export const titleFontSize = '16px';
export const textFontSize = '14px';
export const labelFontSize = '12px';
export const smallLabelFontSize = '10px';

// Font weight
export const bold = '700';
export const semibold = '600';
export const medium = '500';
export const normal = '400';

// Font line height
export const mainlineHeight = '24px';
export const smallLineHeight = '12px';

// Font letter spacing
export const mainFontLetterSpacing = '0.01em';

// Mixins defaults
export const defaultFontSize = textFontSize;
export const defaultWeight = normal;
export const defaultLineHeight = mainlineHeight;
export const defaultLetterSpacing = mainFontLetterSpacing;
