import { sendGET, sendPOST } from './common/RestService';
import responseService from './common/ResponseService';

const API_BASE_URL = process.env.TASKS_API_URL || 'http://127.0.0.1:8011';
const API_PREFIX = 'tasks';
const TaskService = {
  fetch: data => {
    const URL_SEGMENTS = ['search'];
    return responseService.handle(sendPOST(API_BASE_URL, API_PREFIX, URL_SEGMENTS, data));
  },
};

export default TaskService;
