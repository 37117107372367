import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import DiagramPropertiesPanel from '../../components/diagram-properties-panel/DiagramPropertiesPanel';
import currentDiagramAndVersionActions from '../../action-creators/currentDiagramAndVersionActions';
import diagramVersionsActions from '../../action-creators/diagramVersionsActions';
import visiblityActions from '../../action-creators/visibilityActions';
import DiagramPropertiesExpandedArea from '../../components/diagram-properties-panel/diagram-properties-panel-elements/diagram-properties-panel-expanded-area/DiagramPropertiesExpandedArea';
import usersActions from '../../action-creators/usersActions';

const DiagramPropertiesPanelContainer = ({
  user,
  users,
  currentDiagram,
  ownersSearchString,
  currentDiagramVersion,
  currentDiagramLastVersions,
  isCurrentDiagramHasMoreVersions,
  changeDiagramVersionStatus,
  diagramPropertiesVisible,
  toggleDiagramPropertiesVisibility,
  saveInProgress,
  fetchUsersList,
  updateOwnersSearchString,
  updateOwnersList,
  toggleDiagramVersionCommentVisibility,
  isCommentVisible,
  loadMoreCurrentDiagramVersions,
  showLessCurrentDiagramVersions,
  updateCurrentDiagramName,
  updateCurrentDiagramOwner,
  updateCurrentDiagramVersionLabel,
  changeCurrentDiagramAndVersion,
  deleteDiagramVersion,
}) => {
  const [isNameEditable, changeNameEditable] = useState(false);
  const [isLabelEditable, changeLabelEditable] = useState(false);
  const [nameSize, setNameSize] = useState('195px');
  const [labelSize, setLabelSize] = useState('150px');

  const [isOwnerSearchOpen, changeOwnerSearchOpen] = useState(false);

  useEffect(() => {
    if (!isNameEditable) {
      setNameSize(document.querySelector('#current-diagram-name').offsetWidth + 16 + 'px');
    }
    if (!isLabelEditable) {
      setLabelSize(document.querySelector('#current-version-label').offsetWidth + 8 + 'px');
    }
  });

  return (
    <DiagramPropertiesPanel
      user={user}
      users={users}
      currentDiagram={currentDiagram}
      ownersSearchString={ownersSearchString}
      currentDiagramVersion={currentDiagramVersion}
      currentDiagramLastVersions={currentDiagramLastVersions}
      isCurrentDiagramHasMoreVersions={isCurrentDiagramHasMoreVersions}
      updateCurrentDiagramName={updateCurrentDiagramName}
      updateCurrentDiagramOwner={updateCurrentDiagramOwner}
      deleteDiagramVersion={deleteDiagramVersion}
      updateCurrentDiagramVersionLabel={updateCurrentDiagramVersionLabel}
      loadMoreCurrentDiagramVersions={loadMoreCurrentDiagramVersions}
      showLessCurrentDiagramVersions={showLessCurrentDiagramVersions}
      changeCurrentDiagramAndVersion={changeCurrentDiagramAndVersion}
      changeDiagramVersionStatus={changeDiagramVersionStatus}
      fetchUsersList={fetchUsersList}
      updateOwnersSearchString={updateOwnersSearchString}
      updateOwnersList={updateOwnersList}
      isCommentVisible={isCommentVisible}
      toggleDiagramVersionCommentVisibility={toggleDiagramVersionCommentVisibility}
      isNameEditable={isNameEditable}
      changeNameEditable={changeNameEditable}
      isLabelEditable={isLabelEditable}
      changeLabelEditable={changeLabelEditable}
      nonEditable={saveInProgress}
      isCollapsed={diagramPropertiesVisible}
      setCollapsed={toggleDiagramPropertiesVisibility}
      nameSize={nameSize}
      labelSize={labelSize}
      isOwnerSearchOpen={isOwnerSearchOpen}
      changeOwnerSearchOpen={changeOwnerSearchOpen}
    />
  );
};

export const mapStateToProps = state => {
  return {
    user: state.users.currentUser,
    users: state.users.owners,
    ownersSearchString: state.users.ownersSearchString,
    currentDiagram: state.app.currentDiagram,
    currentDiagramVersion: state.app.currentDiagramVersion,
    currentDiagramLastVersions: state.app.currentDiagramLastVersions,
    isCurrentDiagramHasMoreVersions: state.app.isCurrentDiagramHasMoreVersions,
    diagramPropertiesVisible: state.visibility.diagramPropertiesVisible,
    saveInProgress: state.saveProgress.saveInProgress,
    isCommentVisible: state.visibility.diagramVersionCommentVisible,
  };
};

const mapDispatchToProps = {
  updateCurrentDiagramName: currentDiagramAndVersionActions.updateCurrentDiagramName,
  updateCurrentDiagramOwner: currentDiagramAndVersionActions.updateCurrentDiagramOwner,
  updateCurrentDiagramVersionLabel: currentDiagramAndVersionActions.updateCurrentDiagramVersionLabel,
  loadMoreCurrentDiagramVersions: currentDiagramAndVersionActions.loadMoreCurrentDiagramVersions,
  showLessCurrentDiagramVersions: currentDiagramAndVersionActions.showLessCurrentDiagramVersions,
  changeCurrentDiagramAndVersion: currentDiagramAndVersionActions.changeCurrentDiagramAndVersion,
  changeDiagramVersionStatus: diagramVersionsActions.changeDiagramVersionStatus,
  deleteDiagramVersion: diagramVersionsActions.deleteDiagramVersion,
  toggleDiagramPropertiesVisibility: visiblityActions.toggleDiagramPropertiesVisibility,
  toggleDiagramVersionCommentVisibility: visiblityActions.toggleDiagramVersionCommentVisibility,
  fetchUsersList: usersActions.fetchUsersList,
  updateOwnersSearchString: usersActions.updateOwnersSearchString,
  updateOwnersList: usersActions.updateOwnersList,
};

export default connect(mapStateToProps, mapDispatchToProps)(DiagramPropertiesPanelContainer);
