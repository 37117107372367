import { all, call, put, takeLatest } from '@redux-saga/core/effects';
import NotificationService from '../services/common/NotificationService';

import dbActions from '../action-creators/dbActions';
import ModelerService from '../services/ModelerService';
import { addRecord, updateRecord, getRecord, deleteRecord } from '../services/common/DBService';
import { select } from 'redux-saga/effects';
import modalsActions from '../action-creators/modalsActions';
import currentDiagramAndVersionActions from '../action-creators/currentDiagramAndVersionActions';

const getCurrentDiagramAndVersion = state => {
  return {
    diagram: state.app.currentDiagram,
    diagramVersion: state.app.currentDiagramVersion,
  };
};

const isCurrentDiagramAndVersionSaved = state => {
  return (
    state.app.isCurrentDiagramSaved &&
    state.app.isCurrentDiagramVersionSaved &&
    state.app.isCurrentDiagramVersionFileSaved
  );
};

function* putToDBFlow(action) {
  try {
    const isSaved = yield select(isCurrentDiagramAndVersionSaved);
    const { diagram, diagramVersion } = yield select(getCurrentDiagramAndVersion);
    if (diagram.id !== -1 && !isSaved) {
      const storedRecord = yield call(getRecord, diagram.id);
      ModelerService.exportDiagramVersion(action.modeler, async (err, file) => {
        if (err) {
          NotificationService.push({
            type: 'error',
            message: `Internal engine can't export file. Please store this traceback: ${err}`,
          });
        } else {
          const newRecord = {
            id: diagram.id,
            diagram: diagram,
            versionId: diagramVersion.id,
            file: file,
            diagramType: diagramVersion.diagramType
          };
          if (storedRecord) {
            if (storedRecord.file !== file) {
              updateRecord(newRecord);
            }
          } else {
            addRecord(newRecord);
          }
        }
      });
    }
  } catch (error) {
    NotificationService.push({
      type: 'error',
    });
  }
}

function* watchDBFlow() {
  yield takeLatest('PUT_TO_DB', putToDBFlow);
}

function* checkDBFlow(action) {
  try {
    const storedRecord = yield call(getRecord, action.diagramId);
    const diagramType = yield select(state => state.app.currentDiagramVersion.diagramType);
    //check if anything in DB
    if (storedRecord && storedRecord.file !== action.file && (storedRecord.diagramType === diagramType)) {
      //call modal to ask user about unsaved changes
      yield put(
        modalsActions.openModal(
          'localDiagramChanges',
          dbActions.loadFromIndexedDB,
          action.diagramId,
          { id_version: storedRecord.versionId },
          dbActions.deleteFromIndexedDB,
          action.diagramId
        )
      );
    }
  } catch (error) {
    console.log(error);
    NotificationService.push({
      type: 'error',
    });
  }
}

function* watchCheckDBFlow() {
  yield takeLatest('CHECK_DB', checkDBFlow);
}

function* loadFromDBFlow(action) {
  try {
    //get file from local DB
    const storedRecord = yield call(getRecord, action.diagramId);
    //Load last currentDiagram and currentDiagramVersion
    //Replace current diagram version file with local file from DB
    yield put(
      currentDiagramAndVersionActions.changeCurrentDiagramAndVersion({
        diagram: storedRecord.diagram,
        versionId: storedRecord.versionId,
        shouldSave: true,
        specificFile: storedRecord.file,
      })
    );
  } catch (error) {
    console.log('ERROR on load from DB: ', error);
    NotificationService.push({
      type: 'error',
    });
  }
}

function* watchLoadFromDBFlow() {
  yield takeLatest('LOAD_FROM_DB', loadFromDBFlow);
}

function* deleteFromDBFlow(action) {
  try {
    const storedRecord = yield call(getRecord, action.diagramId);
    if (storedRecord) {
      deleteRecord(storedRecord.id);
    }
  } catch (error) {
    console.log('ERROR on delete from DB: ', error);
    NotificationService.push({
      type: 'error',
    });
  }
}

function* watchDeleteFromDBFlow() {
  yield takeLatest('DELETE_FROM_DB', deleteFromDBFlow);
}

export default function* dbSaga() {
  yield all([watchDBFlow(), watchCheckDBFlow(), watchLoadFromDBFlow(), watchDeleteFromDBFlow()]);
}
