import React from "react";

import {DEFAULT_SFM_LANGUAGE, SUPPORTED_LANGUAGES} from "../../../app/components/settings/Settings";
import LanguageHeaderCell from "./LanguageHeaderCell";

const LanguageHeaderRow = ({nodesTranslationData, clearAllTranslationsForLanguage}) => {
  const getTranslationsByLanguage = lang =>
    Object.values(nodesTranslationData)
      .filter(nodeData => nodeData.translations?.[lang]?.text)
      .map(nodeData => nodeData.translations?.[lang]);

  return (
    <div id="languages-top-row" className="row-flex equal-flex">
      {SUPPORTED_LANGUAGES.map(lang => (
        <LanguageHeaderCell
          key={lang}
          lang={lang}
          isDefault={lang === DEFAULT_SFM_LANGUAGE}
          translations={getTranslationsByLanguage(lang)}
          nodesTranslationData={nodesTranslationData}
          clearAllTranslationsForLanguage={clearAllTranslationsForLanguage}
        />
      ))}
    </div>
  );
};

export default LanguageHeaderRow;
