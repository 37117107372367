import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import './Metrics.scss';
import '../common/multi-option/MultiOption.scss';
import Plots from './Plots/Plots';
import withChatDraft from "../utils/chat-draft/withChatDraft";
import {withRoles} from "../utils/roles/withRoles";

const Metrics = ({}) => {
  return (
    <div className={'metrics'}>
      <Plots />
    </div>
  );
};

export default connect()(withRoles(
  ['product_manager', 'admin'],
  withChatDraft(withRouter(Metrics)))
);
