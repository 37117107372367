import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import currentSubProcessActions from '../../action-creators/currentSubProcessActions';
import SubProcessPanel from '../../components/sub-process-panel/SubProcessPanel';
import ViewerService from '../../services/ViewerService';

const disablePreview = process.env.DISABLE_SUBFLOW_PREVIEW === 'true'; // Elisa's feature request

const SubProcessPanelContainer = ({
  modeler,
  viewer,
  diagramsList,
  currentDiagramId,
  fetchDiagramsList,
  currentSubProcess,
  currentSubProcessBusinessObject,
  currentSubProcessDiagramVersions,
  changeCurrentSubProcessFile,
  currentSubProcessFile,
}) => {
  const isExpanded = !!currentSubProcess;

  if (isExpanded && currentSubProcessBusinessObject.diagramId && !disablePreview) {
    document.querySelector('#sfm-app-viewer').classList.add('visible');
  } else {
    document.querySelector('#sfm-app-viewer').classList.remove('visible');
  }

  useEffect(() => {
    // Import new diagram to modeler every time it is changed
    if (disablePreview) {  // Elisa's feature request
      return;
    }
    ViewerService.importDiagramVersion(viewer, currentSubProcessFile);
    if (currentSubProcessFile?.diagramId) document.querySelector('#sfm-app-viewer').classList.add('visible');
    // make visible here otherwise properties like length during render can be calculated badly
  }, [currentSubProcessFile]);

  useEffect(() => {
    const modeling = modeler.get('modeling');
    if (currentSubProcessBusinessObject && currentSubProcessBusinessObject.diagramId) {
      fetchDiagramsList();
      changeCurrentSubProcessFile({
        diagramId: currentSubProcessBusinessObject.diagramId,
        diagramVersionId: currentSubProcessBusinessObject.diagramVersionId,
        modeling: modeling,
        isLatest: currentSubProcessBusinessObject.diagramVersionId === '0',
        currentElement: currentSubProcess,
        isDiagramSelect: true,
      });
    }
  }, [currentSubProcess]);

  useEffect(() => {
    fetchDiagramsList();
  }, []);

  return (
    <SubProcessPanel
      modeler={modeler}
      diagramsList={diagramsList}
      currentDiagramId={currentDiagramId}
      isExpanded={isExpanded}
      currentSubProcess={currentSubProcess}
      currentBusinessObject={currentSubProcessBusinessObject}
      currentSubProcessDiagramVersions={currentSubProcessDiagramVersions}
      changeCurrentSubProcessFile={changeCurrentSubProcessFile}
    />
  );
};

export const mapStateToProps = state => {
  return {
    diagramsList: state.app.diagramsList,
    currentSubProcess: state.app.currentSubProcess,
    currentSubProcessBusinessObject: state.app.currentSubProcessBusinessObject,
    currentSubProcessDiagramVersions: state.app.currentSubProcessDiagramVersions,
    currentSubProcessFile: state.app.currentSubProcessFile,
    currentDiagramId: state.app.currentDiagram.id,
  };
};

const mapDispatchToProps = {
  changeCurrentSubProcessFile: currentSubProcessActions.changeCurrentSubProcessFile,
  updateCurrentSubProcessFile: currentSubProcessActions.updateCurrentSubProcessFile,
};

export default connect(mapStateToProps, mapDispatchToProps)(SubProcessPanelContainer);
