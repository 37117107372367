import crud from './common/CrudService';
import responseService from './common/ResponseService';

const API_BASE_URL = process.env.SFM_API_URL || 'http://127.0.0.1:5000';
const API_PREFIX = process.env.SFM_CLIENT_PREFIX || 'dev-api';
const diagramsCRUD = crud(API_BASE_URL, API_PREFIX, ['diagrams']);
const diagramAndVersionCRUD = crud(API_BASE_URL, API_PREFIX, ['diagrams', 'diagram-and-version']);

const DiagramsService = {
  getDiagramsList: () => {
    // console.log('ENV in app --> ', API_BASE_URL, API_PREFIX);
    return responseService.handle(diagramsCRUD.list());
  },
  searchDiagrams: params => {
    const searchDiagramsCrud = crud(API_BASE_URL, API_PREFIX, ['diagrams', 'search']);
    return responseService.handle(searchDiagramsCrud.list(params));
  },

  getDiagram: ({ diagramId }) => {
    return responseService.handle(diagramsCRUD.view(diagramId));
  },

  getDiagramAndVersionByLabel: ({ label, versionId }) => {
    return responseService.handle(
      diagramAndVersionCRUD.list({
        label: label,
        version_id: versionId,
      })
    );
  },

  createDiagram: ({ diagramName, versionLabel, owner, file, comment, shouldReview, reviewDueDate, diagramType }) => {
    return responseService.handle(
      diagramsCRUD.create({
        diagram_name: diagramName,
        version_label: versionLabel,
        created_by_id: owner.id,
        created_by_first_name: owner.firstName || owner.first_name,
        created_by_last_name: owner.lastName || owner.last_name,
        file: file,
        comment: comment,
        needs_review: shouldReview,
        review_due_date: reviewDueDate,
        diagram_type: diagramType,
      })
    );
  },

  updateDiagram: ({ diagramId, diagramName, owner }) => {
    return responseService.handle(
      diagramsCRUD.update(diagramId, {
        diagram_name: diagramName,
        owner_id: owner.id,
        owner_first_name: owner.firstName || owner.first_name,
        owner_last_name: owner.lastName || owner.last_name,
      })
    );
  },
};

export default DiagramsService;
