import crud from './common/CrudService';
import responseService from './common/ResponseService';
import { create } from 'tiny-svg';

const API_BASE_URL = process.env.SFM_API_URL || 'http://127.0.0.1:5000';
const API_PREFIX = process.env.SFM_CLIENT_PREFIX || 'dev-api';
const URL_SEGMENTS = ['diagram-versions'];
const URL_SEGMENTS_APPROVE_STATUS = ['diagram-versions', 'approve-status'];
const URL_SEGMENTS_ONLY_FILE = ['diagram-versions', 'only-file'];
const diagramVersionsCRUD = crud(API_BASE_URL, API_PREFIX, URL_SEGMENTS);
const diagramVersionsApproveStatusCRUD = crud(API_BASE_URL, API_PREFIX, URL_SEGMENTS_APPROVE_STATUS);
const diagramVersionsOnlyFileCRUD = crud(API_BASE_URL, API_PREFIX, URL_SEGMENTS_ONLY_FILE);

const DiagramVersionsService = {
  getDiagramVersions: ({ diagramId, count, startFrom }) => {
    return responseService.handle(
      diagramVersionsCRUD.list({
        diagram_id: diagramId,
        count: count,
        start_from: startFrom,
      })
    );
  },
  updateBotWorkflowMappings: (diagramId, sfmType) => {
    const ADMIN_API_BASE_URL = process.env.API_URL || 'not-specified';
    const ADMIN_API_PREFIX = process.env.API_PREFIX || 'none';
    const URL_SEGMENTS = ['settings', 'update_bot_workflow_mappings'];
    const settingsCrud = crud(ADMIN_API_BASE_URL, ADMIN_API_PREFIX, URL_SEGMENTS);
    return responseService.handle(settingsCrud.list({ diagram_id: diagramId, sfm_type: sfmType }));
  },
  deployDiagramVersionMessagesToCwc: () => {
    const ADMIN_API_BASE_URL = process.env.API_URL || 'not-specified';
    const ADMIN_API_PREFIX = process.env.API_PREFIX || 'none';
    const URL_SEGMENTS = ['settings', 'deploy_cwc_messages'];
    const settingsCrud = crud(ADMIN_API_BASE_URL, ADMIN_API_PREFIX, URL_SEGMENTS);
    return responseService.handle(settingsCrud.list());
  },
  createDiagramVersion: ({ diagramId, label, createdBy, file, comment, shouldReview, diagramType, baseVersionId }) => {
    return responseService.handle(
      diagramVersionsCRUD.create({
        diagram_id: diagramId,
        label: label,
        created_by_id: createdBy.id,
        created_by_first_name: createdBy.firstName || createdBy.first_name,
        created_by_last_name: createdBy.lastName || createdBy.last_name,
        file: file,
        comment: comment,
        needs_review: shouldReview,
        diagram_type: diagramType,
        base_version_id: baseVersionId,
      })
    );
  },

  getDiagramVersion: ({ versionId }) => {
    return responseService.handle(diagramVersionsCRUD.view(versionId));
  },

  // changeDiagramVersionLiveStatus: ({ versionId, shouldChangeToLive }) => {
  //     return responseService.handle(
  //         diagramVersionsCRUD.update(
  //             versionId,
  //             {
  //                 'should_be_live': shouldChangeToLive
  //             }
  //         )
  //     )
  // },

  changeDiagramVersionStatus: ({ versionId, statusAction }) => {
    return responseService.handle(
      diagramVersionsCRUD.update(versionId, {
        status_action: statusAction,
      })
    );
  },
  changeDiagramVersionApproveStatus: ({ versionId, approveStatus, createReview, reviewDueDate, versionLabel }) => {
    return responseService.handle(
      diagramVersionsApproveStatusCRUD.update(versionId, {
        approve_status: approveStatus,
        create_review: createReview,
        review_due_date: reviewDueDate,
        version_label: versionLabel,
      })
    );
  },

  changeOnlyDiagramVersionFile: ({ versionId, file }) => {
    return responseService.handle(
      diagramVersionsOnlyFileCRUD.update(versionId, {
        file: file,
      })
    );
  },

  deleteDiagramVersion: ({ versionId, deleteDiagram }) => {
    return responseService.handle(diagramVersionsCRUD.delete(versionId, { delete_diagram: deleteDiagram }));
  },
};

export default DiagramVersionsService;
