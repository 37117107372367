import { createGlobalStyle } from 'styled-components';

const ModelerGlobalStyle = createGlobalStyle`
    .bjs-powered-by {
        display: none
    }
    
    .djs-palette {
        transform: translateY(80px);
        border: none !important;
        background-color: #F2F2F2 !important;
        left: 24px !important;
    }

    .djs-palette.two-column.open {
        width: min-content;
    }

    .djs-visual > :nth-child(1) {
        rx: 12px;
        ry: 12px;
        stroke-width: 3px !important;
    }
    
    .djs-visual > .djs-label {
        font-family: 'Poppins', sans-serif !important;
        font-size: 10px !important;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0.01em;
        fill: #000F3C !important;
        white-space: wrap !important;
    }
    
    .djs-visual > .djs-label-small {
        font-family: 'Poppins', sans-serif !important;
        font-size: 10px !important;
        font-weight: 600;
        line-height: 20px;
        letter-spacing: 0.01em;
        fill: #000F3C !important;
        white-space: wrap !important;
    }
    
    .djs-visual > .djs-label-large {
       font-family: 'Poppins', sans-serif !important;
        font-size: 18px !important;
        font-weight: 600;
        line-height: 20px;
        letter-spacing: 0.01em;
        fill: #000F3C !important;
        white-space: wrap !important;
    }
    
    :not(.djs-connection) > .djs-visual > path,
    .djs-drag-group .djs-element > .djs-visual > path {
        display: none;
    }
    
    .message-task .djs-visual > :nth-child(1)  {
        stroke: #263C73 !important;
    }

    .helpdesk-message-task .djs-visual > :nth-child(1)  {
        stroke: #263C73 !important;
    }
    
    .voice-task .djs-visual > :nth-child(1)  {
        stroke: #263C73 !important;
    }
    
    .sms-task .djs-visual > :nth-child(1)  {
        stroke: #263C73 !important;
    }
    
    .routing-task .djs-visual > :nth-child(1)  {
        stroke: #263C73 !important;
    }
    
    .map-task .djs-visual > :nth-child(1)  {
        stroke: #975021 !important;
    }
    
    .input-task .djs-visual > :nth-child(1)  {
        stroke: #1EAAAA !important;
    }
    
    .table-task .djs-visual > :nth-child(1)  {
        stroke: #1EAAAA !important;
    }
    
    .empty-task .djs-visual > :nth-child(1)  {
        stroke: #C4C4C4 !important;
    }
    
    .service-task .djs-visual > :nth-child(1)  {
        stroke: #FF8000 !important;
    }
    
    .logic-task .djs-visual > :nth-child(1)  {
        stroke: #FF8080 !important;
    }
    
    .logic-python-task .djs-visual > :nth-child(1)  {
        stroke: #FFE600 !important;
    }
    
    .call-activity .djs-visual > :nth-child(1)  {
        stroke: #7828C8 !important;
    }
    
    .start-event .djs-visual > :nth-child(1) {
        stroke: #263C73 !important;
    }
    
    .error-end-event .djs-visual > :nth-child(1) {
        stroke: #E61A3C !important;
    }
    
    .end-event .djs-visual > :nth-child(1) {
        stroke: #1EAA1E !important;
    }
    
    // .djs-connection > .djs-visual > :nth-child(1),
    //  .djs-connection > .djs-visual ~ polyline {
    //     stroke: #263C73 !important;
    // }
        
    // Task icons classes for palette and task node overlay
    .icon-overlay {
        width: 24px;
        height: 24px;
        display: flex;
        justify-content: center;
        align-items: center; 
        background-color: white; 
        transform: translate(-6px, -6px);
    }
    
    .entry.timeout:after {
        content: url(sfm-app/modeler/assets/timer-dark.svg);
    }
    
    .entry.no-timeout:after {
        content: url(sfm-app/modeler/assets/no-timer.svg);
    }
    
    .entry.event:after {
        content: url(sfm-app/modeler/assets/event-dark.svg);
    }
    
    .entry.no-event:after {
        content: url(sfm-app/modeler/assets/no-event.svg);
    }
    
    .entry.main-branch:after {
        content: url(sfm-app/modeler/assets/main-branch-icon.svg);
    }
    
    .entry.no-main-branch:after {
        content: url(sfm-app/modeler/assets/no-main-branch-icon.svg);
    }
    
    .entry.start-event:after {
        content: url(sfm-app/modeler/assets/start-event-icon.svg);
    }
    
    .entry.error-event:after {
        content: url(sfm-app/modeler/assets/error-event-icon.svg);
    }
    
    .entry.end-event:after {
        content: url(sfm-app/modeler/assets/end-event-icon.svg);
    }
    
    .entry.message-task:after,
    .icon-overlay.message-task:after {
        content: url(sfm-app/modeler/assets/message-task-icon.svg);
    }

    .entry.helpdesk-message-task:after,
    .icon-overlay.helpdesk-message-task:after {
        content: url(sfm-app/modeler/assets/helpdesk-message-task-icon.svg);
    }
    
    .entry.voice-task:after,
    .icon-overlay.voice-task:after {
        content: url(sfm-app/modeler/assets/voice-task-icon.svg);
    }
    
    .entry.sms-task:after,
    .icon-overlay.sms-task:after {
        content: url(sfm-app/modeler/assets/sms-task-icon.svg);
    }
    
    .entry.routing-task:after,
    .icon-overlay.routing-task:after {
        content: url(sfm-app/modeler/assets/routing-task-icon.svg);
    }
    
    .entry.map-task:after,
    .icon-overlay.map-task:after {
        content: url(sfm-app/modeler/assets/map-task-icon.svg);
    }
    
    .entry.input-task:after,
    .icon-overlay.input-task:after {
        content: url(sfm-app/modeler/assets/input-task-icon.svg);
    }
    
    .entry.table-task:after,
    .icon-overlay.table-task:after {
        content: url(sfm-app/modeler/assets/table-task-icon.svg);
    }
    
    .entry.empty-task:after,
    .icon-overlay.empty-task:after {
        content: url(sfm-app/modeler/assets/empty-task-icon.svg);
    }
    
    .entry.service-task:after,
    .icon-overlay.service-task:after {
        content: url(sfm-app/modeler/assets/service-task-icon.svg);
    }
    
    .entry.logic-task:after,
    .icon-overlay.logic-task:after {
        content: url(sfm-app/modeler/assets/logic-task-icon.svg);
    }
    
    .entry.logic-python-task:after,
    .icon-overlay.logic-python-task:after {
        content: url(sfm-app/modeler/assets/logic-python-task-icon.svg);
    }
    
    .entry.classifier-task:after,
    .icon-overlay.classifier-task:after {
        content: url(sfm-app/modeler/assets/classifier-task-icon.svg);
    }
    
    .entry.carousel-task:after,
    .icon-overlay.carousel-task:after {
        content: url(sfm-app/modeler/assets/carousel-task-icon.svg);
    }
    
    .entry.exclusive-gateway:after {
        content: url(sfm-app/modeler/assets/exclusive-gateway-icon.svg);
    }
    
    .entry.parallel-gateway:after {
        content: url(sfm-app/modeler/assets/parallel-gateway-icon.svg);
    }
    
    .entry.call-activity:after,
    .icon-overlay.call-activity:after {
        content: url(sfm-app/modeler/assets/call-activity-task-icon.svg);
    }
    
    .icon-overlay.error:after {
        content: url(sfm-app/modeler/assets/error-icon.svg);
    }
    
    .icon-overlay.warning:after {
        content: url(sfm-app/modeler/assets/warning-icon.svg);
    }
    
    // Icons in palette on hover
    
    .entry.start-event:hover:after {
        content: url(sfm-app/modeler/assets/hovered/event-icon-hovered.svg);
    }
    
    .entry.error-event:hover:after {
        content: url(sfm-app/modeler/assets/hovered/event-icon-hovered.svg);
    }
    
    .entry.end-event:hover:after {
        content: url(sfm-app/modeler/assets/hovered/event-icon-hovered.svg);
    }
    
    .entry.message-task:hover:after {
        content: url(sfm-app/modeler/assets/hovered/message-task-icon-hovered.svg);
    }

    .entry.helpdesk-message-task:hover:after {
        content: url(sfm-app/modeler/assets/hovered/helpdesk-message-task-icon-hovered.svg);
    }

    .entry.voice-task:hover:after {
        content: url(sfm-app/modeler/assets/hovered/voice-task-icon-hovered.svg);
    }
    
    .entry.sms-task:hover:after {
        content: url(sfm-app/modeler/assets/hovered/sms-task-icon-hovered.svg);
    }
    
    .entry.routing-task:hover:after {
        content: url(sfm-app/modeler/assets/hovered/routing-task-icon-hovered.svg);
    }
    
    .entry.map-task:hover:after {
        content: url(sfm-app/modeler/assets/hovered/map-task-icon-hovered.svg);
    }
    
    .entry.input-task:hover:after {
        content: url(sfm-app/modeler/assets/hovered/input-task-icon-hovered.svg);
    }
    
    .entry.table-task:hover:after {
        content: url(sfm-app/modeler/assets/hovered/table-task-icon-hovered.svg);
    }
    
    .entry.empty-task:hover:after {
        content: url(sfm-app/modeler/assets/hovered/empty-task-icon-hovered.svg);
    }
    
    .entry.service-task:hover:after {
        content: url(sfm-app/modeler/assets/hovered/service-task-icon-hovered.svg);
    }
    
    .entry.logic-task:hover:after {
        content: url(sfm-app/modeler/assets/hovered/logic-task-icon-hovered.svg);
    }
    
    .entry.logic-python-task:hover:after {
        content: url(sfm-app/modeler/assets/hovered/logic-python-task-icon-hovered.svg);
    }
    
    .entry.classifier-task:hover:after {
        content: url(sfm-app/modeler/assets/hovered/classifier-task-icon-hovered.svg);
    }
    
    .entry.carousel-task:hover:after {
        content: url(sfm-app/modeler/assets/hovered/carousel-task-icon-hovered.svg);
    }
    
    .entry.exclusive-gateway:hover:after {
        content: url(sfm-app/modeler/assets/hovered/exclusive-gateway-icon-hovered.svg);
    }
    
    .entry.parallel-gateway:hover:after {
        content: url(sfm-app/modeler/assets/hovered/parallel-gateway-icon-hovered.svg);
    }
    
    .entry.call-activity:hover:after {
        content: url(sfm-app/modeler/assets/hovered/call-activity-task-icon-hovered.svg);
    }
    
    // Common element styles
    
    .selected .djs-visual > .djs-rect {
        stroke-dasharray: 10, 5;
    }
    
    .error .djs-visual > :nth-child(1) {
        stroke: red;
        stroke-width: 6px;
    }
    
    // .djs-direct-editing-parent {
    //     display: none;
    // }
    
    .djs-overlay > .owners-overlay {
        display: flex;
        flex-direction: column;
    }
    
    .djs-overlay > .owners-overlay p {
        box-sizing: border-box;
        padding: 3px 8px;
        background-color: #263C73;
        white-space: nowrap;
        font-family: 'Poppins', sans-serif;
        font-size: 12px;
        font-weight: 500;
        line-height: 12px;
        letter-spacing: 0.01em;
        color: white;
    }
    
    .djs-overlay > .owners-overlay p:first-child:not(:only-child) {
        padding-top: 6px;
    }
    
    .djs-overlay > .owners-overlay p:last-child:not(:only-child) {
        padding-bottom: 6px;
    }
    
    .djs-overlay > .owners-overlay p.current {
        background-color: #E61A3C;
    }
    
    /**
    Palette tooltip fix
    see app/containers/ToolsContainer.js
     */
    .djs-palette-entries .entry {
      position: relative;
    }
    .djs-palette-entries .entry-tooltip {
        position: absolute;
        top: 50%;
        left: 105%;
        transform: translateY(-50%);
        z-index: 100;
        
        padding: 0 20px;
        
        background-color: rgba(0,0,0,0.65);
        color: white;
        line-height: 20px;
        font-size: 12px;
        white-space: nowrap;
        font-family: 'Poppins',sans-serif;
        
        display: none;
    }
    .djs-palette-entries .entry:hover .entry-tooltip {
       display: block;
    }
    
    .comments-container {
        position: relative;
        width: 24px;
        height: 24px;
    }
    
    .comments-toggle {
        width: 100%;
        height: 100%;
        cursor: pointer;
    }
    
    .comments-content {
        position: absolute;
        top: 35px;
        left: -40px;
        width: 225px;
        min-height: 64px;
        background-color: #FFFFFF;
        box-shadow: 0px 0px 90px -10px rgba(0, 128, 255, 0.2);
        border-radius: 2px;
        box-sizing: border-box;
        padding: 16px;
        display: none;
    }
    
    .comments-container.expanded .comments-content {
        display: block;
    }
    
    .comments-input {
        width: 100%;
        height: 32px;
        background: #FFFFFF;
        border: 1px solid rgba(0, 35, 72, 0.5);
        box-sizing: border-box;
        border-radius: 2px;
        padding: 0 8px;
    }
    
    .comments-input:focus {
        border: 1px solid rgb(0, 35, 72);
    }
    
    .comment-header {
        position: relative;
        width: 100%;
    }
    
    .comment-owner {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
    }
    
    .comment-owner-image {
        width: 28px;
        height: 28px;
        background: #1EAAAA;
        border-radius: 50%;
        margin-right: 5px;
    }
    
    .comment-owner-name {
        font-family: Poppins;
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 18px;
        color: #000000;
    }
    
    .comment-date {
        font-family: Poppins;
        font-style: normal;
        font-weight: normal;
        font-size: 10px;
        line-height: 15px;
        letter-spacing: -0.005em;
        color: #002348;
    }
    
    .comment-resolved {
        position: absolute;
        top: 0;
        right: 20px;
        width: 24px;
        height: 24px;
        cursor: pointer;
        display: none;
    }
    
    .comment-resolved.enabled {
        display: block;
    }
    
    .comment-resolved svg {
        width: 100%;
        height: 100%;
    }
    
    .comment-options {
        position: absolute;
        top: 0;
        right: -10px;
        width: 24px;
        height: 24px;
        cursor: pointer;
    }
    
    .comment-options svg {
        width: 100%;
        height: 100%;
    }
    .ant-radio-button {
        z-index: 9999 !important;
    }
    #task-properties-main-container .ant-select-selector,
    .ant-select-item,
    .ant-select-dropdown {
        background: #E5E5E5 !important;
    }
    .comment-text {
        margin-top: 10px;
        margin-bottom: 10px;
        font-family: Poppins;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 18px;
        letter-spacing: -0.005em;
        color: #002348;
        border-bottom: 0.5px solid rgba(0, 35, 72, 0.5);
        box-sizing: border-box;
        padding-bottom: 14px;
    }
    
    .parallel-gateway-display-initial {
      display: initial !important;
    }
`;

export default ModelerGlobalStyle;
