import { sendPOST, sendGET, sendPUT, sendDELETE } from './RestService';

const crudService = (apiBaseURL, apiPrefix, urlSegments, isAuthRequired = true) => {
  return {
    list: params => {
      return sendGET(apiBaseURL, apiPrefix, urlSegments, params);
    },

    view: id => {
      return sendGET(apiBaseURL, apiPrefix, urlSegments.concat([`${id}`]));
    },

    create: (data, params, contentType) => {
      return sendPOST(apiBaseURL, apiPrefix, urlSegments, data, params, isAuthRequired, contentType);
    },

    update: (id, data) => {
      return sendPUT(apiBaseURL, apiPrefix, urlSegments.concat([`${id}`]), data);
    },

    delete: (id, params = {}) => {
      return sendDELETE(apiBaseURL, apiPrefix, id ? urlSegments.concat([`${id}`]) : urlSegments, params);
    },
  };
};

export default crudService;
