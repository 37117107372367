import BaseRenderer from 'diagram-js/lib/draw/BaseRenderer';

import {
  append as svgAppend,
  attr as svgAttr,
  classes as svgClasses,
  create as svgCreate,
  remove as svgRemove,
  innerSVG as svgInnerAppend,
} from 'tiny-svg';

import { getRoundRectPath } from 'bpmn-js/lib/draw/BpmnRenderUtil';

import { is } from 'bpmn-js/lib/util/ModelUtil';
import { isAny } from 'bpmn-js/lib/features/modeling/util/ModelingUtil';

import modeling from 'bpmn-js/lib/features/modeling';

const HIGH_PRIORITY = 1500;

export default class VoiceTaskRenderer extends BaseRenderer {
  constructor(eventBus, bpmnRenderer) {
    super(eventBus, HIGH_PRIORITY);

    this.bpmnRenderer = bpmnRenderer;
    // this.modeling = bpmnRenderer.get('modeling');

    // this.maxHeight = 0;
    this.elementWidth = 200;
    this.currentZoomLevel = 1;
  }

  canRender(element) {
    return is(element, 'bpmn:SendTask') && element.businessObject.subType === 'voice-task';
  }

  svg_textMultiline(id, maxWidth, zoom) {
    var x = 10;
    var y = 0;
    var dy = 15;

    /* get the text */
    var element = document.getElementById(id);

    var text = element.innerHTML;

    var words = text.split(' ');
    var line = '';

    var constructedText = '';

    for (var n = 0; n < text.length; n++) {
      element.innerHTML = '<tspan id="PROCESSING">busy</tspan >';

      var newTextLine = line + text[n];

      const newTextElem = document.getElementById('PROCESSING');

      newTextElem.innerHTML = newTextLine;
      var metrics = newTextElem.getBoundingClientRect();
      var newTextWidth = metrics.width;

      if (newTextWidth > maxWidth - 2 * x * (zoom ? zoom : 1)) {
        if ((constructedText.match(/<tspan/g) || []).length > 0) {
          constructedText += '<tspan x="' + x + '" dy="' + dy + '">' + line + '</tspan>';
        } else {
          constructedText += '<tspan x="' + x + '" dy="' + y + '">' + line + '</tspan>';
        }
        line = text[n];
      } else {
        line = newTextLine;
      }

      document.getElementById('PROCESSING').remove();
    }
    if ((constructedText.match(/<tspan/g) || []).length > 0) {
      constructedText += '<tspan x="' + x + '" dy="' + dy + '">' + line + '</tspan>';
    } else {
      constructedText += '<tspan x="' + x + '" dy="' + y + '">' + line + '</tspan>';
    }

    element.innerHTML = constructedText;
  }

  // Main function to draw custom shape

  drawShape(parentNode, element) {
    let element_id = `${element.id}${Math.floor(Math.random() * 10000)}`;
    const parentNodeBounds = parentNode.getBoundingClientRect();

    element.width = this.elementWidth;

    let shapeProcessing = this.bpmnRenderer.drawShape(parentNode, element);

    this.lastActualWidth = shapeProcessing.getBoundingClientRect().width;

    const newText = svgCreate('text');
    svgAttr(newText, {
      id: `newTextFor${element_id}`,
      class: 'djs-label',
      x: 10,
      y: 35,
    });

    newText.appendChild(document.createTextNode(element.businessObject.template));

    parentNode.appendChild(newText);

    const newLabelText = svgCreate('text');
    svgAttr(newLabelText, {
      id: `newLabelText`,
      class: 'djs-label-small',
      x: 10,
      y: 20,
    });

    newLabelText.appendChild(document.createTextNode('message template'));

    parentNode.appendChild(newLabelText);

    let zoom;
    if (shapeProcessing.getBoundingClientRect().width) {
      zoom = (shapeProcessing.getBoundingClientRect().width / this.elementWidth).toFixed(4);
    } else {
      zoom = 1;
    }

    let newClientRect;
    try {
      this.svg_textMultiline(`newTextFor${element_id}`, this.lastActualWidth, zoom);
      newClientRect = document.getElementById(`newTextFor${element_id}`).getBoundingClientRect();
    } catch (e) {
      newClientRect = {height: 15};
    }

    let newHeight = Math.round(
      newClientRect.height + 40 * (zoom ? zoom : 1) > 80 * (zoom ? zoom : 1)
        ? newClientRect.height + 40 * (zoom ? zoom : 1)
        : 80 * (zoom ? zoom : 1)
    );
    let oldHeight = element.height;
    newHeight = zoom === 1 ? newHeight : newHeight * (1 / zoom);

    svgAttr(shapeProcessing, {
      height: newHeight,
    });

    element.width = this.elementWidth;
    element.height = newHeight;
    element.y = element.y - (newHeight / 2 - oldHeight / 2);

    let shape = this.bpmnRenderer.drawShape(parentNode, element);

    svgAttr(parentNode.childNodes[5], {
      fillOpacity: 0,
      strokeWidth: 3,
      stroke: '#263C73',
      class: 'djs-rect',
    });

    svgRemove(parentNode.childNodes[0]);

    drawRect(parentNode);

    this.currentZoomLevel = zoom;

    return shape;
  }
}

VoiceTaskRenderer.$inject = ['eventBus', 'bpmnRenderer'];

// helpers //////////

// copied from https://github.com/bpmn-io/bpmn-js/blob/master/lib/draw/BpmnRenderer.js
function drawRect(parentNode, width, height, borderRadius, strokeColor) {
  const g = svgCreate('g');

  svgAttr(g, {
    transform: 'translate(-8, -8)',
    fill: 'white',
  });

  /*const rect = svgCreate('rect');

  svgAttr(rect, {
    width: width,
    height: height,
    fill: "#fff"
  });*/

  //svgAppend(g, rect);

  const rect = '<rect width="22" height="22" fill="white"></rect>';

  const iconContent = `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#030F3A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" class="feather feather-volume-2"><polygon points="11 5 6 9 2 9 2 15 6 15 11 19 11 5"></polygon><path d="M19.07 4.93a10 10 0 0 1 0 14.14M15.54 8.46a5 5 0 0 1 0 7.07"></path></svg>`;

  svgInnerAppend(g, rect + iconContent);

  svgAppend(parentNode, g);

  return g;
}

// copied from https://github.com/bpmn-io/diagram-js/blob/master/lib/core/GraphicsFactory.js
function prependTo(newNode, parentNode, siblingNode) {
  parentNode.insertBefore(newNode, siblingNode || parentNode.firstChild);
}
