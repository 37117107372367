import { all, call, put, select, takeEvery } from '@redux-saga/core/effects';
import DiagramVersionsService from '../services/DiagramVersionsService';
import currentDiagramAndVersionActions from '../action-creators/currentDiagramAndVersionActions';
import visiblityActions from '../action-creators/visibilityActions';
import NotificationService from '../services/common/NotificationService';
import modalsActions from '../action-creators/modalsActions';
import diagramsActions from '../action-creators/diagramsActions';
import currentSubProcessActions from '../action-creators/currentSubProcessActions';

const getDiagramsList = state => state.app.diagramsList;
const getInProgressId = state => state.app.inProgressId;

function* changeCurrentSubProcessFile(action) {
  const thisProgressId = Math.floor(Math.random() * 1000000);
  yield put(currentSubProcessActions.setInProgressDiagramVersionFor(thisProgressId));
  let inProgressId;
  try {
    // Extract diagram versions list if needed
    if (action.isDiagramSelect && action.diagramId) {
      const diagramsList = yield select(getDiagramsList);
      const diagram = diagramsList.filter(diagram => diagram.id === parseInt(action.diagramId))[0];
      const diagramVersionsResponse = yield call(DiagramVersionsService.getDiagramVersions, {
        diagramId: action.diagramId,
        count: 30,
        startFrom: 0,
      });
      inProgressId = yield select(getInProgressId);
      if (inProgressId !== thisProgressId) return;
      if (diagramVersionsResponse.status === 200) {
        const versions = diagramVersionsResponse.data.versions;
        const latestVersion =
          diagram && diagram.versionInTest
            ? versions.filter(version => version.id === diagram.versionInTest.id)[0]
            : versions[0];
        yield put(currentSubProcessActions.updateCurrentSubProcessDiagramVersions([latestVersion, ...versions]));

        // Extract new diagram version for viewer
        const response = yield call(DiagramVersionsService.getDiagramVersion, {
          versionId: action.diagramVersionId !== '0' ? action.diagramVersionId : latestVersion.id,
        });
        inProgressId = yield select(getInProgressId);
        if (inProgressId !== thisProgressId) return;
        if (response.status === 200) {
          action.modeling.updateProperties(action.currentElement, {
            ['diagramVersionId']: action.isLatest ? '0' : response.data.id,
          });
          yield put(currentSubProcessActions.updateCurrentSubProcessFile(response.data.file));
          // NotificationService.push({
          //     type: 'success',
          //     message: 'Sub process view successfully loaded!'
          // });
        } else {
          NotificationService.push({
            type: 'error',
            message: response.data,
          });
        }
      } else {
        NotificationService.push({
          type: 'error',
          message: 'Failed to upload diagram versions',
        });
      }
    } else {
      // Extract new diagram version for viewer
      const response = yield call(DiagramVersionsService.getDiagramVersion, {
        versionId: action.diagramVersionId,
      });
      inProgressId = yield select(getInProgressId);
      if (inProgressId !== thisProgressId) return;
      if (response.status === 200) {
        action.modeling.updateProperties(action.currentElement, {
          ['diagramVersionId']: action.isLatest ? '0' : response.data.id,
        });
        yield put(currentSubProcessActions.updateCurrentSubProcessFile(response.data.file));
        // NotificationService.push({
        //     type: 'success',
        //     message: 'Sub process view successfully loaded!'
        // });
      } else {
        NotificationService.push({
          type: 'error',
          message: response.data,
        });
      }
    }
  } catch (error) {
    NotificationService.push({
      type: 'error',
    });
  }
}

function* watchChangeCurrentSubProcessFileRequested() {
  yield takeEvery('CHANGE_CURRENT_SUB_PROCESS_FILE', changeCurrentSubProcessFile);
}

export default function* currentDiagramAndVersionSaga() {
  yield all([watchChangeCurrentSubProcessFileRequested()]);
}
