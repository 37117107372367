import React from 'react';
import uuid from 'react-uuid';
import styled from 'styled-components';
import * as mixins from '../../../styles/mixins';
import * as colors from '../../../styles/colors';
import * as typography from '../../../styles/typography';
import BoxIcon from '../../shared/icons/tools/BoxIcon';
import FlexibleWrapper from '../../shared/FlexibleWrapper';
import ModelerService from '../../../services/ModelerService';


const ENABLE_VOICE_TASK = process.env.ENABLE_VOICE_TASK || 'false';
 
const PanelHeader = styled(FlexibleWrapper)`
  width: 100%;
  cursor: pointer;
  ${mixins.flex('space-between')};
`;

const PanelHeaderLeftSection = styled.div`
  flex: 1;
  ${mixins.flex('flex-start')};
`;

const PanelTaskTypeOverlay = styled.div`
  display: none;
  position: absolute;
  ${props => (props.isFixedToBottom ? `bottom: -70px` : `top: 0`)};
  left: 100%;
  padding-left: 16px;
`;

const PanelTaskTypeMenu = styled.div`
  background-color: ${colors.white};
  width: 400px;
  box-shadow: 0px 16px 40px rgba(0, 51, 167, 0.15);
  box-sizing: border-box;
  padding: 16px 16px 12px;
  ${mixins.flex('space-between', 'center', 'row', 'wrap')};
`;

const MenuItemIcon = styled.div`
  margin-right: 8px;
`;

const MenuItemName = styled.p`
  color: ${colors.extraFontColor};
  ${mixins.fontStyle(typography.textFontSize, typography.normal)};
`;

const MenuItem = styled.div`
  width: 40%;
  margin-bottom: 12px;
  ${mixins.flex('flex-start')};

  &:hover ${MenuItemIcon} {
    & > svg * {
      stroke: ${colors.alternativeIconColor};
    }
  }

  &:hover ${MenuItemName} {
    color: ${colors.alternativeFontColor};
  }

  ${props =>
    props.isCurrent
      ? `
            & ${MenuItemIcon} {
                & > svg * {
                    stroke: ${colors.alternativeIconColor};
                } 
            }
            
            & ${MenuItemName} {
                color: ${colors.alternativeFontColor};
            }
        `
      : ''}
`;

const PanelHeaderRightSection = styled.div`
  position: relative;
  flex: 1;
  ${mixins.flex('flex-end')};

  &:hover ${PanelTaskTypeOverlay} {
    display: block;
  }
`;

const PanelHeaderTitle = styled.p`
  margin-left: 11px;
  color: ${colors.mainFontColor};
  ${mixins.fontStyle(typography.textFontSize, typography.semibold)};
`;

const PanelTaskTypeTitle = styled.p`
  margin-right: 10px;
  color: ${colors.mainFontColor};
  ${mixins.fontStyle(typography.textFontSize, typography.semibold)};
`;

const TaskPropertiesHeader = ({ modeler, isExpanded, taskData, currentTask, currentTaskData, updateCurrentTask }) => {
  return (
    <PanelHeader>
      <PanelHeaderLeftSection>
        <BoxIcon color={colors.mainIconColor} />
        {isExpanded ? <PanelHeaderTitle>Task properties</PanelHeaderTitle> : ''}
      </PanelHeaderLeftSection>
      {isExpanded && currentTaskData ? (
        <PanelHeaderRightSection>
          <PanelTaskTypeTitle>{currentTaskData.title}</PanelTaskTypeTitle>
          {currentTaskData.icon}

          <PanelTaskTypeOverlay isFixedToBottom={currentTask && currentTask.type === 'bpmn:Task'}>
            <PanelTaskTypeMenu>
              {Object.keys(taskData).filter(taskKey => taskKey !== 'voice-task' || ENABLE_VOICE_TASK === 'true').map(taskKey => (
                <MenuItem
                  key={uuid()}
                  isCurrent={taskData?.[taskKey]?.subType === currentTaskData?.subType}
                  onClick={() =>
                    ModelerService.replace(modeler, currentTask, taskData[taskKey].subType, updateCurrentTask)
                  }
                >
                  <MenuItemIcon>{taskData[taskKey].icon}</MenuItemIcon>
                  <MenuItemName>{taskData[taskKey].name}</MenuItemName>
                </MenuItem>
              ))}
            </PanelTaskTypeMenu>
          </PanelTaskTypeOverlay>
        </PanelHeaderRightSection>
      ) : (
        ''
      )}
    </PanelHeader>
  );
};

export default TaskPropertiesHeader;
