import React, {useState} from "react";
import CallbotTestClient from "./CallbotTestClient";
import styled from "styled-components";
import {PhoneOutlined} from "@ant-design/icons";
import * as mixins from "../../styles/mixins";
import * as colors from "../../styles/colors";

const PhoneIcon = styled(PhoneOutlined)`
  color: ${props => props.expanded ? colors.red : colors.white};
  font-size: 24px;
  ${props =>
          props.expanded ? `
            & > * {
                -webkit-transform: rotate(-135deg);
                transform: rotate(-135deg);
                ${mixins.transition(['.3s ease-in'])};
            }
        `
                  : `
            & > * {
                ${mixins.transition(['.3s ease-out'])};
            }
        `}
`

const Button = styled.button`
  width: 56px;
  height: 56px;
  border-radius: 50%;
  background: ${colors.mediumBlue};
  box-shadow: 2px 2px 10px 1px ${colors.darkBlue};
  :hover {
    background: ${colors.darkBlue};
  }
`

const Widget = styled.div`
  position: fixed;
  bottom: 40px;
  right: 40px;
`;

const ChatContainer = styled.div`
  position: fixed;
  bottom: 110px;
  right: 40px;
`


const CallbotWidget = ({}) => {
  const [expanded, setExpanded] = useState(false);

  return (
    <Widget>
      {expanded && <ChatContainer><CallbotTestClient/> </ChatContainer>}
      <Button onClick={() => setExpanded(x => !x)}><PhoneIcon expanded={expanded} /></Button>
    </Widget>
  );
}

export default CallbotWidget;
