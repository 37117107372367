const createNewCurrentDiagramAndVersion = ({ shouldSave, label }) => {
  return {
    type: 'CREATE_NEW_CURRENT_DIAGRAM_AND_VERSION',
    shouldSave: shouldSave,
    label,
  };
};

const changeCurrentDiagramAndVersion = ({ diagram, versionId, shouldSave, specificFile }) => {
  return {
    type: 'CHANGE_CURRENT_DIAGRAM_AND_VERSION',
    diagram,
    versionId,
    shouldSave,
    specificFile,
  };
};

const changeCurrentDiagramVersions = ({ diagram }) => {
  return {
    type: 'CHANGE_CURRENT_DIAGRAM_VERSIONS',
    diagram: diagram,
  };
};

const loadMoreCurrentDiagramVersions = (diagramId, startFrom) => {
  return {
    type: 'LOAD_MORE_CURRENT_DIAGRAM_VERSIONS',
    diagramId: diagramId,
    startFrom: startFrom,
  };
};

const showLessCurrentDiagramVersions = () => {
  return {
    type: 'SHOW_LESS_CURRENT_DIAGRAM_VERSIONS',
    newCount: 3,
  };
};

const loadSpecialDiagramVersion = (label, versionId) => {
  return {
    type: 'LOAD_SPECIAL_DIAGRAM_VERSION',
    label,
    versionId,
  };
};

const synchronizeCurrentDiagramVersions = () => {
  return {
    type: 'SYNCHRONIZE_CURRENT_DIAGRAM_VERSIONS',
  };
};

const saveNewCurrentDiagramAndVersion = ({
  diagram,
  diagramVersion,
  versionLabel,
  owner,
  file,
  diagramName,
  makeTest,
}) => {
  return {
    type: 'SAVE_NEW_CURRENT_DIAGRAM_AND_VERSION',
    diagramName: diagram ? diagram.name : diagramName,
    versionLabel: versionLabel?.normalize('NFKD'),
    owner: owner,
    file: file,
    makeTest: makeTest,
    diagramType: diagramVersion ? diagramVersion.diagramType : 'chatbot',
    diagramVersion,
  };
};

const saveCurrentDiagramVersionApprovedState = (versionId, isApproved) => {
  return {
    type: 'SAVE_CURRENT_DIAGRAM_VERSION_APPROVED_STATE',
    versionId: versionId,
    isApproved: isApproved,
  };
};

const saveOnlyDiagramVersionFile = file => {
  return {
    type: 'SAVE_ONLY_DIAGRAM_VERSION_FILE',
    file: file,
  };
};

const updateCurrentDiagramVersionApprovedState = isApproved => {
  return {
    type: 'UPDATE_CURRENT_DIAGRAM_VERSION_APPROVED_STATE',
    isApproved: isApproved,
  };
};

const saveCurrentDiagramAndVersion = ({
  diagram,
  diagramVersion,
  versionLabel,
  owner,
  file,
  diagramId,
  diagramName,
  makeTest,
  createdBy,
}) => {
  return {
    type: 'SAVE_CURRENT_DIAGRAM_AND_VERSION',
    diagramId: diagram ? diagram.id : diagramId,
    diagramName: diagram ? diagram.name : diagramName,
    owner: owner,
    versionLabel: versionLabel?.normalize('NFKD'),
    createdBy: createdBy || diagramVersion.createdBy,
    file: file,
    makeTest: makeTest,
    diagramType: diagramVersion ? diagramVersion.diagramType : 'chatbot',
    diagramVersion,
  };
};

const updateCurrentDiagramAndVersion = (
  diagram,
  newVersion,
  newVersions,
  hasMore,
  isDiagramSaved,
  isDiagramVersionSaved,
  isDiagramVersionFileSaved
) => {
  return {
    type: 'UPDATE_CURRENT_DIAGRAM_AND_VERSION',
    diagram: diagram,
    version: newVersion,
    versions: newVersions,
    hasMore: hasMore,
    isDiagramSaved: isDiagramSaved,
    isDiagramVersionSaved: isDiagramVersionSaved,
    isDiagramVersionFileSaved: isDiagramVersionFileSaved,
  };
};

const updateCurrentDiagram = newDiagram => {
  return {
    type: 'UPDATE_CURRENT_DIAGRAM',
    diagram: newDiagram,
  };
};

const updateCurrentDiagramName = newDiagramName => {
  return {
    type: 'UPDATE_CURRENT_DIAGRAM_NAME',
    name: newDiagramName,
  };
};

const updateCurrentDiagramOwner = newOwner => {
  return {
    type: 'UPDATE_CURRENT_DIAGRAM_OWNER',
    owner: newOwner,
  };
};

const updateCurrentDiagramVersionLabel = newVersionLabel => {
  return {
    type: 'UPDATE_CURRENT_DIAGRAM_VERSION_LABEL',
    label: newVersionLabel?.normalize('NFKD'),
  };
};

const updateCurrentOptionalComment = newComment => {
  return {
    type: 'UPDATE_CURRENT_OPTIONAL_DIAGRAM_COMMENT',
    comment: newComment,
  };
};

const updateCurrentDiagramShouldReview = diagramShouldReview => {
  return {
    type: 'UPDATE_CURRENT_DIAGRAM_SHOULD_REVIEW',
    diagramShouldReview: diagramShouldReview,
  };
};

const updateCurrentDiagramVersionFile = newFile => {
  return {
    type: 'UPDATE_CURRENT_DIAGRAM_VERSION_FILE',
    file: newFile,
  };
};

const updateCurrentDiagramToBeUnsaved = () => {
  return {
    type: 'UPDATE_CURRENT_DIAGRAM_TO_BE_UNSAVED',
  };
};

const updateCurrentFileState = newState => {
  return {
    type: 'UPDATE_CURRENT_FILE_STATE',
    newState: newState,
  };
};

const updateCurrentDiagramVersions = (newVersions, hasMore, newCount, shouldRenew = false) => {
  return {
    type: 'UPDATE_CURRENT_DIAGRAM_VERSIONS',
    extraDiagramVersions: newVersions,
    hasMore: hasMore,
    newCount: newCount,
    shouldRenew: shouldRenew,
  };
};

const updateCurrentDiagramTranslationsModificationCount = translationsModificationCount => {
  return {
    type: 'UPDATE_CURRENT_DIAGRAM_TRANSLATIONS_MODIFICATION_COUNT',
    translationsModificationCount,
  };
};

const setDiagramType = diagramType => ({
  type: 'SET_DIAGRAM_TYPE',
  payload: diagramType,
});

const diagramsActions = {
  createNewCurrentDiagramAndVersion,
  changeCurrentDiagramAndVersion,
  changeCurrentDiagramVersions,
  loadMoreCurrentDiagramVersions,
  showLessCurrentDiagramVersions,
  loadSpecialDiagramVersion,
  synchronizeCurrentDiagramVersions,
  saveNewCurrentDiagramAndVersion,
  saveCurrentDiagramAndVersion,
  saveOnlyDiagramVersionFile,
  saveCurrentDiagramVersionApprovedState,
  updateCurrentDiagramVersionApprovedState,
  updateCurrentDiagramAndVersion,
  updateCurrentDiagram,
  updateCurrentDiagramName,
  updateCurrentDiagramOwner,
  updateCurrentDiagramToBeUnsaved,
  updateCurrentDiagramVersionLabel,
  updateCurrentOptionalComment,
  updateCurrentDiagramShouldReview,
  updateCurrentDiagramVersionFile,
  updateCurrentFileState,
  updateCurrentDiagramVersions,
  updateCurrentDiagramTranslationsModificationCount,
  setDiagramType,
};

export default diagramsActions;
