import BaseRenderer from 'diagram-js/lib/draw/BaseRenderer';

import {
  append as svgAppend,
  attr as svgAttr,
  classes as svgClasses,
  create as svgCreate,
  remove as svgRemove,
  innerSVG as svgInnerAppend,
} from 'tiny-svg';

import { getRoundRectPath } from 'bpmn-js/lib/draw/BpmnRenderUtil';

import { is } from 'bpmn-js/lib/util/ModelUtil';
import { isAny } from 'bpmn-js/lib/features/modeling/util/ModelingUtil';

import modeling from 'bpmn-js/lib/features/modeling';
import appReducer from '../../../reducers/appReducer';

const HIGH_PRIORITY = 1500;

export default class ServiceTaskRenderer extends BaseRenderer {
  constructor(eventBus, bpmnRenderer) {
    super(eventBus, HIGH_PRIORITY);

    this.bpmnRenderer = bpmnRenderer;
    // this.modeling = bpmnRenderer.get('modeling');

    this.elementWidth = 160;
    this.currentZoomLevel = 0;
  }

  canRender(element) {
    return is(element, 'bpmn:ServiceTask');
  }

  svg_textMultiline(id, maxWidth, zoom) {
    var x = 10;
    var y = 0;
    var dy = 15;

    /* get the text */
    var element = document.getElementById(id);

    var text = element.innerHTML;

    var words = text.split(' ');
    var line = '';

    var constructedText = '';

    for (var n = 0; n < text.length; n++) {
      //
      element.innerHTML = '<tspan id="PROCESSING">busy</tspan >';

      var newTextLine = line + text[n];

      const newTextElem = document.getElementById('PROCESSING');

      newTextElem.innerHTML = newTextLine;
      var metrics = newTextElem.getBoundingClientRect();
      var newTextWidth = metrics.width;

      //

      if (newTextWidth > maxWidth - 2 * x * (zoom ? zoom : 1)) {
        if ((constructedText.match(/<tspan/g) || []).length > 0) {
          constructedText += '<tspan x="' + x + '" dy="' + dy + '">' + line + '</tspan>';
        } else {
          constructedText += '<tspan x="' + x + '" dy="' + y + '">' + line + '</tspan>';
        }
        line = text[n];
      } else {
        line = newTextLine;
      }

      document.getElementById('PROCESSING').remove();
    }
    if ((constructedText.match(/<tspan/g) || []).length > 0) {
      constructedText += '<tspan x="' + x + '" dy="' + dy + '">' + line + '</tspan>';
    } else {
      constructedText += '<tspan x="' + x + '" dy="' + y + '">' + line + '</tspan>';
    }

    element.innerHTML = constructedText;

    //
  }

  // Main function to draw custom shape

  drawShape(parentNode, element) {
    element.width = this.elementWidth;
    let element_id = `${element.id}${Math.floor(Math.random() * 10000)}`;
    // element id can repeat, so add "salt" to avoid it

    let shapeProcessing = this.bpmnRenderer.drawShape(parentNode, element);

    this.lastActualWidth = shapeProcessing.getBoundingClientRect().width;

    const newLabelText = svgCreate('text');
    svgAttr(newLabelText, {
      class: 'djs-label-small',
      x: 10,
      y: 20,
    });

    newLabelText.appendChild(document.createTextNode('request method'));

    parentNode.appendChild(newLabelText);

    const newText = svgCreate('text');
    svgAttr(newText, {
      id: `ApiTextFor${element_id}`,
      class: 'djs-label',
      x: 10,
      y: 35,
    });

    newText.appendChild(document.createTextNode(element.businessObject.method || 'No request url selected'));

    parentNode.appendChild(newText);

    let zoom;
    if (shapeProcessing.getBoundingClientRect().width) {
      zoom = (shapeProcessing.getBoundingClientRect().width / this.elementWidth).toFixed(4);
    } else {
      zoom = 1;
    }
    let InputClientRect;
    try {
      this.svg_textMultiline(`ApiTextFor${element_id}`, this.lastActualWidth, zoom);

      InputClientRect = document.getElementById(`ApiTextFor${element_id}`).getBoundingClientRect();
    } catch (e) {
      InputClientRect = {height: 15};
    }
    let oldInputHeight = element.height;

    const newLabelText2 = svgCreate('text');
    svgAttr(newLabelText2, {
      class: 'djs-label-small',
      x: 10,
      y: 40 + (InputClientRect.height || 15) * (1 / zoom),
    });

    newLabelText2.appendChild(document.createTextNode('request url'));

    parentNode.appendChild(newLabelText2);

    const newText2 = svgCreate('text');
    svgAttr(newText2, {
      id: `RequestTextFor${element_id}`,
      class: 'djs-label',
      x: 10,
      y: 55 + (InputClientRect.height || 15) * (1 / zoom),
    });

    newText2.appendChild(document.createTextNode(element.businessObject.url || 'No url specified'));

    parentNode.appendChild(newText2);
    let ChoicesClientRect;
    try {
      this.svg_textMultiline(`RequestTextFor${element_id}`, this.lastActualWidth, zoom);

      ChoicesClientRect = document.getElementById(`RequestTextFor${element_id}`).getBoundingClientRect();
    } catch (e) {
      ChoicesClientRect = {height: 15}
    }
    const newLabelTextResponse = svgCreate('text');
    svgAttr(newLabelTextResponse, {
      class: 'djs-label-small',
      x: 10,
      y: 60 + (InputClientRect.height + ChoicesClientRect.height || 30) * (1 / zoom),
    });

    newLabelTextResponse.appendChild(document.createTextNode('variable name for response'));

    parentNode.appendChild(newLabelTextResponse);

    const newTextResponse = svgCreate('text');
    svgAttr(newTextResponse, {
      id: `ResponseTextFor${element_id}`,
      class: 'djs-label',
      x: 10,
      y: 75 + (InputClientRect.height + ChoicesClientRect.height || 30) * (1 / zoom),
    });

    newTextResponse.appendChild(
        document.createTextNode(element.businessObject.identifierForResponse || 'No name specified')
    );

    parentNode.appendChild(newTextResponse);
    let ResponseClientRect;
    try {
      this.svg_textMultiline(`ResponseTextFor${element_id}`, this.lastActualWidth, zoom);

      ResponseClientRect = document.getElementById(`ResponseTextFor${element_id}`).getBoundingClientRect();
    } catch (e) {
      ResponseClientRect = {height: 15};
    }
    if (element.businessObject.method !== "GET") {
      const newLabelTextBody = svgCreate('text');
      svgAttr(newLabelTextBody, {
        class: 'djs-label-small',
        x: 10,
        y: 80 + (InputClientRect.height + ChoicesClientRect.height + ResponseClientRect.height || 45) * (1 / zoom),
      });

      newLabelTextBody.appendChild(document.createTextNode(
        element.businessObject.method === "POST" ? 'request body' : 'request variable'
      ));

      parentNode.appendChild(newLabelTextBody);

      const newTextBody = svgCreate('text');
      svgAttr(newTextBody, {
        id: `BodyTextFor${element_id}`,
        class: 'djs-label',
        x: 10,
        y: 95 + (InputClientRect.height + ChoicesClientRect.height + ResponseClientRect.height || 45) * (1 / zoom),
      });

      newTextBody.appendChild(document.createTextNode(
        element.businessObject.method === "POST" ?
          element.businessObject.body : (element.businessObject.requestVariable || 'No request variable specified')
      ));

      parentNode.appendChild(newTextBody);
      try {
        this.svg_textMultiline(`BodyTextFor${element_id}`, this.lastActualWidth, zoom);
      } catch (err) {

      }
    }
    let shape;

    if (element.businessObject.method !== 'GET') {
      let BodyClientRect;
      try {
        BodyClientRect = document.getElementById(`BodyTextFor${element_id}`).getBoundingClientRect();
      } catch (e) {
        BodyClientRect = {height: 15}
      }

      let newHeight = Math.round(
        InputClientRect.height +
          ChoicesClientRect.height +
          ResponseClientRect.height +
          BodyClientRect.height +
          90 * (zoom ? zoom : 1) >
          150 * (zoom ? zoom : 1)
          ? InputClientRect.height +
              ChoicesClientRect.height +
              ResponseClientRect.height +
              BodyClientRect.height +
              90 * (zoom ? zoom : 1)
          : 150 * (zoom ? zoom : 1)
      );
      newHeight = zoom === 1 ? newHeight : newHeight * (1 / zoom);

      svgAttr(shapeProcessing, {
        height: newHeight,
      });

      element.width = this.elementWidth;
      element.height = newHeight;
      element.y = element.y - (newHeight / 2 - oldInputHeight / 2);

      shape = this.bpmnRenderer.drawShape(parentNode, element);
      svgAttr(parentNode.childNodes[13], {
        fillOpacity: 0,
        strokeWidth: 3,
        stroke: '#FF8000',
        class: 'djs-rect',
      });

      svgRemove(parentNode.childNodes[0]);
    } else {
      let newHeight = Math.round(
        InputClientRect.height +
          ChoicesClientRect.height +
          ResponseClientRect.height +
          75 * (zoom ? zoom : 1) >
          105 * (zoom ? zoom : 1)
          ? InputClientRect.height +
            ChoicesClientRect.height +
            ResponseClientRect.height + 75 * (zoom ? zoom : 1)
          : 105 * (zoom ? zoom : 1)
      );
      newHeight = zoom === 1 ? newHeight : newHeight * (1 / zoom);

      svgAttr(shapeProcessing, {
        height: newHeight,
      });

      element.width = this.elementWidth;
      element.height = newHeight;
      element.y = element.y - (newHeight / 2 - oldInputHeight / 2);

      shape = this.bpmnRenderer.drawShape(parentNode, element);
      svgAttr(parentNode.childNodes[11], {
        fillOpacity: 0,
        strokeWidth: 3,
        stroke: '#FF8000',
        class: 'djs-rect',
      });
      svgRemove(parentNode.childNodes[0]);
    }

    drawRect(parentNode);

    this.currentZoomLevel = zoom;

    return shape;
  }
}

ServiceTaskRenderer.$inject = ['eventBus', 'bpmnRenderer'];

// helpers //////////

// copied from https://github.com/bpmn-io/bpmn-js/blob/master/lib/draw/BpmnRenderer.js
function drawRect(parentNode, width, height, borderRadius, strokeColor) {
  const g = svgCreate('g');

  svgAttr(g, {
    transform: 'translate(-11, -11)',
    fill: 'white',
  });

  const rect = '<rect width="24" height="24" fill="white"></rect>';

  const iconContent =
    '<path d="M21 2L14 22L10 13L1 9L21 2Z" stroke="#000F3C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>' +
    '<path d="M21 2L10 13" stroke="#000F3C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>';
  svgInnerAppend(g, rect + iconContent);

  svgAppend(parentNode, g);

  return g;
}

// copied from https://github.com/bpmn-io/diagram-js/blob/master/lib/core/GraphicsFactory.js
function prependTo(newNode, parentNode, siblingNode) {
  parentNode.insertBefore(newNode, siblingNode || parentNode.firstChild);
}
