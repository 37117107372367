import React from 'react';
import styled from 'styled-components';
import * as mixins from '../../../styles/mixins';
import * as colors from '../../../styles/colors';
import * as typography from '../../../styles/typography';

import SaveNewIcon from '../../shared/icons/tools/SaveNewIcon';

const FooterContent = styled.div`
  margin-top: 32px;
`;

const FooterTitle = styled.p`
  margin-left: 14px;
  color: ${colors.mainFontColor};
  ${mixins.fontStyle(typography.titleFontSize, typography.medium)};
`;

const FooterButton = styled.div`
  ${mixins.flex('flex-start')};
  cursor: pointer;

  &:hover > ${FooterTitle} {
    color: ${colors.alternativeFontColor};
  }
`;

const VersionControlFooter = ({ createNewCurrentDiagramAndVersion }) => {
  return (
    <FooterContent>
      <FooterButton
        onClick={() =>
          createNewCurrentDiagramAndVersion({
            shouldSave: false,
          })
        }
      >
        <SaveNewIcon color={colors.alternativeIconColor} />
        <FooterTitle>Create new solution flow</FooterTitle>
      </FooterButton>
    </FooterContent>
  );
};

export default VersionControlFooter;
