import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { LanguageSelect } from './LanguageSelect';
import { SortableContainer, SortableElement, SortableHandle, arrayMove } from 'react-sortable-hoc';
import { MenuOutlined } from '@ant-design/icons';

import $ from 'jquery';
import { Button, Tooltip, Input } from 'antd';
const sha256 = require('crypto-js/sha256');

const { Search } = Input;
const ENABLE_CHAT_DIALOGUE_TRIGGER = process.env.ENABLE_CHAT_DIALOGUE_TRIGGER || 'true';
const filterList = (parentList, filterString) => {
  return filterString
    ? parentList.filter(option => option.name.toLowerCase().indexOf(filterString.toLowerCase()) >= 0)
    : parentList;
};

function sortBasedOnFilter(originalList, filteredList) {
  const filteredSet = new Set(filteredList); // Create a set of filtered names for efficient lookup

  // Sort the original list using a custom comparator function
  originalList.sort((a, b) => {
    const nameA = a.name;
    const nameB = b.name;

    // Check if both names are in the filtered list
    const isInFilteredListA = filteredSet.has(nameA);
    const isInFilteredListB = filteredSet.has(nameB);

    // Compare based on presence in the filtered list
    if (isInFilteredListA && isInFilteredListB) {
      // If both names are in the filtered list, maintain the filtered list order
      return filteredList.indexOf(nameA) - filteredList.indexOf(nameB);
    } else if (isInFilteredListA) {
      // If only nameA is in the filtered list, it should come before nameB
      return -1;
    } else if (isInFilteredListB) {
      // If only nameB is in the filtered list, it should come before nameA
      return 1;
    } else {
      // If neither name is in the filtered list, maintain the original order
      return 0;
    }
  });

  return originalList;
}

const getOriginalIndex = (filteredArray, filteredIndex, originalArray) => {
  // Get the element from the filtered list
  const element = filteredArray[filteredIndex];

  // Find the corresponding index in the original list
  const originalIndex = originalArray.findIndex(obj => obj.name === element.name);

  return originalIndex;
};
const BotAttributesComponent = ({ socket, labels, labelsFromOverview, userId, chatId, width, availChats }) => {
  const ref = useRef(labels);
  ref.current = labels;
  const [state, setState] = useState({ labels: [], filteredLabels: [], searchLabel: '' });
  const setLabels = () => {
    let labels = ref.current?.filter(label => label.sfm?.live) || [];
    try {
      let orderedLabels = JSON.parse(localStorage.getItem('liveChatFlowsOrder'));
      labels = sortBasedOnFilter(labels, orderedLabels);
    } catch (err) {}
    setState({
      ...state,
      labels,
      filteredLabels: filterList(labels, state.searchLabel),
    });
  };

  const [currentChatLanguage, setCurrentChatLanguage] = useState(null);
  const [currentLanguage, setCurrentLanguage] = useState(null);

  const chat = availChats.find(c => chatId && c.chatId === chatId);

  useEffect(() => {
    setCurrentChatLanguage(chat?.language);
    setCurrentLanguage(chat?.language);
  }, [chatId]);

  useEffect(() => {
    if (currentChatLanguage === currentLanguage) {
      setCurrentLanguage(chat?.language);
    }
    setCurrentChatLanguage(chat?.language);
  }, [chat?.language]);

  useEffect(() => {
    socket.on('entities', console.log);
    setLabels();
  }, []);
  useEffect(() => {
    setLabels();
  }, [JSON.stringify(labels)]);
  const getLabelDescription = label => {
    if (labelsFromOverview) {
      for (const lfo of labelsFromOverview) {
        if (lfo.name === label.name) {
          return lfo?.sfm?.description_translations?.translations?.[currentLanguage]?.text || lfo.description;
        }
      }
    }
  };
  const triggerDialogue = (dialogue, language) => {
    const data = {
      chat_session_uid: userId,
      dialogue_code: 'automated-dialog',
      context_data: dialogue,
      default_label_language: language,
    };
    const hashedKeys = ['chat_session_uid', 'dialogue_code', 'context_data', 'force', 'trigger_type'];
    const ELISA_SALT = process.env.ELISA_SALT || '8b490009910f4cdb9b9251257132ae7y';
    const concatenated_string = hashedKeys.map(key => data[key] || '').join('') + ELISA_SALT;
    const hash = sha256(concatenated_string).toString();

    socket.emit('start_solution', {
      ...data,
      hash,
    });
  };
  const applyTooltipStyle = () => {
    $('.ant-tooltip').css('max-width', '86%');
  };
  const filterLabels = ev => {
    setState({
      ...state,
      searchLabel: ev.target.value,
      filteredLabels: filterList(state.labels, ev.target.value),
    });
  };

  const DragHandle = SortableHandle(() => (
    <div style={{ cursor: 'move' }} className="drag-handle">
      <MenuOutlined style={{ color: 'F2F2F2' }} color="red" />
    </div>
  ));

  const SortableItem = SortableElement(({ label, currentLanguage, index }) => (
    <div className="dialogue-item">
      <Button className="dialogue-button">
        <DragHandle />
        {/* <div className="dialogue-content" onClick={() => triggerDialogue(label.name, currentLanguage)}> */}
        <Tooltip
          key={index}
          getPopupContainer={trigger => {
            return trigger;
          }}
          onVisibleChange={applyTooltipStyle}
          placement="bottomLeft"
          color="#F2F2F2"
          overlayStyle={{ width: 1000 }}
          overlayInnerStyle={{ width: 'fit-content', color: '#002348', textAlign: 'center' }}
          title={getLabelDescription(label)}
        >
          <div style={{ display: 'flex' }}>
            <span
              style={{ marginLeft: 15 }}
              className="dialogue-label"
              onClick={() => triggerDialogue(label.name, currentLanguage)}
            >
              {label.name}
            </span>
          </div>
        </Tooltip>
        {/* <span className="dialogue-description">{label.description}</span> */}
        {/* </div> */}
      </Button>
    </div>
  ));

  const SortableList = SortableContainer(({ items, currentLanguage }) => (
    <div id="dialogue-list">
      {items.map((label, index) => (
        <SortableItem key={index} index={index} label={label} currentLanguage={currentLanguage} />
      ))}
    </div>
  ));

  const onSortEnd = ({ oldIndex, newIndex }) => {
    const oldIndexOriginal = getOriginalIndex(state.filteredLabels, oldIndex, state.labels);
    const newIndexOriginal = getOriginalIndex(state.filteredLabels, newIndex, state.labels);

    localStorage.setItem(
      'liveChatFlowsOrder',
      JSON.stringify(arrayMove(state.labels, oldIndexOriginal, newIndexOriginal).map(x => x.name))
    );
    setState(prevState => ({
      ...prevState,
      labels: arrayMove(prevState.labels, oldIndexOriginal, newIndexOriginal),
      filteredLabels: arrayMove(prevState.filteredLabels, oldIndex, newIndex),
    }));
  };

  return (
    <div className={'col-sm-' + width + ' side bot-attributes'}>
      <div className="side-one">
        {ENABLE_CHAT_DIALOGUE_TRIGGER === 'true' ? (
          <>
            <div id="search-dialogue">
              <div className="row heading background-info">Trigger solution flow</div>
              <div className="inline-flex">
                <Search
                  dir="auto"
                  disabled={!chatId}
                  loading={!labels}
                  placeholder="Search solution flow"
                  size="large"
                  onChange={filterLabels}
                />
                <div className="pdl-sm">
                  <LanguageSelect
                    value={currentLanguage}
                    disabled={!chatId}
                    onValueChange={newValue => setCurrentLanguage(newValue || chat?.language)}
                  />
                </div>
              </div>
            </div>
            {chatId && (
              <SortableList
                help
                items={state.filteredLabels}
                currentLanguage={currentLanguage}
                onSortEnd={onSortEnd}
                helperClass="dragging-item"
                useDragHandle
              />
            )}
          </>
        ) : null}
      </div>
    </div>
  );
};

const mapStateToProps = function (store) {
  return {
    socket: store.appState.socket,
    chatId: store.appState.chatId,
    availChats: store.appState.availChats,
    userId: store.appState.userId,
    l1Selectables: store.appState.l1Selectables,
    l2Selectables: store.appState.l2Selectables,
    l3Selectables: store.appState.l3Selectables,
    solutionSelectables: store.appState.availProblemsFlat,
    solutionl1: store.appState.solutionl1,
    solutionl2: store.appState.solutionl2,
    solutionl3: store.appState.solutionl3,
    solution: store.appState.solution,
    ttl_code_required: store.appState.ttl_code_required,
  };
};
const mapDispatchToProps = dispatch => {
  return {
    l1Chosen: chosenValue =>
      dispatch({
        type: 'L1_CHOSEN',
        problemCode: chosenValue.value,
      }),
    l2Chosen: chosenValue =>
      dispatch({
        type: 'L2_CHOSEN',
        problemCode: chosenValue.value,
      }),
    l3Chosen: chosenValue =>
      dispatch({
        type: 'L3_CHOSEN',
        problemCode: chosenValue.value,
      }),
    solutionChosen: chosenValue =>
      dispatch({
        type: 'L1_TO_3_CHOSEN',
        problemCode: chosenValue.value,
      }),
  };
};
const BotAttributes = connect(mapStateToProps, mapDispatchToProps)(BotAttributesComponent);

export { BotAttributes };
