import React from 'react';
import styled from 'styled-components';
import { Badge } from 'antd';
import * as mixins from '../styles/mixins';
import * as colors from '../styles/colors';
import * as typography from '../styles/typography';
import { NotificationContainer } from 'react-notifications';
import 'react-notifications/lib/notifications.css';

import VersionControlButton from './version-control-button/VersionControlButton';
import DiagramSaveButton from './diagram-save-button/DiagramSaveButton';
import DiagramReview from './diagram-review/DiagramReview';
import OwnersVisibilityButtons from './owners-visibility-buttons/OwnersVisibilityButtons';
import VersionControlPanelContainer from '../containers/version-control-panel/VersionControlPanelContainer';
import TaskPropertiesPanelContainer from '../containers/task-properties-panel/TaskPropertiesPanelContainer';
import ModalsContainer from '../containers/modals/ModalsContainer';
import DiagramPropertiesPanelContainer from '../containers/diagram-properties-panel/DiagramPropertiesPanelContainer';
import SaveProgressOverlayContainer from '../containers/save-progress-overlay/SaveProgressOverlayContainer';
import SubProcessPanelContainer from '../containers/sub-process-panel/SubProcessPanelContainer';
import EventPropertiesPanelContainer from '../containers/event-properties-panel/EventPropertiesPanelContainer';
import { Icon } from '@iconify/react/dist/offline';
import translateIcon from '@iconify-icons/carbon/translate';
import BotToggleButtons from './bot-toggle/BotToggleButtons';
import CallbotWidget from "./callbot-test-client/CallbotWidget";

const ToolsMenu = styled.div`
  position: fixed;
  z-index: 1000;
  box-sizing: border-box;
  margin: 24px;

  ${props => (props.blured ? mixins.applyBlur() : '')};
`;
const ENABLE_HEADER = process.env.ENABLE_HEADER || 'true';
// If ENABLE_HEADER is not 'true', then Elisa's instance. Need to unify later
const isElisa = ENABLE_HEADER !== 'true';

const ToolsTopLeftMenu = styled(ToolsMenu)`
  top: ${ isElisa ? '0px' : '90px'};
  left: 300px;

  display: flex;
`;

const ToolsTopRightMenu = styled(ToolsMenu)`
  display: flex;
  top: ${ isElisa ? '0px' : '90px'};
  right: 0;
`;

const ToolsLanguagesView = styled.div`
  margin-right: 10px;
  cursor: pointer;
`;

const ToolsLanguagesButton = styled(Icon)`
  font-size: 48px;
  background-color: #f2f2f2;
  color: #2b3b6f;
  padding: 10px;
`;

const ToolsBottomLeftMenu = styled(ToolsMenu)`
  bottom: 0;
  left: calc(300px + 56px);

  display: flex;
  align-items: flex-end;
`;

const ToolsBottomRightMenu = styled(ToolsMenu)`
  bottom: 0;
  right: 60px;
  height: 48px;

  display: flex;
  align-items: flex-end;
`;

const ToolsAppStatus = styled.p`
  display: block;
  box-sizing: border-box;
  padding: 3px 10px;
  border-radius: 2px;
  background-color: ${colors.componentBackgroundColor};

  color: ${colors.mainFontColor};
  ${mixins.fontStyle(typography.labelFontSize, typography.normal)};
  ${mixins.flex()};
`;

const Tools = ({
  labels,
  modeler,
  viewer,
  reviewViewer,
  visibility,
  toggleVersionControlVisibility,
  toggleOwnersVisibility,
  currentDiagram,
  currentDiagramVersion,
  reviewMode,
  isCurrentDiagramSaved,
  isCurrentDiagramVersionSaved,
  isCurrentDiagramVersionFileSaved,
  toggleUserTasksHighlight,
  fetchDiagramsList,
  saveAllWithVerification,
  saveOnlyDiagramVersionFile,
  saveAll,
  saveCurrentDiagramVersionApprovedState,
  saveInProgress,
  updateSaveProgress,
  setLanguagesOverlayVisible,
  unreviewedTranslationsCount,
  settings,
  diagramType,
  setDiagramType,
  translationsTotal,
  translationsPerLang,
}) => {
  const shouldBeBlur = visibility.versionControlPanelVisible || saveInProgress;
  const showTranslationIndicator = translationsPerLang.some(t => t > 0 && t < translationsTotal);
  return (
    <>
      {diagramType === "callbot" && <CallbotWidget />}
      <div id="app-tools-wrapper">
        <ToolsTopLeftMenu blured={shouldBeBlur}>
          <VersionControlButton
            toggleVersionControlVisibility={toggleVersionControlVisibility}
            fetchDiagramsList={fetchDiagramsList}
            isFixed={visibility.versionControlPanelVisible}
          />
          <DiagramPropertiesPanelContainer />
          <DiagramSaveButton
            modeler={modeler}
            reviewViewer={reviewViewer}
            currentDiagram={currentDiagram}
            currentDiagramVersion={currentDiagramVersion}
            saveAllWithVerification={saveAllWithVerification}
            saveOnlyDiagramVersionFile={saveOnlyDiagramVersionFile}
            saveAll={saveAll}
            updateSaveProgress={updateSaveProgress}
            isCurrentDiagramSaved={isCurrentDiagramSaved}
            isCurrentDiagramVersionSaved={isCurrentDiagramVersionSaved}
            isCurrentDiagramVersionFileSaved={isCurrentDiagramVersionFileSaved}
            reviewMode={reviewMode}
          />
          <BotToggleButtons diagramType={diagramType} setDiagramType={setDiagramType} />
          {reviewMode ? (
            <DiagramReview
              currentDiagramVersion={currentDiagramVersion}
              saveCurrentDiagramVersionApprovedState={saveCurrentDiagramVersionApprovedState}
            />
          ) : (
            ''
          )}
        </ToolsTopLeftMenu>
        <ToolsTopRightMenu blured={shouldBeBlur}>
          {currentDiagramVersion?.id !== -1 && (
            <>
              {(labels || isElisa) && (
                <Badge
                  count={unreviewedTranslationsCount || undefined}
                  offset={[-12, 0]}
                  dot={unreviewedTranslationsCount ? undefined : showTranslationIndicator}
                >
                  <ToolsLanguagesView onClick={() => setLanguagesOverlayVisible(true)}>
                    <ToolsLanguagesButton icon={translateIcon} />
                  </ToolsLanguagesView>
                </Badge>
              )}
            </>
          )}
          <OwnersVisibilityButtons
            ownersVisible={visibility.ownersVisible}
            userTasksHighlighted={visibility.userTasksHighlighted}
            toggleOwnersVisibility={toggleOwnersVisibility}
            toggleUserTasksHighlight={toggleUserTasksHighlight}
          />
        </ToolsTopRightMenu>
        <ToolsBottomLeftMenu blured={shouldBeBlur}>
          <TaskPropertiesPanelContainer
            modeler={modeler}
            reviewMode={reviewMode}
            settings={settings}
            diagramType={diagramType}
          />
          <SubProcessPanelContainer modeler={modeler} viewer={viewer} fetchDiagramsList={fetchDiagramsList} />
          <EventPropertiesPanelContainer modeler={modeler} reviewMode={reviewMode} />
          {/*<CommentsVisibilityButton />*/}
        </ToolsBottomLeftMenu>
        {process.env.ENV !== 'staging' ? (
          <ToolsBottomRightMenu>
            <ToolsAppStatus style={{ display: 'none' }}>Mode: {process.env.ENV}</ToolsAppStatus>
          </ToolsBottomRightMenu>
        ) : (
          ''
        )}
      </div>
      <VersionControlPanelContainer />
      <SaveProgressOverlayContainer />
      <ModalsContainer />
      <NotificationContainer />
    </>
  );
};

export default Tools;
