import BaseRenderer from 'diagram-js/lib/draw/BaseRenderer';

import {
  append as svgAppend,
  attr as svgAttr,
  classes as svgClasses,
  create as svgCreate,
  remove as svgRemove,
  innerSVG as svgInnerAppend,
} from 'tiny-svg';

import { getRoundRectPath } from 'bpmn-js/lib/draw/BpmnRenderUtil';

import { is } from 'bpmn-js/lib/util/ModelUtil';
import { isAny } from 'bpmn-js/lib/features/modeling/util/ModelingUtil';

import modeling from 'bpmn-js/lib/features/modeling';

const HIGH_PRIORITY = 1500;

export default class HelpdeskMessageTaskRenderer extends BaseRenderer {
  constructor(eventBus, bpmnRenderer) {
    super(eventBus, HIGH_PRIORITY);

    this.bpmnRenderer = bpmnRenderer;
    // this.modeling = bpmnRenderer.get('modeling');

    // this.maxHeight = 0;
    this.elementWidth = 200;
    this.currentZoomLevel = 1;
  }

  canRender(element) {
    return is(element, 'bpmn:SendTask') && element.businessObject.subType === 'helpdesk-message-task';
  }

  svg_textMultiline(id, maxWidth, zoom) {
    var x = 10;
    var y = 0;
    var dy = 15;

    /* get the text */
    var element = document.getElementById(id);

    var text = element.innerHTML;

    var words = text.split(' ');
    var line = '';

    var constructedText = '';

    for (var n = 0; n < text.length; n++) {
      element.innerHTML = '<tspan id="PROCESSING">busy</tspan >';

      var newTextLine = line + text[n];

      const newTextElem = document.getElementById('PROCESSING');

      newTextElem.innerHTML = newTextLine;
      var metrics = newTextElem.getBoundingClientRect();
      var newTextWidth = metrics.width;

      if (newTextWidth > maxWidth - 2 * x * (zoom ? zoom : 1)) {
        if ((constructedText.match(/<tspan/g) || []).length > 0) {
          constructedText += '<tspan x="' + x + '" dy="' + dy + '">' + line + '</tspan>';
        } else {
          constructedText += '<tspan x="' + x + '" dy="' + y + '">' + line + '</tspan>';
        }
        line = text[n];
      } else {
        line = newTextLine;
      }

      document.getElementById('PROCESSING').remove();
    }
    if ((constructedText.match(/<tspan/g) || []).length > 0) {
      constructedText += '<tspan x="' + x + '" dy="' + dy + '">' + line + '</tspan>';
    } else {
      constructedText += '<tspan x="' + x + '" dy="' + y + '">' + line + '</tspan>';
    }

    element.innerHTML = constructedText;
  }

  // Main function to draw custom shape

  drawShape(parentNode, element) {
    let element_id = `${element.id}${Math.floor(Math.random() * 10000)}`;
    const parentNodeBounds = parentNode.getBoundingClientRect();

    element.width = this.elementWidth;

    let shapeProcessing = this.bpmnRenderer.drawShape(parentNode, element);

    this.lastActualWidth = shapeProcessing.getBoundingClientRect().width;

    const newText = svgCreate('text');
    svgAttr(newText, {
      id: `newTextFor${element_id}`,
      class: 'djs-label',
      x: 10,
      y: 35,
    });

    newText.appendChild(document.createTextNode(element.businessObject.template));

    parentNode.appendChild(newText);

    const newLabelText = svgCreate('text');
    svgAttr(newLabelText, {
      id: `newLabelText`,
      class: 'djs-label-small',
      x: 10,
      y: 20,
    });

    newLabelText.appendChild(document.createTextNode('message template'));

    parentNode.appendChild(newLabelText);

    let zoom;
    if (shapeProcessing.getBoundingClientRect().width) {
      zoom = (shapeProcessing.getBoundingClientRect().width / this.elementWidth).toFixed(4);
    } else {
      zoom = 1;
    }

    let newClientRect;
    try {
      this.svg_textMultiline(`newTextFor${element_id}`, this.lastActualWidth, zoom);
      newClientRect = document.getElementById(`newTextFor${element_id}`).getBoundingClientRect();
    } catch (e) {
      newClientRect = {height: 15};
    }

    let newHeight = Math.round(
      newClientRect.height + 40 * (zoom ? zoom : 1) > 80 * (zoom ? zoom : 1)
        ? newClientRect.height + 40 * (zoom ? zoom : 1)
        : 80 * (zoom ? zoom : 1)
    );
    let oldHeight = element.height;
    newHeight = zoom === 1 ? newHeight : newHeight * (1 / zoom);

    svgAttr(shapeProcessing, {
      height: newHeight,
    });

    element.width = this.elementWidth;
    element.height = newHeight;
    element.y = element.y - (newHeight / 2 - oldHeight / 2);

    let shape = this.bpmnRenderer.drawShape(parentNode, element);

    svgAttr(parentNode.childNodes[5], {
      fillOpacity: 0,
      strokeWidth: 3,
      stroke: '#263C73',
      class: 'djs-rect',
    });

    svgRemove(parentNode.childNodes[0]);

    drawRect(parentNode);

    this.currentZoomLevel = zoom;

    return shape;
  }
}

HelpdeskMessageTaskRenderer.$inject = ['eventBus', 'bpmnRenderer'];

// helpers //////////

// copied from https://github.com/bpmn-io/bpmn-js/blob/master/lib/draw/BpmnRenderer.js
function drawRect(parentNode, width, height, borderRadius, strokeColor) {
  const g = svgCreate('g');

  svgAttr(g, {
    transform: 'translate(-8, -8)',
    fill: 'white',
  });

  /*const rect = svgCreate('rect');

  svgAttr(rect, {
    width: width,
    height: height,
    fill: "#fff"
  });*/

  //svgAppend(g, rect);

  const rect = '<rect width="22" height="22" fill="white"></rect>';

  const iconContent = `<path d="M3 15V12C3 9.61305 3.94821 7.32387 5.63604 5.63604C7.32387 3.94821 9.61305 3 12 3C14.3869 3 16.6761 3.94821 18.364 5.63604C20.0518 7.32387 21 9.61305 21 12V15" stroke="#030F3A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M21 16C20.3822 18.3056 17.5 22 14.5 22" stroke="#030F3A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M21 16C21 16.5304 20.7893 17.0391 20.4142 17.4142C20.0391 17.7893 19.5304 18 19 18H18C17.4696 18 16.9609 17.7893 16.5858 17.4142C16.2107 17.0391 16 16.5304 16 16V13C16 12.4696 16.2107 11.9609 16.5858 11.5858C16.9609 11.2107 17.4696 11 18 11H21V16ZM3 16C3 16.5304 3.21071 17.0391 3.58579 17.4142C3.96086 17.7893 4.46957 18 5 18H6C6.53043 18 7.03914 17.7893 7.41421 17.4142C7.78929 17.0391 8 16.5304 8 16V13C8 12.4696 7.78929 11.9609 7.41421 11.5858C7.03914 11.2107 6.53043 11 6 11H3V16Z" stroke="#030F3A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>`;

  svgInnerAppend(g, rect + iconContent);

  svgAppend(parentNode, g);

  return g;
}

// copied from https://github.com/bpmn-io/diagram-js/blob/master/lib/core/GraphicsFactory.js
function prependTo(newNode, parentNode, siblingNode) {
  parentNode.insertBefore(newNode, siblingNode || parentNode.firstChild);
}
