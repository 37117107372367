import React from 'react';
import * as colors from '../../styles/colors';

import {
  ModalWrapper,
  ModalContent,
  ModalIconContent,
  ModalTitle,
  ModalParagraph,
  ModalButtons,
  CancelButton,
  ConfirmButton,
} from './modals-elements/ModalsElements';
import RadioIcon from '../shared/icons/tools/RadioIcon';

const ConfirmDeleteVersionModal = ({
  modalKey,
  closeModal,
  proceedOnModalConfirm,
  actionOnConfirm,
  argsOnConfirm,
  extraModalArgs,
}) => {
  const { versionId, diagram, deleteDiagram } = extraModalArgs;

  return (
    <ModalWrapper>
      <ModalContent>
        <ModalIconContent>
          <RadioIcon color={colors.alternativeIconColor} />
        </ModalIconContent>
        <ModalTitle>Confirmation</ModalTitle>
        <ModalParagraph>
          {deleteDiagram ? (
            <>
              Version<span className="bold">{versionId}</span>is the only version of the solution flow
              <span className="bold">{diagram.name}.</span>This operation would delete this solution flow. Do you want
              to continue ?
            </>
          ) : (
            <>
              You are going to delete version
              <span className="bold">{versionId}</span>
              of solution flow
              <span className="bold">{diagram.name}</span>. Do you want to continue?
            </>
          )}
        </ModalParagraph>

        <ModalButtons>
          <CancelButton onClick={() => closeModal(modalKey)}>Cancel</CancelButton>
          <ConfirmButton onClick={() => proceedOnModalConfirm(actionOnConfirm, argsOnConfirm, modalKey)}>
            Yes, delete
          </ConfirmButton>
        </ModalButtons>
      </ModalContent>
    </ModalWrapper>
  );
};

export default ConfirmDeleteVersionModal;
