import React from 'react';
import * as colors from '../../styles/colors';
import styled from 'styled-components';

import {
  ModalWrapper,
  ModalContent,
  ModalIconContent,
  ModalTitle,
  ModalParagraph,
  ModalButtons,
  CancelButton,
  ConfirmButton,

} from './modals-elements/ModalsElements';
import RadioIcon from '../shared/icons/tools/RadioIcon';

const CustomModalWrapper = styled(ModalWrapper)`
  position: fixed
`

const ConfirmDiagramTypeChangeModal = ({diagramType, onClose, onConfirm}) => {

  return (
    <CustomModalWrapper>
      <ModalContent className="big">
        <ModalIconContent>
          <RadioIcon color={colors.alternativeIconColor} />
        </ModalIconContent>
        <ModalTitle>Confirmation</ModalTitle>
          <ModalParagraph>
            Are you sure you want to change diagram type to <span className="bold">{diagramType}</span>?
            Changing diagram type will clear current diagram.
          </ModalParagraph>
        <ModalButtons>
          <CancelButton onClick={onClose}>
            Cancel
          </CancelButton>
          <ConfirmButton onClick={onConfirm}>Continue</ConfirmButton>
        </ModalButtons>
      </ModalContent>
    </CustomModalWrapper>
  );
};

export default ConfirmDiagramTypeChangeModal;
