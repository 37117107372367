import React from 'react';
import styled from 'styled-components';
import * as mixins from '../../../../../styles/mixins';
import * as colors from '../../../../../styles/colors';
import * as typography from '../../../../../styles/typography';
import DiagramVersion from '../../../../shared/diagram-version/DiagramVersion';

const DiagramPropertiesVersion = ({
  diagram,
  version,
  isCurrent,
  isTest,
  isLive,
  isCommentVisible,
  changeCurrentDiagramAndVersion,
  changeCurrentDiagramVersionStatus,
  deleteExpandedDiagramVersion,
}) => {
  const onDiagramVersionClick = event => {
    event.stopPropagation();
    changeCurrentDiagramAndVersion({
      diagram: diagram,
      versionId: version.id,
      shouldSave: false,
    });
  };

  const onChangeVersionStatus = status => event => {
    event.stopPropagation();
    changeCurrentDiagramVersionStatus(status);
  };

  const onDeleteVersionClick = () => {
    deleteExpandedDiagramVersion();
  };

  return (
    <DiagramVersion
      version={version}
      isTest={isTest}
      isLive={isLive}
      isCurrent={isCurrent}
      isCommentVisible={isCommentVisible}
      showCreatedBy={false}
      onDiagramVersionClick={onDiagramVersionClick}
      onDeleteVersionClick={onDeleteVersionClick}
      onChangeVersionStatus={onChangeVersionStatus}
    />
  );
};

export default DiagramPropertiesVersion;
