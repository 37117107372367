import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
    body,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p,
    textarea,
    input,
    select,
    li,
    ul,
    a {
      margin: 0;
      padding: 0;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p,
    a,
    input,
    textarea,
    button {
        font-family: 'Poppins', sans-serif;
    }

    body {
      z-index: 0;
      height: 100%;
    }

    form {
      width: 100%;
    }
    
    button {
        cursor: pointer;
    }

    ul,
    li {
      list-style: none;
    }

    a, a:link, a:visited, a:active, a:hover {
      text-decoration: none;
      -webkit-appearance:none;
      -moz-appearance: none;
    }
    button,
    input,
    select {
      border: none;
      outline: none;
      background: none;
      -webkit-appearance: none;
      -moz-appearance: none;
    }

    .logic-task {
      display: none !important;
    }
`;

export default GlobalStyle;
