import React from 'react';
import { connect } from 'react-redux';

import diagramsActions from '../../action-creators/diagramsActions';
import expandedDiagramsActions from '../../action-creators/expandedDiagramActions';
import VersionControlPanel from '../../components/version-control-panel/VersionControlPanel';
import visiblityActions from '../../action-creators/visibilityActions';
import currentDiagramAndVersionActions from '../../action-creators/currentDiagramAndVersionActions';
import diagramVersionsActions from '../../action-creators/diagramVersionsActions';

const VersionControlPanelContainer = props => {
  return <VersionControlPanel {...props} />;
};

export const mapStateToProps = state => {
  return {
    isVisible: state.visibility.versionControlPanelVisible,
    diagramsList: state.app.searchString ? state.app.diagramsSearchList : state.app.diagramsList,
    expandedDiagram: state.app.expandedDiagram,
    expandedDiagramLastVersions: state.app.expandedDiagramLastVersions,
    isExpandedDiagramHasMoreVersions: state.app.isExpandedDiagramHasMoreVersions,
    currentDiagramId: state.app.currentDiagram.id,
    currentDiagramVersionId: state.app.currentDiagramVersion.id,
    isCommentVisible: state.visibility.diagramVersionCommentVisible,
    searchString: state.app.searchString,
    sfLoading: state.app.sfLoading
  };
};

const mapDispatchToProps = {
  changeExpandedDiagram: expandedDiagramsActions.changeExpandedDiagram,
  loadMoreExpandedDiagramVersions: expandedDiagramsActions.loadMoreExpandedDiagramVersions,
  showLessExpandedDiagramVersions: expandedDiagramsActions.showLessExpandedDiagramVersions,
  deleteDiagramVersion: diagramVersionsActions.deleteDiagramVersion,
  changeDiagramVersionStatus: diagramVersionsActions.changeDiagramVersionStatus,
  createNewCurrentDiagramAndVersion: currentDiagramAndVersionActions.createNewCurrentDiagramAndVersion,
  changeCurrentDiagramAndVersion: currentDiagramAndVersionActions.changeCurrentDiagramAndVersion,
  toggleVersionControlVisibility: visiblityActions.toggleVersionControlVisibility,
  toggleDiagramVersionCommentVisibility: visiblityActions.toggleDiagramVersionCommentVisibility,
  ...diagramsActions,
};

export default connect(mapStateToProps, mapDispatchToProps)(VersionControlPanelContainer);
