const setLoadingFlagSF = (sfLoading) => {
  return {
    type: 'SET_LOADING_FLAG_SF',
    sfLoading,
  }
}

const panelActions = {
  setLoadingFlagSF,
};

export default panelActions;