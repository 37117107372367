const updateIndexedDB = (modeler) => {
  return {
    type: 'PUT_TO_DB',
    modeler,
  };
};

const checkIndexedDB = (diagramId, file) => {
  return {
    type: 'CHECK_DB',
    diagramId,
    file
  };
};

const loadFromIndexedDB = (diagramId) => {
  return {
    type: 'LOAD_FROM_DB',
    diagramId
  };
};

const deleteFromIndexedDB = (diagramId) => {
  return {
    type: 'DELETE_FROM_DB',
    diagramId
  };
};

const dbActions = {
  updateIndexedDB,
  checkIndexedDB,
  loadFromIndexedDB,
  deleteFromIndexedDB,
};

export default dbActions;
