import BaseRenderer from 'diagram-js/lib/draw/BaseRenderer';

import {
  append as svgAppend,
  attr as svgAttr,
  classes as svgClasses,
  create as svgCreate,
  remove as svgRemove,
  innerSVG as svgInnerAppend,
} from 'tiny-svg';

import { getRoundRectPath } from 'bpmn-js/lib/draw/BpmnRenderUtil';

import { is } from 'bpmn-js/lib/util/ModelUtil';
import { isAny } from 'bpmn-js/lib/features/modeling/util/ModelingUtil';

import modeling from 'bpmn-js/lib/features/modeling';
import appReducer from '../../../reducers/appReducer';

const HIGH_PRIORITY = 1501;

export default class TableTaskRenderer extends BaseRenderer {
  constructor(eventBus, bpmnRenderer) {
    super(eventBus, HIGH_PRIORITY);

    this.bpmnRenderer = bpmnRenderer;
    // this.modeling = bpmnRenderer.get('modeling');
    this.elementWidth = 160;
    this.currentZoomLevel = 0;
  }

  canRender(element) {
    return is(element, 'bpmn:SendTask') && element.businessObject.subType === 'table-task';
  }

  svg_textMultiline(id, maxWidth, zoom) {
    var x = 10;
    var y = 0;
    var dy = 15;

    /* get the text */
    var element = document.getElementById(id);

    var text = element.innerHTML;

    var words = text.split(' ');
    var line = '';

    var constructedText = '';

    for (var n = 0; n < text.length; n++) {
      element.innerHTML = '<tspan id="PROCESSING">busy</tspan >';

      var newTextLine = line + text[n];

      const newTextElem = document.getElementById('PROCESSING');

      newTextElem.innerHTML = newTextLine;
      var metrics = newTextElem.getBoundingClientRect();
      var newTextWidth = metrics.width;

      if (newTextWidth > maxWidth - 2 * x * (zoom ? zoom : 1)) {
        if ((constructedText.match(/<tspan/g) || []).length > 0) {
          constructedText += '<tspan x="' + x + '" dy="' + dy + '">' + line + '</tspan>';
        } else {
          constructedText += '<tspan x="' + x + '" dy="' + y + '">' + line + '</tspan>';
        }
        line = text[n];
      } else {
        line = newTextLine;
      }

      document.getElementById('PROCESSING').remove();
    }
    if ((constructedText.match(/<tspan/g) || []).length > 0) {
      constructedText += '<tspan x="' + x + '" dy="' + dy + '">' + line + '</tspan>';
    } else {
      constructedText += '<tspan x="' + x + '" dy="' + y + '">' + line + '</tspan>';
    }

    element.innerHTML = constructedText;
  }

  // Main function to draw custom shape

  drawShape(parentNode, element) {
    const parentNodeBounds = parentNode.getBoundingClientRect();
    let element_id = `${element.id}${Math.floor(Math.random() * 10000)}`;

    element.width = 160;

    let shapeProcessing = this.bpmnRenderer.drawShape(parentNode, element);

    this.lastActualWidth = shapeProcessing.getBoundingClientRect().width;

    const newLabelText = svgCreate('text');
    svgAttr(newLabelText, {
      class: 'djs-label-small',
      x: 10,
      y: 20,
    });

    newLabelText.appendChild(document.createTextNode('title'));

    parentNode.appendChild(newLabelText);

    const newText = svgCreate('text');
    svgAttr(newText, {
      id: `TableTextFor${element_id}`,
      class: 'djs-label',
      x: 10,
      y: 35,
    });

    newText.appendChild(document.createTextNode(element.businessObject.title || 'No title'));

    parentNode.appendChild(newText);

    let zoom;
    if (shapeProcessing.getBoundingClientRect().width) {
      zoom = (shapeProcessing.getBoundingClientRect().width / this.elementWidth).toFixed(4);
    } else {
      zoom = 1;
    }

    let InputClientRect;
    try {
      this.svg_textMultiline(`TableTextFor${element_id}`, this.lastActualWidth, zoom);
      InputClientRect = document.getElementById(`TableTextFor${element_id}`).getBoundingClientRect();
      console.log('!!!', InputClientRect.height)
    } catch (e) {
      InputClientRect = {
        height: 15
      }
    }
    let oldInputHeight = element.height;

    const newLabelText3 = svgCreate('text');
    svgAttr(newLabelText3, {
      class: 'djs-label-small',
      x: 10,
      y: 40 + (InputClientRect.height || 15) * (1 / zoom), // + ChoicesClientRect.height
    });

    newLabelText3.appendChild(document.createTextNode('data'));

    parentNode.appendChild(newLabelText3);

    const newText3 = svgCreate('text');
    svgAttr(newText3, {
      id: `DataFor${element_id}`,
      class: 'djs-label',
      x: 10,
      y: 55 + (InputClientRect.height || 15) * (1 / zoom), // + ChoicesClientRect.height
    });

    newText3.appendChild(document.createTextNode(element.businessObject.data || 'No data'));

    parentNode.appendChild(newText3);

    let DataClientRect;
    try {
      this.svg_textMultiline(`DataFor${element_id}`, this.lastActualWidth, zoom);

      DataClientRect = document.getElementById(`DataFor${element_id}`).getBoundingClientRect();
    } catch (err) {
      DataClientRect = {height: 15};
    }
    let newHeight = Math.round(
        InputClientRect.height + +DataClientRect.height + 60 * (zoom ? zoom : 1) > 90 * (zoom ? zoom : 1) //  ChoicesClientRect.height
            ? InputClientRect.height + DataClientRect.height + 60 * (zoom ? zoom : 1) //  ChoicesClientRect.height
            : 90 * (zoom ? zoom : 1)
    );

    newHeight = zoom === 1 ? newHeight : newHeight * (1 / zoom);

    svgAttr(shapeProcessing, {
      height: newHeight,
    });

    element.width = this.elementWidth;
    element.height = newHeight;
    element.y = element.y - (newHeight / 2 - oldInputHeight / 2);

    let shape = this.bpmnRenderer.drawShape(parentNode, element);

    svgAttr(parentNode.childNodes[7], {
      fillOpacity: 0,
      strokeWidth: 3,
      stroke: '#005CB8',
      class: 'djs-rect',
    });

    svgRemove(parentNode.childNodes[0]);

    drawRect(parentNode);

    this.currentZoomLevel = zoom;

    return shape;
  }
}
TableTaskRenderer.$inject = ['eventBus', 'bpmnRenderer'];

// helpers //////////

// copied from https://github.com/bpmn-io/bpmn-js/blob/master/lib/draw/BpmnRenderer.js
function drawRect(parentNode, width, height, borderRadius, strokeColor) {
  const g = svgCreate('g');

  svgAttr(g, {
    transform: 'translate(-11, -11)',
    fill: 'white',
  });

  const rect = '<rect width="24" height="24" fill="white"></rect>';

  const iconContent =
    '<path stroke="#000F3C" fill="#000F3C" d="M 20.3762 5.6345 c -0.0431 -0.5216 -0.4653 -0.9621 -0.9953 -0.9621 H 5.1791 c -0.5299 0 -0.9522 0.4388 -0.9969 0.9621 h -0.0166 v 13.2381 c 0 0.5597 0.4537 1.0135 1.0135 1.0135 h 14.2002 c 0.5597 0 1.0135 -0.4537 1.0135 -1.0135 V 5.6345 H 20.3762 z M 9.2363 18.8725 h -4.0572 V 15.8305 h 4.0572 V 18.8725 z M 9.2363 14.8998 h -4.0572 V 11.7733 h 4.0572 V 14.8998 z M 9.2363 10.7581 h -4.0572 V 7.7161 h 4.0572 V 10.7581 z M 14.3086 18.8725 h -4.0572 V 15.8305 h 4.0572 V 18.8725 z M 14.3086 14.8998 h -4.0572 V 11.7733 h 4.0572 V 14.8998 z M 14.3086 10.7581 h -4.0572 V 7.7161 h 4.0572 V 10.7581 z M 19.3809 18.8725 h -4.0572 V 15.8305 h 4.0572 V 18.8725 z M 19.3809 14.8998 h -4.0572 V 11.7733 h 4.0572 V 14.8998 z M 19.3809 10.7581 h -4.0572 V 7.7161 h 4.0572 V 10.7581 z"/> ';

  svgInnerAppend(g, rect + iconContent);

  svgAppend(parentNode, g);

  return g;
}

// copied from https://github.com/bpmn-io/diagram-js/blob/master/lib/core/GraphicsFactory.js
function prependTo(newNode, parentNode, siblingNode) {
  parentNode.insertBefore(newNode, siblingNode || parentNode.firstChild);
}
