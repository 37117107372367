import BaseRenderer from 'diagram-js/lib/draw/BaseRenderer';

import {
  append as svgAppend,
  attr as svgAttr,
  classes as svgClasses,
  create as svgCreate,
  remove as svgRemove,
  innerSVG as svgInnerAppend,
} from 'tiny-svg';

import { getRoundRectPath } from 'bpmn-js/lib/draw/BpmnRenderUtil';

import { is } from 'bpmn-js/lib/util/ModelUtil';
import { isAny } from 'bpmn-js/lib/features/modeling/util/ModelingUtil';

import modeling from 'bpmn-js/lib/features/modeling';

const HIGH_PRIORITY = 1500;

export default class LogicTaskRenderer extends BaseRenderer {
  constructor(eventBus, bpmnRenderer) {
    super(eventBus, HIGH_PRIORITY);

    this.bpmnRenderer = bpmnRenderer;
    // this.modeling = bpmnRenderer.get('modeling');

    // this.maxHeight = 0;
    this.elementWidth = 160;
    this.currentZoomLevel = 1;
  }

  canRender(element) {
    return is(element, 'bpmn:ScriptTask') && element.businessObject.subType === 'logic-task';
  }

  svg_textMultiline(id, maxWidth, zoom) {
    var x = 10;
    var y = 0;
    var dy = 15;

    /* get the text */
    var element = document.getElementById(id);

    var text = element.innerHTML;

    var words = text.split(' ');
    var line = '';

    var constructedText = '';

    for (var n = 0; n < text.length; n++) {
      //
      element.innerHTML = '<tspan id="PROCESSING">busy</tspan >';

      var newTextLine = line + text[n];

      const newTextElem = document.getElementById('PROCESSING');

      newTextElem.innerHTML = newTextLine;
      var metrics = newTextElem.getBoundingClientRect();
      var newTextWidth = metrics.width;

      //

      if (newTextWidth > maxWidth - 2 * x * (zoom ? zoom : 1)) {
        if ((constructedText.match(/<tspan/g) || []).length > 0) {
          constructedText += '<tspan x="' + x + '" dy="' + dy + '">' + line + '</tspan>';
        } else {
          constructedText += '<tspan x="' + x + '" dy="' + y + '">' + line + '</tspan>';
        }
        line = text[n];
      } else {
        line = newTextLine;
      }

      document.getElementById('PROCESSING').remove();
    }
    if ((constructedText.match(/<tspan/g) || []).length > 0) {
      constructedText += '<tspan x="' + x + '" dy="' + dy + '">' + line + '</tspan>';
    } else {
      constructedText += '<tspan x="' + x + '" dy="' + y + '">' + line + '</tspan>';
    }

    element.innerHTML = constructedText;

    //
  }

  // Main function to draw custom shape

  drawShape(parentNode, element) {
    element.width = this.elementWidth;
    let element_id = `${element.id}${Math.floor(Math.random() * 10000)}`;

    let shapeProcessing = this.bpmnRenderer.drawShape(parentNode, element);

    this.lastActualWidth = shapeProcessing.getBoundingClientRect().width;
    //this.currentZoomLevel = 170/shapeProcessing.getBoundingClientRect().width;

    //

    const newText = svgCreate('text');
    svgAttr(newText, {
      id: `ResultTextFor${element_id}`,
      class: 'djs-label',
      x: 10,
      y: 35,
    });

    const nodeText =
      element.businessObject.description ||
      (element.businessObject.identifierForResult || 'no var defined') +
        ' = ' +
        (element.businessObject.template.substring(0, 80) || 'no expression given');
    newText.appendChild(document.createTextNode(nodeText || 'No description given'));

    parentNode.appendChild(newText);

    const newLabelText = svgCreate('text');
    svgAttr(newLabelText, {
      id: `newLabelText`,
      class: 'djs-label-small',
      x: 10,
      y: 20,
    });

    newLabelText.appendChild(document.createTextNode('description'));

    parentNode.appendChild(newLabelText);

    let zoom;
    if (shapeProcessing.getBoundingClientRect().width) {
      zoom = (shapeProcessing.getBoundingClientRect().width / this.elementWidth).toFixed(4);
    } else {
      zoom = 1;
    }

    let newClientRect;
    try {
      this.svg_textMultiline(`ResultTextFor${element_id}`, this.lastActualWidth, zoom);

      newClientRect = document.getElementById(`ResultTextFor${element_id}`).getBoundingClientRect();
    } catch (err) {
      newClientRect = {height: 15};
    }

    let newHeight = Math.round(
        newClientRect.height + 40 * (zoom ? zoom : 1) > 80 * (zoom ? zoom : 1)
            ? newClientRect.height + 40 * (zoom ? zoom : 1)
            : 80 * (zoom ? zoom : 1)
    );
    let oldHeight = element.height;

    newHeight = zoom === 1 ? newHeight : newHeight * (1 / zoom);

    svgAttr(shapeProcessing, {
      height: newHeight,
    });

    element.width = this.elementWidth;
    element.height = newHeight;

    element.y = element.y - (newHeight / 2 - oldHeight / 2);

    let shape = this.bpmnRenderer.drawShape(parentNode, element);

    svgAttr(parentNode.childNodes[5], {
      fillOpacity: 0,
      strokeWidth: 3,
      stroke: '#FF8080',
      class: 'djs-rect',
    });

    svgRemove(parentNode.childNodes[0]);
    drawRect(parentNode);

    this.currentZoomLevel = zoom;

    return shape;
  }
}

LogicTaskRenderer.$inject = ['eventBus', 'bpmnRenderer'];

// helpers //////////

// copied from https://github.com/bpmn-io/bpmn-js/blob/master/lib/draw/BpmnRenderer.js
function drawRect(parentNode, width, height, borderRadius, strokeColor) {
  const g = svgCreate('g');

  svgAttr(g, {
    transform: 'translate(-11, -11)',
    fill: 'white',
  });

  /*const rect = svgCreate('rect');

    svgAttr(rect, {
      width: width,
      height: height,
      fill: "#fff"
    });*/

  //svgAppend(g, rect);

  const rect = '<rect width="25" height="22" fill="white"></rect>';

  const iconContent =
    '<path d="M16 18L22 12L16 6" stroke="#000F3C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>' +
    '<path d="M8 6L2 12L8 18" stroke="#000F3C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>';

  svgInnerAppend(g, rect + iconContent);

  svgAppend(parentNode, g);

  return g;
}

// copied from https://github.com/bpmn-io/diagram-js/blob/master/lib/core/GraphicsFactory.js
function prependTo(newNode, parentNode, siblingNode) {
  parentNode.insertBefore(newNode, siblingNode || parentNode.firstChild);
}
