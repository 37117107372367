import React from 'react';
import * as colors from '../../styles/colors';

import SaveIcon from '../shared/icons/tools/SaveIcon';
import saveAllService from '../../services/saveAllService';
import {SaveButtonWrapper, SaveTooltip, SaveTooltipContent} from "../shared/Buttons";

const DiagramSaveButton = ({
  modeler,
  reviewViewer,
  currentDiagram,
  currentDiagramVersion,
  isCurrentDiagramSaved,
  isCurrentDiagramVersionSaved,
  isCurrentDiagramVersionFileSaved,
  saveAllWithVerification,
  saveOnlyDiagramVersionFile,
  saveAll,
  updateSaveProgress,
  reviewMode,
}) => {
  // TODO: probably transfer this as extra condition in state?
  const shouldSave = !isCurrentDiagramSaved || !isCurrentDiagramVersionSaved || !isCurrentDiagramVersionFileSaved;

  const handleSaveClick = () => {
    if (reviewMode) {
      if (currentDiagramVersion.needsReview) {
        saveAllService.handleSaveFileFromViewer(reviewViewer, saveOnlyDiagramVersionFile);
      }
    } else {
      saveAllWithVerification(modeler, saveAll, updateSaveProgress);
    }
  };

  return (
    <SaveButtonWrapper id="save-button-wrapper" onClick={handleSaveClick}>
      <SaveIcon
        color={
          shouldSave || (reviewMode && currentDiagramVersion.needsReview)
            ? colors.mainIconColor
            : colors.extraBackgroundColor
        }
      />
      {shouldSave ? (
        <SaveTooltip>
          <SaveTooltipContent>
            {currentDiagram.id === -1 ? 'Save new solution flow' : 'Save new version'}
          </SaveTooltipContent>
        </SaveTooltip>
      ) : (
        ''
      )}
    </SaveButtonWrapper>
  );
};

export default DiagramSaveButton;
