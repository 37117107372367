import React from "react";
import styled from "styled-components";
import * as colors from "../../../../styles/colors";
import * as mixins from "../../../../styles/mixins";
import * as typography from "../../../../styles/typography";

const ElementDescriptionTextarea = styled.textarea`
  width: 100%;
  min-height: ${props => (props.small ? '28px' : '108px')};
  box-sizing: border-box;
  padding: 4px 6px;
  border: 0;
  border-radius: 2px;
  background: ${colors.extraBackgroundColor};
  color: ${colors.mainFontColor};
  ${mixins.fontStyle(typography.textFontSize, typography.semibold)};
  resize: none;

  &::-webkit-scrollbar {
    width: 6px;
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    width: 6px;
    background-color: ${colors.darkBlue};
    opacity: 0.5;
  }
`;

export const Description = ({
  isEditorExpanded, currentValue, changeCurrentValue, modeling, currentElement, elementPropKey
}) => {
  return (
    <ElementDescriptionTextarea
      className={isEditorExpanded ? 'large element-property-input' : 'element-property-input'}
      value={currentValue}
      onChange={e => {
        changeCurrentValue(e.target.value);
      }}
      onBlur={e => {
        modeling.updateProperties(currentElement, {
          [elementPropKey]: currentValue,
        });
      }}
    />
  );
}