/////////////////////////////////////////////////
// Response service is needed to extract       //
// data from Promise.                          //
// NOTE: it uses then over json() to resolve   //
// promise, and extract data with status       //
/////////////////////////////////////////////////
const responseService = {
  handle: method => {
    return method
      .then(response => {
        if (response.status === 204) {
          return {
            status: response.status,
            data: '',
          };
        } else {
          return response.json().then(resp => {
            return {
              status: response.status,
              data: resp,
            };
          });
        }
      })
      .catch(err => {
        return {
          status: 500,
          data: 'Something went wrong while fetching! Please restart application and try again. If error repeats contact support.',
        };
      });
  },
};

export default responseService;
