import React from 'react';
import styled from 'styled-components';
import * as mixins from '../../styles/mixins';
import * as colors from '../../styles/colors';
import * as typography from '../../styles/typography';

import FlexibleWrapper from '../shared/FlexibleWrapper';
import LockIcon from '../shared/icons/tools/LockIcon';
import DiagramPropertiesHeader from './diagram-properties-panel-elements/DiagramPropertiesHeader';
import DiagramPropertiesExpandedArea from './diagram-properties-panel-elements/diagram-properties-panel-expanded-area/DiagramPropertiesExpandedArea';
import DiagramPropertiesLastVersions from './diagram-properties-panel-elements/diagram-properties-panel-expanded-area/diagram-properties-panel-last-versions/DiagramPropertiesLastVersions';
import DiagramPropertiesOwner from './diagram-properties-panel-elements/diagram-properties-panel-expanded-area/diagram-properties-panel-owner/DiagramPropertiesOwner';
import CommentIcon from '../shared/icons/tools/CommentIcon';

const PropertiesExpandedArea = styled.div`
  visibility: hidden;
  position: absolute;
  top: 100%;
  width: 100%;
  min-height: 80px;
  box-sizing: border-box;
  padding-bottom: 32px;
  background-color: ${colors.componentBackgroundColor};

  ${props => (props.isLocked ? `visibility: visible` : '')}
`;

const DiagramPropertiesWrapper = styled(FlexibleWrapper)`
  position: relative;
  margin-right: 8px;
  cursor: pointer;

  &:hover > ${PropertiesExpandedArea} {
    visibility: visible;
  }

  ${props => (props.nonEditable ? 'pointer-events: none;' : '')};
`;

const CommentVisibilityToggler = styled.div`
  position: absolute;
  bottom: 8px;
  right: 45px;
  opacity: 0.5;

  &:hover {
    opacity: 1;
  }

  ${props =>
    props.isVisible
      ? `
            opacity: 1;
            
            &:hover path {
                stroke: ${colors.alternativeIconColor};
            }
        `
      : ''}
`;

const PropertiesExpandedToggler = styled.div`
  position: absolute;
  bottom: 8px;
  right: 8px;
  opacity: 0.5;

  &:hover {
    opacity: 1;
  }

  ${props =>
    props.isLocked
      ? `
            opacity: 1;
            
            &:hover path {
                stroke: ${colors.alternativeIconColor};
            }
        `
      : ''}
`;

const DiagramPropertiesPanel = ({
  user,
  users,
  currentDiagram,
  currentDiagramVersion,
  ownersSearchString,
  currentDiagramLastVersions,
  isCurrentDiagramHasMoreVersions,
  changeCurrentDiagramAndVersion,
  changeDiagramVersionStatus,
  deleteDiagramVersion,
  nonEditable,
  fetchUsersList,
  updateOwnersSearchString,
  updateOwnersList,
  loadMoreCurrentDiagramVersions,
  showLessCurrentDiagramVersions,
  updateCurrentDiagramName,
  updateCurrentDiagramOwner,
  updateCurrentDiagramVersionLabel,
  isNameEditable,
  changeNameEditable,
  isLabelEditable,
  changeLabelEditable,
  isCollapsed,
  setCollapsed,
  nameSize,
  labelSize,
  isOwnerSearchOpen,
  changeOwnerSearchOpen,
  isCommentVisible,
  toggleDiagramVersionCommentVisibility,
}) => {
  return (
    <DiagramPropertiesWrapper nonEditable={nonEditable}>
      <DiagramPropertiesHeader
        currentDiagram={currentDiagram}
        currentDiagramVersion={currentDiagramVersion}
        updateCurrentDiagramName={updateCurrentDiagramName}
        updateCurrentDiagramVersionLabel={updateCurrentDiagramVersionLabel}
        isNameEditable={isNameEditable}
        changeNameEditable={changeNameEditable}
        isLabelEditable={isLabelEditable}
        changeLabelEditable={changeLabelEditable}
        nameSize={nameSize}
        labelSize={labelSize}
      />
      <PropertiesExpandedArea isLocked={isCollapsed}>
        <DiagramPropertiesExpandedArea
          user={user}
          users={users}
          currentDiagram={currentDiagram}
          ownersSearchString={ownersSearchString}
          currentDiagramVersionId={currentDiagramVersion.id}
          currentDiagramLastVersions={currentDiagramLastVersions}
          deleteDiagramVersion={deleteDiagramVersion}
          isCurrentDiagramHasMoreVersions={isCurrentDiagramHasMoreVersions}
          currentDiagramLiveVersion={currentDiagram.versionInLive}
          loadMoreCurrentDiagramVersions={loadMoreCurrentDiagramVersions}
          showLessCurrentDiagramVersions={showLessCurrentDiagramVersions}
          changeCurrentDiagramAndVersion={changeCurrentDiagramAndVersion}
          changeDiagramVersionStatus={changeDiagramVersionStatus}
          fetchUsersList={fetchUsersList}
          updateOwnersSearchString={updateOwnersSearchString}
          updateCurrentDiagramOwner={updateCurrentDiagramOwner}
          updateOwnersList={updateOwnersList}
          isCommentVisible={isCommentVisible}
          isOwnerSearchOpen={isOwnerSearchOpen}
          changeOwnerSearchOpen={changeOwnerSearchOpen}
        />

        <CommentVisibilityToggler isVisible={isCommentVisible} onClick={() => toggleDiagramVersionCommentVisibility()}>
          <CommentIcon color={colors.mainIconColor} />
        </CommentVisibilityToggler>
        <PropertiesExpandedToggler isLocked={isCollapsed} onClick={() => setCollapsed(!isCollapsed)}>
          <LockIcon color={colors.mainIconColor} />
        </PropertiesExpandedToggler>
      </PropertiesExpandedArea>
    </DiagramPropertiesWrapper>
  );
};

export default DiagramPropertiesPanel;
