const authReducer = (state = { user: null, ready: false, loginErrors: [] }, action) => {
  switch (action.type) {
    case 'SET_ACTIVE_USER':
      return Object.assign({}, state, {
        user: action.user,
        ready: true,
      });
    case 'SET_LOGIN_ERRORS':
      return Object.assign({}, state, {
        loginErrors: action.loginErrors,
      });
    default:
      return state;
  }
};

export default authReducer;
