import React from "react";
import MapInputRow from "./MapInputRow";

/**
 * MapInput component is a table that allows the user to input a list of key-value pairs. The handleChange function
 * is called whenever a key or value is changed. The handleChange function is passed the index of the row that was
 * changed, the new value, and the name of the field that was changed ("key" or "value").
 * @param data - an array of objects with key and value fields
 * @param handleChange - a function that is called whenever a key or value is changed
 * @returns {JSX.Element} - a table with a row for each key-value pair
 */
const MapInput = ({data, handleChange}) => {

  return (
    <table>
      <thead>
      <tr>
        <th>Key</th>
        <th>Value</th>
      </tr>
      </thead>
      <tbody>
      {data.map(({key, value}, i) => <MapInputRow key={i} mapKey={key} value={value}
                                                  onKeyChange={(e) => handleChange(i, e.target.value, "key")}
                                                  onValueChange={(e) => handleChange(i, e.target.value, "value")}/>)}
      </tbody>
    </table>
  )

}

export default MapInput;
