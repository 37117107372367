import React, { useEffect, useState } from 'react';

import '../../overview/modals/common.scss';
import Input from '../../common/inputs/Input';
import Button from '../../common/button/Button';
import Select from '../../common/inputs/Select';
import { sendGET } from '../../../services/common/RestService';
import TagsSelect from '../../common/inputs/TagsSelect';

const API_BASE_URL = process.env.API_URL || 'http://127.0.0.1:8000';
const API_PREFIX = process.env.API_PREFIX || 'api/v1';

const FormModal = ({
  user,
  title,
  closeModal,
  createUser,
  updateUser,
  currentUser,
  formState = undefined,
  modalError,
  options,
}) => {
  const newUser = !user;
  useEffect(() => {
    sendGET(API_BASE_URL, API_PREFIX, ['groups/'], {}, true).then(r => r.json().then(r => setGroups(r)));
  }, []);

  const [groups, setGroups] = useState([]);
  const [data, setData] = useState(user || {});

  const updateForm = (key, value) => {
    setData(prev => ({ ...prev, [key]: value }));
  };

  function onSubmit() {
    if (newUser) {
      createUser(data);
    } else {
      updateUser(user.id, data, currentUser);
    }
  }

  return (
    <div className="modal-container">
      <div className="modal-content">
        <div className="modal-close" onClick={closeModal}></div>
        <div>
          <p className="modal-title">{newUser ? 'New user' : 'Edit'}</p>
        </div>
        <div className="modal-two-columns">
          <div className="column">
            <Input
              dir="auto"
              label="First name"
              placeholder="Type here"
              value={data.first_name || ''}
              onChange={e => updateForm('first_name', e.target.value)}
            />
            <Input
              dir="auto"
              label="Last name"
              placeholder="Type here"
              value={data.last_name || ''}
              onChange={e => updateForm('last_name', e.target.value)}
            />
            <Input
              dir="auto"
              label="E-mail"
              placeholder="Type here"
              value={data.email || ''}
              onChange={e => updateForm('email', e.target.value)}
            />

            {newUser && (
              <Input
                dir="auto"
                label="Password"
                placeholder="Type here"
                value={data.password || ''}
                onChange={e => updateForm('password', e.target.value)}
              />
            )}
            <div dir="auto">
              <Select
                label="Role"
                value={data.role || ''}
                onChange={e => updateForm('role', e.target.value)}
                options={options}
              />
            </div>
            <TagsSelect
              label="Groups"
              value={(data.groups || []).map(g => g.name)}
              onChange={e => {
                console.log(e);
                updateForm(
                  'groups',
                  e.target.value.map(name => ({ name }))
                );
              }}
              options={groups.map(g => g.name)}
            />
          </div>
        </div>
        <div className="modal-error">{modalError}</div>
        <div className="modal-button-container">
          <Button label={newUser ? 'Create' : 'Save'} action={onSubmit} />
          <Button label="Cancel" action={closeModal} />
        </div>
      </div>
    </div>
  );
};

export default FormModal;
