import React from "react";
import ToggleButton from "react-toggle-button";

const SegmentsToggle = ({ segments, segmentFilter, toggleSegmentFilter, loggedInUser, forMe, toggleForMe, activeChats, toggleForActiveChats }) => {
  return <div className="filter-section">
    <div style={{display: "flex", flexDirection: "column"}}>
      <div className="filter-controls">
        {segments.map((seg, index) => (
          <div className="filter-segment" key={index}>
            <ToggleButton
              inactiveLabel={''}
              activeLabel={''}
              colors={{ active: { base: '#263c73', inactive: { base: '#E5E5E5' } } }}
              value={segmentFilter[seg]}
              onToggle={() => toggleSegmentFilter(seg)}
            />
            <span className="filter-name">{seg}</span>
          </div>
        ))}
        <div className="filter-segment">
          <ToggleButton
          inactiveLabel={''}
          activeLabel={''}
          colors={{ active: { base: '#263c73', inactive: { base: '#E5E5E5' } } }}
          value={activeChats}
          onToggle={toggleForActiveChats}
          />
          <span className="filter-name">Active chats</span>
        </div>
        {loggedInUser.groups.length > 0 && (
          <div className="filter-segment">
            <ToggleButton
              inactiveLabel={''}
              activeLabel={''}
              colors={{ active: { base: '#263c73', inactive: { base: '#E5E5E5' } } }}
              value={forMe}
              onToggle={toggleForMe}
            />
            <span className="filter-name">For me</span>
          </div>
        )}
      </div>
    </div>
  </div>
}


const compareProps = (prevProps, nextProps) => {
  const segmentsAreEqual = prevProps.segments
    .map((e, i) => [e, nextProps.segments[i]])
    .reduce((acc, val) => acc && val[0] === val[1], true);
  const forMeEqueal = prevProps.forMe === nextProps.forMe;
  const activeChatsEqual = prevProps.activeChats === nextProps.activeChats;

  const prevSegmentFilter = prevProps.segments.map(e => prevProps.segmentFilter[e]);
  const nextSegmentFilter = nextProps.segments.map(e => nextProps.segmentFilter[e]);
  const togglesAreEqual = prevSegmentFilter
    .map((e, i) => [e, nextSegmentFilter[i]])
    .reduce((acc, val) => acc && val[0] === val[1], true);
  return segmentsAreEqual && togglesAreEqual && forMeEqueal && activeChatsEqual;
}

export default React.memo(SegmentsToggle, compareProps);
