import React, { useEffect, useState } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import uuid from 'react-uuid';
import './Overview.scss';
import LabelsTable from './labels-table/LabelsTable';
import labelsActions from '../../action-creators/labelsActions';
import taskActions from '../../action-creators/taskActions';
import modalsActions from '../../action-creators/modalsActions';
import usersActions from '../../action-creators/usersActions';
import FormModal from './modals/FormModal';
import ConfirmationModal from './modals/ConfirmationModal';

import iconGroup from './img/group.png';
import iconPackage from './img/package.png';
import iconTools from './img/tools.png';

import iconLabel from './img/label.png';
import iconAdd from './img/add_new.png';
import iconEdit from './img/edit.png';
import iconMerge from './img/merge.png';
import iconRemove from './img/trash.png';
import iconPositive from './labels-table/img/positive.png';
import iconNegative from './labels-table/img/negative.png';
import { Switch, Button, Dropdown, Menu } from 'antd';
import { DownOutlined, SettingOutlined } from '@ant-design/icons';
import withChatDraft from '../utils/chat-draft/withChatDraft';
import { withRoles } from '../utils/roles/withRoles';
import MetricsService from '../../services/MetricsService';
import moment from 'moment';
import { Icon } from '@iconify/react';

const Overview = ({
  labels,
  tasks,
  fetchTasksList,
  fetchLabelsList,
  fetchUsersList,
  currentLabelId,
  createLabel,
  updateLabel,
  deleteLabel,
  currentUser,
  addLabelModalOpen,
  editLabelModalOpen,
  deleteLabelModalOpen,
  toggleModal,
  users,
}) => {
  const [showAuxiliaryLabels, setShowAuxiliaryLabels] = useState(false);
  const [extraInfo, setExtraInfo] = useState([]);
  const reviewTasks = extraInfo
    .slice(1, extraInfo.length)
    .filter(metric => !metric.is_auxiliary)
    .map(a => ({ ...a, missedPotential: a.volume / 100 - (parseFloat(a.fcr) / 100) * (a.volume / 100) }))
    .sort((a, b) => (a.missedPotential > b.missedPotential ? -1 : 1))
    .slice(0, 20);

  useEffect(() => {
    fetchLabelsList();
    fetchUsersList();
    // fetchTasksList({ status: [1], due_date_end: new Date() });
    fetchMetrics();
  }, []);

  const fetchMetrics = async () => {
    const response = await MetricsService.getAdminMetrics({
      metrics_name: 'latest_metrics',
      range_start: moment().subtract('4', 'weeks').format('YYYY-MM-DDT') + '00%3A00',
      time_granularity: 'total',
    });
    setExtraInfo(
      [
        {
          accuracy: '-',
          f1: '-',
          f1_keywords: '-',
          volume: null,
          sfm: '-',
          sfmd: '-',
          fcr: '-',
        },
      ].concat(response.data)
    );
  };

  const getLabelFromReviewText = str => {
    try {
      return str.match(/"(.*)"/).pop();
    } catch (e) {
      console.log(e);
      return '';
    }
  };
  const editLabel = id => {
    return (name, owner, interval, description, parent_id, is_auxiliary) =>
      updateLabel(id, name, owner, interval, description, parent_id, is_auxiliary);
  };

  const taskCount = reviewTasks.length + 1;
  return (
    <div id="overview">
      <div id="overview-menu">
        <div id="overview-menu-buttons">
          <div className="buttons">
            {process.env.ENABLE_OVERVIEW_TASKS == 'true' ? (
              <Dropdown
                overlay={
                  <Menu>
                    {[
                      <Menu.Item key={uuid()}>
                        <Link
                          className="link-button__menu-item"
                          to={{
                            pathname: 'labelling',
                          }}
                        >
                          <img src={iconLabel} alt="" /> Label more Uncertain Labels
                        </Link>
                      </Menu.Item>,
                    ].concat(
                      reviewTasks.map(metric => (
                        <Menu.Item key={uuid()}>
                          <Link
                            className="link-button__menu-item"
                            to={{
                              pathname: 'qa',
                              selectedLabel: metric.label_id?.toString(),
                            }}
                          >
                            <img src={iconLabel} alt="" /> Review quality of {metric.label_name}
                          </Link>
                        </Menu.Item>
                      ))
                    )}
                  </Menu>
                }
              >
                <Button type="primary" style={{ display: 'flex', alignItems: 'center' }} size={'large'}>
                  <Icon icon="bx:package" color="white" fontSize="20" style={{ marginRight: 8 }} /> Tasks ({taskCount})
                  <DownOutlined style={{ fontSize: 15 }} size={'large'} />
                </Button>
              </Dropdown>
            ) : null}
            <Dropdown.Button
              onClick={() => toggleModal('addLabelModal')}
              overlay={
                <Menu>
                  {
                    <>
                      <Menu.Item icon={<img src={iconEdit} alt="" />} onClick={() => toggleModal('editLabelModal')}>
                        <p
                          className={`link-button__menu-item ${
                            currentLabelId && currentLabelId != 'uncertain_labels' ? '' : 'disabled'
                          }`}
                        >
                          Edit label
                        </p>
                      </Menu.Item>
                      <Menu.Item icon={<img src={iconMerge} alt="" />} onClick={() => toggleModal('editLabelModal')}>
                        <p
                          className={`link-button__menu-item ${
                            currentLabelId && currentLabelId != 'uncertain_labels' ? '' : 'disabled'
                          }`}
                        >
                          Merge label
                        </p>
                      </Menu.Item>
                      <Menu.Item icon={<img src={iconRemove} alt="" />} onClick={() => toggleModal('editLabelModal')}>
                        <p
                          className={`link-button__menu-item ${
                            currentLabelId && currentLabelId != 'uncertain_labels' ? '' : 'disabled'
                          }`}
                          onClick={() => toggleModal('deleteLabelModal')}
                        >
                          Delete label
                        </p>
                      </Menu.Item>
                    </>
                  }
                </Menu>
              }
              style={{ marginLeft: 16, marginRight: 16 }}
              size={'large'}
              icon={<SettingOutlined />}
            >
              Add new label
            </Dropdown.Button>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <p style={{ lineHeight: '16px' }} className="input-label">
                Show auxiliary labels
              </p>
              <Switch style={{ width: 22 }} checked={showAuxiliaryLabels} onChange={setShowAuxiliaryLabels} />
            </div>
          </div>
        </div>
      </div>
      <LabelsTable extraInfo={extraInfo} tasks={tasks} showAuxiliaryLabels={showAuxiliaryLabels} users={users} />

      {addLabelModalOpen ? (
        <FormModal
          labels={labels.filter(label => !label.parent_id)}
          users={users}
          title="Add label"
          labelAction={createLabel}
          closeModal={() => toggleModal('addLabelModal')}
          formState={{
            name: '',
            owner_id: currentUser?.id,
            description: '',
            interval: 'quarterly',
            is_auxiliary: false,
          }}
        />
      ) : (
        ''
      )}
      {editLabelModalOpen ? (
        <FormModal
          labels={labels.filter(label => label.id != currentLabelId && !label.parent_id)}
          users={users}
          isParent={labels.some(label => label.parent_id == currentLabelId)}
          title="Edit label"
          labelAction={editLabel(currentLabelId)}
          closeModal={() => toggleModal('editLabelModal')}
          formState={labels ? labels.filter(label => label.id === currentLabelId)[0] : undefined}
        />
      ) : (
        ''
      )}
      {deleteLabelModalOpen ? (
        <ConfirmationModal
          title="Delete label"
          message={
            <span>
              Are you sure you want to delete the label?
              <br />
              This action is irreversible
            </span>
          }
          labelAction={() => deleteLabel(currentLabelId)}
          closeModal={() => toggleModal('deleteLabelModal')}
        />
      ) : (
        ''
      )}
    </div>
  );
};

export const mapStateToProps = state => {
  return {
    users: state.users.users,
    labels: state.labels.labels,
    addLabelModalOpen: state.modals.addLabelModal.isOpen,
    editLabelModalOpen: state.modals.editLabelModal.isOpen,
    deleteLabelModalOpen: state.modals.deleteLabelModal.isOpen,
    currentLabelId: state.labels.currentLabelId,
    currentUser: state.auth.user,
    tasks: state.tasks.tasks,
  };
};

const mapDispatchToProps = {
  fetchUsersList: usersActions.fetchUsersList,
  fetchLabelsList: labelsActions.fetchLabelsList,
  toggleModal: modalsActions.toggleModal,
  createLabel: labelsActions.createLabel,
  updateLabel: labelsActions.updateLabel,
  deleteLabel: labelsActions.deleteLabel,
  fetchTasksList: taskActions.fetchTasksList,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRoles(['labeler', 'product_manager', 'admin'], withChatDraft(withRouter(Overview))));
