import {taskData} from "../../constants/tasks/taskData";

function matchAndSplit(text, pattern) {
  const tokens = [],
    originalText = text;

  if (!text) {
    return tokens;
  }

  text = text.toLowerCase();
  pattern = pattern.toLowerCase();

  const i = text.indexOf(pattern);

  if (i > -1) {
    if (i !== 0) {
      tokens.push({
        normal: originalText.substr(0, i)
      });
    }

    tokens.push({
      matched: originalText.substr(i, pattern.length)
    });

    if (pattern.length + i < text.length) {
      tokens.push({
        normal: originalText.substr(pattern.length + i, text.length)
      });
    }
  } else {
    tokens.push({
      normal: originalText
    });
  }

  return tokens;
}

const taskFilterFunctions = Object.keys(taskData).reduce((acc, curr) => {
  const task = taskData[curr];
  acc[task.type] = {
    ...acc[task.type],
    [task.subType]: (businessObject, pattern) => task.props.flatMap(({ key }) => {
      const tokens = matchAndSplit(businessObject[key], pattern)
      if (tokens.filter((t) => t.matched).length) {
        return tokens;
      }
      return [];
    })
  }
  return acc;
}, {});

export default function SearchProvider(elementRegistry, searchPad, canvas, bpmnSearchProvider) {
  this._elementRegistry = elementRegistry;
  this._canvas = canvas;
  this._bpmnSearchProvider = bpmnSearchProvider;

  searchPad.registerProvider(this);
}

SearchProvider.$inject = ['elementRegistry', 'searchPad', 'canvas', 'bpmnSearch']

SearchProvider.prototype.find = function (pattern) {
  const bpmnElements = this._bpmnSearchProvider.find(pattern);
  const allElements = this._elementRegistry.filter(() => true);
  const elements = allElements.flatMap((element) => {
    const tokens = taskFilterFunctions[element.type]
      ?.[element.businessObject?.subType]
      ?.(element.businessObject, pattern)

    if (tokens && tokens.length) {
      return {
        primaryTokens: tokens,
        secondaryTokens: [],
        element,
      }
    }
    return [];
  })
  return bpmnElements.concat(elements);
}



