import { css } from 'styled-components';
import * as typography from './typography';
import * as colors from './colors';
import viewportBreakpoints from '../constants/viewportBreakpoints';

const mainWrapper = (maxWidth = '1000px', padding = '20px') => {
  return `
        width: 100%;
        max-width: ${maxWidth};
        margin: 0 auto;
        padding: 0 ${padding};
        box-sizing: border-box;
    `;
};

const flex = (justify = 'center', align = 'center', direction = 'row', wrap = 'nowrap') => {
  return `
        display: flex;
        flex-direction: ${direction};
        justify-content: ${justify};
        align-items: ${align};
        flex-wrap: ${wrap};
    `;
};

const transition = rules => {
  const composedRules = rules.join(', ');
  return `
        -webkit-transition: ${composedRules};
        -moz-transition: ${composedRules};
        transition: ${composedRules};
    `;
};

const placeholder = (color = colors.grey) => {
  return `
        ::placeholder { /* Firefox, Chrome, Opera */
            color: ${color};
        }
        :-ms-input-placeholder { /* Internet Explorer 10-11 */
            color: ${color};
        }
        ::-ms-input-placeholder { /* Microsoft Edge */
            color: ${color};
        }
    `;
};

const fontStyle = (
  size = typography.defaultFontSize,
  weight = typography.defaultWeight,
  height = typography.defaultLineHeight,
  letterSpacing = typography.defaultLetterSpacing
) => {
  return `
        font-size: ${size};
        line-height: ${height};
        font-weight: ${weight};
        letter-spacing: ${letterSpacing};
    `;
};

const applyBlur = (blur = '10px') => {
  return `
        filter: blur(${blur});
        -webkit-filter: blur(${blur});
    `;
};

const border = (size = '1px', style = 'solid', color = colors.grey) => {
  return `
        border: ${size} ${style} ${color};
    `;
};

const respondTo = Object.keys(viewportBreakpoints).reduce((accumulator, label) => {
  accumulator[label] = (...args) => css`
    @media (min-width: ${viewportBreakpoints[label]}) {
      ${css(...args)};
    } ;
  `;
  return accumulator;
}, {});

export { mainWrapper, flex, transition, placeholder, fontStyle, border, applyBlur, respondTo };
