import { combineReducers } from 'redux';
import appReducer from './appReducer';
import visibilityReducer from './visibilityReducer';
import modalsReducer from './modalsReducer';
import navReducer from './navReducer';
import labelsReducer from './labelsReducer';
import authReducer from './authReducer';
import settingsReducer from './settingsReducer';
import labellingReducer from './labellingReducer';
import qualityAssessmentsReducer from './qualityAssessmentsReducer';
import usersReducer from './usersReducer';
import tasksReducer from './tasksReducer';

const rootReducer = combineReducers({
  app: appReducer,
  auth: authReducer,
  settings: settingsReducer,
  nav: navReducer,
  visibility: visibilityReducer,
  modals: modalsReducer,
  labels: labelsReducer,
  users: usersReducer,
  labelling: labellingReducer,
  qualityAssessments: qualityAssessmentsReducer,
  tasks: tasksReducer,
});

export default rootReducer;
