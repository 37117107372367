import React from 'react';
import styled from 'styled-components';
import * as mixins from '../../../styles/mixins';
import * as colors from '../../../styles/colors';
import * as typography from '../../../styles/typography';

export const ModalWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 2000;
  background: transparent;
  ${mixins.flex()};

  & ~ #app-tools-wrapper {
    pointer-events: none;
  }
`;

export const ModalRowWrapper = styled.div`
  width: 100%;
  box-sizing: border-box;
  padding: 0 12px;
  ${mixins.flex('flex-start')};
`;

export const ModalContent = styled.div`
  width: 400px;
  min-height: 248px;
  box-sizing: border-box;
  padding: 8px 12px 32px;
  background-color: ${colors.backgroundColor};
  box-shadow: 0px 16px 40px rgba(0, 51, 167, 0.15);
  ${mixins.flex('flex-start', 'center', 'column')};

  &.big {
    width: 500px;
  }
`;

export const ModalIconContent = styled.div`
  width: 100%;
  ${mixins.flex('flex-end')};
`;

export const ModalTitle = styled.p`
  width: 100%;
  text-align: center;
  color: ${colors.extraFontColor};
  ${mixins.fontStyle(typography.titleFontSize, typography.semibold)};
`;

export const ModalParagraph = styled.p`
  max-width: 320px;
  margin: 24px 0;
  text-align: center;
  color: ${colors.extraFontColor};
  ${mixins.fontStyle(typography.textFontSize, typography.normal)};

  & span {
    margin: 0 5px;
  }

  & > span.bold {
    ${mixins.fontStyle(typography.textFontSize, typography.semibold)};
  }

  &.small-margin {
    margin: 8px 0;
  }

  &.right-padding {
    padding-right: 10px;
  }
`;

export const ModalInput = styled.input`
  margin-bottom: 10px;
  width: 344px;
  height: 48px;
  box-sizing: border-box;
  padding: 12px;
  border: 1px solid ${colors.alternativeFontColor};
  border-radius: 2px;
  color: ${colors.mainFontColor};
  ${mixins.fontStyle(typography.textFontSize, typography.medium)};

  &.blue-border {
    border: 1px solid ${colors.mainFontColor};
  }

  &.full-width {
    width: 460px;
  }
`;

export const ModalCheckbox = styled.input`
  -webkit-appearance: auto;
  display: inline-block;

  background: ${colors.extraBackgroundColor};
  color: ${colors.mainFontColor};
`;

export const ModalButtons = styled.div`
  width: 100%;
  margin-top: 24px;
  ${mixins.flex('space-around')}
`;

const ModalButton = styled.button`
  display: block;
  width: 128px;
  height: 40px;
  border-radius: 4px;
`;

export const ConfirmButton = styled(ModalButton)`
  background-color: ${colors.mainFontColor};
  color: ${colors.white};

  &:hover {
    background-color: ${colors.alternativeFontColor};
  }

  &.big {
    width: 220px;
  }
`;

export const CancelButton = styled(ModalButton)`
  background-color: transparent;
  color: ${colors.mainFontColor};

  &:hover {
    color: ${colors.alternativeFontColor};
  }

  &.small {
    width: 80px;
  }
`;
