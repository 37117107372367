const appCleanup = () => {
  return {
    type: 'APP_CLEANUP',
  };
};

const cleanUpActions = {
  appCleanup,
};

export default cleanUpActions;
