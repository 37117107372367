const viewportBreakpoints = {
  mobile: '375px',
  tablet: '760px',
  tabletBig: '1024px',
  desktop: '1200px',
  desktopBig: '1500px',
  desktopLarge: '1800px',
};

export default viewportBreakpoints;
