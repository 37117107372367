import React from 'react';
import uuid from 'react-uuid';
import styled from 'styled-components';
import * as mixins from '../../../../../styles/mixins';
import * as colors from '../../../../../styles/colors';
import * as typography from '../../../../../styles/typography';

import DiagramPropertiesVersion from './DiagramPropertiesLastVersion';
import { DiagramVersionHoverProvider } from "../../../../shared/diagram-version/DiagramVersionHoverProvider";
import { DiagramVersionHover } from "../../../../shared/diagram-version/DiagramVersionHover";

const DiagramLastVersions = styled.div`
  box-sizing: border-box;
  padding-left: 46px;
  padding-right: 16px;
`;

const DiagramVersionsButton = styled.div`
  margin-top: 8px;
  ${mixins.flex('flex-start')};
`;

const DiagramVersionsLabel = styled.p`
  margin-right: 10px;
  color: ${colors.mainFontColor};
  ${mixins.fontStyle(typography.textFontSize, typography.semibold)};
  cursor: pointer;

  &:hover {
    color: ${colors.alternativeFontColor};
  }
`;

const DiagramNoLastVersions = styled.p`
  margin: 5px 0 15px;
  color: ${colors.mainFontColor};
  ${mixins.fontStyle(typography.labelFontSize, typography.normal)};
`;

const DiagramVersionsContainer = styled.div`
  max-height: 50vh;
  overflow-x: auto;
`;

const DiagramPropertiesLastVersions = ({
  currentDiagram,
  currentDiagramVersionId,
  currentDiagramLastVersions,
  currentDiagramLiveVersion,
  changeCurrentDiagramAndVersion,
  changeDiagramVersionStatus,
  deleteDiagramVersion,
  isCurrentDiagramHasMoreVersions,
  loadMoreCurrentDiagramVersions,
  showLessCurrentDiagramVersions,
  isCommentVisible,
}) => {
  return (
    <DiagramLastVersions>
      {currentDiagramLastVersions ? (
        currentDiagramLastVersions.length > 0 ? (
          <>
            <DiagramVersionHoverProvider>
              <DiagramVersionHover />
              <DiagramVersionsContainer>
                {currentDiagramLastVersions.map(version => (
                  <DiagramPropertiesVersion
                    key={uuid()}
                    diagram={currentDiagram}
                    deleteExpandedDiagramVersion={() => {
                      deleteDiagramVersion({
                        versionId: version.id,
                        forDiagram: 'current',
                      });
                    }}
                    version={version}
                    isCurrent={currentDiagramVersionId === version.id}
                    isCommentVisible={isCommentVisible}
                    isTest={currentDiagram.versionInTest && currentDiagram.versionInTest.id === version.id}
                    isLive={currentDiagram.versionInLive && currentDiagram.versionInLive.id === version.id}
                    changeCurrentDiagramAndVersion={changeCurrentDiagramAndVersion}
                    changeCurrentDiagramVersionStatus={statusAction =>
                      changeDiagramVersionStatus({
                        versionId: version.id,
                        forDiagram: 'current',
                        statusAction: statusAction,
                      })
                    }
                  />
                ))}
              </DiagramVersionsContainer>
            </DiagramVersionHoverProvider>
            <DiagramVersionsButton>
              {isCurrentDiagramHasMoreVersions ? (
                <DiagramVersionsLabel
                  onClick={() => loadMoreCurrentDiagramVersions(currentDiagram.id, currentDiagramLastVersions.length)}
                >
                  Load more +
                </DiagramVersionsLabel>
              ) : (
                ''
              )}
              {currentDiagramLastVersions.length > 3 ? (
                <DiagramVersionsLabel onClick={() => showLessCurrentDiagramVersions()}>
                  Show less -
                </DiagramVersionsLabel>
              ) : (
                ''
              )}
            </DiagramVersionsButton>
          </>
        ) : (
          <DiagramNoLastVersions>No versions found for solution flow</DiagramNoLastVersions>
        )
      ) : (
        'Loading'
      )}
    </DiagramLastVersions>
  );
};

export default DiagramPropertiesLastVersions;
