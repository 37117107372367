import { NotificationManager } from 'react-notifications';

const commonErrorMessage =
  'Something went wrong ! Please restart application and try again. If error repeats contact support.';

const NotificationService = {
  push: notification => {
    switch (notification.type) {
      case 'info':
        NotificationManager.info(notification.message, 'Information', notification.timeOut || 3000);
        break;
      case 'success':
        NotificationManager.success(notification.message, 'Success', notification.timeOut || 3000);
        break;
      case 'warning':
        NotificationManager.warning(notification.message, 'Warning', notification.timeOut || 3000);
        break;
      case 'error':
        NotificationManager.error(notification.message?.detail || notification.message || commonErrorMessage, 'Error', notification.timeOut || 5000);
        break;
    }
  },
};

export default NotificationService;
