import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import uuid from 'react-uuid';
import './Overview.scss';
import LabelsTable from './labels-table/LabelsTable';
import labelsActions from '../../action-creators/labelsActions';
import modalsActions from '../../action-creators/modalsActions';
import FormModal from './modals/FormModal';
import ConfirmationModal from './modals/ConfirmationModal';

import iconGroup from './img/group.png';
import iconPackage from './img/package.png';
import iconTools from './img/tools.png';

import iconLabel from './img/label.png';
import iconAdd from './img/add_new.png';
import iconEdit from './img/edit.png';
import iconMerge from './img/merge.png';
import iconRemove from './img/trash.png';
import withChatDraft from "../utils/chat-draft/withChatDraft";
import {withRoles} from "../utils/roles/withRoles";

const OverviewMock = ({
  labels,
  fetchLabelsList,
  currentLabelId,
  createLabel,
  updateLabel,
  deleteLabel,
  addLabelModalOpen,
  editLabelModalOpen,
  deleteLabelModalOpen,
  toggleModal,
}) => {
  useEffect(() => {
    fetchLabelsList();
  }, []);

  const editLabel = id => {
    return (name, owner, interval, description) => updateLabel(id, name, owner, interval, description);
  };

  const taskCount = 4;

  return (
    <div id="overview">
      <div id="overview-menu">
        <div id="overview-menu-buttons">
          <div className="buttons">
            <p className="link-button">
              <img src={iconPackage} alt="" /> <span>Tasks ({taskCount})</span>
              <div className="link-button__menu">
                <div className="link-button__menu-header">
                  <img src={iconPackage} alt="" />
                  Tasks ({taskCount})
                </div>

                <p className="link-button__menu-item" onClick={() => toggleModal('addLabelModal')}>
                  <img src={iconLabel} alt="" /> Quarterly review of roaming-inquiry
                </p>
                <p className={`link-button__menu-item`} onClick={() => toggleModal('editLabelModal')}>
                  <img src={iconLabel} alt="" /> Review new solution for device-buy
                </p>
                <p className={`link-button__menu-item`}>
                  <img src={iconLabel} alt="" /> Label more examples for roaming-inqury
                </p>
                <p className={`link-button__menu-item`} onClick={() => toggleModal('deleteLabelModal')}>
                  <img src={iconLabel} alt="" /> Review quality of last-bill-inquiry
                </p>
              </div>
            </p>
            <p className={`link-button link-button--icon`}>
              <img src={iconTools} alt="" />

              <div className="link-button__menu">
                <div className="link-button__menu-header">
                  <img src={iconTools} alt="" />
                  Tools
                </div>

                <p className="link-button__menu-item" onClick={() => toggleModal('addLabelModal')}>
                  Add new label <img src={iconAdd} alt="" />
                </p>
                <p
                  className={`link-button__menu-item ${currentLabelId ? '' : 'disabled'}`}
                  onClick={() => toggleModal('editLabelModal')}
                >
                  Edit label <img src={iconEdit} alt="" />
                </p>
                <p className={`link-button__menu-item ${currentLabelId ? '' : 'disabled'}`}>
                  Merge label <img src={iconMerge} alt="" />
                </p>
                <p
                  className={`link-button__menu-item ${currentLabelId ? '' : 'disabled'}`}
                  onClick={() => toggleModal('deleteLabelModal')}
                >
                  Delete label <img src={iconRemove} alt="" />
                </p>
              </div>
            </p>
          </div>

          <img className="owners-icons" src={iconGroup} alt="" />
        </div>
      </div>
      <LabelsTable />

      {addLabelModalOpen ? (
        <FormModal title="Add label" labelAction={createLabel} closeModal={() => toggleModal('addLabelModal')} />
      ) : (
        ''
      )}
      {editLabelModalOpen ? (
        <FormModal
          title="Edit label"
          labelAction={editLabel(currentLabelId)}
          closeModal={() => toggleModal('editLabelModal')}
          formState={labels ? labels.filter(label => label.id === currentLabelId)[0] : undefined}
        />
      ) : (
        ''
      )}
      {deleteLabelModalOpen ? (
        <ConfirmationModal
          title="Delete label"
          message={
            <span>
              Are you sure you want to delete the label?
              <br />
              This action is irreversible
            </span>
          }
          labelAction={() => deleteLabel(currentLabelId)}
          closeModal={() => toggleModal('deleteLabelModal')}
        />
      ) : (
        ''
      )}
    </div>
  );
};

export const mapStateToProps = state => {
  return {
    labels: state.labels.labels,
    addLabelModalOpen: state.modals.addLabelModal.isOpen,
    editLabelModalOpen: state.modals.editLabelModal.isOpen,
    deleteLabelModalOpen: state.modals.deleteLabelModal.isOpen,
    currentLabelId: state.labels.currentLabelId,
  };
};

const mapDispatchToProps = {
  fetchLabelsList: labelsActions.fetchLabelsList,
  toggleModal: modalsActions.toggleModal,
  createLabel: labelsActions.createLabel,
  updateLabel: labelsActions.updateLabel,
  deleteLabel: labelsActions.deleteLabel,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRoles(
  ['labeler', 'product_manager', 'admin'],
  withChatDraft(withRouter(OverviewMock)))
);
