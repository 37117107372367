import initialCallbotState from "../constants/initial-state-parts/initialCallbotState";
import {SET_CALLBOT_CLASSIFIERS, SET_CALLBOT_VALIDATORS} from "../action-creators/callbotActions";

const callbotReducer = (state = initialCallbotState, action) => {
  switch (action.type) {
    case SET_CALLBOT_CLASSIFIERS:
      return Object.assign({}, state, {
        classifiers: action.payload,
      });
    case SET_CALLBOT_VALIDATORS:
      return Object.assign({}, state, {
        validators: action.payload,
      });
    default:
      return state;
  }
}

export default callbotReducer;
