import React from 'react';

import uuid from 'react-uuid';
import './Button.scss';

const Button = ({ label, action }) => {
  return (
    <div className="button" onClick={action}>
      <p className="button-label">{label}</p>
    </div>
  );
};

export default Button;
