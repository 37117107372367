const StorageService = {
  store: (key, value) => {
    localStorage.setItem(key, JSON.stringify(value));
  },

  get: key => {
    return localStorage.getItem(key);
  },

  remove: key => {
    localStorage.removeItem(key);
  },

  clear: () => {
    localStorage.clear();
  },

  storeForSession: (key, value) => {
    sessionStorage.setItem(key, JSON.stringify(value));
  },

  getFromSession: key => {
    return JSON.parse(sessionStorage.getItem(key));
  },
};

export default StorageService;
