import React from 'react';

const RadioIcon = ({ color }) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12 14C13.1046 14 14 13.1046 14 12C14 10.8954 13.1046 10 12 10C10.8954 10 10 10.8954 10 12C10 13.1046 10.8954 14 12 14Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.2399 7.76018C16.7978 8.31741 17.2404 8.97914 17.5423 9.70753C17.8442 10.4359 17.9997 11.2167 17.9997 12.0052C17.9997 12.7937 17.8442 13.5744 17.5423 14.3028C17.2404 15.0312 16.7978 15.6929 16.2399 16.2502M7.75994 16.2402C7.20208 15.6829 6.75953 15.0212 6.45758 14.2928C6.15564 13.5644 6.00022 12.7837 6.00022 11.9952C6.00022 11.2067 6.15564 10.4259 6.45758 9.69753C6.75953 8.96915 7.20208 8.30741 7.75994 7.75018M19.0699 4.93018C20.9446 6.80545 21.9978 9.34853 21.9978 12.0002C21.9978 14.6518 20.9446 17.1949 19.0699 19.0702M4.92994 19.0702C3.05523 17.1949 2.00208 14.6518 2.00208 12.0002C2.00208 9.34853 3.05523 6.80545 4.92994 4.93018"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default RadioIcon;
