import crud from './common/CrudService';
import responseService from './common/ResponseService';

const API_BASE_URL = process.env.API_URL || 'http://127.0.0.1:8000';
const API_PREFIX = process.env.API_PREFIX || 'api/v1';
const messageLabelsCRUD = crud(API_BASE_URL, API_PREFIX, ['message_labels/']);
const messageLabelsExamplesCRUD = crud(API_BASE_URL, API_PREFIX, ['message_labels', 'example/']);

const MessageLabelsService = {
  createMessageLabel: ({ chatId, messageId, labelId, langLabel, labelMode }) => {
    return responseService.handle(
      messageLabelsCRUD.create({
        chat_intercom_id: chatId,
        message_id: messageId,
        label_id: labelId,
        lang_label: langLabel,
        source: labelMode,
      })
    );
  },
  createMessageLabelExample: ({ labelId, message, userId, lang }) => {
    return responseService.handle(
      messageLabelsExamplesCRUD.create({
        label_id: labelId,
        message,
        user_id: userId,
        lang,
      })
    );
  },
  deleteMessageLabel: ({ id }) => {
    return responseService.handle(messageLabelsCRUD.delete(id));
  },
};

export default MessageLabelsService;
