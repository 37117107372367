import {useSelector} from "react-redux";
import {ElementPropOption, ElementPropSelect} from "./utils/CommonElements";
import React from "react";

export const SelectWithStateOptions = ({isBlocked, modeling, currentElement, elementPropKey, value, stateKey}) => {
  const options = useSelector((state) => state.callbot[stateKey]);

  return (
    <ElementPropSelect
      value={value || ''}
      disabled={isBlocked}
      onChange={e => {
        modeling.updateProperties(currentElement, {
          [elementPropKey]: e.target.value,
        });
      }}
    >
      <ElementPropOption value="">Select {elementPropKey} to use</ElementPropOption>
      {options.map(({id, name}) => <ElementPropOption key={id} value={name}>{name}</ElementPropOption>)}
    </ElementPropSelect>
  );
}
