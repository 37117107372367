import React from "react";
import {useAsyncCombobox} from "@tracksuitdev/use-select";
import {Popover, positionMatchWidth} from "@reach/popover";

const AsyncCombobox = ({itemToString, className, placeholder, ...props}) => {
  const {
    selectRef,
    dropdownRef,
    inputRef,
    open,
    isOpen,
    handleKeyDown,
    inputValue,
    setInputValue,
    items,
    handleItemClick,
    highlightedIndex
  } = useAsyncCombobox({itemToString, ...props});

  return (
    <div className={className} ref={selectRef} onFocus={open} tabIndex={0} onKeyDown={handleKeyDown}>
      <input className="ant-input" value={inputValue} onChange={(e) => setInputValue(e.target.value)} ref={inputRef}
             placeholder={placeholder}/>
      {isOpen && (
        <Popover targetRef={inputRef} position={positionMatchWidth}>
          <ul className="ant-menu ant-menu-root ant-menu-vertical ant-menu-light" role="menu" ref={dropdownRef}
              tabIndex={0} onKeyDown={handleKeyDown}>
            {items && items.map((item, index) => (
              <li
                className={`ant-menu-item ant-menu-item-only-child ${highlightedIndex === index ? 'ant-menu-item-active' : ''}`}
                role="menuitem" tabIndex="-1" key={index}
                onClick={() => handleItemClick(item)}>
              <span className="ant-menu-title-content">
                {itemToString(item)}
              </span>
              </li>
            ))}
          </ul>
        </Popover>
      )}
    </div>
  );
}

export default AsyncCombobox;
