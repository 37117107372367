import React from 'react';
import uuid from 'react-uuid';
import styled from 'styled-components';
import * as mixins from '../../../../../styles/mixins';
import * as colors from '../../../../../styles/colors';
import * as typography from '../../../../../styles/typography';

import UserIcon from '../../../../shared/icons/tools/UserIcon';

const OwnerContainer = styled.div`
  margin-top: 10px;
  margin-left: 10px;
  ${mixins.flex('flex-start', 'flex-start')};
`;

const OwnerIconWrapper = styled.div`
  width: 24px;
`;

const OwnerContent = styled.div`
  margin-left: 10px;
  margin-right: 40px;
  width: 100%;
`;

const OwnersUpdateContent = styled.div`
  position: relative;
  width: 100%;
  height: 28px;
  ${mixins.flex('space-between', 'center')};
`;

const OwnersEditButton = styled.p`
  min-width: 100px;
  box-sizing: border-box;
  color: ${colors.mainFontColor};
  ${mixins.fontStyle(typography.textFontSize, typography.semibold)};
  cursor: pointer;

  &:hover {
    color: ${colors.alternativeFontColor};
  }
`;

const OwnersList = styled.div`
  position: absolute;
  width: 270px;
  max-height: 132px;
  box-sizing: border-box;
  overflow-y: scroll;
  padding: 8px 0;
  top: 100%;
  right: 0;
  border: 1px solid ${colors.darkGrey};
  background-color: ${colors.white};
`;

const OwnersSearchInput = styled.input`
  width: 100%;
  height: 28px;
  box-sizing: border-box;
  padding: 4px 6px;
  background: ${colors.extraBackgroundColor};
  color: ${colors.mainFontColor};
  ${mixins.fontStyle(typography.textFontSize, typography.semibold)};
`;

const OwnerName = styled.p`
  color: ${colors.mainFontColor};
  ${mixins.fontStyle(typography.textFontSize, typography.normal)};
`;

const OwnerChoice = styled(OwnerName)`
  box-sizing: border-box;
  padding: 0 12px;

  &:hover {
    background-color: ${colors.componentBackgroundColor};
  }
`;

const OwnerNoData = styled.p`
  color: ${colors.mainFontColor};
  ${mixins.fontStyle(typography.textFontSize, typography.normal)};
`;

const DiagramPropertiesOwner = ({
  user,
  users,
  owner,
  ownerSearchString,
  isOwnerSearchOpen,
  changeOwnerSearchOpen,
  fetchUsersList,
  updateOwnersSearchString,
  updateCurrentDiagramOwner,
  updateOwnersList,
}) => {
  return (
    <OwnerContainer>
      <OwnerIconWrapper>
        <UserIcon color={colors.mainIconColor} />
      </OwnerIconWrapper>
      <OwnerContent>
        {owner ? (
          <OwnerName>{(owner.firstName || owner.first_name) + ' ' + (owner.lastName || owner.last_name)}</OwnerName>
        ) : (
          <OwnerNoData>No owner</OwnerNoData>
        )}
        {user?.isManager ? (
          <OwnersUpdateContent>
            <OwnersEditButton
              onClick={() => {
                changeOwnerSearchOpen(!isOwnerSearchOpen);
                updateOwnersSearchString('');
                updateOwnersList([]);
              }}
            >
              {isOwnerSearchOpen ? 'Close' : 'Edit'}
            </OwnersEditButton>
            {isOwnerSearchOpen ? (
              <>
                <OwnersSearchInput
                  id="diagram-owners-search-input"
                  value={ownerSearchString}
                  onChange={event => {
                    updateOwnersSearchString(event.target.value);
                    if (event.target.value.length > 2) {
                      fetchUsersList(event.target.value);
                    }
                  }}
                  placeholder="Type owners name"
                  autoFocus={true}
                />
                {users && users.length > 0 ? (
                  <OwnersList>
                    {users.map(ownerElem => (
                      <OwnerChoice
                        key={uuid()}
                        onClick={() => {
                          updateCurrentDiagramOwner(ownerElem);
                          changeOwnerSearchOpen(!isOwnerSearchOpen);
                          updateOwnersSearchString('');
                          updateOwnersList([]);
                        }}
                      >
                        {(ownerElem.firstName || ownerElem.first_name) +
                          ' ' +
                          (ownerElem.lastName || ownerElem.last_name)}
                      </OwnerChoice>
                    ))}
                  </OwnersList>
                ) : (
                  ''
                )}
              </>
            ) : (
              ''
            )}
          </OwnersUpdateContent>
        ) : (
          ''
        )}
      </OwnerContent>
    </OwnerContainer>
  );
};

export default DiagramPropertiesOwner;
