import BaseRenderer from "diagram-js/lib/draw/BaseRenderer";
import {HIGH_PRIORITY, ELEMENT_WIDTH, DEFAULT_ZOOM_LEVEL} from "../renderer-constants";
import {Renderer} from "./RendererUtil";
import {is} from "bpmn-js/lib/util/ModelUtil";

export default class CallbotInputTaskRenderer extends BaseRenderer {
  constructor(eventBus, bpmnRenderer) {
    super(eventBus, HIGH_PRIORITY);

    this.bpmnRenderer = bpmnRenderer;
    this.elementWidth = ELEMENT_WIDTH;
    this.currentZoomLevel = DEFAULT_ZOOM_LEVEL;
    this.config = {
      height: 150,
      color: '#1EAAAA',
      icon: {
        content: '<path d="M19 3H5C3.89543 3 3 3.89543 3 5V19C3 20.1046 3.89543 21 5 21H19C20.1046 21 21 20.1046 21 19V5C21 3.89543 20.1046 3 19 3Z" stroke="#000F3C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/> ' +
          '<path d="M12 8V16" stroke="#000F3C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/> ' +
          '<line x1="7.75" y1="7.25" x2="11.25" y2="7.25" stroke="#000F3C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/> ' +
          '<line x1="12.75" y1="7.25" x2="16.25" y2="7.25" stroke="#000F3C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/> ' +
          '<line x1="7.75" y1="16.75" x2="11.25" y2="16.75" stroke="#000F3C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/> ' +
          '<line x1="12.75" y1="16.75" x2="16.25" y2="16.75" stroke="#000F3C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/> ' +
          '<line x1="9.75" y1="11.75" x2="14.25" y2="11.75" stroke="#000F3C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/> ',
        width: 24,
        height: 24
      }
    }
    this.fields = [
      {label: 'message template', valueKey: 'template', valueId: 'MessageTemplate', defaultValue: ''},
      {label: 'clip duration', valueKey: 'clipDuration', valueId: 'ClipDuration', defaultValue: ''},
      {label: 'variable name for input', valueKey: 'identifierForInput', valueId: 'InputText', defaultValue: 'No variable name'},
      {label: 'input type', valueKey: 'inputType', valueId: 'InputType', defaultValue: 'text'}
    ]
  }

  canRender(element) {
    return is(element, 'bpmn:ReceiveTask') && element.businessObject.subType === 'input-task';
  }

  drawShape(parentNode, element) {
    element.width = this.elementWidth;
    const fields = [...this.fields];
    if (element.businessObject.file) {
      fields[0] = ({label: 'file', valueKey: 'file', valueId: 'File', defaultValue: 'No file'});
    }
    const render = Renderer(parentNode, element, this.bpmnRenderer);
    const {shape, zoom} = render(this.config, this.fields);
    this.currentZoomLevel = zoom;
    return shape;
  }
}

CallbotInputTaskRenderer.$inject = ['eventBus', 'bpmnRenderer'];
