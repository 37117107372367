import React from "react";
import Audio from "./ElementPropertyValueTypes/Audio";
import styled from "styled-components";
import * as colors from "../../../styles/colors";
import * as mixins from "../../../styles/mixins";
import * as typography from "../../../styles/typography";

const ElementPropValue = styled.p`
  min-height: 24px;
  color: ${colors.mainFontColor};
  ${mixins.fontStyle(typography.textFontSize, typography.normal)};
`;


const ElementPropertyGenericValue = ({type, value, currentElement, modeling}) => {
  switch (type) {
    case "audio":
      return <Audio value={value} currentElement={currentElement} modeling={modeling}/>
    default:
      return <ElementPropValue>{value}</ElementPropValue>
  }
}

export default ElementPropertyGenericValue;
