import { SUPPORTED_LANGUAGES } from "./codeToLanguageMap";

export const languagesWithFlags = [
  { flag: '🇬🇧', lang: 'ENG', key: 'en' },
  { flag: '🇩🇪', lang: 'GER', key: 'de' },
  { flag: '🇪🇪', lang: 'EST', key: 'et' },
  { flag: '🇷🇺', lang: 'RUS', key: 'ru' },
  { flag: '🇱🇻', lang: 'LVI', key: 'lv' },
  { flag: '🇱🇹', lang: 'LTH', key: 'lt' },
  { flag: '🇵🇱', lang: 'POL', key: 'pl' },
  { flag: '🇴🇲', lang: 'ARB', key: 'arb' },
];

export const supportedLanguagesWithFlags = languagesWithFlags.filter(l => SUPPORTED_LANGUAGES.includes(l.key));
