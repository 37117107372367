import ModelerDrawService from './modeler-helper-services/ModelerDrawService';
import { taskData } from '../constants/tasks/taskData';
import StorageService from './common/StorageService';
import { isJson } from "../components/shared/element-property/ElementPropertyInputTypes/utils/CommonFunctions";
import JSON5 from "json5";
import unEscape from "../helpers/unescape";

const allTaskTypes = [
  'bpmn:SendTask',
  'bpmn:ReceiveTask',
  'bpmn:ServiceTask',
  'bpmn:Task',
  'bpmn:ScriptTask',
  'bpmn:CallActivity',
];

const DEFAULT_SFM_LANGUAGE = process.env.DEFAULT_SFM_LANGUAGE || 'en';

// This function is required to remove editing-parent box
// when it becomes visible, only if task node added
const __removeCurrentEditingInputBox = (maxIter = 3) => {
  if (maxIter === 0) {
    return 0;
  }
  const editParents = document.getElementsByClassName('djs-direct-editing-parent');
  if (editParents.length > 0) {
    editParents[0].style.display = 'none';
    return 1;
  } else {
    setTimeout(() => {
      __removeCurrentEditingInputBox(maxIter - 1);
    }, 100);
  }
};

function getXYWayPoints(arr) {
  if (!arr) {
    return null;
  }
  var maxY = arr[0];
  var maxX = arr[0];
  for (let a of arr) {
    if (a.y > maxY.y) maxY = a;
    if (a.x > maxX.x) maxX = a;
  }
  return { maxX, maxY };
}

function getLastTarget(arr) {
  if (!arr) {
    return null;
  }
  var maxY = arr[0];
  for (let a of arr) {
    if (a.target.y > maxY.target.y) maxY = a;
  }
  return maxY;
}

const ModelerService = {
  importDiagramVersion: (modeler, file, currentUserId) => {
    modeler.importXML(file).then(() => {
      ModelerService._subscribeForCustomAppearance(modeler);
      // NOTE: this function require separate service for all scenarious
      ModelerService._autoRefactorAppearance(modeler);
      ModelerService.processOwnerOverlays(modeler, currentUserId);
      ModelerService._updateRenderedApperance(modeler)
    });
  },

  exportDiagramVersion: (modeler, done) => {
    modeler
      .saveXML()
      .then(r => done(null, r.xml).then(r => r))
      .catch(e => done(e, null).then(r => r));
  },

  centerDiagram: modeler => {
    var canvas = modeler.get('canvas');
    canvas.zoom('fit-viewport', true);
  },
  selectNode: id => {
    const selection = modeler.get('selection');
    const elementRegistry = modeler.get('elementRegistry');
    const newElement = elementRegistry.get(id);
    selection.select(newElement);
  },
  createMessageNodesForInput: (currentElement, modeling, canvas, value, currentValue) => {
    if (!currentElement.outgoing.length) {
      // Create Gateway if does not exist at the required position.
      let gateway = modeling.createShape(
        {
          type: 'bpmn:ExclusiveGateway',
        },
        { x: currentElement.x + 300, y: currentElement.y + currentElement.height / 2 },
        canvas.getRootElement()
      );
      // Create connection from input node to gatway
      modeling.connect(currentElement, gateway);
    } else if (value.length === 0) {
      // Remove gatway, connections and all message nodes if all choices are deleted
      for (let targetRef of currentElement.outgoing[0].target.outgoing) {
        modeling.removeShape(targetRef.target);
      }
      let target = currentElement.outgoing[0].target;
      modeling.removeConnection(currentElement.outgoing[0]);
      modeling.removeShape(target);
    }

    let gateway = currentElement.outgoing[0]?.target;
    if (gateway && gateway.type === 'bpmn:ExclusiveGateway') {
      let oldValues = isJson(currentValue) ? JSON5.parse(currentValue) : [];
      let valuesToAdd = value.filter(v => !oldValues.includes(v));
      let valuesToRemove = oldValues.filter(v => !value.includes(v));
      // Add Message Nodes for New choices
      for (let addValue of valuesToAdd) {
        let lastTargetNode = getLastTarget(currentElement.outgoing[0].target.outgoing);
        let messageNode = modeling.createShape(
          {
            type: 'bpmn:SendTask',
          },
          {
            x: gateway.x + 200,
            y:
              gateway.y +
              gateway.height / 2 +
              (lastTargetNode
                ? 15 + lastTargetNode.target.height + lastTargetNode.target.y - (gateway.y - gateway.height / 2)
                : 0),
          },
          canvas.getRootElement()
        );
        modeling.updateProperties(messageNode, {
          subType: 'message-task',
          source: 'Source',
          template: '',
        });
        let connection = modeling.connect(gateway, messageNode, {
          type: 'bpmn:SequenceFlow',
        });
        // Add Label Condition based on Variable Name and Choice
        modeling.updateProperties(connection, {
          name: `${currentElement.businessObject.identifierForInput} ==  "${addValue}"`,
        });
        //Position Label correctly
        // if (currentElement.outgoing[0].target.outgoing.length > 1)
        const { maxX, maxY } = getXYWayPoints(connection.waypoints);
        modeling.moveShape(connection.label, {
          x: maxX.x - connection.label.x - connection.label.width - 20,
          y: maxY.y - connection.label.y - connection.label.height - 5,
        });
      }
      // Remove Message Nodes for Deleted choices
      for (let removeValue of valuesToRemove) {
        let elem = currentElement.outgoing[0].target.outgoing.find(
          o => o.businessObject.name === `${currentElement.businessObject.identifierForInput} ==  "${removeValue}"`
        );
        if (elem) {
          modeling.removeShape(elem.target);
        }
      }
    }
  },

  subscribe: (
    modeler,
    currentUserId,
    updateCurrentTask,
    updateCurrentSubProcess,
    updateCurrentSubProcessDiagramVersions,
    updateCurrentEvent,
    updateCurrentFileState,
    updateCurrentDiagramTranslationsModificationCount,
    diagramType
  ) => {
    ModelerService._updateFileSaveStateOnCommand(modeler, updateCurrentFileState);
    ModelerService._updateElementsOnSelect(
      modeler,
      updateCurrentTask,
      updateCurrentSubProcess,
      updateCurrentSubProcessDiagramVersions,
      updateCurrentEvent
    );
    ModelerService._disableDoubleClick(modeler);
    ModelerService._updateCurrentElementOnChange(
      modeler,
      currentUserId,
      updateCurrentTask,
      updateCurrentSubProcess,
      updateCurrentSubProcessDiagramVersions,
      updateCurrentEvent,
      updateCurrentDiagramTranslationsModificationCount,
      diagramType
    );
  },

  replace: (modeler, currentTask, newTaskSubType) => {
    let canvas = modeler.get('canvas');
    let overlays = modeler.get('overlays');

    const modelerDrawService = ModelerDrawService(canvas, overlays);

    const replace = modeler.get('replace');
    const modeling = modeler.get('modeling');
    const selection = modeler.get('selection');
    const newTaskData = taskData[newTaskSubType];
    let newElementData = {
      type: newTaskData.type,
    };
    let newBusinessObject = {
      // name: newTaskData.name, 
      subType: newTaskSubType,
      id: newTaskData.prettyType + '_' + currentTask.id.split('_')[1],
    };
    newTaskData.props.forEach(prop => {
      newBusinessObject = Object.assign({}, newBusinessObject, { [prop.key]: '' });
    });
    const newElement = replace.replaceElement(currentTask, newElementData);
    modeling.updateProperties(newElement, newBusinessObject);
    selection.select(newElement);
    // modelerDrawService.updateElementAppearance(newElement, newBusinessObject.subType);
  },

  processOwnerOverlays: (modeler, currentUserId) => {
    const isOwnersVisible = StorageService.getFromSession('DTM_OWNERS_VISIBILITY');
    const isUserTasksHighlighted = StorageService.getFromSession('DTM_USER_TASKS_HIGHLIGHTED');
    const elementRegistry = modeler.get('elementRegistry');
    const overlays = modeler.get('overlays');
    const canvas = modeler.get('canvas');
    const tasks = elementRegistry.filter(element => allTaskTypes.includes(element.type));
    const modelerDrawService = ModelerDrawService(canvas, overlays);
    tasks.map(task => {
      if (isOwnersVisible || isUserTasksHighlighted) {
        if (task.businessObject.extensionElements && task.businessObject.extensionElements.values.length > 0) {
          modelerDrawService.addOwnersOverlay(task, isOwnersVisible, isUserTasksHighlighted, currentUserId);
        }
      } else {
        overlays.remove({ element: task.id });
      }
    });
  },

  // Internal functions

  _autoRefactorAppearance: modeler => {
    const elementRegistry = modeler.get('elementRegistry');
    const modeling = modeler.get('modeling');
    let canvas = modeler.get('canvas');
    let overlays = modeler.get('overlays');

    const modelerDrawService = ModelerDrawService(canvas, overlays);

    const scriptTasks = elementRegistry.filter(element => element.type === 'bpmn:ScriptTask');

    const allNonCompositeTypes = ['bpmn:ReceiveTask', 'bpmn:ServiceTask', 'bpmn:Task'];
    const allNonCompositeTasks = elementRegistry.filter(element => allNonCompositeTypes.includes(element.type));

    allNonCompositeTasks.map(element => {
      if (!element.businessObject.subType) {
        const newSubType = Object.keys(taskData).filter(taskKey => taskData[taskKey].type === element.type);
        modeling.updateProperties(element, {
          subType: newSubType[0],
        });
        modelerDrawService.updateElementAppearance(element, newSubType[0]);
      }
    });

    scriptTasks.map(element => {
      const scriptTaskSubType = element.businessObject.subType;
      if (scriptTaskSubType === 'logic' || scriptTaskSubType === 'logic-python') {
        modeling.updateProperties(element, {
          subType: scriptTaskSubType + '-task',
        });
        modelerDrawService.updateElementAppearance(element, scriptTaskSubType + '-task');
      }
    });

    const sendTasks = elementRegistry.filter(element => element.type === 'bpmn:SendTask');

    sendTasks.map(element => {
      const sendTaskSubType = element.businessObject.subType;
      if (!sendTaskSubType) {
        modeling.updateProperties(element, {
          subType: 'message-task',
        });
        modelerDrawService.updateElementAppearance(element, 'message-task');
      }
    });

    const parallelGateways = elementRegistry.filter(element => element.type === 'bpmn:ParallelGateway');
    parallelGateways.map(element => {
      const gatewaySubType = element.businessObject.subType;
      if (!gatewaySubType) {
        modeling.updateProperties(element, {
          subType: 'parallel-gateway',
        });
        modelerDrawService.updateElementAppearance(element, 'parallel-gateway');
      }
    });

    const endEvents = elementRegistry.filter(element => element.type === 'bpmn:EndEvent');
    endEvents.map(element => {
      const businessObject = element.businessObject;
      if (businessObject.eventDefinitions) {
        if (businessObject.eventDefinitions[0].$type === 'bpmn:ErrorEventDefinition') {
          modelerDrawService.updateEventAppearance(element, 'error-end-event');
        }
      } else {
        modelerDrawService.updateEventAppearance(element, 'end-event');
      }
    })
  },

  _subscribeForCustomAppearance: modeler => {
    let eventBus = modeler.get('eventBus');
    let canvas = modeler.get('canvas');
    let overlays = modeler.get('overlays');

    const modelerDrawService = ModelerDrawService(canvas, overlays);

    eventBus.on('shape.added', e => {
      __removeCurrentEditingInputBox();
      if (e.element.businessObject) {
        if (e.element.type === 'bpmn:EndEvent') {
          const businessObject = e.element.businessObject;
          if (businessObject.eventDefinitions) {
            if (businessObject.eventDefinitions[0].$type === 'bpmn:ErrorEventDefinition') {
              modelerDrawService.updateEventAppearance(e.element, 'error-end-event');
            }
          } else {
            modelerDrawService.updateEventAppearance(e.element, 'end-event');
          }
        }
      }
    });
  },

  _updateFileSaveStateOnCommand: (modeler, updateCurrentFileState) => {
    modeler.on('commandStack.changed', function () {
      let commandStack = modeler.get('commandStack');
      updateCurrentFileState(commandStack['_stackIdx'] === -1);
    });
  },

  _updateElementsOnSelect: (
    modeler,
    updateCurrentTask,
    updateCurrentSubProcess,
    updateCurrentSubProcessDiagramVersions,
    updateCurrentEvent
  ) => {
    const __onSelectionChangedListener = ({ oldSelection, newSelection }) => {
      if (!newSelection || newSelection.length === 0) {
        updateCurrentSubProcess(undefined, undefined);
        updateCurrentTask(undefined, undefined);
        updateCurrentSubProcessDiagramVersions([]);
        updateCurrentEvent(undefined, undefined);
      }
    };

    const __onDoubleClickChangedListener = e => {
      const oneSelectedElement = e.element;
      if (oneSelectedElement.type.includes('Task') || oneSelectedElement.type === 'bpmn:ParallelGateway') {
        updateCurrentTask(oneSelectedElement, oneSelectedElement.businessObject);
        updateCurrentSubProcess(undefined, undefined);
        updateCurrentSubProcessDiagramVersions([]);
        updateCurrentEvent(undefined, undefined);
      } else if (oneSelectedElement.type === 'bpmn:CallActivity') {
        updateCurrentSubProcess(oneSelectedElement, oneSelectedElement.businessObject);
        updateCurrentSubProcessDiagramVersions([]);
        updateCurrentTask(undefined, undefined);
      } else if (oneSelectedElement.type === 'bpmn:EndEvent') {
        updateCurrentEvent(oneSelectedElement, oneSelectedElement.businessObject);
        updateCurrentTask(undefined, undefined);
        updateCurrentSubProcess(undefined, undefined);
        updateCurrentSubProcessDiagramVersions([]);
      } else {
        updateCurrentSubProcess(undefined, undefined);
        updateCurrentTask(undefined, undefined);
        updateCurrentSubProcessDiagramVersions([]);
        updateCurrentEvent(undefined, undefined);
      }
    };

    let eventBus = modeler.get('eventBus');
    eventBus.on('selection.changed', __onSelectionChangedListener);

    let priority = 10000;
    eventBus.on('element.dblclick', priority, __onDoubleClickChangedListener);

    return __onSelectionChangedListener;
  },

  _updateCurrentElementOnChange: (
    modeler,
    currentUserId,
    updateCurrentTask,
    updateCurrentSubProcess,
    updateCurrentSubProcessDiagramVersions,
    updateCurrentEvent,
    updateCurrentDiagramTranslationsModificationCount,
    diagramType
  ) => {
    const updateTranslations = event => {
      const root = modeler._definitions.rootElements[0];
      const nodesTranslationData = JSON.parse(root.translations || '{}');
      if (Object.keys(nodesTranslationData).length === 0) {
        return;
      }
      const latestNode = root.flowElements.find(elem => elem.id === event.element.id);
      if (event.element.type === 'bpmn:ScriptTask') {
        processGettext(event, latestNode.template || '', nodesTranslationData)
      }
      else if (!nodesTranslationData[event.element.id]) {
        nodesTranslationData[event.element.id] = {
          srcString: latestNode.template,
          srcStringModifiedAt: event.element.businessObject.lastModified,
          defaultLanguage: DEFAULT_SFM_LANGUAGE,
          translations: {},
        };
      } else {
        if (nodesTranslationData[event.element.id].srcString !== latestNode.template) {
          nodesTranslationData[event.element.id].srcStringModifiedAt = event.element.businessObject.lastModified;
          nodesTranslationData[event.element.id].srcString = latestNode.template;
          if (diagramType === "callbot") {
            nodesTranslationData[event.element.id].values = latestNode.values;
          }
          for (const lang in nodesTranslationData[event.element.id].translations) {
            nodesTranslationData[event.element.id].translations[lang].reviewed = false;
          }
        } else if (diagramType === "callbot" && nodesTranslationData[event.element.id].values !== latestNode.values) {
          nodesTranslationData[event.element.id].srcStringModifiedAt = event.element.businessObject.lastModified;
          nodesTranslationData[event.element.id].values = latestNode.values;
          for (const lang in nodesTranslationData[event.element.id].translations) {
            nodesTranslationData[event.element.id].translations[lang].reviewed = false;
          }
        }
      }
      if (event.element.type === 'bpmn:ReceiveTask') {
        const inputNodeRetryMessage = latestNode.retryMessage?.replace(/'/g, '"');
        const existingRetryMessage = nodesTranslationData[`${event.element.id}_retry`]?.srcString;
        if (inputNodeRetryMessage && inputNodeRetryMessage !== existingRetryMessage) {
          const existingTranslations = nodesTranslationData[`${event.element.id}_retry`]?.translations;
          for (const lang in existingTranslations) {
            existingTranslations[lang].reviewed = false;
          }
          nodesTranslationData[`${event.element.id}_retry`] = {
            srcString: inputNodeRetryMessage,
            srcStringModifiedAt: event.element.businessObject.lastModified,
            defaultLanguage: DEFAULT_SFM_LANGUAGE,
            translations: existingTranslations,
          };
        }
        try {
          const choicesParseableString = latestNode.choices?.replace(/'/g, '"');
          const choices = JSON5.parse(choicesParseableString || '[]');
          choices.forEach((choice, index) => {
            const existingChoice = nodesTranslationData[`${event.element.id}_choices_${index}`]?.srcString;
            if (choice !== existingChoice) {
              const existingTranslations = nodesTranslationData[`${event.element.id}_choices_${index}`].translations;
              for (const lang in existingTranslations) {
                existingTranslations[lang].reviewed = false;
              }
              nodesTranslationData[`${event.element.id}_choices_${index}`] = {
                srcString: choice,
                srcStringModifiedAt: event.element.businessObject.lastModified,
                defaultLanguage: DEFAULT_SFM_LANGUAGE,
                translations: existingTranslations,
              };
            }
          });
        } catch (e) {
          processGettext(event, latestNode.choices || '', nodesTranslationData)
        }
      }

      root.translations = JSON.stringify(nodesTranslationData);
      updateCurrentDiagramTranslationsModificationCount();
    };

    const processGettext = (event, inputText, nodesTranslationData) => {
      const gettextInputs = Array.from((inputText)
        .matchAll(/gettext\((["'])(?<str>.*?)["']\)/g), match => unEscape(match.groups.str));
      let lastIndex = 0;
      gettextInputs.forEach((value, index) => {
        let translation = null;
        let oldKey = null;
        for (let key in nodesTranslationData) {
          if (!key.includes(`${event.element.id}_scripts_`)) {
            continue;
          }
          const existing = nodesTranslationData[key];
          if (!!existing && existing.srcString === value) {
            translation = existing;
            oldKey = key;
            break;
          }
        }
        const newKey = `${event.element.id}_scripts_${index}`;
        if (!!translation) {
          const temp = nodesTranslationData[oldKey];
          nodesTranslationData[oldKey] = nodesTranslationData[newKey];
          nodesTranslationData[newKey] = temp;
        }
      }); // swap translations before marking 'new'
      gettextInputs.forEach((value, index) => {
        lastIndex = index;
        let existingTranslations = {};
        if (nodesTranslationData[`${event.element.id}_scripts_${index}`]) {
          const oldSource = nodesTranslationData[`${event.element.id}_scripts_${index}`].srcString;
          existingTranslations = nodesTranslationData[`${event.element.id}_scripts_${index}`].translations;
          if (oldSource !== value) {
            for (const lang in existingTranslations) {
              existingTranslations[lang].reviewed = false;
            }
          }
        }
        nodesTranslationData[`${event.element.id}_scripts_${index}`] = {
          srcString: value,
          srcStringModifiedAt: event.element.businessObject.lastModified,
          defaultLanguage: DEFAULT_SFM_LANGUAGE,
          translations: existingTranslations,
        };
      });
      while (nodesTranslationData[`${event.element.id}_scripts_${lastIndex + 1}`]) {
        lastIndex += 1;
        delete nodesTranslationData[`${event.element.id}_scripts_${lastIndex}`];
      }
    }

    const deleteNodeFromTranslations = event => {
      const root = modeler._definitions.rootElements[0];
      const nodesTranslationData = JSON.parse(root.translations || '{}');
      if (Object.keys(nodesTranslationData).length === 0) {
        return;
      }
      Object.keys(nodesTranslationData).forEach(k => {
        if (k.includes(event.element.id)) {
          delete nodesTranslationData[k];
        }
      });
      root.translations = JSON.stringify(nodesTranslationData);
      updateCurrentDiagramTranslationsModificationCount();
    };

    const __onElementChangeListener = event => {
      const isOwnersVisible = StorageService.getFromSession('DTM_OWNERS_VISIBILITY');
      const isUserTasksHighlighted = StorageService.getFromSession('DTM_USER_TASKS_HIGHLIGHTED');

      if (event) {
        event.element.businessObject.lastModified = Math.floor(Date.now() / 1000);
      }
      if (event && event.gfx && event.element.type.includes('Task')) {
        updateCurrentTask(event.element, event.element.businessObject, true);
        if (isOwnersVisible || isUserTasksHighlighted) {
          ModelerService.processOwnerOverlays(modeler, currentUserId);
        }
        if (
          event.element.type === 'bpmn:SendTask' ||
          event.element.type === 'bpmn:ReceiveTask' ||
          event.element.type === 'bpmn:ScriptTask'
        ) {
          updateTranslations(event);
        }
      } else if (
        event && event.gfx && event.element.type === 'bpmn:CallActivity'
      ) {
        updateCurrentSubProcess(event.element, event.element.businessObject, true);
        if (isOwnersVisible || isUserTasksHighlighted) {
          ModelerService.processOwnerOverlays(modeler, currentUserId);
        }
      } else {
        if (
          event && (
            event.element.type === 'bpmn:SendTask' ||
            event.element.type === 'bpmn:ReceiveTask' ||
            event.element.type === 'bpmn:ScriptTask'
          )) {
          deleteNodeFromTranslations(event);
        }
        updateCurrentTask(undefined, undefined, true);
        updateCurrentSubProcess(undefined, undefined, true);
        updateCurrentSubProcessDiagramVersions([]);
      }
      ModelerService._updateRenderedApperance(modeler)
    };
    modeler.on('element.changed', __onElementChangeListener);

    setTimeout(() => {
      __onElementChangeListener(null);
    }, 1000);

    return __onElementChangeListener;
  },

  _disableDoubleClick: modeler => {
    const eventBus = modeler.get('eventBus');
    const priority = 10000;
    const doubleClickAllowedElements = ['label', 'bpmn:SequenceFlow'];
    eventBus.on('element.dblclick', priority, e => {
      if (!doubleClickAllowedElements.includes(e.element.type)) {
        return false;
      }
    });
  },



  //// Add Label Condition based on Variable Name and Choice
  //         modeling.updateProperties(connection, {
  //           name: `${currentElement.businessObject.identifierForInput} ==  "${addValue}"`,
  //         });
  //         //Position Label correctly
  //         // if (currentElement.outgoing[0].target.outgoing.length > 1)
  //         const { maxX, maxY } = getXYWayPoints(connection.waypoints);
  //         modeling.moveShape(connection.label, {
  //           x: maxX.x - connection.label.x - connection.label.width - 20,
  //           y: maxY.y - connection.label.y - connection.label.height - 5,
  //         });


  // Kept it here as a reference on how to access command stack events
  // _removeSelfConnection: modeler => {
  //   const eventBus = modeler.get('eventBus');
  //   eventBus.on('commandStack.connection.create.postExecuted', e => {
  //     let modeling = modeler.get('modeling');
  //     let element = e.context.connection;
  //     if (element.businessObject && element.type === 'bpmn:SequenceFlow' && element.source.id === element.target.id) {
  //       modeling.removeElements([element]);
  //     }
  //   });
  // },

  _updateRenderedApperance: modeler => {
    let canvas = modeler.get('canvas');
    let overlays = modeler.get('overlays');
    let elementRegistry = modeler.get('elementRegistry');
    const modelerDrawService = ModelerDrawService(canvas, overlays);

    const parallelGateways = elementRegistry.filter(element => element.type === 'bpmn:ParallelGateway');
    parallelGateways.forEach(gateway => {
      if (gateway.outgoing.length < 1 || gateway.incoming.length < 1) {
        modelerDrawService.updateElementAppearance(gateway, 'error', {
          bottom: 10,
          left: 0,
        });
      } else {
        const mainBranches = gateway.outgoing.filter(sequenceFlow =>
          sequenceFlow.businessObject.name === '<main>');
        if (mainBranches.length !== 1) {
          modelerDrawService.updateElementAppearance(gateway, 'error', {
            bottom: 10,
            left: 0,
          });
        } else {
          overlays.remove({ element: gateway.id });
        }
      }
    });

    const allGateways = elementRegistry.filter(element => element.type === 'bpmn:ExclusiveGateway');
    allGateways.forEach(gateway => {
      if (
        gateway.outgoing.length < 2 ||
        gateway.incoming.length < 1 ||
        gateway.outgoing.filter(sequenceFlow => sequenceFlow.businessObject.name === 'else').length >= 2
      ) {
        modelerDrawService.updateElementAppearance(gateway, 'error', {
          bottom: 10,
          left: 0,
        });
      } else {
        const conditionValidation = gateway.outgoing.map(
          sequenceFlow => !!sequenceFlow.businessObject.name && sequenceFlow.businessObject.name !== ''
        );
        if (conditionValidation.includes(false)) {
          modelerDrawService.updateElementAppearance(gateway, 'warning', {
            bottom: 10,
            left: 0,
          });
        } else {
          overlays.remove({ element: gateway.id });
        }

        let hasTimeoutSequenceFlow = false;
        let hasEventSequenceFlow = false;
        gateway.outgoing.forEach(sequenceFlow => {
          if (sequenceFlow.businessObject.name === '<event>') {
            hasEventSequenceFlow = true;
          }
          if (sequenceFlow.businessObject.name === '<timeout>') {
            hasTimeoutSequenceFlow = true;
          }
        });

        let renderError = false;
        if (hasTimeoutSequenceFlow) {
          let hasNoTimeoutInputNodes = true;
          gateway.incoming.forEach(sequenceFlow => {
            if (sequenceFlow.source.businessObject.timeout) {
              hasNoTimeoutInputNodes = false;
            }
          });
          renderError = hasNoTimeoutInputNodes;
        }
        if (hasEventSequenceFlow) {
          let hasNoEventInputNodes = true;
          gateway.incoming.forEach(sequenceFlow => {
            if (sequenceFlow.source.businessObject.eventFilter) {
              hasNoEventInputNodes = false;
            }
          });
          renderError = hasNoEventInputNodes;
        }
        if (renderError) {
          modelerDrawService.updateElementAppearance(gateway, 'error', {
            bottom: 10,
            left: 0,
          });
        }
      }
    });

    const allTasks = elementRegistry.filter(element => element.type.includes('Task'));
    allTasks.forEach(task => {
      if (task.outgoing.length > 1) {
        modelerDrawService.updateElementAppearance(task, 'error', {
          bottom: 10,
          left: 0,
        });
      } else {
        overlays.remove({ element: task.id });
      }
    });

    const allStartEvents = elementRegistry.filter(element => element.type === 'bpmn:StartEvent');
    if (allStartEvents.length > 1) {
      allStartEvents.forEach(startEvent => {
        modelerDrawService.updateElementAppearance(startEvent, 'error', {
          bottom: 10,
          left: 0,
        });
      });
    } else {
      if (allStartEvents[0]) overlays.remove({ element: allStartEvents[0].id });
    }
  },
};

export default ModelerService;
