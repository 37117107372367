import ModelerService from './ModelerService';
import NotificationService from './common/NotificationService';
import ViewerService from './ViewerService';
import {computeBranchesAndUpdateLayout} from '.././bpmn-engine/index';
import TTSService from "./TTSService";
import {SUPPORTED_LANGUAGES} from "../../app/components/settings/Settings";
import {timer} from "../helpers/timer";

const addBranchesToFile = async file => {
  let nodes = await computeBranchesAndUpdateLayout(file);
  let newFile = file;
  newFile = newFile.replaceAll(/\s*branches=\"\[(.*?)\]\"/g, '');
  newFile = newFile.replaceAll(/\s*leaf=\"true\"/g, '');
  newFile = newFile.replaceAll(/\s*\$type=\"(.*?)\"/g, '');
  nodes.map(node => {
    if (Array.isArray(node.branches))
      newFile = newFile.replace(`id=\"${node.id}\"`, `id=\"${node.id}\" branches=\"${JSON.stringify(node.branches)}\"`);
    if (node.isLeaf) newFile = newFile.replace(`id=\"${node.id}\"`, `id=\"${node.id}\" leaf=\"true\"`);
  });
  return newFile;
};

const synthesizeAllPrompts = async (modeler) => {
  timer.start('synthesizeAllPrompts');
  const elementRegistry = modeler.get('elementRegistry');
  const modeling = modeler.get('modeling');

  const translations = JSON.parse(modeler._definitions.rootElements[0].translations || "{}")

  const promises = []
  const nodeIds = []
  Object.keys(translations).forEach((nodeId) => {
    const nodeData = translations[nodeId];
    if (nodeId === 'label_name_translations' || nodeId === 'label_description_translations') {
      return
    }
    SUPPORTED_LANGUAGES.forEach((lang) => {
      const translation = nodeData?.translations?.[lang];
      const text = translation?.text;
      if (text) {
        promises.push(TTSService.synthesize(text, translation.values, null));
        nodeIds.push({id: nodeId, lang});
      }
    })
  })
  const breakingPoint = promises.length;

  const synthTasks = elementRegistry.filter(element =>
    (element.businessObject.subType === 'message-task' || element.businessObject.subType === 'input-task') &&
    !element.businessObject.file);

  promises.push(...synthTasks.map(task => TTSService.synthesize(
    task.businessObject.template, task.businessObject.values, task.businessObject.name)));

  await Promise.all(promises).then((responses) => {
    responses.forEach(({data}, index) => {
      if (index < breakingPoint) {
        const nodeId = nodeIds[index];
        const translation = translations[nodeId.id].translations[nodeId.lang];
        translation.promptId = data.id;
        translation.promptUrl = data.url;
      } else {
        modeling.updateProperties(synthTasks[index - breakingPoint], {
          promptUrl: data.url,
          promptId: data.id,
          clipDuration: data.duration,
        });
      }
    })
  });
  modeler._definitions.rootElements[0].translations = JSON.stringify(translations);
  timer.end('synthesizeAllPrompts');
};

const exportDiagramVersion = (modeler, currentDiagram, currentDiagramVersion, saveAll, updateSaveProgress, saveInstantly) => {
  ModelerService.exportDiagramVersion(modeler, async (err, file) => {
    timer.start('exportDiagramVersion');
    if (err) {
      updateSaveProgress({saveInProgress: false, saveProgressStatus: 0});
      NotificationService.push({
        type: 'error',
        message: `Internal engine can't export file. Please store this traceback: ${err}`,
      });
    } else {
      let newFile = await addBranchesToFile(file);
      updateSaveProgress({saveInProgress: true, saveProgressStatus: 10});
      saveAll(currentDiagram, currentDiagramVersion, newFile, saveInstantly);
    }
    timer.end('exportDiagramVersion');
  });
}

const saveAllService = {
  handleSave: (modeler, currentDiagram, currentDiagramVersion, saveAll, updateSaveProgress, saveInstantly) => {
    updateSaveProgress({saveInProgress: true, saveProgressStatus: 0});
    if (currentDiagramVersion.diagramType === "callbot") {
      synthesizeAllPrompts(modeler).then(() => exportDiagramVersion(modeler, currentDiagram, currentDiagramVersion,
        saveAll, updateSaveProgress, saveInstantly));
    } else {
      exportDiagramVersion(modeler, currentDiagram, currentDiagramVersion, saveAll, updateSaveProgress, saveInstantly);
    }
  },

  handleSaveFileFromViewer: (viewer, saveOnlyDiagramVersionFile) => {
    ViewerService.exportDiagramVersion(viewer, (err, file) => {
      if (err) {
        NotificationService.push({
          type: 'error',
          message: `Internal engine can't export file. Please store this traceback: ${err}`,
        });
      } else {
        saveOnlyDiagramVersionFile(file);
      }
    });
  },
};

export default saveAllService;
