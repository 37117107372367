import React from "react";
import styled from "styled-components";
import {Radio} from "antd";

const ChoicesOptionWrapper = styled.div`
  position: relative;
  top: -15px;
  float: right;
`;

const Options = ({options, value, onChange}) => {
  return (
    <ChoicesOptionWrapper>
      <Radio.Group
        options={options}
        value={value}
        onChange={onChange}
        optionType="button"
        style={{ zIndex: 9999 }}
      />
    </ChoicesOptionWrapper>
  )
}

export default Options;
