import initialSaveProgressState from '../constants/initial-state-parts/initialSaveProgressState';

const saveProgressReducer = (state = initialSaveProgressState, action) => {
  switch (action.type) {
    case 'UPDATE_SAVE_PROGRESS':
      if (action.saveInProgress !== state.saveInProgress) {
        document.querySelector('#sfm-app-modeler').classList.toggle('blured');
      }
      return Object.assign({}, state, {
        saveInProgress: action.saveInProgress,
        saveProgressStatus: action.saveProgressStatus || 0,
      });
    default:
      return state;
  }
};

export default saveProgressReducer;
