import React from 'react';

import './common.scss';

const Input = ({ label, placeholder, value, onChange, type = 'text', ...props }) => {
  return (
    <div className="input-container" {...props}>
      <p className="input-label">{label}</p>
      <input className="input" placeholder={placeholder} value={value} onChange={onChange} type={type} {...props} />
    </div>
  );
};

export default Input;
