import React, {useState} from "react";
import {Upload, Button} from "antd";
import {constructUrl} from "../../../../services/common/RestService";
import styled from "styled-components";

const API_BASE_URL = process.env.SFM_API_URL || 'http://127.0.0.1:5000';
const API_PREFIX = process.env.SFM_CLIENT_PREFIX || 'dev-api';
const URL = constructUrl(API_BASE_URL, API_PREFIX, ["tts", "prompts", "upload"]);

const Container = styled.div`
  width: 50%;
`

const FileUpload = ({value, modeling, disabled, elementPropKey, currentElement}) => {
  const [fileList, setFileList] = useState(value ? [{name: value}] : []);

  const handleChange = ({file, fileList}) => {
    fileList.forEach(f => f.originFileObj = null);
    const isRemoved = !fileList || !fileList.length;
    if (isRemoved) {
      modeling.updateProperties(currentElement, {
        [elementPropKey]: null,
        promptId: null,
        promptUrl: null,
        clipDuration: null,
      })
    } else if (file.status === 'done') {
      modeling.updateProperties(currentElement, {
        [elementPropKey]: file.name,
        promptId: file.response.id,
        promptUrl: file.response.url,
        clipDuration: file.response.duration,
      })
    }
    setFileList(fileList)
  }

  return (
    <Container>
      <Upload disabled={disabled} fileList={fileList} name="file" action={URL}
              onChange={handleChange} accept=".wav" maxCount={1}>
        <Button>Upload</Button>
      </Upload>
    </Container>
  );
}

export default FileUpload;
