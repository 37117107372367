import SendTaskRenderer from './SendTaskRenderer';
import InputTaskRenderer from './InputTaskRenderer';
import ServiceTaskRenderer from './ServiceTaskRenderer';
import MapTaskRenderer from './MapTaskRenderer';
import LogicTaskRenderer from './LogicTaskRenderer';
import LogicPythonTaskRenderer from './LogicPythonTaskRenderer';
import ClassifierRenderer from './ClassifierTaskRenderer';
import CallActivityRenderer from './CallActivityRenderer';
import EmptyTaskRenderer from './EmptyTaskRenderer';
import TableTaskRenderer from './TableTaskRenderer';
import CarouselTaskRenderer from './CarouselTaskRenderer';
import HelpdeskMessageTaskRenderer from './HelpdeskMessageTaskRenderer';
import VoiceTaskRenderer from './VoiceTaskRenderer';
import SequenceFlowTaskRenderer from './SequenceFlowTaskRenderer';

export default {
  __init__: [
    'SendTaskRenderer',
    'InputTaskRenderer',
    'TableTaskRenderer',
    'ServiceTaskRenderer',
    'MapTaskRenderer',
    'LogicTaskRenderer',
    'LogicPythonTaskRenderer',
    'ClassifierTaskRenderer',
    'CallActivityRenderer',
    'CarouselTaskRenderer',
    'EmptyTaskRenderer',
    'HelpdeskMessageTaskRenderer',
    'VoiceTaskRenderer',
    'SequenceFlowTaskRenderer',
  ],
  SendTaskRenderer: ['type', SendTaskRenderer],
  InputTaskRenderer: ['type', InputTaskRenderer],
  TableTaskRenderer: ['type', TableTaskRenderer],
  ServiceTaskRenderer: ['type', ServiceTaskRenderer],
  MapTaskRenderer: ['type', MapTaskRenderer],
  LogicTaskRenderer: ['type', LogicTaskRenderer],
  LogicPythonTaskRenderer: ['type', LogicPythonTaskRenderer],
  ClassifierTaskRenderer: ['type', ClassifierRenderer],
  CallActivityRenderer: ['type', CallActivityRenderer],
  CarouselTaskRenderer: ['type', CarouselTaskRenderer],
  EmptyTaskRenderer: ['type', EmptyTaskRenderer],
  HelpdeskMessageTaskRenderer: ['type', HelpdeskMessageTaskRenderer],
  VoiceTaskRenderer: ['type', VoiceTaskRenderer],
  SequenceFlowTaskRenderer: ['type', SequenceFlowTaskRenderer],
};
