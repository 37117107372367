import inherits from 'inherits';

import ContextPadProvider from 'bpmn-js/lib/features/context-pad/ContextPadProvider';

import { assign, bind } from 'min-dash';

const ENABLE_VOICE_TASK = process.env.ENABLE_VOICE_TASK || 'false';

export default function CustomContextPadProvider(injector, connect, translate, _elementRegistry) {
  injector.invoke(ContextPadProvider, this);

  let elementFactory = this._elementFactory;
  let elementRegistry = _elementRegistry;
  let autoPlace = this._autoPlace;
  let create = this._create;
  let modeling = this._modeling;

  function getMaxNum(arr) {
    if (!arr) {
      return null;
    }
    var maxV = arr[0];
    for (let a of arr) {
      if (a > maxV) maxV = a;
    }
    return maxV;
  }

  function appendAction(type, group, className, title, options) {
    if (typeof title !== 'string') {
      options = title;
      title = translate('Append {type}', { type: type.replace(/^bpmn:/, '') });
    }

    function appendStart(event, element) {
      let target;
      if (options && options.isError) {
        target = {
          type: type,
          eventDefinitionType: 'bpmn:ErrorEventDefinition',
        };
      } else {
        target = { type: type };
      }
      var shape = elementFactory.createShape(assign(target, options));

      if (options && !options.isError) {
        shape.businessObject.subType = options.subType;
      }

      if (type === 'bpmn:SendTask' && props.subType) {
        shape.businessObject.subType = props.subType;
        shape.businessObject.template = props.template || '';
        shape.businessObject.source = 'Source';
        shape.businessObject.templateAsAudioUrl = '';
      } else if (type === 'bpmn:Task') {
        shape.businessObject.subType = props.subType;
      } else if (type === 'bpmn:ReceiveTask' && props.subType === 'input-task') {
        shape.businessObject.subType = props.subType;
        shape.businessObject.template = props.template || '';
        shape.businessObject.identifierForInput = `choice_${getCurrentChoice()}`;
        shape.businessObject.identifierForChoice = '';
        shape.businessObject.choices = '[]';
        shape.businessObject.validation = '';
        shape.businessObject.classifierEnabled = '';
        shape.businessObject.parser = '';
        shape.businessObject.eventFilter = '';
      } else if (type === 'bpmn:ReceiveTask' && props.subType === 'carousel-task') {
        shape.businessObject.subType = props.subType;
        shape.businessObject.identifierForSelectedItem = props.identifierForSelectedItem;
        shape.businessObject.carouselItemsData = '';
        shape.businessObject.itemTitleTemplate = '';
        shape.businessObject.itemDescription = '';
        shape.businessObject.itemImage = '';
        shape.businessObject.itemActionPayload = '';
      } else if (type === 'bpmn:ServiceTask') {
        shape.businessObject.subType = props.subType;
        shape.businessObject.method = '';
        shape.businessObject.url = '';
        shape.businessObject.body = '';
        shape.businessObject.requestVariable = '';
        shape.businessObject.identifierForResponse = '';
      } else if (type === 'bpmn:ScriptTask' && props.subType === 'table-task') {
        shape.businessObject.subType = props.subType;
        shape.businessObject.firstRowIsHeader = '';
        shape.businessObject.firstColumnIsHeader = '';
        shape.businessObject.title = '';
        shape.businessObject.footer = '';
        shape.businessObject.data = '';
        shape.businessObject.definition = '';
      } else if (type === 'bpmn:ScriptTask' && props.subType) {
        shape.businessObject.subType = props.subType;
        shape.businessObject.identifierForResult = 'identifier_for_result';
        shape.businessObject.template = props.subType === 'logic' ? 'Jinja expression goes here' : 'Python code here';
      }

      create.start(event, shape);
    }

    var append = autoPlace
      ? function (event, element) {
          let target;
          if (options && options.isError) {
            target = {
              type: type,
              eventDefinitionType: 'bpmn:ErrorEventDefinition',
            };
          } else {
            target = { type: type };
          }
          var shape = elementFactory.createShape(assign(target, options));

          if (
            type === 'bpmn:SendTask' &&
            ['message-task', 'helpdesk-message-task', 'voice-task'].includes(options.subType)
          ) {
            shape.businessObject.subType = options.subType;
            shape.businessObject.template = '';
            shape.businessObject.source = 'Source';
            shape.businessObject.message_speed = '';
            shape.businessObject.templateAsAudioUrl = '';
          } else if (type === 'bpmn:SendTask' && options.subType === 'table-task') {
            shape.businessObject.subType = options.subType;
            shape.businessObject.firstRowIsHeader = '';
            shape.businessObject.firstColumnIsHeader = '';
            shape.businessObject.title = '';
            shape.businessObject.footer = '';
            shape.businessObject.data = '';
            shape.businessObject.definition = '';
          } else if (type === 'bpmn:Task') {
            shape.businessObject.subType = options.subType;
          } else if (type === 'bpmn:ReceiveTask' && options.subType === 'input-task') {
            shape.businessObject.subType = options.subType;
            shape.businessObject.identifierForInput = `choice_${getCurrentChoice()}`;
            shape.businessObject.identifierForChoice = '';
            shape.businessObject.choices = '[]';
            shape.businessObject.classifierEnabled = '';
            shape.businessObject.parser = '';
            shape.businessObject.eventFilter = '';
          } else if (type === 'bpmn:ReceiveTask' && options.subType === 'carousel-task') {
            shape.businessObject.subType = options.subType;
            shape.businessObject.identifierForSelectedItem = '';
            shape.businessObject.identifierForChoice = '';
            shape.businessObject.carouselItemsData = '';
            shape.businessObject.itemTitleTemplate = '';
            shape.businessObject.itemDescription = '';
            shape.businessObject.itemImage = '';
            shape.businessObject.itemActionPayload = '';
          } else if (type === 'bpmn:ServiceTask') {
            shape.businessObject.subType = options.subType;
            shape.businessObject.method = '';
            shape.businessObject.url = '';
            shape.businessObject.body = '';
            shape.businessObject.requestVariable = '';
            shape.businessObject.identifierForResponse = '';
          } else if (type === 'bpmn:ScriptTask' && options.subType) {
            shape.businessObject.subType = options.subType;
            shape.businessObject.identifierForResult = '';
            shape.businessObject.template =
              options.subType === 'logic' ? 'Jinja expression goes here' : 'Python code here';
            shape.businessObject.classifierType = '';
          }

          if (options && !options.isError) {
            shape.businessObject.subType = options.subType;
          }
          autoPlace.append(element, shape);
        }
      : appendStart;

    return {
      group: group,
      className: className,
      title: title,
      action: {
        dragstart: appendStart,
        click: append,
      },
    };
  }

  var cached = bind(this.getContextPadEntries, this);

  this.getContextPadEntries = function (element) {
    var actions = cached(element);

    delete actions['append.text-annotation'];
    delete actions['replace'];
    delete actions['append.append-task'];
    delete actions['append.intermediate-event'];
    delete actions['append.end-event'];

    if (element.type === 'bpmn:EndEvent') {
      delete actions['connect'];
    }
    if (
      element.type.includes('Task') ||
      element.type.includes('StartEvent') ||
      element.type.includes('Gateway') ||
      element.type.includes('CallActivity')
    ) {
      assign(actions, {
        'append.gateway': appendAction(
          'bpmn:ExclusiveGateway',
          'model',
          'exclusive-gateway',
          translate('Exclusive Gateway')
        ),
        'append.error-event': appendAction('bpmn:EndEvent', 'model', 'error-event', translate('Error Event'), {
          isError: true,
        }),
        'append.end-event': appendAction('bpmn:EndEvent', 'model', 'end-event', translate('End Event')),
        'append.message-task': appendAction('bpmn:SendTask', 'connect', 'message-task', translate('Message Task'), {
          template: '',
          subType: 'message-task',
        }),
        'append.input-task': appendAction('bpmn:ReceiveTask', 'connect', 'input-task', translate('Input Task'), {
          subType: 'input-task',
        }),
        'append.table-task': appendAction('bpmn:SendTask', 'connect', 'table-task', translate('Table Task'), {
          subType: 'table-task',
        }),
        'append.carousel-task': appendAction(
          'bpmn:ReceiveTask',
          'connect',
          'carousel-task',
          translate('Carousel Task'),
          { subType: 'carousel-task' }
        ),
        'append.service-task': appendAction('bpmn:ServiceTask', 'connect', 'service-task', translate('API Call Task'), {
          subType: 'service-task',
        }),
        'append.script-logic-task': appendAction('bpmn:ScriptTask', 'connect', 'logic-task', translate('Logic Task'), {
          subType: 'logic-task',
        }),
        'append.script-logic-python-task': appendAction(
          'bpmn:ScriptTask',
          'connect',
          'logic-python-task',
          translate('Logic (python) Task'),
          { subType: 'logic-python-task' }
        ),
        'append.classifier-task': appendAction(
          'bpmn:ScriptTask',
          'connect',
          'classifier-task',
          translate('Classifier Task'),
          { subType: 'classifier-task' }
        ),
        'append.helpdesk-message-task': appendAction(
          'bpmn:SendTask',
          'connect',
          'helpdesk-message-task',
          translate('Helpdesk Message Task'),
          {
            subType: 'helpdesk-message-task',
          }
        ),
        'append.call-actibity-task': appendAction(
          'bpmn:CallActivity',
          'connect',
          'call-activity',
          translate('Subflow')
        ),
      });
      if (ENABLE_VOICE_TASK === 'true')
        assign(actions, {
          'append.voice-task': appendAction('bpmn:SendTask', 'connect', 'voice-task', translate('Voice Task'), {
            subType: 'voice-task',
          }),
        });
    }

    if (element.type === 'bpmn:SequenceFlow' && element.source?.type === 'bpmn:ExclusiveGateway') {
      if (element.businessObject.name === '<timeout>') {
        assign(actions, {
          'replace.sf-regular': {
            group: 'edit',
            className: 'no-timeout',
            title: translate('Change to regular'),
            action: {
              click: function (event, element) {
                element.businessObject.name = '';
                modeling.updateProperties(element, element.businessObject);
              },
            },
          },
        });
      } else {
        assign(actions, {
          'replace.sf-timeout': {
            group: 'edit',
            className: 'timeout',
            title: translate('Change to timeout'),
            action: {
              click: function (event, element) {
                element.businessObject.name = '<timeout>';
                modeling.updateProperties(element, element.businessObject);
              },
            },
          },
        });
      }
      if (element.businessObject.name === '<event>') {
        assign(actions, {
          'replace.sf-regular': {
            group: 'edit',
            className: 'no-event',
            title: translate('Change to regular'),
            action: {
              click: function (event, element) {
                element.businessObject.name = '';
                modeling.updateProperties(element, element.businessObject);
              },
            },
          },
        });
      } else {
        assign(actions, {
          'replace.sf-event': {
            group: 'edit',
            className: 'event',
            title: translate('Change to event'),
            action: {
              click: function (event, element) {
                element.businessObject.name = '<event>';
                modeling.updateProperties(element, element.businessObject);
              },
            },
          },
        });
      }
    }

    return actions;
  };

  function getCurrentChoice() {
    var elements = elementRegistry.filter(
      element => element.type === 'bpmn:ReceiveTask' && element.businessObject.identifierForInput?.startsWith('choice_')
    );
    let numChoice =
      parseInt(
        getMaxNum(
          elements
            .map(el => el.businessObject.identifierForInput.replace('choice_', ''))
            .filter(x => !isNaN(parseInt(x)))
        ) || 0
      ) + 1;
    return numChoice;
  }
}

inherits(CustomContextPadProvider, ContextPadProvider);

CustomContextPadProvider.$inject = ['injector', 'connect', 'translate', 'elementRegistry'];
