import initialVisibilityState from '../constants/initial-state-parts/initialVisibilityState';
import StorageService from '../services/common/StorageService';

const visibilityReducer = (state = initialVisibilityState, action) => {
  switch (action.type) {
    case 'TOGGLE_OWNERS_VISIBILITY':
      StorageService.storeForSession('DTM_OWNERS_VISIBILITY', !state.ownersVisible);
      return Object.assign({}, state, {
        ownersVisible: !state.ownersVisible,
      });
    case 'TOGGLE_USER_TASKS_VISIBILITY':
      StorageService.storeForSession('DTM_USER_TASKS_HIGHLIGHTED', !state.userTasksHighlighted);
      return Object.assign({}, state, {
        userTasksHighlighted: !state.userTasksHighlighted,
      });
    case 'TOGGLE_VERSION_CONTROL_VISIBILITY':
      // TODO: rewrite this logic as saga, as reducer should not
      //  perform any side effects.
      document.querySelector('#sfm-app-modeler').classList.toggle('blured');
      return Object.assign({}, state, {
        versionControlPanelVisible: !state.versionControlPanelVisible,
      });
    case 'TOGGLE_DIAGRAM_PROPERTIES_VISIBILITY':
      return Object.assign({}, state, {
        diagramPropertiesVisible: !state.diagramPropertiesVisible,
      });
    case 'TOGGLE_DIAGRAM_VERSION_COMMENT_VISIBILITY':
      return Object.assign({}, state, {
        diagramVersionCommentVisible: !state.diagramVersionCommentVisible,
      });
    default:
      return state;
  }
};

export default visibilityReducer;
