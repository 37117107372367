import React, {useEffect, useState, useRef} from 'react';
import {Input} from "antd";
import styled from "styled-components";

const Container = styled.div`
  padding: 10px 0 10px 10px;
`;


const NumberInput = ({sendInput}) => {
  const ref = useRef(null);

  useEffect(() => {
    ref.current.scrollIntoView({behavior: "smooth"});
  }, []);

  const handleChange = ({target: {value}}) => {
    sendInput(value.slice(-1));
  }

  return (
    <Container ref={ref}>
      <Input onChange={handleChange} />
    </Container>
  )
}

export default NumberInput;
