import {useEffect} from "react";
import $ from "jquery";


const ChatDraftProvider = ({ children }) => {
  useEffect(() => {
    return () => {
      window.cws?.destroy();
      window.cws = undefined;
      $('#chat-web-sdk-reset-btn').removeClass('visible');
    };
  }, []);
  return children;
}

export default ChatDraftProvider;