const toggleOwnersVisibility = () => {
  return {
    type: 'TOGGLE_OWNERS_VISIBILITY',
  };
};

const toggleUserTasksHighlight = () => {
  return {
    type: 'TOGGLE_USER_TASKS_VISIBILITY',
  };
};

const toggleVersionControlVisibility = () => {
  return {
    type: 'TOGGLE_VERSION_CONTROL_VISIBILITY',
  };
};

const toggleDiagramPropertiesVisibility = () => {
  return {
    type: 'TOGGLE_DIAGRAM_PROPERTIES_VISIBILITY',
  };
};

const toggleDiagramVersionCommentVisibility = () => {
  return {
    type: 'TOGGLE_DIAGRAM_VERSION_COMMENT_VISIBILITY',
  };
};

const visiblityActions = {
  toggleOwnersVisibility,
  toggleUserTasksHighlight,
  toggleVersionControlVisibility,
  toggleDiagramPropertiesVisibility,
  toggleDiagramVersionCommentVisibility,
};

export default visiblityActions;
