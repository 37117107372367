const changeExpandedDiagram = (diagram, shouldCollapse = true) => {
  return {
    type: 'CHANGE_EXPANDED_DIAGRAM',
    diagram: diagram,
    shouldCollapse: shouldCollapse,
  };
};

const reloadExpandedDiagramAndVersions = diagram => {
  return {
    type: 'RELOAD_EXPANDED_DIAGRAM_AND_VERSIONS',
    diagram: diagram,
  };
};

const loadMoreExpandedDiagramVersions = (diagramId, startFrom) => {
  return {
    type: 'LOAD_MORE_EXPANDED_DIAGRAM_VERSIONS',
    diagramId: diagramId,
    startFrom: startFrom,
  };
};

const showLessExpandedDiagramVersions = () => {
  return {
    type: 'SHOW_LESS_EXPANDED_DIAGRAM_VERSIONS',
    newCount: 3,
  };
};

const updateExpandedDiagramWithVersions = (diagram, newVersions, hasMore, shouldCollapse = true) => {
  return {
    type: 'UPDATE_EXPANDED_DIAGRAM_WITH_VERSIONS',
    diagram: diagram,
    diagramVersions: newVersions,
    hasMore: hasMore,
    shouldCollapse: shouldCollapse,
  };
};

const updateExpandedDiagramVersions = (newVersions, hasMore, newCount, shouldRenew) => {
  return {
    type: 'UPDATE_EXPANDED_DIAGRAM_VERSIONS',
    extraDiagramVersions: newVersions,
    hasMore: hasMore,
    newCount: newCount,
    shouldRenew: shouldRenew,
  };
};

const diagramsActions = {
  changeExpandedDiagram,
  reloadExpandedDiagramAndVersions,
  loadMoreExpandedDiagramVersions,
  showLessExpandedDiagramVersions,
  updateExpandedDiagramWithVersions,
  updateExpandedDiagramVersions,
};

export default diagramsActions;
