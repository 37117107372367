import React from "react";
import {Checkbox as AntdCheckbox} from 'antd';
import {CheckboxWrapper} from "../ElementPropertyGenericInput";

export const Checkbox = ({
  currentValue, changeCurrentValue, isBlocked, modeling, currentElement, elementPropKey
}) => {
  return (
    <CheckboxWrapper>
      <AntdCheckbox
        checked={currentValue || false}
        disabled={isBlocked}
        onChange={e => {
          modeling.updateProperties(currentElement, {
            [elementPropKey]: !!e.target.checked,
          });
          changeCurrentValue(!!e.target.checked);
        }}
      />
    </CheckboxWrapper>
  );
}
