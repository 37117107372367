const changeDiagramVersionStatus = ({ versionId, forDiagram, statusAction, shouldProceed = false }) => {
  return {
    type: 'CHANGE_DIAGRAM_VERSION_STATUS',
    versionId: versionId,
    forDiagram: forDiagram,
    statusAction: statusAction,
    shouldProceed: shouldProceed,
  };
};

const deleteDiagramVersion = ({ versionId, forDiagram, shouldProceed = false, deleteDiagram = false }) => {
  return {
    type: 'DELETE_DIAGRAM_VERSION',
    versionId: versionId,
    forDiagram: forDiagram,
    shouldProceed: shouldProceed,
    deleteDiagram,
  };
};

const diagramVersionsActions = {
  changeDiagramVersionStatus,
  deleteDiagramVersion,
};

export default diagramVersionsActions;
