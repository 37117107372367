import BaseRenderer from 'diagram-js/lib/draw/BaseRenderer';

import {
  append as svgAppend,
  attr as svgAttr,
  classes as svgClasses,
  create as svgCreate,
  remove as svgRemove,
  innerSVG as svgInnerAppend,
} from 'tiny-svg';

import { getRoundRectPath } from 'bpmn-js/lib/draw/BpmnRenderUtil';

import { is } from 'bpmn-js/lib/util/ModelUtil';
import { isAny } from 'bpmn-js/lib/features/modeling/util/ModelingUtil';

import modeling from 'bpmn-js/lib/features/modeling';
import appReducer from '../../../reducers/appReducer';

const HIGH_PRIORITY = 1500;

export default class CarouselTaskRenderer extends BaseRenderer {
  constructor(eventBus, bpmnRenderer) {
    super(eventBus, HIGH_PRIORITY);

    this.bpmnRenderer = bpmnRenderer;
    // this.modeling = bpmnRenderer.get('modeling');
    this.elementWidth = 160;
    this.currentZoomLevel = 0;
  }

  canRender(element) {
    // console.log(
    //   'In carousel render: ',
    //   is(element, 'bpmn:ReceiveTask'),
    //   element.businessObject.subType === 'carousel-task'
    // );
    return is(element, 'bpmn:ReceiveTask') && element.businessObject.subType === 'carousel-task';
  }

  svg_textMultiline(id, maxWidth, zoom) {
    var x = 10;
    var y = 0;
    var dy = 15;

    /* get the text */
    var element = document.getElementById(id);

    var text = element.innerHTML;

    var words = text.split(' ');
    var line = '';

    var constructedText = '';

    for (var n = 0; n < text.length; n++) {
      element.innerHTML = '<tspan id="PROCESSING">busy</tspan >';

      var newTextLine = line + text[n];

      const newTextElem = document.getElementById('PROCESSING');

      newTextElem.innerHTML = newTextLine;
      var metrics = newTextElem.getBoundingClientRect();
      var newTextWidth = metrics.width;

      if (newTextWidth > maxWidth - 2 * x * (zoom ? zoom : 1)) {
        if ((constructedText.match(/<tspan/g) || []).length > 0) {
          constructedText += '<tspan x="' + x + '" dy="' + dy + '">' + line + '</tspan>';
        } else {
          constructedText += '<tspan x="' + x + '" dy="' + y + '">' + line + '</tspan>';
        }
        line = text[n];
      } else {
        line = newTextLine;
      }

      document.getElementById('PROCESSING').remove();
    }
    if ((constructedText.match(/<tspan/g) || []).length > 0) {
      constructedText += '<tspan x="' + x + '" dy="' + dy + '">' + line + '</tspan>';
    } else {
      constructedText += '<tspan x="' + x + '" dy="' + y + '">' + line + '</tspan>';
    }

    element.innerHTML = constructedText;
  }

  // Main function to draw custom shape

  drawShape(parentNode, element) {
    const parentNodeBounds = parentNode.getBoundingClientRect();
    let element_id = `${element.id}${Math.floor(Math.random() * 10000)}`;

    element.width = 160;

    let shapeProcessing = this.bpmnRenderer.drawShape(parentNode, element);

    this.lastActualWidth = shapeProcessing.getBoundingClientRect().width;

    const newLabelText = svgCreate('text');
    svgAttr(newLabelText, {
      class: 'djs-label-small',
      x: 10,
      y: 20,
    });

    newLabelText.appendChild(document.createTextNode('title'));

    parentNode.appendChild(newLabelText);

    const newText = svgCreate('text');
    svgAttr(newText, {
      id: `TableTextFor${element_id}`,
      class: 'djs-label',
      x: 10,
      y: 35,
    });

    newText.appendChild(document.createTextNode(element.businessObject.title || 'No title'));

    parentNode.appendChild(newText);

    let zoom;
    if (shapeProcessing.getBoundingClientRect().width) {
      zoom = (shapeProcessing.getBoundingClientRect().width / this.elementWidth).toFixed(4);
    } else {
      zoom = 1;
    }

    let InputClientRect;
    try {
      this.svg_textMultiline(`TableTextFor${element_id}`, this.lastActualWidth, zoom);

      InputClientRect = document.getElementById(`TableTextFor${element_id}`).getBoundingClientRect();
    } catch (e) {
      InputClientRect = {height: 15};
    }

    let oldInputHeight = element.height;

    const newLabelText3 = svgCreate('text');
    svgAttr(newLabelText3, {
      class: 'djs-label-small',
      x: 10,
      y: 40 + (InputClientRect.height || 15) * (1 / zoom), // + ChoicesClientRect.height
    });

    newLabelText3.appendChild(document.createTextNode('data'));

    parentNode.appendChild(newLabelText3);

    const newText3 = svgCreate('text');
    svgAttr(newText3, {
      id: `DataFor${element_id}`,
      class: 'djs-label',
      x: 10,
      y: 55 + (InputClientRect.height || 15) * (1 / zoom), // + ChoicesClientRect.height
    });

    newText3.appendChild(document.createTextNode(element.businessObject.data || 'No data'));

    parentNode.appendChild(newText3);

    let DataClientRect;
    try {
      this.svg_textMultiline(`DataFor${element_id}`, this.lastActualWidth, zoom);

      DataClientRect = document.getElementById(`DataFor${element_id}`).getBoundingClientRect();
    } catch (e) {
      DataClientRect = {height: 15};
    }

    let newHeight = Math.round(
      InputClientRect.height + +DataClientRect.height + 60 * (zoom ? zoom : 1) > 90 * (zoom ? zoom : 1) //  ChoicesClientRect.height
        ? InputClientRect.height + DataClientRect.height + 60 * (zoom ? zoom : 1) //  ChoicesClientRect.height
        : 90 * (zoom ? zoom : 1)
    );

    newHeight = zoom === 1 ? newHeight : newHeight * (1 / zoom);

    svgAttr(shapeProcessing, {
      height: newHeight,
    });

    element.width = this.elementWidth;
    element.height = newHeight;
    element.y = element.y - (newHeight / 2 - oldInputHeight / 2);

    let shape = this.bpmnRenderer.drawShape(parentNode, element);

    svgAttr(parentNode.childNodes[7], {
      fillOpacity: 0,
      strokeWidth: 3,
      stroke: '#BAFF29',
      class: 'djs-rect',
    });

    svgRemove(parentNode.childNodes[0]);

    drawRect(parentNode);

    this.currentZoomLevel = zoom;

    return shape;
  }
}
CarouselTaskRenderer.$inject = ['eventBus', 'bpmnRenderer'];

// helpers //////////

// copied from https://github.com/bpmn-io/bpmn-js/blob/master/lib/draw/BpmnRenderer.js
function drawRect(parentNode, width, height, borderRadius, strokeColor) {
  const g = svgCreate('g');

  svgAttr(g, {
    transform: 'translate(-11, -11)',
    fill: 'white',
  });

  const rect = '<rect width="24" height="24" fill="white"></rect>';

  const iconContent =
    '<rect x="8" y="6" width="8" height="12" stroke="#002348" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>\n' +
    '    <path d="M6 7H5V17H5.83333" stroke="#002348" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>\n' +
    '    <path d="M3 8H2V16H2.83333" stroke="#002348" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>\n' +
    '    <path d="M18 17L19 17L19 7L18.1667 7" stroke="#002348" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>\n' +
    '    <path d="M21 16L22 16L22 8L21.1667 8" stroke="#002348" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>\n';
  svgInnerAppend(g, rect + iconContent);

  svgAppend(parentNode, g);

  return g;
}

// copied from https://github.com/bpmn-io/diagram-js/blob/master/lib/core/GraphicsFactory.js
function prependTo(newNode, parentNode, siblingNode) {
  parentNode.insertBefore(newNode, siblingNode || parentNode.firstChild);
}
