import React from 'react';
import { connect } from 'react-redux';
import uuid from 'react-uuid';
import './LabelsTable.scss';
import labelsActions from '../../../action-creators/labelsActions';

import icon1 from './img/icon1.png';
import icon2 from './img/icon2.png';
import iconMinus from './img/minus.png';
import iconPositive from './img/positive.png';
import iconNegative from './img/negative.png';

const LabelsTable = ({ tableContent, labels, currentLabelId, updateCurrentLabelId }) => {
  const extraInfo = [
    {
      statisticsColor: 'green-border',
      numbers: '50/55',
      numbersColor: 'green',
      accuracy: '97%',
      accuracyColor: 'green',
      fcr: '92%',
      fcrColor: 'green',
      volume: '1.7%',
      volumeColor: 'black',
      sfm: 'v15 live',
      sfmd: 'v15 dev',
      owner: icon1,
      review: iconPositive,
    },
    {
      statisticsColor: 'red-border',
      numbers: '0/20',
      numbersColor: 'red',
      sfm: '',
      sfmd: '',
      owner: icon1,
      review: iconMinus,
    },
    {
      statisticsColor: 'darkGreen-border',
      numbers: '35/35',
      accuracy: '85%',
      accuracyColor: 'green',
      fcr: '85%',
      fcrColor: 'green',
      numbersColor: 'darkGreen',
      sfm: 'v9 live',
      sfmd: 'v10 dev',
      volume: '1.1%',
      owner: icon1,
      review: iconPositive,
    },
    {
      statisticsColor: 'orange-border',
      numbers: '10/30',
      numbersColor: 'orange',
      accuracy: '73%',
      accuracyColor: 'orange',
      volume: '0.8%',
      volumeColor: 'black',
      sfm: 'v17 live',
      sfmd: 'v20 dev',
      owner: icon2,
      review: iconNegative,
    },
    {
      statisticsColor: 'darkGreen-border',
      numbers: '45/45',
      accuracy: '87%',
      accuracyColor: 'green',
      fcr: '75%',
      fcrColor: 'orange',
      volume: '2.3%',
      numbersColor: 'darkGreen',
      sfm: 'v19 live',
      sfmd: 'v22 dev',
      owner: icon1,
      review: iconPositive,
    },
    {
      statisticsColor: 'orange-border',
      numbers: '20/40',
      accuracy: '79%',
      accuracyColor: 'orange',
      numbersColor: 'orange',
      fcr: '36%',
      fcrColor: 'red',
      volume: '0.5%',
      sfm: 'v30 live',
      sfmd: 'v30 dev',
      owner: icon1,
      review: iconPositive,
    },
    {
      statisticsColor: 'darkGreen-border',
      numbers: '50/50',
      numbersColor: 'darkGreen',
      accuracy: '91%',
      accuracyColor: 'green',
      fcr: '72%',
      fcrColor: 'orange',
      volume: '0.4%',
      volumeColor: 'black',
      sfm: 'v40 live',
      sfmd: 'v40 dev',
      owner: icon1,
      review: iconNegative,
    },
    {
      statisticsColor: 'red-border',
      numbers: '0/20',
      numbersColor: 'red',
      sfm: 'v22 live',
      sfmd: 'v22 dev',
      owner: icon2,
      review: iconPositive,
    },
    {
      statisticsColor: 'darkGreen-border',
      numbers: '35/35',
      numbersColor: 'darkGreen',
      sfm: 'lala',
      owner: icon1,
      review: iconPositive,
    },
  ];

  return (
    <>
      {labels ? (
        <table id="labels-table">
          <thead id="labels-table-header">
            <tr>
              {tableContent.map(tableContentItem => (
                <th key={uuid()}>
                  <p className="labels-table-header-item">{tableContentItem.label}</p>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {labels.map((label, labelIndex) => (
              <tr
                key={uuid()}
                className={`labels-table-item-container 
                                                ${currentLabelId === label.id ? 'selected' : ''}`}
                onClick={() => (currentLabelId !== label.id ? updateCurrentLabelId(label.id) : '')}
              >
                {tableContent.map(tableContentItem => (
                  <td style={{ width: '20%' }} key={uuid()}>
                    {(() => {
                      switch (tableContentItem.key) {
                        case 'name':
                          return (
                            <p className="labels-table-item">
                              {label[tableContentItem.key]}

                              <span className="labels-table-item__tip">
                                Customer has lost the puk <code className=""> </code>
                              </span>
                            </p>
                          );
                        case 'labels':
                          return (
                            <div className={`labels-statistics ${extraInfo[labelIndex]['statisticsColor']}`}>
                              <p className={`labels-statistics-number ${extraInfo[labelIndex]['numbersColor']}`}>
                                {extraInfo[labelIndex]['numbers']}
                              </p>
                            </div>
                          );
                        case 'sfm':
                          return (
                            <p className={`labels-table-item`}>
                              <div style={{ color: 'green' }}>{extraInfo[labelIndex]['sfm']}</div>
                              <div style={{ color: 'black' }}>{extraInfo[labelIndex]['sfmd']}</div>
                            </p>
                          );
                        case 'accuracy':
                          return (
                            <p className={`labels-table-item ${extraInfo[labelIndex]['accuracyColor']}`}>
                              {extraInfo[labelIndex]['accuracy']}
                            </p>
                          );
                        case 'fcr':
                          return (
                            <p className={`labels-table-item ${extraInfo[labelIndex]['fcrColor']}`}>
                              {extraInfo[labelIndex]['fcr']}
                            </p>
                          );
                        case 'volume':
                          return (
                            <p className={`labels-table-item ${extraInfo[labelIndex]['volumeColor']}`}>
                              {extraInfo[labelIndex]['volume']}
                            </p>
                          );
                        case 'owner':
                          return (
                            <p className={`labels-table-item`}>
                              <img src={extraInfo[labelIndex]['owner']} alt="" />
                            </p>
                          );
                        case 'solutionReview':
                          return (
                            <p className={`labels-table-item`}>
                              <img src={extraInfo[labelIndex]['review']} alt="" />
                            </p>
                          );
                        default:
                          return (
                            <div className="empty">
                              <div className="empty-content"></div>
                            </div>
                          );
                      }
                    })()}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      ) : labels === undefined ? (
        'Loading...'
      ) : (
        'No labels found'
      )}
    </>
  );
};

export const mapStateToProps = state => {
  return {
    tableContent: state.labels.tableContent,
    labels: state.labels.labels,
    currentLabelId: state.labels.currentLabelId,
  };
};

const mapDispatchToProps = {
  updateCurrentLabelId: labelsActions.updateCurrentLabelId,
};

export default connect(mapStateToProps, mapDispatchToProps)(LabelsTable);
