import React from "react";
import { useVersionHover } from "./useVersionHover";
import * as colors from "../../../styles/colors";
import * as mixins from "../../../styles/mixins";
import * as typography from "../../../styles/typography";
import styled from 'styled-components';


const DiagramVersionComment = styled.div`
  display: block;
  position: fixed;
  z-index: 1000;
  padding: 5px 25px 15px;
  max-width: 250px;
  box-shadow: 0 16px 40px rgba(0, 51, 167, 0.15);
  background-color: ${colors.tooltipBackgroundColor};

  & p {
    margin-top: 10px;
    color: ${colors.mainFontColor};
    ${mixins.fontStyle(typography.labelFontSize, typography.normal, typography.smallLineHeight)};
  }

  & p span {
    ${mixins.fontStyle(typography.labelFontSize, typography.semibold, typography.smallLineHeight)};
  }
  
  
`;


export const DiagramVersionHover = () => {
  const { element, version } = useVersionHover();
  if (!version || !element) {
    return <></>
  }

  const bound = element.getBoundingClientRect();
  return <DiagramVersionComment style={{ top: bound.top + bound.height, left: bound.left }}>
    <p>
      label: <span>{version.label}</span>
    </p>
    <p>
      comment: <span>{version.comment}</span>
    </p>
    <p>
      created by: <span>{version.createdBy.firstName} {version.createdBy.lastName}</span>
    </p>
    <p>
      created: <span>{version.createdDate}</span>
    </p>
    <p>
      updated: <span>{version.updatedDate}</span>
    </p>
  </DiagramVersionComment>
}
