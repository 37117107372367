import React from "react";
import { connect } from 'react-redux';
import { Redirect } from "react-router-dom";


export const withRoles = (roles, Component) => {
  const mapStateToProps = state => {
    return { user: state.auth.user };
  };

  return connect(mapStateToProps)((props) => {
    const user = props.user;

    if (process.env.REQUIRE_AUTH !== 'false' && !roles.includes(user.role))
      return <Redirect to="/chat" />;

    return <Component {...props} />;
  });
}