import BaseRenderer from 'diagram-js/lib/draw/BaseRenderer';

import {
  append as svgAppend,
  attr as svgAttr,
  classes as svgClasses,
  create as svgCreate,
  remove as svgRemove,
  innerSVG as svgInnerAppend,
} from 'tiny-svg';

import { getRoundRectPath } from 'bpmn-js/lib/draw/BpmnRenderUtil';

import { is } from 'bpmn-js/lib/util/ModelUtil';
import { isAny } from 'bpmn-js/lib/features/modeling/util/ModelingUtil';

import modeling from 'bpmn-js/lib/features/modeling';
import appReducer from '../../../reducers/appReducer';

const HIGH_PRIORITY = 1500;

export default class EmptyTaskRenderer extends BaseRenderer {
  constructor(eventBus, bpmnRenderer) {
    super(eventBus, HIGH_PRIORITY);

    this.bpmnRenderer = bpmnRenderer;
  }

  canRender(element) {
    return is(element, 'bpmn:Task') && !element.businessObject.subType;
  }

  drawShape(parentNode, element) {
    let shape = this.bpmnRenderer.drawShape(parentNode, element);

    svgAttr(parentNode.childNodes[0], {
      fillOpacity: 0,
      strokeWidth: 3,
      stroke: '#C4C4C4',
      class: 'djs-rect',
    });

    drawRect(parentNode);

    return shape;
  }
}

EmptyTaskRenderer.$inject = ['eventBus', 'bpmnRenderer'];

// helpers //////////

// copied from https://github.com/bpmn-io/bpmn-js/blob/master/lib/draw/BpmnRenderer.js
function drawRect(parentNode, width, height, borderRadius, strokeColor) {
  const g = svgCreate('g');

  svgAttr(g, {
    transform: 'translate(-11, -11)',
    fill: 'white',
  });

  const rect = '<rect width="24" height="24" fill="white"></rect>';

  const iconContent =
    '<path d="M19 3H5C3.89543 3 3 3.89543 3 5V19C3 20.1046 3.89543 21 5 21H19C20.1046 21 21 20.1046 21 19V5C21 3.89543 20.1046 3 19 3Z" stroke="#000F3C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>';
  svgInnerAppend(g, rect + iconContent);

  svgAppend(parentNode, g);

  return g;
}

// copied from https://github.com/bpmn-io/diagram-js/blob/master/lib/core/GraphicsFactory.js
function prependTo(newNode, parentNode, siblingNode) {
  parentNode.insertBefore(newNode, siblingNode || parentNode.firstChild);
}
