import React, { useState, useMemo } from "react";
import { Select } from "antd";
import { supportedLanguagesWithFlags } from "../../app/constants/languagesWithFlags";

export const LanguageSelect = ({
  value,
  disabled,
  onValueChange,
}) => {

  const [isOpen, setIsOpen] = useState(false);
  const label = useMemo(() => {
    if (!value) {
      return '';
    }

    return supportedLanguagesWithFlags
      .find((l) => l.key === value)
      ?.flag || '';
  }, [value]);

  const getMinWidth = () => {
    if (isOpen) {
      return 100;
    }

    return 70;
  }

  return <Select
    size="large"
    showAction="focus"
    style={{
      minWidth: getMinWidth(),
      fontSize: value ? 26 : 'unset'
    }}
    disabled={disabled}
    value={disabled ? '' : label}
    onDropdownVisibleChange={setIsOpen}
    onChange={(newValue) => {
      onValueChange?.(newValue)
    }}
    allowClear={!!value}
  >
    {
      supportedLanguagesWithFlags.map(option => (
        <Select.Option key={option.key} value={option.key} label={option.flag}>
          {option.lang} {option.flag}
        </Select.Option>
      ))
    }
  </Select>
}