import initialModalsState from '../constants/initial-state-parts/initialModalsState';

const modalsReducer = (state = initialModalsState, action) => {
  switch (action.type) {
    case 'TOGGLE_MODAL':
      return Object.assign({}, state, {
        [action.modalName]: Object.assign({}, state[action.modalName], {
          isOpen: !state[action.modalName].isOpen,
          args: action.args || {},
        }),
        modalError: undefined,
      });
    case 'CHANGE_MODAL_ERROR':
      return Object.assign({}, state, {
        modalError: action.modalError,
      });
    default:
      return state;
  }
};

export default modalsReducer;
