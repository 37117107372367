import { put, call, all, take, takeLatest, spawn } from 'redux-saga/effects';
import modalsActions from '../action-creators/modalsActions';
import NotificationService from '../services/common/NotificationService';

function* proceedOnModalConfirmFlow(action) {
  try {
    yield put(action.actionOnConfirm(action.argsOnConfirm));
    yield put(modalsActions.closeModal(action.modalKey));
  } catch (error) {
    NotificationService.push({
      type: 'error',
      message: 'Error occurred in modal confirmation proceed action. Please contact support!',
    });
  }
}

function* watchProceedOnModalConfirmRequested() {
  yield takeLatest('PROCEED_ON_MODAL_CONFIRM', proceedOnModalConfirmFlow);
}

function* proceedOnModalCancelFlow(action) {
  try {
    yield put(action.actionOnCancel(action.argsOnCancel));
    yield put(modalsActions.closeModal(action.modalKey));
  } catch (error) {
    NotificationService.push({
      type: 'error',
      message: 'Error occurred in modal canceling proceed action. Please contact support!',
    });
  }
}

function* watchProceedOnModalCancelRequested() {
  yield takeLatest('PROCEED_ON_MODAL_CANCEL', proceedOnModalCancelFlow);
}

export default function* modalsSaga() {
  yield all([watchProceedOnModalConfirmRequested(), watchProceedOnModalCancelRequested()]);
}
