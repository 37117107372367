import React, { useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import uuid from 'react-uuid';
import './Nav.scss';
import packageJson from '/package.json';

const ENABLE_OVERVIEW = process.env.ENABLE_OVERVIEW || 'true';
const ENABLE_LABELLING = process.env.ENABLE_LABELLING || 'true';
const ENABLE_MANAGEMENT = process.env.ENABLE_MANAGEMENT || 'true';
const ENABLE_SETTINGS = process.env.ENABLE_SETTINGS || 'true';
const ENABLE_STATISTICS = process.env.ENABLE_STATISTICS || 'true';
const ENABLE_SFM = process.env.ENABLE_SFM || 'true';

const Nav = ({ user, navigationLinks }) => {
  const location = useLocation();

  let navLinks = navigationLinks;
  if (ENABLE_LABELLING === 'false') {
    navLinks = navLinks.filter(link => link.group !== 'labelling');
  }
  if (ENABLE_OVERVIEW === 'false') {
    navLinks = navLinks.filter(link => link.group !== 'main');
  }
  if (ENABLE_SFM === 'false') {
    navLinks = navLinks.filter(link => link.group !== 'sfm');
  }
  if (ENABLE_MANAGEMENT === 'false') {
    navLinks = navLinks.filter(link => link.group !== 'management');
  }
  if (ENABLE_SETTINGS === 'false') {
    navLinks = navLinks.filter(link => link.group !== 'settings');
  }
  if (ENABLE_STATISTICS === 'false') {
    navLinks = navLinks.filter(link => link.group !== 'statistics');
  }

  return (
    <div id="nav">
      {navLinks
        .filter(navItem => navItem.roles(user))
        .map(navItem => (
          <Link key={navItem.link} style={{textDecoration: 'none'}} to={navItem.link}>
            <div key={uuid()} className={`nav-item ${navItem.link === location.pathname ? 'current' : ''}`}>
              <div className="arrow">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M15 18L21 12L15 6" stroke="#E61A3C" strokeWidth="1.5" strokeLinecap="round" />
                  <path d="M3 12H21" stroke="#E61A3C" strokeWidth="1.5" strokeLinecap="round" />
                </svg>
              </div>
              <p>{navItem.label}</p>
            </div>
          </Link>
        ))}
      <div style={{ position: 'absolute', bottom: 10, marginLeft: 5, fontSize: 10, color: '#F2F2F2' }}>
        version {packageJson.version}
      </div>
    </div>
  );
};

export const mapStateToProps = state => {
  return {
    navigationLinks: state.nav.links,
    user: state.auth.user,
  };
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Nav);
