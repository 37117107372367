import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { getStore } from './Store';

const User = props => {
  const store = getStore(props.mode);
  const expandChat = () => {
    store.dispatch({
      type: 'CHAT_SELECTED',
      userId: props.userId,
      chatId: props.chatId,
      supportPerson: props.loggedInUser,
      solutionChosen: props.solutionChosen,
    });
  };

  const markResolved = event => {
    event.stopPropagation();
    store.dispatch({ type: 'RESOLVE_TOGGLED', userId: props.userId, chatId: props.chatId });
    if (!props.is_open) {
      store.dispatch({ type: 'CHAT_CLOSED', userId: props.userId, chatId: props.chatId });
    }
  };

  const removeChat = event => {
    event.stopPropagation();
    store.dispatch({ type: 'CHAT_CLOSED', userId: props.userId, chatId: props.chatId });
    if (props.resolved) {
      store.dispatch({ type: 'RESOLVE_TOGGLED', userId: props.userId, chatId: props.chatId });
    }
  };
  let mainClass = props.resolved ? 'row sideBar-body ' : 'row sideBar-body ' + ' resolved';
  mainClass = props.is_open ? mainClass : mainClass + ' closed';
  if (props.currentChatId === props.chatId) mainClass += ' selected';
  let subClass = 'customer_segment customer_segment_' + (props.customer_segment || 'not_specified');
  let divStyle = { backgroundColor: props.urgencyColor };
  const renderGroups = props.groups.length > 0;
  return (
    <div style={props.style}>
      <div className={mainClass} onClick={expandChat} style={divStyle}>
        <div className="sideBar-main">
          <span className="line-left" />
          <div style={{ display: 'flex', flexWrap: 'wrap', height: 25 }} className="row">
            <div style={{ width: '100%' }}>
              {
                renderGroups ? <span className={subClass}>[{ props.groups.join(', ') }]</span> : ''
              }
              <span className="name-meta">{props.userName === 'test_account' ? 'John Smith' : props.userName}</span>
            </div>
            <div style={{ marginLeft: 'auto' }} className="pull-right buttonGroup">
              <div className="" onClick={removeChat}>
                <i className="fa fa-close pull-left" aria-hidden="true" />
              </div>
              <div className="" onClick={markResolved}>
                <i className="fa fa-check fa-2x  pull-right" aria-hidden="true" />
              </div>
            </div>
          </div>

          <div className="row meta">
            <div className="keep-left latest-message">
              <span>
                {props.latestMessage.startsWith('Elisa:')
                  ? props.latestMessage.replace('Elisa:', 'Support:')
                  : props.latestMessage}
              </span>
            </div>
            <div className="keep-left latest-message-time">
              <span>{moment(props.latestTime * 1000).format('DD/MM/YY - HH:mm')}</span>
              {props.supportPerson ? <span>{` - ${props.supportPerson}`}</span> : ''}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default React.memo(User);
