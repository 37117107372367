import React from 'react';
import { ComposedChart, Line, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import moment from 'moment';

const titles = ['Correct Label', 'Incorrect label', 'Percent'];
const Resolution = ({ metrics }) => {
  return (
    <ResponsiveContainer width="100%" height="100%">
      <ComposedChart
        height={350}
        data={metrics}
        margin={{
          top: 20,
          right: 20,
          bottom: 20,
          left: 20,
        }}
      >
        <CartesianGrid stroke="#f5f5f5" />
        <XAxis dataKey="dt" name="Time" tickFormatter={unixTime => moment(unixTime).format('MMMM YYYY')} />
        <YAxis stroke="#2A3254" yAxisId="left" />
        <Bar yAxisId="left" name={titles[0]} dataKey="tp" barSize={20} fill="#283257" />
        <Bar yAxisId="left" name={titles[1]} dataKey="fp" barSize={20} fill="#D43643" />
        <YAxis stroke="#D43643" yAxisId="right" orientation="right" domain={[0, 100]} />
        <Line yAxisId="right" type="monotone" name={titles[2]} dataKey="prec" stroke="#283257" />
        <Legend />
        <Tooltip labelFormatter={unixTime => moment(unixTime).format('MMMM YYYY')} />
      </ComposedChart>
    </ResponsiveContainer>
  );
};

export default Resolution;
