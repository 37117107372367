import React from "react";
import SmallCloseIcon from "../../icons/tools/SmallCloseIcon";
import styled from "styled-components";
import * as colors from "../../../../styles/colors";
import * as mixins from "../../../../styles/mixins";
import * as typography from "../../../../styles/typography";



const ElementPropOwnersSelectContainer = styled.div`
  &.disabled {
    pointer-events: none;
    opacity: 0.9;
  }
`;

const ElementPropOwnersSelect = styled.div`
  ${mixins.flex('flex-start', 'flex-start', 'row', 'wrap')};
`;

const ElementOwner = styled.div`
  ${mixins.flex('flex-start')};
`;

const ElementOwnerName = styled.p`
  color: ${colors.mainFontColor};
  ${mixins.fontStyle(typography.textFontSize, typography.normal)};
`;

const ElementOwnerDeleteButton = styled.div`
  margin: 0 4px;
  padding-top: 2px;

  cursor: pointer;

  &:hover {
    & svg path {
      stroke: ${colors.alternativeFontColor};
    }
  }
`;

const ElementOwnersSearch = styled.div`
  ${mixins.flex('space-between', 'center')};
`;

const ElementOwnersAddButton = styled.p`
  min-width: 100px;
  box-sizing: border-box;
  padding-top: 4px;
  min-height: 32px;
  color: ${colors.mainFontColor};
  ${mixins.fontStyle(typography.textFontSize, typography.semibold)};
  cursor: pointer;

  &:hover {
    color: ${colors.alternativeFontColor};
  }
`;

const OwnersSearchContent = styled.div`
  position: relative;
  width: 100%;
`;

const OwnersList = styled.div`
  position: absolute;
  max-height: 132px;
  box-sizing: border-box;
  overflow-y: scroll;
  padding: 8px 0;
  top: 100%;
  right: 0;
  z-index: 1000;
  border: 1px solid ${colors.darkGrey};
  background-color: ${colors.white};
`;

const OwnersSearchInput = styled.input`
  width: 100%;
  box-sizing: border-box;
  padding: 4px 6px;
  background: ${colors.extraBackgroundColor};
  color: ${colors.mainFontColor};
  ${mixins.fontStyle(typography.textFontSize, typography.semibold)};
`;

const OwnerName = styled.p`
  color: ${colors.mainFontColor};
  ${mixins.fontStyle(typography.textFontSize, typography.normal)};
`;

const OwnerChoice = styled(OwnerName)`
  box-sizing: border-box;
  padding: 0 12px;
  cursor: pointer;

  &:hover {
    background-color: ${colors.componentBackgroundColor};
  }
`;

function getExtensionElement(element, type) {
  if (!element.extensionElements) {
    return;
  }

  return element.extensionElements.values.filter(extensionElement => {
    return extensionElement.$instanceOf(type);
  })[0];
}

export const Owners = ({
  owners, moddle, currentElement , changeIsOpen, isOpen, updateOwnersSearchString, updateOwnersList, modeling,
  extensionElements, ownersSearchString, fetchUsersList, isBlocked
}) => {
  const addOwner = id => {
    const newOwnerData = owners.filter(owner => owner.id === id)[0];
    const newOwner = moddle.create('chatbot:Owner', {
      id: newOwnerData.id,
      firstName: newOwnerData.firstName || newOwnerData.first_name,
      lastName: newOwnerData.lastName || newOwnerData.last_name,
      role: newOwnerData.role,
    });
    let newExtensionElements;
    if (currentElement.businessObject.extensionElements) {
      newExtensionElements = currentElement.businessObject.extensionElements;
    } else {
      newExtensionElements = moddle.create('bpmn:ExtensionElements');
    }

    let oneOwners = getExtensionElement(currentElement.businessObject, 'chatbot:Owners');

    if (!oneOwners) {
      oneOwners = moddle.create('chatbot:Owners');
      oneOwners.values = [newOwner];
    } else {
      oneOwners.values.push(newOwner);
    }
    newExtensionElements.get('values').pop();
    newExtensionElements.get('values').push(oneOwners);

    modeling.updateProperties(currentElement, {
      extensionElements: newExtensionElements,
    });
    changeIsOpen(!isOpen);
    updateOwnersSearchString('');
    updateOwnersList([]);
  };
  const removeOwner = id => {
    let newExtensionElements = currentElement.businessObject.extensionElements;
    let oneOwners = getExtensionElement(currentElement.businessObject, 'chatbot:Owners');
    oneOwners.values = extensionElements[0].values.filter(value => value.id !== id);
    newExtensionElements.get('values').pop();
    newExtensionElements.get('values').push(oneOwners);
    modeling.updateProperties(currentElement, {
      extensionElements: newExtensionElements,
    });
  };

  return (
    <ElementPropOwnersSelectContainer className={`${isBlocked ? 'disabled' : ''}`}>
      <ElementPropOwnersSelect>
        {extensionElements[0] &&
          extensionElements[0].values?.map((currentOwner, index) => {
            return (
              <ElementOwner key={index}>
                <ElementOwnerName>{currentOwner.firstName + ' ' + currentOwner.lastName}</ElementOwnerName>
                <ElementOwnerDeleteButton
                  onClick={() => {
                    removeOwner(currentOwner.id);
                  }}
                >
                  <SmallCloseIcon color={colors.mainIconColor}/>
                </ElementOwnerDeleteButton>
              </ElementOwner>
            );
          })}
      </ElementPropOwnersSelect>
      <ElementOwnersSearch>
        <ElementOwnersAddButton
          onClick={() => {
            changeIsOpen(!isOpen);
            updateOwnersSearchString('');
            updateOwnersList([]);
          }}
        >
          {isOpen ? 'Cancel' : 'Add more +'}
        </ElementOwnersAddButton>
        {isOpen ? (
          <OwnersSearchContent>
            <OwnersSearchInput
              id="node-owners-search-input"
              value={ownersSearchString}
              onChange={event => {
                updateOwnersSearchString(event.target.value);
                if (event.target.value.length > 2) {
                  fetchUsersList(event.target.value);
                }
              }}
              placeholder="Type owners name"
              autoFocus={true}
            />
            {owners && owners.length > 0 && ownersSearchString ? (
              <OwnersList>
                {owners.map((owner, index) => (
                  <OwnerChoice key={index} onClick={() => addOwner(owner.id)}>
                    {(owner.firstName || owner.first_name) + ' ' + (owner.lastName || owner.last_name)}
                  </OwnerChoice>
                ))}
              </OwnersList>
            ) : (
              ''
            )}
          </OwnersSearchContent>
        ) : (
          ''
        )}
      </ElementOwnersSearch>
    </ElementPropOwnersSelectContainer>
  );
}