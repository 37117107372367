import React, { useState } from 'react';
import './LabellingHeader.scss';
import MultiOption from '../../common/multi-option/MultiOption';
import '../../common/multi-option/MultiOption.scss';
import ArrowIcon from '../../icons/Arrow';
import { Radio, Select, Switch, Button } from 'antd';
import { SUPPORTED_LANGUAGES } from '../../settings/Settings';
const options = [
  { label: 'Uncertain labels', value: 'uncertain_labels' },
  { label: 'My labels', value: 'my_labels' },
];
const ENABLE_ACTIVE_LEARNING = process.env.ENABLE_ACTIVE_LEARNING || 'true';
const ENABLE_CS_ASSIST = process.env.ENABLE_CS_ASSIST || 'false';

const LabellingHeader = ({
  onlyPagination,
  totalCount,
  limit,
  labels,
  setLimit,
  page,
  setPage,
  labelMode,
  setLabelMode,
  selectedLabel,
  setSelectedLabel,
  initialPromptLabelling,
  setInitialPromptLabelling,
  addLabelsModal,
  showLabelled,
  setShowLabelled,
  currentCount,
  selectedLanguage,
  setSelectedLanguage,
  languages = [
    { flag: '🇬🇧', lang: 'ENG', key: 'en' },
    { flag: '🇪🇪', lang: 'EST', key: 'et' },
    { flag: '🇷🇺', lang: 'RUS', key: 'ru' },
    { flag: '🇩🇪', lang: 'GER', key: 'de' },
    { flag: '🇸🇦', lang: 'ARA', key: 'arb' },
    { flag: '🇵🇱', lang: 'POL', key: 'pl' },
    { flag: '🇱🇹', lang: 'LTU', key: 'lt' },
    { flag: '🇱🇻', lang: 'LVA', key: 'lv' },
  ],
}) => {
  const [searchLabel, setSearchLabel] = useState('');
  const canLoadPreviousPage = page > 1;
  const canLoadNextPage = (page - 1) * limit + currentCount < totalCount;
  const previousPage = () => (canLoadPreviousPage ? setPage(page - 1) : null);
  const nextPage = () => (canLoadNextPage ? setPage(page + 1) : null);
  const getActiveLearningDisplay = () => (ENABLE_ACTIVE_LEARNING === 'false' ? { display: 'none' } : {});

  return (
    <div id="labelling-header">
      {!onlyPagination && (
        <>
          <div id="labelling-selectors" style={{ fontWeight: 'normal', ...getActiveLearningDisplay() }}>
            <Radio.Group
              size={'large'}
              options={
                Array.isArray(labels) && labels.length == 0 ? options.filter(o => o.value !== 'my_labels') : options
              }
              onChange={ev => {
                setSelectedLabel(null);
                setLabelMode(ev.target.value);
              }}
              value={labelMode}
              optionType="button"
            />
            {Array.isArray(labels) && labels.length == 0 ? null : (
              <div dir="auto">
                <Select
                  size={'large'}
                  getPopupContainer={trigger => trigger.parentNode}
                  allowClear
                  showSearch
                  style={{ minWidth: 200, marginLeft: 10, display: labelMode == 'my_labels' ? 'block' : 'none' }}
                  placeholder="Choose Label"
                  loading={!labels}
                  disabled={!labels}
                  filterOption={(input, option) =>
                    option.props.key.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  value={selectedLabel?.normalize('NFKD')}
                  onChange={setSelectedLabel}
                  onSearch={value => setSearchLabel(value?.normalize('NFKD'))}
                  searchValue={searchLabel}
                >
                  {Array.isArray(labels) &&
                    labels.length > 0 &&
                    labels.map(option => (
                      <Option key={option?.id?.toString()} value={option?.id?.toString()}>
                        {option.name}
                      </Option>
                    ))}
                </Select>
              </div>
            )}
            <Button onClick={addLabelsModal} style={{ margin: 10 }} size={'large'}>
              Add Manually
            </Button>
            {ENABLE_CS_ASSIST == 'true' ? (
              <>
                <Switch checked={initialPromptLabelling} onChange={setInitialPromptLabelling} style={{ margin: 10 }} />{' '}
                Initial prompt labelling{' '}
              </>
            ) : null}
          </div>
          {ENABLE_ACTIVE_LEARNING == 'false' ? (
            <Button onClick={addLabelsModal} style={{ margin: 10 }} size={'large'}>
              Add Manually
            </Button>
          ) : null}
          <div style={{ marginRight: 15, marginLeft: 15, display: 'flex', flexDirection: 'row' }}>
            <div style={{ position: 'absolute', bottom: 82, marginLeft: 10 }}>Select language</div>
            <Select
              size={'large'}
              getPopupContainer={trigger => trigger.parentNode}
              style={{ minWidth: 100, marginLeft: 10, fontSize: selectedLanguage ? 22 : 'unset' }}
              placeholder="Choose Language"
              filterOption={(input, option) =>
                option.props.key.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              allowClear={selectedLanguage !== null}
              value={selectedLanguage}
              onChange={setSelectedLanguage}
              optionLabelProp="label"
            >
              {Array.isArray(languages) &&
                languages.length > 0 &&
                languages
                  .filter(lang => SUPPORTED_LANGUAGES.includes(lang.key))
                  .map(option => (
                    <Option key={option.key} value={option.key} label={option.flag}>
                      {option.lang} {option.flag}
                    </Option>
                  ))}
            </Select>
          </div>
          <Switch
            checked={showLabelled}
            onChange={setShowLabelled}
            disabled={labelMode !== 'my_labels'}
            style={{ margin: 10 }}
          />
          Show Labelled
        </>
      )}
      <div style={onlyPagination ? { bottom: 30 } : {}} id="labelling-total-info">
        <div id="labelling-total-row">
          <p id="labelling-total-row-label">Rows on page</p>
          <select id="labelling-total-row-select" value={limit} onChange={e => setLimit(e.target.value)}>
            <option value={10}>10</option>
            <option value={50}>50</option>
            <option value={100}>100</option>
            <option value={500}>500</option>
          </select>
        </div>

        <p id="labelling-total-count">
          {(page - 1) * limit + 1} - {(page - 1) * limit + currentCount} total {totalCount}
        </p>

        <div id="labelling-pagination">
          {canLoadPreviousPage ? (
            <div className="pagination-arrow reverse" onClick={() => previousPage()}>
              <ArrowIcon />
            </div>
          ) : null}
          {canLoadNextPage ? (
            <div className="pagination-arrow" onClick={() => nextPage()}>
              <ArrowIcon />
            </div>
          ) : (
            <div className="no-more-conversation"> No more conversations! </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default LabellingHeader;
