import React from 'react';

const CircleLoaderIcon = ({ color, ...props }) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M11 21.9506C5.94668 21.4489 2 17.1853 2 12C2 6.47715 6.47715 2 12 2C14.7974 2 17.3265 3.14864 19.1414 5C20.6907 6.58039 21.7196 8.67285 21.9506 11"
        stroke={color}
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default CircleLoaderIcon;
