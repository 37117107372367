import responseService from "./common/ResponseService";
import crudService from "./common/CrudService";

const API_BASE_URL = process.env.SFM_API_URL || 'http://127.0.0.1:5000';
const API_PREFIX = process.env.SFM_CLIENT_PREFIX || 'dev-api';

const callbotDbServiceCrudClassifiers = crudService(API_BASE_URL, API_PREFIX, ["callbot-db", "classifiers"]);
const callbotDbServiceCrudValidators = crudService(API_BASE_URL, API_PREFIX, ["callbot-db", "validators"]);

const CallbotService = {
  fetchClassifiers() {
    return responseService.handle(callbotDbServiceCrudClassifiers.list());
  },
  fetchValidators() {
    return responseService.handle(callbotDbServiceCrudValidators.list());
  }
};


export default CallbotService;
