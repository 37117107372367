const updateCurrentSubProcess = (subProcess, businessObject, silently = false) => {
  return {
    type: 'UPDATE_CURRENT_SUB_PROCESS',
    subProcess,
    businessObject,
    silently,
  };
};

const updateCurrentSubProcessDiagramVersions = newVersions => {
  return {
    type: 'UPDATE_CURRENT_SUB_PROCESS_VERSIONS',
    newVersions: newVersions,
  };
};

const changeCurrentSubProcessFile = ({
  diagramVersionId,
  isLatest,
  modeling,
  currentElement,
  isDiagramSelect = false,
  diagramId = undefined,
}) => {
  return {
    type: 'CHANGE_CURRENT_SUB_PROCESS_FILE',
    diagramVersionId: diagramVersionId,
    isLatest: isLatest,
    modeling: modeling,
    currentElement: currentElement,
    isDiagramSelect: isDiagramSelect,
    diagramId: diagramId,
  };
};

const updateCurrentSubProcessFile = file => {
  return {
    type: 'UPDATE_CURRENT_SUB_PROCESS_FILE',
    file: file,
  };
};

const setInProgressDiagramVersionFor = inProgressId => {
  return {
    type: 'SET_IN_PROGRESS_DIAGRAM_VERSION_FOR_PROGRESS_ID',
    inProgressId
  }
}

const currentSubProcessActions = {
  updateCurrentSubProcess,
  updateCurrentSubProcessDiagramVersions,
  changeCurrentSubProcessFile,
  updateCurrentSubProcessFile,
  setInProgressDiagramVersionFor,
};

export default currentSubProcessActions;
