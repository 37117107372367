import { put, call, all, takeEvery, takeLatest, select } from 'redux-saga/effects';
import DiagramsService from '../services/DiagramsService';
import diagramsActions from '../action-creators/diagramsActions';
import searchActions from '../action-creators/searchActions';
import NotificationService from '../services/common/NotificationService';
import panelActions from "../action-creators/pannelActions";

const getDiagramListLoading = state => state.app.sfLoading;

function* diagramsListFlow() {
  const sfLoading = yield select(getDiagramListLoading);
  if (sfLoading) {
    console.log('previous diagram list is not loaded yet. Skipped');
    return;
  }
  yield put(panelActions.setLoadingFlagSF(true));
  try {
    const response = yield call(DiagramsService.getDiagramsList);
    if (response.status === 200) {
      yield put(diagramsActions.updateDiagramsList(response.data));
      // NotificationService.push({
      //     type: 'success',
      //     message: 'Diagram list successfully fetched!'
      // });
    } else {
      NotificationService.push({
        type: 'error',
        message: response.data,
      });
    }
  } catch (error) {
    NotificationService.push({
      type: 'error',
    });
  }
  yield put(panelActions.setLoadingFlagSF(false));
}

function* searchDiagramsFlow({ text }) {
  try {
    const response = yield call(DiagramsService.searchDiagrams, { text });
    if (response.status === 200) {
      yield put(
        searchActions.updateSearchResults({
          newSearchString: text,
          results: response.data,
        })
      );
    } else {
      NotificationService.push({
        type: 'error',
        message: response.data,
      });
    }
  } catch (error) {
    NotificationService.push({
      type: 'error',
    });
  }
}

function* watchDiagramsListRequested() {
  yield takeEvery('FETCH_DIAGRAMS_LIST', diagramsListFlow); // we can't really stop fetch, so do not stop previous tasks
}

function* watchSearchDiagramsRequested() {
  yield takeLatest('SEARCH_DIAGRAMS', searchDiagramsFlow);
}

function* diagramFlow() {
  try {
    const response = yield call(DiagramsService.getDiagram);
    if (response.status === 200) {
      yield put(diagramsActions.updateDiagramsList(response.data));
      // NotificationService.push({
      //     type: 'success',
      //     message: 'Diagram list successfully fetched!'
      // });
    } else {
      NotificationService.push({
        type: 'error',
        message: response.data,
      });
    }
  } catch (error) {
    NotificationService.push({
      type: 'error',
    });
  }
}

function* watchDiagramRequested() {
  yield takeLatest('FETCH_DIAGRAM', diagramFlow);
}

export default function* diagramsSaga() {
  yield all([watchDiagramsListRequested(), watchDiagramRequested(), watchSearchDiagramsRequested()]);
}
