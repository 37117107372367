import React from 'react';
import styled from 'styled-components';
import * as mixins from '../../../../styles/mixins';
import * as colors from '../../../../styles/colors';
import * as typography from '../../../../styles/typography';
import DiagramVersion from '../../../shared/diagram-version/DiagramVersion';

const VersionControlDiagramVersion = ({
  isFirst,
  diagram,
  version,
  isTest,
  isLive,
  canDelete,
  isCommentVisible,
  isCurrent,
  changeCurrentDiagramAndVersion,
  changeExpandedDiagramVersionStatus,
  deleteExpandedDiagramVersion,
}) => {
  const onDiagramVersionClick = event => {
    event.stopPropagation();
    changeCurrentDiagramAndVersion({
      diagram: diagram,
      versionId: version.id,
      shouldSave: false,
    });
  };

  const onChangeVersionStatus = status => event => {
    event.stopPropagation();
    changeExpandedDiagramVersionStatus(status);
  };

  const onDeleteVersionClick = event => {
    event.stopPropagation();
    deleteExpandedDiagramVersion();
  };

  return (
    <DiagramVersion
      isFirst={isFirst}
      version={version}
      isTest={isTest}
      isLive={isLive}
      isCurrent={isCurrent}
      isCommentVisible={isCommentVisible}
      showCreatedBy={true}
      canDelete={canDelete}
      onDeleteVersionClick={onDeleteVersionClick}
      onDiagramVersionClick={onDiagramVersionClick}
      onChangeVersionStatus={onChangeVersionStatus}
    />
  );
};

export default VersionControlDiagramVersion;
