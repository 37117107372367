const updateSearchResults = ({ newSearchString, results }) => {
  return {
    type: 'UPDATE_SEARCH_RESULTS',
    newSearchString,
    results,
  };
};

const searchDiagrams = newSearchString => {
  if (!newSearchString) {
    return {
      type: 'UPDATE_SEARCH_RESULTS',
      newSearchString,
      results: [],
    };
  }
  return {
    type: 'SEARCH_DIAGRAMS',
    text: newSearchString,
  };
};

const searchActions = {
  updateSearchResults,
  searchDiagrams,
};

export default searchActions;
