import React from 'react';

const IdentifierIcon = ({ color }) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M21.59 14.41L14.42 21.58C14.2343 21.766 14.0137 21.9135 13.7709 22.0141C13.5281 22.1148 13.2678 22.1666 13.005 22.1666C12.7422 22.1666 12.4819 22.1148 12.2391 22.0141C11.9963 21.9135 11.7757 21.766 11.59 21.58L3 13V3H13L21.59 11.59C21.9625 11.9647 22.1716 12.4716 22.1716 13C22.1716 13.5284 21.9625 14.0353 21.59 14.41V14.41Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle cx="8.5" cy="8.5" r="1.5" fill={color} />
    </svg>
  );
};

export default IdentifierIcon;
