import React from "react";
import {Input} from "antd";

const MapInputRow = ({mapKey, value, onKeyChange, onValueChange}) => {
  return (
    <tr>
      <td>
        <Input value={mapKey} onChange={onKeyChange} />
      </td>
      <td>
        <Input value={value} onChange={onValueChange} />
      </td>
    </tr>
  )
}

export default MapInputRow;
