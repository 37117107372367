import React from 'react';
import styled from 'styled-components';
import * as mixins from '../../styles/mixins';
import * as colors from '../../styles/colors';
import * as typography from '../../styles/typography';

import FlexibleWrapper from '../shared/FlexibleWrapper';
import CallActivityIcon from '../shared/icons/SubProcessIcon';
import ElementPropertyContainer from '../../containers/shared/ElementPropertyContainer';

const PanelWrapper = styled.div`
  display: none;
  margin-right: 8px;
  min-width: 48px;

  ${props =>
    props.isExpanded
      ? `
        width: 400px;
        display: block;
        `
      : ''}
`;

const PanelHeader = styled(FlexibleWrapper)`
  width: 100%;
  cursor: pointer;
  ${mixins.flex('flex-start')};
`;

const PanelHeaderTitle = styled.p`
  margin-left: 11px;
  color: ${colors.mainFontColor};
  ${mixins.fontStyle(typography.textFontSize, typography.semibold)};
`;

const SubProcessProperties = styled(FlexibleWrapper)`
  flex-direction: column;
  box-sizing: border-box;
  padding-bottom: 4px;
  padding-top: 4px;
`;

const SubProcessPanel = ({
  modeler,
  diagramsList,
  currentDiagramId,
  isExpanded,
  currentSubProcess,
  currentBusinessObject,
  currentSubProcessDiagramVersions,
  changeCurrentSubProcessFile,
}) => {
  const modeling = modeler.get('modeling');
  const moddle = modeler.get('moddle');

  const extensionElements =
    currentBusinessObject && currentBusinessObject.extensionElements
      ? currentBusinessObject.extensionElements.values
      : [];

  const callActivityIdData = {
    type: 'id',
    label: 'Call activity id',
    eidtable: false,
  };

  const diagramOwnerData = {
    key: 'owners',
    type: 'owners',
    label: 'Call activity owners',
    editable: true,
    values: [
      {
        id: '1',
        name: 'John',
        surname: 'Wick',
        role: 'admin',
      },
      {
        id: '2',
        name: 'Leslie',
        surname: 'Johnson',
        role: 'editor',
      },
      {
        id: '3',
        name: 'John',
        surname: 'McHulligan',
        role: 'admin',
      },
      {
        id: '4',
        name: 'Ricky',
        surname: 'Lanson',
        role: 'visitor',
      },
    ],
  };

  const diagramIdPropData = {
    type: 'diagramId',
    label: 'Diagram id',
    editable: true,
  };

  const diagramVersionIdPropData = {
    type: 'diagramVersionId',
    label: 'Diagram version id',
    editable: true,
  };

  return (
    <PanelWrapper isExpanded={isExpanded} id="sub-process-main-container">
      <PanelHeader>
        <CallActivityIcon color={colors.mainIconColor} />
        {isExpanded ? <PanelHeaderTitle>Subflow</PanelHeaderTitle> : ''}
      </PanelHeader>
      {isExpanded ? (
        <SubProcessProperties>
          <ElementPropertyContainer
            modeling={modeling}
            moddle={moddle}
            currentElement={currentSubProcess}
            index="0"
            value={currentBusinessObject['id']}
            {...callActivityIdData}
            elementPropKey="id"
          />
          <ElementPropertyContainer
            modeling={modeling}
            moddle={moddle}
            currentElement={currentSubProcess}
            index="3"
            value={currentBusinessObject['owners']}
            {...diagramOwnerData}
            elementPropKey="owners"
            extensionElements={Object.assign([], extensionElements)}
          />
          <ElementPropertyContainer
            modeling={modeling}
            moddle={moddle}
            currentElement={currentSubProcess}
            index="1"
            value={currentBusinessObject['diagramId']}
            values={diagramsList.filter(diagram => diagram.id !== currentDiagramId)} // TODO: Rethink possibility of recursive use of diagrams
            {...diagramIdPropData}
            elementPropKey="diagramId"
            onChangeEffect={changeCurrentSubProcessFile}
          />
          <ElementPropertyContainer
            modeling={modeling}
            moddle={moddle}
            currentElement={currentSubProcess}
            index="2"
            value={currentBusinessObject['diagramVersionId']}
            values={currentSubProcessDiagramVersions}
            {...diagramVersionIdPropData}
            elementPropKey="diagramVersionId"
            onChangeEffect={changeCurrentSubProcessFile}
            isBlocked={!currentBusinessObject.diagramId}
          />
        </SubProcessProperties>
      ) : (
        ''
      )}
    </PanelWrapper>
  );
};

export default SubProcessPanel;
