/*This module imports all components defined elsewhere and combines these*/

import React from 'react';
// import {Link} from 'react-router';
import { TextArea } from './TextArea';
import { ConversationList } from './ConversationList';
import { BotAttributes } from './BotAttributes';
import { connect } from 'react-redux';
import IdleMonitor from 'react-simple-idle-monitor';

const MyIdleMonitor = connect()(IdleMonitor);

const Main = ({ children }) => {
  return <div className="main-container full-height">{children}</div>;
};

const ChatArea = ({ loggedInUser, labels, segments, labelsFromOverview }) => {
  const loggedInUserName = loggedInUser.first_name + ' ' + loggedInUser.last_name;

  return (
    <div className="live-container" id="chatArea">
      <ConversationList width="3" loggedInUser={loggedInUserName} segments={segments} loggedInUserObj={loggedInUser} />
      <TextArea width="6" loggedInUser={loggedInUserName} />
      <BotAttributes labels={labels} labelsFromOverview={labelsFromOverview} width="3" />
    </div>
  );
};

export { Main, ChatArea };
