import React from 'react';

const ArrowIcon = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M9.5 17.5L15 12L9.5 6.5" stroke="#002348" strokeWidth="1.5" strokeLinecap="round" />
    </svg>
  );
};

export default ArrowIcon;
