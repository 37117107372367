import { sendGET, sendPUT } from './common/RestService';
import responseService from './common/ResponseService';

const API_BASE_URL = process.env.API_URL || 'http://127.0.0.1:8000';
const API_PREFIX = process.env.API_PREFIX || 'api/v1';
const URL_SEGMENTS = ['settings/'];

const SettingsService = {
  fetch: () => {
    return responseService.handle(sendGET(API_BASE_URL, API_PREFIX, URL_SEGMENTS));
  },
  update: data => {
    return responseService.handle(sendPUT(API_BASE_URL, API_PREFIX, URL_SEGMENTS, data));
  },
};

export default SettingsService;
