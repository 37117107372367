import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import './UserManagement.scss';
import FormModal from './modals/FormModal';
import modalsActions from '../../action-creators/modalsActions';
import iconPackage from '../overview/img/package.png';
import iconGroup from '../overview/img/group.png';
import labelsActions from '../../action-creators/labelsActions';
import usersActions from '../../action-creators/usersActions';
import ConfirmationModal from '../overview/modals/ConfirmationModal';
import withChatDraft from "../utils/chat-draft/withChatDraft";
import {withRoles} from "../utils/roles/withRoles";

const UserRoles = [
  {
    value: 'admin',
    name: 'Admin',
  },
  {
    value: 'product_manager',
    name: 'Product Manager',
  },
  {
    value: 'labeler',
    name: 'Labeler',
  },
  {
    value: 'customer_service_representative',
    name: 'Customer Service Representative',
  },
];

const UserManagement = ({
  users,
  currentUser,
  fetchUsersList,
  deleteUserModalArgs,
  createUser,
  updateUser,
  deleteUser,
  editUserModalOpen,
  editUserModalArgs,
  deleteUserModalOpen,
  toggleModal,
  modalError,
}) => {
  useEffect(() => {
    fetchUsersList();
  }, []);

  return (
    <div className="users">
      <div id="users-menu">
        <div id="user-menu-buttons">
          <div className="buttons">
            <p className="link-button" onClick={() => toggleModal('editUserModal')}>
              {/*<img src={iconPackage} alt=""/> */}
              <span>Add user</span>
            </p>
          </div>
        </div>
      </div>

      <table id="users-table">
        <thead id="users-table-header">
          <tr>
            <th>
              <p className="users-table-header-item">Name</p>
            </th>
            <th>
              <p className="users-table-header-item">E-mail</p>
            </th>
            <th>
              <p className="users-table-header-item">Role</p>
            </th>
            <th />
            <th />
          </tr>
        </thead>
        <tbody>
          {users &&
            users.map(user => (
              <tr className={'users-table-item-container'} key={user.id}>
                <td>
                  <p className="users-table-item">{user.first_name + ' ' + user.last_name}</p>
                </td>
                <td>
                  <p className="users-table-item">{user.email}</p>
                </td>
                <td>
                  <p className="users-table-item">{UserRoles.find(u => u.value === user.role).name}</p>
                </td>
                <td>
                  <p className="link-button" onClick={() => toggleModal('editUserModal', { user })}>
                    Edit
                  </p>
                </td>
                <td>
                  <p className="link-button" onClick={() => toggleModal('deleteUserModal', { user })}>
                    Delete
                  </p>
                </td>
              </tr>
            ))}
        </tbody>
      </table>

      {editUserModalOpen ? (
        <FormModal
          user={editUserModalArgs.user}
          className="link-button"
          createUser={createUser}
          currentUser={currentUser}
          updateUser={updateUser}
          closeModal={() => toggleModal('editUserModal')}
          modalError={modalError}
          options={UserRoles}
        />
      ) : (
        ''
      )}

      {deleteUserModalOpen ? (
        <ConfirmationModal
          title="Delete user"
          message={
            <span>
              Are you sure you want to delete the user?
              <br />
              This action is irreversible
            </span>
          }
          labelAction={() => deleteUser(deleteUserModalArgs.user.id)}
          closeModal={() => toggleModal('deleteUserModal')}
        />
      ) : (
        ''
      )}
    </div>
  );
};

export const mapStateToProps = state => {
  return {
    users: state.users.users,
    // addUserModalOpen: state.modals.addUserModal.isOpen,
    editUserModalOpen: state.modals.editUserModal.isOpen,
    editUserModalArgs: state.modals.editUserModal.args,
    deleteUserModalOpen: state.modals.deleteUserModal.isOpen,
    deleteUserModalArgs: state.modals.deleteUserModal.args,
    modalError: state.modals.modalError,
    currentUser: state.auth.user
    // currentUserId: state.users.currentUserId
  };
};

const mapDispatchToProps = {
  fetchUsersList: usersActions.fetchUsersList,
  toggleModal: modalsActions.toggleModal,
  createUser: usersActions.createUser,
  updateUser: usersActions.updateUser,
  deleteUser: usersActions.deleteUser,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRoles(
  ['admin'],
  withChatDraft(withRouter(UserManagement)))
);
