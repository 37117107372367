import React from "react";
import {ElementPropInput} from "./utils/CommonElements";

export const Choices = ({
  currentValue, isBlocked, changeCurrentValue, elementPropKey, currentElement, modeling
}) => {
  return <ElementPropInput
    className="element-property-input"
    value={currentValue || ''}
    disabled={isBlocked}
    onChange={e => {
      changeCurrentValue(e.target.value);
    }}
    onBlur={e => {
      modeling.updateProperties(currentElement, {
        [elementPropKey]: currentValue,
      });
    }}
  />
}