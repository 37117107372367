import RuleProvider from 'diagram-js/lib/features/rules/RuleProvider';

import inherits from 'inherits';

export default function SelfConnectionRules(bpmnRules, eventBus) {
  RuleProvider.call(this, eventBus);
  this._bpmnRules = bpmnRules;
}

inherits(SelfConnectionRules, RuleProvider);

SelfConnectionRules.$inject = ['bpmnRules', 'eventBus'];

SelfConnectionRules.prototype.init = function () {
  this.addRule('connection.create', 2000, ({ source, target }) => {
    if (source === target) {
      // Disallow loops
      return false;
    }
    return this._bpmnRules.canConnect(source, target);
  });

  this.addRule('connection.reconnectEnd', 2000, ({source, target}) => {
    if (source === target) {
      return false;
    }
    return this._bpmnRules.canConnect(source, target);
  });

  this.addRule('connection.reconnectStart', 2000, ({source, target}) => {
    if (source === target) {
      return false;
    }
    return this._bpmnRules.canConnect(source, target);
  })
};
