const initialModalsState = {
  modalError: undefined,

  addLabelModal: {
    isOpen: false,
  },
  editLabelModal: {
    isOpen: false,
  },
  deleteLabelModal: {
    isOpen: false,
  },
  editUserModal: {
    isOpen: false,
    args: {},
  },
  deleteUserModal: {
    isOpen: false,
    args: {},
  },
};

export default initialModalsState;
