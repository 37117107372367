import React, { useEffect, useState, useRef } from 'react';
import { withRouter, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import './Callbot.scss';
import $ from 'jquery';
import RecordRTC from 'recordrtc';
import hark from 'hark';
import updateArrow from './img/update-arrow.svg';
import withChatDraft from '../utils/chat-draft/withChatDraft';
import { withRoles } from '../utils/roles/withRoles';
import { Input } from 'antd';
import { Icon } from '@iconify/react/dist/offline';
import soundHigh from '@iconify-icons/iconoir/sound-low';
import soundOff from '@iconify-icons/iconoir/sound-off';
import infoEmpty from '@iconify-icons/iconoir/info-empty';

const { Search } = Input;
const { StereoAudioRecorder } = RecordRTC;
//================= CONFIG =================
// Stream Audio
let bufferSize = 2048,
  AudioContext,
  context,
  processor,
  input,
  globalStream;

//vars
let finalWord,
  removeLastSentence = true,
  streamStreaming = false;

//audioStream constraints
const constraints = {
  audio: true,
  video: false,
};

const DOCUMENTATION_URL = 'https://docs.google.com/document/d/1snlpm111fBD3pa8Y4VvWJ4f7CVNVG8LBSCgBHHM2J_o';

//================= RECORDING =================
const ENABLE_PHONE_TO_TEXT_TRANSCRIPTION = process.env.ENABLE_PHONE_TO_TEXT_TRANSCRIPTION || 'false';

const Callbot = ({}) => {
  let resultText;
  const cwcSendData = useRef(null);
  let sendingDataOnRecordingEnded = false;
  let sendingDataOnInterval = false;
  let waitForLastMessage = false;
  let [textToSpeechEnabled, setTextToSpeechEnabled] = useState(
    localStorage.getItem('mute-btn-state') === 'false' ? false : true
  );
  const params = new URLSearchParams(window.location.search); // id=123
  let liveRecordingEnabled = params.get('liveRecordingEnabled');
  const CALLBOT_SOCKET_API = `${process.env.CALLBOT_SOCKET_API || 'http://localhost:8080'}`;
  const CALLBOT_SOCKET_API_PATH = process.env.CALLBOT_SOCKET_API_PATH;
  const socketio = CALLBOT_SOCKET_API_PATH
    ? window.io(CALLBOT_SOCKET_API, { path: CALLBOT_SOCKET_API_PATH })
    : window.io(CALLBOT_SOCKET_API);

  const socket = socketio.on('connect', function () {
    // reset the recorder
    socket.emit('join', 'Server Connected to Client');
    // startRecording.disabled = false;
  });
  function initRecording() {
    socket.emit('startGoogleCloudStream', ''); //init socket Google Speech Connection
    streamStreaming = true;
    AudioContext = window.AudioContext || window.webkitAudioContext;
    context = new AudioContext({
      // if Non-interactive, use 'playback' or 'balanced' // https://developer.mozilla.org/en-US/docs/Web/API/AudioContextLatencyCategory
      latencyHint: 'interactive',
    });
    processor = context.createScriptProcessor(bufferSize, 1, 1);
    processor.connect(context.destination);
    context.resume();

    var handleSuccess = function (stream) {
      globalStream = stream;
      input = context.createMediaStreamSource(stream);
      input.connect(processor);

      processor.onaudioprocess = function (e) {
        microphoneProcess(e);
      };
    };

    navigator.mediaDevices.getUserMedia(constraints).then(handleSuccess);
  }

  function microphoneProcess(e) {
    var left = e.inputBuffer.getChannelData(0);
    // var left16 = convertFloat32ToInt16(left); // old 32 to 16 function
    var left16 = downsampleBuffer(left, 44100, 16000);
    socket.emit('binaryData', left16);
  }

  function startRecording() {
    // startButton.disabled = true;
    // endButton.disabled = false;
    // recordingStatus.style.visibility = 'visible';
    initRecording();
  }

  function stopRecording() {
    // waited for FinalWord
    // startButton.disabled = false;
    // endButton.disabled = true;
    // recordingStatus.style.visibility = 'hidden';
    // streamStreaming = false;
    socket.emit('endGoogleCloudStream', '');
    let track = globalStream.getTracks()[0];
    track.stop();

    input.disconnect(processor);
    processor.disconnect(context.destination);
    context.close().then(function () {
      input = null;
      processor = null;
      context = null;
      AudioContext = null;
      // startButton.disabled = false;
    });
    setTimeout(() => {
      classifyResults();
    }, 500);
    // if (finalWord === undefined || finalWord == true) classifyResults();
    // else waitForLastMessage = true;
    // context.close();

    // audiovideostream.stop();

    // microphone_stream.disconnect(script_processor_node);
    // script_processor_node.disconnect(audioContext.destination);
    // microphone_stream = null;
    // script_processor_node = null;

    // audiovideostream.stop();
    // videoElement.srcObject = null;
  }

  //================= SOCKET IO =================
  socket.on('connect', function (data) {
    console.log('connected to socket');
    socket.emit('join', 'Server Connected to Client');
  });

  socket.on('messages', function (data) {
    console.log(data);
  });

  socket.on('text_from_phone', function (text) {
    if (text) {
      if (!cwcSendData.current) {
        alert('CWC is not ready!');
        return;
      }
      cwcSendData.current(text);
      $('body').removeClass('hide-pannel');
    }
  });

  socket.on('speechData', function (data) {
    // console.log(data.results[0].alternatives[0].transcript);
    let dataFinal = data.results[0].isFinal;

    if (dataFinal === false) {
      // console.log(resultText.lastElementChild);
      if (removeLastSentence) {
        resultText?.lastElementChild?.remove();
      }
      removeLastSentence = true;

      //add empty span
      let empty = document.createElement('span');
      resultText.appendChild(empty);

      //add children to empty span
      let edit = addTimeSettingsInterim(data);

      for (let i = 0; i < edit.length; i++) {
        resultText.lastElementChild.appendChild(edit[i]);
        resultText.lastElementChild.appendChild(document.createTextNode('\u00A0'));
      }
    } else if (dataFinal === true) {
      resultText?.lastElementChild?.remove();

      //add empty span
      let empty = document.createElement('span');
      resultText.appendChild(empty);

      //add children to empty span
      let edit = addTimeSettingsFinal(data);
      for (let i = 0; i < edit.length; i++) {
        if (i === 0) {
          edit[i].innerText = capitalize(edit[i].innerText);
        }
        resultText.lastElementChild.appendChild(edit[i]);

        if (i !== edit.length - 1) {
          resultText.lastElementChild.appendChild(document.createTextNode('\u00A0'));
        }
      }
      resultText.lastElementChild.appendChild(document.createTextNode('\u002E\u00A0'));

      console.log("Google Speech sent 'final' Sentence.");
      finalWord = true;
      // endButton.disabled = false;
      removeLastSentence = false;
      if (waitForLastMessage) {
        classifyResults();
        waitForLastMessage = false;
      }
    }
  });

  //================= Juggling Spans for nlp Coloring =================
  function addTimeSettingsInterim(speechData) {
    let wholeString = speechData.results[0].alternatives[0].transcript;
    console.log(wholeString);

    let nlpObject = nlp(wholeString).out('terms');

    let words_without_time = [];

    for (let i = 0; i < nlpObject.length; i++) {
      //data
      let word = nlpObject[i].text;
      let tags = [];

      //generate span
      let newSpan = document.createElement('span');
      newSpan.innerHTML = word;

      //push all tags
      for (let j = 0; j < nlpObject[i].tags.length; j++) {
        tags.push(nlpObject[i].tags[j]);
      }

      //add all classes
      for (let j = 0; j < nlpObject[i].tags.length; j++) {
        let cleanClassName = tags[j];
        // console.log(tags);
        let className = `nl-${cleanClassName}`;
        newSpan.classList.add(className);
      }

      words_without_time.push(newSpan);
    }

    finalWord = false;
    // endButton.disabled = true;

    return words_without_time;
  }

  function addTimeSettingsFinal(speechData) {
    let wholeString = speechData.results[0].alternatives[0].transcript;

    let nlpObject = nlp(wholeString).out('terms');
    let words = speechData.results[0].alternatives[0].words;

    let words_n_time = [];

    for (let i = 0; i < words.length; i++) {
      //data
      let word = words[i].word;
      let startTime = `${words[i].startTime.seconds}.${words[i].startTime.nanos}`;
      let endTime = `${words[i].endTime.seconds}.${words[i].endTime.nanos}`;
      let tags = [];

      //generate span
      let newSpan = document.createElement('span');
      newSpan.innerHTML = word;
      newSpan.dataset.startTime = startTime;

      //push all tags
      for (let j = 0; j < nlpObject[i].tags.length; j++) {
        tags.push(nlpObject[i].tags[j]);
      }

      //add all classes
      for (let j = 0; j < nlpObject[i].tags.length; j++) {
        let cleanClassName = nlpObject[i].tags[j];
        // console.log(tags);
        let className = `nl-${cleanClassName}`;
        newSpan.classList.add(className);
      }

      words_n_time.push(newSpan);
    }

    return words_n_time;
  }

  window.onbeforeunload = function () {
    if (streamStreaming) {
      socket.emit('endGoogleCloudStream', '');
    }
  };

  //================= SANTAS HELPERS =================

  // sampleRateHertz 16000 //saved sound is awefull
  function convertFloat32ToInt16(buffer) {
    let l = buffer.length;
    let buf = new Int16Array(l / 3);

    while (l--) {
      if (l % 3 == 0) {
        buf[l / 3] = buffer[l] * 0xffff;
      }
    }
    return buf.buffer;
  }

  var downsampleBuffer = function (buffer, sampleRate, outSampleRate) {
    if (outSampleRate == sampleRate) {
      return buffer;
    }
    if (outSampleRate > sampleRate) {
      throw 'downsampling rate show be smaller than original sample rate';
    }
    var sampleRateRatio = sampleRate / outSampleRate;
    var newLength = Math.round(buffer.length / sampleRateRatio);
    var result = new Int16Array(newLength);
    var offsetResult = 0;
    var offsetBuffer = 0;
    while (offsetResult < result.length) {
      var nextOffsetBuffer = Math.round((offsetResult + 1) * sampleRateRatio);
      var accum = 0,
        count = 0;
      for (var i = offsetBuffer; i < nextOffsetBuffer && i < buffer.length; i++) {
        accum += buffer[i];
        count++;
      }

      result[offsetResult] = Math.min(1, accum / count) * 0x7fff;
      offsetResult++;
      offsetBuffer = nextOffsetBuffer;
    }
    return result.buffer;
  };

  function capitalize(s) {
    if (s.length < 1) {
      return s;
    }
    return s.charAt(0).toUpperCase() + s.slice(1);
  }
  function classifyResults() {
    let text = $('#ResultText').text();
    let h1 = document.querySelector('h1');
    text = text.trim();
    if (text[text.length - 1] == '.') text = text.slice(0, -1);
    if (text === '') {
      h1.innerHTML = 'Please repeat yourself';
      $('#ResultText').empty();
    } else if ($('body').hasClass('hide-pannel')) {
      $('#classify-result').show();
    } else {
      if (!cwcSendData.current) {
        alert('CWC is not ready!');
        return;
      }
      cwcSendData.current(text);
      $('#ResultText').empty();
    }
  }
  useEffect(() => {
    resultText = document.getElementById('ResultText');
    //================= INTERFACE =================
    // var startButton = document.getElementById('speech');
    // startButton.addEventListener('click', startRecording);

    // var endButton = document.getElementById('stop-recording');
    // endButton.addEventListener('click', stopRecording);
    // endButton.disabled = true;

    const sendMessage = msg => {
      if (!cwcSendData.current) {
        alert('CWC is not ready!');
        return;
      }
      cwcSendData.current(msg);
      $('#classify-result a').hide();
      $('body').removeClass('hide-pannel');
    };
    $('body').addClass('hide-pannel');
    window?.cws?.destroy();
    $('#chat-web-sdk-reset-btn').removeClass('visible');

    $('.reset-btn').click(function () {
      location.reload();
    });
    $('#classify-result a').click(() => {
      let text = $('#ResultText').text();
      text = text.trim();
      if (text[text.length - 1] === '.') text = text.slice(0, -1);
      sendMessage(text);
      $('#ResultText').empty();
    });
    $(document).on('contextmenu', '#classify-result a', function (e) {
      e.preventDefault();
      sendMessage('when should i vaccinate');
    });
    var h1 = document.querySelector('h1');
    var ring = document.querySelector('.pulse-ring');
    // when the server found results and send
    // it back to the client
    const resultpreview = document.getElementById('results');
    socketio.on('results', function (data) {
      if (data && data.results[0] && data.results[0].alternatives[0]) {
        resultpreview.innerHTML += ' ' + data.results[0].alternatives[0].transcript;
      }
    });

    const startRecordingBtn = document.getElementById('speech');
    // const stopRecording = document.getElementById('stop-recording');
    let recordAudio;

    // const cws = initChatSdk('https://cwc.demo.titancs.mindtitan.com/mindtitan/', {
    const cws = initChatSdk(`${process.env.CWC_DRAFT_URL}/custom_enabled/`, {
      target: 'callbot-sdk',
      options: {
        cachingEnabled: false,
        exclude_launch: true,
        text_to_speech: textToSpeechEnabled,
        recent_message_threshold: 3 * 24 * 60 * 60, // seconds in 3 days
        custom_bubble_style: {
          'z-index': 10000,
          border: 'none',
          height: 'calc(100% - 80px)',
          position: 'fixed',
          width: '100%',
          'max-width': '376px',
          'box-shadow': '0 2px 10px 1px #b5b5b5',
          opacity: 1,
          transition: 'bottom 0.6s, opacity 0.8s',
        },
        custom_bubble_style_mobile: {
          'z-index': 10000,
          border: 'none',
          height: '100%',
          position: 'fixed',
          right: '0px',
          width: '100%',
          'box-shadow': '0 2px 10px 1px #b5b5b5',
          'border-radius': '5px',
          opacity: 1,
          bottom: '0px',
          transition: 'bottom 0.6s, opacity 0.8s',
          'max-width': '100%',
        },
      },
    });
    cws.onReady = async function () {
      console.log('READY CWC!');
      cwcSendData.current = text => {
        cws.send(text, 'text', null, { content_type: 'voice' }, [], 'call');
      };
    };
    if (cws.ready) {
      cws.onReady();
    }

    // on start button handler
    let stopped = true;
    startRecordingBtn.onclick = function () {
      if (stopped) {
        stopped = false;
        // $('body').addClass('hide-pannel');
        $('#classify-result').hide();
        h1.innerHTML = 'Listening. Press again to stop listening.';
        // resultpreview.innerHTML = '';
        // $("#chat-web-sdk").remove();

        ring.classList.add('listening');
        startRecording();
        return;
        // recording started
        // startRecording.disabled = true;

        // make use of HTML 5/WebRTC, JavaScript getUserMedia()
        // to capture the browser microphone stream
        navigator.getUserMedia(
          {
            audio: true,
          },
          function (stream) {
            recordAudio = RecordRTC(stream, {
              type: 'audio',
              mimeType: 'audio/webm',
              sampleRate: 44100, // this sampleRate should be the same in your server code

              // MediaStreamRecorder, StereoAudioRecorder, WebAssemblyRecorder
              // CanvasRecorder, GifRecorder, WhammyRecorder
              recorderType: StereoAudioRecorder,

              // Dialogflow / STT requires mono audio
              numberOfAudioChannels: 1,

              // get intervals based blobs
              // value in milliseconds
              // as you might not want to make detect calls every seconds
              timeSlice: 4000,

              // only for audio track
              // audioBitsPerSecond: 128000,

              // used by StereoAudioRecorder
              // the range 22050 to 96000.
              // let us force 16khz recording:
              desiredSampRate: 16000,

              // as soon as the stream is available
              ondataavailable: function (blob) {
                // making use of socket.io-stream for bi-directional
                // streaming, create a stream
                if (!sendingDataOnRecordingEnded) {
                  sendingDataOnInterval = true;
                  var stream = window.ss.createStream();
                  // stream directly to server
                  // it will be temp. stored locally
                  ss(socket).emit('stream-transcribe', stream, {
                    name: 'stream.wav',
                    size: blob.size,
                  });
                  // pipe the audio blob to the read stream
                  window.ss.createBlobReadStream(blob).pipe(stream);
                  stream.on('end', () => {
                    sendingDataOnInterval = false;
                    if (sendingDataOnRecordingEnded) {
                      recordAudio.stopRecording();
                      classifyResults();
                    }
                  });
                }
              },
            });

            recordAudio.startRecording();
            h1.innerHTML = 'Listening. Press again to stop listening.';
            // stopRecording.disabled = false;

            // var max_seconds = 3;
            // var stopped_speaking_timeout;
            // var speechEvents = hark(stream, {});

            // speechEvents.on('speaking', function () {
            //   if (recordAudio.getBlob()) return;

            //   // clearTimeout(stopped_speaking_timeout);

            //   if (recordAudio.getState() === 'paused') {
            //     // recordAudio.resumeRecording();
            //   }
            // });

            // speechEvents.on('stopped_speaking', function () {
            //   if (recordAudio.getBlob()) return;

            // recordAudio.pauseRecording();
            // stopped_speaking_timeout = setTimeout(function () {
            // h1.innerHTML = 'Recording is now stopped.';
            // ring.classList.remove('listening');
            // // recording stopped
            // // startRecording.disabled = false;
            // // stopRecording.disabled = true;
            // recordAudio.stopRecording();
            // const response = fetch(`${process.env.CALLBOT_API || 'http://localhost:8080'}/classify`, {
            //   method: 'POST',
            //   headers: {
            //     'Content-Type': 'application/json',
            //   },
            //   body: JSON.stringify({
            //     input: resultpreview.innerHTML,
            //   }),
            // })
            //   .then(r => r.json())
            //   .then(data => {
            //     const newLabels = (data.labels.length > 0 ? data.labels : ['none'])
            //       .map(l => '"' + l + '"')
            //       .join(', ');
            //     $('#classify-result .keywords').html(newLabels);
            //     $('#classify-result').show();
            //   });
            // }, max_seconds * 1000);

            // just for logging purpose (you ca remove below code)
            // var seconds = max_seconds;
            // (function looper() {
            //   h1.innerHTML = 'Recording is going to be stopped in ' + seconds + ' seconds.';
            //   seconds--;

            //   if (seconds <= 0) {
            //     h1.innerHTML = 'Listening ...';
            //     return;
            //   }

            //   setTimeout(looper, 1000);
            // })();
            // });
          },
          function (error) {
            console.error(JSON.stringify(error));
          }
        );
      } else {
        stopped = true;
        h1.innerHTML = 'Recording is now stopped.';
        ring.classList.remove('listening');
        stopRecording();
        return;
        // recording stopped
        // startRecording.disabled = false;
        // stopRecording.disabled = true;
        sendingDataOnRecordingEnded = true;
        // let blob = recordAudio.getBlob();
        // var stream = window.ss.createStream();
        // ss(socket).emit('stream-transcribe', stream, {
        //   name: 'stream.wav',
        //   size: blob.size,
        // });
        // // pipe the audio blob to the read stream
        // window.ss.createBlobReadStream(blob).pipe(stream);
        if (!sendingDataOnInterval) {
          recordAudio.stopRecording(() => {
            if (!sendingDataOnInterval) {
              let blob = recordAudio.getBlob();
              var stream = window.ss.createStream();
              ss(socket).emit('stream-transcribe', stream, {
                name: 'stream.wav',
                size: blob.size,
              });
              window.ss.createBlobReadStream(blob).pipe(stream);
              // pipe the audio blob to the read stream
              stream.on('end', classifyResults);
            }
          });
        }
      }
    };
    return () => {
      window.initCWC();
    };
  }, []);
  return (
    <div style={{ height: '100%' }}>
      {ENABLE_PHONE_TO_TEXT_TRANSCRIPTION === 'true' && (
        <Search
          id="phone_to_text_search"
          style={{ position: 'absolute', width: 376, right: 376 }}
          onSearch={phone => socket.emit('phone_to_text', phone)}
          placeholder="Sisesta siia telefoni number"
          enterButton
        />
      )}
      <div id="callbot-sdk" />
      <div className="callbot-component" style={{ height: '100%', backgroundColor: '#002348' }}>
        <div id="callbot-sdk-btn" />
        <meta charSet="UTF-8" />
        <title>client</title>
        <meta httpEquiv="content-type" content="text/html; charset=utf-8" />
        <link
          rel="stylesheet"
          href="https://pro.fontawesome.com/releases/v5.10.0/css/all.css"
          integrity="sha384-AYmEC3Yw5cVb3ZcuHtOA93w35dYTsvhLPVnYs9eStHfGJvOvKxVfELGroGkvsg+p"
          crossOrigin="anonymous"
        />
        <link href="https://fonts.googleapis.com/css?family=Poppins:400,500,600,700&display=swap" rel="stylesheet" />
        {/*    */}
        <a className="reset-btn">
          <img src={updateArrow} />
        </a>
        <div
          onClick={() => {
            setTextToSpeechEnabled(!textToSpeechEnabled);
            localStorage.setItem('mute-btn-state', !textToSpeechEnabled);
            location.reload();
          }}
        >
          <Icon className="mute-btn" id="mute-btn" icon={textToSpeechEnabled ? soundHigh : soundOff} />
        </div>
        <div onClick={() => window.open(DOCUMENTATION_URL, '_blank').focus()}>
          <Icon className="info-btn" id="info-btn" icon={infoEmpty} />
        </div>

        <div className="container" style={{ width: '100%' }}>
          <div
            style={{ display: 'flex', position: 'absolute', top: '45%', flexDirection: 'column', alignItems: 'center' }}
          >
            <button id="speech" className="btn">
              <i className="fa fa-microphone" aria-hidden="true" />
              <div className="pulse-ring" />
            </button>
            <h1
              style={{ fontSize: '18px', maxWidth: '400px', textAlign: 'center', color: 'white', margin: '12px 0px' }}
            >
              Press to start and describe why did you call us in a few sentences.
            </h1>
            <h2 style={{ display: 'none' }}>Results: data.results[0].alternatives[0].transcript</h2>
            <div>
              <p style={liveRecordingEnabled !== 'true' ? { display: 'none' } : {}} id="ResultText">
                <span className="greyText"></span>
              </p>
            </div>
            <div id="classify-result" style={{ display: 'none', marginTop: 'fpx', color: 'white' }}>
              <div style={{ display: 'none' }}>Classify result:</div>
              <span className="keywords" style={{ fontStyle: 'italic', color: 'white', display: 'none' }}></span>
              <a style={{ color: 'white', display: 'block', textAlign: 'center', marginTop: '30px' }}>Proceed »</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export const mapStateToProps = state => {
  return {};
};

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  withRoles(
    ['customer_service_representative', 'labeler', 'product_manager', 'admin'],
    withChatDraft(withRouter(Callbot))
  )
);
