import React from 'react';
import styled from 'styled-components';
import * as mixins from '../../../styles/mixins';
import * as colors from '../../../styles/colors';
import * as typography from '../../../styles/typography';

import FolderIcon from '../../shared/icons/tools/FolderIcon';
import SearchContainer from '../../../containers/version-control-panel/VersionControlSearchContainer';
import CommentIcon from '../../shared/icons/tools/CommentIcon';
import FlexibleWrapper from '../../shared/FlexibleWrapper';

const HeaderContent = styled.div`
  ${mixins.flex('space-between')};
  margin-bottom: 8px;
`;

const HeaderTitleWrapper = styled.div`
  ${mixins.flex()};
`;

const HeaderTitle = styled.p`
  margin-left: 14px;
  color: ${colors.extraFontColor};
  ${mixins.fontStyle(typography.titleFontSize, typography.medium)};
`;

const HeaderFunctionality = styled.div`
  ${mixins.flex()};
`;

const CommentVisibility = styled(FlexibleWrapper)`
  margin-right: 15px;
`;

const CommentVisibilityToggler = styled.div`
  opacity: 0.5;

  &:hover {
    opacity: 1;
  }

  ${props =>
    props.isVisible
      ? `
            opacity: 1;
            
            &:hover path {
                stroke: ${colors.alternativeIconColor};
            }
        `
      : ''}
`;

const VersionControlHeader = ({ isCommentVisible, toggleDiagramVersionCommentVisibility, isSearchVisible }) => {
  return (
    <HeaderContent>
      <HeaderTitleWrapper>
        <FolderIcon color={colors.extraIconColor} />
        <HeaderTitle>Existing solution flows</HeaderTitle>
      </HeaderTitleWrapper>

      <HeaderFunctionality>
        <CommentVisibility>
          <CommentVisibilityToggler
            isVisible={isCommentVisible}
            onClick={() => toggleDiagramVersionCommentVisibility()}
          >
            <CommentIcon color={colors.mainIconColor} />
          </CommentVisibilityToggler>
        </CommentVisibility>

        {isSearchVisible ? <SearchContainer/> : null}
      </HeaderFunctionality>
    </HeaderContent>
  );
};

export default VersionControlHeader;
