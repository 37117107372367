import responseService from './common/ResponseService';
import { sendGET, sendPOST } from './common/RestService';

const API_BASE_URL = process.env.API_URL || 'http://127.0.0.1:8000';
const API_PREFIX = process.env.API_PREFIX || 'api/v1';

const LoginService = {
  login: ({ credentials }) => {
    return responseService.handle(sendPOST(API_BASE_URL, API_PREFIX, ['login'], credentials));
  },
  me: () => {
    return responseService.handle(sendGET(API_BASE_URL, API_PREFIX, ['users', 'me'], {}, true));
  },
};

export default LoginService;
