const openModal = (
  modalKey,
  actionOnConfirm,
  argsOnConfirm,
  extraModalArgs = {},
  actionOnCancel = () => {},
  argsOnCancel = {},
  modalText = null
) => {
  return {
    type: 'OPEN_MODAL',
    modalKey: modalKey,
    actionOnConfirm: actionOnConfirm,
    argsOnConfirm: argsOnConfirm,
    extraModalArgs: extraModalArgs,
    actionOnCancel: actionOnCancel,
    argsOnCancel: argsOnCancel,
    modalText,
  };
};

const proceedOnModalConfirm = (actionOnConfirm, argsOnConfirm, modalKey) => {
  return {
    type: 'PROCEED_ON_MODAL_CONFIRM',
    actionOnConfirm: actionOnConfirm,
    argsOnConfirm: argsOnConfirm,
    modalKey: modalKey,
  };
};

const proceedOnModalCancel = (actionOnCancel, argsOnCancel, modalKey) => {
  return {
    type: 'PROCEED_ON_MODAL_CANCEL',
    actionOnCancel: actionOnCancel,
    argsOnCancel: argsOnCancel,
    modalKey: modalKey,
  };
};

const closeModal = modalKey => {
  return {
    type: 'CLOSE_MODAL',
    modalKey: modalKey,
  };
};

const modalsActions = {
  openModal,
  proceedOnModalConfirm,
  proceedOnModalCancel,
  closeModal,
};

export default modalsActions;
