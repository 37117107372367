import React from "react";
import {useEffect} from "react";
import $ from "jquery";

const withoutChatDraft = (Component) => {
  return (props) => {
    useEffect(() => {
      if (window.cws && !window.cws.hidden) {
        window.cws.hide();
        window.cws.resetButtonActive = $('#chat-web-sdk-reset-btn').hasClass('visible');
        $('#chat-web-sdk-reset-btn').removeClass('visible');
      }
    }, []);
    return <Component {...props} />
  };
}

export default withoutChatDraft;