import React, { useState, useEffect, useRef, useMemo } from 'react';
import { connect } from 'react-redux';
import Tools from '../components/Tools';
import Languages from '../components/languages/Languages';
import diagramsActions from '../action-creators/diagramsActions';
import visiblityActions from '../action-creators/visibilityActions';
import currentTaskActions from '../action-creators/currentTaskActions';
import currentDiagramAndVersionActions from '../action-creators/currentDiagramAndVersionActions';
import ModelerService from '../services/ModelerService';
import saveAllActions from '../action-creators/saveAllActions';
import saveProgressActions from '../action-creators/saveProgressActions';
import currentSubProcessActions from '../action-creators/currentSubProcessActions';
import labelActions from '../action-creators/labelActions';
import usersActions from '../action-creators/usersActions';
import StorageService from '../services/common/StorageService';
import isAnyInputActive from '../helpers/isAnyInputActive';
import currentEventActions from '../action-creators/currentEventActions';
import dbActions from '../action-creators/dbActions';
import callbotActions from '../action-creators/callbotActions';
import { useModeler } from '../hooks/useModeler';
import { initDB } from 'react-indexed-db';
import { DBService } from '../services/common/DBService';
import isObjectEmpty from '../helpers/isObjectEmpty';
import { SUPPORTED_LANGUAGES } from '../../app/constants/codeToLanguageMap';

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

const translationsPerLangInitialState = SUPPORTED_LANGUAGES.map(() => 0);

const ToolsContainer = ({
  currentUser,
  currentDiagram,
  currentDiagramVersion,
  updateCurrentTask,
  updateCurrentSubProcess,
  updateCurrentSubProcessDiagramVersions,
  updateCurrentEvent,
  updateCurrentDiagramVersionFile,
  updateCurrentDiagramTranslationsModificationCount,
  updateCurrentFileState,
  fetchCurrentUser,
  updateLabels,
  updateIndexedDB,
  checkIndexedDB,
  labels,
  settings,
  diagramType,
  setDiagramType,
  ...props
}) => {
  const [reviewMode, setReviewMode] = useState(false);
  const [languagesOverlayVisible, setLanguagesOverlayVisible] = useState(false);
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
  const [maxPaletteHeight, setMaxPaletteHeight] = useState(0);
  const firstRender = useRef(false);

  useEffect(() => {
    try {
      initDB(DBService);
    } catch (e) {
      console.log('CANT CREATE DB');
    }
  }, []);

  const [modeler, viewer, reviewViewer] = useModeler({
    diagramType,
    userId: currentUser?.id,
    diagramFile: currentDiagramVersion?.file,
    updateCurrentTask,
    updateCurrentSubProcess,
    updateCurrentSubProcessDiagramVersions,
    updateCurrentEvent,
    updateCurrentDiagramTranslationsModificationCount,
    updateCurrentFileState,
    updateIndexedDB,
    setReviewMode,
  });

  useEffect(() => {
    if (Array.isArray(labels)) updateLabels(labels);
  }, [labels]);

  useEffect(() => {
    if (firstRender.current && currentDiagramVersion && labels) {
      const label = labels.find(label => label.name === currentDiagramVersion.label);
      if (label) {
        localStorage.setItem('currentLabel', label.id);
        localStorage.setItem('currentLabelVersion', currentDiagramVersion.id);
      }
    }
  }, [currentDiagramVersion, labels]);

  useEffect(() => {
    document.getElementById('sfm-app-modeler').style.display = languagesOverlayVisible ? 'none' : 'unset';
  }, [languagesOverlayVisible]);

  useEffect(() => {
    // Effect on search params
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const id = params.get('id') || localStorage.getItem('currentLabelVersion');
    const label =
      params.get('label') || labels?.find(label => label.id.toString() === localStorage.getItem('currentLabel'))?.name;
    const create = params.get('create');
    const labelCreate = params.get('label');

    // TODO: make better pathname validation
    if (create === 'true') {
      props.createNewCurrentDiagramAndVersion({
        shouldSave: true,
        label: labelCreate,
      });
    } else if ((label && label !== 'sfm') || id) {
      props.loadSpecialDiagramVersion(label, id);
    }
  }, []);

  useEffect(() => {
    firstRender.current = true;
  }, []);

  // resize effect
  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  // Check session storage and if it contains info, apply it
  useEffect(() => {
    if (StorageService.getFromSession('DTM_OWNERS_VISIBILITY') && !props.visibility.ownersVisible) {
      props.toggleOwnersVisibility();
    }
    if (StorageService.getFromSession('DTM_USER_TASKS_HIGHLIGHTED') && !props.visibility.userTasksHighlighted) {
      props.toggleUserTasksHighlight();
    }
  }, []);

  // Disable native tooltips and replace with css based customizable solution
  useEffect(() => {
    document.querySelectorAll('.djs-palette-entries .entry').forEach(el => {
      let tooltip = document.createElement('div');
      tooltip.setAttribute('class', 'entry-tooltip');
      tooltip.textContent = el.getAttribute('title');
      el.removeAttribute('title');
      el.appendChild(tooltip);
    });
  }, [modeler]); // when modeler is recreated, tooltips are reset

  // Add event listener to trigger save on Ctrl+Shift+c
  useEffect(() => {
    const keydownListener = e => {
      if (e.key === 'S' && e.ctrlKey && e.shiftKey) {
        if (
          !props.isCurrentDiagramSaved ||
          !props.isCurrentDiagramVersionSaved ||
          !props.isCurrentDiagramVersionFileSaved
        ) {
          props.saveAllWithVerification(modeler, props.saveAll, props.updateSaveProgress);
        }
      } else if (e.key === 'p' && !isAnyInputActive()) {
        ModelerService.centerDiagram(modeler);
      } else if (e.key === 's' && e.ctrlKey) {
        //trigger save & move to test on ctrl+S
        e.preventDefault();
        props.saveAllWithVerification(modeler, props.saveAll, props.updateSaveProgress, true);
      }
    };

    document.addEventListener('keydown', keydownListener);

    return () => {
      document.removeEventListener('keydown', keydownListener);
    };
  }, [modeler]);

  useEffect(() => {
    let elem = document.querySelector('.djs-palette');
    if (!elem) return;
    let elementBounds = elem.getBoundingClientRect();
    let height = maxPaletteHeight;
    if (elementBounds.bottom > maxPaletteHeight) {
      setMaxPaletteHeight(elementBounds.bottom);
      height = elementBounds.bottom;
    }
    document.querySelector('.djs-palette').style.width = height >= windowDimensions.height ? '94px' : 'min-content';
  }, [windowDimensions]);

  useEffect(() => {
    checkIndexedDB(currentDiagram.id, currentDiagramVersion.file);
  }, [currentDiagramVersion.file, currentDiagram.id]);

  useEffect(() => {
    if (modeler && (process.env.REQUIRE_AUTH === 'false' || currentUser?.id)) {
      ModelerService.processOwnerOverlays(modeler, currentUser?.id);
    }
  }, [props.visibility.ownersVisible, props.visibility.userTasksHighlighted, modeler]);

  const { translationsPerLang, translationsTotal, unreviewedTranslationsCount } = useMemo(() => {
    const initialState = {
      unreviewedTranslationsCount: 0,
      translationsPerLang: translationsPerLangInitialState,
      translationsTotal: -1,
    };
    if (!modeler?._definitions?.rootElements?.[0]?.translations) {
      return initialState;
    }
    const root = modeler._definitions.rootElements[0];
    const translations = JSON.parse(root.translations || '{}');
    if (isObjectEmpty(translations)) {
      return initialState;
    }
    const nodes = Object.values(translations);
    const translationsPerLang = SUPPORTED_LANGUAGES.map(
      lang => nodes.filter(nodeData => nodeData.translations?.[lang]?.text).length
    );
    const translationsTotal = nodes.filter(node => node.srcString).length;
    let count = 0;
    nodes.forEach(node => {
      for (const tr in node.translations) {
        if (!node.translations[tr].reviewed && node.translations[tr].text) {
          count += 1;
        }
      }
    });
    return {
      unreviewedTranslationsCount: count,
      translationsPerLang: translationsPerLang,
      translationsTotal: translationsTotal,
    };
  }, [currentDiagramVersion.file, currentDiagramVersion.translationsModificationCount, modeler]);

  if (!modeler) {
    return 'No modeler';
  }

  return (
    <>
      {languagesOverlayVisible ? (
        <Languages
          modeler={modeler}
          description={labels?.find(label => label.name === currentDiagramVersion?.label)?.description}
          currentDiagram={currentDiagram}
          diagramType={diagramType}
          setLanguagesOverlayVisible={setLanguagesOverlayVisible}
          translationsModificationCount={currentDiagramVersion.translationsModificationCount}
          updateCurrentDiagramTranslationsModificationCount={updateCurrentDiagramTranslationsModificationCount}
        />
      ) : (
        <Tools
          labels={labels}
          modeler={modeler}
          viewer={viewer}
          diagramType={diagramType}
          setDiagramType={setDiagramType}
          reviewViewer={reviewViewer}
          currentDiagram={currentDiagram}
          currentDiagramVersion={currentDiagramVersion}
          reviewMode={reviewMode}
          setLanguagesOverlayVisible={setLanguagesOverlayVisible}
          unreviewedTranslationsCount={unreviewedTranslationsCount}
          settings={settings}
          translationsPerLang={translationsPerLang}
          translationsTotal={translationsTotal}
          {...props}
        />
      )}
    </>
  );
};

export const mapStateToProps = state => {
  return {
    currentUser: state.users.currentUser,
    currentDiagram: state.app.currentDiagram,
    currentDiagramVersion: state.app.currentDiagramVersion,
    isCurrentDiagramSaved: state.app.isCurrentDiagramSaved,
    isCurrentDiagramVersionSaved: state.app.isCurrentDiagramVersionSaved,
    isCurrentDiagramVersionFileSaved: state.app.isCurrentDiagramVersionFileSaved,
    saveInProgress: state.saveProgress.saveInProgress,
    visibility: state.visibility,
    diagramType: state.app.currentDiagramVersion.diagramType,
  };
};

const mapDispatchToProps = {
  fetchDiagramsList: diagramsActions.fetchDiagramsList,
  saveAllWithVerification: saveAllActions.saveAllWithVerification,
  createNewCurrentDiagramAndVersion: currentDiagramAndVersionActions.createNewCurrentDiagramAndVersion,
  saveOnlyDiagramVersionFile: currentDiagramAndVersionActions.saveOnlyDiagramVersionFile,
  saveAll: saveAllActions.saveAll,
  saveNewDiagramAndVersion: currentDiagramAndVersionActions.saveNewCurrentDiagramAndVersion,
  saveDiagramAndVersion: currentDiagramAndVersionActions.saveCurrentDiagramAndVersion,
  saveCurrentDiagramVersionApprovedState: currentDiagramAndVersionActions.saveCurrentDiagramVersionApprovedState,
  updateCurrentDiagramVersionFile: currentDiagramAndVersionActions.updateCurrentDiagramVersionFile,
  updateCurrentDiagramTranslationsModificationCount:
    currentDiagramAndVersionActions.updateCurrentDiagramTranslationsModificationCount,
  updateCurrentFileState: currentDiagramAndVersionActions.updateCurrentFileState,
  loadSpecialDiagramVersion: currentDiagramAndVersionActions.loadSpecialDiagramVersion,
  fetchCurrentUser: usersActions.fetchCurrentUser,
  updateLabels: labelActions.updateLabels,
  updateIndexedDB: dbActions.updateIndexedDB,
  checkIndexedDB: dbActions.checkIndexedDB,
  setCallbotClassifiers: callbotActions.setCallbotClassifiers,
  setCallbotValidators: callbotActions.setCallbotValidators,
  setDiagramType: currentDiagramAndVersionActions.setDiagramType,

  ...saveProgressActions,
  ...visiblityActions,
  ...currentTaskActions,
  ...currentSubProcessActions,
  ...currentEventActions,
  ...usersActions,
};

export default connect(mapStateToProps, mapDispatchToProps)(ToolsContainer);
