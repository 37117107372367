const ModelerDrawService = (canvas, overlays) => {
  const _defineType = (element, type) => {
    canvas.addMarker(element, type);
  };

  const _createElementTypeOverlay = (element, type, position = { top: 0, left: 0 }) => {
    overlays.add(element, {
      position: position,
      html: `<div
                      class="icon-overlay ${type}"
                   ></div>`,
    });
  };

  return {
    updateElementAppearance: (element, type, position) => {
      _defineType(element, type);
      _createElementTypeOverlay(element, type, position);
    },

    updateEventAppearance: (element, type) => {
      _defineType(element, type);
    },

    addOwnersOverlay: (element, shouldOwnersBeVisible, shouldHighlightUserTasks, currentUserId) => {
      let ownersToShow;
      if (shouldOwnersBeVisible) {
        ownersToShow = element.businessObject.extensionElements.values[0].values;
      } else if (shouldHighlightUserTasks) {
        ownersToShow = element.businessObject.extensionElements.values[0].values.filter(
          value => value.id === currentUserId
        );
      }
      overlays.remove({ element: element.id });
      overlays.add(element, {
        position: { bottom: -10, left: 0 },
        html: `<div
                                class="owners-overlay"
                            >
                                ${ownersToShow
                                  .map(owner => {
                                    if (shouldHighlightUserTasks && owner.id === currentUserId) {
                                      return `<p class="current">${owner.firstName} ${owner.lastName}</p>`;
                                    } else {
                                      return `<p>${owner.firstName} ${owner.lastName}</p>`;
                                    }
                                  })
                                  .join('')}
                            </div>`,
      });
    },
  };
};

export default ModelerDrawService;
