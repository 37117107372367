import React, { useEffect, useRef, useState } from 'react';
import { List, AutoSizer } from 'react-virtualized';
import User from './User';

function HSVtoRGB(h, s, v) {
  let r, g, b, i, f, p, q, t;
  if (arguments.length === 1) {
    (s = h.s), (v = h.v), (h = h.h);
  }
  i = Math.floor(h * 6);
  f = h * 6 - i;
  p = v * (1 - s);
  q = v * (1 - f * s);
  t = v * (1 - (1 - f) * s);
  switch (i % 6) {
    case 0:
      (r = v), (g = t), (b = p);
      break;
    case 1:
      (r = q), (g = v), (b = p);
      break;
    case 2:
      (r = p), (g = v), (b = t);
      break;
    case 3:
      (r = p), (g = q), (b = v);
      break;
    case 4:
      (r = t), (g = p), (b = v);
      break;
    case 5:
      (r = v), (g = p), (b = q);
      break;
  }
  return [Math.round(r * 255), Math.round(g * 255), Math.round(b * 255)];
}

function perc2color(i) {
  let rgb = HSVtoRGB(0, i / 100, 1);
  return 'rgb(' + rgb[0] + ',' + rgb[1] + ',' + rgb[2] + ')';
}

function decay(C, k, t) {
  return C * (1 - Math.exp(-k * t));
}

const Chats = ({ mode, availChats, loggedInUser, currentChatId }) => {
  const chatsRef = useRef(availChats);
  chatsRef.current = availChats;
  let checkUrgency = (item, oldUrgencyColor, setUrgencyColor) => {
    let urgencyColor = '';
    if (item.is_open && !item.resolved && (item.supportPerson === null || item.supportPerson === loggedInUser)) {
      // console.log('checkUrgency', item, state);
      let now = Math.floor(Date.now() / 1000);
      if (item.lockExpirationTime != null && now <= item.lockExpirationTime && item.type === 'client') {
        urgencyColor = '#E5E4E2';
      } else if (
        item.seen !== 'seen' &&
        (item.dialog_failed || item.isSysHandover) &&
        (item.supportPerson === null || item.supportPerson === loggedInUser)
      ) {
        urgencyColor = perc2color(50);
      } else if (
        (item.seen === 'notSeen' || (item.seen === 'seenBot' && item.lockExpirationTime != null)) &&
        item.type === 'client'
      ) {
        let maxTime = 300; // 5 minutes
        let timeDelta = Math.min(maxTime, now - item.latestTime);
        let urgencyPercentage = decay(40, 0.01, timeDelta);
        urgencyColor = perc2color(urgencyPercentage);
      } else if (item.seen !== 'seen' && oldUrgencyColor === perc2color(50)) {
        urgencyColor = oldUrgencyColor;
      }
    }

    if (urgencyColor === oldUrgencyColor || (!urgencyColor && !oldUrgencyColor)) return;
    setUrgencyColor(urgencyColor);
  };

  const getUrgencyColor = (item, previousUrgencyColor) => {
    let urgencyColor = undefined;
    let newOne = false;
    const callback = color => {
      urgencyColor = color;
      newOne = true;
    };
    checkUrgency(item, previousUrgencyColor, callback);

    return { urgencyColor, newOne };
  };

  const [colors, setColorsInner] = useState(chatsRef.current.map(chat => getUrgencyColor(chat, undefined)));
  const refTimer = useRef(null);
  const colorsRef = useRef(colors);
  colorsRef.current = colors;
  const setColors = clrs => {
    colorsRef.current = clrs;
    setColorsInner(clrs);
  };

  const checkUrgencyForChats = () => {
    let needToUpdate = false;
    const newColors = chatsRef.current.map((item, i) => {
      let thisColor = undefined;
      if (i < colorsRef.current.length) {
        thisColor = colorsRef.current[i];
      }
      const newColorObj = getUrgencyColor(item, colorsRef.current[i]);
      needToUpdate = needToUpdate || newColorObj.newOne;
      return newColorObj.newOne ? newColorObj.urgencyColor : thisColor;
    });
    if (needToUpdate) setColors(newColors);
  };

  useEffect(() => {
    refTimer.current = setInterval(checkUrgencyForChats, 1000);
    return () => {
      if (refTimer.current) {
        clearInterval(refTimer.current);
      }
    };
  }, []);

  useEffect(() => {
    const needToUpdate = colorsRef.current.length < availChats.length;
    while (colorsRef.current.length < availChats.length) {
      colorsRef.current.unshift(undefined); // insert at first place
    }
    if (needToUpdate) {
      setColors(colorsRef.current);
    }
  }, [availChats.length]);

  return (
    <div className="row sideBar">
      <AutoSizer>
        {({ height, width }) => {
          return (
            <List
              width={width}
              height={height - 75}
              rowHeight={96}
              rowCount={availChats.length}
              rowRenderer={({ index, key, style }) => {
                const item = availChats[index];
                return (
                  <User
                    mode={mode}
                    currentChatId={currentChatId}
                    style={style}
                    key={key}
                    urgencyColor={colorsRef.current[index]}
                    clientAvatar={item.clientAvatar}
                    userName={item.userName}
                    latestTime={item.latestTime}
                    resolved={item.resolved}
                    userId={item.userId}
                    seen={item.seen}
                    is_open={item.is_open}
                    chatId={item.chatId}
                    latestMessage={item.latestMessage}
                    supportPerson={item.supportPerson}
                    solutionChosen={item.ttl_code_chosen}
                    customer_segment={item.customer_segment}
                    lockExpirationTime={item.lockExpirationTime}
                    dialog_failed={item.dialog_failed}
                    loggedInUser={loggedInUser}
                    groups={item.groups}
                  />
                );
              }}
            />
          );
        }}
      </AutoSizer>
    </div>
  );
};

export default Chats;
