const createMessageLabel = (chatId, messageId, labelId, langLabel, labelMode) => {
  return {
    type: 'CREATE_MESSAGE_LABEL',
    chatId,
    messageId,
    labelId,
    langLabel,
    labelMode,
  };
};

const createMessageLabelExample = (labelId, message, userId, lang) => {
  return {
    type: 'CREATE_MESSAGE_LABEL_EXAMPLE',
    labelId,
    message,
    userId,
    lang,
  };
};
const addMessageLabel = label => {
  return {
    type: 'ADD_MESSAGE_LABEL',
    label,
  };
};

const deleteMessageLabel = id => {
  return {
    type: 'DELETE_MESSAGE_LABEL',
    id: id,
  };
};

const removeMessageLabel = label => {
  return {
    type: 'REMOVE_MESSAGE_LABEL',
    label,
  };
};

const labellingActions = {
  createMessageLabel,
  addMessageLabel,
  deleteMessageLabel,
  removeMessageLabel,
  createMessageLabelExample,
};

export default labellingActions;
