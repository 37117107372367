class Timer {
  constructor() {
    this.timers = {};
  }

  report() {
    let total = 0;
    let min = Date.now();
    let max = 0;
    Object.keys(this.timers).forEach(name => {
      if (this.timers[name].end) {
        if (this.timers[name].start < min) {
          min = this.timers[name].start;
        }
        if (this.timers[name].end > max) {
          max = this.timers[name].end;
        }
        const duration = this.timers[name].end - this.timers[name].start;
        total += duration;
        console.log(`${name} took ${duration}ms`);
      }
    });
    console.log(`time: ${max - min}ms, total durations: ${total}ms`);
  }

  start(name) {
    this.timers[name] = {
      start: Date.now(),
      end: null
    }
  }

  end(name) {
    if (!this.timers[name]) {
      return;
    }
    this.timers[name].end = Date.now();
    console.log(`${name} took ${this.timers[name].end - this.timers[name].start}ms`);
  }
}

export const timer = new Timer();
