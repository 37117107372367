import { put, call, all, take, takeLatest, spawn, select } from 'redux-saga/effects';

import DiagramVersionsService from '../services/DiagramVersionsService';
import NotificationService from '../services/common/NotificationService';
import modalsActions from '../action-creators/modalsActions';
import currentDiagramAndVersionActions from '../action-creators/currentDiagramAndVersionActions';
import DiagramsService from '../services/DiagramsService';
import saveProgressActions from '../action-creators/saveProgressActions';
import diagramVersionsActions from '../action-creators/diagramVersionsActions';
import dbActions from '../action-creators/dbActions';
import {timer} from "../helpers/timer";

const currentDiagramAndVersionActionDataCreator = state => {
  return {
    diagram: state.app.currentDiagram,
    shouldSave: true,
  };
};

const currentOptionalComment = state => state.app.currentOptionalDiagramComment;
const currentDiagramShouldReview = state => state.app.currentDiagramShouldReview;

const getCurrentDiagramVersionId = state => state.app.currentDiagramVersion.id;
const getCurrentUser = state => state.users.currentUser;

function* saveDiagramAndVersionFlow(action) {
  timer.start("Save diagram and version");
  try {
    yield put(saveProgressActions.updateSaveProgress({ saveInProgress: true, saveProgressStatus: 15 }));
    const diagramNameResponse = yield call(DiagramsService.updateDiagram, {
      diagramId: action.diagramId,
      diagramName: action.diagramName,
      owner: action.owner,
    });
    yield put(saveProgressActions.updateSaveProgress({ saveInProgress: true, saveProgressStatus: 25 }));
    const currentComment = yield select(currentOptionalComment);
    const diagramShouldReview = yield select(currentDiagramShouldReview);
    const versionId = yield select(getCurrentDiagramVersionId);
    const diagramVersionResponse = yield call(DiagramVersionsService.createDiagramVersion, {
      diagramId: action.diagramId,
      label: action.versionLabel,
      createdBy: action.createdBy,
      file: action.file,
      comment: currentComment,
      shouldReview: diagramShouldReview,
      diagramType: action.diagramVersion ? action.diagramVersion.diagramType : 'chatbot',
      baseVersionId: versionId,
    });

    if ([200, 204].includes(diagramNameResponse.status) && diagramVersionResponse.status === 200) {
      yield put(saveProgressActions.updateSaveProgress({ saveInProgress: true, saveProgressStatus: 75 }));
      const actionData = yield select(currentDiagramAndVersionActionDataCreator);
      yield put(
        currentDiagramAndVersionActions.changeCurrentDiagramAndVersion(
          Object.assign({}, actionData, { versionId: diagramVersionResponse.data['new_version'].id })
        )
      );
      yield put(saveProgressActions.updateSaveProgress({ saveInProgress: true, saveProgressStatus: 100 }));

      if (diagramNameResponse.status === 200) {
        yield put(modalsActions.closeModal('localDiagramChanges'));
      } else if (diagramNameResponse.status === 204) {
        if (action.makeTest) {
          yield put(
            diagramVersionsActions.changeDiagramVersionStatus({
              versionId: diagramVersionResponse.data['new_version'].id,
              forDiagram: 'selected',
              statusAction: 'test',
              shouldProceed: true,
            })
          );
        }
      }
      yield put(dbActions.deleteFromIndexedDB(action.diagramId));
    } else if (diagramNameResponse.status === 409 || diagramVersionResponse.status === 409) {
      const currentUser = yield select(getCurrentUser);
      yield put(
        modalsActions.openModal(
          'diagramCreationConflict',
          currentDiagramAndVersionActions.saveCurrentDiagramAndVersion,
          {
            diagramId: action.diagramId,
            diagramName: action.diagramName,
            versionLabel: action.versionLabel,
            file: action.file,
            owner: currentUser,
            diagramVersion: action.diagramVersion,
          },
          {
            isConflictInName: diagramNameResponse.status === 409,
            isConflictInLabel: diagramVersionResponse.status === 409,
          },
          saveProgressActions.updateSaveProgress,
          {
            saveInProgress: false,
            saveProgressStatus: 0,
          }
        )
      );
    }
    yield put(currentDiagramAndVersionActions.updateCurrentOptionalComment(''));
  } catch (error) {
    console.log('ERROR on save: ', error);
    NotificationService.push({
      type: 'error',
    });
  }
  timer.end("Save diagram and version");
}

function* watchSaveDiagramAndVersionRequested() {
  yield takeLatest('SAVE_CURRENT_DIAGRAM_AND_VERSION', saveDiagramAndVersionFlow);
}

export default function* saveDiagramAndVersionSaga() {
  yield all([watchSaveDiagramAndVersionRequested()]);
}
