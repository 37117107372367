import AutoLayout from './AutoLayout';

export const computeBranchesAndUpdateLayout = async diagramXML => {
  let autoLayout = new AutoLayout();
  let branchTree = await autoLayout.layoutProcess(diagramXML);
  return branchTree;
};

export const getDiagramVersionTranslations = async ({ diagramXML, diagramData, description }) => {
  const autoLayout = new AutoLayout();
  return await autoLayout.getProcessTranslations({diagramXML, diagramData, description});
};

export const filterTranslations = (moddle, translations) => {
  const autoLayout = new AutoLayout();
  autoLayout.filterGhostTranslations(translations, moddle._definitions.rootElements[0]);
  return translations;
};
