import {useRef, useEffect} from "react";

export function useIsMounted() {
  const isMounted = useRef(false);
  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);

  const executeIfMounted = (callback) => {
    if (isMounted.current) {
      callback();
    }
  }

  return {isMounted, executeIfMounted};
}
