import React from 'react';
import { connect } from 'react-redux';

import modalsActions from '../../action-creators/modalsActions';
import UnsavedChangesModal from '../../components/modals/UnsavedChangesModal';
import DiagramCreationConflictModal from '../../components/modals/DiagramCreationConflictModal';
import currentDiagramAndVersionActions from '../../action-creators/currentDiagramAndVersionActions';
import ConfirmSaveModal from '../../components/modals/ConfirmSaveModal';
import ConfirmLiveModal from '../../components/modals/ConfirmLiveModal';
import ConfirmDeleteVersionModal from '../../components/modals/ConfirmDeleteModal';
import LocalDiagramChangesModal from '../../components/modals/LocalDiagramChangesModal';

const ModalsContainer = ({
  unsavedChangesModal,
  localDiagramChangesModal,
  diagramCreationConflictModal,
  diagramUpdateConflictModal,
  confirmSaveModal,
  confirmLiveModal,
  confirmDeleteVersionModal,
  proceedOnModalConfirm,
  proceedOnModalCancel,
  closeModal,
  currentDiagramName,
  currentVersionLabel,
  updateCurrentDiagramName,
  updateCurrentVersionLabel,
  currentOptionalComment,
  updateCurrentOptionalComment,
  currentDiagramShouldReview,
  updateCurrentDiagramShouldReview,
}) => {
  return (
    <>
      {unsavedChangesModal.isOpen && (
        <UnsavedChangesModal
          proceedOnModalConfirm={proceedOnModalConfirm}
          closeModal={closeModal}
          {...unsavedChangesModal}
        />
      )}
      {localDiagramChangesModal.isOpen && (
        <LocalDiagramChangesModal
          proceedOnModalConfirm={proceedOnModalConfirm}
          proceedOnModalCancel={proceedOnModalCancel}
          {...localDiagramChangesModal}
        />
      )}
      {diagramCreationConflictModal.isOpen && (
        <DiagramCreationConflictModal
          proceedOnModalConfirm={proceedOnModalConfirm}
          proceedOnModalCancel={proceedOnModalCancel}
          closeModal={closeModal}
          currentDiagramName={currentDiagramName}
          currentVersionLabel={currentVersionLabel}
          updateCurrentDiagramName={updateCurrentDiagramName}
          updateCurrentVersionLabel={updateCurrentVersionLabel}
          {...diagramCreationConflictModal}
        />
      )}
      {confirmSaveModal.isOpen && (
        <ConfirmSaveModal
          proceedOnModalConfirm={proceedOnModalConfirm}
          proceedOnModalCancel={proceedOnModalCancel}
          closeModal={closeModal}
          currentOptionalComment={currentOptionalComment}
          updateCurrentOptionalComment={updateCurrentOptionalComment}
          currentDiagramShouldReview={currentDiagramShouldReview}
          updateCurrentDiagramShouldReview={updateCurrentDiagramShouldReview}
          {...confirmSaveModal}
        />
      )}
      {confirmLiveModal.isOpen && (
        <ConfirmLiveModal proceedOnModalConfirm={proceedOnModalConfirm} closeModal={closeModal} {...confirmLiveModal} />
      )}
      {confirmDeleteVersionModal.isOpen && (
        <ConfirmDeleteVersionModal
          proceedOnModalConfirm={proceedOnModalConfirm}
          closeModal={closeModal}
          {...confirmDeleteVersionModal}
        />
      )}
    </>
  );
};

const mapStateToProps = state => {
  return {
    unsavedChangesModal: state.modals.unsavedChangesModal,
    localDiagramChangesModal: state.modals.localDiagramChangesModal,
    diagramCreationConflictModal: state.modals.diagramCreationConflictModal,
    diagramUpdateConflictModal: state.modals.diagramUpdateConflictModal,
    confirmSaveModal: state.modals.confirmSaveModal,
    confirmLiveModal: state.modals.confirmLiveModal,
    confirmDeleteVersionModal: state.modals.confirmDeleteVersionModal,

    currentDiagramName: state.app.currentDiagram.name,
    currentVersionLabel: state.app.currentDiagramVersion.label,

    currentOptionalComment: state.app.currentOptionalDiagramComment,
    currentDiagramShouldReview: state.app.currentDiagramShouldReview,
  };
};

const mapDispatchToProps = {
  ...modalsActions,

  updateCurrentDiagramName: currentDiagramAndVersionActions.updateCurrentDiagramName,
  updateCurrentVersionLabel: currentDiagramAndVersionActions.updateCurrentDiagramVersionLabel,
  updateCurrentOptionalComment: currentDiagramAndVersionActions.updateCurrentOptionalComment,
  updateCurrentDiagramShouldReview: currentDiagramAndVersionActions.updateCurrentDiagramShouldReview,
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalsContainer);
