const ENABLE_OVERVIEW_MOCK = process.env.ENABLE_OVERVIEW_MOCK || 'false';

const initialLabelsState = {
  labels: undefined,
  labelVersions: undefined,
  currentLabelId: undefined,
  tableContent: ENABLE_OVERVIEW_MOCK
    ? [
        {
          label: 'Name',
          key: 'name',
        },
        {
          label: 'Labels',
          key: 'labels',
        },
        {
          label: 'Solution Flow',
          key: 'sfm',
        },
        {
          label: 'Description',
          key: 'description',
        },
        {
          label: 'AI: trained',
          key: 'accuracy',
        },
        {
          label: 'AI: keywords',
          key: 'f1_keywords',
        },
        {
          label: 'FCR',
          key: 'fcr',
        },
        {
          label: 'Volume',
          key: 'volume',
        },
        {
          label: 'Solution Review',
          key: 'solutionReview',
        },
        {
          label: 'Owner',
          key: 'owner',
        },
      ]
    : [
        {
          label: 'Name',
          key: 'name',
        },
        // {
        //     label: "Labels",
        //     key: "labels"
        // },
        {
          label: 'Solution Flow',
          key: 'sfm',
        },
        {
          label: 'Description',
          key: 'description',
        },
      ],
};

export default initialLabelsState;
