import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import ElementProperty from '../../components/shared/element-property/ElementProperty';
import usersActions from '../../action-creators/usersActions';

const ElementPropertyContainer = ({
  modeling,
  moddle,
  canvas,
  currentElement,
  index,
  elementPropKey,
  type,
  label,
  value,
  editable,
  options,
  values = [],
  onChangeEffect = () => {},
  isBlocked = false,
  reviewMode,
  extensionElements,
  owners,
  ownersList,
  ownersSearchString,
  fetchUsersList,
  updateOwnersSearchString,
  updateOwnersList,
  templateHeight,
  optionsKey
}) => {
  // This container was primarily created to
  // support update on blur for inputs
  // This is done via hook state for value:
  const [currentValue, changeCurrentValue] = useState(value);
  const [isOpen, changeIsOpen] = useState(false);

  const [isEditorExpanded, changeEditorExpandState] = useState(false);

  useEffect(() => {
    changeCurrentValue(value);
  }, [value]);

  return (
    <ElementProperty
      canvas={canvas}
      modeling={modeling}
      moddle={moddle}
      currentElement={currentElement}
      options={options}
      index={index}
      value={value}
      values={values}
      type={type}
      label={label}
      editable={editable}
      elementPropKey={elementPropKey}
      onChangeEffect={onChangeEffect}
      isBlocked={isBlocked || reviewMode}
      extensionElements={extensionElements}
      owners={owners}
      ownersList={ownersList}
      ownersSearchString={ownersSearchString}
      fetchUsersList={fetchUsersList}
      updateOwnersSearchString={updateOwnersSearchString}
      updateOwnersList={updateOwnersList}
      currentValue={currentValue}
      changeCurrentValue={changeCurrentValue}
      isOpen={isOpen}
      changeIsOpen={changeIsOpen}
      isEditorExpanded={isEditorExpanded}
      changeEditorExpandState={() => changeEditorExpandState(!isEditorExpanded)}
      templateHeight={templateHeight}
      optionsKey={optionsKey}
    />
  );
};

export const mapStateToProps = state => {
  return {
    owners: state.users.owners,
    ownersList: state.users.ownersList,
    ownersSearchString: state.users.ownersSearchString,
  };
};

const mapDispatchToProps = {
  fetchUsersList: usersActions.fetchUsersList,
  updateOwnersSearchString: usersActions.updateOwnersSearchString,
  updateOwnersList: usersActions.updateOwnersList,
};

export default connect(mapStateToProps, mapDispatchToProps)(ElementPropertyContainer);
