export const getSenderCaptionFromChannelAndRole = (role, channel) => {
  if (role === 'client' && channel === 'call') return 'Client to bot (transcribed)';
  if (role === 'client' && (channel === 'text' || !channel)) return 'Client';
  if (role === 'client' && channel === 'cs_assist_chat') return 'Client to CS (transcribed)';

  if (role === 'bot' && channel === 'call') return 'Callbot';
  if (role === 'bot' && (channel === 'text' || !channel || channel === 'error')) return 'Chatbot';
  if (role === 'bot' && channel === 'cs_assist_chat') return 'CS assist';

  if (role === 'remark') return 'System Message';

  if ((role === 'support' || role === 'elisa') && channel === 'call') return 'Customer Support (transcribed)';
  if (role === 'support' || role === 'elisa') return 'Customer Support';
  if ((role === 'support' || role === 'elisa') && (channel === 'chat' || !channel)) return 'Customer Support';
  if ((role === 'support' || role === 'elisa') && channel === 'cs_assist_chat') return 'Customer Support (chat)';
  return '';
};

export const getClassNameFromChannelAndRole = (role, channel) => {
  if (role === 'bot' && channel === 'call') return 'bot-message-call';
  if (role === 'bot' && (channel === 'text' || !channel || channel === 'error')) return 'bot-message';
  if (role === 'bot' && channel === 'cs_assist_chat') return 'bot-message-cs-assist-chat';

  if (role === 'remark') return 'system-message';

  if ((role === 'support' || role === 'elisa') && channel === 'call') return 'support-message-call';
  if ((role === 'support' || role === 'elisa') && (channel === 'text' || !channel)) return 'support-message';
  if ((role === 'support' || role === 'elisa') && channel === 'cs_assist_chat') return 'support-message-cs-assist-chat';
  return '';
};
