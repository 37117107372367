import initialUsersState from '../constants/initial-state-parts/initialUsersState';

const usersReducer = (state = initialUsersState, action) => {
  switch (action.type) {
    case 'UPDATE_OWNERS_SEARCH_STRING':
      return Object.assign({}, state, {
        ownersSearchString: action.ownersSearchString,
      });
    case 'UPDATE_OWNERS_LIST':
      return Object.assign({}, state, {
        owners: Object.assign([], action.owners),
      });
    case 'UPDATE_CURRENT_USER':
      return Object.assign({}, state, {
        currentUser: Object.assign([], action.currentUser),
      });
    default:
      return state;
  }
};

export default usersReducer;
