import React from 'react';
import styled from 'styled-components';
import * as mixins from '../../styles/mixins';
import * as colors from '../../styles/colors';
import * as typography from '../../styles/typography';

import FlexibleWrapper from '../shared/FlexibleWrapper';
import FolderIcon from '../shared/icons/tools/FolderIcon';

const ButtonTitle = styled.p`
  width: 0;
  max-height: 48px;
  opacity: 0;
  margin-left: 0;
  color: ${colors.mainFontColor};
  ${mixins.fontStyle(typography.textFontSize, typography.medium)};
  pointer-events: none;
`;

const ButtonWrapper = styled(FlexibleWrapper)`
  margin-right: 8px;
  cursor: pointer;

  &:hover > ${ButtonTitle} {
    width: auto;
    opacity: 1;
    margin-left: 10px;
  }

  ${props =>
    props.isFixed
      ? `
            & > ${ButtonTitle} {
                width: auto;
                opacity: 1;
                margin-left: 10px;
            }
        `
      : ``}
`;

const VersionControlButton = ({ toggleVersionControlVisibility, fetchDiagramsList = () => {}, isFixed = false }) => {
  const onButtonClick = () => {
    // Start saga to fetch diagrams list
    fetchDiagramsList();
    // Toggle version control panel to open
    toggleVersionControlVisibility();
  };

  return (
    <ButtonWrapper onClick={onButtonClick} isFixed={isFixed}>
      <FolderIcon color={colors.mainIconColor} />
      <ButtonTitle>Solution flow version control</ButtonTitle>
    </ButtonWrapper>
  );
};

export default VersionControlButton;
