export function deepClone(object) {
  if (!object || typeof object !== 'object') {
    return object;
  }

  const newObject = Array.isArray(object) ? [] : {};

  for (const key in object) {
    if (object.hasOwnProperty(key)) {
      newObject[key] = deepClone(object[key]);
    }
  }

  return newObject;
}
