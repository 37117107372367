import { put, call, all, take, takeLatest, spawn, select } from 'redux-saga/effects';

import DiagramVersionsService from '../services/DiagramVersionsService';
import expandedDiagramActions from '../action-creators/expandedDiagramActions';
import NotificationService from '../services/common/NotificationService';

const getExpandedCount = state => state.app.expandedDiagramLastVersionsCount;

const getExpandedDiagramId = state => state.app.expandedDiagram.id;

function* changeExpandedDiagramFlow(action) {
  try {
    const expandedCount = yield select(getExpandedCount);
    const response = yield call(DiagramVersionsService.getDiagramVersions, {
      diagramId: action.diagram.id,
      count: expandedCount,
      startFrom: 0,
    });
    if (response.status === 200) {
      const diagramVersions = response.data['versions'];
      const hasMore = response.data['has_next'];
      yield put(
        expandedDiagramActions.updateExpandedDiagramWithVersions(
          action.diagram,
          diagramVersions,
          hasMore,
          action.shouldCollapse
        )
      );
      // NotificationService.push({
      //     type: 'success',
      //     message: 'Uploaded diagram versions for chosen diagram successfully!'
      // });
    } else {
      NotificationService.push({
        type: 'error',
      });
    }
  } catch (error) {
    NotificationService.push({
      type: 'error',
    });
  }
}

function* watchChangeExpandedDiagramRequested() {
  yield takeLatest('CHANGE_EXPANDED_DIAGRAM', changeExpandedDiagramFlow);
}

function* loadMoreExpandedDiagramVersionsFlow(action) {
  try {
    const response = yield call(DiagramVersionsService.getDiagramVersions, {
      diagramId: action.diagramId,
      count: 3,
      startFrom: action.startFrom,
    });
    if (response.status === 200) {
      const diagramVersions = response.data['versions'];
      const hasMore = response.data['has_next'];
      const expandedCount = yield select(getExpandedCount);
      yield put(expandedDiagramActions.updateExpandedDiagramVersions(diagramVersions, hasMore, expandedCount + 3));
      // NotificationService.push({
      //     type: 'success',
      //     message: 'Uploaded extra diagram versions for chosen diagram successfully!'
      // });
    } else {
      NotificationService.push({
        type: 'error',
      });
    }
  } catch (error) {
    NotificationService.push({
      type: 'error',
    });
  }
}

function* watchLoadMoreExpandedDiagramVersionsRequested() {
  yield takeLatest('LOAD_MORE_EXPANDED_DIAGRAM_VERSIONS', loadMoreExpandedDiagramVersionsFlow);
}

function* showLessExpandedDiagramVersionsFlow(action) {
  try {
    const expandedDiagramId = yield select(getExpandedDiagramId);
    const response = yield call(DiagramVersionsService.getDiagramVersions, {
      diagramId: expandedDiagramId,
      count: action.newCount,
      startFrom: 0,
    });
    if (response.status === 200) {
      const diagramVersions = response.data['versions'];
      const hasMore = response.data['has_next'];
      yield put(expandedDiagramActions.updateExpandedDiagramVersions(diagramVersions, hasMore, 3, true));
      // NotificationService.push({
      //     type: 'success',
      //     message: 'Uploaded extra diagram versions for chosen diagram successfully!'
      // });
    } else {
      NotificationService.push({
        type: 'error',
      });
    }
  } catch (error) {
    NotificationService.push({
      type: 'error',
    });
  }
}

function* watchShowLessExpandedDiagramVersionsRequested() {
  yield takeLatest('SHOW_LESS_EXPANDED_DIAGRAM_VERSIONS', showLessExpandedDiagramVersionsFlow);
}

export default function* expandedDiagramSaga() {
  yield all([
    watchChangeExpandedDiagramRequested(),
    watchLoadMoreExpandedDiagramVersionsRequested(),
    watchShowLessExpandedDiagramVersionsRequested(),
  ]);
}
