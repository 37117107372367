const initialState = {
  diagramsList: [],
  labels: [],
  currentDiagram: {
    id: -1,
    name: 'Untitled solution flow',
    owner_id: null,
    versionInLive: null,
    versionInTest: null,
    lastVersion: null,
  },
  currentDiagramVersion: {
    id: -1,
    label: 'no-label',
    file: '<?xml version="1.0" encoding="UTF-8"?>\n<bpmn2:definitions xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xmlns:bpmn2="http://www.omg.org/spec/BPMN/20100524/MODEL" xmlns:bpmndi="http://www.omg.org/spec/BPMN/20100524/DI" xmlns:dc="http://www.omg.org/spec/DD/20100524/DC" id="sample-diagram" targetNamespace="http://bpmn.io/schema/bpmn" xsi:schemaLocation="http://www.omg.org/spec/BPMN/20100524/MODEL BPMN20.xsd"><bpmn2:process id="Process_1" isExecutable="false"><bpmn2:startEvent id="StartEvent_1" leaf="true" branches="[1]" name="Start" /></bpmn2:process><bpmndi:BPMNDiagram id="BPMNDiagram_1"><bpmndi:BPMNPlane id="BPMNPlane_1" bpmnElement="Process_1"><bpmndi:BPMNShape id="_BPMNShape_StartEvent_2" bpmnElement="StartEvent_1"><dc:Bounds x="807" y="812" width="36" height="36" /><bpmndi:BPMNLabel><dc:Bounds x="791" y="855" width="69" height="14" /></bpmndi:BPMNLabel></bpmndi:BPMNShape></bpmndi:BPMNPlane></bpmndi:BPMNDiagram></bpmn2:definitions>',
    diagramType: "chatbot"
  },
  currentDiagramLastVersions: [],
  currentOptionalDiagramComment: '',
  currentDiagramShouldReview: false,
  isCurrentDiagramHasMoreVersions: false,
  isCurrentDiagramSaved: false,
  isCurrentDiagramVersionSaved: false,
  isCurrentDiagramVersionFileSaved: false,

  currentDiagramLastVersionsCount: 3,

  expandedDiagram: { id: -1 },
  expandedDiagramLastVersions: [],
  isExpandedDiagramHasMoreVersions: false,

  expandedDiagramLastVersionsCount: 3,

  searchString: '',
  diagramsSearchList: [],

  currentTask: undefined,
  currentTaskBusinessObject: undefined,

  currentSubProcess: undefined,
  currentSubProcessBusinessObject: undefined,
  currentSubProcessDiagramVersions: [],
  currentSubProcessFile: undefined,

  currentEvent: undefined,
  currentEventBusinessObject: undefined,

  backendDump: {
    currentDiagram: {
      id: -1,
      name: 'Untitled solution flow 2',
      versionInLive: null,
      versionInTest: null,
      lastVersion: null,
    },
    currentDiagramVersion: {
      id: -1,
      label: 'new-version-',
      file: '',
    },
    currentDiagramLastVersions: [],
    isCurrentDiagramHasMoreVersions: false,
  },
  currentFileState: '',
};

export default initialState;
