import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import * as mixins from '../../../styles/mixins';
import * as colors from '../../../styles/colors';
import * as typography from '../../../styles/typography';

import FlexibleWrapper from '../../shared/FlexibleWrapper';
import SearchIcon from '../../shared/icons/tools/SearchIcon';

const SearchWrapper = styled(FlexibleWrapper)``;

const SearchInput = styled.input`
  width: 212px;
  margin-left: 15px;
  pointer-events: all;
  height: 24px;
  color: ${colors.extraFontColor};
  ${mixins.fontStyle(typography.textFontSize, typography.normal)};
  ${mixins.placeholder(colors.placeholderColor)};

  ${props => (props.isOpen ? 'display: block' : 'display: none')};
`;

const VersionControlSearch = ({ searchString, searchDiagrams, isOpen }) => {
  const [searchTerm, setSearchTerm] = useState(searchString);
  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      searchDiagrams(searchTerm);
    }, 800);

    return () => clearTimeout(delayDebounceFn);
  }, [searchTerm]);

  return (
    <SearchWrapper>
      <SearchIcon color={colors.mainIconColor} />
      <SearchInput
        dir="auto"
        id="diagram-list-search"
        key={Math.random()}
        value={searchTerm}
        onChange={event => setSearchTerm(event.target.value)}
        placeholder="Search here"
        autoFocus={!!isOpen}
        isOpen={isOpen}
      />
    </SearchWrapper>
  );
};

export default VersionControlSearch;
