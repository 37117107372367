const toggleModal = (modalName, args) => {
  return {
    type: 'TOGGLE_MODAL',
    modalName: modalName,
    args,
  };
};

const changeModalError = error => {
  return {
    type: 'CHANGE_MODAL_ERROR',
    modalError: error,
  };
};

const modalsActions = {
  toggleModal,
  changeModalError,
};

export default modalsActions;
