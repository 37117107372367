import { ElementPropOption, ElementPropSelect } from "./utils/CommonElements";
import { useLoading } from "./utils/CommonFunctions";
import React from "react";

export const DiagramVersionId = ({
  value, isBlocked, values, modeling, currentElement, elementPropKey, onChangeEffect
}) => {
  const text = useLoading('Select diagram version', values.length > 0);

  return (
    <ElementPropSelect
      value={value || '-1'}
      disabled={isBlocked}
      onChange={e => {
        if (e.target.value === '-1') {
          return;
        }
        const selectedDiagramVersion =
          e.target.value === '0' ? values[0] : values.filter(value => value.id.toString() === e.target.value)[0];
        modeling.updateProperties(currentElement, {
          [elementPropKey]: e.target.value,
          diagramVersionLabel: selectedDiagramVersion.label,
        });
        onChangeEffect({
          diagramVersionId: selectedDiagramVersion.id,
          isLatest: e.target.value === '0',
          modeling: modeling,
          currentElement: currentElement,
        });
      }}
    >
      <ElementPropOption value="-1">{ text }</ElementPropOption>
      {values.map((value, index) =>
        index === 0 ? (
          <ElementPropOption key={index} value="0">
            Latest version
          </ElementPropOption>
        ) : (
          <ElementPropOption key={index} value={value.id.toString()}>
            {value.label} ({value.id.toString()})
          </ElementPropOption>
        )
      )}
    </ElementPropSelect>
  );
}