export const commonTaskProps = [
  /*{
        key: 'id',
        label: 'id',
        type: 'id',
        editable: false
    },*/
  {
    key: 'owners',
    label: 'owners',
    type: 'owners',
    editable: true,
    values: [
      {
        id: '1',
        name: 'John',
        surname: 'Wick',
        role: 'admin',
      },
      {
        id: '2',
        name: 'Leslie',
        surname: 'Johnson',
        role: 'editor',
      },
      {
        id: '3',
        name: 'John',
        surname: 'McHulligan',
        role: 'admin',
      },
      {
        id: '4',
        name: 'Ricky',
        surname: 'Lanson',
        role: 'visitor',
      },
    ],
  },
];
