import { ElementPropOption, ElementPropSelect } from "./utils/CommonElements";
import React, { useEffect, useState } from "react";
import { useLoading } from "./utils/CommonFunctions";
import { key } from '../../../../../common/utils/sort';

export const DiagramId = ({
  value, values, modeling, elementPropKey, currentElement, onChangeEffect
}) => {
  const text = useLoading('Select diagram', values.length > 0);
  const [sortedValues, setSortedValues] = useState(values);

  useEffect(() => {
    const innerValues = [...values];
    innerValues.sort(key(diagram => diagram.name));
    setSortedValues(innerValues);
  }, [values.length]);

  return (
    <ElementPropSelect
      value={value || 0}
      onChange={e => {
        if (e.target.value !== 0) {
          const selectedDiagram = values.filter(value => value.id.toString() === e.target.value)[0];
          const selectedVersion = selectedDiagram.versionInLive || selectedDiagram.lastVersion;
          modeling.updateProperties(currentElement, {
            [elementPropKey]: e.target.value,
            diagramName: selectedDiagram.name,
            diagramVersionLabel: selectedVersion.label,
          });
          onChangeEffect({
            diagramVersionId: selectedVersion.id,
            modeling: modeling,
            currentElement: currentElement,
            isDiagramSelect: true,
            isLatest: true,
            diagramId: selectedDiagram.id,
          });
        }
      }}
    >
      <ElementPropOption value={0}>{ text }</ElementPropOption>
      {sortedValues.map((value, index) => (
        <ElementPropOption key={index} value={value.id.toString()}>
          {value.name}
        </ElementPropOption>
      ))}
    </ElementPropSelect>
  );
}