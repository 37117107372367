import BaseRenderer from 'diagram-js/lib/draw/BaseRenderer';

import {
  append as svgAppend,
  attr as svgAttr,
  classes as svgClasses,
  create as svgCreate,
  remove as svgRemove,
  innerSVG as svgInnerAppend,
} from 'tiny-svg';

import { getRoundRectPath } from 'bpmn-js/lib/draw/BpmnRenderUtil';

import { is } from 'bpmn-js/lib/util/ModelUtil';
import { isAny } from 'bpmn-js/lib/features/modeling/util/ModelingUtil';

import modeling from 'bpmn-js/lib/features/modeling';

const HIGH_PRIORITY = 1500;

export default class CallActivityRenderer extends BaseRenderer {
  constructor(eventBus, bpmnRenderer) {
    super(eventBus, HIGH_PRIORITY);

    this.bpmnRenderer = bpmnRenderer;
    // this.modeling = bpmnRenderer.get('modeling');

    // this.maxHeight = 0;
    this.elementWidth = 120;
    this.currentZoomLevel = 1;
  }

  canRender(element) {
    return is(element, 'bpmn:CallActivity');
  }

  svg_textMultiline(id, maxWidth, zoom) {
    var x = 10;
    var y = 0;
    var dy = 15;

    /* get the text */
    var element = document.getElementById(id);

    var text = element.innerHTML;

    var words = text.split(' ');
    var line = '';

    var constructedText = '';

    for (var n = 0; n < text.length; n++) {
      //
      element.innerHTML = '<tspan id="PROCESSING">busy</tspan >';

      var newTextLine = line + text[n];

      const newTextElem = document.getElementById('PROCESSING');

      newTextElem.innerHTML = newTextLine;
      var metrics = newTextElem.getBoundingClientRect();
      var newTextWidth = metrics.width;

      //

      if (newTextWidth > maxWidth - 2 * x * (zoom ? zoom : 1)) {
        if ((constructedText.match(/<tspan/g) || []).length > 0) {
          constructedText += '<tspan x="' + x + '" dy="' + dy + '">' + line + '</tspan>';
        } else {
          constructedText += '<tspan x="' + x + '" dy="' + y + '">' + line + '</tspan>';
        }
        line = text[n];
      } else {
        line = newTextLine;
      }

      document.getElementById('PROCESSING').remove();
    }
    if ((constructedText.match(/<tspan/g) || []).length > 0) {
      constructedText += '<tspan x="' + x + '" dy="' + dy + '">' + line + '</tspan>';
    } else {
      constructedText += '<tspan x="' + x + '" dy="' + y + '">' + line + '</tspan>';
    }

    element.innerHTML = constructedText;

    //
  }

  // Main function to draw custom shape

  drawShape(parentNode, element) {
    let shapeProcessing = this.bpmnRenderer.drawShape(parentNode, element);
    let element_id = `${element.id}${Math.floor(Math.random() * 10000)}`;

    this.lastActualWidth = shapeProcessing.getBoundingClientRect().width;
    //this.currentZoomLevel = 170/shapeProcessing.getBoundingClientRect().width;

    //

    const newText = svgCreate('text');
    svgAttr(newText, {
      id: `ResultTextFor${element_id}`,
      class: 'djs-label',
      x: 10,
      y: 35,
    });

    newText.appendChild(document.createTextNode(element.businessObject.diagramName || 'No diagram'));

    parentNode.appendChild(newText);

    const newLabelText = svgCreate('text');
    svgAttr(newLabelText, {
      id: `newLabelText`,
      class: 'djs-label-small',
      x: 10,
      y: 20,
    });

    newLabelText.appendChild(document.createTextNode('diagram name'));

    parentNode.appendChild(newLabelText);

    let zoom;
    if (shapeProcessing.getBoundingClientRect().width) {
      zoom = (shapeProcessing.getBoundingClientRect().width / this.elementWidth).toFixed(4);
    } else {
      zoom = 1;
    }

    let newClientRect;
    try {
      this.svg_textMultiline(`ResultTextFor${element_id}`, this.lastActualWidth, zoom);
      newClientRect = document.getElementById(`ResultTextFor${element_id}`).getBoundingClientRect();
    } catch (e) {
      newClientRect = {height: 15};
    }

    const newLabelText2 = svgCreate('text');
    svgAttr(newLabelText2, {
      id: `newLabelText`,
      class: 'djs-label-small',
      x: 10,
      y: 40 + (newClientRect.height || 15) * (1 / zoom),
    });

    newLabelText2.appendChild(document.createTextNode('version label'));

    parentNode.appendChild(newLabelText2);

    const newText2 = svgCreate('text');
    svgAttr(newText2, {
      id: `ExpressionTextFor${element_id}`,
      class: 'djs-label',
      x: 10,
      y: 55 + (newClientRect.height || 15) * (1 / zoom),
    });

    newText2.appendChild(document.createTextNode(element.businessObject.diagramVersionLabel || 'No version'));

    parentNode.appendChild(newText2);

    let MapMessageClientRect;
    try {
      this.svg_textMultiline(`ExpressionTextFor${element_id}`, this.lastActualWidth, zoom);

      MapMessageClientRect = document.getElementById(`ExpressionTextFor${element_id}`).getBoundingClientRect();
    } catch (e) {
      MapMessageClientRect = {height: 15};
    }

    let newHeight = Math.round(
      newClientRect.height + MapMessageClientRect.height + 60 * (zoom ? zoom : 1) > 80 * (zoom ? zoom : 1)
        ? newClientRect.height + MapMessageClientRect.height + 60 * (zoom ? zoom : 1)
        : 80 * (zoom ? zoom : 1)
    );
    let oldHeight = element.height;

    newHeight = zoom === 1 ? newHeight : newHeight * (1 / zoom);

    element.height = newHeight;

    svgAttr(shapeProcessing, {
      height: newHeight,
    });

    element.width = this.elementWidth;
    element.height = newHeight;
    element.y = element.y - (newHeight / 2 - oldHeight / 2);

    let shape = this.bpmnRenderer.drawShape(parentNode, element);

    svgAttr(parentNode.childNodes[8], {
      fillOpacity: 0,
      strokeWidth: 3,
      stroke: '#7828C8',
      class: 'djs-rect',
    });

    svgRemove(parentNode.childNodes[0]);
    svgRemove(parentNode.childNodes[1]);
    svgRemove(parentNode.childNodes[8]);

    drawRect(parentNode);

    this.currentZoomLevel = zoom;

    return shape;
  }
}

CallActivityRenderer.$inject = ['eventBus', 'bpmnRenderer'];

// helpers //////////

// copied from https://github.com/bpmn-io/bpmn-js/blob/master/lib/draw/BpmnRenderer.js
function drawRect(parentNode, width, height, borderRadius, strokeColor) {
  const g = svgCreate('g');

  svgAttr(g, {
    transform: 'translate(-10, -10)',
    fill: 'white',
  });

  /*const rect = svgCreate('rect');

    svgAttr(rect, {
      width: width,
      height: height,
      fill: "#fff"
    });*/

  //svgAppend(g, rect);

  const rect = '<rect width="24" height="23" fill="white"></rect>';

  const iconContent =
    '<path d="M3.00024 20.0002H9.00024L9.00024 3.99996H3.00024L3.00024 20.0002Z" stroke="#000F3C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>' +
    '<path d="M15.0002 17H21.0002V7H15.0002V17Z" stroke="#000F3C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>' +
    '<line x1="9.75024" y1="12.05" x2="14.2502" y2="12.05" stroke="#000F3C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>';

  svgInnerAppend(g, rect + iconContent);

  svgAppend(parentNode, g);

  return g;
}

// copied from https://github.com/bpmn-io/diagram-js/blob/master/lib/core/GraphicsFactory.js
function prependTo(newNode, parentNode, siblingNode) {
  parentNode.insertBefore(newNode, siblingNode || parentNode.firstChild);
}
