import { put, call, all, takeLatest } from 'redux-saga/effects';
import NotificationService from '../services/common/NotificationService';
import CleanUpService from '../services/CleanupService';

function* cleanUpFlow() {
  try {
    const response = yield call(CleanUpService.cleanup);
    if (response.status === 200) {
      NotificationService.push({
        type: 'info',
        message: 'Initialization Successful!',
      });
      window.location.href = '/';
    } else {
      NotificationService.push({
        type: 'error',
      });
    }
  } catch (error) {
    NotificationService.push({
      type: 'error',
    });
  }
}

function* cleanUpRequested() {
  yield takeLatest('APP_CLEANUP', cleanUpFlow);
}

export default function* cleanUpSaga() {
  yield all([cleanUpRequested()]);
}
