import React, { useState } from "react";
import { useDebounce } from "../../../hooks/useDebounce";
import { diagramVersionHoverContext } from "./diagramVersionHoverContext";

export const DiagramVersionHoverProvider = ({ children }) => {
  const [elementInner, setElement] = useState(null);
  const [versionInner, setVersion] = useState(null);

  const element = useDebounce(elementInner, 150);
  const version = useDebounce(versionInner, 150);

  return <diagramVersionHoverContext.Provider value={{
      version, element, setVersionAndElement: (ver, el) => { setVersion(ver); setElement(el); }
  }}>
    {children}
  </diagramVersionHoverContext.Provider>
}
