import React from 'react';
import styled from 'styled-components';
import * as mixins from '../../styles/mixins';
import * as colors from '../../styles/colors';

import FlexibleWrapper from '../shared/FlexibleWrapper';
import UserIcon from '../shared/icons/tools/UserIcon';
import UsersIcon from '../shared/icons/tools/UsersIcon';

const ButtonsWrapper = styled(FlexibleWrapper)``;

const UsersButton = styled.div`
  margin-right: 10px;
  cursor: pointer;
`;

const UserButton = styled.div`
  cursor: pointer;
`;

const OwnersVisibilityButtons = ({
  ownersVisible,
  userTasksHighlighted,
  toggleOwnersVisibility,
  toggleUserTasksHighlight,
}) => {
  return (
    <ButtonsWrapper>
      <UsersButton onClick={toggleOwnersVisibility}>
        <UsersIcon color={ownersVisible ? colors.alternativeIconColor : colors.mainIconColor} />
      </UsersButton>
      <UserButton onClick={toggleUserTasksHighlight}>
        <UserIcon color={userTasksHighlighted ? colors.alternativeIconColor : colors.mainIconColor} />
      </UserButton>
    </ButtonsWrapper>
  );
};

export default OwnersVisibilityButtons;
