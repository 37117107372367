import React, { PureComponent } from 'react';
import { ComposedChart, Line, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import moment from 'moment';

class CustomizedLabel extends PureComponent {
  render() {
    const { x, y, stroke, value, index, metrics } = this.props;
    return (
      <text x={x} y={y} dy={-4} fill={stroke} fontSize={10} textAnchor="middle">
        {metrics[index].changeover && parseJSON(metrics[index].changeover)?.label_version
          ? `v${parseJSON(metrics[index].changeover)?.label_version}`
          : ''}
      </text>
    );
  }
}

function parseJSON(jsonString) {
  try {
    var o = JSON.parse(jsonString);
    if (o && typeof o === 'object') {
      return o;
    }
  } catch (e) {}

  return false;
}

const Resolution = ({ metrics }) => {
  return (
    <ResponsiveContainer width="100%" height="100%">
      <ComposedChart
        height={350}
        data={metrics}
        margin={{
          top: 20,
          right: 20,
          bottom: 20,
          left: 20,
        }}
      >
        <CartesianGrid stroke="#f5f5f5" />
        <XAxis dataKey="dt" name="Time" tickFormatter={unixTime => moment(unixTime).format('DD/MM/YY')} />
        <YAxis />
        <Line
          dot={false}
          type="monotone"
          name={'FCR'}
          dataKey="percent"
          stroke="#5C9EF5"
          label={<CustomizedLabel metrics={metrics} />}
        />
        <Tooltip labelFormatter={unixTime => moment(unixTime).format('DD/MM/YY')} />
      </ComposedChart>
    </ResponsiveContainer>
  );
};

export default Resolution;
