const fetchDiagramsList = () => {
  return {
    type: 'FETCH_DIAGRAMS_LIST',
  };
};

const updateDiagramsList = newDiagramsList => {
  return {
    type: 'UPDATE_DIAGRAMS_LIST',
    newDiagramsList: newDiagramsList,
  };
};

const diagramsActions = {
  fetchDiagramsList,
  updateDiagramsList,
};

export default diagramsActions;
