import Overview from '../../components/overview/Overview';
import Labelling from '../../components/labelling/Labelling';
import Settings from '../../components/settings/Settings';
import QualityAssessment from '../../components/quality-assessment/QualityAssessment';
import UserManagement from '../../components/user-management/UserManagement';
const ENABLE_CS_ASSIST = process.env.ENABLE_CS_ASSIST || 'false';
const initialNavState = {
  links:
    ENABLE_CS_ASSIST == 'false'
      ? [
          {
            label: 'Overview',
            link: '/',
            component: Labelling,
            group: 'main',
            roles: user => ['labeler', 'product_manager', 'admin'].includes(user.role),
          },
          {
            label: 'Labelling',
            link: '/labelling',
            component: Labelling,
            group: 'labelling',
            roles: user => ['labeler', 'product_manager', 'admin'].includes(user.role),
          },
          {
            label: 'Settings',
            link: '/settings',
            group: 'settings',
            roles: user => ['product_manager', 'admin'].includes(user.role),
          },
          {
            label: 'Solution Flows',
            link: '/sfm',
            group: 'sfm',
            roles: user => ['product_manager', 'admin'].includes(user.role),
          },
          {
            label: 'Metrics',
            link: '/metrics',
            group: 'statistics',
            roles: user => ['product_manager', 'admin'].includes(user.role),
          },
          {
            label: 'User Management',
            link: '/user-management',
            component: UserManagement,
            group: 'management',
            roles: user => ['admin'].includes(user.role),
          },
          {
            label: 'Quality Assessment',
            link: '/qa',
            component: QualityAssessment,
            group: 'labelling',
            roles: user => ['labeler', 'product_manager', 'admin'].includes(user.role),
          },
          {
            label: 'Export',
            link: '/export',
            component: UserManagement,
            group: 'export',
            roles: user => ['admin'].includes(user.role),
          },
        ]
      : [
          {
            label: 'Overview',
            link: '/',
            component: Labelling,
            group: 'main',
            roles: user => ['labeler', 'product_manager', 'admin'].includes(user.role),
          },
          {
            label: 'Labelling',
            link: '/labelling',
            component: Labelling,
            group: 'labelling',
            roles: user => ['labeler', 'product_manager', 'admin'].includes(user.role),
          },
          {
            label: 'Settings',
            link: '/settings',
            group: 'settings',
            roles: user => ['product_manager', 'admin'].includes(user.role),
          },
          {
            label: 'Solution Flows',
            link: '/sfm',
            group: 'sfm',
            roles: user => ['product_manager', 'admin'].includes(user.role),
          },
          {
            label: 'Metrics',
            link: '/metrics',
            group: 'statistics',
            roles: user => ['product_manager', 'admin'].includes(user.role),
          },
          {
            label: 'User Management',
            link: '/user-management',
            component: UserManagement,
            group: 'management',
            roles: user => ['admin'].includes(user.role),
          },
          {
            label: 'Callbot Metrics',
            link: '/callbot-metrics',
            group: 'callbot-statistics',
            roles: user => ['product_manager', 'admin'].includes(user.role),
          },
          {
            label: 'Quality Assessment',
            link: '/qa',
            component: QualityAssessment,
            group: 'labelling',
            roles: user => ['labeler', 'product_manager', 'admin'].includes(user.role),
          },
          {
            label: 'Export',
            link: '/export',
            component: UserManagement,
            group: 'export',
            roles: user => ['admin'].includes(user.role),
          },
        ],
};
export default initialNavState;
