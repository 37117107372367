import { put, call, all, take, takeLatest, spawn } from 'redux-saga/effects';
import NotificationService from '../services/common/NotificationService';
import ChatsService from '../services/ChatsService';
import chatsActions from '../action-creators/chatsActions';

function* updateMessageLangLabelFlow(action) {
  try {
    const response = yield call(ChatsService.updateMessageLangLabel, action.message_id, action.lang);
    if (response.status === 200) {
    } else {
      NotificationService.push({
        type: 'error',
        message: 'Label not saved',
      });
    }
  } catch (error) {
    NotificationService.push({
      type: 'error',
      message: 'Label not saved',
    });
  }
}

function* exportChatContextsFlow(action) {
  try {
    const response = yield call(ChatsService.exportChatContexts, action.contextKey, action.dateRange);
    // if (response.status === 200) {
    //   yield put(chatsActions.updateChatsNotLabelled(response.data));
    // } else {
    //   NotificationService.push({
    //     type: 'error',
    //     message: response.data,
    //   });
    // }
  } catch (error) {
    NotificationService.push({
      type: 'error',
    });
  }
}

function* chatsNotLabelledListFlow(action) {
  try {
    const response = yield call(
      ChatsService.getChatsNotLabelled,
      action.skip,
      action.limit,
      action.labelMode,
      action.selectedLabel,
      action.showLabelled,
      action.selectedLanguage,
      action.seed
    );
    if (response.status === 200) {
      yield put(chatsActions.updateChatsNotLabelled(response.data));
    } else {
      NotificationService.push({
        type: 'error',
        message: response.data,
      });
    }
  } catch (error) {
    NotificationService.push({
      type: 'error',
    });
  }
}

function* watchChatsNotLabelledRequested() {
  yield takeLatest('FETCH_CHATS_NOT_LABELLED', chatsNotLabelledListFlow);
}

function* watchExportChatContextsRequested() {
  yield takeLatest('EXPORT_CHAT_CONTEXTS', exportChatContextsFlow);
}

function* watchUpdateMessageLangLabelRequested() {
  yield takeLatest('UPDATE_MESSAGE_LANG_LABEL', updateMessageLangLabelFlow);
}

export default function* chatsSaga() {
  yield all([
    watchChatsNotLabelledRequested(),
    watchUpdateMessageLangLabelRequested(),
    watchExportChatContextsRequested(),
  ]);
}
