import React from 'react';
import { connect } from 'react-redux';

import TaskPropertiesPanel from '../../components/task-properties-panel/TaskPropertiesPanel';
import {callbotTaskData, taskData} from '../../constants/tasks/taskData';
import currentTaskActions from '../../action-creators/currentTaskActions';

const TaskPropertiesPanelContainer = ({
  modeler,
  reviewMode,
  currentTask,
  currentTaskBusinessObject,
  updateCurrentTask,
  settings,
  diagramType
}) => {
  const isExpanded = !!currentTask;
  const tasks = diagramType === "callbot" ? callbotTaskData : taskData;
  let currentTaskData;
  if (isExpanded) {
    currentTaskData = tasks?.[currentTaskBusinessObject.subType];
  }

  return (
    <TaskPropertiesPanel
      modeler={modeler}
      isExpanded={isExpanded}
      currentTask={currentTask}
      currentTaskData={currentTaskData}
      currentBusinessObject={currentTaskBusinessObject}
      updateCurrentTask={updateCurrentTask}
      reviewMode={reviewMode}
      settings={settings}
      diagramType={diagramType}
    />
  );
};

export const mapStateToProps = state => {
  return {
    currentTask: state.app.currentTask,
    currentTaskBusinessObject: state.app.currentTaskBusinessObject,
  };
};

const mapDispatchToProps = {
  updateCurrentTask: currentTaskActions.updateCurrentTask,
};

export default connect(mapStateToProps, mapDispatchToProps)(TaskPropertiesPanelContainer);
