import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';
import * as colors from '../styles/colors';
import * as mixins from '../styles/mixins';
import * as typography from '../styles/typography';
import { DatePicker, Tabs, Table, Select } from 'antd';
import {
  Sankey,
  Tooltip,
  Label as AxisLabel,
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
} from 'recharts';
import DemoSankeyLink from './DemoSankeyLink';
import DemoSankeyNode from './DemoSankeyNode';
import labelsActions from '../../../action-creators/labelsActions';
import { connect } from 'react-redux';
import moment from 'moment';
import MetricsService from '../../../services/MetricsService';

const { RangePicker } = DatePicker;

const { TabPane } = Tabs;

const PlotsContent = styled.div`
  width: 100%;
  box-sizing: border-box;
  padding: 20px 10px;

  ${mixins.respondTo.desktop`
        padding: 40px 30px;

        ${mixins.flex('space-between', 'flex-start', 'row', 'wrap')};
    `};

  ${mixins.respondTo.desktopLarge`
        padding: 40px 7%;
    `};
`;

const Card = styled.div`
  width: 100%;
  background-color: ${colors.white};
  // box-shadow: 0 1px 2px rgba(0,0,0,0.07),
  //             0 2px 4px rgba(0,0,0,0.07),
  //             0 4px 8px rgba(0,0,0,0.07),
  //             0 8px 16px rgba(0,0,0,0.07),
  //             0 16px 32px rgba(0,0,0,0.07),
  //             0 32px 64px rgba(0,0,0,0.07);

  filter: drop-shadow(0px 8px 16px rgba(0, 51, 167, 0.08));

  box-sizing: border-box;
  padding: 20px;
  margin-bottom: 40px;

  ${mixins.respondTo.tablet`
        padding: 40px 20px;
    `};
`;

const SmallCard = styled(Card)`
  padding: 0px;
  margin-top: 30px;
  ${mixins.respondTo.desktop`
        width: 49%;
    `};
`;

const Header = styled.p`
  padding-bottom: 20px;
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const Title = styled.p`
  color: ${colors.darkBlue};
  ${mixins.fontStyle(typography.biggestTitleFontSize, typography.semibold)};
  margin-bottom: 20px;

  ${mixins.respondTo.tablet`
        margin-bottom: 40px;
    `};
`;
const CardTitle = styled.p`
  background-color: #dbdee2;
  font-weight: bold;
  padding: 15px;
  padding-left: 10px;
`;
const Overview = styled.div`
  ${mixins.respondTo.desktop`
        ${mixins.flex('space-evenly')};
    `};
`;

const OverviewElement = styled.div`
  ${mixins.respondTo.desktop`
        flex: 2;
    `};
`;

const MainPiePlot = styled(OverviewElement)`
  max-width: 350px;
`;

const GatewayPlot = styled.div`
  width: 100%;
  box-sizing: border-box;

  ${mixins.respondTo.desktop`
        padding: 0px 10%;
    `};
`;

const ExtraPiePlots = styled(OverviewElement)`
  ${mixins.respondTo.desktop`
        flex: 1;
        max-width: 400px;
    `};
`;

const MainPlots = styled.div`
  width: 100%;
  ${mixins.flex('space-evenly', 'flex-start', 'row', 'wrap')};
`;

const Plot = styled.div`
  width: 100%;
  margin-bottom: 30px;
  overflow-x: scroll;
  overflow-y: hidden;

  ${mixins.respondTo.desktop`
        width: 49%;
        margin-bottom: 50px;
        overflow-x: hidden;
    `};

  ${mixins.respondTo.desktopBig`
        width: 40%;
    `};

  ${mixins.respondTo.desktopLarge`
        width: 35%;
    `};
`;

const PiePlots = styled.div`
  ${mixins.respondTo.desktop`
        ${mixins.flex('space-evenly')};
    `};
`;

const ExtraPiePlot = styled.div``;

const Annotation = styled.div`
  margin-left: 20px;
  ${mixins.flex('flex-start')};
`;

const Color = styled.div`
  width: 20px;
  height: 20px;
  margin-right: 10px;

  &.orange {
    background-color: #ff9800;
  }

  &.gray {
    background-color: #c4c4c4;
  }

  &.green {
    background-color: #4cb051;
  }
`;

const Label = styled.p`
  ${mixins.fontStyle(typography.labelFontSize, typography.normal)};
  margin-left: 20px;
  font-weight: bold;
  font-size: 16px;
`;

const data1 = {
  nodes: [
    { name: 'Incoming Calls' },
    { name: 'Routed to the correct agent' },
    { name: 'SMS forward to chat' },
    { name: 'Automated calls' },
    { name: 'Solved with CS assist tool' },
    { name: 'Manually handled' },
    { name: 'Human handover' },
    { name: 'Automatically solved' },
    { name: 'Calls dropped' },
  ],
  links: [
    { source: 0, target: 1, value: 70000 },
    { source: 0, target: 2, value: 20000 },
    { source: 0, target: 3, value: 12000 },
    { source: 0, target: 8, value: 1000 },
    { source: 1, target: 4, value: 55000 },
    { source: 1, target: 5, value: 15000 },
    { source: 2, target: 6, value: 5000 },
    { source: 2, target: 7, value: 15000 },
    { source: 3, target: 6, value: 3000 },
    { source: 3, target: 7, value: 9000 },
    { source: 6, target: 4, value: 5000 },
    { source: 6, target: 5, value: 2000 },
  ],
};
function randomNumber(min, max) {
  return Math.random() * (max - min + 1) + min;
}

const Plots = ({ labels, fetchLabelsList }) => {
  const [selectedKeywords, setSelectedKeywords] = useState(['homebox', 'internet', 'invoice']);
  const [selectedRange, setSelectedRange] = useState([moment().subtract('10', 'days'), moment()]);
  const [extraInfo, setExtraInfo] = useState([]);
  const [metricsBotHandledChats, setMetricsBotHandledChats] = useState([]);

  useEffect(() => {
    fetchLabelsList();
  }, []);

  useEffect(() => {
    getLatestAdminMetrics();
    getBotHandledMetrics();
  }, [selectedRange]);

  let getLatestAdminMetrics = () => {
    MetricsService.getAdminMetrics({
      metrics_name: 'latest_metrics',
      range_start: selectedRange[0].format('YYYY-MM-DDT') + '00%3A00',
      range_end: selectedRange[1].format('YYYY-MM-DDT') + '00%3A00',
      time_granularity: 'total',
    }).then(response => {
      setExtraInfo(response.data);
    });
  };

  let getBotHandledMetrics = () => {
    MetricsService.getMetrics({
      metrics_name: 'metrics_bot_handled_percent',
      range_start: selectedRange[0].format('YYYY-MM-DDT') + '00%3A00',
      range_end: selectedRange[1].format('YYYY-MM-DDT') + '00%3A00',
      time_granularity: 'day',
    }).then(response => {
      let data = response.data.filter(x => !isNaN(Date.parse(x.dt)));
      data.sort((a, b) => (a.dt > b.dt ? 1 : b.dt > a.dt ? -1 : 0));
      setMetricsBotHandledChats(data);
    });
  };

  const getColumns = key => {
    let columns = [
      {
        title: 'Label',
        dataIndex: 'label',
        key: 'label',
      },
      {
        title: 'Description',
        dataIndex: 'description',
        key: 'description',
      },
      {
        title: 'FCR %',
        dataIndex: 'fcr',
        key: 'fcr',
      },
      {
        title: 'Avg. time',
        dataIndex: 'avgTime',
        key: 'avgTime',
      },
      {
        colSpan: 2,
        title: 'Volume',
        dataIndex: 'proportion',
        key: 'proportion',
      },
    ];
    if ([1, 2, 5].includes(key)) columns = columns.filter(col => col.key != 'fcr');
    if (key == 2) {
      columns.find(col => col.title == 'Label').title = 'Skill';
      columns.splice(2, 0, {
        title: 'Accuracy %',
        dataIndex: 'accuracy',
        key: 'accuracy',
      });
      columns = columns.filter(col => col.key != 'description');
    }
    if ([2, 4, 5].includes(key)) {
      columns = columns.filter(col => col.key != 'avgTime');
    }

    return columns;
  };

  const getChartData = (median, tabKey, delta = 4) => {
    if (tabKey == 3) return metricsBotHandledChats;
    let data = [];
    for (
      let date = moment(selectedRange[0]).startOf('day');
      date < selectedRange[1].endOf('day');
      date.add('1', 'days')
    ) {
      data.push({
        dt: moment(date).format('YYYY-MM-DD'),
        value: randomNumber(median - delta < 0 ? 0 : median - delta, median + delta).toFixed(1),
      });
    }
    return data;
  };

  const getKeywordData = () => {
    let data = [];
    for (
      let date = moment(selectedRange[0]).startOf('day');
      date < selectedRange[1].endOf('day');
      date.add('1', 'days')
    ) {
      data.push({
        name: moment(date).format('YYYY-MM-DD'),
        HomeBox: Math.floor(randomNumber(0, 4)),
        Internet: Math.floor(randomNumber(6, 7)),
        Invoice: Math.floor(randomNumber(2, 7)),
      });
    }
    return data;
  };
  const listLabelsForTable = () => {
    let temp = labels
      ?.sort((a, b) => b.is_auxiliary - a.is_auxiliary)
      ?.map((label, labelIndex) => ({
        isAuxilary: label.is_auxiliary,
        key: `${labelIndex}`,
        label: label.name,
        description: label.description,
        fcr: extraInfo[labelIndex]?.fcr,
        accuracy: extraInfo[labelIndex]?.accuracy,
        avgTime: `${randomNumber(3, 6).toFixed(1)} min`,
        proportion: extraInfo[labelIndex]?.volume,
        sfm: label.sfm,
      }));
    return temp?.filter(label => !label.isAuxilary && label.sfm);
  };

  const getTab = (tabKey, tabName, tabTitle, tabOverview, chartTitle, chartYAxis, chartRangeMedian) => (
    <TabPane tab={tabName} key={tabKey}>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <span style={{ fontSize: 20, marginBottom: 10 }}>{tabTitle}</span>
        <span style={{ width: '70%' }}>{tabOverview}</span>
      </div>
      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
        <SmallCard>
          <CardTitle>{chartTitle}</CardTitle>
          <LineChart
            width={500}
            height={320}
            data={getChartData(chartRangeMedian, tabKey)}
            margin={{
              top: 20,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="dt" tickFormatter={unixTime => moment(unixTime).format('DD/MM/YY')} />
            <YAxis>
              <AxisLabel value={chartYAxis} position="insideLeft" angle={-90} style={{ textAnchor: 'middle' }} />
            </YAxis>
            <Tooltip labelFormatter={unixTime => moment(unixTime).format('DD/MM/YY')} />
            <Line type="monotone" dataKey="value" stroke="#203A72" activeDot={{ r: 8 }} />
          </LineChart>
        </SmallCard>
        {tabKey != 6 ? (
          <SmallCard className="table-metrics">
            <Table
              scroll={{ y: 300 }}
              pagination={false}
              dataSource={listLabelsForTable()}
              columns={getColumns(tabKey)}
            />
          </SmallCard>
        ) : null}
      </div>
    </TabPane>
  );
  return (
    <PlotsContent>
      <Header>
        <Title>Overview</Title>
        <RangePicker value={selectedRange} onChange={setSelectedRange} />
      </Header>
      <Card>
        <Label>Call funnel plot</Label>
        <Sankey
          width={717}
          height={462}
          margin={{ top: 20, bottom: 20, left: 20 }}
          data={data1}
          nodeWidth={10}
          nodePadding={60}
          linkCurvature={0.61}
          iterations={64}
          link={<DemoSankeyLink />}
          node={<DemoSankeyNode containerWidth={400} />}
        >
          <defs>
            <linearGradient id={'linkGradient'}>
              <stop offset="0%" stopColor="#4d91cd" />
              <stop offset="100%" stopColor="#e6eff8" />
            </linearGradient>
          </defs>
          <Tooltip />
        </Sankey>
        <Tabs style={{ marginTop: 85, paddingLeft: 20 }} defaultActiveKey="3" type="card">
          {getTab(
            1,
            'Manually handled',
            'Manually handled - statistics',
            `Overview for manually handled calls: the proportion of calls handled manually over time and the distribution of topics that are handled manually.`,
            'Proportion of manually handled calls',
            'Calls [%]',
            16
          )}
          {getTab(
            2,
            'Routed to the correct agent',
            'Routed to the correct agent - statistics',
            `Overview for correctly routed calls: the accuracy of call routing and the accuracy per category.`,
            'Routing accuracy',
            'Routing accuracy [%]',
            95
          )}
          {getTab(
            3,
            'Solved with CS assist tool',
            'Solved with CS assist tool - statistics',
            `The overview for CS Assist tool: the change of first contact resolution (FCR) over the selected time period
          and the high level overview of the labels solved with the help of the CS assist tool.`,
            'First Contact Resolution Trendline',
            'FCR [%]',
            70
          )}
          {getTab(
            4,
            'Automated calls',
            'Automated calls - statistics',
            `Overview for automated calls: the proportion of automated calls and overview of automation per label.`,
            'Automated calls',
            'Automation percentage [%]',
            14
          )}
          {getTab(
            5,
            'SMS forward to chat',
            'SMS forward to chat - statistics',
            `Overview for SMS forward to chat: the proportion of calls forwarded to chat and forwarding per label.`,
            'SMS forward to chat',
            'Proportion of calls forwarded to chat [%]',
            19
          )}
          {getTab(
            6,
            'Calls dropped',
            'Calls dropped - statistics',
            `Overview of dropped calls over time`,
            'Percentage of dropped calls',
            'Automation percentage [%]',
            1
          )}
        </Tabs>
      </Card>
      <div style={{ marginTop: 30, width: 650, display: 'flex', flexDirection: 'column' }}>
        <Title>Keyword Statistics</Title>
        <span style={{ marginTop: 10 }}>
          Monitor keyword trends over time. Select the keywords of interest to be displayed the according trendlines
          over the selected time period.
        </span>
      </div>
      <Card style={{ marginTop: 30 }}>
        <Select
          style={{ width: 425 }}
          mode="tags"
          size={'large'}
          getPopupContainer={trigger => trigger.parentNode}
          showAction="focus"
          allowClear={false}
          placeholder="Add new keyward"
          value={selectedKeywords}
          onChange={setSelectedKeywords}
        >
          <>
            <Option key={'homebox'} value={'homebox'}>
              HomeBox
            </Option>
            <Option key={'internet'} value={'internet'}>
              Internet
            </Option>
            <Option key={'invoice'} value={'invoice'}>
              Invoice
            </Option>
          </>
        </Select>
        <SmallCard style={{ width: '100%' }}>
          <CardTitle>Percentage of calls where the keyword is mentioned</CardTitle>
          <ResponsiveContainer width="100%" height={400}>
            <LineChart
              data={getKeywordData()}
              margin={{
                top: 20,
                right: 30,
                left: 20,
                bottom: 5,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <Legend />
              <XAxis dataKey="name" />
              <YAxis>
                <AxisLabel
                  value="Keyword frequency [%]"
                  position="insideLeft"
                  angle={-90}
                  style={{ textAnchor: 'middle' }}
                />
              </YAxis>
              <Tooltip />
              {selectedKeywords.includes('homebox') ? (
                <Line type="monotone" dataKey="HomeBox" stroke="#203A72" activeDot={{ r: 8 }} />
              ) : null}
              {selectedKeywords.includes('internet') ? (
                <Line type="monotone" dataKey="Internet" stroke="orange" activeDot={{ r: 8 }} />
              ) : null}
              {selectedKeywords.includes('invoice') ? (
                <Line type="monotone" dataKey="Invoice" stroke="green" activeDot={{ r: 8 }} />
              ) : null}
            </LineChart>
          </ResponsiveContainer>
        </SmallCard>
      </Card>
    </PlotsContent>
  );
};
export const mapStateToProps = state => {
  return {
    labels: state.labels.labels,
  };
};

const mapDispatchToProps = {
  fetchLabelsList: labelsActions.fetchLabelsList,
};
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Plots));
