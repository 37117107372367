const fetchChatsNotLabelled = (skip, limit, labelMode, selectedLabel, showLabelled, selectedLanguage, seed) => {
  return {
    type: 'FETCH_CHATS_NOT_LABELLED',
    skip,
    limit,
    labelMode,
    selectedLabel,
    showLabelled,
    selectedLanguage,
    seed,
  };
};

const exportChatContexts = (contextKey, dateRange) => {
  return {
    type: 'EXPORT_CHAT_CONTEXTS',
    contextKey,
    dateRange,
  };
};

const updateChatsNotLabelled = ({ chats, total_count }) => {
  return {
    type: 'UPDATE_CHATS_NOT_LABELLED',
    chats: chats,
    totalCount: total_count,
  };
};

const updateMessageLangLabel = ({ message_id, lang }) => {
  return {
    type: 'UPDATE_MESSAGE_LANG_LABEL',
    message_id,
    lang,
  };
};

const chatsActions = {
  fetchChatsNotLabelled,
  updateChatsNotLabelled,
  updateMessageLangLabel,
  exportChatContexts,
};

export default chatsActions;
