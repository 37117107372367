const fetchLabelsList = (descriptionTranslationsVersion = null) => {
  return {
    type: 'FETCH_LABELS_LIST',
    descriptionTranslationsVersion,
  };
};

const fetchSFMList = () => {
  return {
    type: 'FETCH_SFM_LIST',
  };
};

const fetchLabelVersionsList = params => {
  return {
    type: 'FETCH_LABEL_VERSIONS_LIST',
    params,
  };
};

const updateLabelsList = newLabelsList => {
  return {
    type: 'UPDATE_LABELS_LIST',
    newLabelsList: newLabelsList,
  };
};

const updateSFMList = newSFMList => {
  return {
    type: 'UPDATE_SFM_LIST',
    newSFMList,
  };
};

const updateLabelVersionsList = newLabelVersionsList => {
  return {
    type: 'UPDATE_LABEL_VERSIONS_LIST',
    newLabelVersionsList,
  };
};

const updateCurrentLabelId = newLabelId => {
  return {
    type: 'UPDATE_CURRENT_LABEL_ID',
    newLabelId: newLabelId,
  };
};

const createLabel = (name, owner_id, interval, description, parent_id, is_auxiliary) => {
  return {
    type: 'CREATE_LABEL',
    name,
    owner_id,
    interval,
    description,
    parent_id,
    is_auxiliary,
  };
};

const updateLabel = (id, name, owner_id, interval, description, parent_id, is_auxiliary) => {
  return {
    type: 'UPDATE_LABEL',
    id,
    name,
    owner_id,
    interval,
    description,
    parent_id,
    is_auxiliary,
  };
};

const deleteLabel = id => {
  return {
    type: 'DELETE_LABEL',
    id: id,
  };
};

const labelsActions = {
  fetchLabelsList,
  fetchSFMList,
  fetchLabelVersionsList,
  updateLabelVersionsList,
  updateSFMList,
  updateLabelsList,
  updateCurrentLabelId,
  createLabel,
  updateLabel,
  deleteLabel,
};

export default labelsActions;
