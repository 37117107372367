import React, { useState, useRef, useEffect } from 'react';
import { Select } from 'antd';
import moment from 'moment';
// import "./Message.scss";
import NotificationService from '../../../../services/common/NotificationService';
import {
  getSenderCaptionFromChannelAndRole,
  getClassNameFromChannelAndRole,
} from '../../../../helpers/messageChannelAndRoleInfo';

const Message = ({ message, chat, failureReasons, setQualityAssessmentsWithReasons }) => {
  const labelSelectRef = useRef(null);
  const [showReasonMarker, setShowReasonMarker] = useState(false);
  const [searchLabel, setSearchLabel] = useState('');
  const handleMessageLabelsChange = values => {
    setSearchLabel('');
  };
  const openLabelSelector = () => {
    setShowReasonMarker(!showReasonMarker);
  };
  useEffect(() => {
    if (showReasonMarker) {
      document.getElementById(`input-${chat.id}-${message.id}`)?.focus();
    }
  }, [showReasonMarker]);

  const selectValue =
    message?.quality_assessments?.map(quality_assessment => quality_assessment?.failure_reason?.reason) || [];

  return (
    <div
      id="message"
      className="message-container"
      style={{
        width: '100%',
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <div
        className={`message-content ${getClassNameFromChannelAndRole(message.role, message.channel)}`}
        onClick={() => openLabelSelector()}
      >
        <div className="message" style={{ cursor: message.role === 'bot' ? 'pointer' : 'default' }}>
          {message['content_type'] === 'file' ? (
            <a
              style={{ cursor: 'pointer', textDecoration: 'underline' }}
              href={message.url}
              dir="auto"
              target="_blank"
              rel="noopener noreferrer"
              className="message-text"
            >
              {message.message}
            </a>
          ) : (
            <p dir="auto" className="message-text">
              {message.message}
            </p>
          )}

          <div className="sender-info">
            <span className="message-sender">{`${getSenderCaptionFromChannelAndRole(
              message.role,
              message.channel
            )} `}</span>
            <span className="message-timestamp">
              &#183;{` ${moment(message.created_on).format('DD/MM/YYYY - HH:mm')}`}
            </span>
          </div>
        </div>
      </div>
      {message.role === 'remark' ? <div style={{ width: '100%', paddingLeft: 20 }} /> : null}
      {['bot', 'support', 'elisa'].includes(message.role) ? (
        <div style={{ width: '100%', paddingLeft: 20 }}>
          <span
            style={{
              width: '100%',
              display: showReasonMarker || Object.keys(message?.failureReasons || {})?.length > 0 ? 'block' : 'none',
            }}
          >
            Failure Reason
          </span>
          {
            <div dir="auto">
              <Select
                mode="tags"
                size={'large'}
                getPopupContainer={trigger => trigger.parentNode}
                ref={labelSelectRef}
                showAction="focus"
                id={`input-${chat.id}-${message.id}`}
                allowClear={false}
                onInputKeyDown={e => {
                  if (e.key === 'Enter' && selectValue.includes(searchLabel)) {
                    e.preventDefault();
                    e.stopPropagation();
                    setSearchLabel('');
                    return;
                  }
                }}
                onDropdownVisibleChange={isOpen => {
                  if (!isOpen && !message?.quality_assessments?.length) setShowReasonMarker(false);
                  setSearchLabel('');
                }}
                style={{
                  width: '100%',
                  display: showReasonMarker || message?.quality_assessments?.length > 0 ? 'block' : 'none',
                }}
                placeholder="Type to add new failure reason"
                onChange={reasons => {
                  const reasonsToSave = reasons.map(r => r.trim() || '');
                  const showWarning = reasonsToSave.some(r => !r);
                  if (showWarning) {
                    NotificationService.push({
                      type: 'info',
                      message: 'Failure reason needs to have at least 1 letter in the name!',
                    });
                  } else {
                    setQualityAssessmentsWithReasons(reasonsToSave);
                  }
                  setSearchLabel('');
                }}
                onSearch={value => setSearchLabel(value?.normalize('NFKD'))}
                searchValue={searchLabel}
                value={selectValue}
              >
                {failureReasons
                  ?.map(f => f.name)
                  ?.sort((a, b) => failureReasons[b] - failureReasons[a])
                  ?.map(option => (
                    <Option key={option} value={option}>
                      {option}
                    </Option>
                  ))}
              </Select>
            </div>
          }
        </div>
      ) : null}
    </div>
  );
};

export default Message;
