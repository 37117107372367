import React, { useEffect } from 'react';
import ToggleButton from 'react-toggle-button';
import { HexColorPicker } from 'react-colorful';
import { withRouter } from 'react-router-dom';
import { Select, Input } from 'antd';
import { connect } from 'react-redux';
import TimezoneSelect from 'react-timezone-select';
import './Settings.scss';
// import Input from '../common/inputs/Input';
import Button from '../common/button/Button';
import Textarea from '../common/inputs/Textarea';
import SettingsCwcPreview from './SettingsCwcPreview';
import { useState } from 'react';
import settingsActions from '../../action-creators/settingsActions';
import labelsActions from '../../action-creators/labelsActions';
import { CODE_TO_LANGUAGE_MAP } from '../../constants/codeToLanguageMap';
import withChatDraft from '../utils/chat-draft/withChatDraft';
import { withRoles } from '../utils/roles/withRoles';
import ReactJson from 'react-json-view';
import { RedoOutlined } from '@ant-design/icons';

const { Option } = Select;

export const SUPPORTED_LANGUAGES = process.env.SUPPORTED_LANGUAGES ? process.env.SUPPORTED_LANGUAGES.split(',') : [];
export const DEFAULT_SFM_LANGUAGE = process.env.DEFAULT_SFM_LANGUAGE || 'en';

function isPositiveInteger(n) {
  return n >>> 0 === parseFloat(n);
}
function generateId(length) {
  var result = '';
  var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

function HSLToHex({ h, s, l }) {
  s /= 100;
  l /= 100;

  let c = (1 - Math.abs(2 * l - 1)) * s,
    x = c * (1 - Math.abs(((h / 60) % 2) - 1)),
    m = l - c / 2,
    r = 0,
    g = 0,
    b = 0;

  if (0 <= h && h < 60) {
    r = c;
    g = x;
    b = 0;
  } else if (60 <= h && h < 120) {
    r = x;
    g = c;
    b = 0;
  } else if (120 <= h && h < 180) {
    r = 0;
    g = c;
    b = x;
  } else if (180 <= h && h < 240) {
    r = 0;
    g = x;
    b = c;
  } else if (240 <= h && h < 300) {
    r = x;
    g = 0;
    b = c;
  } else if (300 <= h && h < 360) {
    r = c;
    g = 0;
    b = x;
  }
  // Having obtained RGB, convert channels to hex
  r = Math.round((r + m) * 255).toString(16);
  g = Math.round((g + m) * 255).toString(16);
  b = Math.round((b + m) * 255).toString(16);

  // Prepend 0s, if necessary
  if (r.length == 1) r = '0' + r;
  if (g.length == 1) g = '0' + g;
  if (b.length == 1) b = '0' + b;

  return '#' + r + g + b;
}

const hexToHSL = H => {
  if (typeof H !== 'string') return H;
  // Convert hex to RGB first
  let r = 0,
    g = 0,
    b = 0;
  if (H.length == 4) {
    r = '0x' + H[1] + H[1];
    g = '0x' + H[2] + H[2];
    b = '0x' + H[3] + H[3];
  } else if (H.length == 7) {
    r = '0x' + H[1] + H[2];
    g = '0x' + H[3] + H[4];
    b = '0x' + H[5] + H[6];
  }
  // Then to HSL
  r /= 255;
  g /= 255;
  b /= 255;
  let cmin = Math.min(r, g, b),
    cmax = Math.max(r, g, b),
    delta = cmax - cmin,
    h = 0,
    s = 0,
    l = 0;

  if (delta == 0) h = 0;
  else if (cmax == r) h = ((g - b) / delta) % 6;
  else if (cmax == g) h = (b - r) / delta + 2;
  else h = (r - g) / delta + 4;

  h = Math.round(h * 60);

  if (h < 0) h += 360;

  l = (cmax + cmin) / 2;
  s = delta == 0 ? 0 : delta / (1 - Math.abs(2 * l - 1));
  s = +(s * 100).toFixed(1);
  l = +(l * 100).toFixed(1);

  return { h, s, l };
};

const Settings = ({ fetchSettings, updateSettings, settings, fetchSFMList, diagrams }) => {
  useEffect(() => {
    fetchSettings();
    fetchSFMList();
  }, []);
  Object.keys(settings).forEach(key => {
    if (
      key !== 'enabled' &&
      key !== 'live_description_enabled' &&
      key !== 'draft_description_enabled' &&
      (!settings[key] || settings[key] === '')
    ) {
      delete settings[key];
    }
  });
  const defaultValues = {
    icon_url: 'https://cwc.demo.mindtitan.com/mt.png',
    title: '{"en": "My custom title"}',
    languages: 'en',
    timezone: 'Europe/Helsinki',
    color_bar: '#263c73',
    color_support: '#00071a',
    color_user: '#f0f1f5',
    custom_css: '',
    default_label_language: '',
    default_retry_message: '',
    default_retry_count: 0,
    enabled: true,
    draft_description_enabled: false,
    live_description_enabled: true,
  };

  const [data, setData] = useState({});
  const [searchDraftDefaultFlow, setSearchDraftDefaultFlow] = useState('');
  const [searchLiveDefaultFlow, setSearchLiveDefaultFlow] = useState('');
  const [searchDraftClosingFlow, setSearchDraftClosingFlow] = useState('');
  const [searchLiveClosingFlow, setSearchLiveClosingFlow] = useState('');
  const [searchDraftFallbackFlow, setSearchDraftFallbackFlow] = useState('');
  const [searchLiveFallbackFlow, setSearchLiveFallbackFlow] = useState('');

  const actualData = {
    ...defaultValues,
    ...settings,
    ...data,
  };

  const draftDefaultLabel = actualData?.draft_default_label;
  const liveDefaultLabel = actualData?.live_default_label;
  const draftClosingLabel = actualData?.draft_closing_label;
  const liveClosingLabel = actualData?.live_closing_label;
  const draftFallbackLabel = actualData?.draft_fallback_label;
  const liveFallbackLabel = actualData?.live_fallback_label;

  const updateForm = (key, value) => {
    setData(prev => ({ ...prev, [key]: value }));
  };

  let languages;
  if (actualData.languages === '') {
    languages = [];
  } else {
    languages = actualData.languages.split(',');
  }

  // ensure is object: provides smooth migration from previous versions of settings.js
  let titles;
  try {
    titles = JSON.parse(actualData.title);
    if (typeof titles !== 'object') {
      titles = { [languages[0]]: JSON.stringify(titles) };
    }
  } catch (e) {
    titles = JSON.parse(defaultValues.title);
  }

  const updateTitle = (lang, value) => {
    updateForm('title', JSON.stringify({ ...titles, [lang]: value }));
  };

  const widgetOptions = {
    ...actualData,
    languages,
    title: titles[languages[0]],
    color_bar: actualData.color_bar,
    color_user: actualData.color_user,
    color_support: actualData.color_support,
    custom_bubble_style: {
      bottom: '0%',
      height: '80%',
      border: 'none',
      position: 'fixed',
      width: '100%',
      'max-width': '376px',
      right: '0px',
      transition: 'bottom 0.6s, opacity 0.8s',
    },
    custom_bubble_style_mobile: {
      bottom: '0%',
      height: '100%',
      border: 'none',
      position: 'fixed',
      width: '100%',
      right: '0px',
      transition: 'bottom 0.6s, opacity 0.8s',
    },
    custom_css: actualData.custom_css,
  };

  function handleSave() {
    updateSettings({
      ...actualData,
    });
  }

  const diagramsWithLiveVersions = diagrams?.filter(d => d.sfm?.live) || [];
  const diagramsWithDraftVersions = diagrams?.filter(d => d.sfm?.test) || [];

  const { token = '' } = settings;

  return (
    <div className={'settings'}>
      <div className="left-side">
        <div className="setting-container">
          <p className="multiple-selector-title">Chatbot name</p>
          {languages.map(lang => (
            <Input
              dir="auto"
              key={lang}
              placeholder="Type here"
              value={titles[lang]}
              onChange={e => updateTitle(lang, e.target.value)}
            />
          ))}
        </div>
        <div className="setting-container">
          <p className="multiple-selector-title">Icon url</p>
          <Input
            placeholder="Type here"
            value={actualData.icon_url}
            onChange={e => updateForm('icon_url', e.target.value)}
          />
        </div>
        <div className="setting-container">
          <p className="multiple-selector-title">Timezone</p>
          <TimezoneSelect value={actualData.timezone} onChange={tz => updateForm('timezone', tz.value)} />
        </div>
        <p className="multiple-selector-title">Choose brand color</p>
        <div className="color-container">
          <HexColorPicker
            color={actualData.color_bar}
            onChange={newVal => {
              updateForm('color_bar', newVal);
              const hslValue = hexToHSL(newVal);
              updateForm(
                'color_support',
                HSLToHex({
                  h: 0,
                  s: 0,
                  l: hslValue.l > 60 ? 0 : 100,
                })
              );
              updateForm('color_user', HSLToHex({ h: hslValue.h, s: 20, l: 95 }));
            }}
          />
          <input
            className="selected-color"
            style={{ borderLeftColor: actualData.color_bar }}
            value={actualData.color_bar}
            onInput={e => {
              const newVal = e.target.value;

              if (newVal[0] !== '#') return;

              updateForm('color_bar', newVal);
              const hslValue = hexToHSL(newVal);
              updateForm(
                'color_support',
                HSLToHex({
                  h: 0,
                  s: 0,
                  l: hslValue.l > 60 ? 0 : 100,
                })
              );
              updateForm('color_user', HSLToHex({ h: hslValue.h, s: 20, l: 95 }));
            }}
          />
        </div>
        <div className="setting-container">
          <Textarea
            label="JavaScript base code"
            disabled={true}
            value={`<script src="${process.env.CWC_LIVE_URL}/chat-web-sdk-v2.js"></script>
<script type="text/javascript">
  const cwc = initChatSdk("${process.env.CWC_LIVE_URL}/custom?token=${token}");
</script>`}
            onChange={e => updateForm('name', e.target.value)}
          />
          <p className="description">To enable the chatbot on your website, copy the snippet.</p>
        </div>
        <div className="setting-container">
          <Textarea
            label="Custom CSS"
            value={actualData.custom_css}
            onChange={e => updateForm('custom_css', e.target.value)}
          />
        </div>
        <div className="setting-container">
          <p className="multiple-selector-title"> Chat starting solution(Draft)</p>
          <Select
            showSearch
            style={{ width: 400 }}
            onChange={value => updateForm('draft_default_label', value?.normalize('NFKD'))}
            onSearch={value => setSearchDraftDefaultFlow(value?.normalize('NFKD'))}
            searchValue={searchDraftDefaultFlow}
            value={draftDefaultLabel || 'None'}
          >
            <Option value="None">None</Option>
            {diagramsWithDraftVersions.map(d => (
              <Option value={d.sfm.test.label?.normalize('NFKD')}>{d.sfm.test.label?.normalize('NFKD')}</Option>
            ))}
          </Select>
        </div>
        <div className="setting-container">
          <p className="multiple-selector-title"> Chat starting solution(Live)</p>
          <Select
            showSearch
            style={{ width: 400 }}
            onChange={value => updateForm('live_default_label', value?.normalize('NFKD'))}
            onSearch={value => setSearchLiveDefaultFlow(value?.normalize('NFKD'))}
            searchValue={searchLiveDefaultFlow}
            value={liveDefaultLabel || 'None'}
          >
            <Option value="None">None</Option>
            {diagramsWithLiveVersions.map(d => (
              <Option value={d.sfm.live.label?.normalize('NFKD')}>{d.sfm.live.label?.normalize('NFKD')}</Option>
            ))}
          </Select>
        </div>
        <div className="setting-container">
          <p className="multiple-selector-title"> Chat closing solution(Draft)</p>
          <Select
            showSearch
            style={{ width: 400 }}
            onChange={value => updateForm('draft_closing_label', value?.normalize('NFKD'))}
            onSearch={value => setSearchDraftClosingFlow(value?.normalize('NFKD'))}
            searchValue={searchDraftClosingFlow}
            value={draftClosingLabel || 'None'}
          >
            <Option value="None">None</Option>
            {diagramsWithDraftVersions.map(d => (
              <Option value={d.sfm.test.label?.normalize('NFKD')}>{d.sfm.test.label?.normalize('NFKD')}</Option>
            ))}
          </Select>
        </div>
        <div className="setting-container">
          <p className="multiple-selector-title"> Chat closing solution(Live)</p>
          <Select
            showSearch
            style={{ width: 400 }}
            onChange={value => updateForm('live_closing_label', value?.normalize('NFKD'))}
            onSearch={value => setSearchLiveClosingFlow(value?.normalize('NFKD'))}
            searchValue={searchLiveClosingFlow}
            value={liveClosingLabel || 'None'}
          >
            <Option value="None">None</Option>
            {diagramsWithLiveVersions.map(d => (
              <Option value={d.sfm.live.label?.normalize('NFKD')}>{d.sfm.live.label?.normalize('NFKD')}</Option>
            ))}
          </Select>
        </div>
        <div className="setting-container">
          <p className="multiple-selector-title"> Default language </p>
          <Select
            style={{ width: 400 }}
            onChange={value => updateForm('default_label_language', value)}
            value={actualData.default_label_language}
          >
            <Option value="">None</Option>
            {SUPPORTED_LANGUAGES.map(d => (
              <Option value={d}>{CODE_TO_LANGUAGE_MAP[d]}</Option>
            ))}
          </Select>
        </div>
        <div className="setting-container">
          <p className="multiple-selector-title"> Fallback solution(Draft)</p>
          <Select
            showSearch
            style={{ width: 400 }}
            onChange={value => updateForm('draft_fallback_label', value?.normalize('NFKD'))}
            onSearch={value => setSearchDraftFallbackFlow(value?.normalize('NFKD'))}
            searchValue={searchDraftFallbackFlow}
            value={draftFallbackLabel || 'None'}
          >
            <Option value="None">None</Option>
            {diagramsWithDraftVersions.map(d => (
              <Option value={d.sfm.test.label?.normalize('NFKD')}>{d.sfm.test.label?.normalize('NFKD')}</Option>
            ))}
          </Select>
        </div>
        <div className="setting-container">
          <p className="multiple-selector-title"> Fallback solution(Live)</p>
          <Select
            showSearch
            style={{ width: 400 }}
            onChange={value => updateForm('live_fallback_label', value?.normalize('NFKD'))}
            onSearch={value => setSearchLiveFallbackFlow(value?.normalize('NFKD'))}
            searchValue={searchLiveFallbackFlow}
            value={liveFallbackLabel || 'None'}
          >
            <Option value="None">None</Option>
            {diagramsWithLiveVersions.map(d => (
              <Option value={d.sfm.live.label?.normalize('NFKD')}>{d.sfm.live.label?.normalize('NFKD')}</Option>
            ))}
          </Select>
        </div>
        <div className="setting-container">
          <p className="multiple-selector-title"> Default Retry Message</p>
          <Input
            dir="auto"
            placeholder="Type here"
            value={actualData.default_retry_message}
            onChange={e => updateForm('default_retry_message', e.target.value)}
          />
        </div>
        <div className="setting-container">
          <p className="multiple-selector-title"> Default Retry Count</p>
          <Input
            type="number"
            min="0"
            step="1"
            value={actualData.default_retry_count}
            onChange={e => updateForm('default_retry_count', e.target.value)}
          />
        </div>
        <div className="setting-container">
          <span className="input-label">Enable chat-bot bubble</span>
          <ToggleButton
            styles={{ marginRight: 0 }}
            inactiveLabel={''}
            activeLabel={''}
            colors={{ active: { base: '#263c73', inactive: { base: '#E5E5E5' } } }}
            value={actualData.enabled}
            onToggle={value => updateForm('enabled', !value)}
          />
        </div>
        <div className="setting-container">
          <span className="input-label">Enable Classifier(Draft)</span>
          <ToggleButton
            styles={{ marginRight: 0 }}
            inactiveLabel={''}
            activeLabel={''}
            colors={{ active: { base: '#263c73', inactive: { base: '#E5E5E5' } } }}
            value={actualData.draft_classifier_enabled}
            onToggle={value => updateForm('draft_classifier_enabled', !value)}
          />
        </div>
        <div className="setting-container">
          <span className="input-label">Enable Classifier(Live)</span>
          <ToggleButton
            styles={{ marginRight: 0 }}
            inactiveLabel={''}
            activeLabel={''}
            colors={{ active: { base: '#263c73', inactive: { base: '#E5E5E5' } } }}
            value={actualData.live_classifier_enabled}
            onToggle={value => updateForm('live_classifier_enabled', !value)}
          />
        </div>
        <div className="setting-container">
          <span className="input-label">Enable Label Description (Draft)</span>
          <ToggleButton
            styles={{ marginRight: 0 }}
            inactiveLabel={''}
            activeLabel={''}
            colors={{ active: { base: '#263c73', inactive: { base: '#E5E5E5' } } }}
            value={actualData.draft_description_enabled}
            onToggle={value => updateForm('draft_description_enabled', !value)}
          />
        </div>
        <div className="setting-container">
          <span className="input-label">Enable Label Description (Live)</span>
          <ToggleButton
            styles={{ marginRight: 0 }}
            inactiveLabel={''}
            activeLabel={''}
            colors={{ active: { base: '#263c73', inactive: { base: '#E5E5E5' } } }}
            value={actualData.live_description_enabled}
            onToggle={value => updateForm('live_description_enabled', !value)}
          />
        </div>
        <div className="setting-container">
          <span className="input-label">Enable Rerunning Solutions (Draft)</span>
          <ToggleButton
            styles={{ marginRight: 0 }}
            inactiveLabel={''}
            activeLabel={''}
            colors={{ active: { base: '#263c73', inactive: { base: '#E5E5E5' } } }}
            value={actualData.draft_rerunning_solution_enabled}
            onToggle={value => updateForm('draft_rerunning_solution_enabled', !value)}
          />
        </div>
        <div className="setting-container">
          <span className="input-label">Enable Rerunning Solutions (Live)</span>
          <ToggleButton
            styles={{ marginRight: 0 }}
            inactiveLabel={''}
            activeLabel={''}
            colors={{ active: { base: '#263c73', inactive: { base: '#E5E5E5' } } }}
            value={actualData.live_rerunning_solution_enabled}
            onToggle={value => updateForm('live_rerunning_solution_enabled', !value)}
          />
        </div>
        <div className="setting-container">
          <span className="input-label">Enable sys-handover (Draft)</span>
          <ToggleButton
            styles={{ marginRight: 0 }}
            inactiveLabel={''}
            activeLabel={''}
            colors={{ active: { base: '#263c73', inactive: { base: '#E5E5E5' } } }}
            value={actualData.draft_enable_sys_handover}
            onToggle={value => updateForm('draft_enable_sys_handover', !value)}
          />
        </div>
        <div className="setting-container">
          <span className="input-label">Enable sys-handover (Live)</span>
          <ToggleButton
            styles={{ marginRight: 0 }}
            inactiveLabel={''}
            activeLabel={''}
            colors={{ active: { base: '#263c73', inactive: { base: '#E5E5E5' } } }}
            value={actualData.live_enable_sys_handover}
            onToggle={value => updateForm('live_enable_sys_handover', !value)}
          />
        </div>
        <div className="setting-container">
          <ReactJson
            groupArraysAfterLength={1000000}
            name="Draft Parsers"
            displayDataTypes={false}
            displayObjectSize={false}
            quotesOnKeys={false}
            onAdd={val => updateForm('draft_parsers', JSON.stringify(val.updated_src))}
            onEdit={val => updateForm('draft_parsers', JSON.stringify(val.updated_src))}
            onDelete={val => updateForm('draft_parsers', JSON.stringify(val.updated_src))}
            src={actualData?.draft_parsers && JSON.parse(actualData?.draft_parsers)}
            collapsed={true}
          />
        </div>
        <div className="setting-container">
          <ReactJson
            groupArraysAfterLength={1000000}
            name="Live Parsers"
            displayDataTypes={false}
            displayObjectSize={false}
            quotesOnKeys={false}
            onAdd={val => updateForm('live_parsers', JSON.stringify(val.updated_src))}
            onEdit={val => updateForm('live_parsers', JSON.stringify(val.updated_src))}
            onDelete={val => updateForm('live_parsers', JSON.stringify(val.updated_src))}
            src={actualData?.live_parsers && JSON.parse(actualData?.live_parsers)}
            collapsed={true}
          />
        </div>
        <div className="setting-container">
          <span className="input-label">Message speed(Draft)</span>
          <Input
            type={'number'}
            value={JSON.parse(actualData?.draft_message_speed || '{}')?.value}
            onChange={e =>
              (e.target.value === '' ||
                ((JSON.parse(actualData?.draft_message_speed || '{}')?.mode === 'constant' ||
                  isPositiveInteger(e.target.value)) &&
                  parseInt(e.target.value) >= 0)) &&
              updateForm(
                'draft_message_speed',
                JSON.stringify({
                  mode: JSON.parse(actualData?.draft_message_speed || '{}')?.mode || 'constant',
                  value: e.target.value,
                })
              )
            }
            addonBefore={
              <Select
                value={JSON.parse(actualData?.draft_message_speed || '{}')?.mode || 'constant'}
                onChange={mode =>
                  updateForm(
                    'draft_message_speed',
                    JSON.stringify({
                      mode,
                      value: '',
                    })
                  )
                }
                className="select-before"
              >
                <Option value="constant">Constant</Option>
                <Option value="dynamic">Dynamic</Option>
              </Select>
            }
            addonAfter={
              JSON.parse(actualData?.draft_message_speed || '{}')?.mode === 'dynamic' ? 'words per minute' : 'seconds'
            }
          />
        </div>
        <div className="setting-container">
          <span className="input-label">Message speed(Live)</span>
          <Input
            type={'number'}
            value={JSON.parse(actualData?.live_message_speed || '{}')?.value}
            onChange={e =>
              (e.target.value === '' ||
                ((JSON.parse(actualData?.live_message_speed || '{}')?.mode === 'constant' ||
                  isPositiveInteger(e.target.value)) &&
                  parseInt(e.target.value) >= 0)) &&
              updateForm(
                'live_message_speed',
                JSON.stringify({
                  mode: JSON.parse(actualData?.live_message_speed || '{}')?.mode || 'constant',
                  value: e.target.value,
                })
              )
            }
            addonBefore={
              <Select
                value={JSON.parse(actualData?.live_message_speed || '{}')?.mode || 'constant'}
                onChange={mode =>
                  updateForm(
                    'live_message_speed',
                    JSON.stringify({
                      mode,
                      value: '',
                    })
                  )
                }
                className="select-before"
              >
                <Option value="constant">Constant</Option>
                <Option value="dynamic">Dynamic</Option>
              </Select>
            }
            addonAfter={
              JSON.parse(actualData?.live_message_speed || '{}')?.mode === 'dynamic' ? 'words per minute' : 'seconds'
            }
          />
        </div>
        <div className="setting-container">
          <span className="input-label">External CWC Key(Draft)</span>
          <Input
            style={{ margin: 0 }}
            disabled
            value={actualData.draft_external_cwc_key}
            onChange={e => updateForm('draft_external_cwc_key', e.target.value)}
          />
          <RedoOutlined
            onClick={() => updateForm('draft_external_cwc_key', generateId(32))}
            style={{ fontSize: 40, left: 420, bottom: 38, cursor: 'pointer', position: 'relative' }}
          />
        </div>
        <div className="setting-container">
          <span className="input-label">External CWC Key(Live)</span>
          <Input
            style={{ margin: 0 }}
            disabled
            value={actualData.live_external_cwc_key}
            onChange={e => updateForm('live_external_cwc_key', e.target.value)}
          />
          <RedoOutlined
            onClick={() => updateForm('live_external_cwc_key', generateId(32))}
            style={{ fontSize: 40, left: 420, bottom: 38, cursor: 'pointer', position: 'relative' }}
          />
        </div>
        <Button label="Save" action={handleSave} />
      </div>

      <div className="right-side">
        <SettingsCwcPreview
          options={{
            ...widgetOptions,
            draft_parsers: undefined,
            live_parsers: undefined,
            draft_classifier_enabled: undefined,
            live_classifier_enabled: undefined,
            draft_default_label: undefined,
            live_default_label: undefined,
            draft_closing_label: undefined,
            live_closing_label: undefined,
            draft_fallback_label: undefined,
            live_fallback_label: undefined,
            draft_rerunning_solution_enabled: undefined,
            live_rerunning_solution_enabled: undefined,
            draft_enable_sys_handover: undefined,
            live_enable_sys_handover: undefined,
            exclude_launch: true,
            custom_bubble_style: {
              width: '400px',
              height: '800px',
              border: 'none',
              'box-sizing': 'border-box',
              'box-shadow': '0 2px 10px 1px #b5b5b5',
              'border-radius': '5px',
            },
          }}
        />
      </div>
    </div>
  );
};

export const mapStateToProps = state => {
  return {
    settings: state.settings,
    diagrams: state.labels.diagrams,
  };
};

const mapDispatchToProps = {
  fetchSettings: settingsActions.fetchSettings,
  updateSettings: settingsActions.updateSettings,
  fetchSFMList: labelsActions.fetchSFMList,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRoles(['product_manager', 'admin'], withChatDraft(withRouter(Settings))));
