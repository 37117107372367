// import routes from './config/routes';
import React, { useEffect, useState } from 'react';
// import ReactDOM from 'react-dom';
import { getStore } from './components/Store';
import { Provider } from 'react-redux';
import { Main, ChatArea } from './components/Components';
import { initSocket } from './utils/bot_api';

const ChatComponent = ({ mode, labels, loggedInUser, segments, labelsFromOverview }) => {
  const [socketInitialized, setSocketInitialized] = useState(true);
  const store = getStore(mode);
  useEffect(() => {
    const socket = initSocket(mode, loggedInUser);
    setSocketInitialized(false);
    return () => {
      socket.disconnect();
    };
  }, [mode]);
  return (
    <>
      {!socketInitialized ? (
        <Provider store={store}>
          <Main>
            <ChatArea
              labels={labels}
              loggedInUser={loggedInUser}
              segments={segments}
              labelsFromOverview={labelsFromOverview}
            />
          </Main>
        </Provider>
      ) : null}
    </>
  );
};

export default ChatComponent;
