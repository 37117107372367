import crud from './common/CrudService';
import responseService from './common/ResponseService';

const API_BASE_URL = process.env.METRICS_API_URL || 'http://localhost:8004';

const MetricsService = {
  getAdminMetrics: params => {
    const metricsCRUD = crud(API_BASE_URL, 'none', ['admin_metrics_values']);
    return responseService.handle(metricsCRUD.list(params));
  },
  getBranchMetrics: params => {
    const metricsCRUD = crud(API_BASE_URL, 'none', ['metrics_label_version_branch']);
    return responseService.handle(metricsCRUD.list(params));
  },
  getMetrics: params => {
    const metricsCRUD = crud(API_BASE_URL, 'none', ['metrics_values']);
    return responseService.handle(metricsCRUD.list(params));
  },
  listGroups: () => {
    const metricsCRUD = crud(API_BASE_URL, 'none', ['groups']);
    return responseService.handle(metricsCRUD.list());
  },
  listCustomMetricContextKeys: () => {
    const metricsCRUD = crud(API_BASE_URL, 'none', ['custom_metrics_context_key']);
    return responseService.handle(metricsCRUD.list());
  },
  insertCustomMetricContextKey: contextKey => {
    const metricsCRUD = crud(API_BASE_URL, 'none', ['custom_metrics_context_key']);
    return responseService.handle(metricsCRUD.create({ context_key: contextKey }));
  },
  deleteCustomMetricContextKey: contextKey => {
    const metricsCRUD = crud(API_BASE_URL, 'none', ['custom_metrics_context_key']);
    return responseService.handle(metricsCRUD.delete(null, { context_key: contextKey }));
  },
};

export default MetricsService;
