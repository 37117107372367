import React, { useState, useEffect, useRef, useMemo } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { Select, ConfigProvider, Empty, Divider } from 'antd';
import './Message.scss';
import labellingActions from '../../../../action-creators/labellingActions';
import { PlusSquareOutlined } from '@ant-design/icons';
import NotificationService from '../../../../services/common/NotificationService';
import {
  getSenderCaptionFromChannelAndRole,
  getClassNameFromChannelAndRole,
} from '../../../../helpers/messageChannelAndRoleInfo';
import { SUPPORTED_LANGUAGES } from '../../../settings/Settings';
import { languagesWithFlags } from "../../../../constants/languagesWithFlags";

const customizeRenderEmpty = () => (
  <div style={{ textAlign: 'center' }}>
    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={'No Labels Found'} />
  </div>
);

const MemoizedMessage = ({ message, labels, ...rest }) =>
  useMemo(
    () => <Message message={message} labels={labels} {...rest} />,
    [message?.messageLabels?.length, message?.lang_label, labels?.length]
  );

const Message = ({
  chat,
  message,
  createMessageLabel,
  deleteMessageLabel,
  addLabelsModal,
  labels,
  updateMessageLangLabel,
  languages = languagesWithFlags,
  labelMode,
}) => {
  const [showReasonMarker, setShowReasonMarker] = useState(false);
  const [showLangLabel, setShowLangLabel] = useState(false);
  const [searchLabel, setSearchLabel] = useState('');
  const [langLabel, setLangLabel] = useState(message?.lang_label);
  const [languagesDropdownOpen, setLanguagesDropdownOpen] = useState(false);
  const messageStateRef = useRef();
  const langLabelRef = useRef();
  langLabelRef.current = langLabel;

  useEffect(() => {
    messageStateRef.current = message;
    setLangLabel(message?.lang_label);
  }, [message]);

  useEffect(() => {
    langLabelRef.current = langLabel;
  }, [langLabel]);

  useEffect(() => {
    document.addEventListener(`onLabelSelectorOpen`, e => {
      if (messageStateRef.current.id !== e.detail.id) {
        if (!messageStateRef.current?.messageLabels?.length) setShowReasonMarker(false);
        if (!langLabelRef.current) setShowLangLabel(false);
      }
    });
  }, []);

  useEffect(() => {
    if (showReasonMarker) {
      document.getElementById(`input-${chat.id}-${message.id}`)?.focus();
    }
  }, [showReasonMarker]);

  const openLabelSelector = () => {
    console.log(`${message.id}-clicked`);
    let event = new CustomEvent(`onLabelSelectorOpen`, { detail: { id: message.id } });
    document.dispatchEvent(event);
    if (message.role !== 'client') {
      NotificationService.push({
        type: 'info',
        title: 'Wrong message selected',
        message: 'You can only label customer messages.',
      });
      return;
    }
    setShowLangLabel(!showLangLabel);
    setShowReasonMarker(!showReasonMarker);
  };
  useEffect(() => {
    if (showReasonMarker) {
      document.getElementById(`input-${chat.id}-${message.id}`)?.focus();
    }
  }, [showReasonMarker]);

  const handleMessageLabelsChange = values => {
    message?.messageLabels?.forEach(messageLabel => {
      if (!values.includes(messageLabel.label_id?.toString())) deleteMessageLabel(messageLabel.id);
    });
    values.forEach(value => {
      if (!message?.messageLabels?.find(messageLabel => messageLabel.label_id?.toString() == value)) {
        createMessageLabel(
          chat.id,
          message.id,
          value,
          langLabel,
          labelMode === 'my_labels' ? 'active_learning' : 'uncertain'
        );
        if (message?.messageLabels?.length === 0) expectingData = true;
      }
    });
    setSearchLabel('');
  };
  const messageText =
    message.message.split('mt_internal_separator')[message.message.split('mt_internal_separator').length - 1];
  return (
    <div
      name={`message-${chat.id}-${message.id}`}
      id={`message-${chat.id}-${message.id}`}
      className="message-container"
    >
      <div className={`message-content ${getClassNameFromChannelAndRole(message.role, message.channel)}`}>
        <div
          style={{ cursor: message?.role === 'client' ? 'pointer' : 'default' }}
          onClick={() => openLabelSelector()}
          className="message"
        >
          <p dir="auto" className="message-text">
            {messageText}
          </p>
          <div className="sender-info">
            <span className="message-sender">{`${getSenderCaptionFromChannelAndRole(
              message.role,
              message.channel
            )} `}</span>
            <span className="message-timestamp">
              &#183;{` ${moment(message.created_on).format('DD/MM/YYYY - HH:mm')}`}
            </span>
          </div>
        </div>
        {message.role === 'client' ? (
          <div dir="auto">
            <Select
              id={`input-lang-${chat.id}-${message.id}`}
              showAction="focus"
              size={'large'}
              getPopupContainer={trigger => trigger.parentNode}
              style={{
                display: langLabel || showLangLabel ? 'block' : 'none',
                marginLeft: 10,
                minWidth: languagesDropdownOpen ? 100 : 'unset',
                fontSize: langLabel ? 22 : 'unset',
              }}
              placeholder="Language"
              filterOption={(input, option) =>
                option.props.key.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              optionLabelProp="label"
              value={langLabel}
              allowClear={langLabel !== null}
              onChange={lang => {
                setLangLabel(lang);
                updateMessageLangLabel({ message_id: message.id, lang });
              }}
              onDropdownVisibleChange={setLanguagesDropdownOpen}
            >
              {Array.isArray(languages) &&
                languages.length > 0 &&
                languages
                  .filter(lang => SUPPORTED_LANGUAGES.includes(lang.key))
                  .map(option => (
                    <Option key={option.key} value={option.key} label={option.flag}>
                      {option.lang} {option.flag}
                    </Option>
                  ))}
            </Select>
          </div>
        ) : null}
      </div>

      {message.role === 'client' ? (
        <div>
          {
            <ConfigProvider renderEmpty={customizeRenderEmpty}>
              <div dir="auto">
                <Select
                  size={'large'}
                  getPopupContainer={trigger => trigger.parentNode}
                  showAction="focus"
                  onBlur={() => setShowReasonMarker(false)}
                  id={`input-${chat.id}-${message.id}`}
                  mode="multiple"
                  allowClear={false}
                  open={message?.messageLabels?.length > 0 ? undefined : showReasonMarker}
                  value={message?.messageLabels?.map(label => label?.label_id?.toString())}
                  style={{
                    minWidth: 230,
                    display: showReasonMarker || message?.messageLabels?.length > 0 ? 'block' : 'none',
                  }}
                  placeholder="Place Labels"
                  loading={!labels}
                  disabled={!labels}
                  filterOption={(input, option) =>
                    option.props.key.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  onChange={values => handleMessageLabelsChange(values)}
                  onSearch={value => setSearchLabel(value?.normalize('NFKD'))}
                  searchValue={searchLabel}
                  dropdownRender={menu => (
                    <div>
                      {menu}
                      {Array.isArray(labels) &&
                      (labels.length == 0 ||
                        !labels.some(label => label?.name?.toLowerCase()?.indexOf(searchLabel?.toLowerCase()) >= 0)) ? (
                        <div>
                          <Divider style={{ margin: '4px 0' }} />
                          <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8, justifyContent: 'center' }}>
                            <a
                              style={{ display: 'flex', padding: '8px', cursor: 'pointer', alignItems: 'center' }}
                              onMouseDown={() => {
                                addLabelsModal(searchLabel);
                                setTimeout(() => {
                                  document.getElementById(`input-${chat.id}-${message.id}`).blur();
                                }, 100);
                              }}
                            >
                              <PlusSquareOutlined
                                style={{ fontSize: 20, color: '#e61a3c', paddingRight: 10, maxWidth: 50 }}
                              />
                              <div
                                style={{
                                  display: 'block',
                                  whiteSpace: 'nowrap',
                                  overflow: 'hidden',
                                  textOverflow: 'ellipsis',
                                  maxWidth: 170,
                                }}
                              >
                                Add New Label {searchLabel}
                              </div>
                            </a>
                          </div>
                        </div>
                      ) : null}
                    </div>
                  )}
                >
                  {Array.isArray(labels) &&
                    labels.length > 0 &&
                    labels.map(option => (
                      <Option key={option?.id?.toString()} value={option?.id?.toString()}>
                        {option.name}
                      </Option>
                    ))}
                </Select>
              </div>
            </ConfigProvider>
          }
        </div>
      ) : null}
    </div>
  );
};

export const mapStateToProps = state => {
  return {};
};

const mapDispatchToProps = {
  createMessageLabel: labellingActions.createMessageLabel,
  deleteMessageLabel: labellingActions.deleteMessageLabel,
};

export default connect(mapStateToProps, mapDispatchToProps)(MemoizedMessage);
