const initialModalsState = {
  unsavedChangesModal: {
    modalKey: 'unsavedChanges',
    isOpen: false,
    actionOnConfirm: () => {},
    argsOnConfirm: {},
    extraModalArgs: {},
    actionOnCancel: () => {},
    argsOnCancel: {},
  },
  localDiagramChangesModal: {
    modalKey: 'localDiagramChanges',
    isOpen: false,
    actionOnConfirm: () => {},
    argsOnConfirm: {},
    extraModalArgs: {},
    actionOnCancel: () => {},
    argsOnCancel: {},
  },
  diagramCreationConflictModal: {
    modalKey: 'diagramCreationConflict',
    isOpen: false,
    actionOnConfirm: () => {},
    argsOnConfirm: {},
    extraModalArgs: {},
    actionOnCancel: () => {},
    argsOnCancel: {},
  },
  diagramUpdateConflictModal: {
    modalKey: 'diagramUpdateConflict',
    isOpen: false,
    actionOnConfirm: () => {},
    argsOnConfirm: {},
    extraModalArgs: {},
    actionOnCancel: () => {},
    argsOnCancel: {},
  },
  confirmSaveModal: {
    modalKey: 'confirmSave',
    isOpen: false,
    actionOnConfirm: () => {},
    argsOnConfirm: {},
    extraModalArgs: {},
    actionOnCancel: () => {},
    argsOnCancel: {},
  },
  confirmLiveModal: {
    modalKey: 'confirmLive',
    isOpen: false,
    actionOnConfirm: () => {},
    argsOnConfirm: {},
    extraModalArgs: {},
    actionOnCancel: () => {},
    argsOnCancel: {},
  },
  confirmDeleteVersionModal: {
    modalKey: 'confirmDeleteVersion',
    isOpen: false,
    actionOnConfirm: () => {},
    argsOnConfirm: {},
    extraModalArgs: {},
    actionOnCancel: () => {},
    argsOnCancel: {},
  },
};

export default initialModalsState;
