import callbotTaskTypes from "../moddle/callbot-task-types.json";
import tasksExtension from "../moddle/tasks.json";
import callbotPaletteModule from "../components/custom-palette/callbot-pallete";
import customPaletteModule from "../components/custom-palette";
import callbotContextPad from "../components/custom-context-pad/callbot-context-pad";
import customContextPad from "../components/custom-context-pad";
import callbotTaskRenderer from "../renderers/tasks/callbotTasksRenderer";
import taskRendererModule from "../renderers/tasks";
import Modeler from "bpmn-js/lib/Modeler";
import processExtension from "../moddle/process-properties.json";
import selfConnectionsModule from "../rules/self-connection-rules";
import comments from "../components/comments";
import NavigatedViewer from "bpmn-js/lib/NavigatedViewer";
import searchProvider from "../search";
import copyPaste from "../copy-paste/copyPaste";

function getTasks(type) {
  switch (type) {
    case "callbot":
      const nameTaskMap = {}
      callbotTaskTypes.forEach(taskType => {
        nameTaskMap[taskType.name] = taskType;
      });
      return {
        ...tasksExtension,
        types: [...tasksExtension.types.filter(({name}) => !nameTaskMap[name]), ...callbotTaskTypes]
      }
    case "chatbot":
    default:
      return tasksExtension;
  }
}

function getPalette(type) {
  switch (type) {
    case "callbot":
      return callbotPaletteModule;
    case "chatbot":
    default:
      return customPaletteModule;
  }
}

function getContextPad(type) {
  switch (type) {
    case "callbot":
      return callbotContextPad;
    case "chatbot":
    default:
      return customContextPad;
  }
}

function getTaskRenderer(type) {
  switch (type) {
    case "callbot":
      return callbotTaskRenderer;
    case "chatbot":
    default:
      return taskRendererModule;
  }
}

function createModeler(diagramType) {
  const container = document.querySelector('#sfm-app-modeler');

  const modeler = new Modeler({
    container,
    moddleExtensions: {
      tasksExtension: getTasks(diagramType),
      processExtension: processExtension,
    },
    additionalModules: [
      searchProvider,
      selfConnectionsModule,
      copyPaste,
      // resizeAllModule,
      getPalette(diagramType),
      getContextPad(diagramType),
      getTaskRenderer(diagramType),
    ],
  });

  container.addEventListener(
    'mouseenter',
    () => {
      modeler.get('keyboard').bind(document.body);
    },
    false
  );

  container.addEventListener(
    'mouseleave',
    () => {
      modeler.get('keyboard').unbind();
    },
    false
  );

  return modeler;
}

function createViewer(diagramType, review) {
  const additionalModules = [getTaskRenderer(diagramType)];
  let container;
  if (review) {
    additionalModules.push(comments);
    container = document.querySelector('#sfm-app-review-viewer');
  } else {
    container = document.querySelector('#sfm-app-viewer')
  }

  return new NavigatedViewer({
    container,
    moddleExtensions: {
      tasksExtension: getTasks(diagramType),
      processExtension: processExtension,
    },
    additionalModules,
  })
}

export function destroyBpmnEditor(modeler, viewer, reviewViewer) {
  modeler.get('keyboard').unbind();
  viewer.destroy();
  reviewViewer.destroy();
  modeler.destroy();
}

export function createBpmnEditor(diagramType) {
  return [createModeler(diagramType), createViewer(diagramType, false), createViewer(diagramType, true)];
}

function recreateBpmnEditor(diagramType, oldModeler, oldViewer, oldReviewViewer) {
  destroyBpmnEditor(oldModeler, oldViewer, oldReviewViewer);
  return createBpmnEditor(diagramType);
}
