import { initializeApp } from "firebase/app";
import { getStorage, ref, uploadBytes } from "firebase/storage";
import uuid from 'uuid'

let app = null;
let storage = null;

const folder = process.env.REACT_APP_FIREBASE_FOLDER || 'titancs';

const initFirebase = () => {
  const firebaseConfig = JSON.parse(process.env.REACT_APP_FIREBASE_CREDS);
  app = initializeApp(firebaseConfig);
  storage = getStorage()
}

const getDateStr = () => {
  const date = new Date();
  const isoStr = date.toISOString()
  return isoStr
    .replaceAll('T', '_')
    .replaceAll(':', '-')
    .slice(0, 19);
}

const buildFileName = (filename) => {
  return `${getDateStr()}-${uuid.v4()}-${filename}`;
}

export function uploadFile(mode, file) {
  if (!app) {
    initFirebase();
  }

  return new Promise((resolve, reject) => {
    const filename = `titancs/${folder}/${buildFileName(file.name)}`
    const storageRef = ref(storage, filename);
    uploadBytes(storageRef, file).then(() => resolve(filename)).catch(reject);
  });
}
