import React from 'react';
import _ from 'lodash';

const MultiOption = ({ options, value, onChange }) => {
  options = (options || []).map(option => {
    if (typeof option !== 'object') {
      option = {
        value: option,
        label: option,
      };
    }
    if (value && value.includes(option.value)) {
      option.selected = true;
    }
    return option;
  });

  function optionClassname(option) {
    const classes = ['multiple-selector-option'];
    if (option.selected) {
      classes.push('selected');
    }
    return classes.join(' ');
  }

  function onValueToggle(optionValue) {
    if (!onChange) return;

    let newVal;

    if (!_.includes(value, optionValue)) {
      newVal = _.concat(value, optionValue);
    } else {
      newVal = _.without(value, optionValue);
    }

    console.log('NEW VAL', newVal);
    onChange(newVal);
  }

  return (
    <div id="multiple-selector">
      <div id="multiple-selector-options">
        {options.map(option => (
          <div className={optionClassname(option)} key={option.value} onClick={() => onValueToggle(option.value)}>
            <p>{option.label}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default MultiOption;
