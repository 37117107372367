import { put, call, all, take, takeLatest, spawn, select } from 'redux-saga/effects';

import DiagramsService from '../services/DiagramsService';
import NotificationService from '../services/common/NotificationService';
import modalsActions from '../action-creators/modalsActions';
import currentDiagramAndVersionActions from '../action-creators/currentDiagramAndVersionActions';
import saveProgressActions from '../action-creators/saveProgressActions';
import diagramVersionsActions from '../action-creators/diagramVersionsActions';
import WebUrlService from '../services/common/WebUrlService';
import moment from 'moment';
import dbActions from "../action-creators/dbActions";
import {timer} from "../helpers/timer";
const currentOptionalComment = state => state.app.currentOptionalDiagramComment;
const currentDiagramShouldReview = state => state.app.currentDiagramShouldReview;
const adminLabels = state => state.app.labels;

function* saveNewDiagramFlow(action) {
  timer.start('Save new diagram');
  try {
    yield put(saveProgressActions.updateSaveProgress({ saveInProgress: true, saveProgressStatus: 25 }));
    const currentComment = yield select(currentOptionalComment);
    const diagramShouldReview = yield select(currentDiagramShouldReview);
    const labels = yield select(adminLabels);
    const labelExists = labels.find(label => label.name === action.versionLabel);
    let reviewDueDate = moment();
    if (labelExists) {
      reviewDueDate =
        labelExists.interval === 'weekly'
          ? reviewDueDate.add('1', 'weeks')
          : labelExists.interval === 'monthly'
          ? reviewDueDate.add('1', 'months')
          : labelExists.interval === 'quarterly'
          ? reviewDueDate.add('4', 'months')
          : reviewDueDate.add('1', 'years');
    }
    const response = yield call(DiagramsService.createDiagram, {
      diagramName: action.diagramName,
      versionLabel: action.versionLabel,
      owner: action.owner,
      file: action.file,
      comment: currentComment,
      shouldReview: labelExists !== null,
      reviewDueDate: reviewDueDate.toDate(),
      diagramType: action.diagramType,
    });
    if (response.status === 200) {
      // UPDATE STATE AFTER SAVE
      yield put(saveProgressActions.updateSaveProgress({ saveInProgress: true, saveProgressStatus: 75 }));
      yield put(
        currentDiagramAndVersionActions.updateCurrentDiagramAndVersion(
          response.data['diagram'],
          response.data['diagram_versions'][0],
          response.data['diagram_versions'],
          false,
          true,
          true,
          true
        )
      );
      yield put(dbActions.updateIndexedDB(action.modeler));
      yield put(modalsActions.closeModal('localDiagramChanges'));
      yield put(saveProgressActions.updateSaveProgress({ saveInProgress: true, saveProgressStatus: 100 }));
      // NotificationService.push({
      //     type: 'success',
      //     message: 'New diagram and its initial version were successfully created and saved!'
      // });
      WebUrlService.updateWebUrlWithVersion(action.versionLabel);
      if (action.makeTest) {
        yield put(
          diagramVersionsActions.changeDiagramVersionStatus({
            versionId: response.data['diagram_versions'][0].id,
            forDiagram: 'selected',
            statusAction: 'test',
            shouldProceed: true,
          })
        );
      }
    } else if (response.status === 409) {
      yield put(saveProgressActions.updateSaveProgress({ saveInProgress: true, saveProgressStatus: 25 }));
      yield put(
        modalsActions.openModal(
          'diagramCreationConflict',
          currentDiagramAndVersionActions.saveNewCurrentDiagramAndVersion,
          {
            diagramName: action.diagramName,
            versionLabel: action.versionLabel,
            file: action.file,
            owner: action.owner,
            diagramVersion: action.diagramVersion,
          },
          {
            isConflictInName: response.data.includes('name'),
            isConflictInLabel: response.data.includes('label'),
          },
          saveProgressActions.updateSaveProgress,
          {
            saveInProgress: false,
            saveProgressStatus: 0,
          }
        )
      );
    }
    yield put(currentDiagramAndVersionActions.updateCurrentOptionalComment(''));
  } catch (error) {
    console.log('ERROR on save: ', error);
    NotificationService.push({
      type: 'error',
    });
  }
  timer.end("Save new diagram");
}

function* watchSaveNewDiagramRequested() {
  yield takeLatest('SAVE_NEW_CURRENT_DIAGRAM_AND_VERSION', saveNewDiagramFlow);
}

export default function* saveNewDiagramSaga() {
  yield all([watchSaveNewDiagramRequested()]);
}
