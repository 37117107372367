import React from 'react';
import { ComposedChart, Line, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import moment from 'moment';

const Resolution = ({ metrics, titles, dateFormat = 'MMMM YYYY', lineChartEnabled = true, barChartEnabled = true }) => {
  return (
    <ResponsiveContainer width="100%" height="100%">
      <ComposedChart
        height={350}
        data={metrics}
        margin={{
          top: 20,
          right: 20,
          bottom: 20,
          left: 20,
        }}
      >
        <CartesianGrid stroke="#f5f5f5" />
        <XAxis dataKey="dt" name="Time" tickFormatter={unixTime => moment(unixTime).format(dateFormat)} />
        {barChartEnabled && <YAxis stroke="#2A3254" yAxisId="left" />}
        {barChartEnabled && <Bar yAxisId="left" name={titles[0]} dataKey="count" barSize={20} fill="#283257" />}
        {lineChartEnabled && (
          <YAxis stroke="#D43643" yAxisId="right" orientation={barChartEnabled ? 'right' : 'left'} domain={[0, 100]} />
        )}
        {lineChartEnabled && (
          <Line yAxisId="right" type="monotone" name={titles[1]} dataKey="percent" stroke="#D43643" />
        )}
        <Legend />
        <Tooltip labelFormatter={unixTime => moment(unixTime).format(dateFormat)} />
      </ComposedChart>
    </ResponsiveContainer>
  );
};

export default Resolution;
