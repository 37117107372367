const fetchSettings = () => {
  return {
    type: 'FETCH_SETTINGS',
  };
};

const fetchSettingsComplete = settings => {
  return {
    type: 'FETCH_SETTINGS_COMPLETE',
    settings,
  };
};

const updateSettings = settings => {
  return {
    type: 'UPDATE_SETTINGS',
    settings,
  };
};

const settingsActions = {
  fetchSettings,
  updateSettings,
  fetchSettingsComplete,
};

export default settingsActions;
