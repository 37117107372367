import crud from './common/CrudService';
import responseService from './common/ResponseService';

const API_BASE_URL = process.env.API_URL || 'http://127.0.0.1:8000';
const API_PREFIX = process.env.API_PREFIX || 'api/v1';
const qualityAssessmentCRUD = crud(API_BASE_URL, API_PREFIX, ['quality_assessments/']);

const QualityAssessmentsService = {
  getAssessmentsList: (skip, limit, showMarkedChats, label, labelVersion, branch) => {
    const params = {
      limit,
    };
    if (skip != null) params['skip'] = `${skip}`;
    if (label) params['label'] = label;
    if (showMarkedChats) params['show_marked_chats'] = showMarkedChats;
    if (labelVersion) params['label_version'] = labelVersion;
    if (branch) params['branch'] = branch;

    return responseService.handle(qualityAssessmentCRUD.list(params));
  },
  getChatsByFailureReason: (label, labelVersion, failureReason) => {
    const params = {};
    if (label) params['label'] = label;
    if (labelVersion) params['label_version'] = labelVersion;
    if (failureReason) params['failure_reason'] = failureReason;
    return responseService.handle(crud(API_BASE_URL, API_PREFIX, ['quality_assessments', 'byreason']).list(params));
  },
  createQualityAssessment: ({ chatId, isChatSolvedByBot, isNewReason, failureReasonId, messageId, branches }) => {
    return responseService.handle(
      qualityAssessmentCRUD.create({
        chat_intercom_id: chatId,
        is_chat_solved_by_bot: isChatSolvedByBot,
        failure_reason_id: !isNewReason ? failureReasonId : undefined,
        reason: isNewReason ? failureReasonId : undefined,
        message_id: messageId,
        is_new_reason: isNewReason,
        branches,
      })
    );
  },

  updateQualityAssessment: ({ id, chatId, isChatSolvedByBot, failureReasonId }) => {
    const data = {
      chat_intercom_id: chatId,
      is_chat_solved_by_bot: isChatSolvedByBot,
      failure_reason_id: failureReasonId,
    };

    return responseService.handle(qualityAssessmentCRUD.update(id, data));
  },

  deleteQualityAssessment: ({ id }) => {
    return responseService.handle(qualityAssessmentCRUD.delete(id));
  },
};

export default QualityAssessmentsService;
