import crud from './common/CrudService';
import responseService from './common/ResponseService';

const API_BASE_URL = process.env.API_URL || 'http://127.0.0.1:8000';
const API_PREFIX = process.env.API_PREFIX || 'api/v1';
const labelsCRUD = crud(API_BASE_URL, API_PREFIX, ['failure_reasons/']);

const FailureReasonsService = {
  getFailureReasonsList: () => {
    return responseService.handle(labelsCRUD.list());
  },

  createFailureReason: ({ reason }) => {
    return responseService.handle(
      labelsCRUD.create({
        reason,
      })
    );
  },

  // updateFailureReason: ({ id, name, owner, interval, description }) => {
  //     return responseService.handle(
  //         labelsCRUD.update(
  //             id,
  //             {
  //                 'name': name,
  //                 'description': description
  //             }
  //         )
  //     );
  // },
  //
  // deleteFailureReason: ({ id }) => {
  //     return responseService.handle(
  //         labelsCRUD.delete(id)
  //     );
  // }
};

export default FailureReasonsService;
