import React from 'react';
import Message from './message/Message';
import './QualityAssessmentChat.scss';
const QualityAssessmentChat = ({ chat, failureReasons, setReasons }) => {
  chat.messages = chat.messages.filter(message => message.message);
  return (
    <div className="chat">
      <div className="reason-selector-container"></div>
      {chat.messages && chat.messages.length > 0 ? (
        chat.messages.map(message => (
          <Message
            setQualityAssessmentsWithReasons={reasons => {
              let reasonsToAdd =
                reasons?.filter(
                  reason =>
                    !message?.quality_assessments?.find(
                      quality_assessment => quality_assessment?.failure_reason?.reason == reason
                    )
                ) || [];

              let reasonsToRemove =
                message?.quality_assessments?.filter(
                  quality_assessment => !reasons?.includes(quality_assessment?.failure_reason?.reason)
                ) || [];

              setReasons(reasonsToAdd, reasonsToRemove, message.id);
            }}
            failureReasons={failureReasons}
            chat={chat}
            message={message}
          />
        ))
      ) : (
        <p> No messages found </p>
      )}
    </div>
  );
};

export default QualityAssessmentChat;
