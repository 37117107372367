import { put, call, all, take, takeLatest, spawn } from 'redux-saga/effects';
import NotificationService from '../services/common/NotificationService';
import MessageLabelsService from '../services/MessageLabelsService';
import labellingActions from '../action-creators/labellingActions';

function* createMessageLabelFlow(action) {
  try {
    let payload = {
      label: { id: parseInt(action.labelId) },
      message_id: action.messageId,
      label_id: parseInt(action.labelId),
      chat_intercom_id: action.chatId,
      source: action.labelMode,
    };
    yield put(labellingActions.addMessageLabel(payload));
    const response = yield call(MessageLabelsService.createMessageLabel, action);
    if (response.status === 200) {
      yield put(labellingActions.addMessageLabel(response.data));
      yield put(labellingActions.removeMessageLabel(payload));
    } else {
      NotificationService.push({
        type: 'error',
        message: response.data,
      });
    }
  } catch (error) {
    NotificationService.push({
      type: 'error',
    });
  }
}

function* createMessageLabelExampleFlow(action) {
  try {
    const response = yield call(MessageLabelsService.createMessageLabelExample, action);
    if (response.status === 200) {
      NotificationService.push({
        type: 'success',
        message: 'Message label examples created',
      });
    } else {
      NotificationService.push({
        type: 'error',
        message: response.data,
      });
    }
  } catch (error) {
    NotificationService.push({
      type: 'error',
    });
  }
}

function* watchCreateMessageLabelRequested() {
  yield takeLatest('CREATE_MESSAGE_LABEL', createMessageLabelFlow);
}

function* watchCreateMessageLabelExampleRequested() {
  yield takeLatest('CREATE_MESSAGE_LABEL_EXAMPLE', createMessageLabelExampleFlow);
}

function* deleteMessageLabelFlow(action) {
  try {
    const response = yield call(MessageLabelsService.deleteMessageLabel, action);
    if (response.status === 200) {
      yield put(labellingActions.removeMessageLabel(response.data));
    } else {
      NotificationService.push({
        type: 'error',
        message: response.data,
      });
    }
  } catch (error) {
    NotificationService.push({
      type: 'error',
    });
  }
}

function* watchDeleteMessageLabelRequested() {
  yield takeLatest('DELETE_MESSAGE_LABEL', deleteMessageLabelFlow);
}

export default function* messageLabelsSaga() {
  yield all([
    watchCreateMessageLabelRequested(),
    watchDeleteMessageLabelRequested(),
    watchCreateMessageLabelExampleRequested(),
  ]);
}
