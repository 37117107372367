import crud from './common/CrudService';
import responseService from './common/ResponseService';

const API_BASE_URL = process.env.API_URL || 'http://127.0.0.1:8000';
const API_PREFIX = process.env.API_PREFIX || 'api/v1';
const labelsCRUD = crud(API_BASE_URL, API_PREFIX, ['labels/']);

const LabelsService = {
  getLabelsList: () => {
    return responseService.handle(labelsCRUD.list());
  },

  createLabel: ({ name, owner_id, interval, description, parent_id, is_auxiliary }) => {
    return responseService.handle(
      labelsCRUD.create({
        name: name?.normalize('NFKD'),
        description,
        owner_id,
        interval,
        parent_id,
        is_auxiliary,
      })
    );
  },

  updateLabel: ({ id, name, owner_id, interval, description, parent_id, is_auxiliary }) => {
    return responseService.handle(
      labelsCRUD.update(id, {
        name,
        description,
        owner_id,
        interval,
        parent_id,
        is_auxiliary,
      })
    );
  },

  deleteLabel: ({ id }) => {
    return responseService.handle(labelsCRUD.delete(id));
  },
};

export default LabelsService;
