import { put, call, all, take, takeLatest, spawn } from 'redux-saga/effects';
import NotificationService from '../services/common/NotificationService';
import UsersService from '../services/UsersService';
import usersActions from '../action-creators/usersActions';
import modalsActions from '../action-creators/modalsActions';
import LoginService from "../services/LoginService";
import authActions from "../action-creators/authActions";

function* usersListFlow() {
  try {
    const response = yield call(UsersService.getUsersList);
    if (response.status === 200) {
      yield put(usersActions.updateUsersList(response.data));
    } else {
      NotificationService.push({
        type: 'error',
        message: response.data,
      });
    }
  } catch (error) {
    NotificationService.push({
      type: 'error',
    });
  }
}

function* watchUsersListRequested() {
  yield takeLatest('FETCH_USERS_LIST', usersListFlow);
}

function* createUserFlow(action) {
  try {
    const response = yield call(UsersService.createUser, action.user);
    if (response.status === 200) {
      yield put(usersActions.fetchUsersList());
      yield put(modalsActions.toggleModal('editUserModal'));
    } else if (response.status === 422) {
      yield put(modalsActions.changeModalError('Please check email correctness'));
    } else {
      NotificationService.push({
        type: 'error',
        message: 'Something went wrong on user creation',
      });
    }
  } catch (error) {
    NotificationService.push({
      type: 'error',
    });
  }
}

function* watchCreateUserRequested() {
  yield takeLatest('CREATE_USER', createUserFlow);
}

function* updateUserFlow(action) {
  try {
    const response = yield call(UsersService.updateUser, action.user);
    if (response.status === 200) {
      if (action.user.id === action.currentUser.id) {
        const responseMe = yield call(LoginService.me);
        if (responseMe.status === 200) {
          yield put(authActions.setActiveUser(responseMe.data));
        }
      }

      yield put(usersActions.fetchUsersList());
      yield put(modalsActions.toggleModal('editUserModal'));
    } else if (response.status === 422) {
      yield put(modalsActions.changeModalError('Please check email correctness'));
    } else {
      NotificationService.push({
        type: 'error',
        message: response.data,
      });
    }
  } catch (error) {
    NotificationService.push({
      type: 'error',
    });
  }
}

function* watchUpdateUserRequested() {
  yield takeLatest('UPDATE_USER', updateUserFlow);
}

function* deleteUserFlow(action) {
  try {
    const response = yield call(UsersService.deleteUser, action.id);
    if (response.status === 200) {
      yield put(usersActions.fetchUsersList());
      yield put(modalsActions.toggleModal('deleteUserModal'));
    } else {
      NotificationService.push({
        type: 'error',
        message: response.data,
      });
    }
  } catch (error) {
    NotificationService.push({
      type: 'error',
    });
  }
}

function* watchDeleteUserRequested() {
  yield takeLatest('DELETE_USER', deleteUserFlow);
}

export default function* usersSaga() {
  yield all([
    watchUsersListRequested(),
    watchCreateUserRequested(),
    watchUpdateUserRequested(),
    watchDeleteUserRequested(),
  ]);
}
