import React from 'react';

import './common.scss';

const Textarea = ({ label, placeholder, value, onChange, ...props }) => {
  return (
    <div className="input-container">
      <p className="input-label">{label}</p>
      <textarea
        className="textarea"
        // disabled={disabled}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        {...props}
      />
    </div>
  );
};

export default Textarea;
