import { put, call, all, take, takeLatest, spawn, select } from 'redux-saga/effects';
import DiagramsService from '../services/DiagramsService';
import diagramsActions from '../action-creators/diagramsActions';
import NotificationService from '../services/common/NotificationService';
import DiagramVersionsService from '../services/DiagramVersionsService';
import expandedDiagramActions from '../action-creators/expandedDiagramActions';
import currentDiagramAnVersionsActions from '../action-creators/currentDiagramAndVersionActions';
import modalsActions from '../action-creators/modalsActions';
import diagramVersionsActions from '../action-creators/diagramVersionsActions';
import ViewerService from '../services/ViewerService';
import currentDiagramAndVersionActions from '../action-creators/currentDiagramAndVersionActions';
import moment from 'moment';
import {timer} from "../helpers/timer";

const getExpandedDiagram = state => state.app.expandedDiagram;

const getCurrentDiagram = state => state.app.currentDiagram;

const getCurrentDiagramVersion = state => state.app.currentDiagramVersion;

const getExpandedDiagramLastVersionsCount = state => state.app.expandedDiagramLastVersions.length;

const getCurrentDiagramLastVersionsCount = state => state.app.currentDiagramLastVersions.length;

const adminLabels = state => state.app.labels;

function* changeDiagramVersionsStatusFlow(action) {
  timer.start('Change diagram version status');
  try {
    if (action.shouldProceed) {
      const response = yield call(DiagramVersionsService.changeDiagramVersionStatus, {
        versionId: action.versionId,
        statusAction: action.statusAction,
      });
      if (response.status === 200) {
        const currentDiagram = yield select(getCurrentDiagram);
        DiagramVersionsService.deployDiagramVersionMessagesToCwc();
        DiagramVersionsService.updateBotWorkflowMappings(currentDiagram.id, action.statusAction);
        const expandedDiagram = yield select(getExpandedDiagram);
        if (action.forDiagram === 'expanded' && currentDiagram.id !== expandedDiagram.id) {
          yield put(diagramsActions.fetchDiagramsList());
          yield put(expandedDiagramActions.changeExpandedDiagram(expandedDiagram, false));
        } else if (currentDiagram.id === expandedDiagram.id) {
          yield put(diagramsActions.fetchDiagramsList());
          yield put(expandedDiagramActions.changeExpandedDiagram(expandedDiagram, false));
          const newCurrentDiagramResponse = yield call(DiagramsService.getDiagram, {
            diagramId: currentDiagram.id,
          });
          if (newCurrentDiagramResponse.status === 200) {
            yield put(
              currentDiagramAnVersionsActions.changeCurrentDiagramVersions({
                diagram: newCurrentDiagramResponse.data,
              })
            );
          } else {
            NotificationService.push({
              type: 'error',
              message: newCurrentDiagramResponse.data,
            });
          }
        } else {
          const newCurrentDiagramResponse = yield call(DiagramsService.getDiagram, {
            diagramId: currentDiagram.id,
          });
          if (newCurrentDiagramResponse.status === 200) {
            yield put(
              currentDiagramAnVersionsActions.changeCurrentDiagramVersions({
                diagram: newCurrentDiagramResponse.data,
              })
            );
          } else {
            NotificationService.push({
              type: 'error',
              message: newCurrentDiagramResponse.data,
            });
          }
        }
      } else {
        NotificationService.push({
          type: 'error',
          message: response.data,
        });
      }
    } else {
      let givenDiagram;
      if (action.forDiagram === 'expanded') {
        givenDiagram = yield select(getExpandedDiagram);
      } else {
        givenDiagram = yield select(getCurrentDiagram);
      }
      yield put(
        modalsActions.openModal(
          'confirmLive',
          diagramVersionsActions.changeDiagramVersionStatus,
          {
            versionId: action.versionId,
            forDiagram: action.forDiagram,
            statusAction: action.statusAction,
            shouldProceed: true,
          },
          {
            shouldChangeToLive: action.shouldChangeToLive,
            versionId: action.versionId,
            diagram: givenDiagram,
          }
        )
      );
    }
  } catch (error) {
    NotificationService.push({
      type: 'error',
    });
  }
  timer.end('Change diagram version status');
  timer.report();
}

function* changeDiagramVersionsStatusRequested() {
  yield takeLatest('CHANGE_DIAGRAM_VERSION_STATUS', changeDiagramVersionsStatusFlow);
}

function* deleteDiagramVersionFlow(action) {
  try {
    if (action.shouldProceed) {
      const response = yield call(DiagramVersionsService.deleteDiagramVersion, {
        versionId: action.versionId,
        deleteDiagram: action.deleteDiagram,
      });
      if (response.status === 200) {
        const expandedDiagram = yield select(getExpandedDiagram);
        const currentDiagram = yield select(getCurrentDiagram);
        if (action.forDiagram === 'expanded' && currentDiagram.id !== expandedDiagram.id) {
          yield put(diagramsActions.fetchDiagramsList());
          if (!action.deleteDiagram) yield put(expandedDiagramActions.changeExpandedDiagram(expandedDiagram, false));
        } else if (currentDiagram.id === expandedDiagram.id) {
          yield put(diagramsActions.fetchDiagramsList());
          if (!action.deleteDiagram) {
            yield put(expandedDiagramActions.changeExpandedDiagram(expandedDiagram, false));
            const newCurrentDiagramResponse = yield call(DiagramsService.getDiagram, {
              diagramId: currentDiagram.id,
            });
            if (newCurrentDiagramResponse.status === 200) {
              yield put(
                currentDiagramAnVersionsActions.changeCurrentDiagramVersions({
                  diagram: newCurrentDiagramResponse.data,
                })
              );
            } else {
              NotificationService.push({
                type: 'error',
                message: newCurrentDiagramResponse.data,
              });
            }
          } else {
            yield put(
              currentDiagramAndVersionActions.updateCurrentDiagramAndVersion(
                { id: -1 },
                -1,
                [],
                false,
                true,
                true,
                true
              )
            );
          }
        } else {
          if (!action.deleteDiagram) {
            const newCurrentDiagramResponse = yield call(DiagramsService.getDiagram, {
              diagramId: currentDiagram.id,
            });
            if (newCurrentDiagramResponse.status === 200) {
              yield put(
                currentDiagramAnVersionsActions.changeCurrentDiagramVersions({
                  diagram: newCurrentDiagramResponse.data,
                })
              );
            } else {
              NotificationService.push({
                type: 'error',
                message: newCurrentDiagramResponse.data,
              });
            }
          } else {
            yield put(
              currentDiagramAndVersionActions.updateCurrentDiagramAndVersion(
                { id: -1 },
                -1,
                [],
                false,
                true,
                true,
                true
              )
            );
          }
        }
      } else {
        NotificationService.push({
          type: 'error',
          message: response.data,
        });
      }
    } else {
      let givenDiagram;
      let versionsCount;
      if (action.forDiagram === 'expanded') {
        givenDiagram = yield select(getExpandedDiagram);
        versionsCount = yield select(getExpandedDiagramLastVersionsCount);
      } else {
        givenDiagram = yield select(getCurrentDiagram);
        versionsCount = yield select(getCurrentDiagramLastVersionsCount);
      }
      yield put(
        modalsActions.openModal(
          'confirmDeleteVersion',
          diagramVersionsActions.deleteDiagramVersion,
          {
            versionId: action.versionId,
            forDiagram: action.forDiagram,
            deleteDiagram: versionsCount == 1 ? 1 : 0,
            shouldProceed: true,
          },
          {
            versionId: action.versionId,
            diagram: givenDiagram,
            deleteDiagram: versionsCount == 1,
          }
        )
      );
    }
  } catch (error) {
    NotificationService.push({
      type: 'error',
    });
  }
}

function* deleteDiagramVersionsRequested() {
  yield takeLatest('DELETE_DIAGRAM_VERSION', deleteDiagramVersionFlow);
}

function* changeDiagramVersionsLiveStatusFlow(action) {
  try {
    if (action.shouldProceed) {
      const response = yield call(DiagramVersionsService.changeDiagramVersionLiveStatus, {
        versionId: action.versionId,
        shouldChangeToLive: action.shouldChangeToLive,
      });
      if (response.status === 200) {
        const expandedDiagram = yield select(getExpandedDiagram);
        const currentDiagram = yield select(getCurrentDiagram);
        if (action.forDiagram === 'expanded' && currentDiagram.id !== expandedDiagram.id) {
          yield put(diagramsActions.fetchDiagramsList());
          yield put(expandedDiagramActions.changeExpandedDiagram(expandedDiagram, false));
        } else if (currentDiagram.id === expandedDiagram.id) {
          yield put(diagramsActions.fetchDiagramsList());
          yield put(expandedDiagramActions.changeExpandedDiagram(expandedDiagram, false));
          const newCurrentDiagramResponse = yield call(DiagramsService.getDiagram, {
            diagramId: currentDiagram.id,
          });
          if (newCurrentDiagramResponse.status === 200) {
            yield put(
              currentDiagramAnVersionsActions.changeCurrentDiagramVersions({
                diagram: newCurrentDiagramResponse.data,
              })
            );
          } else {
            NotificationService.push({
              type: 'error',
              message: newCurrentDiagramResponse.data,
            });
          }
        } else {
          const newCurrentDiagramResponse = yield call(DiagramsService.getDiagram, {
            diagramId: currentDiagram.id,
          });
          if (newCurrentDiagramResponse.status === 200) {
            yield put(
              currentDiagramAnVersionsActions.changeCurrentDiagramVersions({
                diagram: newCurrentDiagramResponse.data,
              })
            );
          } else {
            NotificationService.push({
              type: 'error',
              message: newCurrentDiagramResponse.data,
            });
          }
        }
      } else {
        NotificationService.push({
          type: 'error',
          message: response.data,
        });
      }
    } else {
      let givenDiagram;
      if (action.forDiagram === 'expanded') {
        givenDiagram = yield select(getExpandedDiagram);
      } else {
        givenDiagram = yield select(getCurrentDiagram);
      }
      yield put(
        modalsActions.openModal(
          'confirmLive',
          action.shouldChangeToLive
            ? diagramVersionsActions.makeDiagramVersionLive
            : diagramVersionsActions.unmakeDiagramVersionLive,
          {
            versionId: action.versionId,
            forDiagram: action.forDiagram,
            shouldProceed: true,
          },
          {
            shouldChangeToLive: action.shouldChangeToLive,
            versionId: action.versionId,
            diagram: givenDiagram,
          }
        )
      );
    }
  } catch (error) {
    NotificationService.push({
      type: 'error',
    });
  }
}

function* changeDiagramVersionsLiveStatusRequested() {
  yield takeLatest('CHANGE_DIAGRAM_VERSION_LIVE_STATUS', changeDiagramVersionsLiveStatusFlow);
}

function* saveDiagramVersionApprovedStateFlow(action) {
  try {
    const currentDiagram = yield select(getCurrentDiagram);
    const labels = yield select(adminLabels);
    const labelExists = labels.find(label => label.name === currentDiagram?.lastVersion?.label);
    let reviewDueDate = moment();
    if (labelExists) {
      reviewDueDate =
        labelExists.interval === 'weekly'
          ? reviewDueDate.add('1', 'weeks')
          : labelExists.interval === 'monthly'
          ? reviewDueDate.add('1', 'months')
          : labelExists.interval === 'quarterly'
          ? reviewDueDate.add('4', 'months')
          : reviewDueDate.add('1', 'years');
    }
    const response = yield call(DiagramVersionsService.changeDiagramVersionApproveStatus, {
      versionId: action.versionId,
      approveStatus: action.isApproved,
      createReview: labelExists !== null,
      reviewDueDate,
      versionLabel: currentDiagram.name,
    });
    if (response.status === 200) {
      yield put(currentDiagramAnVersionsActions.updateCurrentDiagramVersionApprovedState(action.isApproved));
    } else {
      NotificationService.push({
        type: 'error',
      });
    }
  } catch (error) {
    NotificationService.push({
      type: 'error',
    });
  }
}

function* watchSaveDiagramVersionApprovedStateRequested() {
  yield takeLatest('SAVE_CURRENT_DIAGRAM_VERSION_APPROVED_STATE', saveDiagramVersionApprovedStateFlow);
}

function* saveOnlyDiagramVersionFileFlow(action) {
  try {
    const currentDiagramVersion = yield select(getCurrentDiagramVersion);

    const response = yield call(DiagramVersionsService.changeOnlyDiagramVersionFile, {
      versionId: currentDiagramVersion.id,
      file: action.file,
    });
    if (response.status === 200) {
    } else {
      console.log('ERROR: ', error);
      NotificationService.push({
        type: 'error',
      });
    }
  } catch (error) {
    console.log('ERROR: ', error);
    NotificationService.push({
      type: 'error',
    });
  }
}

function* watchSaveOnlyDiagramVersionFileRequested() {
  yield takeLatest('SAVE_ONLY_DIAGRAM_VERSION_FILE', saveOnlyDiagramVersionFileFlow);
}

export default function* diagramVersionsSaga() {
  yield all([
    changeDiagramVersionsStatusRequested(),
    deleteDiagramVersionsRequested(),
    watchSaveDiagramVersionApprovedStateRequested(),
    watchSaveOnlyDiagramVersionFileRequested(),
  ]);
}
