import crud from './common/CrudService';
import responseService from './common/ResponseService';

const API_BASE_URL = process.env.API_URL || 'http://127.0.0.1:8000';
const API_PREFIX = process.env.API_PREFIX || 'api/v1';
const usersCRUD = crud(API_BASE_URL, API_PREFIX, ['users/']);

const UsersService = {
  getUsersList: searchString => {
    console.log('PROCESSED');
    return responseService.handle(usersCRUD.list({ q: searchString }));
  },

  createUser: user => {
    return responseService.handle(usersCRUD.create(user));
  },

  updateUser: user => {
    return responseService.handle(usersCRUD.update(user.id, user));
  },

  deleteUser: id => {
    return responseService.handle(usersCRUD.delete(id));
  },
};

export default UsersService;
