import React, { useEffect, Suspense } from 'react';
import labelsActions from '../../action-creators/labelsActions';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import ChatApp from '../../../chat-app/chat-app';
import withoutChatDraft from '../utils/chat-draft/withoutChatDraft';
import { withRoles } from '../utils/roles/withRoles';
const SEGMENTS = JSON.parse(process.env.SEGMENTS || '["Anonymous", "Logged-in"]');

const Chat = ({ mode, fetchLabelsList, diagrams, loggedInUser, labelsFromOverview }) => {
  useEffect(() => {
    fetchLabelsList(mode === 'draft' ? 'test' : 'live');
  }, []);
  return (
    <div id="chat-app-container">
      <ChatApp
        mode={mode}
        labels={diagrams}
        loggedInUser={loggedInUser}
        segments={SEGMENTS}
        labelsFromOverview={labelsFromOverview}
      />
    </div>
  );
};

export const mapStateToProps = state => {
  return {
    diagrams: state.labels.diagrams,
    loggedInUser: state.auth.user,
    labelsFromOverview: state.labels.labels,
  };
};

const mapDispatchToProps = {
  fetchSFMList: labelsActions.fetchSFMList,
  fetchLabelsList: labelsActions.fetchLabelsList,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  withRoles(
    ['customer_service_representative', 'labeler', 'product_manager', 'admin'],
    withoutChatDraft(withRouter(Chat))
  )
);
