import {
  append as svgAppend,
  attr as svgAttr,
  create as svgCreate,
  innerSVG as svgInnerAppend,
  remove as svgRemove
} from "tiny-svg";

function svg_textMultiline(id, maxWidth, zoom) {
  var x = 10;
  var y = 0;
  var dy = 15;

  /* get the text */
  var element = document.getElementById(id);

  var text = element.innerHTML;

  var line = '';

  var constructedText = '';

  for (var n = 0; n < text.length; n++) {
    //
    element.innerHTML = '<tspan id="PROCESSING">busy</tspan >';

    var newTextLine = line + text[n];

    const newTextElem = document.getElementById('PROCESSING');

    newTextElem.innerHTML = newTextLine;
    var metrics = newTextElem.getBoundingClientRect();
    var newTextWidth = metrics.width;

    //

    if (newTextWidth > maxWidth - 2 * x * (zoom ? zoom : 1)) {
      if ((constructedText.match(/<tspan/g) || []).length > 0) {
        constructedText += '<tspan x="' + x + '" dy="' + dy + '">' + line + '</tspan>';
      } else {
        constructedText += '<tspan x="' + x + '" dy="' + y + '">' + line + '</tspan>';
      }
      line = text[n];
    } else {
      line = newTextLine;
    }

    document.getElementById('PROCESSING').remove();
  }
  if ((constructedText.match(/<tspan/g) || []).length > 0) {
    constructedText += '<tspan x="' + x + '" dy="' + dy + '">' + line + '</tspan>';
  } else {
    constructedText += '<tspan x="' + x + '" dy="' + y + '">' + line + '</tspan>';
  }

  element.innerHTML = constructedText;

  //
}

function textNode(text) {
  return document.createTextNode(text);
}

/**
 * Creates unique id based on the bpmn element id
 * @param element
 * @return {string}
 */
function getElementId(element) {
  return `${element.id}${Math.floor(Math.random() * 10000)}`;
}

/**
 * Creates label for field
 * @param {string} label text for the label
 * @param {number} y value of y coordinate for the label svg
 * @return {SVGTextElement} label svg element
 */
function drawLabel(label, y) {
  const newLabelText = svgCreate('text');
  svgAttr(newLabelText, {
    id: `newLabelText`,
    class: 'djs-label-small',
    x: 10,
    y,
  });
  newLabelText.appendChild(textNode(label));
  return newLabelText;
}

/**
 * Creates text svg element that represents the value of the task field
 * @param {string} text value
 * @param {number} y y coordinate of the svg element
 * @param {text} id id of the svg element
 * @return {SVGTextElement} value svg element
 */
function drawText(text, y, id) {
  const newText = svgCreate('text');
  svgAttr(newText, {
    id,
    class: 'djs-label',
    x: 10,
    y: y,
  });
  newText.appendChild(textNode(text));
  return newText;
}

/**
 * Calculates zoom as ratio of real width and set width
 * @param {SVGElement} shapeProcessing
 * @param {number} elementWidth
 * @return {number}
 */
function calcZoom(shapeProcessing, elementWidth) {
  let zoom;
  if (shapeProcessing.getBoundingClientRect().width) {
    zoom = (shapeProcessing.getBoundingClientRect().width / elementWidth).toFixed(4);
  } else {
    zoom = 1;
  }
  return zoom;
}

/**
 * Calculates rect of the svg element identified by given id
 * @param {string} id id of svg element
 * @param {number} lastActualWidth width of the task element
 * @param {number} zoom zoom level
 * @return {{height: number}}
 */
function getClientRect(id, lastActualWidth, zoom) {
  let newClientRect;
  try {
    svg_textMultiline(id, lastActualWidth, zoom);
    newClientRect = document.getElementById(id).getBoundingClientRect();
  } catch (e) {
    newClientRect = {height: 15}
  }
  return newClientRect;
}

/**
 * Calculates sum of height of given rects if height is 0 uses Y_LABEL_STEP
 * @param {[{height: number}]} rects
 * @return {number}
 */
function heightSum(rects) {
  return rects.reduce((acc, curr) => acc + (curr.height || Y_LABEL_STEP), 0);
}

/**
 * Calculates full height of task element
 * @param {[{height: number}]} rects all svg element rects inside the task element
 * @param {number} cutoff if calculated height is too small use this number instead
 * @param {number} [zoom=1] zoom level
 * @return {number}
 */
function calcNewHeight(rects, cutoff, zoom = 1) {
  const rectZoomHeight = (rects.length + 1) * 20;
  const rectHeight = heightSum(rects) + rectZoomHeight * zoom;
  const cutoffHeight = cutoff * zoom;
  const newHeight = Math.round(Math.max(rectHeight, cutoffHeight));
  return zoom === 1 ? newHeight : newHeight * (1 / zoom);
}

// copied from https://github.com/bpmn-io/bpmn-js/blob/master/lib/draw/BpmnRenderer.js
function drawIcon(parentNode, {content, width, height}) {
  const g = svgCreate('g');

  svgAttr(g, {
    transform: 'translate(-11, -11)',
    fill: 'white',
  });

  const rect = `<rect width="${width}" height="${height}" fill="white"></rect>`;

  svgInnerAppend(g, rect + content);

  svgAppend(parentNode, g);

  return g;
}

const Y_LABEL_STEP = 15;
const Y_FIELD_STEP = 5;
const COLOR_BOX_NODE_STEP = 2;
const COLOR_BOX_NODE_OFFSET = 3;


/**
 * @typedef IconConfig
 * @type object
 * @property {string} content - string that represents icon svg
 * @property {number} width - icon width
 * @property {number} height - icon height
 */
/**
 * @typedef Config - data needed to render the task element
 * @type object
 * @property {number} height - cutoff height, minimum height of the element
 * @property {string} color - color of the outline of the task element
 * @property {IconConfig} icon - icon in the top left corner of the task element
 */

/**
 * @typedef Field - represents field of the task element
 * @type object
 * @property {string} label - label of the field
 * @property {string} valueKey - key of the value in element.businessObject (value = element.businessObject[valueKey])
 * @property {string} valueId - part of the id of the svg element that renders value text
 * @property {string} defaultValue - if no value is present at the element.businessObject[valueKey] this value will be
 * used
 */
/**
 * Creates render function for drawing tasks.
 * Rendering is done along the y-axis from top to bottom. This function keeps track of the current y value as well as
 * holding and array of the svg element rects for all the drawn fields. All of this is needed to ensure each field is
 * drawn at the correct height and that the element itself adjusts its height to its fields.
 * @param parentNode
 * @param element
 * @param bpmnRenderer
 * @return {function(Config, [Field]): {shape: *, zoom: *}} - render function for this task
 */
export function Renderer(parentNode, element, bpmnRenderer) {
  let y = 20;
  let zoom;
  let width = element.width;

  const shapeProcessing = bpmnRenderer.drawShape(parentNode, element);
  const lastActualWidth = shapeProcessing.getBoundingClientRect().width;
  const id = getElementId(element);
  const rects = [];

  function getTextId(valueId) {
    return `${valueId}For${id}`;
  }

  function incLabel() {
    y += Y_LABEL_STEP;
  }

  function incField() {
    y += Y_FIELD_STEP;
  }

  function calcHeight(cutoff) {
    return calcNewHeight(rects, cutoff, zoom);
  }

  function calcOffset() {
    return (heightSum(rects) || (rects.length * Y_LABEL_STEP)) * (1 / (zoom || 1));
  }

  /**
   * Creates a single field (label text and value text elements).
   * Fields are drawn from top to bottom by the order they are passed to render function.
   * @param {string} label - label of the field
   * @param {string} valueKey - key of the value in element.businessObject (value = element.businessObject[valueKey])
   * @param {string} valueId - part of the id of the svg element that renders value text
   * @param {string} defaultValue - if no value is present at the element.businessObject[valueKey] this value will be
   */
  function drawField({label, valueKey, defaultValue, valueId}) {
    const textId = getTextId(valueId);
    const offset = calcOffset();
    parentNode.append(drawLabel(label, y + offset));
    incLabel();
    parentNode.append(drawText(element.businessObject[valueKey] || defaultValue, y + offset, getTextId(valueId)));
    incField();
    if (!zoom) {
      zoom = calcZoom(shapeProcessing, width);
    }
    rects.push(getClientRect(textId, lastActualWidth, zoom));
  }

  /**
   * Adjusts height of the task element, applies color of the outline and draws icon in the top left corner.
   * @param {number} cutoffHeight - minimal height of the element
   * @param {string} color - color of the element outline / border
   * @param {IconConfig} iconProps - config needed to draw icon
   * @return {{shape: *, zoom}} - shape from bpmnRenderer and zoom level
   */
  function close(cutoffHeight, color, iconProps) {
    const oldHeight = element.height;
    const newHeight = calcHeight(cutoffHeight)
    element.width = width;
    element.height = newHeight
    element.y = element.y - (newHeight / 2 - oldHeight / 2);

    const shape = bpmnRenderer.drawShape(parentNode, element);
    const colorBoxNodeIndex = COLOR_BOX_NODE_OFFSET + (COLOR_BOX_NODE_STEP * rects.length);
    svgAttr(parentNode.childNodes[colorBoxNodeIndex], {
      fillOpacity: 0,
      strokeWidth: 3,
      stroke: color,
      class: 'djs-rect',
    });

    svgRemove(parentNode.childNodes[0]);

    drawIcon(parentNode, iconProps);

    return {shape, zoom};
  }

  /**
   * Function to render task element based on config and fields.
   * Fields will be drawn vertically from top to bottom, each field is a label and value pair.
   * Applies color from config to the element border and draws the icon in the top left corner.
   *
   * @param {Config} config - data needed to draw the task element (min height, icon, border color)
   * @param {[Field]} fields - element fields that we want to show on the task element
   * @return {{shape: *, zoom}} - bpmnRenderer shape to be returned from the drawShape function and zoom level to apply
   * to currentZoomLevel of the calling class
   */
  function render(config, fields) {
    fields.forEach(f => drawField(f));
    return close(config.height, config.color, config.icon);
  }

  return render;
}
