import React from 'react';
import { connect } from 'react-redux';

import saveProgressActions from '../../action-creators/saveProgressActions';
import SaveProgressOverlay from '../../components/save-progress-overlay/SaveProgressOverlay';

const SaveProgressOverlayContainer = ({ saveProgress, updateSaveProgress }) => {
  return <SaveProgressOverlay {...saveProgress} updateSaveProgress={updateSaveProgress} />;
};

const mapStateToProps = state => {
  return {
    saveProgress: state.saveProgress,
  };
};

const mapDispatchToProps = {
  ...saveProgressActions,
};

export default connect(mapStateToProps, mapDispatchToProps)(SaveProgressOverlayContainer);
