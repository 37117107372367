import React from 'react';
import styled from 'styled-components';
import * as mixins from '../../styles/mixins';
import * as colors from '../../styles/colors';
import * as typography from '../../styles/typography';

import FlexibleWrapper from '../shared/FlexibleWrapper';
import ElementPropertyContainer from '../../containers/shared/ElementPropertyContainer';
import EndEventIcon from '../shared/icons/tools/EndEventIcon';


const ENABLE_HEADER = process.env.ENABLE_HEADER || 'true';

// If ENABLE_HEADER is not 'true', then Elisa's instance. Need to unify later
const PanelWrapper = styled.div`
  display: block;
  margin-right: 8px;
  min-width: 48px;
  visibility: hidden;

  ${props => (
    (props.isExpanded && ENABLE_HEADER !== 'true')
      ? `
        width: 400px;
        visibility: visible;
        `
      : ''
  )}
`;

const PanelHeader = styled(FlexibleWrapper)`
  width: 100%;
  cursor: pointer;
  ${mixins.flex('flex-start')};
`;

const PanelHeaderTitle = styled.p`
  margin-left: 11px;
  color: ${colors.mainFontColor};
  ${mixins.fontStyle(typography.textFontSize, typography.semibold)};
`;

const PanelProperties = styled(FlexibleWrapper)`
  flex-direction: column;
  box-sizing: border-box;
  padding-bottom: 4px;
  padding-top: 4px;
`;

const ErrorDescription = styled.p`
  margin-left: 11px;
  color: ${colors.mainFontColor};
  ${mixins.fontStyle(typography.textFontSize)};
`;

const EventPropertiesPanel = ({
  modeler,
  isExpanded,
  reviewMode,
  currentEvent,
  currentBusinessObject,
  updateCurrentEvent,
}) => {
  const modeling = modeler.get('modeling');
  const moddle = modeler.get('moddle');

  // const extensionElements = currentBusinessObject && currentBusinessObject.extensionElements ? currentBusinessObject.extensionElements.values : [];
  const eventIdData = {
    type: 'id',
    label: 'End event id',
    eidtable: false,
  };
  const eventCheckboxData = {
    key: 'disableEndQuestion',
    type: 'checkbox',
    label: 'Disable "Did you get answer?" question?',
    editable: true,
  };
  return (
    <PanelWrapper isExpanded={isExpanded}>
      <PanelHeader>
        <EndEventIcon color={colors.mainIconColor} />
        {isExpanded ? <PanelHeaderTitle>Event properties</PanelHeaderTitle> : ''}
      </PanelHeader>
      {isExpanded ? (
        <PanelProperties>
          {currentEvent.businessObject.eventDefinitions && currentEvent.businessObject.eventDefinitions.length > 0 ? (
            <ErrorDescription>Error end events does not have properties</ErrorDescription>
          ) : (
            <>
              <ElementPropertyContainer
                modeling={modeling}
                moddle={moddle}
                currentElement={currentEvent}
                index="0"
                value={currentBusinessObject['id']}
                {...eventIdData}
                elementPropKey="id"
              />
              <ElementPropertyContainer
                modeling={modeling}
                moddle={moddle}
                currentElement={currentEvent}
                index="0"
                value={currentBusinessObject.disableEndQuestion}
                {...eventCheckboxData}
                elementPropKey="disableEndQuestion"
              />
            </>
          )}
        </PanelProperties>
      ) : (
        ''
      )}
    </PanelWrapper>
  );
};

export default EventPropertiesPanel;
