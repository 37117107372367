import { ConfigProvider, Empty, Select } from 'antd';
import JSON5 from 'json5';
import ModelerService from '../../../../services/ModelerService';
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { ElementPropInput } from './utils/CommonElements';
import { isJson } from './utils/CommonFunctions';
import Options from './Options';

const ChoicesInputWrapper = styled.div`
  position: relative;
  ${props => (props.optionsVisible ? `top: -10px;` : '')};
`;

const customizeRenderEmpty = () => (
  <div style={{ textAlign: 'center', background: '#E5E5E5' }}>
    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={'Add Choices'} />
  </div>
);

export const ChoicesWithOptions = ({
  currentValue,
  changeCurrentValue,
  elementPropKey,
  options,
  canvas,
  modeling,
  currentElement,
  isBlocked,
}) => {
  const isPureSerializable = value => {
    return isJson(value) && Array.isArray(JSON5.parse(value));
  };
  const isSerializable = value => {
    if (!value) {
      return true;
    }
    return isPureSerializable(preprocssValue(value));
  };

  const getSerializedValue = value => {
    try {
      if (!isPureSerializable(value)) throw new Error('invalid value');
      return JSON5.parse(value);
    } catch (err) {
      return [];
    }
  };

  const preprocssValue = value => {
    if (!value) {
      return '[]';
    }
    const preprocessed = Array.from(value.matchAll(/gettext\((["'])(?<str>.*?)\1\)/g), match => ({
      from: match[0],
      to: match[1] + match.groups.str + match[1],
    })).reduce((acc, replaceVal) => acc.replace(replaceVal.from, replaceVal.to), value);

    console.log(preprocessed);
    return preprocessed;
  };

  const [currentOption, setCurrentOption] = useState(
    options.length === 1 ? options[0].value : isPureSerializable(currentValue) ? 'simple' : 'variable'
  );

  useEffect(() => {
    if (
      !(isJson(currentValue) && Array.isArray(JSON5.parse(currentValue))) &&
      options.find(option => option.value === 'variable')
    ) {
      setCurrentOption('variable');
    }
  }, [currentValue]);

  return (
    <>
      {options.length > 1 && (
        <Options
          options={options.map(option => {
            return option.value === 'simple'
              ? {
                  ...option,
                  disabled: !isSerializable(currentValue),
                }
              : option;
          })}
          value={currentOption}
          onChange={() => {
            if (currentOption === 'variable' && isSerializable(currentValue)) {
              if (!isPureSerializable(currentValue)) {
                const value = preprocssValue(currentValue);
                changeCurrentValue(value);
                modeling.updateProperties(currentElement, {
                  [elementPropKey]: value,
                });
              }
              setCurrentOption('simple');
            } else {
              setCurrentOption('variable');
            }
          }}
        />
      )}
      <ChoicesInputWrapper optionsVisible={options.length > 1}>
        {currentOption === 'simple' ? (
          <ConfigProvider renderEmpty={customizeRenderEmpty}>
            <Select
              open={false}
              mode="tags"
              allowClear
              style={{ width: '100%', background: '#E5E5E5' }}
              value={getSerializedValue(currentValue)}
              onChange={value => {
                if (elementPropKey === 'choices')
                  ModelerService.createMessageNodesForInput(currentElement, modeling, canvas, value, currentValue);
                changeCurrentValue(JSON.stringify(value));
                modeling.updateProperties(currentElement, {
                  [elementPropKey]: JSON.stringify(value),
                });
              }}
            />
          </ConfigProvider>
        ) : (
          <ElementPropInput
            className="element-property-input"
            value={currentValue || ''}
            disabled={isBlocked}
            onChange={e => {
              changeCurrentValue(e.target.value);
            }}
            onBlur={e => {
              modeling.updateProperties(currentElement, {
                [elementPropKey]: currentValue,
              });
            }}
          />
        )}
      </ChoicesInputWrapper>
    </>
  );
};
