import React from 'react';

import './common.scss';
import { Select } from 'antd';

const TagsSelect = ({ label, placeholder, value, onChange, options }) => {
  const { Option } = Select;
  return (
    <div dir="auto" className="input-container">
      <p className="input-label">{label}</p>
      <Select
        className="select"
        mode="tags"
        size="large"
        style={{ width: '100%', background: '#E5E5E5' }}
        allowClear
        value={value}
        onChange={e => onChange({ target: { value: e } })}
      >
        {options.map(option => (
          <Option value={option}>{option}</Option>
        ))}
      </Select>
    </div>
  );
};

export default TagsSelect;
