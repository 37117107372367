import React from 'react';
import * as colors from '../../styles/colors';

import {
  ModalWrapper,
  ModalContent,
  ModalIconContent,
  ModalTitle,
  ModalParagraph,
  ModalButtons,
  CancelButton,
  ConfirmButton,
} from './modals-elements/ModalsElements';
import RadioIcon from '../shared/icons/tools/RadioIcon';

const ConfirmLiveModal = ({
  modalKey,
  closeModal,
  proceedOnModalConfirm,
  actionOnConfirm,
  argsOnConfirm,
  extraModalArgs,
}) => {
  const { versionId, diagram } = extraModalArgs;

  return (
    <ModalWrapper>
      <ModalContent>
        <ModalIconContent>
          <RadioIcon color={colors.alternativeIconColor} />
        </ModalIconContent>
        <ModalTitle>Confirmation</ModalTitle>

        <ModalParagraph>
          {['test', 'live'].includes(argsOnConfirm?.statusAction) ? 'Setting' : 'Removing'} version
          <span className="bold">{versionId}</span>
          of solution flow
          <span className="bold">{diagram.name}</span> as{' '}
          {argsOnConfirm?.statusAction?.includes('live') ? 'LIVE' : 'TEST'}. Do you want to continue?
        </ModalParagraph>

        <ModalButtons>
          <CancelButton onClick={() => closeModal(modalKey)}>Cancel</CancelButton>
          <ConfirmButton onClick={() => proceedOnModalConfirm(actionOnConfirm, argsOnConfirm, modalKey)}>
            Continue
          </ConfirmButton>
        </ModalButtons>
      </ModalContent>
    </ModalWrapper>
  );
};

export default ConfirmLiveModal;
