import styled from "styled-components";
import * as colors from "../../styles/colors";
import * as mixins from "../../styles/mixins";
import * as typography from "../../styles/typography";
import FlexibleWrapper from "./FlexibleWrapper";

export const SaveTooltip = styled.div`
  visibility: hidden;
  position: absolute;
  top: 12px;
  left: 50px;
  z-index: 1000;
  height: 24px;
  width: 300px;
`;

export const SaveTooltipContent = styled.p`
  display: inline-block;
  box-sizing: border-box;
  padding: 0 8px;
  background-color: ${colors.mainFontColor};
  color: ${colors.white};
  ${mixins.fontStyle(typography.labelFontSize, typography.medium)};
`;

export const SaveButtonWrapper = styled(FlexibleWrapper)`
  position: relative;
  cursor: pointer;

  &:hover > ${SaveTooltip} {
    visibility: visible;
    ${mixins.transition(['opacity 0.2s ease-in 0.5s'])};
  }
`;
