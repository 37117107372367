import React from "react"
import {ConfirmButton} from "../modals/modals-elements/ModalsElements";
import styled from "styled-components";

export const Audio = styled.audio`
  max-width: 100%;
`

const AudioPlayer = ({promptUrl, handleSynthesize}) => {
  return (
    <>
      <Audio controls src={promptUrl} />
      {handleSynthesize && <ConfirmButton onClick={handleSynthesize}>Synthesize</ConfirmButton>}
    </>
  )
}

export default AudioPlayer;
