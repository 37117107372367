import React from "react";
import {Switch} from 'antd'

export const CheckboxSwitch = ({
  currentValue, changeCurrentValue, isBlocked, modeling, currentElement, elementPropKey
}) => {
  return (
    <Switch
      style={{marginRight: 5}}
      checked={JSON.parse(currentValue || '{}').selected}
      disabled={isBlocked}
      onClick={e => {
        modeling.updateProperties(currentElement, {
          [elementPropKey]: JSON.stringify({
            selected: e,
            value: JSON.parse(currentValue|| '{}').value
          })
        });
        changeCurrentValue(e);
      }}
    />
  );
}