import { combineReducers } from 'redux';
import appReducer from './appReducer';
import visibilityReducer from './visibilityReducer';
import modalsReducer from './modalsReducer';
import saveProgressReducer from './saveProgressReducer';
import usersReducer from './usersReducer';
import callbotReducer from "./callbotReducer";

const rootReducer = combineReducers({
  app: appReducer,
  users: usersReducer,
  visibility: visibilityReducer,
  modals: modalsReducer,
  saveProgress: saveProgressReducer,
  callbot: callbotReducer,
});

export default rootReducer;
