import React from 'react';
import LanguageCell from './LanguageCell';
import { DEFAULT_SFM_LANGUAGE, SUPPORTED_LANGUAGES } from '../../../app/components/settings/Settings';
import { Audio } from '../audio/AudioPlayer';

const LanguageRow = ({
  nodeId,
  elementRegistry,
  nodesTranslationData,
  handleSrcLanguageMouseMove,
  deleteTranslation,
  onTranslationSelected,
  onTranslationChanged,
  onTranslationReview,
  onTranslationsValuesChange,
  handleSynthesize,
  diagramType,
  title,
}) => {
  if (!nodesTranslationData[nodeId]?.srcString) {
    return null;
  }

  const noPlayer =
    nodeId === 'label_name_translations' ||
    nodeId === 'label_description_translations' ||
    nodeId.includes('choices') ||
    nodeId.includes('scripts') ||
    nodeId.includes('retry');

  return (
    <div className="languages-row row-flex equal-flex">
      <div className="languages-col src-language p-b-20" onMouseMove={ev => handleSrcLanguageMouseMove(ev)}>
        <div style={{ width: '100%' }} dir="auto">
          <div className="word-break line-break">
            {nodesTranslationData[nodeId].srcString}
            <span className="src-language-tooltip">Editing source language is disabled</span>
          </div>
          {diagramType === 'callbot' && <div>{nodesTranslationData[nodeId].values}</div>}
          {diagramType === 'callbot' && !noPlayer && (
            <div>
              <Audio controls src={elementRegistry.get(nodeId).businessObject.promptUrl} />
            </div>
          )}
        </div>
        <div className="column-flex node-id word-break">
          <div>{title || nodeId}</div>
          <button style={{ padding: 0 }} className="attention-ok" onClick={() => deleteTranslation(nodeId)}>
            delete
          </button>
        </div>
      </div>
      {SUPPORTED_LANGUAGES.filter(lang => lang !== DEFAULT_SFM_LANGUAGE).map(lang => (
        <div
          key={lang}
          onClick={() => onTranslationSelected(nodeId, lang)}
          className={`cursor-pointer-class languages-col ${
            nodesTranslationData[nodeId].translations?.[lang]?.selected
              ? 'languages-col-selected'
              : nodesTranslationData[nodeId].translations?.[lang]?.reviewed === false &&
                nodesTranslationData[nodeId].translations?.[lang]?.text
              ? 'languages-col-attention'
              : ''
          }`}
        >
          <LanguageCell
            onTranslationChanged={text => onTranslationChanged(nodeId, lang, text)}
            onTranslationReview={e => onTranslationReview(e, nodeId, lang)}
            handleSynthesize={() => handleSynthesize(nodeId, lang)}
            node={nodesTranslationData[nodeId].translations?.[lang] || {}}
            diagramType={diagramType}
            srcStringModifiedAt={nodesTranslationData[nodeId].srcStringModifiedAt}
            noPlayer={noPlayer}
            onTranslationValuesChange={values => onTranslationsValuesChange(nodeId, lang, values)}
          />
        </div>
      ))}
    </div>
  );
};

export default LanguageRow;
