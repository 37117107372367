import BaseRenderer from 'diagram-js/lib/draw/BaseRenderer';

import {
  append as svgAppend,
  attr as svgAttr,
  classes as svgClasses,
  create as svgCreate,
  remove as svgRemove,
  innerSVG as svgInnerAppend,
} from 'tiny-svg';

import { getRoundRectPath } from 'bpmn-js/lib/draw/BpmnRenderUtil';

import { is } from 'bpmn-js/lib/util/ModelUtil';
import { isAny } from 'bpmn-js/lib/features/modeling/util/ModelingUtil';

import modeling from 'bpmn-js/lib/features/modeling';
import appReducer from '../../../reducers/appReducer';

const HIGH_PRIORITY = 1500;

export default class InputTaskRenderer extends BaseRenderer {
  constructor(eventBus, bpmnRenderer) {
    super(eventBus, HIGH_PRIORITY);

    this.bpmnRenderer = bpmnRenderer;
    // this.modeling = bpmnRenderer.get('modeling');
    this.elementWidth = 160;
    this.currentZoomLevel = 0;
  }

  canRender(element) {
    // console.log('ELEM in receive: ', element);
    return is(element, 'bpmn:ReceiveTask') && element.businessObject.subType === 'input-task';
  }

  svg_textMultiline(id, maxWidth, zoom) {
    var x = 10;
    var y = 0;
    var dy = 15;

    /* get the text */
    var element = document.getElementById(id);

    var text = element.innerHTML;

    var words = text.split(' ');
    var line = '';

    var constructedText = '';
    let linesNumber = 0;

    for (var n = 0; n < text.length; n++) {
      element.innerHTML = '<tspan id="PROCESSING">busy</tspan >';

      var newTextLine = line + text[n];

      const newTextElem = document.getElementById('PROCESSING');

      newTextElem.innerHTML = newTextLine;
      var metrics = newTextElem.getBoundingClientRect();
      var newTextWidth = metrics.width;

      if (newTextWidth > maxWidth - 2 * x * (zoom ? zoom : 1)) {
        linesNumber += 1;
        if ((constructedText.match(/<tspan/g) || []).length > 0) {
          constructedText += '<tspan x="' + x + '" dy="' + dy + '">' + line + '</tspan>';
        } else {
          constructedText += '<tspan x="' + x + '" dy="' + y + '">' + line + '</tspan>';
        }
        line = text[n];
      } else {
        line = newTextLine;
      }

      document.getElementById('PROCESSING').remove();
    }
    linesNumber += 1;
    if ((constructedText.match(/<tspan/g) || []).length > 0) {
      constructedText += '<tspan x="' + x + '" dy="' + dy + '">' + line + '</tspan>';
    } else {
      constructedText += '<tspan x="' + x + '" dy="' + y + '">' + line + '</tspan>';
    }

    element.innerHTML = constructedText;
    return linesNumber;
  }

  // Main function to draw custom shape

  drawCollumnRow(parentNode, previousHeight, zoom, toDraw) {
    if (toDraw.type === 'label') {
      const label = svgCreate('text');
      svgAttr(label, {
        id: toDraw.id,
        class: 'djs-label-small',
        x: 10,
        y: previousHeight,
      });

      const newHeight = previousHeight + 15;

      label.appendChild(document.createTextNode(toDraw.value));

      parentNode.appendChild(label);

      return { newHeight };
    }
    else if (toDraw.type === 'text') {
      const newText = svgCreate('text');
      svgAttr(newText, {
        id: toDraw.id,
        class: 'djs-label',
        x: 10,
        y: previousHeight,
      });
      const newHeight = previousHeight + 20;

      newText.appendChild(document.createTextNode(toDraw.value));

      parentNode.appendChild(newText);

      return { newHeight };
    }
    else if (toDraw.type === 'multiline') {
      this.drawCollumnRow(
        parentNode, previousHeight, zoom, {...toDraw, type: 'text'}
      );
      let linesNumber;
      try {
        linesNumber = this.svg_textMultiline(toDraw.id, this.lastActualWidth, zoom);
      } catch (e) {
        linesNumber = 1;
      }
      return { newHeight: previousHeight + linesNumber * 15 + 5 };
    }
  }

  drawCollumn(parentNode, initialHeight, zoom, toDraws) {
    let newHeight = initialHeight;
    toDraws.forEach((toDraw) => {
      newHeight = this.drawCollumnRow(parentNode, newHeight, zoom, toDraw).newHeight;
    })
    newHeight -= 5;
    return newHeight;
  }

  drawShape(parentNode, element) {
    const parentNodeBounds = parentNode.getBoundingClientRect();
    let element_id = `${element.id}${Math.floor(Math.random() * 10000)}`;

    element.width = 160;

    let shapeProcessing = this.bpmnRenderer.drawShape(parentNode, element);

    this.lastActualWidth = shapeProcessing.getBoundingClientRect().width;

    let zoom;
    if (shapeProcessing.getBoundingClientRect().width) {
      zoom = (shapeProcessing.getBoundingClientRect().width / this.elementWidth).toFixed(4);
    } else {
      zoom = 1;
    }

    const initalHeight = 20;
    const oldHeight = element.height;

    const newHeight = this.drawCollumn(parentNode, initalHeight, zoom, [
      {
        type: 'label',
        value: 'message'
      },
      {
        type: 'multiline',
        value: element.businessObject.template || 'No message',
        id: `InputTemplateFor${element_id}`,
      },
      {
        type: 'label',
        value: 'variable name for input'
      },
      {
        type: 'text',
        value: element.businessObject.identifierForInput || 'No variable name',
        id: `InputTextFor${element_id}`
      },
      {
        type: 'label',
        value: 'choices'
      },
      {
        type: 'multiline',
        value: (
          (element.businessObject.choices && element.businessObject.choices !== '[]') ?
            element.businessObject.choices :
            'No choices'
        ),
        id: `InputChoicesFor${element_id}`
      },
    ]);

    element.width = this.elementWidth;
    element.height = newHeight;
    element.y = element.y - (newHeight / 2 - oldHeight / 2);

    svgAttr(shapeProcessing, {
      height: newHeight,
    });

    let shape = this.bpmnRenderer.drawShape(parentNode, element);

    svgAttr(parentNode.childNodes[parentNode.childNodes.length - 3], {
      fillOpacity: 0,
      strokeWidth: 3,
      stroke: '#1EAAAA',
      class: 'djs-rect',
    });

    svgRemove(parentNode.childNodes[0]);

    drawRect(parentNode);

    this.currentZoomLevel = zoom;

    let overlaysNumber = 0;

    if (element.businessObject.timeout) {
      overlaysNumber += 1;
      drawOverlay(
        parentNode, 'sfm-app/modeler/assets/timer-dark.svg',
        element.width, overlaysNumber - 1,
      );
    }

    if (element.businessObject.eventFilter) {
      overlaysNumber += 1;
      drawOverlay(
        parentNode, 'sfm-app/modeler/assets/event-dark.svg',
        element.width, overlaysNumber - 1,
      );
    }

    return shape;
  }
}

InputTaskRenderer.$inject = ['eventBus', 'bpmnRenderer'];

// helpers //////////

function drawOverlay(parentNode, iconContent, width, overlaysNumber) {
  const g = svgCreate('g');

  svgAttr(g, {
    transform: `translate(${width - 2}, ${ 11 + overlaysNumber * (23 + 5) })`,
    fill: 'white',
  });

  const iconElement = svgCreate('image');
  svgAttr(iconElement, {
    href: iconContent,
    transform: 'translate(-10, 0)',
    fill: 'white',
  });

  svgAppend(g, iconElement);
  svgAppend(parentNode, g);
  return g;
}

// copied from https://github.com/bpmn-io/bpmn-js/blob/master/lib/draw/BpmnRenderer.js
function drawRect(parentNode, width, height, borderRadius, strokeColor) {
  const g = svgCreate('g');

  svgAttr(g, {
    transform: 'translate(-11, -11)',
    fill: 'white',
  });

  const rect = '<rect width="24" height="24" fill="white"></rect>';

  const iconContent =
    '<path d="M19 3H5C3.89543 3 3 3.89543 3 5V19C3 20.1046 3.89543 21 5 21H19C20.1046 21 21 20.1046 21 19V5C21 3.89543 20.1046 3 19 3Z" stroke="#000F3C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/> ' +
    '<path d="M12 8V16" stroke="#000F3C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/> ' +
    '<line x1="7.75" y1="7.25" x2="11.25" y2="7.25" stroke="#000F3C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/> ' +
    '<line x1="12.75" y1="7.25" x2="16.25" y2="7.25" stroke="#000F3C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/> ' +
    '<line x1="7.75" y1="16.75" x2="11.25" y2="16.75" stroke="#000F3C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/> ' +
    '<line x1="12.75" y1="16.75" x2="16.25" y2="16.75" stroke="#000F3C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/> ' +
    '<line x1="9.75" y1="11.75" x2="14.25" y2="11.75" stroke="#000F3C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/> ';

  svgInnerAppend(g, rect + iconContent);

  svgAppend(parentNode, g);

  return g;
}

// copied from https://github.com/bpmn-io/diagram-js/blob/master/lib/core/GraphicsFactory.js
function prependTo(newNode, parentNode, siblingNode) {
  parentNode.insertBefore(newNode, siblingNode || parentNode.firstChild);
}
