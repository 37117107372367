import React from 'react';
import styled from 'styled-components';

import { commonTaskProps } from '../../../../constants/tasks/commonTaskProperties';
import FlexibleWrapper from '../../../shared/FlexibleWrapper';
import ElementPropertyContainer from '../../../../containers/shared/ElementPropertyContainer';

const PanelMainSection = styled(FlexibleWrapper)`
  max-height: calc(100vh - 252px);
  justify-content: unset;
  overflow-y: auto;
  flex-direction: column;
  box-sizing: border-box;
  padding-bottom: 4px;
  padding-top: 4px;
`;

const TaskPropertiesExpandedArea = ({
  modeler,
  reviewMode,
  currentTask,
  currentTaskData,
  currentBusinessObject,
  settings,
  diagramType
}) => {
  const modeling = modeler.get('modeling');
  const moddle = modeler.get('moddle');
  const canvas = modeler.get('canvas');

  const extensionElements = currentBusinessObject.extensionElements
    ? currentBusinessObject.extensionElements.values
    : [];
  if (currentBusinessObject['$type'] === 'bpmn:ReceiveTask' && diagramType === "chatbot") {
    if (currentBusinessObject['retryCount'] === undefined && settings?.default_retry_count) {
      modeling.updateProperties(currentTask, {
        retryCount: settings.default_retry_count.toString(),
      });
    }
    if (currentBusinessObject['retryMessage'] === undefined && settings?.default_retry_message) {
      modeling.updateProperties(currentTask, {
        retryMessage: settings.default_retry_message,
      });
    }
  }

  if (currentBusinessObject['$type'] === 'bpmn:SendTask') {
    if (currentBusinessObject['message_speed'] === undefined && settings?.draft_message_speed) {
      modeling.updateProperties(currentTask, {
        message_speed: JSON.stringify({
          selected: false,
          value: JSON.parse(settings?.live_message_speed || '{}').value
        })
      });
    }
  }

  return (
    <PanelMainSection>
      {commonTaskProps.concat(currentTaskData.props).map((taskProp, index) => {
        if (taskProp.key === 'body' && currentTask.businessObject.method !== "POST") {
          return '';
        }
        if (taskProp.key === 'requestVariable' && currentTask.businessObject.method !== "POST_NEW") {
          return '';
        }
        if (currentTaskData.filterByTaskState && !currentTaskData.filterByTaskState(currentTask.businessObject, taskProp.key)) {
          return '';
        }
        return (
          <ElementPropertyContainer
            key={taskProp.key}
            modeling={modeling}
            moddle={moddle}
            canvas={canvas}
            currentElement={currentTask}
            index={index}
            value={currentBusinessObject[taskProp.key]}
            reviewMode={reviewMode}
            {...taskProp}
            elementPropKey={taskProp.key}
            extensionElements={Object.assign([], extensionElements)}
            templateHeight={currentTask.type === "bpmn:SendTask" ? '40vh' : '124px'}
          />
        );
      })}
    </PanelMainSection>
  );
};

export default TaskPropertiesExpandedArea;
