const updateCurrentTask = (task, businessObject, silently = false) => {
  return {
    type: 'UPDATE_CURRENT_TASK',
    task,
    businessObject,
    silently,
  };
};

const currentTaskActions = {
  updateCurrentTask,
};

export default currentTaskActions;
