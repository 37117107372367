import React from 'react';
import uuid from 'react-uuid';
import styled, { keyframes } from 'styled-components';
import * as mixins from '../../styles/mixins';
import * as colors from '../../styles/colors';
import * as typography from '../../styles/typography';

import VersionControlHeader from './version-control-panel-elements/VersionControlHeader';
import VersionControlFooter from './version-control-panel-elements/VersionControlFooter';
import VersionControlDiagram from './version-control-panel-elements/version-control-panel-diagram/VersionControlDiagram';
import VersionControlButton from '../version-control-button/VersionControlButton';
import CloseIcon from '../shared/icons/tools/CloseIcon';
import CircleLoaderIcon from "../shared/icons/tools/CircleLoaderIcon";
import { extraIconColor } from "../../styles/colors";

const ENABLE_HEADER = process.env.ENABLE_HEADER || 'true';

const PanelModal = styled.section`
  display: ${props => (props.isVisible ? 'flex' : 'none')};
  position: fixed;
  top: 90px;
  bottom: 0;
  left: 300px;
  right: 0;
  width: calc(100vw - 300px);
  height: calc(100vh - 90px);
  z-index: 1000;
  padding-top: ${ENABLE_HEADER === 'true' ? '100px' : '0px'};
  background-color: ${colors.backgroundColorWithOpacity};
`;

const PanelCloseButtons = styled.div`
  width: 100%;
  position: fixed;
  top: ${ENABLE_HEADER === 'true' ? '90px' : '0px'};
  left: 300px;
  width: calc(100vw - 300px);
  z-index: 1200;
  box-sizing: border-box;
  padding: 24px;

  ${mixins.flex('space-between')};
`;

const PanelCloseButton = styled.div`
  cursor: pointer;
`;

const PanelModalWrapper = styled.div`
  ${mixins.mainWrapper('800px')};
`;

const PanelResults = styled.div`
  max-height: 584px;
  border-radius: 2px;
  overflow-y: scroll;
  box-sizing: border-box;
  padding: 8px 0;
  background: ${colors.componentBackgroundColor};
  box-shadow: 0px 16px 40px rgba(0, 51, 167, 0.15);

  &::-webkit-scrollbar {
    width: 6px;
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    width: 6px;
    background-color: ${colors.mediumBlueWithOpacity};
  }
`;

const PanelLoader = styled(PanelResults)`
  height: 584px;
  z-index: 9999;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

const rotate = keyframes`
  0% {  transform: rotate(0deg); }
  100% {  transform: rotate(360deg); }
`;

const IconWrapper = styled.div`
  display: flex;
  animation: 1.5s ${rotate} linear infinite;
`

const PanelNoResultsMessage = styled.p`
  width: 100%;
  height: 30px;
  ${mixins.flex()};
  color: ${colors.extraFontColor};
  ${mixins.fontStyle(typography.textFontSize, typography.normal)};
`;

const VersionControlPanel = ({
  isVisible,
  diagramsList,
  expandedDiagram,
  expandedDiagramLastVersions,
  deleteDiagramVersion,
  changeDiagramVersionStatus,
  isCommentVisible,
  toggleDiagramVersionCommentVisibility,
  changeExpandedDiagram,
  isExpandedDiagramHasMoreVersions,
  loadMoreExpandedDiagramVersions,
  showLessExpandedDiagramVersions,
  createNewCurrentDiagramAndVersion,
  changeCurrentDiagramAndVersion,
  currentDiagramId,
  currentDiagramVersionId,
  toggleExpanded,
  toggleVersionControlVisibility,
  searchString,
  sfLoading
}) => {
  return (
    <PanelModal
      tabIndex={-1}  onKeyDown={(ev) => ev.keyCode === 27 && toggleVersionControlVisibility()}
      id="main-version-panel"
      onClick={e => {
        if (e.target.id === 'main-version-panel') {
          toggleVersionControlVisibility();
        }
      }}
      isVisible={isVisible}
    >
      <PanelCloseButtons>
        <VersionControlButton toggleVersionControlVisibility={toggleVersionControlVisibility} isFixed={true} />
        <PanelCloseButton onClick={toggleVersionControlVisibility}>
          <CloseIcon color={colors.mainIconColor} />
        </PanelCloseButton>
      </PanelCloseButtons>
      <PanelModalWrapper>
        <VersionControlHeader
          isCommentVisible={isCommentVisible}
          toggleDiagramVersionCommentVisibility={toggleDiagramVersionCommentVisibility}
          isSearchVisible={!sfLoading}
        />
        {sfLoading ? <PanelLoader>
          <IconWrapper>
            <CircleLoaderIcon color={extraIconColor} width="64" height="64"/>
          </IconWrapper>
        </PanelLoader> : <PanelResults>
          {diagramsList.length > 0 ? (
              diagramsList.map(diagram => {
                return (
                    <VersionControlDiagram
                        key={uuid()}
                        diagram={diagram}
                        isExpanded={expandedDiagram.id === diagram.id}
                        isCurrent={currentDiagramId === diagram.id}
                        versions={expandedDiagramLastVersions}
                        isExpandedHasMoreVersion={isExpandedDiagramHasMoreVersions}
                        isCommentVisible={isCommentVisible}
                        changeExpandedDiagram={() => changeExpandedDiagram(diagram)}
                        loadMoreExpandedDiagramVersions={loadMoreExpandedDiagramVersions}
                        showLessExpandedDiagramVersions={showLessExpandedDiagramVersions}
                        changeCurrentDiagramAndVersion={changeCurrentDiagramAndVersion}
                        currentDiagramVersionId={currentDiagramVersionId}
                        deleteDiagramVersion={deleteDiagramVersion}
                        changeDiagramVersionStatus={changeDiagramVersionStatus}
                        searchString={searchString}
                    />
                );
              })
          ) : (
            <PanelNoResultsMessage>No results</PanelNoResultsMessage>
          )}
        </PanelResults>}
        <VersionControlFooter createNewCurrentDiagramAndVersion={createNewCurrentDiagramAndVersion} />
      </PanelModalWrapper>
    </PanelModal>
  );
};

export default VersionControlPanel;
