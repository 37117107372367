import { put, call, all, take, takeLatest, spawn } from 'redux-saga/effects';
import NotificationService from '../services/common/NotificationService';
import LabelsService from '../services/LabelsService';
import labelsActions from '../action-creators/labelsActions';
import modalsActions from '../action-creators/modalsActions';
import SFMService from '../services/SFMService';

const ENABLE_HEADER = process.env.ENABLE_HEADER || 'true';

function* labelsListFlow({ descriptionTranslationsVersion }) {
  try {
    const response = yield call(LabelsService.getLabelsList);
    if (response.status === 200) {
      let labels = response.data;
      const sfmResponse = yield call(SFMService.getSFMList, { descriptionTranslationsVersion });
      let modifiedLabels = [];
      if (response.status === 200) {
        const sfmData = sfmResponse.data;
        for (let i = 0; i < labels.length; i++) {
          modifiedLabels.push(
            Object.assign({}, labels[i], {
              sfm: sfmData[labels[i].name.normalize('NFKD')],
            })
          );
        }
      } else {
        modifiedLabels = labels;
      }
      let sfmList = Object.keys(sfmResponse.data).map(k => ({ name: k, sfm: sfmResponse.data[k] }));
      yield put(labelsActions.updateSFMList(sfmList));
      yield put(labelsActions.updateLabelsList(modifiedLabels));
    } else {
      if (ENABLE_HEADER === 'true') {
        NotificationService.push({
          type: 'error',
          message: response.data,
        });
      }
    }
  } catch (error) {
    if (ENABLE_HEADER === 'true') {
      NotificationService.push({
        type: 'error',
      });
    }
  }
}

function* SFMListFlow() {
  try {
    const response = yield call(SFMService.getSFMList);
    if (response.status === 200) {
      let sfmList = Object.keys(response.data).map(k => ({ name: k, sfm: response.data[k] }));
      yield put(labelsActions.updateSFMList(sfmList));
    } else {
      NotificationService.push({
        type: 'error',
        message: response.data,
      });
    }
  } catch (error) {
    NotificationService.push({
      type: 'error',
    });
  }
}

function* watchLabelsListRequested() {
  yield takeLatest('FETCH_LABELS_LIST', labelsListFlow);
}

function* watchSFMListRequested() {
  yield takeLatest('FETCH_SFM_LIST', SFMListFlow);
}

function* createLabelFlow(action) {
  try {
    const response = yield call(LabelsService.createLabel, action);
    if (response.status === 200) {
      yield put(labelsActions.fetchLabelsList());
      // yield put(modalsActions.toggleModal('addLabelModal'));
    } else {
      NotificationService.push({
        type: 'error',
        message: response.data,
      });
    }
  } catch (error) {
    NotificationService.push({
      type: 'error',
    });
  }
}

function* watchCreateLabelRequested() {
  yield takeLatest('CREATE_LABEL', createLabelFlow);
}

function* updateLabelFlow(action) {
  try {
    const response = yield call(LabelsService.updateLabel, action);
    if (response.status === 200) {
      yield put(labelsActions.fetchLabelsList());
      // yield put(modalsActions.toggleModal('editLabelModal'));
    } else {
      NotificationService.push({
        type: 'error',
        message: response.data,
      });
    }
  } catch (error) {
    NotificationService.push({
      type: 'error',
    });
  }
}

function* fetchLabelVersionFlow({ params }) {
  try {
    const sfmResponse = yield call(SFMService.getSFMLabelVersionsList, params);
    yield put(labelsActions.updateLabelVersionsList(sfmResponse?.data?.versions));
  } catch (error) {
    NotificationService.push({
      type: 'error',
    });
  }
}

function* watchUpdateLabelRequested() {
  yield takeLatest('UPDATE_LABEL', updateLabelFlow);
}

function* watchFetchLabelVersions() {
  yield takeLatest('FETCH_LABEL_VERSIONS_LIST', fetchLabelVersionFlow);
}

function* deleteLabelFlow(action) {
  try {
    const response = yield call(LabelsService.deleteLabel, action);
    if (response.status === 200) {
      yield put(labelsActions.fetchLabelsList());
      yield put(modalsActions.toggleModal('deleteLabelModal'));
    } else {
      NotificationService.push({
        type: 'error',
        message: response.data,
      });
    }
  } catch (error) {
    NotificationService.push({
      type: 'error',
    });
  }
}

function* watchDeleteLabelRequested() {
  yield takeLatest('DELETE_LABEL', deleteLabelFlow);
}

export default function* labelsSaga() {
  yield all([
    watchLabelsListRequested(),
    watchCreateLabelRequested(),
    watchUpdateLabelRequested(),
    watchDeleteLabelRequested(),
    watchFetchLabelVersions(),
    watchSFMListRequested(),
  ]);
}
