import BaseRenderer from 'diagram-js/lib/draw/BaseRenderer';

import {
  append as svgAppend,
  attr as svgAttr,
  classes as svgClasses,
  create as svgCreate,
  remove as svgRemove,
  innerSVG as svgInnerAppend,
} from 'tiny-svg';

import { getRoundRectPath } from 'bpmn-js/lib/draw/BpmnRenderUtil';

import { is } from 'bpmn-js/lib/util/ModelUtil';
import { isAny } from 'bpmn-js/lib/features/modeling/util/ModelingUtil';

import modeling from 'bpmn-js/lib/features/modeling';

const HIGH_PRIORITY = 1500;

export default class LogicPythonTaskRenderer extends BaseRenderer {
  constructor(eventBus, bpmnRenderer) {
    super(eventBus, HIGH_PRIORITY);

    this.bpmnRenderer = bpmnRenderer;
    // this.modeling = bpmnRenderer.get('modeling');

    // this.maxHeight = 0;
    this.elementWidth = 160;
    this.currentZoomLevel = 1;
  }

  canRender(element) {
    return is(element, 'bpmn:ScriptTask') && element.businessObject.subType === 'logic-python-task';
  }

  svg_textMultiline(id, maxWidth, zoom) {
    var x = 10;
    var y = 0;
    var dy = 15;

    /* get the text */
    var element = document.getElementById(id);

    var text = element.innerHTML;

    var words = text.split(' ');
    var line = '';

    var constructedText = '';

    for (var n = 0; n < text.length; n++) {
      //
      element.innerHTML = '<tspan id="PROCESSING">busy</tspan >';

      var newTextLine = line + text[n];

      const newTextElem = document.getElementById('PROCESSING');

      newTextElem.innerHTML = newTextLine;
      var metrics = newTextElem.getBoundingClientRect();
      var newTextWidth = metrics.width;

      //

      if (newTextWidth > maxWidth - 2 * x * (zoom ? zoom : 1)) {
        if ((constructedText.match(/<tspan/g) || []).length > 0) {
          constructedText += '<tspan x="' + x + '" dy="' + dy + '">' + line + '</tspan>';
        } else {
          constructedText += '<tspan x="' + x + '" dy="' + y + '">' + line + '</tspan>';
        }
        line = text[n];
      } else {
        line = newTextLine;
      }

      document.getElementById('PROCESSING').remove();
    }
    if ((constructedText.match(/<tspan/g) || []).length > 0) {
      constructedText += '<tspan x="' + x + '" dy="' + dy + '">' + line + '</tspan>';
    } else {
      constructedText += '<tspan x="' + x + '" dy="' + y + '">' + line + '</tspan>';
    }

    element.innerHTML = constructedText;

    //
  }

  // Main function to draw custom shape

  drawShape(parentNode, element) {
    const parentNodeBounds = parentNode.getBoundingClientRect();
    let element_id = `${element.id}${Math.floor(Math.random() * 10000)}`;

    element.width = this.elementWidth;

    let shapeProcessing = this.bpmnRenderer.drawShape(parentNode, element);

    this.lastActualWidth = shapeProcessing.getBoundingClientRect().width;

    const newText = svgCreate('text');
    svgAttr(newText, {
      id: `newTextFor${element_id}`,
      class: 'djs-label',
      x: 10,
      y: 35,
    });

    const nodeText =
      element.businessObject.description ||
      (element.businessObject.identifierForResult || '') +
        (element.businessObject.template.substring(0, 80) || 'empty code block');
    newText.appendChild(document.createTextNode(nodeText || 'no description given'));

    parentNode.appendChild(newText);

    const newLabelText = svgCreate('text');
    svgAttr(newLabelText, {
      id: `newLabelText`,
      class: 'djs-label-small',
      x: 10,
      y: 20,
    });

    newLabelText.appendChild(document.createTextNode('description'));

    parentNode.appendChild(newLabelText);

    let zoom;
    if (shapeProcessing.getBoundingClientRect().width) {
      zoom = (shapeProcessing.getBoundingClientRect().width / this.elementWidth).toFixed(4);
    } else {
      zoom = 1;
    }

    let newClientRect;
    try {
      this.svg_textMultiline(`newTextFor${element_id}`, this.lastActualWidth, zoom);

      newClientRect = document.getElementById(`newTextFor${element_id}`).getBoundingClientRect();
    } catch (err) {
      newClientRect = {height: 15};
    }
    let newHeight = Math.round(
        newClientRect.height + 40 * (zoom ? zoom : 1) > 80 * (zoom ? zoom : 1)
            ? newClientRect.height + 40 * (zoom ? zoom : 1)
            : 80 * (zoom ? zoom : 1)
    );
    let oldHeight = element.height;
    newHeight = zoom === 1 ? newHeight : newHeight * (1 / zoom);

    svgAttr(shapeProcessing, {
      height: newHeight,
    });

    element.width = this.elementWidth;
    element.height = newHeight;

    element.y = element.y - (newHeight / 2 - oldHeight / 2);

    let shape = this.bpmnRenderer.drawShape(parentNode, element);
    svgAttr(parentNode.childNodes[5], {
      fillOpacity: 0,
      strokeWidth: 3,
      stroke: '#FFE600',
      class: 'djs-rect',
    });

    svgRemove(parentNode.childNodes[0]);
    drawRect(parentNode);

    this.currentZoomLevel = zoom;

    return shape;
  }
}

LogicPythonTaskRenderer.$inject = ['eventBus', 'bpmnRenderer'];

// helpers //////////

// copied from https://github.com/bpmn-io/bpmn-js/blob/master/lib/draw/BpmnRenderer.js
function drawRect(parentNode, width, height, borderRadius, strokeColor) {
  const g = svgCreate('g');

  svgAttr(g, {
    transform: 'translate(-11, -11)',
    fill: 'white',
  });

  /*const rect = svgCreate('rect');

    svgAttr(rect, {
      width: width,
      height: height,
      fill: "#fff"
    });*/

  //svgAppend(g, rect);

  const rect = '<rect width="26" height="25" fill="white"></rect>';

  const iconContent =
    '<path d="M12 7.75C12.4142 7.75 12.75 7.41421 12.75 7V6C12.75 5.58579 12.4142 5.25 12 5.25H7.75V5C7.75 3.20507 9.20507 1.75 11 1.75H12C13.7949 1.75 15.25 3.20507 15.25 5V7V7.5C15.25 9.29493 13.7949 10.75 12 10.75H11C8.63185 10.75 6.66846 12.483 6.30888 14.75H6C4.20507 14.75 2.75 13.2949 2.75 11.5V11C2.75 9.20507 4.20507 7.75 6 7.75H12Z" stroke="#000F3C" stroke-width="1.5" stroke-linejoin="round"/>' +
    '<path d="M12 16.25C11.5858 16.25 11.25 16.5858 11.25 17V18C11.25 18.4142 11.5858 18.75 12 18.75H16.25V19C16.25 20.7949 14.7949 22.25 13 22.25H12C10.2051 22.25 8.75 20.7949 8.75 19V17V16.5C8.75 14.7051 10.2051 13.25 12 13.25H13C15.3682 13.25 17.3315 11.517 17.6911 9.25H18C19.7949 9.25 21.25 10.7051 21.25 12.5V13C21.25 14.7949 19.7949 16.25 18 16.25H12Z" stroke="#000F3C" stroke-width="1.5" stroke-linejoin="round"/>';

  svgInnerAppend(g, rect + iconContent);

  svgAppend(parentNode, g);

  return g;
}

// copied from https://github.com/bpmn-io/diagram-js/blob/master/lib/core/GraphicsFactory.js
function prependTo(newNode, parentNode, siblingNode) {
  parentNode.insertBefore(newNode, siblingNode || parentNode.firstChild);
}
