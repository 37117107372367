import React, {useState} from 'react';
import AsyncCombobox from "../async-combobox/AsyncCombobox";
import DiagramService from "../../services/DiagramsService";
import {Input, Select, Button, Tooltip} from "antd";
import styled from "styled-components";
import * as mixins from "../../styles/mixins";
import * as colors from "../../styles/colors";
import ArrowIcon from "../shared/icons/tools/ArrowIcon";
import MapInput from "./MapInput";

const itemToString = (item) => item ? `${item.diagram_name} (${item.label})` : '';

async function fetchItems(query) {
  if (!query) {
    return [];
  }
  const response = await DiagramService.searchDiagrams({text: query, diagram_type: "callbot"});
  if (response.status === 200) {
    return response.data;
  }
  return [];
}

const FieldContainer = styled.div`
  margin-bottom: 10px;
`

const ButtonContainer = styled(FieldContainer)`
  display: flex;
  justify-content: center;
`

const LabelSelectContainer = styled(FieldContainer)`
  display: flex;
  justify-content: space-between;
`;

const StyledCombobox = styled(AsyncCombobox)`
  margin-right: 10px;
`

const SettingsButton = styled(Button)`
  ${props =>
          props.expanded ? `
            & > * {
                -webkit-transform: rotate(180deg);
                transform: rotate(180deg);
                ${mixins.transition(['.1s ease-in'])};
            }
        `
                  : `
            & > * {
                ${mixins.transition(['.1s ease-out'])};
            }
        `}
`

const Container = styled.div`
  padding: 10px 0 0 10px;
`

const EMPTY_STATE_VALUE = {key: "", value: ""};

function isUnique(arr) {
  return new Set(arr).size === arr.length;
}

const CallForm = ({onSubmit}) => {
  const [diagram, setDiagram] = useState();
  const [expanded, setExpanded] = useState(false);
  const [callerNumber, setCallerNumber] = useState('');
  const [language, setLanguage] = useState('estonian');
  const [state, setState] = useState([EMPTY_STATE_VALUE]);

  const handleDiagramChange = (diagram) => {
    setDiagram(diagram);
    if (!expanded) {
      onSubmit({label: diagram.label, language});
    }
  }

  const handleMapChange = (index, value, field) => {
    const newState = [...state];
    newState[index] = {...newState[index], [field]: value};
    if (newState[index].key === "" && newState[index].value === "") {
      newState.splice(index, 1);
    }
    if (newState[newState.length - 1] !== EMPTY_STATE_VALUE) {
      newState.push(EMPTY_STATE_VALUE);
    }
    if (isUnique(newState.map(({key}) => key))) {
      setState(newState);
    }
  }

  const handleSubmit = () => {
    onSubmit({
      callData: callerNumber ? { caller_number: callerNumber } : {},
      label: diagram.label, language, state: state.reduce((acc, curr) => {
        if (curr.key !== "" && curr.value !== "") {
          acc[curr.key] = curr.value;
        }
        return acc;
      }, {})
    });
  }

  return (
    <Container>
      <LabelSelectContainer>
        <StyledCombobox itemToString={itemToString}
                        fetchItems={fetchItems}
                        value={diagram}
                        onChange={(diagram) => handleDiagramChange(diagram)}
                        placeholder="Select diagram"/>
        <Tooltip title="Additional configuration">
          <SettingsButton expanded={expanded} onClick={() => setExpanded(x => !x)} shape="circle">
            <ArrowIcon color={colors.mainIconColor}/>
          </SettingsButton>
        </Tooltip>
      </LabelSelectContainer>
      {expanded &&
        <>
          <FieldContainer>
            <Input value={callerNumber} onChange={(e) => setCallerNumber(e.target.value)}
                   placeholder="Caller number"/>
          </FieldContainer>
          <FieldContainer>
            <Select onChange={(value) => setLanguage(value)} value={language}
                    options={[{label: 'estonian', value: 'estonian'}]}/>
          </FieldContainer>
          <FieldContainer>
            <div>State</div>
            <MapInput data={state} handleChange={handleMapChange}/>
          </FieldContainer>
          <ButtonContainer>
            <Button type="primary" onClick={handleSubmit}>Start call</Button>
          </ButtonContainer>
        </>
      }
    </Container>
  );
}

export default CallForm;
