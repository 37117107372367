import usersActions from "../../action-creators/usersActions";
import React, {useEffect} from "react";
import CallbotService from "../../services/CallbotService";
import callbotActions from "../../action-creators/callbotActions";
import {connect} from "react-redux";
import ToolsContainer from "../ToolsContainer";

const InitContainer = ({user, fetchCurrentUser, setCallbotClassifiers, setCallbotValidators, labels, settings}) => {
  useEffect(() => {
    fetchCurrentUser();
  }, [])

  useEffect(() => {
    CallbotService.fetchClassifiers().then(({data}) => {
      setCallbotClassifiers(data.classifiers);
    })
    CallbotService.fetchValidators().then(({data}) => {
      setCallbotValidators(data.validators);
    })
  }, []);


  if (user && user.id) {
    return <ToolsContainer labels={labels} settings={settings}/>;
  }

  return null;
}

const mapStateToProps = (state) => ({
  user: state.users.currentUser
});

const mapDispatchToProps = {
  fetchCurrentUser: usersActions.fetchCurrentUser,
  setCallbotValidators: callbotActions.setCallbotValidators,
  setCallbotClassifiers: callbotActions.setCallbotClassifiers,
};

export default connect(mapStateToProps, mapDispatchToProps)(InitContainer);
