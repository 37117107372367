import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import $ from 'jquery';
import settingsActions from '../../action-creators/settingsActions';
import withoutChatDraft from "../utils/chat-draft/withoutChatDraft";
import {withRoles} from "../utils/roles/withRoles";

function addClass(el, className) {
  if (el.classList) el.classList.add(className);
  else if (!hasClass(el, className)) el.className += ' ' + className;
}

function removeClass(el, className) {
  if (el.classList) el.classList.remove(className);
  else el.className = el.className.replace(new RegExp('\\b' + className + '\\b', 'g'), '');
}
let cws;
const LiveClient = ({ fetchSettings, settings }) => {
  useEffect(() => {
      fetchSettings();
    return () => {
      cws?.destroy();
    };
  }, []);
  useEffect(() => {
    if (settings && Object.keys(settings).length > 0) {
      let widgetOptions = getWidgetOptions(settings);
      initCWC(widgetOptions);
    }
  }, [settings]);
  return <div style={{ height: '100%' }} />;
};

const getWidgetOptions = settings => {
  Object.keys(settings).forEach(key => {
    if (!settings[key] || settings[key] === '') {
      delete settings[key];
    }
  });
  const defaultValues = {
    icon_url: 'https://cwc.demo.mindtitan.com/mt.png',
    title: '{"en": "My custom title"}',
    languages: 'en',
    timezone: 'Europe/Helsinki',
    color_bar: '#263c73',
    color_support: '#000719',
    color_user: '#f0f1f5',
  };
  delete settings.draft_parsers;  // this take lots of URL space. Remove it when large URL problem fixed
  const actualData = {
    ...defaultValues,
    ...settings,
  };
  let languages;
  if (actualData.languages === '') {
    languages = [];
  } else {
    languages = actualData.languages.split(',');
  }

  let titles;
  try {
    titles = JSON.parse(actualData.title);
    if (typeof titles !== 'object') {
      titles = { [languages[0]]: JSON.stringify(titles) };
    }
  } catch (e) {
    titles = JSON.parse(defaultValues.title);
  }
  const widgetOptions = {
    languages,
    title: titles[languages[0]],
    icon_url: actualData.icon_url,
    timezone: actualData.timezone,
    color_bar: actualData.color_bar,
    color_support: actualData.color_support,
    color_user: actualData.color_user,
    default_label_language: actualData.default_label_language,
  };
  return widgetOptions;
};

export const mapStateToProps = state => {
  return {
    settings: state.settings,
  };
};

const mapDispatchToProps = {
  fetchSettings: settingsActions.fetchSettings,
};

export default connect(mapStateToProps, mapDispatchToProps)(
  withRoles(
    ['product_manager', 'admin'],
    withoutChatDraft(withRouter(LiveClient)))
);
function initCWC(widgetOptions) {
  cws?.destroy();
  cws = initChatSdk(`${process.env.CWC_LIVE_URL}/custom_enabled/`, {
    options: widgetOptions,
  });
}
