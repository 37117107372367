import { EditorWrapper } from "./utils/CommonElements";
import { Editor } from "@monaco-editor/react";
import React from "react";

export const CodeEditor = ({
  isEditorExpanded, isBlocked, handleEditorDidMount, currentValue, changeCurrentValue
}) => {
  const onChange = (newValue) => {
    changeCurrentValue(newValue);
  };
  const editorOptions = {
    minimap: {
      enabled: false,
    },
    unicodeHighlight: {
		invisibleCharacters: true
	},
  };
  return (
    <EditorWrapper className={`${isEditorExpanded ? 'large' : ''} ${isBlocked ? 'disabled' : ''}`}>
      <Editor
        width={isEditorExpanded ? '1050px' : '750px'}
        height={isEditorExpanded ? 'calc(50vh - 12px)' : 'calc(40vh - 12px)'}
        language="python"
        defaultValue={currentValue}
        options={editorOptions}
        onChange={onChange}
        onMount={handleEditorDidMount}
      />
    </EditorWrapper>
  );
}