export default function unEscape(str) {
  if (!str) {
    return str;
  }
  if (str.indexOf('\\') < 0) {
    return str;
  }
  const message = str.replaceAll('"', '\\"');
  return JSON.parse(`{"message": "${message}"}`).message;
}
