import initialState from '../constants/initial-state-parts/initialState';

const search = (origin, searchString, key) => {
  return origin.filter(item => item[key] === searchString);
};

const appReducer = (state = initialState, action) => {
  switch (action.type) {
    ////////////////////////////////////////////////
    /// Diagram version control related reducers ///
    ////////////////////////////////////////////////
    case 'UPDATE_DIAGRAMS_LIST':
      return Object.assign({}, state, {
        diagramsList: action.newDiagramsList,
      });
    case 'UPDATE_LABELS_LIST':
      return Object.assign({}, state, {
        labels: action.labels,
      });
    case 'UPDATE_SEARCH_RESULTS':
      return Object.assign({}, state, {
        searchString: action.newSearchString,
        diagramsSearchList: Object.assign(
          [],
          state.diagramsList
            .filter(item => action.results.find(diagram => diagram['diagram_id'] === item.id))
            .map(item => ({
              ...item,
              searchMatchesCount: action.results.find(diagram => diagram['diagram_id'] === item.id)?.count,
              searchResults: action.results.find(diagram => diagram['diagram_id'] === item.id)?.text,
            }))
            .sort(
              (a, b) =>
                action.results.indexOf(action.results.find(x => x['diagram_id'] === a.id)) -
                action.results.indexOf(action.results.find(x => x['diagram_id'] === b.id))
            )
        ),
      });
    case 'CLEAR_SEARCH_RESULTS':
      return Object.assign({}, state, {
        searchString: '',
        diagramsSearchList: [],
      });
    case 'UPDATE_EXPANDED_DIAGRAM_WITH_VERSIONS':
      return Object.assign({}, state, {
        expandedDiagram:
          state.expandedDiagram.id === action.diagram.id && action.shouldCollapse ? { id: -1 } : action.diagram,
        expandedDiagramLastVersions: action.diagramVersions,
        isExpandedDiagramHasMoreVersions: action.hasMore,
      });
    case 'UPDATE_EXPANDED_DIAGRAM_VERSIONS':
      return Object.assign({}, state, {
        expandedDiagramLastVersions: action.shouldRenew
          ? action.extraDiagramVersions
          : state.expandedDiagramLastVersions.concat(action.extraDiagramVersions),
        isExpandedDiagramHasMoreVersions: action.hasMore,
        expandedDiagramLastVersionsCount: action.newCount,
      });

    //////////////////////////////////////////////////////////
    /// Current diagram, version and task related reducers ///
    //////////////////////////////////////////////////////////
    case 'UPDATE_CURRENT_DIAGRAM_AND_VERSION':
      return Object.assign({}, state, {
        currentDiagram: action.diagram,
        currentDiagramVersion: action.version,
        currentDiagramLastVersions: action.versions,
        isCurrentDiagramHasMoreVersions: action.hasMore,
        isCurrentDiagramSaved: action.isDiagramSaved,
        isCurrentDiagramVersionSaved: action.isDiagramVersionSaved,
        isCurrentDiagramVersionFileSaved: action.isDiagramVersionFileSaved,
        backendDump: Object.assign({}, state.backendDump, {
          currentDiagram: action.diagram,
          currentDiagramVersion: action.version,
          currentDiagramLastVersions: action.versions,
          isCurrentDiagramHasMoreVersions: action.hasMore,
        }),
      });
    case 'UPDATE_CURRENT_DIAGRAM_VERSION_APPROVED_STATE':
      return Object.assign({}, state, {
        currentDiagramVersion: Object.assign({}, state.currentDiagramVersion, {
          approved: action.isApproved,
        }),
      });
    case 'UPDATE_CURRENT_DIAGRAM':
      return Object.assign({}, state, {
        currentDiagram: Object.assign({}, state.currentDiagram, {
          versionInLive: action.diagram['versionInLive'],
          versionInTest: action.diagram['versionInTest'],
          lastVersion: action.diagram['lastVersion'],
        }),
      });
    case 'UPDATE_CURRENT_DIAGRAM_NAME':
      return Object.assign({}, state, {
        currentDiagram: Object.assign({}, state.currentDiagram, {
          name: action.name,
        }),
        isCurrentDiagramSaved:
          action.name === state.backendDump.currentDiagram.name &&
          action.owner.id === state.backendDump.currentDiagram.owner.id,
      });
    case 'UPDATE_CURRENT_DIAGRAM_OWNER':
      return Object.assign({}, state, {
        currentDiagram: Object.assign({}, state.currentDiagram, {
          owner: Object.assign({}, action.owner),
        }),
        isCurrentDiagramSaved:
          action.name === state.backendDump.currentDiagram.name &&
          action.owner.id === state.backendDump.currentDiagram.owner.id,
      });
    case 'UPDATE_CURRENT_DIAGRAM_TO_BE_UNSAVED':
      return Object.assign({}, state, {
        isCurrentDiagramSaved: false
      });
    case 'UPDATE_CURRENT_DIAGRAM_VERSION_LABEL':
      return Object.assign({}, state, {
        currentDiagramVersion: Object.assign({}, state.currentDiagramVersion, {
          label: action.label,
        }),
        isCurrentDiagramVersionSaved: action.label === state.backendDump.currentDiagramVersion.label,
      });
    case 'UPDATE_CURRENT_OPTIONAL_DIAGRAM_COMMENT':
      return Object.assign({}, state, {
        currentOptionalDiagramComment: action.comment,
      });
    case 'UPDATE_CURRENT_DIAGRAM_SHOULD_REVIEW':
      return Object.assign({}, state, {
        currentDiagramShouldReview: action.diagramShouldReview,
      });
    case 'UPDATE_CURRENT_DIAGRAM_VERSION_FILE':
      return Object.assign({}, state, {
        currentDiagramVersion: Object.assign({}, state.currentDiagramVersion, {
          file: action.file,
        }),
        backendDump: Object.assign({}, state.backendDump, {
          currentDiagramVersion: Object.assign({}, state.backendDump.currentDiagramVersion, {
            file: action.file,
          }),
        }),
        isCurrentDiagramVersionFileSaved: true,
      });
    case 'UPDATE_CURRENT_FILE_STATE':
      return Object.assign({}, state, {
        isCurrentDiagramVersionFileSaved: action.newState,
      });
    case 'UPDATE_CURRENT_DIAGRAM_VERSIONS':
      return Object.assign({}, state, {
        currentDiagramLastVersions: action.shouldRenew
          ? action.extraDiagramVersions
          : state.currentDiagramLastVersions.concat(action.extraDiagramVersions),
        isCurrentDiagramHasMoreVersions: action.hasMore,
        currentDiagramLastVersionsCount: action.newCount,
      });
    case 'UPDATE_CURRENT_DIAGRAM_TRANSLATIONS_MODIFICATION_COUNT':
      const modificationCount =
        action.translationsModificationCount === 'reset'
          ? 0
          : (state.currentDiagramVersion?.translationsModificationCount || 0) + 1;

      if (modificationCount === 0) {
        return Object.assign({}, state, {
          currentDiagramVersion: Object.assign({}, state.currentDiagramVersion, {
            translationsModificationCount: modificationCount,
          }),
          isCurrentDiagramVersionFileSaved: false,
        });
      }
      return Object.assign({}, state, {
        currentDiagramVersion: Object.assign({}, state.currentDiagramVersion, {
          translationsModificationCount: modificationCount,
        }),
      });
    case 'UPDATE_CURRENT_TASK':
      if (!action.silently || (
        state.currentTask && state.currentTaskBusinessObject
        && action.businessObject && action.task
      )) {
        return Object.assign({}, state, {
          currentTask: action.task,
          currentTaskBusinessObject: Object.assign({}, action.businessObject),
        });
      } else return Object.assign({}, state, {});
    case 'UPDATE_CURRENT_SUB_PROCESS':
      if (!action.silently || (
        state.currentSubProcess && state.currentSubProcessBusinessObject
        && action.subProcess && action.businessObject
      )) {
        return Object.assign({}, state, {
          currentSubProcess: action.subProcess,
          currentSubProcessBusinessObject: Object.assign({}, action.businessObject),
        });
      } else return Object.assign({}, state, {});
    case 'UPDATE_CURRENT_SUB_PROCESS_VERSIONS':
      return Object.assign({}, state, {
        currentSubProcessDiagramVersions: action.newVersions,
      });
    case 'UPDATE_CURRENT_SUB_PROCESS_FILE':
      return Object.assign({}, state, {
        currentSubProcessFile: action.file,
      });
    case 'UPDATE_CURRENT_EVENT':
      return Object.assign({}, state, {
        currentEvent: action.event,
        currentEventBusinessObject: Object.assign({}, action.businessObject),
      });
    case 'SET_DIAGRAM_TYPE':
      return Object.assign({}, state, {
        currentDiagramVersion: Object.assign({}, state.currentDiagramVersion, {
          diagramType: action.payload,
          // reset file
          file: '<?xml version="1.0" encoding="UTF-8"?>\n<bpmn2:definitions xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xmlns:bpmn2="http://www.omg.org/spec/BPMN/20100524/MODEL" xmlns:bpmndi="http://www.omg.org/spec/BPMN/20100524/DI" xmlns:dc="http://www.omg.org/spec/DD/20100524/DC" id="sample-diagram" targetNamespace="http://bpmn.io/schema/bpmn" xsi:schemaLocation="http://www.omg.org/spec/BPMN/20100524/MODEL BPMN20.xsd"><bpmn2:process id="Process_1" isExecutable="false"><bpmn2:startEvent id="StartEvent_1" leaf="true" branches="[1]" name="Start" /></bpmn2:process><bpmndi:BPMNDiagram id="BPMNDiagram_1"><bpmndi:BPMNPlane id="BPMNPlane_1" bpmnElement="Process_1"><bpmndi:BPMNShape id="_BPMNShape_StartEvent_2" bpmnElement="StartEvent_1"><dc:Bounds x="807" y="812" width="36" height="36" /><bpmndi:BPMNLabel><dc:Bounds x="791" y="855" width="69" height="14" /></bpmndi:BPMNLabel></bpmndi:BPMNShape></bpmndi:BPMNPlane></bpmndi:BPMNDiagram></bpmn2:definitions>'
        })
      });

    case 'SET_LOADING_FLAG_SF':
      return Object.assign({}, state, { sfLoading: action.sfLoading });

    case 'SET_IN_PROGRESS_DIAGRAM_VERSION_FOR_PROGRESS_ID':
      return Object.assign({}, state, { inProgressId: action.inProgressId })

    default:
      return state;
  }
};

export default appReducer;
