import React, { useEffect } from 'react';
import { Route, withRouter } from 'react-router-dom';
import BindComponent from '../../../sfm-app/sfm-app';
import { connect } from 'react-redux';
import labelsActions from '../../action-creators/labelsActions';
import settingsActions from '../../action-creators/settingsActions';
import withChatDraft from "../utils/chat-draft/withChatDraft";
import {withRoles} from "../utils/roles/withRoles";

const SFM = ({ fetchLabelsList, labels, match, fetchSettings, settings }) => {
  useEffect(() => {
    if (process.env.API_URL) {
      fetchLabelsList();
      if (Object.keys(settings || {}).length === 0) {
        fetchSettings();
      }
    }
  }, []);

  return (
    <>
      <div id="sfm-app-container">
        <div id="sfm-app-modeler"></div>
        <div id="sfm-app-viewer"></div>
        <div id="sfm-app-tools"></div>
        <BindComponent labels={labels} settings={settings} />
      </div>
      <div id="sfm-app-review-viewer"></div>
    </>
  );
};
export const mapStateToProps = state => {
  return {
    labels: state.labels.labels,
    settings: state.settings,
  };
};

const mapDispatchToProps = {
  fetchLabelsList: labelsActions.fetchLabelsList,
  fetchSettings: settingsActions.fetchSettings,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRoles(
  ['product_manager', 'admin'],
  withChatDraft(withRouter(SFM)))
);
