import React from 'react';
import * as colors from '../../styles/colors';

import {
  ModalWrapper,
  ModalContent,
  ModalIconContent,
  ModalTitle,
  ModalParagraph,
  ModalButtons,
  CancelButton,
  ConfirmButton,
} from './modals-elements/ModalsElements';
import SaveIcon from '../shared/icons/tools/SaveIcon';

const UnsavedChangesModal = ({ modalKey, closeModal, proceedOnModalConfirm, actionOnConfirm, argsOnConfirm }) => {
  return (
    <ModalWrapper>
      <ModalContent>
        <ModalIconContent>
          <SaveIcon color={colors.alternativeIconColor} />
        </ModalIconContent>
        <ModalTitle>Unsaved changes detected</ModalTitle>
        <ModalParagraph>
          You have requested system to open another solution flow. However, we have detected{' '}
          <span className="bold">unsaved changes</span>
          in current solution flow. Do you want to continue?
        </ModalParagraph>
        <ModalButtons>
          <CancelButton onClick={() => closeModal(modalKey)}>Cancel</CancelButton>
          <ConfirmButton onClick={() => proceedOnModalConfirm(actionOnConfirm, argsOnConfirm, modalKey)}>
            Continue
          </ConfirmButton>
        </ModalButtons>
      </ModalContent>
    </ModalWrapper>
  );
};

export default UnsavedChangesModal;
