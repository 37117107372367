import { put, call, all, take, takeLatest, spawn, fork } from 'redux-saga/effects';
import NotificationService from '../services/common/NotificationService';
import SettingsService from '../services/SettingsService';
import settingsActions from '../action-creators/settingsActions';

const ENABLE_HEADER = process.env.ENABLE_HEADER || 'true';

function* fetchSettingsFlow() {
  try {
    const response = yield call(SettingsService.fetch);

    if (response.status === 200) {
      yield put(settingsActions.fetchSettingsComplete(response.data));
    } else {
      if (ENABLE_HEADER === 'true') {
        NotificationService.push({
          type: 'error',
        });
      }
    }
  } catch (error) {
    if (ENABLE_HEADER === 'true') {
      NotificationService.push({
        type: 'error',
      });
    }
  }
}

function* updateSettingsFlow({ settings }) {
  try {
    const response = yield call(SettingsService.update, settings);

    if (response.status === 200) {
      yield put(settingsActions.fetchSettingsComplete(response.data));

      NotificationService.push({
        type: 'info',
        message: 'settings saved!',
      });
    } else {
      NotificationService.push({
        type: 'error',
      });
    }
  } catch (error) {
    NotificationService.push({
      type: 'error',
    });
  }
}

function* watchFetcSettingsRequested() {
  yield takeLatest('FETCH_SETTINGS', fetchSettingsFlow);
}

function* watchUpdateSettingsRequested() {
  yield takeLatest('UPDATE_SETTINGS', updateSettingsFlow);
}

export default function* settingsSaga() {
  yield all([watchFetcSettingsRequested(), watchUpdateSettingsRequested()]);
}
