const updateCurrentEvent = (event, businessObject) => {
    return {
        type: 'UPDATE_CURRENT_EVENT',
        event: event,
        businessObject: businessObject
    }
};

const currentEventActions = {
    updateCurrentEvent
};

export default currentEventActions;
