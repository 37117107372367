import crud from './common/CrudService';
import responseService from './common/ResponseService';

const API_BASE_URL = process.env.API_URL || 'http://127.0.0.1:8000';
const API_PREFIX = process.env.API_PREFIX || 'api/v1';
const cleanUpCrud = crud(API_BASE_URL, API_PREFIX, ['cleanup/']);
const CleanUpService = {
  cleanup: () => {
    return responseService.handle(cleanUpCrud.delete());
  },
};

export default CleanUpService;
