import React from 'react';
import { connect } from 'react-redux';

import VersionControlSearch from '../../components/version-control-panel/version-control-panel-elements/VersionControlSearch';
import searchActions from '../../action-creators/searchActions';

const VersionControlSearchContainer = ({ searchString, searchDiagrams, isOpen }) => {
  return <VersionControlSearch searchString={searchString} searchDiagrams={searchDiagrams} isOpen={isOpen} />;
};

const mapStateToProps = state => {
  return {
    isOpen: state.visibility.versionControlPanelVisible,
    searchString: state.app.searchString,
  };
};

const mapDispatchToProps = {
  ...searchActions,
};

export default connect(mapStateToProps, mapDispatchToProps)(VersionControlSearchContainer);
