import initialNavState from '../constants/initial-state-parts/initialNavState';

const navReducer = (state = initialNavState, action) => {
  switch (action.type) {
    case 'NEVER':
      return state;
    default:
      return state;
  }
};

export default navReducer;
