import initialModalsState from '../constants/initial-state-parts/initialModalsState';

const modalsReducer = (state = initialModalsState, action) => {
  switch (action.type) {
    case 'OPEN_MODAL':
      return Object.assign({}, state, {
        [action.modalKey + 'Modal']: Object.assign({}, state[action.modalKey + 'Modal'], {
          isOpen: true,
          actionOnConfirm: action.actionOnConfirm,
          argsOnConfirm: action.argsOnConfirm,
          extraModalArgs: action.extraModalArgs,
          actionOnCancel: action.actionOnCancel,
          argsOnCancel: action.argsOnCancel,
          modalText: action.modalText,
        }),
      });
    case 'CLOSE_MODAL':
      return Object.assign({}, state, {
        [action.modalKey + 'Modal']: Object.assign({}, state[action.modalKey + 'Modal'], {
          isOpen: false,
          actionOnConfirm: () => {},
          argsOnConfirm: {},
          extraModalArgs: {},
          actionOnCancel: () => {},
          argsOnCancel: {},
        }),
      });
    default:
      return state;
  }
};

export default modalsReducer;
