import React from 'react';
import styled from 'styled-components';
import * as mixins from '../../../../styles/mixins';
import * as colors from '../../../../styles/colors';
import * as typography from '../../../../styles/typography';

const DiagramInfoText = styled.p`
  color: ${colors.mainFontColor};
`;

const DiagramCurrentMark = styled.div`
  position: absolute;
  top: calc(50% - 3px);
  left: 0;
  transform: translateX(-15px);
  width: 6px;
  height: 6px;
  background-color: ${colors.extraFontColor};
  border-radius: 50%;
`;

const DiagramInfoContent = styled.div`
  position: relative;
  ${mixins.flex('flex-start')};

  &:hover > ${DiagramInfoText} {
    color: ${colors.alternativeFontColor};
  }

  &:hover > ${DiagramCurrentMark} {
    background-color: ${colors.alternativeFontColor};
  }
`;

const DiagramTitle = styled(DiagramInfoText)`
  margin-right: 8px;
  ${props =>
    props.isCurrent
      ? `
        color: ${colors.extraFontColor};
        ${mixins.fontStyle(typography.textFontSize, typography.bold)};
    `
      : `
        ${mixins.fontStyle(typography.textFontSize, typography.medium)};
    `};
`;

const DiagramVersion = styled(DiagramInfoText)`
  ${props =>
    props.isCurrent
      ? `
        color: ${colors.extraFontColor};
        ${mixins.fontStyle(typography.labelFontSize, typography.semibold)};
    `
      : `
        ${mixins.fontStyle(typography.labelFontSize, typography.normal)};
    `};
`;

const VersionControlDiagramInfo = ({ name, version, isCurrent, onDiagramInfoClick }) => {
  return (
    <DiagramInfoContent onClick={onDiagramInfoClick}>
      <DiagramTitle isCurrent={isCurrent}>{name}</DiagramTitle>
      {version ? (
        <DiagramVersion isCurrent={isCurrent}>
          ({version.label}) - {version.id}
        </DiagramVersion>
      ) : (
        <p>No version in live</p>
      )}

      {isCurrent ? <DiagramCurrentMark /> : ''}
    </DiagramInfoContent>
  );
};

export default VersionControlDiagramInfo;
