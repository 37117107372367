import styled from "styled-components";
import * as colors from "../../../../../styles/colors";
import * as mixins from "../../../../../styles/mixins";
import * as typography from "../../../../../styles/typography";

export const ElementPropInput = styled.input`
  width: 100%;
  min-height: 24px;
  box-sizing: border-box;
  padding: 4px 6px;
  background: ${colors.extraBackgroundColor};
  color: ${colors.mainFontColor};
  ${mixins.fontStyle(typography.textFontSize, typography.semibold)};
`;

export const EditorWrapper = styled.div`
  min-width: 750px;
  font-family: 'codicon' !important;
  .button {
    padding: 0;
    background: none;
    margin-right: 0;
  }
  .input,
  .textarea,
  .select {
    min-width: 30px;
  }
  &.large {
    min-width: 1050px;
    min-height: 50vh;
  }

  &.disabled {
    pointer-events: none;
    opacity: 0.9;
  }
`;

export const ElementPropSelect = styled.select`
  width: 100%;
  min-height: 24px;
  box-sizing: border-box;
  padding: 4px 6px;
  background: ${colors.extraBackgroundColor};
  color: ${colors.mainFontColor};
  ${mixins.fontStyle(typography.textFontSize, typography.semibold)};

  ${props => (props.disabled ? `color: ${colors.extraFontColorWithOpacity}` : '')};
`;

export const ElementPropOption = styled.option`
  background-color: white;
  min-height: 24px;
  border: 1px solid ${colors.componentBackgroundColor};

  &:hover {
    background-color: ${colors.componentBackgroundColor};
  }
`;