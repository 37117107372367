import React, { useEffect, useState } from 'react';
import { Row, Button } from 'antd';
import TranslationImage from './translate.png';
import './Languages.scss';
import { getDiagramVersionTranslations } from '../../bpmn-engine/index';
import ModelerService from '../../services/ModelerService';
import { Icon } from '@iconify/react/dist/offline';
import checkCircleOutline from '@iconify-icons/mdi/check-circle-outline';
import closeCircleOutline from '@iconify-icons/mdi/close-circle-outline';
import { deepClone } from '../../helpers/deepClone';
import TTSService from '../../services/TTSService';
import LanguageRow from './LanguageRow';
import LanguageHeaderRow from './LanguageHeaderRow';
import { SUPPORTED_LANGUAGES } from '../../../app/components/settings/Settings';

const Languages = ({
  modeler,
  description,
  currentDiagram,
  translationsModificationCount,
  setLanguagesOverlayVisible,
  updateCurrentDiagramTranslationsModificationCount,
  diagramType,
}) => {
  let [nodesTranslationData, setNodesTranslationData] = useState(null);
  const deleteTranslation = nodeId => {
    const copyOfNodesTranslationData = deepClone(nodesTranslationData);
    delete copyOfNodesTranslationData[nodeId];
    setNodesTranslationData(copyOfNodesTranslationData);
  };

  const loadLatestTranslations = () =>
    new Promise((resolve, reject) => {
      ModelerService.exportDiagramVersion(modeler, async (err, file) => {
        if (err) {
          reject(err);
        }

        const nodesData = await getDiagramVersionTranslations({
          diagramXML: file,
          diagramData: currentDiagram,
          description,
        });

        // Fetch list of prompt objects
        const promptIds = [];
        Object.values(nodesData).forEach(nodeData => {
          nodeData.srcString = nodeData.srcString || '';
          nodeData.defaultLanguage = nodeData.defaultLanguage || '';
          nodeData.srcStringModifiedAt = nodeData.srcStringModifiedAt || 0;
          if (Object.keys(nodeData.translations || {}).length === 0) {
            nodeData.translations = {};
            SUPPORTED_LANGUAGES.forEach(lang => {
              nodeData.translations[lang] = {
                selected: false,
                reviewed: nodeData?.[lang]?.reviewed || true,
                text: nodeData?.[lang]?.text || '',
                values: nodeData?.[lang]?.values || '',
              };
            });
          }

          // Add prompt id to list of prompt ids
          SUPPORTED_LANGUAGES.forEach(lang => {
            if (nodeData?.translations[lang]?.promptId) {
              promptIds.push(nodeData.translations[lang].promptId);
            }
          });
        });

        // Fetch prompts and convert list to object
        const prompts = {};
        if (promptIds.length) {
          (await TTSService.getPrompts(promptIds)).forEach(prompt => {
            prompts[prompt.id] = prompt;
          });
        }

        // Assign returned url to nodeData.translations[lang].promptURl
        Object.values(nodesData).forEach(nodeData => {
          SUPPORTED_LANGUAGES.forEach(lang => {
            if (nodeData?.translations[lang]?.promptId) {
              const prompt = prompts[nodeData.translations[lang].promptId];
              if (prompt) {
                nodeData.translations[lang].promptUrl = prompt.url;
              }
            }
          });
        });

        resolve(nodesData);
      });
    });

  useEffect(() => {
    async function helper() {
      const nodesData = await loadLatestTranslations();
      setNodesTranslationData(nodesData);
    }
    helper();
  }, []);

  useEffect(() => {
    async function helper() {
      const nodesData = await loadLatestTranslations();
      setNodesTranslationData(nodesData);
    }
    if (!translationsModificationCount) {
      helper();
    }
  }, [translationsModificationCount]);

  useEffect(() => {
    function handleClickOutsideSelectableLangs(e) {
      if (!Array.from(document.getElementsByClassName('languages-col')).find(element => element.contains(e.target))) {
        const copyOfNodesTranslationData = deepClone(nodesTranslationData);
        Object.values(copyOfNodesTranslationData).forEach(v => {
          SUPPORTED_LANGUAGES.forEach(lang => {
            if (!v.translations[lang]) {
              v.translations[lang] = { selected: false, reviewed: true, text: '' };
            }
            v.translations[lang].selected = false;
          });
        });
        setNodesTranslationData(copyOfNodesTranslationData);
      }
    }

    window.addEventListener('mousedown', handleClickOutsideSelectableLangs);

    return () => {
      window.removeEventListener('mousedown', handleClickOutsideSelectableLangs);
    };
  });

  const onTranslationSelected = (nodeId, lang) => {
    const copyOfNodesTranslationData = deepClone(nodesTranslationData);
    Object.values(copyOfNodesTranslationData).forEach(v => {
      SUPPORTED_LANGUAGES.forEach(lang => {
        if (!v.translations[lang]) {
          v.translations[lang] = { selected: false, reviewed: true, text: '' };
        }
        v.translations[lang].selected = false;
      });
    });
    if (!copyOfNodesTranslationData[nodeId].translations[lang]) {
      copyOfNodesTranslationData[nodeId].translations[lang] = { selected: false, reviewed: true, text: '' };
    }
    copyOfNodesTranslationData[nodeId].translations[lang].selected = true;
    setNodesTranslationData(copyOfNodesTranslationData);
  };

  const onTranslationChanged = (nodeId, lang, text) => {
    const copyOfNodesTranslationData = deepClone(nodesTranslationData);
    if (!copyOfNodesTranslationData[nodeId].translations[lang]) {
      copyOfNodesTranslationData[nodeId].translations[lang] = { selected: false, reviewed: true, text: '' };
    }
    copyOfNodesTranslationData[nodeId].translations[lang].text = text;
    copyOfNodesTranslationData[nodeId].translations[lang].reviewed = true;
    setNodesTranslationData(copyOfNodesTranslationData);
  };

  const onTranslationValuesChange = (nodeId, lang, values) => {
    const copyOfNodesTranslationData = deepClone(nodesTranslationData);
    copyOfNodesTranslationData[nodeId].translations[lang].values = values;
    copyOfNodesTranslationData[nodeId].translations[lang].reviewed = true;
    setNodesTranslationData(copyOfNodesTranslationData);
  };

  const discardTranslations = e => {
    e.stopPropagation();
    setLanguagesOverlayVisible(false);
  };

  const addTranslationsToXml = e => {
    e.stopPropagation();
    const root = modeler._definitions.rootElements[0];
    root.translations = JSON.stringify(nodesTranslationData);
    updateCurrentDiagramTranslationsModificationCount('reset');
    setLanguagesOverlayVisible(false);
  };

  const onTranslationReview = (e, nodeId, lang) => {
    e.stopPropagation();
    const copyOfNodesTranslationData = deepClone(nodesTranslationData);
    if (!copyOfNodesTranslationData[nodeId].translations[lang]) {
      copyOfNodesTranslationData[nodeId].translations[lang] = { selected: false, reviewed: true, text: '' };
    }
    copyOfNodesTranslationData[nodeId].translations[lang].reviewed = true;
    setNodesTranslationData(copyOfNodesTranslationData);
  };

  const clearAllTranslationsForLanguage = lang => {
    const copyOfNodesTranslationData = deepClone(nodesTranslationData);
    for (const nodeId in copyOfNodesTranslationData) {
      if (!copyOfNodesTranslationData[nodeId].translations[lang]) {
        copyOfNodesTranslationData[nodeId].translations[lang] = { selected: false, reviewed: true, text: '' };
      }
      copyOfNodesTranslationData[nodeId].translations[lang].text = '';
    }
    setNodesTranslationData(copyOfNodesTranslationData);
  };

  const handleSynthesize = (nodeId, lang) => {
    const copyOfNodesTranslationData = deepClone(nodesTranslationData);
    TTSService.synthesizeDynamic(
      copyOfNodesTranslationData[nodeId].translations[lang].text,
      copyOfNodesTranslationData[nodeId].translations[lang].values
    ).then(({ data }) => {
      copyOfNodesTranslationData[nodeId].translations[lang].promptUrl = data.url;
      setNodesTranslationData(copyOfNodesTranslationData);
    });
  };

  const handleSrcLanguageMouseMove = ev => {
    const x = ev.clientX + 20 + 'px';
    const y = ev.clientY + 20 + 'px';
    const tooltip = ev.currentTarget.getElementsByTagName('span')[0];
    tooltip.style.top = y;
    tooltip.style.left = x;
  };

  if (!nodesTranslationData) {
    return null;
  }

  return (
    <div id="languages-container">
      <div id="languages-sticky-row">
        <Row id="languages-header">
          <div>
            <img height={25} width={25} id="translation-img" src={TranslationImage} />
            <span>Translations</span>
          </div>
          <div>
            <Button className="no-padding languages-action-btn-proceed" type="text" onClick={addTranslationsToXml}>
              <Icon className="no-padding" icon={checkCircleOutline} />
            </Button>
            <Button className="no-padding languages-action-btn-cancel" type="text" onClick={discardTranslations}>
              <Icon className="no-padding" icon={closeCircleOutline} />
            </Button>
          </div>
        </Row>
        <LanguageHeaderRow
          nodesTranslationData={nodesTranslationData}
          clearAllTranslationsForLanguage={clearAllTranslationsForLanguage}
        />
      </div>
      <LanguageRow
        nodeId="label_name_translations"
        diagramType={diagramType}
        nodesTranslationData={nodesTranslationData}
        handleSynthesize={handleSynthesize}
        onTranslationReview={onTranslationReview}
        onTranslationChanged={onTranslationChanged}
        deleteTranslation={deleteTranslation}
        handleSrcLanguageMouseMove={handleSrcLanguageMouseMove}
        onTranslationSelected={onTranslationSelected}
        title="Description for clients"
      />
      <LanguageRow
        nodeId="label_description_translations"
        diagramType={diagramType}
        nodesTranslationData={nodesTranslationData}
        handleSynthesize={handleSynthesize}
        onTranslationReview={onTranslationReview}
        onTranslationChanged={onTranslationChanged}
        deleteTranslation={deleteTranslation}
        handleSrcLanguageMouseMove={handleSrcLanguageMouseMove}
        onTranslationSelected={onTranslationSelected}
        title="Description for agents"
      />
      {Object.keys(nodesTranslationData)
        .filter(nodeId => nodeId !== 'label_name_translations' && nodeId !== 'label_description_translations')
        .map(nodeId => (
          <LanguageRow
            nodeId={nodeId}
            elementRegistry={modeler.get('elementRegistry')}
            key={nodeId}
            diagramType={diagramType}
            nodesTranslationData={nodesTranslationData}
            handleSynthesize={handleSynthesize}
            onTranslationReview={onTranslationReview}
            onTranslationChanged={onTranslationChanged}
            deleteTranslation={deleteTranslation}
            handleSrcLanguageMouseMove={handleSrcLanguageMouseMove}
            onTranslationSelected={onTranslationSelected}
            onTranslationsValuesChange={onTranslationValuesChange}
          />
        ))}
    </div>
  );
};

export default Languages;
