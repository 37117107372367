import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import EventListener from 'react-event-listener';

import ReplyBox from './ReplyBox';
import AutoCompleteText from './AutoCompletetext';
import MessageBox from './MessageBox';

const TextAreaComponent = ({
  setNewUser,
  addIncomingMessage,
  loggedInUser,
  supportPerson,
  width,
  userName,
  userId,
  chatId,
  availChats,
  elisaCustomerId,
  links,
  elisaCustomerCode,
  messages,
  addMessage,
  socket,
}) => {
  useEffect(() => {
    /* Listen on selected user info from Server. */
    socket.on('selected user', setNewUser);
    socket.on('client message', addIncomingMessage);
  }, []);

  /* Test whether the current user is the owner of the selected chat.*/
  const someOneElsesProblem = loggedInUser !== supportPerson;

  const activity = () => {
    /*If any activity detected, send to api that the chat has been seen.*/
    if (!chatId) return;
    let currentChat = availChats.filter(chat => chat.chatId === chatId)[0];

    if (!(currentChat.seen === 'seen' || someOneElsesProblem)) {
      console.log('Emitting chat seen');
      socket.emit('chat seen', { chatId, userId });
    }
  };

  let displayEpdURL = null;
  let displayTtlURL = null;
  let displayWebwareURL = null;

  if (links.includes('EPD')) {
    let epdURL = 'https://epd.teleyks.ee/wagner/wicket/customerview?asiakasnumero=' + elisaCustomerId;
    displayEpdURL = (
      <a className="headingg-name-meta" target="_blank" href={epdURL}>
        [EPD]
      </a>
    );
  }
  if (links.includes('TTL')) {
    let ttlURL = 'https://ttl.int.radiolinja.ee/personnel-desktop/pd/pd.html#id%5Bsearch%5D=' + elisaCustomerId;
    displayTtlURL = (
      <a className="headingg-name-meta" target="_blank" href={ttlURL}>
        [TTL]
      </a>
    );
  }
  if (links.includes('WEBWARE')) {
    let webwareURL = 'https://webware/query/apps/crm/#/' + elisaCustomerCode;
    displayWebwareURL = (
      <a className="headingg-name-meta" target="_blank" href={webwareURL}>
        [Webware]
      </a>
    );
  }

  return (
    <div className={'col-sm-' + width + ' textArea side'}>
      <div className="conversation">
        {/*Heading*/}
        <div className="row heading vcenter">
          <div className="col-sm-8 col-xs-8 heading-name">
            {userName === 'test_account' ? 'John Smith' : userName} {displayEpdURL} {displayTtlURL} {displayWebwareURL}
          </div>
          <div className="col-sm-4 col-xs-4 chatId">{'Chat id: ' + chatId}</div>
        </div>
        {/*Heading End*/}

        <MessageBox userId={userId} messages={messages.filter(m => m.text)} loggedInUser={loggedInUser} />
        <ReplyBox addMessage={addMessage} loggedInUser={loggedInUser} />
      </div>
      <AutoCompleteText width={width} />
      <EventListener target={document} onMouseMoveCapture={activity} onKeyDown={activity} />
    </div>
  );
};

/* What attributes from the global state to connect to replybox */
const mapStateToProps = function (store) {
  return {
    socket: store.appState.socket,
    userId: store.appState.userId,
    chatId: store.appState.chatId,
    elisaCustomerId: store.appState.elisaCustomerId,
    elisaCustomerCode: store.appState.elisaCustomerCode,
    userName: store.appState.userName,
    messages: store.appState.messages,
    availChats: store.appState.availChats,
    supportPerson: store.appState.supportPerson,
    ttl_code_required: store.appState.ttl_code_required,
    links: store.appState.links,
  };
};

/* What update actions to the global state replybox should have */
const mapDispatchToProps = dispatch => {
  return {
    setNewUser: chat => {
      console.log('newchat', chat);
      dispatch({
        type: 'NEW_CHAT',
        userName: chat.userName,
        chatId: chat.chatId,
        elisaCustomerId: chat.elisaCustomerId,
        elisaCustomerCode: chat.elisaCustomerCode,
        userId: chat.userId,
        messages: chat.messages,
        clientAvatar: chat.clientAvatar,
        supportPerson: chat.supportPerson,
        someoneTyping: chat.somebodyTyping,
        ttl_code_chosen: chat.ttl_code_chosen,
        textFieldContents: chat.textFieldContents,
        ttl_code_required: chat.ttl_code_required,
        links: chat.links,
      });
    },
    addMessage: message =>
      dispatch({
        type: 'ADD_MESSAGE',
        message: message,
      }),
    addIncomingMessage: message => {
      return dispatch({
        type: 'ADD_INCOMING_MESSAGE',
        message: message,
      });
    },
  };
};

const TextArea = connect(mapStateToProps, mapDispatchToProps)(TextAreaComponent);

export { TextArea };
