const fetchUsersList = searchString => {
  return {
    type: 'FETCH_USERS_LIST',
    searchString: searchString,
  };
};

const fetchCurrentUser = () => {
  return {
    type: 'FETCH_CURRENT_USER',
  };
};

const updateCurrentUser = currentUser => {
  return {
    type: 'UPDATE_CURRENT_USER',
    currentUser: currentUser,
  };
};

const updateOwnersList = owners => {
  return {
    type: 'UPDATE_OWNERS_LIST',
    owners: owners,
  };
};

const updateOwnersSearchString = searchString => {
  return {
    type: 'UPDATE_OWNERS_SEARCH_STRING',
    ownersSearchString: searchString,
  };
};

const usersActions = {
  fetchUsersList,
  fetchCurrentUser,
  updateCurrentUser,
  updateOwnersList,
  updateOwnersSearchString,
};

export default usersActions;
