import initialLabellingState from '../constants/initial-state-parts/initialLabellingState';

const labellingReducer = (state = initialLabellingState, action) => {
  switch (action.type) {
    case 'UPDATE_CHATS_NOT_LABELLED':
      return Object.assign({}, state, {
        chats: action.chats,
        totalCount: action.totalCount,
      });
    case 'ADD_MESSAGE_LABEL':
      const getUpdatedChatMessages = (chat, label) => {
        return Object.assign({}, chat, {
          messages: Object.assign(
            [],
            chat.messages.map(message =>
              message.id === label.message_id
                ? Object.assign({}, message, {
                    messageLabels: Object.assign([], [...(message.messageLabels || []), label]),
                  })
                : message
            )
          ),
        });
      };
      return Object.assign({}, state, {
        chats: Object.assign(
          [],
          state.chats.map(chat =>
            chat.id === action.label.chat_intercom_id ? getUpdatedChatMessages(chat, action.label) : chat
          )
        ),
      });
    case 'REMOVE_MESSAGE_LABEL':
      console.log('In remove: ', action);
      const getCleanedChatMessages = (chat, label) => {
        return Object.assign({}, chat, {
          messages: Object.assign(
            [],
            chat.messages.map(message =>
              message.id === label.message_id
                ? Object.assign({}, message, {
                    messageLabels: Object.assign(
                      [],
                      message.messageLabels.filter(messageLabel => messageLabel.id !== label.id)
                    ),
                  })
                : message
            )
          ),
        });
      };
      return Object.assign({}, state, {
        chats: Object.assign(
          [],
          state.chats.map(chat =>
            chat.id === action.label.chat_intercom_id ? getCleanedChatMessages(chat, action.label) : chat
          )
        ),
      });
    default:
      return state;
  }
};

export default labellingReducer;
