import React, { useState } from 'react';

import uuid from 'react-uuid';
import './common.scss';
import './ConfirmationModal.scss';
import Button from '../../common/button/Button';

const ConfirmationModal = ({ title, message, labelAction, closeModal }) => {
  return (
    <div className="modal-container">
      <div className="modal-content">
        <div className="modal-close" onClick={closeModal}>
          <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M13 1L1 13" stroke="#002348" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M1 1L13 13" stroke="#002348" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
          </svg>
        </div>
        <p className="modal-title centered">{title}</p>
        <p className="modal-message centered">{message}</p>
        <div className="modal-button-container">
          <Button label="Cancel" action={closeModal} />
          <Button label="Continue" action={labelAction} />
        </div>
      </div>
    </div>
  );
};

export default ConfirmationModal;
