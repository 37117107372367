import BaseRenderer from 'diagram-js/lib/draw/BaseRenderer';

import {is} from 'bpmn-js/lib/util/ModelUtil';
import {DEFAULT_ZOOM_LEVEL, ELEMENT_WIDTH, HIGH_PRIORITY} from "../renderer-constants";
import {Renderer} from "./RendererUtil";

export default class CallbotClassifierTaskRenderer extends BaseRenderer {
  constructor(eventBus, bpmnRenderer) {
    super(eventBus, HIGH_PRIORITY);

    this.bpmnRenderer = bpmnRenderer;
    this.elementWidth = ELEMENT_WIDTH;
    this.currentZoomLevel = DEFAULT_ZOOM_LEVEL;
    this.config = {
      height: 120,
      color: '#0080FF',
      icon: {
        content: '<path d="M12 19V5.00003L12.001 3.55402C10.47 2.66502 8.482 2.86103 7.171 4.17203C6.045 5.29803 5.744 6.92602 6.242 8.33402C4.351 9.04802 3 10.86 3 13C3 14.655 3.814 16.111 5.052 17.021C5.314 19.258 7.193 21 9.5 21C10.425 21 11.285 20.719 12 20.24V19Z" stroke="#002348" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>\n' +
          '    <path d="M6.53601 5C5.43101 6.913 6.08701 9.36 8.00001 10.464M11.994 10C11.996 11.654 10.657 12.998 9.00301 13" stroke="#002348" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>\n' +
          '    <path d="M12 8H17V6" stroke="#002348" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>\n' +
          '    <path d="M12 12H19" stroke="#002348" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>\n' +
          '    <path d="M17 2C16.4696 2 15.9609 2.21071 15.5858 2.58579C15.2107 2.96086 15 3.46957 15 4C15 4.53043 15.2107 5.03914 15.5858 5.41421C15.9609 5.78929 16.4696 6 17 6C17.5304 6 18.0391 5.78929 18.4142 5.41421C18.7893 5.03914 19 4.53043 19 4C19 3.46957 18.7893 2.96086 18.4142 2.58579C18.0391 2.21071 17.5304 2 17 2ZM21 10C20.4696 10 19.9609 10.2107 19.5858 10.5858C19.2107 10.9609 19 11.4696 19 12C19 12.5304 19.2107 13.0391 19.5858 13.4142C19.9609 13.7893 20.4696 14 21 14C21.5304 14 22.0391 13.7893 22.4142 13.4142C22.7893 13.0391 23 12.5304 23 12C23 11.4696 22.7893 10.9609 22.4142 10.5858C22.0391 10.2107 21.5304 10 21 10Z" stroke="#002348" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>\n' +
          '    <path d="M12 16H17V18" stroke="#002348" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>\n' +
          '    <path d="M17 18C16.4696 18 15.9609 18.2107 15.5858 18.5858C15.2107 18.9609 15 19.4696 15 20C15 20.5304 15.2107 21.0391 15.5858 21.4142C15.9609 21.7893 16.4696 22 17 22C17.5304 22 18.0391 21.7893 18.4142 21.4142C18.7893 21.0391 19 20.5304 19 20C19 19.4696 18.7893 18.9609 18.4142 18.5858C18.0391 18.2107 17.5304 18 17 18Z" stroke="#002348" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>',
        width: 26,
        height: 25
      }
    }
    this.fields = [
      {label: 'classifier type', valueKey: 'classifierType', valueId: 'ClassifierType', defaultValue: 'No classifier selected'},
      {label: 'text to use for classification', valueId: 'InputText', valueKey: 'identifierForInput', defaultValue: 'No variable name'},
      {label: 'variable name for result', valueId: 'ExpressionText', valueKey: 'identifierForResult', defaultValue: 'No variable name'}
    ]
  }

  canRender(element) {
    return is(element, 'bpmn:ScriptTask') && element.businessObject.subType === 'classifier-task';
  }

  // Main function to draw custom shape

  drawShape(parentNode, element) {
    element.width = this.elementWidth;
    const render = Renderer(parentNode, element, this.bpmnRenderer);
    const {shape, zoom} = render(this.config, this.fields);
    this.currentZoomLevel = zoom;
    return shape;
  }
}

CallbotClassifierTaskRenderer.$inject = ['eventBus', 'bpmnRenderer'];
