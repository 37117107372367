import { all } from 'redux-saga/effects';
import modalsSaga from './modalsSaga';
import labelsSaga from './labelsSaga';
import authSaga from './authSaga';
import messageLabelsSaga from './messageLabelsSaga';
import chatsSaga from './chatsSaga';
import qualityAssessmentsSaga from './qualityAssessmentsSaga';
import settingsSaga from './settingsSaga';
import usersSaga from './usersSaga';
import cleanUpSaga from './cleanupSaga';
import tasksSaga from './tasksSaga';

export default function* rootSaga() {
  yield all([
    modalsSaga(),
    settingsSaga(),
    authSaga(),
    labelsSaga(),
    usersSaga(),
    messageLabelsSaga(),
    chatsSaga(),
    qualityAssessmentsSaga(),
    cleanUpSaga(),
    tasksSaga(),
  ]);
}
