import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import * as colors from '../styles/colors';
import * as mixins from '../styles/mixins';
import * as typography from '../styles/typography';
import Labels from './Labels';
import Resolution from './Resolution';
import CustomMetrics from './CustomMetrics';
import MetricsService from '../../../services/MetricsService';
import { Select, Tag } from 'antd';

const customMetricsColorPalette = [
  '#55a868',
  '#c44e52',
  '#8172b3',
  '#937860',
  '#da8bc3',
  '#8c8c8c',
  '#ccb974',
  '#64b5cd',
  '#8de5a1',
  '#ff9f9b',
  '#d0bbff',
  '#debb9b',
  '#fab0e4',
  '#cfcfcf',
  '#fffea3',
  '#b9f2f0',
  '#12711c',
  '#8c0800',
  '#591e71',
  '#592f0d',
  '#a23582',
  '#3c3c3c',
  '#b8850a',
  '#006374',
  '#1ac938',
  '#e8000b',
  '#8b2be2',
  '#9f4800',
  '#f14cc1',
  '#a3a3a3',
  '#ffc400',
  '#00d7ff',
];

const PlotsContent = styled.div`
  width: 100%;
  box-sizing: border-box;
  padding: 20px 10px;

  ${mixins.respondTo.desktop`
        padding: 40px 30px;

        ${mixins.flex('space-between', 'flex-start', 'row', 'wrap')};
    `};

  ${mixins.respondTo.desktopLarge`
        padding: 40px 7%;
    `};
`;

const SelectGroupsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 40px;
`;

const Card = styled.div`
  width: 100%;
  background-color: ${colors.white};
  // box-shadow: 0 1px 2px rgba(0,0,0,0.07),
  //             0 2px 4px rgba(0,0,0,0.07),
  //             0 4px 8px rgba(0,0,0,0.07),
  //             0 8px 16px rgba(0,0,0,0.07),
  //             0 16px 32px rgba(0,0,0,0.07),
  //             0 32px 64px rgba(0,0,0,0.07);

  filter: drop-shadow(0px 8px 16px rgba(0, 51, 167, 0.08));

  box-sizing: border-box;
  padding: 20px;
  margin-bottom: 40px;

  ${mixins.respondTo.tablet`
        padding: 40px 20px;
    `};
`;

const SmallCard = styled(Card)`
  ${mixins.respondTo.desktop`
        width: 49%;
        height: 420px;
    `};
`;

const CustomMetricsSmallCard = styled.div`
  ${mixins.respondTo.desktop`
        height: 420px;
    `};
`;

const CustomMetricsCard = styled(Card)`
  ${mixins.respondTo.desktop`
        width: 49%;
        height: 550px;
    `};
`;

const Title = styled.p`
  color: ${colors.darkBlue};
  ${mixins.fontStyle(typography.biggestTitleFontSize, typography.semibold)};
  margin-bottom: 20px;
  margin-left: 10px;
  text-align: center;
  ${mixins.respondTo.tablet`
        margin-bottom: 40px;
    `};
`;

function get_default_range_start(time_granularity) {
  let default_range_start = new Date();

  if (time_granularity === 'hour') {
    // current day hours and previous day hours
    default_range_start.setDate(default_range_start.getDate() - 1);
    var yyyy = default_range_start.getFullYear();
    var mm = String(default_range_start.getMonth() + 1).padStart(2, '0'); //January is 0
    var dd = String(default_range_start.getDate()).padStart(2, '0');
    default_range_start = yyyy + '-' + mm + '-' + dd + 'T00%3A00';
  } else if (time_granularity === 'day') {
    // current month days and previous month days
    default_range_start.setMonth(default_range_start.getMonth() - 1);
    default_range_start.setDate(1);
    var yyyy = default_range_start.getFullYear();
    var mm = String(default_range_start.getMonth() + 1).padStart(2, '0'); //January is 0
    var dd = String(default_range_start.getDate()).padStart(2, '0');
    default_range_start = yyyy + '-' + mm + '-' + dd + 'T00%3A00';
  } else if (time_granularity === 'month') {
    // current month and previous 12 months
    default_range_start.setMonth(default_range_start.getMonth() - 12);
    var yyyy = default_range_start.getFullYear();
    var mm = String(default_range_start.getMonth() + 1).padStart(2, '0'); //January is 0
    default_range_start = yyyy + '-' + mm + '-' + '01T00%3A00';
  }
  return default_range_start;
}

const Plots = () => {
  const [metricsBotHandledChats, setMetricsBotHandledChats] = useState([]);
  const [metricsDetectedProblemCodeChats, setMetricsDetectedProblemCodeChats] = useState([]);
  const [metricsTotalChats, setMetricsTotalChats] = useState([]);
  const [metricsCustomContexts, setMetricsCustomContexts] = useState([]);
  const [chatLabelAccuracyMetrics, setChatLabelAccuracyMetrics] = useState([]);
  const [customMetricsContextKeys, setCustomMetricsContextKeys] = useState([]);
  const [selectedCustomMetricsContextKeys, setSelectedCustomMetricsContextKeys] = useState([]);
  const [customMetricsColorPaletteMap, setCustomMetricsColorPaletteMap] = useState({});
  const [selectedGroup, setSelectedGroup] = useState(
    localStorage.getItem('selectedGroupForMetrics') !== null
      ? localStorage.getItem('selectedGroupForMetrics')
      : 'ALL_GROUPS'
  );
  const [groups, setGroups] = useState([]);

  useEffect(() => {
    if (groups.length === 0) getGroups();
    getBotHandledMetrics();
    getTotalChatMetrics();
    getDetectedProblemCodes();
    getChatLabelAccuracyMetrics();
    getCustomMetricsKeys();
  }, [selectedGroup]);

  const tagRender = props => {
    console.log(props);
    const { label, value, closable, onClose } = props;
    const onPreventMouseDown = event => {
      event.preventDefault();
      event.stopPropagation();
    };
    return (
      <Tag
        color={customMetricsColorPaletteMap[value]}
        onMouseDown={onPreventMouseDown}
        closable={closable}
        onClose={onClose}
        style={{ marginRight: 3 }}
      >
        {label}
      </Tag>
    );
  };

  let getGroups = async () => {
    let response = await MetricsService.listGroups();
    if (response.status === 200) {
      setGroups(response.data);
    }
  };

  let getCustomMetricsKeys = async () => {
    let response = await MetricsService.listCustomMetricContextKeys();
    if (response.status === 200) {
      setCustomMetricsColorPaletteMap(
        response.data.reduce((acc, curr, index) => {
          acc[curr] = customMetricsColorPalette[index % customMetricsColorPalette.length];
          return acc;
        }, {})
      );
      const keys = response.data.map(x => ({ label: x, value: x, color: 'red' }));
      setCustomMetricsContextKeys(keys);
      setSelectedCustomMetricsContextKeys(response.data);
      getCustomMetrics();
    }
  };

  let getCustomMetrics = async () => {
    let time_granularity = 'month';
    let default_range_start = get_default_range_start(time_granularity);
    let response = await MetricsService.getMetrics({
      metrics_name: 'metrics_custom_contexts',
      time_granularity,
      default_range_start: default_range_start,
      groups: selectedGroup,
    });
    setMetricsCustomContexts(response.data);
  };

  let getTotalChatMetrics = () => {
    let time_granularity = 'month';
    let default_range_start = get_default_range_start(time_granularity);

    MetricsService.getMetrics({
      metrics_name: 'metrics_chat_count',
      time_granularity,
      default_range_start: default_range_start,
      groups: selectedGroup,
    }).then(response => {
      let data = response.data.filter(x => !isNaN(Date.parse(x.dt)));
      data.map(x => (x.count = x.value));
      setMetricsTotalChats(data);
    });
  };

  let getBotHandledMetrics = () => {
    let time_granularity = 'month';
    let default_range_start = get_default_range_start(time_granularity);

    MetricsService.getMetrics({
      metrics_name: 'metrics_bot_handled_chats',
      time_granularity,
      default_range_start: default_range_start,
      groups: selectedGroup,
    }).then(response => {
      let chats = response.data.filter(x => !isNaN(Date.parse(x.dt)));
      MetricsService.getMetrics({
        metrics_name: 'metrics_bot_handled_percent',
        time_granularity,
        default_range_start: default_range_start,
        groups: selectedGroup,
      }).then(response => {
        let data = response.data.filter(x => !isNaN(Date.parse(x.dt)));
        chats.map(x => {
          x.count = x.value;
          x.percent = data.find(y => y.dt == x.dt)?.value?.toFixed(2) || 0;
          x.dt = new Date(x.dt);
        });
        data.map(x => {
          if (!chats.find(y => new Date(y.dt).getTime() == new Date(x.dt).valueOf())) {
            chats.push({
              count: 0,
              percent: x.value?.toFixed(2),
              dt: new Date(x.dt),
            });
          }
        });
        chats.sort((a, b) => (a.dt > b.dt ? 1 : b.dt > a.dt ? -1 : 0));
        setMetricsBotHandledChats(chats);
      });
    });
  };
  let handleCustomMetricsChange = async keys => {
    let newKeys = keys.filter(k => !customMetricsContextKeys.find(x => x.value === k));
    let deletedKeys = customMetricsContextKeys.filter(k => !keys.find(x => x === k.value));
    if (deletedKeys.length > 0) {
      if (
        !confirm(
          'Are you sure you want to delete this custom metric? This is not just for displaying it, but will delete the calculation of this metric'
        )
      )
        return;
    }
    for (let key of newKeys) {
      await MetricsService.insertCustomMetricContextKey(key);
    }
    for (let key of deletedKeys) {
      await MetricsService.deleteCustomMetricContextKey(key.value);
    }
    setCustomMetricsContextKeys([...customMetricsContextKeys, ...newKeys.map(k => ({ label: k, value: k }))]);
    setSelectedCustomMetricsContextKeys(keys);
    setCustomMetricsColorPaletteMap(
      keys.reduce((acc, curr, index) => {
        acc[curr] = customMetricsColorPalette[index % customMetricsColorPalette.length];
        return acc;
      }, {})
    );
  };
  let getChatLabelAccuracyMetrics = () => {
    let time_granularity = 'month';
    let default_range_start = get_default_range_start(time_granularity);

    MetricsService.getMetrics({
      metrics_name: 'metrics_chat_label_accuracy',
      time_granularity,
      default_range_start: default_range_start,
      groups: selectedGroup,
    }).then(response => {
      response.data.sort((a, b) => (a.dt > b.dt ? 1 : b.dt > a.dt ? -1 : 0));
      setChatLabelAccuracyMetrics(response.data);
    });
  };

  let getDetectedProblemCodes = () => {
    let time_granularity = 'month';
    let default_range_start = get_default_range_start(time_granularity);

    MetricsService.getMetrics({
      metrics_name: 'metrics_bot_contacts_count',
      time_granularity,
      default_range_start: default_range_start,
      groups: selectedGroup,
    }).then(response => {
      let chats = response.data.filter(x => !isNaN(Date.parse(x.dt)));
      MetricsService.getMetrics({
        metrics_name: 'metrics_bot_contacts_percent',
        time_granularity,
        default_range_start: default_range_start,
        groups: selectedGroup,
      }).then(response => {
        let data = response.data.filter(x => !isNaN(Date.parse(x.dt)));
        chats.map(x => {
          x.count = x.value;
          x.percent = data.find(y => y.dt == x.dt)?.value?.toFixed(2) || 0;
          x.dt = new Date(x.dt);
        });
        data.map(x => {
          if (!chats.find(y => new Date(y.dt).getTime() == new Date(x.dt).valueOf())) {
            chats.push({
              count: 0,
              percent: x.value?.toFixed(2),
              dt: new Date(x.dt),
            });
          }
        });
        chats.sort((a, b) => (a.dt > b.dt ? 1 : b.dt > a.dt ? -1 : 0));
        setMetricsDetectedProblemCodeChats(chats);
      });
    });
  };
  return (
    <PlotsContent>
      <SelectGroupsContainer>
        <p> Show metrics for group</p>
        <Select
          showSearch
          style={{ width: 400 }}
          value={selectedGroup}
          onChange={group => {
            setSelectedGroup(group);
            localStorage.setItem('selectedGroupForMetrics', group);
          }}
        >
          <Option value="ALL_GROUPS">All groups</Option>
          {groups.map(group => (
            <Option value={group}>{group}</Option>
          ))}
          <Option value="">No specific group</Option>
        </Select>
      </SelectGroupsContainer>
      <SmallCard>
        <Title>Gateway</Title>
        <Resolution metrics={metricsTotalChats} titles={['Total number of chats']} lineChartEnabled={false} />
      </SmallCard>
      <SmallCard>
        <Title>Bot conversation started</Title>
        <Resolution titles={['Bot Contacts', 'Bot conversation started %']} metrics={metricsDetectedProblemCodeChats} />
      </SmallCard>
      <SmallCard>
        <Title>AI</Title>
        <Labels metrics={chatLabelAccuracyMetrics} />
      </SmallCard>

      <SmallCard>
        <Title>First Contact Resolution (FCR)</Title>
        <Resolution titles={['Sum of Bot Resolved', 'FCR %']} metrics={metricsBotHandledChats} />
      </SmallCard>
      <CustomMetricsCard>
        <CustomMetricsSmallCard>
          <Title>Custom Metrics</Title>
          <CustomMetrics
            titles={['Sum of Bot Resolved', 'FCR %']}
            selectedCustomMetricsContextKeys={selectedCustomMetricsContextKeys}
            metrics={metricsCustomContexts}
            customMetricsColorPaletteMap={customMetricsColorPaletteMap}
          />
          <Select
            mode="tags"
            style={{ width: '100%' }}
            tagRender={tagRender}
            placeholder="Tags Mode"
            onChange={handleCustomMetricsChange}
            options={customMetricsContextKeys}
            value={selectedCustomMetricsContextKeys}
          />
        </CustomMetricsSmallCard>
      </CustomMetricsCard>
    </PlotsContent>
  );
};

export default Plots;
