import React from 'react';
import * as colors from '../../styles/colors';

import MessageIcon from '../../components/shared/icons/tasks/MessageIcon';
import VoiceIcon from '../../components/shared/icons/tasks/VoiceIcon';
import HelpdeskMessageIcon from '../../components/shared/icons/tasks/HelpdeskMessageIcon';
import InputIcon from '../../components/shared/icons/tasks/InputIcon';
import ServiceIcon from '../../components/shared/icons/tasks/ServiceIcon';
import TableIcon from '../../components/shared/icons/tasks/TableIcon';
import LogicPythonIcon from '../../components/shared/icons/tasks/LogicPythonIcon';
import ClassifierIcon from '../../components/shared/icons/tasks/ClassifierIcon';
import CarouselIcon from '../../components/shared/icons/tasks/CarouselIcon';
import SmsIcon from '../../components/shared/icons/tasks/SmsIcon';
import RouteIcon from '../../components/shared/icons/tasks/RouteIcon';

export const taskData = {
  'message-task': {
    type: 'bpmn:SendTask',
    subType: 'message-task',
    composed: true,
    prettyType: 'SendTask',
    title: 'Message Task',
    name: 'Message Task',
    icon: <MessageIcon color={colors.mainFontColor} />,
    props: [
      {
        key: 'template',
        label: 'message template',
        type: 'template',
        editable: true,
      },
      {
        key: 'message_speed',
        label: 'message delay speed (seconds)',
        type: 'checkbox-input',
        editable: true,
      },
    ],
  },
  'voice-task': {
    type: 'bpmn:SendTask',
    subType: 'voice-task',
    composed: true,
    prettyType: 'SendTask',
    title: 'Voice Task',
    name: 'Voice Task',
    icon: <VoiceIcon color={colors.mainFontColor} />,
    props: [
      {
        key: 'templateAsAudioUrl',
        label: 'Play an audio by URL in the template',
        type: 'checkbox',
        editable: true,
      },
      {
        key: 'template',
        label: 'SSML message template',
        type: 'template',
        editable: true,
      },
      {
        key: 'message_speed',
        label: 'message delay speed (seconds)',
        type: 'checkbox-input',
        editable: true,
      },
    ],
  },
  'helpdesk-message-task': {
    type: 'bpmn:SendTask',
    subType: 'helpdesk-message-task',
    composed: true,
    prettyType: 'SendTask',
    title: 'Helpdesk Message Task',
    name: 'Helpdesk Message Task',
    icon: <HelpdeskMessageIcon color={colors.mainFontColor} />,
    props: [
      {
        key: 'template',
        label: 'message template',
        type: 'template',
        editable: true,
      },
    ],
  },
  'input-task': {
    type: 'bpmn:ReceiveTask',
    subType: 'input-task',
    prettyType: 'ReceiveTask',
    title: 'Input Task',
    name: 'Input Task',
    icon: <InputIcon color={colors.mainFontColor} />,
    props: [
      {
        key: 'template',
        label: 'message template',
        type: 'template',
        editable: true,
      },
      {
        key: 'identifierForInput',
        label: 'variable name for input',
        type: 'identifier',
        editable: true,
      },
      {
        key: 'choices',
        label: 'choices',
        type: 'choices',
        editable: true,
        options: [
          { label: 'Simple', value: 'simple', component: 'tags-select' },
          { label: 'Variable', value: 'variable', component: 'input' },
        ],
      },
      {
        key: 'validation',
        label: 'validation rules',
        type: 'choices',
        options: [{ label: 'Simple', value: 'simple', component: 'tags-select' }],
        editable: true,
      },
      {
        key: 'retryCount',
        label: 'validation retry count',
        type: 'identifier',
        editable: true,
      },
      {
        key: 'retryMessage',
        label: 'validation retry message',
        type: 'identifier',
        editable: true,
      },
      {
        key: 'timeout',
        label: 'timeout (in seconds)',
        type: 'identifier',
        editable: true,
      },
      {
        key: 'classifierEnabled',
        label: 'Classifier is Enabled?',
        type: 'checkbox',
        editable: true,
      },
      {
        key: 'parser',
        label: 'Parser',
        type: 'identifier',
        editable: true,
      },
      {
        key: 'eventFilter',
        label: 'Event Filter',
        type: 'identifier',
        editable: true,
      },
    ],
  },
  'service-task': {
    type: 'bpmn:ServiceTask',
    subType: 'service-task',
    prettyType: 'ServiceTask',
    title: 'API Call Task',
    name: 'API Call Task',
    icon: <ServiceIcon color={colors.mainFontColor} />,
    props: [
      {
        key: 'method',
        label: 'request method',
        type: 'api',
        editable: true,
      },
      {
        key: 'url',
        label: 'request url',
        type: 'identifier',
        editable: true,
      },
      {
        key: 'identifierForResponse',
        label: 'variable name for response',
        type: 'identifier',
        editable: true,
      },
      {
        key: 'body',
        label: 'request body',
        type: 'code',
        editable: true,
      },
      {
        key: 'requestVariable',
        label: 'request variable',
        type: 'code',
        editable: true,
      },
    ],
  },
  'table-task': {
    type: 'bpmn:ScriptTask',
    subType: 'table-task',
    prettyType: 'ScriptTask',
    composed: true,
    title: 'Table Task',
    name: 'Table Task',
    icon: <TableIcon color={colors.mainFontColor} />,
    props: [
      {
        key: 'firstRowIsHeader',
        label: 'First row is header',
        type: 'checkbox',
        editable: true,
      },
      {
        key: 'firstColumnIsHeader',
        label: 'First column is header',
        type: 'checkbox',
        editable: true,
      },
      {
        key: 'title',
        label: 'Title',
        type: 'input',
        editable: true,
      },
      {
        key: 'footer',
        label: 'Footer',
        type: 'input',
        editable: true,
      },
      {
        key: 'data',
        label: 'Data',
        type: 'input',
        editable: true,
      },
      {
        key: 'definition',
        label: 'Column definition',
        type: 'code',
        editable: true,
      },
    ],
  },
  'carousel-task': {
    type: 'bpmn:ReceiveTask',
    subType: 'carousel-task',
    prettyType: 'ReceiveTask',
    title: 'Carousel Task',
    name: 'Carousel Task',
    composed: true,
    icon: <CarouselIcon color={colors.mainFontColor} />,
    props: [
      {
        key: 'carouselItemsData',
        label: 'items variable',
        type: 'identifier',
        editable: true,
      },
      {
        key: 'itemTitleTemplate',
        label: 'item title reference',
        type: 'template',
        editable: true,
      },
      {
        key: 'itemDescription',
        label: 'item description reference',
        type: 'identifier',
        editable: true,
      },
      {
        key: 'itemButton',
        label: 'item button',
        type: 'identifier',
        editable: true,
      },
      {
        key: 'itemImage',
        label: 'item image reference',
        type: 'identifier',
        editable: true,
      },
      {
        key: 'itemActionPayload',
        label: 'item payload reference',
        type: 'identifier',
        editable: true,
      },
      {
        key: 'identifierForSelectedItem',
        label: 'variable name for input',
        type: 'identifier',
        editable: true,
      },
      {
        key: 'retryCount',
        label: 'validation retry count',
        type: 'identifier',
        editable: true,
      },
      {
        key: 'timeout',
        label: 'timeout',
        type: 'identifier',
        editable: true,
      },
    ],
  },
  'logic-python-task': {
    type: 'bpmn:ScriptTask',
    subType: 'logic-python-task',
    composed: true,
    prettyType: 'ScriptPythonTask',
    title: 'Logic (python) Task',
    name: 'Logic (python) Task',
    icon: <LogicPythonIcon color={colors.mainFontColor} />,
    props: [
      {
        key: 'description',
        label: 'description',
        type: 'description',
        editable: true,
      },
      {
        key: 'template',
        label: 'python code',
        type: 'code',
        editable: true,
      },
    ],
  },
  'classifier-task': {
    type: 'bpmn:ScriptTask',
    subType: 'classifier-task',
    composed: true,
    prettyType: 'ClassifierTask',
    title: 'Classifier Task',
    name: 'Classifier Task',
    icon: <ClassifierIcon color={colors.mainFontColor} />,
    props: [
      {
        key: 'classifierType',
        label: 'classifier type',
        type: 'classifier',
        editable: true,
      },
      {
        key: 'identifierForResult',
        label: 'variable name for result',
        type: 'identifier',
        editable: true,
      },
    ],
  },
};

export const callbotTaskData = {
  ...taskData,
  'sms-task': {
    type: 'bpmn:SendTask',
    subType: 'sms-task',
    composed: true,
    prettyType: 'SendTask',
    title: 'Sms Task',
    name: 'Sms Task',
    icon: <SmsIcon color={colors.mainFontColor} />,
    props: [],
  },
  'routing-task': {
    type: 'bpmn:ScriptTask',
    subType: 'routing-task',
    composed: true,
    prettyType: 'ScriptTask',
    title: 'Routing Task',
    name: 'Routing Task',
    icon: <RouteIcon color={colors.mainFontColor} />,
    props: [
      {
        key: 'routingNumber',
        label: 'routing number',
        type: 'template',
        editable: true,
      },
    ],
  },
  'classifier-task': {
    type: 'bpmn:ScriptTask',
    subType: 'classifier-task',
    composed: true,
    prettyType: 'ClassifierTask',
    title: 'Classifier Task',
    name: 'Classifier Task',
    icon: <ClassifierIcon color={colors.mainFontColor} />,
    props: [
      {
        key: 'classifierType',
        label: 'classifier type',
        type: 'select-state-options',
        optionsKey: 'classifiers',
        editable: true,
      },
      {
        key: 'identifierForInput',
        label: 'text to use for classification',
        type: 'identifier',
        editable: true,
      },
      {
        key: 'identifierForResult',
        label: 'variable name for result',
        type: 'identifier',
        editable: true,
      },
    ],
  },
  'message-task': {
    type: 'bpmn:SendTask',
    subType: 'message-task',
    composed: true,
    prettyType: 'SendTask',
    title: 'Message Task',
    name: 'Message Task',
    icon: <MessageIcon color={colors.mainFontColor} />,
    filterByTaskState: (task, propKey) => !task.file || (propKey !== 'template' && propKey !== 'values'),
    props: [
      {
        key: 'promptName',
        label: 'prompt name',
        type: 'input',
        editable: true,
      },
      {
        key: 'template',
        label: 'message template',
        type: 'template',
        editable: true,
      },
      {
        key: 'promptUrl',
        label: 'audio player',
        type: 'audio',
        editable: false,
      },
      {
        key: 'values',
        label: 'values for jinja template',
        type: 'input',
        editable: true,
      },
      {
        key: 'file',
        label: 'upload file',
        type: 'file',
        editable: true
      }
    ],
  },
  'parallel-gateway': {
    type: 'bpmn:ParallelGateway',
    subType: 'parallel-gateway',
    prettyType: 'ParallelGateway',
    title: 'Parallel Gateway',
    name: 'Parallel Gateway',
    icon: <></>,
    props: [{
      key: 'timeout',
      label: 'timeout (in seconds)',
      type: 'identifier',
      editable: true,
    }],
  },
  'input-task': {
    type: 'bpmn:ReceiveTask',
    subType: 'input-task',
    prettyType: 'ReceiveTask',
    title: 'Input Task',
    name: 'Input Task',
    icon: <InputIcon color={colors.mainFontColor} />,
    filterByTaskState: (task, propKey) => {
      const common = {
        template: !task.file,
        inputType: true,
        identifierForInput: true,
        promptUrl: true,
        file: true,
        timeout: true,
        retryCount: true
      };
      const map = {
        text: { ...common, choices: true },
        numbers: { ...common, validator: true, endChar: true }
      };
      return map[task.inputType][propKey];
    },
    props: [
      {
        key: 'template',
        label: 'message template',
        type: 'template',
        editable: true,
      },
      {
        key: 'inputType',
        label: 'input type',
        type: 'options',
        editable: true,
        options: [
          { label: 'Text', value: 'text' },
          { label: 'Numbers', value: 'numbers' },
        ],
      },
      {
        key: 'identifierForInput',
        label: 'variable name for input',
        type: 'identifier',
        editable: true,
      },
      {
        key: 'endChar',
        label: 'end input on #',
        type: 'checkbox',
        editable: true,
      },
      {
        key: 'choices',
        label: 'choices',
        type: 'choices',
        editable: true,
        options: [
          { label: 'Simple', value: 'simple', component: 'tags-select' },
          { label: 'Variable', value: 'variable', component: 'input' },
        ],
      },
      {
        key: 'validator',
        label: 'validator',
        type: 'select-state-options',
        optionsKey: 'validators',
        editable: true,
      },
      {
        key: 'retryCount',
        label: 'validation retry count',
        type: 'identifier',
        editable: true,
      },
      {
        key: 'timeout',
        label: 'timeout (in seconds)',
        type: 'identifier',
        editable: true,
      },
      {
        key: 'promptUrl',
        label: 'audio player',
        type: 'audio',
        editable: false,
      },
      {
        key: 'file',
        label: 'upload file',
        type: 'file',
        editable: true
      }
    ],
  },
};
