import storage from './StorageService';
import isObjectEmpty from '../../helpers/isObjectEmpty';

export const constructUrl = (apiBaseURL, apiPrefix, urlSegments = []) => {
  let api_constructed_url = apiBaseURL + '/';
  if (apiPrefix !== 'none') {
    api_constructed_url += apiPrefix + '/';
  }
  const compositeUrl = urlSegments.length > 1 ? urlSegments.join('/') : urlSegments[0];
  return api_constructed_url.concat(compositeUrl);
};

const constructQueryParams = data => {
  return !isObjectEmpty(data)
    ? '?'.concat(
        Object.keys(data)
          .flatMap(key => {
            if (Array.isArray(data[key])) {
              return data[key].map(v => `${key}=${v}`)
            } else {
              return `${key}=${data[key]}`;
            }
          })
          .join('&')
      )
    : '';
};

const constructHeaders = (contentType, isAuthRequired, extraHeaders) => {
  // console.log('HEADERS in construct: ', extraHeaders, process.env.REMOTE_USER);
  let headers = {};
  if (process.env.ENV !== 'staging') {
    headers = {
      remote_user: process.env.REMOTE_USER,
    };
  }
  if (isAuthRequired) {
    return Object.assign(headers, {
      'Content-Type': contentType,
    });
  } else {
    return Object.assign(headers, {
      'Content-Type': contentType,
      'X-Auth-Token': storage.get('DTM-CLIENT-TOKEN'),
    });
  }
};

const constructBody = (data, contentType) => {
  return contentType === 'application/json;charset=utf-8' ? JSON.stringify(data) : data;
};

export const sendGET = (
  apiBaseURL,
  apiPrefix,
  urlSegments,
  params = {},
  isAuthRequired,
  extraHeaders = {},
  contentType = 'application/json;charset=utf-8'
) => {
  return fetch(constructUrl(apiBaseURL, apiPrefix, urlSegments).concat(constructQueryParams(params)), {
    method: 'GET',
    headers: constructHeaders(contentType, isAuthRequired, extraHeaders),
  });
};

export const sendPOST = (
  apiBaseURL,
  apiPrefix,
  urlSegments,
  data = [],
  params = {},
  isAuthRequired,
  contentType = 'application/json;charset=utf-8'
) => {
  return fetch(constructUrl(apiBaseURL, apiPrefix, urlSegments).concat(constructQueryParams(params)), {
    method: 'POST',
    headers: constructHeaders(contentType, isAuthRequired),
    body: constructBody(data, contentType),
  });
};

export const sendPUT = (apiBaseURL, apiPrefix, urlSegments, data, contentType = 'application/json;charset=utf-8') => {
  return fetch(constructUrl(apiBaseURL, apiPrefix, urlSegments), {
    method: 'PUT',
    headers: constructHeaders(contentType),
    body: constructBody(data, contentType),
  });
};

export const sendDELETE = (
  apiBaseURL,
  apiPrefix,
  urlSegments,
  params = {},
  contentType = 'application/json;charset=utf-8'
) => {
  return fetch(constructUrl(apiBaseURL, apiPrefix, urlSegments).concat(constructQueryParams(params)), {
    method: 'DELETE',
    headers: constructHeaders(contentType),
  });
};
