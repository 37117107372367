import crud from './common/CrudService';
import responseService from './common/ResponseService';

const API_BASE_URL = process.env.API_URL || 'http://127.0.0.1:8000';
const API_PREFIX = process.env.API_PREFIX || 'api/v1';
const chatsNotLabelledCRUD = crud(API_BASE_URL, API_PREFIX, ['chats/not-labelled/']);

const ChatsService = {
  getChatsNotLabelled: (skip, limit, labelMode, selectedLabel, showLabelled, selectedLanguage, seed) => {
    console.log('ALL: ', skip, limit, labelMode, selectedLabel, showLabelled, selectedLanguage, seed);
    const params = {
      limit,
    };
    if (skip) params['skip'] = skip;
    if (labelMode) params['labelMode'] = labelMode;
    if (selectedLabel) params['selectedLabel'] = selectedLabel;
    if (showLabelled) params['show_labelled'] = showLabelled;
    if (selectedLanguage) params['selected_language'] = selectedLanguage;
    if (seed) params['seed'] = seed;
    return responseService.handle(chatsNotLabelledCRUD.list(params));
  },
  exportChatContexts: (contextKey, dateRange) => {
    let url = `${API_BASE_URL}/${API_PREFIX}/chats/contexts/export?contextKey=${contextKey}&startDate=${dateRange[0]}&endDate=${dateRange[1]}`;
    window.open(url);
  },
  updateMessageLangLabel: (message_id, lang) => {
    const messageLangLabelCRUD = crud(API_BASE_URL, API_PREFIX, ['chats/message/lang']);
    const params = {
      message_id,
      lang,
    };
    return responseService.handle(messageLangLabelCRUD.list(params));
  },
};

export default ChatsService;
