const userLogin = credentials => {
  return {
    type: 'LOGIN',
    credentials,
  };
};

const setLoginErrors = loginErrors => {
  return {
    type: 'SET_LOGIN_ERRORS',
    loginErrors,
  };
};

const userLogout = () => {
  return {
    type: 'LOGOUT',
  };
};

const setActiveUser = user => {
  return {
    type: 'SET_ACTIVE_USER',
    user,
  };
};

const authActions = {
  userLogin,
  setLoginErrors,
  userLogout,
  setActiveUser,
};

export default authActions;
