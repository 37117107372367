import React, { useState } from 'react';

import './common.scss';
import './FormModal.scss';
import Input from '../../common/inputs/Input';
import Button from '../../common/button/Button';
import Textarea from '../../common/inputs/Textarea';
import { Select, Switch, Button as AntButton } from 'antd';
import { PlusOutlined, MinusCircleOutlined } from '@ant-design/icons';
import { SUPPORTED_LANGUAGES } from '../../settings/Settings';

const languages = [
  { flag: '🇬🇧', lang: 'ENG', key: 'en' },
  { flag: '🇪🇪', lang: 'EST', key: 'et' },
  { flag: '🇷🇺', lang: 'RUS', key: 'ru' },
  { flag: '🇩🇪', lang: 'GER', key: 'de' },
  { flag: '🇸🇦', lang: 'ARA', key: 'arb' },
  { flag: '🇵🇱', lang: 'POL', key: 'pl' },
  { flag: '🇱🇹', lang: 'LTU', key: 'lt' },
  { flag: '🇱🇻', lang: 'LVA', key: 'lv' },
];

const intervalOptions = [
  {
    value: 'weekly',
    name: 'Weekly',
  },
  {
    value: 'monthly',
    name: 'Monthly',
  },
  {
    value: 'quarterly',
    name: 'Quarterly',
  },
  {
    value: 'yearly',
    name: 'Yearly',
  },
];

const getJSON = obj => {
  try {
    let json = JSON.parse(obj);
    if (typeof json !== 'object') throw new Error();
    return json;
  } catch (err) {
    return {};
  }
};

const getLabelParser = (obj, label) => {
  obj = getJSON(obj);
  return obj?.main_parser?.[label] || [];
};

const FormModal = ({
  title,
  labelAction,
  closeModal,
  users,
  labels,
  isParent = false,
  exampleModal = false,
  formState = undefined,
  settings,
}) => {
  const getParsers = labelId =>
    getLabelParser(settings?.live_parsers, labels?.find(label => label.id === parseInt(labelId))?.name);

  const [labelParsers, setLabelParsers] = useState(getParsers(parseInt(formState?.label_id)));
  const [parserState, setParserState] = useState(getParsers(parseInt(formState?.label_id)));

  const [labelForm, updateLabelForm] = useState(
    formState || {
      name: '',
      owner_id: '',
      description: '',
      interval: '',
      parent_id: null,
      is_auxiliary: false,
    }
  );

  const updateParsersInSettings = () => {
    let liveParsers = getJSON(settings?.live_parsers);
    let draftParsers = getJSON(settings?.draft_parsers);
    const labelId = labelForm?.label_id;
    if (!labelId) return { liveParsers, draftParsers };
    if (!liveParsers.main_parser) liveParsers.main_parser = {};
    if (!draftParsers.main_parser) draftParsers.main_parser = {};

    let labelName = labels.find(label => label.id === parseInt(labelId))?.name;
    liveParsers.main_parser[labelName] = liveParsers.main_parser[labelName] || [];
    draftParsers.main_parser[labelName] = draftParsers.main_parser[labelName] || [];

    let valuesToAdd = parserState.filter(v => !liveParsers.main_parser[labelName].includes(v));
    let valuesToRemove = liveParsers.main_parser[labelName].filter(v => !parserState.includes(v));
    liveParsers.main_parser[labelName] = parserState;

    draftParsers.main_parser[labelName] = [
      ...new Set(draftParsers.main_parser[labelName].filter(x => !valuesToRemove.includes(x)).concat(valuesToAdd)),
    ];

    if (draftParsers.main_parser[labelName].length === 0) delete draftParsers.main_parser[labelName];
    if (liveParsers.main_parser[labelName].length === 0) delete liveParsers.main_parser[labelName];
    return { liveParsers, draftParsers };
  };

  const setParsers = labelId => {
    let parsers = getParsers(labelId);
    setLabelParsers(parsers);
    setParserState(parsers);
  };

  const updateForm = (key, value) => {
    console.log('V: ', value);
    updateLabelForm(Object.assign({}, labelForm, { [key]: value }));
  };

  return (
    <div className="modal-container">
      <div className="modal-content">
        <div className="modal-close" onClick={closeModal}>
          <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M13 1L1 13" stroke="#002348" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M1 1L13 13" stroke="#002348" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
          </svg>
        </div>
        <div>
          <p className="modal-title">{title}</p>
        </div>
        <div className="modal-two-columns">
          {!exampleModal ? (
            <div className="column">
              <Input
                dir="auto"
                label="Label name"
                placeholder="Provide new label name"
                value={labelForm['name']}
                onChange={e => updateForm('name', e.target.value)}
              />
              <div className="input-container">
                <p className="input-label">Label owner</p>
                <div dir="auto">
                  <Select
                    allowClear
                    showSearch
                    className="input"
                    size="large"
                    bordered={false}
                    placeholder="Specify owner"
                    onChange={e => updateForm('owner_id', e || null)}
                    value={labelForm['owner_id']}
                    filterOption={(input, option) =>
                      option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                      option.props.key.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {users?.map(user => (
                      <Option key={user.email} value={user.id}>{`${user.first_name} ${user.last_name}`}</Option>
                    ))}
                  </Select>
                </div>
              </div>
              <div className="input-container">
                <p className="input-label">Checking interval</p>
                <Select
                  allowClear
                  className="input"
                  size="large"
                  bordered={false}
                  placeholder="Choose Interval"
                  onChange={e => updateForm('interval', e || null)}
                  value={labelForm['interval']}
                  filterOption={(input, option) =>
                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {intervalOptions.map(option => (
                    <Option value={option.value}>{`${option.name}`}</Option>
                  ))}
                </Select>
              </div>
              <div className="input-container">
                <p className="input-label">Parent label</p>
                <div dir="auto">
                  <Select
                    allowClear
                    disabled={isParent}
                    className="input"
                    size="large"
                    bordered={false}
                    showSearch
                    placeholder="Choose Parent"
                    onChange={e => updateForm('parent_id', e || null)}
                    value={labelForm['parent_id']}
                    filterOption={(input, option) =>
                      option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {labels?.map(label => (
                      <Option value={label.id}>{`${label.name}`}</Option>
                    ))}
                  </Select>
                </div>
              </div>
            </div>
          ) : (
            <>
              <div className="column">
                <div className="input-container">
                  <p className="input-label">Label selection</p>
                  <div dir="auto">
                    <Select
                      allowClear
                      className="input"
                      size="large"
                      bordered={false}
                      showSearch
                      placeholder="Choose label"
                      onChange={e => {
                        updateForm('label_id', e || null);
                        setParsers(e || null);
                      }}
                      value={labelForm['label_id']}
                      filterOption={(input, option) =>
                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      {labels?.map(label => (
                        <Option value={label.id}>{`${label.name}`}</Option>
                      ))}
                    </Select>
                  </div>
                </div>
                <div className={`customer-example-list${labelForm?.examples?.length <= 1 ? ' no-scroll' : ''}`}>
                  {labelForm?.examples?.map((example, exampleIndex) => (
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                      <Textarea
                        dir="auto"
                        style={{ height: 100, maxWidth: 370 }}
                        label="How would a customer ask it?"
                        textarea={true}
                        value={example}
                        onChange={e => {
                          let temp = [...labelForm.examples];
                          temp[exampleIndex] = e.target.value;
                          updateForm('examples', temp);
                        }}
                      />
                      {labelForm.examples.length > 1 ? (
                        <MinusCircleOutlined
                          onClick={() => {
                            updateForm(
                              'examples',
                              labelForm.examples.filter((ex, ind) => ind != exampleIndex)
                            );
                          }}
                          className="dynamic-delete-button"
                        />
                      ) : null}
                    </div>
                  ))}
                </div>

                <AntButton
                  onClick={() => {
                    updateForm('examples', Array.isArray(labelForm.examples) ? ['', ...labelForm.examples] : ['']);
                  }}
                  type="dashed"
                  className="add-example-btn"
                  icon={<PlusOutlined />}
                >
                  Add Example
                </AntButton>
              </div>
              <div className="column">
                <div className="input-container">
                  <p className="input-label">Select language</p>
                  <Select
                    allowClear
                    className="input"
                    size="large"
                    bordered={false}
                    showSearch
                    placeholder="Choose Language"
                    onChange={e => updateForm('language', e || null)}
                    value={labelForm['language']}
                  >
                    {languages
                      .filter(lang => SUPPORTED_LANGUAGES.includes(lang.key))
                      .map(option => (
                        <Option key={option.key} value={option.key} label={option.flag}>
                          {option.lang} {option.flag}
                        </Option>
                      ))}
                  </Select>
                </div>
                <div style={{ marginTop: 45 }} className="input-container">
                  <p className="input-label">Keywords</p>
                  <div dir="auto">
                    <Select
                      open={false}
                      mode={'tags'}
                      allowClear
                      className="input"
                      size="large"
                      bordered={false}
                      showSearch
                      disabled={!labelForm['label_id']}
                      placeholder="Choose Keywords"
                      onChange={e => setParserState(e)}
                      value={parserState}
                    >
                      {labelParsers?.map(keyword => (
                        <Option value={keyword}>{`${keyword}`}</Option>
                      ))}
                    </Select>
                  </div>
                </div>
              </div>
            </>
          )}

          {!exampleModal ? (
            <div className="column">
              <div className="input-container">
                <p className="input-label">Auxiliary Label</p>
                <Switch checked={labelForm['is_auxiliary']} onChange={e => updateForm('is_auxiliary', e || false)} />
              </div>
              <Textarea
                dir="auto"
                label="Description"
                textarea={true}
                value={labelForm['description']}
                onChange={e => updateForm('description', e.target.value)}
              />
            </div>
          ) : null}
        </div>
        <div className="modal-button-container">
          <Button
            label="Save and add more"
            action={() => {
              exampleModal
                ? labelAction(
                    labelForm['label_id'],
                    labelForm['examples'],
                    labelForm['language'],
                    updateParsersInSettings()
                  )
                : labelAction(
                    labelForm['name'],
                    labelForm['owner_id'],
                    labelForm['interval'],
                    labelForm['description'],
                    labelForm['parent_id'],
                    labelForm['is_auxiliary']
                  );
              updateLabelForm(
                Object.assign({}, labelForm, {
                  name: '',
                  description: '',
                  interval: 'quarterly',
                  parent_id: null,
                  is_auxiliary: false,
                  label_id: null,
                  examples: [''],
                })
              );
            }}
          />
          <Button
            label="Save and exit"
            action={() => {
              exampleModal
                ? labelAction(
                    labelForm['label_id'],
                    labelForm['examples'],
                    labelForm['language'],
                    updateParsersInSettings()
                  )
                : labelAction(
                    labelForm['name'],
                    labelForm['owner_id'],
                    labelForm['interval'],
                    labelForm['description'],
                    labelForm['parent_id'],
                    labelForm['is_auxiliary']
                  );
              closeModal();
            }}
          />
          <Button label="Cancel" action={closeModal} />
        </div>
      </div>
    </div>
  );
};

export default FormModal;
