const updateLabels = labels => {
  return {
    type: 'UPDATE_LABELS_LIST',
    labels,
  };
};

const labelActions = {
  updateLabels,
};

export default labelActions;
