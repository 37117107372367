import BaseRenderer from 'diagram-js/lib/draw/BaseRenderer';
import {
  append as svgAppend,
  attr as svgAttr,
  create as svgCreate,
} from 'tiny-svg';
import Ids from 'ids';
import {assign} from "min-dash";
import {
  query as domQuery
} from 'min-dom';

const RENDERER_IDS = new Ids();
const HIGH_PRIORITY = 1501;

const CUSTOM_CONNECTIONS = {
  '<timeout>': {
    color: '#335eff',
    endMarker: 'sfm-app/modeler/assets/timer.svg',
    ref: { x: '19', y: '11' }
  },
  '<event>': {
    color: '#10D170',
    endMarker: 'sfm-app/modeler/assets/event.svg',
    ref: { x: '20', y: '10' }
  },
}

const PARALLEL_GATEWAY_CONNECTIONS = {
  '<timeout>': CUSTOM_CONNECTIONS['<timeout>'],
  '<main>': {
    color: '#159a08',
    endMarker: 'sfm-app/modeler/assets/main-branch-marker.svg',
    ref: { x: '19', y: '10' }
  }
}

const DEFAULT = {
  color: 'black',
  endMarker: null,
}

export default class SequenceFlowTaskRenderer extends BaseRenderer {
  constructor(eventBus, bpmnRenderer, canvas) {
    super(eventBus, HIGH_PRIORITY);
    this.bpmnRenderer = bpmnRenderer;
    this.rendererId = RENDERER_IDS.next();
    this.markers = {};
    this.canvas = canvas

    // disable adding labels to sequence flows, which are not going from gateway and which are special rendering rules
    eventBus.on('element.dblclick', HIGH_PRIORITY, (context) => {
      const element = context.element;
      if (element.type === 'bpmn:SequenceFlow') {
        if (
          element.source?.type !== 'bpmn:ExclusiveGateway' ||
          Object.keys(CUSTOM_CONNECTIONS).includes(element.businessObject.name)
        ) {
          return false;
        }
      }
    })
  }

  canRender(element) {
    if (element.type === 'bpmn:SequenceFlow' && 
      (element.source?.type === 'bpmn:ExclusiveGateway' || element.source?.type === 'bpmn:ParallelGateway')) {
      return true;
    }
    
    // if label of sequence flow and should be removed
    if (element.type === 'label' && element.labelTarget.type === 'bpmn:SequenceFlow') {
      if (element.labelTarget.source?.type === 'bpmn:ExclusiveGateway') {
        return Object.keys(CUSTOM_CONNECTIONS).includes(element.labelTarget.businessObject.name);
      }
      else if (element.labelTarget.source?.type === 'bpmn:ParallelGateway') {
        return Object.keys(PARALLEL_GATEWAY_CONNECTIONS).includes(element.labelTarget.businessObject.name);
      } else {
        return true;
      }
    }
    return false;
  }

  drawShape(parentgfx, el) {
    parentgfx.remove(); // remove unneeded label
    return {};  // disabling rendering
  }

  drawConnection(visuals, element, attrs) {
    const drawingProperties = CUSTOM_CONNECTIONS[element.businessObject.name] ||
      PARALLEL_GATEWAY_CONNECTIONS[element.businessObject.name] || DEFAULT;
    element.businessObject.di.set('bioc:stroke', drawingProperties.color)
    const connection = this.bpmnRenderer.drawConnection(visuals, element);
    element.businessObject.di.set('bioc:stroke', undefined);
    if (!drawingProperties.endMarker) {
      return connection;
    }
    const markerContent = this.marker(
      element.businessObject.name, drawingProperties.color,
      drawingProperties.color, drawingProperties.endMarker, drawingProperties.ref
    )
    connection.style.setProperty('marker-end', markerContent);
    // connection.order = { level: 0 };
    return connection;
  }

  colorEscape(str) {
    // only allow characters and numbers
    return str.replace(/[^0-9a-zA-z]+/g, '_');
  }

  marker(type, fill, stroke, content, ref) {
    const id =
      this.colorEscape(type) + '-' + this.colorEscape(fill) + '-' + this.colorEscape(stroke) + '-' + this.rendererId;

    if (!this.markers[id]) {
      this.createMarker(id, fill, stroke, content, ref);
    }

    return 'url(#' + id + ')';
  }

  createMarker(id, fill, stroke, content, ref) {
    const markerContent = svgCreate('image');
    svgAttr(markerContent, {
      href: content,
    })

    this.addMarker(id, {
      element: markerContent,
      ref: ref,
      scale: 0.5,
      attrs: {
        fill: stroke,
        stroke: stroke
      }
    });
  }

  addMarker(id, options) {
    const attrs = assign({
      fill: 'black',
      strokeWidth: 1,
      strokeLinecap: 'round',
      strokeDasharray: 'none'
    }, options.attrs);

    const ref = options.ref || { x: 0, y: 0 };

    const scale = options.scale || 1;

    // fix for safari / chrome / firefox bug not correctly
    // resetting stroke dash array
    if (attrs.strokeDasharray === 'none') {
      attrs.strokeDasharray = [10000, 1];
    }

    const marker = svgCreate('marker');

    svgAttr(options.element, attrs);

    svgAppend(marker, options.element);

    svgAttr(marker, {
      id: id,
      viewBox: '0 0 20 20',
      refX: ref.x,
      refY: ref.y,
      markerWidth: 20 * scale,
      markerHeight: 20 * scale,
      orient: 'auto' // 0
    });

    let defs = domQuery('defs', this.canvas._svg);

    if (!defs) {
      defs = svgCreate('defs');

      svgAppend(this.canvas._svg, defs);
    }

    svgAppend(defs, marker);

    this.markers[id] = marker;
  }

}

SequenceFlowTaskRenderer.$inject = [ 'eventBus', 'bpmnRenderer', 'canvas' ];