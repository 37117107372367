import initialTasksState from '../constants/initial-state-parts/initialTasksState';

const tasksReducer = (state = initialTasksState, action) => {
  switch (action.type) {
    case 'SAVE_TASKS_LIST':
      return Object.assign({}, state, {
        tasks: action.tasks,
      });
    default:
      return state;
  }
};

export default tasksReducer;
