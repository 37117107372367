import React, { useState } from 'react';

import uuid from 'react-uuid';
import './common.scss';
import './FormModal.scss';
import Input from '../../common/inputs/Input';
import Button from '../../common/button/Button';
import Textarea from '../../common/inputs/Textarea';
import Select from '../../common/inputs/Select';

const FormModal = ({ title, labelAction, closeModal, formState = undefined }) => {
  console.log('FORM: ', formState);

  const [labelForm, updateLabelForm] = useState(
    formState || {
      name: '',
      owner: '',
      description: '',
      interval: '',
    }
  );

  const updateForm = (key, value) => {
    console.log('V: ', value);
    updateLabelForm(Object.assign({}, labelForm, { [key]: value }));
  };

  return (
    <div className="modal-container">
      <div className="modal-content">
        <div className="modal-close" onClick={closeModal}>
          <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M13 1L1 13" stroke="#002348" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M1 1L13 13" stroke="#002348" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
          </svg>
        </div>
        <div>
          <p className="modal-title">{title}</p>
        </div>
        <div className="modal-two-columns">
          <div className="column">
            <Input
              label="Label name"
              placeholder="Provide new label name"
              value={labelForm['name']}
              onChange={e => updateForm('name', e.target.value)}
            />
            <Input
              label="Label owner"
              placeholder="Specify owner"
              value={labelForm['owner']}
              onChange={e => updateForm('owner', e.target.value)}
            />
            <Select
              label="Checking interval"
              value={labelForm['interval']}
              onChange={e => updateForm('interval', e.target.value)}
              options={[
                {
                  value: 'weekly',
                  name: 'Weekly',
                },
                {
                  value: 'monthly',
                  name: 'Monthly',
                },
                {
                  value: 'quarterly',
                  name: 'Quarterly',
                },
                {
                  value: 'yearly',
                  name: 'Yearly',
                },
              ]}
            />
          </div>
          <div className="column">
            <Textarea
              label="Checking interval"
              textarea={true}
              value={labelForm['description']}
              onChange={e => updateForm('description', e.target.value)}
            />
          </div>
        </div>
        <div className="modal-button-container">
          <Button
            label="Save"
            action={() =>
              labelAction(labelForm['name'], labelForm['owner'], labelForm['interval'], labelForm['description'])
            }
          />

          <Button label="Cancel" action={closeModal} />
        </div>
      </div>
    </div>
  );
};

export default FormModal;
