import { NotificationManager } from 'react-notifications';

const commonErrorMessage = 'Something went wrong ! Please restart application and try again.';

const NotificationService = {
  push: notification => {
    switch (notification.type) {
      case 'info':
        NotificationManager.info(
          notification.message,
          notification.title || 'Information',
          notification.timeOut || 3000
        );
        break;
      case 'success':
        NotificationManager.success(
          notification.message,
          notification.title || 'Success',
          notification.timeOut || 3000
        );
        break;
      case 'warning':
        NotificationManager.warning(
          notification.message,
          notification.title || 'Warning',
          notification.timeOut || 3000
        );
        break;
      case 'error':
        NotificationManager.error(
          notification.message?.detail || notification.message || commonErrorMessage,
          notification.title || 'Error',
          notification.timeOut || 5000
        );
        break;
    }
  },
};

export default NotificationService;
