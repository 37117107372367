import React, {useMemo} from 'react';
import styled from 'styled-components';
// import * as mixins from '../../styles/mixins';
// import * as colors from '../../styles/colors';
// import * as typography from '../../styles/typography';

import { taskData, callbotTaskData } from '../../constants/tasks/taskData';
import TaskPropertiesHeader from './task-properties-panel-elements/TaskPropertiesHeader';
import TaskPropertiesExpandedArea from './task-properties-panel-elements/task-properties-panel-expanded-area/TaskPropertiesExpandedArea';

const PanelWrapper = styled.div`
  display: block;
  min-width: 48px;
  visibility: hidden;

  ${props =>
    props.isExpanded
      ? `
        min-width: 400px;
        visibility: visible;
        `
      : ``}
`;

const TaskPropertiesPanel = ({
  modeler,
  reviewMode,
  isExpanded,
  currentTask,
  currentTaskData,
  currentBusinessObject,
  updateCurrentTask,
  settings,
  diagramType
}) => {
  const tasks = diagramType === "callbot" ? callbotTaskData : taskData;
  return (
    <PanelWrapper isExpanded={isExpanded} id="task-properties-main-container">
      <TaskPropertiesHeader
        modeler={modeler}
        isExpanded={isExpanded}
        taskData={tasks}
        currentTask={currentTask}
        currentTaskData={currentTaskData}
        updateCurrentTask={updateCurrentTask}
      />
      {isExpanded && currentTaskData ? (
        <TaskPropertiesExpandedArea
          modeler={modeler}
          reviewMode={reviewMode}
          currentTask={currentTask}
          currentTaskData={currentTaskData}
          currentBusinessObject={currentBusinessObject}
          settings={settings}
          diagramType={diagramType}
        />
      ) : (
        ''
      )}
    </PanelWrapper>
  );
};

export default TaskPropertiesPanel;
