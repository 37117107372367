import React from 'react';
import moreHorizontal from '@iconify-icons/akar-icons/more-horizontal';
import { Icon } from '@iconify/react/dist/offline';
import { Button, Dropdown, Menu, Tag } from 'antd';
import { CODE_TO_LANGUAGE_MAP } from '../../../app/constants/codeToLanguageMap';

const LanguageHeaderCell = ({
  lang,
  isDefault,
  translations,
  nodesTranslationData,
  clearAllTranslationsForLanguage,
}) => {
  const notReviewedTranslations = translations.filter(x => x.reviewed === false).length;
  return (
    <div className={`languages-col ${isDefault ? 'bold-text' : ''}`}>
      {isDefault ? (
        <>Source ({CODE_TO_LANGUAGE_MAP[lang]})</>
      ) : (
        <>
          <span>
            <span className="bold-text">{CODE_TO_LANGUAGE_MAP[lang]}&nbsp;</span>(
            {`${translations.length} of ${
              Object.keys(nodesTranslationData).filter(
                nodeId =>
                  nodesTranslationData[nodeId]?.srcString &&
                  nodeId !== 'label_name_translations' &&
                  nodeId !== 'label_description_translations'
              ).length + 2
            }`}
            )
          </span>
          {notReviewedTranslations > 0 && (
            <Tag className="attention-tag" color="error">
              {notReviewedTranslations} require
              {notReviewedTranslations === 1 ? 's' : ''} attention
            </Tag>
          )}
          <div className="more-actions-btn">
            <Dropdown
              placement="bottomRight"
              overlay={
                <Menu>
                  <Menu.Item key="1" onClick={() => clearAllTranslationsForLanguage(lang)}>
                    <span className="color-red">Clear all strings</span>
                  </Menu.Item>
                </Menu>
              }
              trigger={['click']}
            >
              <Button type="text" shape="circle" onClick={e => e.preventDefault()}>
                <Icon className="more-icon" icon={moreHorizontal} />
              </Button>
            </Dropdown>
          </div>
        </>
      )}
    </div>
  );
};

export default LanguageHeaderCell;
