const saveAllWithVerification = (modeler, saveAll, updateSaveProgress, saveInstantly = false) => {
  return {
    type: 'SAVE_ALL_WITH_VERIFICATION',
    modeler,
    saveAll,
    updateSaveProgress,
    saveInstantly,
  };
};

const saveAll = (currentDiagram, currentDiagramVersion, file, saveInstantly) => {
  return {
    type: 'SAVE_ALL',
    currentDiagram,
    currentDiagramVersion,
    file,
    saveInstantly,
  };
};

const saveAllActions = {
  saveAllWithVerification,
  saveAll,
};

export default saveAllActions;
