import React from 'react';
import * as colors from '../../styles/colors';

import {
  ModalWrapper,
  ModalContent,
  ModalIconContent,
  ModalTitle,
  ModalParagraph,
  ModalButtons,
  CancelButton,
  ConfirmButton,
} from './modals-elements/ModalsElements';
import SaveIcon from '../shared/icons/tools/SaveIcon';

const LocalDiagramChangesModal = ({
  modalKey, proceedOnModalCancel, actionOnCancel, argsOnCancel,
  proceedOnModalConfirm, actionOnConfirm, argsOnConfirm, extraModalArgs
}) => {
  return (
    <ModalWrapper>
      <ModalContent>
        <ModalIconContent>
          <SaveIcon color={colors.alternativeIconColor} />
        </ModalIconContent>
        <ModalTitle>Unsaved changes detected in your browser</ModalTitle>
        <ModalParagraph>
          We have detected{' '}
          <span className="bold">unsaved local changes</span>
          in browser for solution flow {extraModalArgs.id_version}. {' '}
          Do you want to continue editing?
        </ModalParagraph>
        <ModalButtons>
          <CancelButton onClick={() => proceedOnModalCancel(actionOnCancel, argsOnCancel, modalKey)}>Cancel</CancelButton>
          <ConfirmButton onClick={() => proceedOnModalConfirm(actionOnConfirm, argsOnConfirm, modalKey)}>
            Continue
          </ConfirmButton>
        </ModalButtons>
      </ModalContent>
    </ModalWrapper>
  );
};

export default LocalDiagramChangesModal;
