import React, { useEffect } from 'react';
import styled from 'styled-components';
import * as mixins from '../../styles/mixins';
import * as colors from '../../styles/colors';
import * as typography from '../../styles/typography';

import FlexibleWrapper from '../shared/FlexibleWrapper';

const DiagramReviewWrapper = styled(FlexibleWrapper)`
  position: relative;
  margin-left: 8px;
`;

const DiagramReviewContent = styled.div`
  ${mixins.flex()};
`;

const DiagramReviewTitle = styled.p`
  margin-right: 10px;
  color: ${colors.mainFontColor};
  ${mixins.fontStyle(typography.labelFontSize, typography.normal)};

  &.no-margin {
    margin-right: 0;
  }
`;

const DiagramReviewButtons = styled.div`
  ${mixins.flex()};
`;

const DiagramReviewButton = styled.p`
  margin-right: 10px;
  color: ${colors.mainFontColor};
  ${mixins.fontStyle(typography.largeTitleFontSize, typography.semibold)};
  cursor: pointer;
  opacity: 0.5;

  &:hover,
  &.current {
    opacity: 1;
  }
`;

const DiagramReview = ({ currentDiagramVersion, saveCurrentDiagramVersionApprovedState }) => {
  return (
    <>
      <DiagramReviewWrapper>
        <DiagramReviewContent>
          <DiagramReviewTitle>Do you approve this solution flow?</DiagramReviewTitle>
          <DiagramReviewButtons>
            <DiagramReviewButton
              className={currentDiagramVersion.approved === true ? 'current' : ''}
              onClick={() => saveCurrentDiagramVersionApprovedState(currentDiagramVersion.id, true)}
            >
              +
            </DiagramReviewButton>
            <DiagramReviewButton
              className={currentDiagramVersion.approved === false ? 'current' : ''}
              onClick={() => saveCurrentDiagramVersionApprovedState(currentDiagramVersion.id, false)}
            >
              -
            </DiagramReviewButton>
          </DiagramReviewButtons>
        </DiagramReviewContent>
      </DiagramReviewWrapper>
      <DiagramReviewWrapper>
        <DiagramReviewTitle className="no-margin">Review mode</DiagramReviewTitle>
      </DiagramReviewWrapper>
    </>
  );
};

export default DiagramReview;
