import React from 'react';
import styled from 'styled-components';

import * as colors from '../../../styles/colors';

import IdIcon from '../../shared/icons/props/IdIcon';
import DescriptionIcon from '../../shared/icons/props/DescriptionIcon';
import OwnersIcon from '../../shared/icons/props/OwnersIcon';
import SubtypeIcon from '../../shared/icons/props/SubtypeIcon';
import TemplateIcon from '../../shared/icons/props/TemplateIcon';
import CodeIcon from '../../shared/icons/props/CodeIcon';
import ExpressionIcon from '../../shared/icons/props/ExpressionIcon';
import IdentifierIcon from '../../shared/icons/props/IdentifierIcon';
import ApiIcon from '../../shared/icons/props/ApiIcon';
import SourceIcon from '../../shared/icons/props/SourceIcon';
import ChoicesIcon from '../../shared/icons/props/ChoicesIcon';
import DiagramIdIcon from '../../shared/icons/props/DiagramIdIcon';
import DiagramVersionIdIcon from '../icons/props/DiagramVersionIdIcon';
import ClassifierIcon from '../icons/props/ClassifierIcon';
import AudioIcon from "../icons/props/AudioIcon";
import {UploadOutlined} from "@ant-design/icons";

const ElementPropIcon = styled.div`
  position: relative;
  box-sizing: border-box;
  padding-top: ${props => (props.biggerTopPadding ? '18px' : '12px')};
  top: ${props => (props.applyTopPositioning ? '20px' : '0px')};
`;

const ElementPropertyGenericIcon = ({ type, applyTopPositioning, biggerTopPadding, isBlocked }) => {
  const currentColor = isBlocked ? colors.extraFontColorWithOpacity : colors.mainFontColor;

  return (
    <ElementPropIcon applyTopPositioning={applyTopPositioning} biggerTopPadding={biggerTopPadding}>
      {type === 'id' ? <IdIcon color={currentColor} /> : ''}
      {type === 'input' ? <DescriptionIcon color={currentColor} /> : ''}
      {type === 'checkbox' ? <ChoicesIcon color={currentColor} /> : ''}
      {type === 'checkbox-input' ? <ChoicesIcon color={currentColor} /> : ''}
      {type === 'description' ? <DescriptionIcon color={currentColor} /> : ''}
      {type === 'owners' ? <OwnersIcon color={currentColor} /> : ''}
      {type === 'subtype' ? <SubtypeIcon color={currentColor} /> : ''}
      {type === 'template' ? <TemplateIcon color={currentColor} /> : ''}
      {type === 'code' ? <CodeIcon color={currentColor} /> : ''}
      {type === 'expression' ? <ExpressionIcon color={currentColor} /> : ''}
      {type === 'identifier' ? <IdentifierIcon color={currentColor} /> : ''}
      {type === 'api' ? <ApiIcon color={currentColor} /> : ''}
      {type === 'source' ? <SourceIcon color={currentColor} /> : ''}
      {type === 'choices' ? <ChoicesIcon color={currentColor} /> : ''}
      {type === 'diagramId' ? <DiagramIdIcon color={currentColor} /> : ''}
      {type === 'diagramVersionId' ? <DiagramVersionIdIcon color={currentColor} /> : ''}
      {type === 'classifier' || type === 'select-state-options' ? <ClassifierIcon color={currentColor} /> : ''}
      {type === 'audio' && <AudioIcon color={currentColor} />}
      {type === 'file' && <UploadOutlined style={{fontSize: "24px", color: currentColor}} />}
    </ElementPropIcon>
  );
};

export default ElementPropertyGenericIcon;
