import React, {useCallback, useRef, useState} from "react";
import {useWebsocket} from "../../hooks/useWebsocket";
import TTSService from "../../services/TTSService";
import VoiceInput from "./VoiceInput";
import NumberInput from "./NumberInput";
import CallbotMessage from "./CallbotMessage";
import CallForm from "./CallForm";
import styled from "styled-components";
import {format} from "date-fns";
import CallbotClientHeader from "./CallbotClientHeader";
import {useIsMounted} from "../../hooks/useIsMounted";
import * as colors from "../../styles/colors";
import uuid from "react-uuid";

const BOT_WS_URL = process.env.CALLBOT_WS_URL || "ws://localhost:8000";

const DEFAULT_CALL_DATA = {
  "caller_number": "37255555555",
  "call_to": "annika",
}

function getCallData(id, callData) {
  return {
    call_id: id,
    start_time: format(new Date(), 'yyyy.MM.dd HH:mm:ss'),
    ...DEFAULT_CALL_DATA,
    ...callData,
  }
}

const ScrollContainer = styled.div`
  overflow-y: auto;
  overflow-x: hidden;
  max-height: calc(100vh - 220px);
  background-color: ${colors.white};
`;

const MessageContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  height: 100%;
  width: 600px;
  padding: 10px 20px 10px 10px;
`;

const Container = styled.div`
  width: 600px;
  border-radius: 10px;
  box-shadow: 0 2px 10px 1px #b5b5b5;
  z-index: 1000;
`;

/**
 * Test client for the callbot (Annika IVR) service. The client is used to test the callbot service by simulating
 * a call with the bot. The client can play prompts, record and send speech, send sound files containing speech, and
 * send keyboard input which is used to simulate DTMF tones.
 *
 * The client "implements" the interface from
 * https://gitlab.mindtitan.com/elisa/annika/-/blob/dev/annikalib/annikalib/rpc_proxy.py?ref_type=heads
 * simulating the sip application that is normally used to handle calls.
 *
 * The client also "consumes" the bot interface from
 * https://gitlab.mindtitan.com/elisa/annika/-/blob/dev/annikalib/annikalib/bot_message_handler.py?ref_type=heads
 * by sending the required messages over websocket connection.
 */
const CallbotTestClient = () => {
  /**
   * The messages state is used to store the messages that are displayed in the client. The messages are displayed in
   * a chat-like interface where the messages are displayed in a list.
   * messages: [{author: 'bot' | 'client' | 'system', message: string, audioSrc?: string}]
   */
  const [messages, setMessages] = useState([]);
  /**
   * The inputState state is used to determine the current state of the input. The input can be in three states:
   * - IDLE: The input is not active and the user can't input anything.
   * - SPEECH: The input is active and the user can record speech.
   * - KEYBOARD: The input is active and the user can input numbers using the keyboard.
   */
  const [inputState, setInputState] = useState("IDLE");
  /**
   * The callInProgress state is used to determine if a call is currently in progress. If a call is in progress, the
   * client can't start a new call.
   */
  const [callInProgress, setCallInProgress] = useState(false);
  /**
   * The isMounted state is used to determine if the component is mounted. This is used to prevent memory leaks when
   * the component is unmounted.
   */
  const {isMounted} = useIsMounted();

  /**
   * Id is a ref that is used to store the id of the current call. The id is used to identify the call.
   * @type {React.MutableRefObject<string>}
   */
  const id = useRef(uuid());

  /**
   * InputValue is a ref that is used to store the current input value. The input value is used to store the input
   * from the user when the input is in the KEYBOARD state.
   * @type {React.MutableRefObject<string>}
   */
  const inputValue = useRef("");

  const addMessage = useCallback((message) => {
    setMessages([...messages, message]);
  }, [messages])

  /**
   * Implementation of the rpc proxy interface
   * https://gitlab.mindtitan.com/elisa/annika/-/blob/dev/annikalib/annikalib/rpc_proxy.py?ref_type=heads
   * handles messages sent by the bot
   * @type {{play_prompt: handlers.play_prompt, chain: handlers.chain, transfer_number: handlers.transfer_number, reset_dtmf_buffer: handlers.reset_dtmf_buffer, record_and_transcript: handlers.record_and_transcript, get_dtmf_buffer: handlers.get_dtmf_buffer, terminate: handlers.terminate}}
   */
  const handlers = {
    play_prompt: ({prompt_id}) => {
      TTSService.getPrompt(prompt_id).then(({data, status}) => {
        if (status === 200) {
          addMessage({author: 'bot', message: data.text, audioSrc: data.url, promptId: prompt_id});
        } else {
          addMessage({author: 'system', message: JSON.stringify(data)});
        }
      }).catch((e) => {
        console.error(e);
        addMessage({author: 'system', message: e.toString()});
      })
    },
    record_and_transcript: () => {
      setInputState("SPEECH");
    },
    terminate: () => {
      addMessage({author: 'system', message: 'Call terminated'});
      terminateCall();
    },
    transfer_number: ({number_to_transfer_to}) => {
      setCallInProgress(false);
      addMessage({author: 'system', message: `Call transferred to ${number_to_transfer_to}`});
    },
    get_dtmf_buffer: () => {
      setInputState("KEYBOARD");
    },
    reset_dtmf_buffer: () => {
      setInputState("IDLE");
      addMessage({author: 'client', message: inputValue.current})
      inputValue.current = "";
    },
    chain: ({messages}) => {
      messages.forEach(({procedure, arguments: args}) => {
        handlers[procedure]({...args});
      })
    },
    cancel_message: ({prompt_id}) => {
      setMessages((messages) => messages.filter(m => m.promptId !== prompt_id));
    },
    cancel_input: () => {
      setInputState("IDLE");
    }
  }

  const {send} = useWebsocket({
    url: BOT_WS_URL,
    onMessage: (e) => {
      if (isMounted) {
        const message = JSON.parse(e.data);
        handlers[message.procedure](message.arguments);
      }
    }
  });

  /**
   * Starts a call with the bot. The call is started by sending a message to the bot with the call_start procedure.
   * @param label Diagram label
   * @param callData Data needed to initialize the call (caller_number, call_to, etc.)
   * @param state Initial diagram state
   * @param language Language of the call, currently its always estonian
   */
  const startCall = ({label, callData, state, language = "estonian"}) => {
    if (!isMounted) {
      return;
    }
    id.current = uuid();
    send(JSON.stringify({
      procedure: 'call_start',
      arguments: {
        label,
        call_data: getCallData(id.current, callData),
        call_id: id.current,
        initial_state: state,
        language,
      }
    }));
    setCallInProgress(true);
  }

  // MESSAGES TO BOT
  const sendTranscription = (transcription) => {
    if (!isMounted) {
      return;
    }
    send(JSON.stringify({
      procedure: 'record_and_transcript_response',
      arguments: {
        call_id: id.current,
        transcription_data: {
          transcribed_text: transcription,
        },
      }
    }));
    addMessage({author: 'client', message: transcription});
    inputValue.current = ""
    setInputState("IDLE");
  }

  const sendNumberInput = (value) => {
    if (!isMounted) {
      return;
    }
    inputValue.current += value;
    send(JSON.stringify({
      procedure: 'number_input',
      arguments: {
        call_id: id.current,
        number: value,
      }
    }));
  }

  const terminateCall = () => {
    if (!isMounted) {
      return;
    }
    inputValue.current = "";
    setInputState("IDLE");
    setCallInProgress(false);
    send(JSON.stringify({
      procedure: 'terminated_call',
      arguments: {
        call_id: id.current,
      }
    }));
  }

  const finishedPlayingPrompt = () => {
    if (isMounted) {
      send(JSON.stringify({
        procedure: 'finished_playing_prompt',
        arguments: {call_id: id.current}
      }));
    }
  }

  return (
    <Container>
      <CallbotClientHeader hangUp={terminateCall}/>
      <ScrollContainer>
        <MessageContainer>
          {messages.map((m, index) => <CallbotMessage key={index} {...m}
                                                      finishedPlayingPrompt={finishedPlayingPrompt}/>)}
          {inputState === "SPEECH" && <VoiceInput sendTranscript={sendTranscription}/>}
          {inputState === "KEYBOARD" && <NumberInput sendInput={sendNumberInput}/>}
          {!callInProgress && <CallForm onSubmit={startCall}/>}
        </MessageContainer>
      </ScrollContainer>
    </Container>
  );
};

export default CallbotTestClient;
