import { put, all, takeLatest, select } from 'redux-saga/effects';

import NotificationService from '../services/common/NotificationService';
import modalsActions from '../action-creators/modalsActions';
import currentDiagramAndVersionActions from '../action-creators/currentDiagramAndVersionActions';
import saveProgressActions from '../action-creators/saveProgressActions';
import saveAllService from '../services/saveAllService';
import {timer} from "../helpers/timer";

const isCurrentDiagramAndVersionSaved = state => {
  return (
    state.app.isCurrentDiagramSaved &&
    state.app.isCurrentDiagramVersionSaved &&
    state.app.isCurrentDiagramVersionFileSaved
  );
};

const getCurrentDiagram = state => state.app.currentDiagram;

const getCurrentDiagramVersion = state => state.app.currentDiagramVersion;

const getCurrentUser = state => state.users.currentUser;

function* saveAllWithVerificationFlow(action) {
  const isSaved = false; // yield select(isCurrentDiagramAndVersionSaved);
  const currentDiagram = yield select(getCurrentDiagram);
  const currentDiagramVersion = yield select(getCurrentDiagramVersion);
  if (!isSaved) {
    saveAllService.handleSave(
      action.modeler,
      currentDiagram,
      currentDiagramVersion,
      action.saveAll,
      action.updateSaveProgress,
      action.saveInstantly,
      action.deleteFromIndexedDB
    );
  }
}

function* watchSaveAllWithVerificationRequested() {
  yield takeLatest('SAVE_ALL_WITH_VERIFICATION', saveAllWithVerificationFlow);
}

function* saveAllFlow(action) {
  timer.start('Save all');
  try {
    const diagram = action.currentDiagram;
    const diagramVersion = action.currentDiagramVersion;
    const file = action.file;
    const currentUser = yield select(getCurrentUser);
    console.log('In save all: ', diagram);

    if (!action.saveInstantly || (action.saveInstantly && diagram.id === -1)) {
      yield put(
        modalsActions.openModal(
          'confirmSave',
          diagram.id === -1
            ? currentDiagramAndVersionActions.saveNewCurrentDiagramAndVersion
            : currentDiagramAndVersionActions.saveCurrentDiagramAndVersion,
          {
            diagram: diagram,
            diagramVersion: diagramVersion,
            versionLabel: diagramVersion.label,
            owner: diagram.id === -1 ? currentUser : diagram.owner,
            createdBy: currentUser,
            file: file,
          },
          {
            isNew: diagram.id === -1,
            diagram: diagram,
            version: diagramVersion,
          },
          saveProgressActions.updateSaveProgress,
          {
            saveInProgress: false,
            saveProgressStatus: 0,
          }
        )
      );
    } else {
      yield put(
        currentDiagramAndVersionActions.saveCurrentDiagramAndVersion({
          diagram: diagram,
          diagramVersion: diagramVersion,
          versionLabel: diagramVersion.label,
          owner: diagram.owner,
          file: file,
          makeTest: true,
        })
      );
    }
  } catch (error) {
    console.error('Save all saga failed: ', error);
    NotificationService.push({
      type: 'error',
      message: 'Save all saga failed!',
    });
  }
  timer.end("Save all");
}

function* watchSaveAllRequested() {
  yield takeLatest('SAVE_ALL', saveAllFlow);
}

export default function* saveAllSaga() {
  yield all([watchSaveAllRequested(), watchSaveAllWithVerificationRequested()]);
}
