import { all } from 'redux-saga/effects';

import usersSaga from './usersSaga';
import diagramsSaga from './diagramsSaga';
import diagramVersionsSaga from './diagramVersionsSaga';
import expandedDiagramSaga from './expandedDiagramSaga';
import currentDiagramAndVersionSaga from './currentDiagramAndVersionSaga';
import saveAllSaga from './saveAllSaga';
import saveDiagramAndVersionSaga from './saveDiagramAndVersionSaga';
import saveNewDiagramSaga from './saveNewDiagramSaga';
import modalsSaga from './modalsSaga';
import currentSubProcessSaga from './currentSubProcessSaga';
import dbSaga from './dbSaga';

export default function* rootSaga() {
  yield all([
    usersSaga(),
    diagramsSaga(),
    diagramVersionsSaga(),
    expandedDiagramSaga(),
    currentDiagramAndVersionSaga(),
    currentSubProcessSaga(),
    saveAllSaga(),
    saveNewDiagramSaga(),
    saveDiagramAndVersionSaga(),
    modalsSaga(),
    dbSaga(),
  ]);
}
