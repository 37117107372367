import {ElementPropOption, ElementPropSelect} from "./utils/CommonElements";
import React from "react";

export const Api = ({
  value, isBlocked, currentElement, elementPropKey, modeling
}) => {
  return (
    <ElementPropSelect
      value={value || ''}
      disabled={isBlocked}
      onChange={e => {
        modeling.updateProperties(currentElement, {
          [elementPropKey]: e.target.value,
        });
      }}
    >
      <ElementPropOption value="">Select request method</ElementPropOption>
      <ElementPropOption value="GET">GET</ElementPropOption>
      <ElementPropOption value="POST">POST</ElementPropOption>
      <ElementPropOption value="POST_NEW">POST (new)</ElementPropOption>
    </ElementPropSelect>
  );
}