import React from 'react';
import styled from 'styled-components';
import * as mixins from '../../styles/mixins';
import * as colors from '../../styles/colors';

const FlexibleWrapper = styled.div`
  ${mixins.flex()};
  min-width: 48px;
  min-height: 48px;
  background: ${colors.componentBackgroundColor};
  border-radius: 2px;
  box-sizing: border-box;
  padding: 0 12px;
`;

export default FlexibleWrapper;
