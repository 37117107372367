import unEscape from "../helpers/unescape";

const DEFAULT_SFM_LANGUAGE = process.env.DEFAULT_SFM_LANGUAGE || 'en';

export function getDefaultTranslations({
  root,
  diagramData,
  description,
  allMessagesWithTranslations: paramAllMessagesWithTranslations,
}) {
  const flowElements = root.flowElements;

  const allMessagesWithTranslations = JSON.parse(JSON.stringify(paramAllMessagesWithTranslations));

  if (!allMessagesWithTranslations['label_name_translations']) {
    allMessagesWithTranslations['label_name_translations'] = {
      srcString: diagramData.name,
      defaultLanguage: DEFAULT_SFM_LANGUAGE,
      translations: {},
    };
  }

  if (!allMessagesWithTranslations['label_description_translations']) {
    allMessagesWithTranslations['label_description_translations'] = {
      srcString: description,
      defaultLanguage: DEFAULT_SFM_LANGUAGE,
      translations: {},
    };
  }

  flowElements.forEach(element => {
    if (element.$type === 'bpmn:SendTask' || element.$type === 'bpmn:ReceiveTask') {
      if (!allMessagesWithTranslations[element.id]) {
        allMessagesWithTranslations[element.id] = {
          srcString: element.$attrs?.['chatbot:template'],
          defaultLanguage: DEFAULT_SFM_LANGUAGE,
          translations: {},
        };
      }
      if (element.$attrs['chatbot:subType'] === 'helpdesk-message-task') {
        delete allMessagesWithTranslations[element.id];
      }
    }

    if (element.$type === 'bpmn:ReceiveTask') {
      if (allMessagesWithTranslations[`${element.id}_retryMessage`]) {
        // migrate from Elisa
        allMessagesWithTranslations[`${element.id}_retry`] = allMessagesWithTranslations[`${element.id}_retryMessage`];
        delete allMessagesWithTranslations[`${element.id}_retryMessage`];
      }
      if (!allMessagesWithTranslations[`${element.id}_retry`]) {
        const inputNodeRetryMessage = element.$attrs?.['chatbot:retryMessage']?.replace(/'/g, '"');

        if (inputNodeRetryMessage) {
          allMessagesWithTranslations[`${element.id}_retry`] = {
            srcString: inputNodeRetryMessage,
            defaultLanguage: DEFAULT_SFM_LANGUAGE,
            translations: {},
          };
        }
      }

      try {
        const choicesParseableString = element.$attrs?.['chatbot:choices']
          ?.replace(/'/g, '"')
          .replace(/gettext\("/g, "gettext('")
          .replace(/"\)/, "')");
        const choices = JSON.parse(choicesParseableString || '[]');
        choices.forEach((choice, index) => {
          const length = Array.from(
            choice.matchAll(/gettext\((["'])(?<str>.*?)["']\)/g),
            match => match.groups.str
          ).length;
          if (length > 0) {
            return;
          }
          if (!allMessagesWithTranslations[`${element.id}_choices_${index}`]) {
            allMessagesWithTranslations[`${element.id}_choices_${index}`] = {
              srcString: unEscape(choice),
              defaultLanguage: DEFAULT_SFM_LANGUAGE,
              translations: {},
            };
          }
        });
      } catch (e) {
        console.log(e);
      }
    }

    if (element.$type === 'bpmn:ScriptTask') {
      const template = element.$attrs?.['chatbot:template'];
      if (template) {
        const groups = Array.from(template.matchAll(/gettext\((["'])(?<str>.*?)["']\)/g), match => match.groups.str);
        groups.forEach((group, index) => {
          if (group && !allMessagesWithTranslations[`${element.id}_scripts_${index}`]) {
            allMessagesWithTranslations[`${element.id}_scripts_${index}`] = {
              srcString: unEscape(group),
              defaultLanguage: DEFAULT_SFM_LANGUAGE,
              translations: {},
            };
          }
        });
      }
    }
  });

  return allMessagesWithTranslations;
}
