import React, { useState } from 'react';
import * as colors from '../../styles/colors';

import {
  ModalWrapper,
  ModalContent,
  ModalIconContent,
  ModalTitle,
  ModalParagraph,
  ModalButtons,
  CancelButton,
  ConfirmButton,
  ModalInput,
  ModalCheckbox,
  ModalRowWrapper,
} from './modals-elements/ModalsElements';
import RadioIcon from '../shared/icons/tools/RadioIcon';

const ENABLE_FEATURE_REVIEW_AND_COMMENTS = process.env.ENABLE_FEATURE_REVIEW_AND_COMMENTS === 'true' || false;

const ConfirmSaveModal = ({
  modalKey,
  closeModal,
  extraModalArgs,
  proceedOnModalConfirm,
  actionOnConfirm,
  argsOnConfirm,
  proceedOnModalCancel,
  actionOnCancel,
  argsOnCancel,
  currentOptionalComment,
  updateCurrentOptionalComment,
  currentDiagramShouldReview,
  updateCurrentDiagramShouldReview,
}) => {
  const { isNew, diagram, version } = extraModalArgs;

  const onConfirmClick = () => {
    proceedOnModalConfirm(actionOnConfirm, argsOnConfirm, modalKey);
  };

  const onExtraConfirmClick = () => {
    proceedOnModalConfirm(actionOnConfirm, Object.assign(argsOnConfirm, { makeTest: true }), modalKey);
  };

  const onCancelClick = () => proceedOnModalCancel(actionOnCancel, argsOnCancel, modalKey);

  return (
    <ModalWrapper>
      <ModalContent className="big">
        <ModalIconContent>
          <RadioIcon color={colors.alternativeIconColor} />
        </ModalIconContent>
        <ModalTitle>Confirmation</ModalTitle>
        {isNew ? (
          <ModalParagraph>
            You are continue with creating new solution flow
            <span className="bold">{diagram.name}</span>
            and new solution flow version with label
            <span className="bold">{version.label}</span>. Do you want to continue?
          </ModalParagraph>
        ) : (
          <ModalParagraph>
            You are continue with creating new version with label
            <span className="bold">{version.label}</span> for solution flow
            <span className="bold">{diagram.name}</span> (#{diagram.id}). Do you want to continue?
          </ModalParagraph>
        )}
        <ModalInput
          id="version-comment-input"
          className="blue-border full-width"
          value={currentOptionalComment}
          onChange={event => updateCurrentOptionalComment(event.target.value)}
          placeholder="Comment (optional)"
        />
        {/* {ENABLE_FEATURE_REVIEW_AND_COMMENTS ? (
          <ModalRowWrapper>
            <ModalParagraph className="small-margin right-padding">Need review:</ModalParagraph>
            <ModalCheckbox
              type="checkbox"
              checked={currentDiagramShouldReview}
              onChange={e => {
                updateCurrentDiagramShouldReview(!currentDiagramShouldReview);
              }}
            ></ModalCheckbox>
          </ModalRowWrapper>
        ) : (
          ''
        )} */}
        <ModalButtons>
          <CancelButton className="small" onClick={onCancelClick}>
            Cancel
          </CancelButton>
          <ConfirmButton onClick={onConfirmClick}>Continue</ConfirmButton>
          <ConfirmButton className="big" onClick={onExtraConfirmClick}>
            Continue & Move to Test
          </ConfirmButton>
        </ModalButtons>
      </ModalContent>
    </ModalWrapper>
  );
};

export default ConfirmSaveModal;
