import React from 'react';
import styled, { keyframes } from 'styled-components';
import * as mixins from '../../styles/mixins';
import * as colors from '../../styles/colors';
import * as typography from '../../styles/typography';

import DiagramPropertiesPanelContainer from '../../containers/diagram-properties-panel/DiagramPropertiesPanelContainer';
import FlexibleWrapper from '../shared/FlexibleWrapper';
import CircleLoaderIcon from '../shared/icons/tools/CircleLoaderIcon';
import CircleLoaderTrackIcon from '../shared/icons/tools/CircleLoaderTrackIcon';
import CheckIcon from '../shared/icons/tools/CheckIcon';

const PanelModal = styled.section`
  display: ${props => (props.isVisible ? 'flex' : 'none')};
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1200;
  padding-top: 100px;
  background-color: ${colors.backgroundColorWithOpacity};
`;

const ToolsReplacer = styled.div`
  position: fixed;
  z-index: 1400;
  box-sizing: border-box;
  padding: 24px;
  top: 90px;
  left: calc(300px + 56px);

  display: flex;
`;

const DiagramSaveLoader = styled(FlexibleWrapper)``;

const rotate = keyframes`
  0% {  transform: rotate(0deg); }
  100% {  transform: rotate(360deg); }
`;

const SaveLoaderTrack = styled.div`
  position: relative;
  width: 24px;
  height: 24px;
  width: 100%;
`;

const SaveLoader = styled.div`
  position: absolute;
  z-index: 1600;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;

  animation: 1.5s ${rotate} linear infinite;
`;

const SaveProgressBar = styled.div`
  width: 100%;
  height: 12px;
  position: fixed;
  top: 0;
  z-index: 1200;
`;

const SaveProgressBarContent = styled.div`
  width: ${props => props.width || 0}%;
  height: 100%;
  background-color: ${colors.mainFontColor};
  ${mixins.transition([`width ${Math.random() * 0.5}s 0s ${Math.random() > 0.5 ? 'ease-in' : 'ease-out'}`])};
`;

const SaveProgressOverlay = ({ saveInProgress, saveProgressStatus, updateSaveProgress }) => {
  // Postponing close of save progress overlay
  // for animation to finish
  let savedSuccessfully = false;
  if (saveProgressStatus === 100) {
    savedSuccessfully = true;
    const finishTimeout = setTimeout(() => {
      updateSaveProgress({});
      savedSuccessfully = false;
      clearTimeout(finishTimeout);
    }, 600);
  }

  return (
    <PanelModal isVisible={saveInProgress}>
      <ToolsReplacer>
        <DiagramPropertiesPanelContainer />
        <DiagramSaveLoader>
          {savedSuccessfully ? (
            <CheckIcon color={colors.successIconColor} />
          ) : (
            <SaveLoaderTrack>
              <CircleLoaderTrackIcon color={colors.mainIconColor} />
              <SaveLoader>
                <CircleLoaderIcon color={colors.extraIconColor} />
              </SaveLoader>
            </SaveLoaderTrack>
          )}
        </DiagramSaveLoader>
      </ToolsReplacer>

      <SaveProgressBar>
        <SaveProgressBarContent width={saveProgressStatus} />
      </SaveProgressBar>
    </PanelModal>
  );
};

export default SaveProgressOverlay;
